import * as Common from "@sp-crm/core";
import { PrimaryButton } from "components/ui/primary-button";
import { SecondaryButton } from "components/ui/secondary-button";
import moment from "moment";
import * as React from "react";

interface PdfDownloadProps {
    client: Common.IClient;
    onDismiss: (interval: number) => void;
    options: Common.ClientIntakeCustomizationOptions;
}

const waitBeforeClosingDelay = 777;

export class PdfDownload extends React.Component<PdfDownloadProps, undefined> {
    downloadForm: HTMLFormElement;

    download() {
        this.downloadForm.submit();
        this.props.onDismiss(waitBeforeClosingDelay);
    }

    dateStampFormatted(): string {
        return moment(this.props.options.dateStamp).toISOString();
    }

    render(): JSX.Element {
        return (
            <>
                <div className="dialog-panel--left-align">
                    <form
                        target="_blank"
                        method="POST"
                        ref={x => (this.downloadForm = x)}
                        action={`/api/clients/${this.props.client.id}/pdf`}
                        className="hidden">
                        <input
                            type="hidden"
                            value={this.props.options.notesOption}
                            name="notesOption"
                        />
                        <input
                            type="hidden"
                            value={this.props.options.freeformNotes}
                            name="freeformNotes"
                        />
                        <input
                            type="hidden"
                            value={JSON.stringify(this.props.options.prebuiltNotes)}
                            name="prebuiltNotes"
                        />
                        <input
                            type="hidden"
                            value={this.props.options.includeAdditionalContacts.toString()}
                            name="includeAdditionalContacts"
                        />
                        <input
                            type="hidden"
                            value={this.props.options.includeContactPhoneNumbers.toString()}
                            name="includeContactPhoneNumbers"
                        />
                        <input
                            type="hidden"
                            value={this.props.options.includeContactEmailAddrs.toString()}
                            name="includeContactEmailAddrs"
                        />
                        <input
                            type="hidden"
                            value={this.props.options.includeContactLocations.toString()}
                            name="includeContactLocations"
                        />
                        <input
                            type="hidden"
                            value={this.props.options.includeMyContact.toString()}
                            name="includeMyContact"
                        />
                        <input
                            type="hidden"
                            value={this.props.options.includeClientBudget.toString()}
                            name="includeClientBudget"
                        />
                        <input
                            type="hidden"
                            value={this.props.options.includeClientLocation.toString()}
                            name="includeClientLocation"
                        />
                        <input
                            type="hidden"
                            value={this.props.options.includeCareProviders.toString()}
                            name="includeCareProviders"
                        />
                        <input
                            type="hidden"
                            value={this.props.options.includeHealthInsurance.toString()}
                            name="includeHealthInsurance"
                        />
                        <input
                            type="hidden"
                            value={this.dateStampFormatted()}
                            name="dateStamp"
                        />
                    </form>
                    <div className="w-full space-x-2 items-center justify-end flex">
                        <SecondaryButton
                            data-automation-id="discard-form-btn"
                            onClick={() => this.props.onDismiss(0)}>
                            Close
                        </SecondaryButton>
                        <PrimaryButton
                            data-automation-id="submit-form-btn"
                            onClick={this.download.bind(this)}>
                            Download
                        </PrimaryButton>
                    </div>
                </div>
            </>
        );
    }
}
