import { Community } from "@sp-crm/core";
import { PrimaryButton } from "components/ui/primary-button";
import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { communitySearchInitializeEvent } from "store/actions/communities";
import { usePreferences, useRegionId } from "store/selectors/hooks";
import { ApplicationState } from "store/state";
import { saveNewCommunity } from "../../store/actions";
import { Content, ContentContainer, SectionHeader, Stage } from "../layout";
import { CommunitySearchResults } from "./community-search-results";
import { SearchCommunity } from "./search-bar/search-bar";

export const CommunitySearch = () => {
    const dispatch = useDispatch();
    const newCommunityRegion = useSelector(
        (state: ApplicationState) => state.region.selectedRegion,
    );
    const preferences = usePreferences();
    const defaultCommunitySortOrder = preferences.defaultCommunitySortOrder;
    const regionId = useRegionId("community");
    const createCommunity = useCallback(() => {
        saveNewCommunity(
            {
                name: "",
                regions: [newCommunityRegion],
            } as unknown as Community,
            dispatch,
        );
    }, [dispatch, newCommunityRegion]);
    const searchKey = useMemo(() => `dashboard-${regionId}`, [regionId]);
    useEffect(() => {
        dispatch(
            communitySearchInitializeEvent(
                searchKey,
                defaultCommunitySortOrder,
                regionId,
            ),
        );
    }, [searchKey, regionId, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Stage>
            <SectionHeader title="Communities">
                <PrimaryButton onClick={createCommunity}>
                    Add a new community
                </PrimaryButton>
            </SectionHeader>
            <Content>
                <SearchCommunity includedExcludedEnabled={false} searchKey={searchKey} />
            </Content>
            <ContentContainer className="mt-2 lg:mt-4">
                <CommunitySearchResults searchKey={searchKey} />
            </ContentContainer>
        </Stage>
    );
};
