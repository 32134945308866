import React from "react";
import { Route, Switch } from "react-router";
import { ManageUsersEdit } from "../manage-users/manage-users-edit";
import { ManageIntegrationsList } from "./manage-integrations-list";

export const ManageIntegrationAccounts: React.FC<unknown> = props => {
    return (
        <Switch>
            <Route
                exact
                path="/settings/integrations/accounts"
                component={ManageIntegrationsList}
            />
            <Route
                path="/settings/integrations/accounts/:userId"
                component={ManageUsersEdit}
            />
        </Switch>
    );
};
