import { ITenantSettings } from "@sp-crm/core";
import React, { useCallback } from "react";
import { useTenantSettings } from "store/selectors/hooks";
import { WorkflowSettings } from "../manage-workflows/workflow-settings";
import { useCommitTenantSettingGeneric } from "./agency-settings-hooks";

export const AgencySettingsWorkflow: React.FC<unknown> = () => {
    const tenantSettings = useTenantSettings();
    const commitTenantSetting = useCommitTenantSettingGeneric();
    const onSettingSaved = useCallback(
        (field: string, newValue: unknown) => {
            commitTenantSetting(field as keyof ITenantSettings, newValue);
        },
        [commitTenantSetting],
    );

    const workflowSettings: Record<string, string> = {};

    Object.keys(tenantSettings).forEach(key => {
        if (key.startsWith("$workflow.")) {
            workflowSettings[key] = tenantSettings[
                key as keyof ITenantSettings
            ] as string;
        }
    });

    return (
        <WorkflowSettings
            workflowSettings={workflowSettings}
            onSave={onSettingSaved}
            scope="tenant"
        />
    );
};
