import { SentMessageId, formatNumber } from "@sp-crm/core";
import { CrmTable } from "components/table/crm-table";
import React from "react";
import { useFeature } from "store/selectors/hooks";
import { useGetMessageQuery } from "../../generated/graphql";
import { isoDateStringToHumanReadable } from "../../util/date/locale-date-format";
import { ErrorMessage } from "../ui/error-message";
import { Panel } from "../ui/panel/panel";
import { PanelType } from "../ui/panel/panel-type";
import { Spinner } from "../ui/spinner";

interface Props {
    messageId: SentMessageId;
    onDismiss: () => void;
}
export const SentMessageDetail: React.FC<Props> = props => {
    const { messageId, onDismiss } = props;
    return (
        <Panel
            isOpen={true}
            isLightDismiss={true}
            onDismiss={onDismiss}
            type={PanelType.largeFixed}
            className="app-component edit-contact-panel"
            headerText="View Email">
            <SentMessageInnerDetail messageId={messageId} />
        </Panel>
    );
};

interface InnerProps {
    messageId: SentMessageId;
}
const SentMessageInnerDetail: React.FC<InnerProps> = props => {
    const enhancedMode = useFeature("enhancedSentMessageView");

    const { messageId } = props;
    const query = useGetMessageQuery({ id: messageId });

    if (query.isLoading) {
        return <Spinner />;
    }
    if (query.isError) {
        return (
            <ErrorMessage component="SentMessageInnerDetail">
                <pre>{JSON.stringify(query.error, null, 2)}</pre>
            </ErrorMessage>
        );
    }
    if (!query.data) {
        return <Spinner />;
    }

    const message = query.data.getMessage;

    const openedEvents = message.events
        ? message.events.filter(e => e.eventType === "opened")
        : [];

    return (
        <div className="space-y-4">
            <div className="space-y-2">
                <h2 className="text-lg">To</h2>
                <div className="font-bold">{(message.to ?? []).join(", ")}</div>
                {message.bouncedRecipients.length !== 0 ? (
                    <div className="rounded bg-yellow-200 text-yellow-700 mt-4 p-4">
                        The email could not be delivered to the following recipients:
                        <br />
                        <ul className="list-inside list-disc">
                            {message.bouncedRecipients.map(r => (
                                <li key={r}>{r}</li>
                            ))}
                        </ul>
                    </div>
                ) : null}
            </div>
            <div className="space-y-2">
                <h2 className="text-lg">Sent</h2>
                <div className="font-bold">
                    {isoDateStringToHumanReadable(message.createdAt)}
                </div>
            </div>
            <div className="space-y-2">
                <h2 className="text-lg">Subject</h2>
                <div className="font-bold">{message.subject}</div>
            </div>
            {message.invoice ? (
                <div className="space-y-2">
                    <h2 className="text-lg">Invoice</h2>
                    <div className="font-bold">{message.invoice.serial}</div>
                </div>
            ) : null}
            <div
                className="unreset min-w-full rounded border border-gray-200 p-4"
                dangerouslySetInnerHTML={{ __html: message.body ?? "" }}
            />
            {message.attachments?.length > 0 ? (
                <div className="space-y-2">
                    <h2 className="text-lg">Attachments</h2>
                    <ul>
                        {message.attachments.map((a, index) => (
                            <li key={index}>{a}</li>
                        ))}
                    </ul>
                </div>
            ) : null}
            {enhancedMode ? (
                <>
                    <div className="space-y-2">
                        <h2 className="text-lg">Times Opened</h2>
                        {openedEvents.some(o => !!o.recipient) ? (
                            <CrmTable
                                columns={[
                                    {
                                        key: "eventTimestamp",
                                        header: "Time",
                                        renderCell: x => (
                                            <>
                                                {isoDateStringToHumanReadable(
                                                    x.record.eventTimestamp,
                                                )}
                                            </>
                                        ),
                                    },
                                    { key: "recipient", header: "Recipient" },
                                ]}
                                data={openedEvents.filter(o => !!o.recipient)}
                            />
                        ) : openedEvents.length > 0 ? (
                            <ul>
                                {openedEvents.map((oe, index) => (
                                    <li key={index} className="font-bold">
                                        {isoDateStringToHumanReadable(oe.eventTimestamp)}
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <div>Unopened</div>
                        )}
                    </div>
                    <div className="space-y-2">
                        <h2 className="text-lg">Links Clicked</h2>
                        {message.clickedLinkEvents ? (
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead>
                                    <tr>
                                        <th className="py-3.5 text-left text-sm font-semibold text-gray-900 px-3">
                                            Link
                                        </th>
                                        <th className="py-3.5 text-left text-sm font-semibold text-gray-900 px-3">
                                            Times Clicked
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {message.clickedLinkEvents.map((cle, index) => {
                                        const clicksByRecipient: Record<string, number> =
                                            cle.eventsV2?.reduce((acc, e) => {
                                                if (e.recipient) {
                                                    acc[e.recipient] =
                                                        acc[e.recipient] || 0;
                                                    acc[e.recipient]++;
                                                } else {
                                                    acc["unknown"] = acc["unknown"] || 0;
                                                    acc["unknown"]++;
                                                }
                                                return acc;
                                            }, {} as Record<string, number>) || {};

                                        return (
                                            <tr key={index}>
                                                <td className="px-3 py-4 text-sm font-medium text-gray-900">
                                                    {cle.link}
                                                </td>
                                                <td className="px-3 py-4 text-sm font-medium text-gray-900">
                                                    {cle.eventsV2?.some(
                                                        e => !!e.recipient,
                                                    ) ? (
                                                        <ul>
                                                            {Object.entries(
                                                                clicksByRecipient,
                                                            )
                                                                .sort(
                                                                    (a, b) => b[1] - a[1],
                                                                )
                                                                .map(
                                                                    ([
                                                                        recipient,
                                                                        count,
                                                                    ]) => (
                                                                        <li
                                                                            key={
                                                                                recipient
                                                                            }>
                                                                            {recipient}
                                                                            &nbsp;(
                                                                            {formatNumber(
                                                                                count,
                                                                            )}
                                                                            )
                                                                        </li>
                                                                    ),
                                                                )}
                                                        </ul>
                                                    ) : (
                                                        cle.eventsV2?.length || 0
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        ) : (
                            <em>None</em>
                        )}
                    </div>
                </>
            ) : null}
        </div>
    );
};
