import { DocumentPlusIcon } from "@heroicons/react/24/outline";
import React from "react";
import { GetClientQuery, useGenerateInvoiceMutation } from "../../generated/graphql";
import { Spinner } from "../ui/spinner";

type Diff<T, U> = T extends U ? never : T;
type NotNullable<T> = Diff<T, null | undefined>;

interface Props {
    invoice: NotNullable<GetClientQuery["getClient"]["invoices"]>[0];
    refetch: () => void;
}

export const InvoiceGenerateLink: React.FC<Props> = props => {
    const { refetch, invoice } = props;
    const [isGenerating, setIsGenerating] = React.useState(false);
    const mutation = useGenerateInvoiceMutation();

    const generate = React.useCallback(
        (e: React.MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault();
            (async () => {
                setIsGenerating(true);
                const result = await mutation.mutateAsync({ id: invoice.id });
                if (result.generateInvoice.errors) {
                    alert(result.generateInvoice.errors.message);
                } else {
                    refetch();
                    setIsGenerating(false);
                }
            })();
        },
        [setIsGenerating, mutation, invoice, refetch],
    );

    if (invoice.fileId) {
        return null;
    }
    if (isGenerating) {
        return <Spinner />;
    }
    return (
        <a
            href="#"
            onClick={generate}
            className="font-semibold text-brand-600 hover:underline">
            <p className="flex items-center space-x-1">
                <DocumentPlusIcon className="w-5 h-5" />
                <span>Generate PDF</span>
            </p>
        </a>
    );
};
