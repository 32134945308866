import { Bars3Icon, TrashIcon } from "@heroicons/react/24/outline";
import { PromptId, QuestionType, genEntityId } from "@sp-crm/core";
import { Input } from "components/ui/input";
import { SecondaryButton } from "components/ui/secondary-button";
import { BridgePromptInput, BridgeQuestionInput } from "generated/graphql";
import { produce } from "immer";
import React, { useCallback } from "react";
import {
    calculateNewOrder,
    useReorderableListDrag,
    useReorderableListDrop,
} from "util/dnd";

interface QuestionPromptListItemProps {
    question: BridgeQuestionInput;
    prompt: BridgePromptInput;
    index: number;
    commitOrder: () => void;
    reorder: (draggingIndex: number, targetIndex: number) => void;
    onChange: (prompt: BridgePromptInput) => void;
    onDelete: (prompt: BridgePromptInput) => void;
    onAddPrompt: () => void;
    editable: boolean;
}

const QuestionPromptListItem: React.FC<QuestionPromptListItemProps> = props => {
    const {
        question,
        prompt,
        onChange,
        onDelete,
        onAddPrompt,
        commitOrder,
        reorder,
        index,
        editable,
    } = props;

    const handleTitleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            onChange({
                ...prompt,
                title: e.target.value,
            });
        },
        [prompt, onChange],
    );

    const handleDeleteClick = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e && e.preventDefault && e.preventDefault();
            onDelete(prompt);
        },
        [prompt, onDelete],
    );

    const handleKeyPress = useCallback(
        (e: React.KeyboardEvent) => {
            if (e.key === "Enter") {
                e.preventDefault();
                onAddPrompt();
            }
        },
        [onAddPrompt],
    );

    const ref = React.useRef<HTMLLIElement>(null);
    const type = `prompt-${question.id}`;

    const [{ isDragging }, drag, preview] = useReorderableListDrag({
        type,
        index,
        commitOrder,
        id: prompt.id,
    });
    // eslint-disable-next-line no-empty-pattern
    const [{}, drop] = useReorderableListDrop({
        ref,
        index,
        reorder,
        type,
    });
    drop(ref);
    return (
        <li ref={ref}>
            <div
                ref={preview}
                className={`py-1 flex space-x-2 ${isDragging ? "opacity-0" : ""}`}>
                {editable ? (
                    <div className="cursor-move px-1 py-3" ref={drag}>
                        <Bars3Icon className="w-4 h-4 text-gray-500" />
                    </div>
                ) : null}
                <Input
                    placeholder="Option title"
                    value={prompt.title}
                    onChange={handleTitleChange}
                    onKeyPress={handleKeyPress}
                    autoFocus
                    disabled={!editable}
                />
                {editable ? (
                    <button onClick={handleDeleteClick}>
                        <TrashIcon className="w-4 h-4" />
                    </button>
                ) : null}
            </div>
        </li>
    );
};

interface QuestionPromptInputProps {
    question: BridgeQuestionInput;
    onChange: (question: BridgeQuestionInput) => void;
    editable: boolean;
}

export const QuestionPromptInput: React.FC<QuestionPromptInputProps> = props => {
    const { question, onChange, editable } = props;

    const handlePromptChange = useCallback(
        (prompt: BridgePromptInput) => {
            const newQuestion = produce(question, draft => {
                const promptIndex = draft.prompts.findIndex(p => p.id === prompt.id);
                if (promptIndex >= 0) {
                    draft.prompts[promptIndex] = prompt;
                }
            });

            onChange(newQuestion);
        },
        [question, onChange],
    );

    const handlePromptDelete = useCallback(
        (prompt: BridgePromptInput) => {
            const newQuestion = produce(question, draft => {
                draft.prompts = draft.prompts.filter(p => p.id !== prompt.id);
            });

            onChange(newQuestion);
        },
        [question, onChange],
    );

    const handleAddPrompt = useCallback(
        (e?: React.MouseEvent<HTMLButtonElement>) => {
            e && e.preventDefault && e.preventDefault();

            const newQuestion = produce(question, draft => {
                draft.prompts = draft.prompts || [];

                const id = genEntityId<PromptId>();

                let order = 0;
                if (draft.prompts.length > 0) {
                    order = draft.prompts[draft.prompts.length - 1].order + 1;
                }

                draft.prompts.push({
                    id,
                    key: id,
                    title: "",
                    order,
                });
            });

            onChange(newQuestion);
        },
        [question, onChange],
    );

    const reorder = useCallback(
        (draggingIndex: number, targetIndex: number) => {
            const newQuestion = produce(question, draft => {
                const newOrder = calculateNewOrder(
                    draft.prompts,
                    draggingIndex,
                    targetIndex,
                );
                const current = draft.prompts[draggingIndex];
                const currentActual = draft.prompts.find(p => p.id === current.id);
                currentActual.order = newOrder;
                draft.prompts.sort((a, b) => a.order - b.order);
            });

            onChange(newQuestion);
        },
        [question, onChange],
    );
    const commitOrder = useCallback(() => {
        const newQuestion = produce(question, draft => {
            draft.prompts.forEach((p, index) => {
                p.order = index;
            });
        });

        onChange(newQuestion);
    }, [question, onChange]);

    if (question.questionType !== QuestionType[QuestionType.nary]) {
        return null;
    }

    return (
        <div>
            <div>Options</div>
            <ul className="mb-2">
                {(question.prompts || []).map((prompt, index) => (
                    <QuestionPromptListItem
                        key={prompt.id}
                        question={question}
                        prompt={prompt}
                        onChange={handlePromptChange}
                        onDelete={handlePromptDelete}
                        onAddPrompt={handleAddPrompt}
                        reorder={reorder}
                        commitOrder={commitOrder}
                        index={index}
                        editable={editable}
                    />
                ))}
            </ul>
            {editable ? (
                <SecondaryButton onClick={handleAddPrompt}>Add option</SecondaryButton>
            ) : null}
        </div>
    );
};
