import { Panel } from "components/ui/panel/panel";
import { PanelType } from "components/ui/panel/panel-type";
import React from "react";
import {
    SignatureRequestIndex,
    SignatureRequestIndexProps,
} from "./signature-request-index";

interface SignatureRequestPanelOwnProps {
    onDismiss: () => void;
    isOpen: boolean;
}

type SignatureRequestPanelProps = SignatureRequestPanelOwnProps &
    SignatureRequestIndexProps;

export const SignatureRequestPanel: React.FC<SignatureRequestPanelProps> = props => {
    const { onDismiss, isOpen, ...rest } = props;
    return (
        <Panel
            isOpen={isOpen}
            onDismiss={onDismiss}
            type={PanelType.large}
            headerText="Request signature">
            <SignatureRequestIndex {...rest} />
        </Panel>
    );
};
