import { UnknownAction } from "@reduxjs/toolkit";
import * as C from "@sp-crm/core";
import {
    FileEntityId,
    FileId,
    genInternalId,
    globalFilesEntityId,
    InternalId,
} from "@sp-crm/core";
import { Action, Dispatch } from "redux";
import * as http from "../../util/http";
import { Actions } from "./index";

interface FileRequestAction {
    eventId: InternalId;
}

export type LoadFilesAction = {
    files: C.IFile[];
    entityId: FileEntityId;
} & Action &
    FileRequestAction;

function loadFilesPayload(
    eventId: InternalId,
    entityId: FileEntityId,
    files: C.IFile[],
): LoadFilesAction {
    return {
        type: Actions[Actions.LOAD_FILES_FINISH],
        eventId,
        entityId,
        files,
    };
}

export function loadFilesAction(
    entityId: FileEntityId,
    dispatch: Dispatch<Action>,
): void {
    const eventId = genInternalId();
    dispatch({
        eventId,
        type: Actions[Actions.LOAD_FILES_START],
    });
    const requestDescription = http.newRequestDescription();
    http.get(requestDescription, `/api/files/${entityId}`)
        .then(r => r.data)
        .then((data: C.IFile[]) => {
            dispatch(loadFilesPayload(eventId, entityId, data));
            return data;
        })
        .catch(r => http.errorHandler(r, requestDescription, dispatch));
    http.get(requestDescription, `/api/files/${globalFilesEntityId.toString()}`)
        .then(r => r.data)
        .then((data: C.IFile[]) => {
            dispatch(loadFilesPayload(eventId, globalFilesEntityId, data));
            return data;
        })
        .catch(r => http.errorHandler(r, requestDescription, dispatch));
}

export interface UpdateFileAction extends UnknownAction {
    eventId: InternalId;
    file: C.IFile;
}

export const updateFilePayload = (
    eventId: InternalId,
    file: C.IFile,
): UpdateFileAction => {
    return {
        type: Actions[Actions.UPDATE_FILE_FINISH],
        eventId,
        file,
    };
};

export const deleteFilePayload = (
    fileId: FileId,
    entityId: FileEntityId,
): UpdateFileAction => {
    return {
        type: Actions[Actions.UPDATE_FILE_FINISH],
        eventId: genInternalId(),
        file: {
            id: fileId,
            entityId: entityId,
            deleted: true,
        } as C.IFile,
    };
};

export interface ClearFileCacheAction extends UnknownAction {
    entityId: FileEntityId;
}

export const clearFileCacheAction = (entityId: FileEntityId): ClearFileCacheAction => ({
    type: Actions[Actions.CLEAR_FILE_CACHE],
    entityId,
});
