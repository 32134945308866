import { ClientId, IAdditionalClient, IClient } from "@sp-crm/core";
import { fancyConfirm } from "components/ui/fancy-confirm";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Action, Dispatch } from "redux";
import { getClient } from "store/selectors/selectors";
import { ApplicationState } from "store/state";
import { updateClient } from "../../../../store/actions";
import { tenantSettings } from "../../../../store/selectors/preferences";
import MultipleClients from "./multiple-client";
import SingleClient from "./single-client";

type InsuranceDetailsOwnProps = {
    clientId: ClientId;
};

type InsuranceDetailsPropsFromState = {
    client: IClient;
    additionalClient: IAdditionalClient;
    showInsuranceInfo: boolean;
};

type InsuranceDetailsDispatchProps = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    onFieldChange: (fieldName: string, newValue: any) => void;
};

type InsuranceDetailsProps = InsuranceDetailsOwnProps &
    InsuranceDetailsPropsFromState &
    InsuranceDetailsDispatchProps;

class InsuranceDetails extends PureComponent<InsuranceDetailsProps> {
    constructor(props: InsuranceDetailsProps) {
        super(props);
        this.handleAdditionalClientFieldChange =
            this.handleAdditionalClientFieldChange.bind(this);
        this.handleSameForBothClientsCheckbox =
            this.handleSameForBothClientsCheckbox.bind(this);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    handleAdditionalClientFieldChange(fieldName: string, newValue: any) {
        this.props.onFieldChange(`additionalClient.${fieldName}`, newValue);
    }

    handleSameForBothClientsCheckbox(newValue: boolean) {
        // TODO: IS THIS REALLY THE RIGHT CHECK? DON'T WE CREATE INSURANCE INFO ALWAYS?!?
        // CONFIRMED - THIS IS NOT THE CORRECT CHECK :(
        // Now tracked by AB#334 (https://dev.azure.com/SeniorPlace/0eb160df-82f3-4a76-90c2-1263582636b2/_workitems/edit/334)
        // We actually need to check every field to see if it has a value.
        if (newValue && !!this.props.additionalClient.insuranceInfo) {
            (async () => {
                const result = await fancyConfirm(
                    "Use same answers for both clients?",
                    "This will erase any Additional Client's answers registered so far",
                    "Yes, I'm sure",
                    "Cancel",
                );
                if (result) {
                    this.handleAdditionalClientFieldChange(
                        "insuranceAnswersSameAsPrimaryClient",
                        newValue,
                    );
                }
            })();
        } else {
            this.handleAdditionalClientFieldChange(
                "insuranceAnswersSameAsPrimaryClient",
                newValue,
            );
        }
    }

    render() {
        if (!this.props.showInsuranceInfo) return null;

        const hasMultipleCliets = !!this.props.additionalClient;

        return (
            <>
                {hasMultipleCliets ? (
                    <MultipleClients
                        client={this.props.client}
                        additionalClient={this.props.additionalClient}
                        onFieldChange={this.props.onFieldChange}
                        onAdditionalClientFieldChange={
                            this.handleAdditionalClientFieldChange
                        }
                        onSameForBothClientsCheckboxChanged={
                            this.handleSameForBothClientsCheckbox
                        }
                    />
                ) : (
                    <SingleClient
                        client={this.props.client}
                        onFieldChange={this.props.onFieldChange}
                    />
                )}
            </>
        );
    }
}

const mapDispatchToProps = (
    dispatch: Dispatch<Action>,
    ownProps: InsuranceDetailsOwnProps,
) => ({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    onFieldChange: (fieldName: string, newValue: any) => {
        updateClient(ownProps.clientId, fieldName, newValue, dispatch);
    },
});

const mapStateToProps = (state: ApplicationState, ownProps: InsuranceDetailsOwnProps) => {
    const loadedClient = getClient(state, ownProps.clientId);

    return {
        client: loadedClient,
        additionalClient: loadedClient.additionalClient,
        showInsuranceInfo: tenantSettings(state).showInsuranceInfo,
    };
};

export default connect<
    InsuranceDetailsPropsFromState,
    InsuranceDetailsDispatchProps,
    InsuranceDetailsOwnProps
>(
    mapStateToProps,
    mapDispatchToProps,
)(InsuranceDetails);
