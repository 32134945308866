import React from "react";
import { NavLink } from "react-router-dom";

interface TertiarynavMenuProps {
    children: React.ReactNode;
}

/**
 * Tertiarynav is the preferred way to render a 3rd level of navigation on a page.
 * Links are listed down the left side of the page.
 * @param props accepts children
 */
export const TertiarynavMenu: React.FC<TertiarynavMenuProps> = props => {
    return <ul className="space-y-1">{props.children}</ul>;
};

interface TertiarynavMenuItemProps {
    to: string;
    children: React.ReactNode;
}
export const TertiarynavMenuItem: React.FC<TertiarynavMenuItemProps> = props => {
    const { to, children } = props;
    return (
        <li>
            <NavLink
                to={to}
                activeClassName="twoverride whitespace-nowrap text-brand-700 bg-brand-100 px-3 py-2 font-semibold rounded-md flex items-center space-x-1"
                className="twoverride whitespace-nowrap text-gray-500 hover:text-gray-700 hover:bg-gray-50 hover:shadow-sm px-3 py-2 font-semibold rounded-md flex items-center space-x-1">
                {children}
            </NavLink>
        </li>
    );
};
