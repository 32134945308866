import { User, UserId, displayName } from "@sp-crm/core";
import React, { useCallback, useMemo } from "react";
import Multiselect from "react-widgets/Multiselect";

interface MultiUserSelectProps {
    onChange: (userIds: UserId[]) => void;
    value: UserId[];
    label: string;
    viewableUsersMap: { [id: UserId]: User };
    className?: string;
}

type UserObject = {
    key: UserId;
    text: string;
};

export const MultiUserSelect: React.FC<MultiUserSelectProps> = props => {
    const { onChange, value, label, viewableUsersMap, className } = props;
    const values: UserObject[] = useMemo(
        () =>
            Object.values(viewableUsersMap)
                .map(u => ({ key: u.id, text: displayName(u) }))
                .sort((a, b) => a.text.localeCompare(b.text)),
        [viewableUsersMap],
    );
    const handleOnChange = useCallback(
        (value: UserObject[]) => {
            const userIds = value.map(x => x.key);
            onChange(userIds);
        },
        [onChange],
    );
    return (
        <div className={`${className ?? ""} w-full`}>
            <label>
                {label ? <div className="mb-1">{label}</div> : null}
                <Multiselect
                    data={values}
                    value={value}
                    dataKey="key"
                    textField="text"
                    onChange={handleOnChange}
                />
            </label>
        </div>
    );
};
