import {
    CalendarDate,
    IChangeTrackOverrideable,
    User,
    UserId,
    isCrmAuthor,
} from "@sp-crm/core";
import { ProductBrandingContainer } from "components/app/product-branding-container";
import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "store/state";
import util from "../../util";
import { ChangeDateCallout } from "../change-date-callout/change-date-callout";

interface BylineHeaderEditableInboundProps {
    showUpdatedBy: boolean;
    createdAtDateModified: (newCreatedAtDate: Date) => void;
    entity: IChangeTrackOverrideable;
    createdByLabel?: string;
}

interface BylineHeaderEditableProps {
    createdAt?: Date;
    createdBy?: User;
    updatedAt?: Date;
    updatedBy?: User;
    createdById?: UserId;
    updatedById?: UserId;

    originalCreatedAt?: Date;
    createdAtDateModified: (newCreatedAtDate: Date) => void;
    showUpdatedBy: boolean;
    createdByLabel?: string;
    closedAt?: CalendarDate;
}

class BylineHeaderEditable extends React.Component<BylineHeaderEditableProps, undefined> {
    static defaultProps = { createdByLabel: "Created" };
    modifyCreated(): JSX.Element {
        if (!this.props.createdAtDateModified) return null;

        return (
            <div className="date-picker-callout-anchor">
                <ChangeDateCallout
                    calloutCaption={`Choose the ${this.props.createdByLabel} Date:`}
                    revertBackToDate={this.props.originalCreatedAt}
                    revertBackDateLabel="*actual date created was"
                    initialDatePickerValue={this.props.createdAt}
                    onDateChanged={newDate => this.props.createdAtDateModified(newDate)}
                />
            </div>
        );
    }

    created(productName: string): JSX.Element {
        if (this.props.createdAt && this.props.createdBy) {
            return (
                <div className="created">
                    {this.props.createdByLabel} by{" "}
                    {util.user.displayName(this.props.createdBy)}{" "}
                    {this.props.originalCreatedAt === this.props.createdAt
                        ? util.date.ago(
                              this.props.createdAt,
                          ) /* only show time if createdAt is not an overridden date-only value */
                        : util.date.daysAgo(this.props.createdAt)}
                </div>
            );
        }
        if (this.props.createdAt && isCrmAuthor(this.props.createdById)) {
            return (
                <div className="created">
                    {this.props.createdByLabel} by {productName}{" "}
                    {this.props.originalCreatedAt === this.props.createdAt
                        ? util.date.ago(
                              this.props.createdAt,
                          ) /* only show time if createdAt is not an overridden date-only value */
                        : util.date.daysAgo(this.props.createdAt)}
                </div>
            );
        }
        if (this.props.createdBy) {
            return (
                <div className="created">
                    {this.props.createdByLabel} by{" "}
                    {util.user.displayName(this.props.createdBy)}
                </div>
            );
        }
        if (this.props.createdAt) {
            return (
                <div className="created">
                    {this.props.createdByLabel}{" "}
                    {this.props.originalCreatedAt === this.props.createdAt
                        ? util.date.ago(
                              this.props.createdAt,
                          ) /* only show time if createdAt is not an overridden date-only value */
                        : util.date.daysAgo(this.props.createdAt)}
                </div>
            );
        }
        return null;
    }

    updated(productName: string): JSX.Element {
        if (!this.props.showUpdatedBy) {
            return null;
        }

        if (this.props.updatedAt && this.props.updatedBy) {
            return (
                <div className="updated">
                    Updated by {util.user.displayName(this.props.updatedBy)}{" "}
                    {util.date.ago(this.props.updatedAt)}
                </div>
            );
        }

        if (this.props.updatedAt && isCrmAuthor(this.props.updatedById)) {
            return (
                <div className="updated">
                    Updated by {productName} {util.date.ago(this.props.updatedAt)}
                </div>
            );
        }

        if (this.props.updatedBy) {
            return (
                <div className="updated">
                    Updated by {util.user.displayName(this.props.updatedBy)}
                </div>
            );
        }
        if (this.props.updatedAt) {
            return (
                <div className="updated">
                    Updated {util.date.ago(this.props.updatedAt)}
                </div>
            );
        }
        return null;
    }

    closed(): JSX.Element {
        if (!this.props.closedAt) {
            return <></>;
        }
        return (
            <div>Closed At {util.date.daysAgo(this.props.closedAt.toMoment(), "")}</div>
        );
    }

    render() {
        return (
            <ProductBrandingContainer>
                {branding => {
                    const productName = branding?.productName || "";
                    return (
                        <div className="flex-row byline-header-control">
                            <div className="flex-column-align-end">
                                <div className="flex-row-no-bottom-margin byline-date-row">
                                    {this.created(productName)}
                                </div>
                                <div className="flex-row-no-bottom-margin byline-date-row">
                                    {this.updated(productName)}
                                </div>
                                <div className="flex-row-no-bottom-margin byline-date-row">
                                    {this.closed()}
                                </div>
                            </div>
                            <div className="flex-column">{this.modifyCreated()}</div>
                        </div>
                    );
                }}
            </ProductBrandingContainer>
        );
    }
}

function mapStateToProps(
    state: ApplicationState,
    ownProps: BylineHeaderEditableInboundProps,
): BylineHeaderEditableProps {
    const entity = ownProps.entity as IChangeTrackOverrideable;
    return {
        createdAt: entity.dateAdded,
        createdBy: state.users.users[entity.createdBy],
        createdById: entity.createdBy,
        updatedAt: entity.updatedAt,
        updatedBy: state.users.users[entity.updatedBy],
        updatedById: entity.updatedBy,
        originalCreatedAt: entity._dateAdded,
        createdAtDateModified: ownProps.createdAtDateModified,
        showUpdatedBy: ownProps.showUpdatedBy,
        createdByLabel: ownProps.createdByLabel,
        closedAt: entity.statusClosedDate,
    };
}
const control = connect(mapStateToProps)(BylineHeaderEditable);
export { control as BylineHeaderEditable };
