import { Checkbox } from "components/ui/checkbox";
import { InlineBanner } from "components/ui/inline-banner";
import { Input } from "components/ui/input";
import { PrimaryButton } from "components/ui/primary-button";
import React, { ChangeEvent, FormEvent, MouseEvent, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "store/state";
import { LoginCredentials } from "util/browser";
import { login, navigate } from "../../store/actions";
import { Col, Row } from "../shared/grid";

export const Login: React.FC = () => {
    const dispatch = useDispatch();

    const isFailed = useSelector((state: ApplicationState) => state.login.loginFailure);

    const [credentials, setCredentials] = useState<LoginCredentials>({
        email: "",
        password: "",
        longTermLogin: false,
    });

    const queryClient = useQueryClient();

    const handleSubmit = (e: FormEvent<HTMLFormElement> | null): void => {
        if (e) {
            e.preventDefault();
        }
        login(credentials, queryClient, dispatch);
    };

    const changeEmail = (e: ChangeEvent<HTMLInputElement>): void => {
        setCredentials(prev => ({ ...prev, email: e.target.value }));
    };

    const changePassword = (e: ChangeEvent<HTMLInputElement>): void => {
        setCredentials(prev => ({ ...prev, password: e.target.value }));
    };

    const onKeepLoggedInCheckbox = (e: ChangeEvent<HTMLInputElement>): void => {
        setCredentials(prev => ({ ...prev, longTermLogin: e.target.checked }));
    };

    const signInError = (): JSX.Element | null => {
        if (isFailed) {
            return (
                <InlineBanner type="error">Sorry! Wrong email or password.</InlineBanner>
            );
        }
        return null;
    };

    const resetPassword = (e: MouseEvent<HTMLAnchorElement>): void => {
        e.preventDefault();
        navigate("/account/reset-password");
    };

    const shouldUseAnotherBrowser = (): boolean => {
        const userAgent = ((navigator && navigator.userAgent) || "").toLowerCase();
        const vendor = ((navigator && navigator.vendor) || "").toLowerCase();
        const isFirefox = /(?:firefox|fxios)\/(\d+)/.test(userAgent);
        const isChrome = /google inc/.test(vendor)
            ? /(?:chrome|crios)\/(\d+)/.test(userAgent)
            : false;
        const isMobile =
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                userAgent,
            );
        const supportsTouch = "ontouchstart" in window || navigator.maxTouchPoints > 0;

        return !isMobile && !isFirefox && !isChrome && !supportsTouch;
    };

    const browserGreenlight = (): JSX.Element | null => {
        if (shouldUseAnotherBrowser()) {
            return (
                <Row>
                    <Col>
                        <div className="greenlight__wrapper">
                            <h3 className="greenlight__header">
                                We recommend a different browser
                            </h3>
                            <p className="greenlight__text">
                                For the best experience, we strongly recommend you use
                                either Google Chrome or Mozilla Firefox.
                            </p>
                            <p className="greenlight__text">
                                To download Google Chrome:{" "}
                                <a
                                    href="https://www.google.com/chrome/browser"
                                    target="_blank"
                                    rel="noreferrer">
                                    https://www.google.com/chrome/browser
                                </a>
                            </p>
                            <p className="greenlight__text">
                                To download Mozilla Firefox:{" "}
                                <a
                                    href="https://www.mozilla.org/download"
                                    target="_blank"
                                    rel="noreferrer">
                                    https://www.mozilla.org/download
                                </a>
                            </p>
                            <p className="greenlight__text">
                                If you would like to proceed with your current browser you
                                can still login below.
                            </p>
                        </div>
                    </Col>
                </Row>
            );
        }
        return null;
    };

    return (
        <div>
            <div className="page-header row">
                <div className="page-title col">
                    <h2>Sign in</h2>
                </div>
                <div className="toolbar col" />
            </div>
            <div className="row main-body">
                <div className="login-control">
                    {browserGreenlight()}
                    <Row>
                        <Col>{signInError()}</Col>
                    </Row>
                    <form onSubmit={handleSubmit}>
                        <div className="space-y-2">
                            <Input
                                type="email"
                                id="email"
                                value={credentials.email}
                                onChange={changeEmail}
                                label="Email address"
                            />
                            <Input
                                type="password"
                                id="password"
                                value={credentials.password}
                                onChange={changePassword}
                                label="Password"
                            />
                            <Checkbox
                                checked={credentials.longTermLogin}
                                onChange={onKeepLoggedInCheckbox}
                                label="Keep me logged in on this computer"
                            />
                            <div className="flex-row flex-space-between">
                                <PrimaryButton
                                    id="signin"
                                    onClick={() => handleSubmit(null)}>
                                    Sign in
                                </PrimaryButton>
                                <a href="#" onClick={resetPassword}>
                                    Forgot your password?
                                </a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
