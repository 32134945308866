import { ChevronUpIcon } from "@heroicons/react/24/outline";
import { extractFirstName } from "@sp-crm/core";
import React from "react";
import { EditButton, IconButton } from "./action-button";
import { AutosavingInput } from "./autosaving-input";

interface ContactNameInputProps {
    name: string;
    preferredName: string;
    onCommit: (fieldName: string, newValue: string) => void;
    disabled?: boolean;
}

export const ContactNameInput: React.FC<ContactNameInputProps> = props => {
    const { name, preferredName, onCommit, disabled } = props;

    const [editingPreferredName, setEditingPreferredName] = React.useState(false);
    const enableEditingPreferredName = React.useCallback(() => {
        setEditingPreferredName(true);
    }, []);
    const disableEditingPreferredName = React.useCallback(() => {
        setEditingPreferredName(false);
    }, []);

    return (
        <div className="w-full">
            <AutosavingInput
                label="Name"
                initial={name}
                onCommit={v => onCommit("name", v)}
                disabled={disabled}
            />
            {editingPreferredName ? (
                <div className="mt-2">
                    <AutosavingInput
                        label={
                            <div className="flex space-x-2">
                                <span>Preferred name</span>
                                <IconButton
                                    size="sm"
                                    onClick={disableEditingPreferredName}
                                    backgroundColor="bg-white"
                                    Icon={ChevronUpIcon}
                                />
                            </div>
                        }
                        initial={preferredName}
                        onCommit={v => onCommit("preferredName", v)}
                        onEmpty={() => extractFirstName(name)}
                    />
                </div>
            ) : (
                <div className="flex items-center space-x-1">
                    <p className="text-sm text-gray-600 p-1">
                        Preferred name: {preferredName}
                    </p>
                    <EditButton
                        tabindex={-1}
                        size="sm"
                        backgroundColor="bg-white"
                        onClick={enableEditingPreferredName}
                    />
                </div>
            )}
        </div>
    );
};
