import { ContentFull, ContentHeader } from "components/layout";
import React from "react";
import { UserCheckboxPref } from "./account-settings-checkbox";

export const AccountSettingsReferralSources: React.FC<unknown> = () => {
    return (
        <ContentFull>
            <ContentHeader>Referral sources</ContentHeader>
            <div className="space-y-4">
                <UserCheckboxPref
                    field="referralMapExperience"
                    label="Enable map view for Referral sources"
                    description={`When viewing all referral sources in the main "Referrals" section, display a map view of the referral sources.`}
                />
                <UserCheckboxPref
                    field="showReferralCityOnMainTable"
                    label="Show Referral Organization's City when viewing all Referral sources"
                    description={`When viewing all referral sources in the main "Referrals" section, display a value for the referral source's city.`}
                />
                <UserCheckboxPref
                    field="showReferralLastUpdatedDateOnMainTable"
                    label="Show Referral's Updated Date when viewing all Referral sources"
                    description={`When viewing all referral sources in the main "Referrals" section, display a value for the date that the referral source was last updated.`}
                />
            </div>
        </ContentFull>
    );
};
