import { CustomLabel } from "./custom-label";
import { DateInput } from "./date";
import { Dropzone } from "./dropzone";
import { Col, Row } from "./grid";
import { InputContactPerson } from "./input-contact-person";
import { InputCurrency } from "./input-currency";
import { MultiplePhotoUpload } from "./multiple-photo-upload";
import { NumberInput } from "./number-input";
import { PhoneInput } from "./phone-input";
import * as Nav from "./subnav";

export default {
    CustomLabel,
    Col,
    DateInput,
    InputContactPerson,
    InputCurrency,
    MultiplePhotoUpload,
    Nav,
    NumberInput,
    PhoneInput,
    Row,
    Dropzone,
};
