import React from "react";
import { SelectBaseOption } from "../../helpers/select-defs";
import { generateOptionsJSX } from "../../helpers/select-util";

interface Props {
    options: SelectBaseOption[];
}

// This control is intended to be used in places where a form control is used for
// multiple potential purposes. For example, the Age vs. Birthday control on a
// client form. The styling of this is intended to look like a "label", while
// still allowing users to toggle the label among select options.
export const SelectLabelPicker: React.FC<
    Props & React.SelectHTMLAttributes<HTMLSelectElement>
> = props => {
    const { options, ...rest } = props;
    const selectOptions = React.useMemo(() => {
        return generateOptionsJSX(options);
    }, [options]);

    return (
        <select
            className="mb-1 bg-transparent min-w-max border-0 hover:border-brand-100 cursor-pointer"
            {...rest}>
            {selectOptions}
        </select>
    );
};
