import { IQuestion, QuestionId, QuestionSource } from "@sp-crm/core";
import { linkStyle } from "components/reports/show/custom-reports/custom-reports-sidebar";
import React, { useCallback } from "react";
import { push } from "util/history";

interface EntityQuestionProps {
    question: IQuestion;
    onEdit: (questionId: QuestionId) => void;
}

export const EntityQuestion: React.FC<EntityQuestionProps> = props => {
    const { question, onEdit } = props;

    const handleClick = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e && e.preventDefault && e.preventDefault();
            if (question.source === QuestionSource.Entity) {
                onEdit(question.id);
            } else {
                push(`/settings/agency/customize/cc/${question.id}`);
            }
        },
        [question, onEdit],
    );

    return (
        <button onClick={handleClick} className={linkStyle}>
            {question.title}
        </button>
    );
};
