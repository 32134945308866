import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import React, { useEffect, useRef } from "react";

interface CalloutChildrenParams {
    close: () => void;
}

interface CalloutProps {
    renderButton: () => JSX.Element;
    onOpen?: () => void;
    forceClose?: boolean;
    children: React.ReactNode | ((params: CalloutChildrenParams) => React.ReactNode);
}

export const Callout: React.FC<CalloutProps> = props => {
    const { onOpen, forceClose, children, renderButton } = props;
    const [isOpen, setIsOpen] = React.useState(false);
    const theButton = useRef<HTMLButtonElement>();
    useEffect(() => {
        if (forceClose && isOpen && theButton.current) {
            theButton.current.click();
        }
    }, [forceClose, isOpen, theButton]);
    return (
        <Popover className="relative">
            {({ open, close }) => {
                setIsOpen(open);
                return (
                    <>
                        <PopoverButton ref={theButton} as="div" onClick={onOpen}>
                            {renderButton()}
                        </PopoverButton>
                        <PopoverPanel className="absolute z-10 mt-4">
                            <div className="relative bg-white p-4 shadow-xl sm:mx-auto sm:max-w-lg sm:rounded-lg ring-2 ring-gray-400">
                                <div className="w-4 h-4 absolute -mt-2 ml-1 bg-white shadow-xl top-0 transform rotate-45  ring-2 ring-gray-400"></div>
                                <div className="absolute inset-0 bg-white rounded-lg" />
                                <div className="mx-auto max-w-lg z-10 relative">
                                    {typeof children === "function"
                                        ? children({
                                              close: () => {
                                                  close();
                                                  setIsOpen(false);
                                              },
                                          })
                                        : children}
                                </div>
                            </div>
                        </PopoverPanel>
                    </>
                );
            }}
        </Popover>
    );
};
