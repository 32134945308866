import { IChangeTracked, isCrmAuthor, User, UserId } from "@sp-crm/core";
import { ProductBrandingContainer } from "components/app/product-branding-container";
import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "store/state";
import util from "../../util";

interface BylineHeaderProps {
    createdAt?: Date;
    createdBy?: User;
    createdById?: UserId;
    updatedAt?: Date;
    updatedBy?: User;
    updatedById?: UserId;
}

class BylineHeader extends React.Component<BylineHeaderProps, undefined> {
    created(productName: string): JSX.Element {
        if (this.props.createdAt && this.props.createdBy) {
            return (
                <div className="created">
                    Created by {util.user.displayName(this.props.createdBy)}{" "}
                    {util.date.ago(this.props.createdAt)}
                </div>
            );
        }
        if (this.props.createdAt && isCrmAuthor(this.props.createdById)) {
            return (
                <div className="created">
                    Created by {productName} {util.date.ago(this.props.createdAt)}
                </div>
            );
        }

        if (this.props.createdBy) {
            return (
                <div className="created">
                    Created by {util.user.displayName(this.props.createdBy)}
                </div>
            );
        }
        if (this.props.createdAt) {
            return (
                <div className="created">
                    Created {util.date.ago(this.props.createdAt)}
                </div>
            );
        }
        return null;
    }

    updated(productName: string): JSX.Element {
        if (this.props.updatedAt && this.props.updatedBy) {
            return (
                <div className="updated">
                    Updated by {util.user.displayName(this.props.updatedBy)}{" "}
                    {util.date.ago(this.props.updatedAt)}
                </div>
            );
        }
        if (this.props.updatedAt && isCrmAuthor(this.props.updatedById)) {
            return (
                <div className="updated">
                    Updated by {productName} {util.date.ago(this.props.updatedAt)}
                </div>
            );
        }
        if (this.props.updatedBy) {
            return (
                <div className="updated">
                    Updated by {util.user.displayName(this.props.updatedBy)}
                </div>
            );
        }
        if (this.props.updatedAt) {
            return (
                <div className="updated">
                    Updated {util.date.ago(this.props.updatedAt)}
                </div>
            );
        }
        return null;
    }

    render() {
        return (
            <ProductBrandingContainer>
                {branding => {
                    const productName = branding?.productName || "";
                    return (
                        <div className="byline-header-control">
                            <div className="byline-created-date-row">
                                {this.created(productName)}
                            </div>
                            {this.updated(productName)}
                        </div>
                    );
                }}
            </ProductBrandingContainer>
        );
    }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
function mapStateToProps(state: ApplicationState, ownProps: any): BylineHeaderProps {
    const entity = ownProps.entity as IChangeTracked;
    return {
        createdAt: entity.dateAdded,
        createdBy: state.users.users[entity.createdBy],
        createdById: entity.createdBy,
        updatedAt: entity.updatedAt,
        updatedBy: state.users.users[entity.updatedBy],
        updatedById: entity.updatedBy,
    };
}
const control = connect(mapStateToProps)(BylineHeader);
export { control as BylineHeader };
