import { CustomListItemId, CustomListKey, IClient } from "@sp-crm/core";
import { ActionBarComponent } from "components/shared/action-bar-component";
import { CustomListSelect } from "components/shared/custom-list-select";
import React, { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { updateClient } from "store/actions";
import { useCustomList } from "store/selectors/bridge";
import { promptForStatusIdChange } from "util/clientStatus";
import { handleEvent } from "util/user-events";

interface ActionBarStatusProps {
    client: IClient;
    reactivate: () => void;
}

export const ActionBarStatus: React.FC<ActionBarStatusProps> = props => {
    const { client, reactivate } = props;

    const { customList } = useCustomList(CustomListKey.ClientStatus);
    const dispatch = useDispatch();

    const value = useMemo(() => {
        return customList?.items.find(x => x.id === client.statusListItemId)?.name || "";
    }, [customList, client]);

    const handleChange = useCallback(
        async (newStatusId: CustomListItemId) => {
            const oldStatusId = client.statusListItemId;

            const result = await promptForStatusIdChange(
                customList,
                oldStatusId,
                newStatusId,
            );

            if (result === "reactivate") {
                reactivate();
            } else {
                handleEvent("client-status-change", {
                    source: "client-detail-page",
                    oldStatus: oldStatusId,
                    newStatus: newStatusId,
                });
                updateClient(client.id, "statusListItemId", newStatusId, dispatch);
            }
        },
        [customList, client, reactivate, dispatch],
    );

    return (
        <ActionBarComponent
            label="Status"
            value={value}
            className="action-bar-status action-bar-accented-component">
            {({ close }) => {
                return (
                    <CustomListSelect
                        label="Status"
                        customListKey={CustomListKey.ClientStatus}
                        onChange={newStatus => {
                            close();
                            handleChange(newStatus as CustomListItemId);
                        }}
                        value={client.statusListItemId}
                        valueMode="id"
                    />
                );
            }}
        </ActionBarComponent>
    );
};
