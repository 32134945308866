import { User } from "@sp-crm/core";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { updateUser } from "store/actions";
import { useCurrentUser } from "store/selectors/hooks";
import { WorkflowSettings } from "../manage-workflows/workflow-settings";

export const AccountSettingsWorkflow: React.FC<unknown> = () => {
    const user = useCurrentUser();
    const dispatch = useDispatch();
    const commitUserPreference = useCallback(
        (field: string, newValue: unknown) => {
            const loadedUser = User.load(user);
            loadedUser.preferences = {
                ...loadedUser.preferences,
                ...(loadedUser.preferences.workflowSettings ?? {}),
                [`${field}`]: newValue,
            };
            delete loadedUser.preferences.workflowSettings;
            updateUser(loadedUser, dispatch);
        },
        [user, dispatch],
    );
    return (
        <WorkflowSettings
            workflowSettings={user.preferences.workflowSettings}
            onSave={commitUserPreference}
            scope="user"
        />
    );
};
