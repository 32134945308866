import { DefaultReferences } from "@sp-crm/core";
import { InlineBanner } from "components/ui/inline-banner";
import { SecondaryButton } from "components/ui/secondary-button";
import React from "react";

interface DefaultReferenceItemProps {
    item: {
        key: string;
        text: string;
    };
    onSelect: (value: string) => void;
}

const DefaultReferenceItem: React.FC<DefaultReferenceItemProps> = props => {
    const { item, onSelect } = props;

    const handleSelect = React.useCallback(
        async (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
            e.preventDefault();
            onSelect(item.key);
        },
        [onSelect, item.key],
    );

    return (
        <div className="flex justify-between hover:bg-brand-100 items-center text-sm px-4 py-2">
            <p className="font-medium">{item.text}</p>
            <SecondaryButton onClick={handleSelect} className="text-sm">
                Select
            </SecondaryButton>
        </div>
    );
};

interface GeneralReferralSourcePickerProps {
    onSelect: (value: string) => void;
}

export const GeneralReferralSourcePicker: React.FC<
    GeneralReferralSourcePickerProps
> = props => {
    const { onSelect } = props;

    return (
        <div>
            <InlineBanner type="info">
                For more effective tracking and reporting, we recommend choosing one of
                the referral source options available in the other tabs
            </InlineBanner>
            <ul>
                {DefaultReferences.filter(r => r.group === "General").map(r => {
                    return (
                        <li key={r.key} className="mt-2">
                            <DefaultReferenceItem item={r} onSelect={onSelect} />
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};
