import React from "react";
import { usePreferences, useResponsiveMode } from "store/selectors/hooks";
import { ReferenceDashboardMap, referralMapThreshold } from "../reference-dashboard-map";
import { ReferenceDashboardTable } from "../reference-dashboard-table";

export const ReferenceDashboard: React.FC<unknown> = () => {
    const { referralMapExperience } = usePreferences();
    const responsiveMode = useResponsiveMode();

    const useCards = referralMapExperience || responsiveMode < referralMapThreshold;

    return useCards ? <ReferenceDashboardMap /> : <ReferenceDashboardTable />;
};
