import * as React from "react";
import { Spinner } from "./ui/spinner";

interface LoadingProps {
    componentName: string;
}

export const Loading: React.FunctionComponent<LoadingProps> = props => {
    return (
        <div>
            <Spinner /> {props.componentName}
        </div>
    );
};
