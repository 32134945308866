import { CheckCircleIcon, NoSymbolIcon } from "@heroicons/react/20/solid";
import { CommunityRelationshipType } from "@sp-crm/core";
import React from "react";
import { ClientCommunityRelationship } from "../types";

interface CommunityCardClientSearchExperienceProps {
    clientCommunityRelationship: ClientCommunityRelationship;
}

export const CommunityCardClientSearchExperience: React.FC<
    CommunityCardClientSearchExperienceProps
> = props => {
    if (!props.clientCommunityRelationship) {
        throw new Error(
            "Expected props.clientCommunityRelationship, but none was provided.",
        );
    }
    const { updateRelationship, relationship } = props.clientCommunityRelationship;
    return (
        <div>
            <div className="-mt-px flex divide-x divide-gray-200 cursor-pointer">
                {relationship === null || relationship === undefined ? (
                    <>
                        <div className="flex w-0 flex-1">
                            <a
                                onClick={e => {
                                    e.preventDefault();
                                    updateRelationship({
                                        action: "updateRelationship",
                                        relationship: CommunityRelationshipType.featured,
                                    });
                                }}
                                className="relative inline-flex w-0 flex-1 items-center justify-center rounded-bl-lg border border-transparent py-4 text-sm font-medium text-brand-600 hover:bg-brand-100 hover:text-brand-800 twoverride">
                                <span className="ml-3">Add as potential match</span>
                            </a>
                        </div>
                        <div className="-ml-px flex w-0 flex-1">
                            <a
                                onClick={e => {
                                    e.preventDefault();
                                    updateRelationship({
                                        action: "updateRelationship",
                                        relationship: CommunityRelationshipType.excluded,
                                    });
                                }}
                                className="relative inline-flex w-0 flex-1 items-center justify-center rounded-br-lg border border-transparent py-4 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800 twoverride">
                                <span className="ml-3">Exclude community</span>
                            </a>
                        </div>
                    </>
                ) : null}
                {relationship === CommunityRelationshipType.featured ||
                relationship === CommunityRelationshipType.matched ? (
                    <>
                        <div className="flex w-0 flex-1">
                            <a
                                onClick={e => {
                                    e.preventDefault();
                                    updateRelationship({
                                        action: "removeRelationship",
                                    });
                                }}
                                className="relative inline-flex w-0 flex-1 items-center justify-center rounded-bl-lg border border-transparent py-4 text-sm font-medium text-brand-600 bg-brand-100 hover:bg-gray-100 hover:text-gray-800 twoverride">
                                <CheckCircleIcon className="w-4 h-4" />
                                <span className="ml-3">Potential match</span>
                            </a>
                        </div>
                        <div className="-ml-px flex w-0 flex-1">
                            <a
                                onClick={e => {
                                    e.preventDefault();
                                    updateRelationship({
                                        action: "updateRelationship",
                                        relationship: CommunityRelationshipType.excluded,
                                    });
                                }}
                                className="relative inline-flex w-0 flex-1 items-center justify-center rounded-br-lg border border-transparent py-4 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800 twoverride">
                                <span className="ml-3">Exclude community</span>
                            </a>
                        </div>
                    </>
                ) : null}
                {relationship === CommunityRelationshipType.excluded ? (
                    <>
                        <div className="flex w-0 flex-1">
                            <a
                                onClick={e => {
                                    e.preventDefault();
                                    updateRelationship({
                                        action: "removeRelationship",
                                    });
                                }}
                                className="relative inline-flex w-0 flex-1 items-center justify-center rounded-bl-lg border border-transparent py-4 text-sm font-medium text-gray-400 bg-gray-100 hover:bg-gray-200 hover:text-gray-800 twoverride">
                                <NoSymbolIcon className="w-4 h-4" />
                                <span className="ml-3">Excluded</span>
                            </a>
                        </div>
                    </>
                ) : null}
            </div>
        </div>
    );
};
