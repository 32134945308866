import * as Common from "@sp-crm/core";
import {
    BadArgumentException,
    CalendarDate,
    ClientId,
    CustomListItemId,
    displayName,
    formatDateNoTime,
    Maybe,
} from "@sp-crm/core";
import { SortDown, SortUp } from "components/ui/icon";
import { Client, ReferralBasicsFragment } from "generated/graphql";
import React, { useMemo } from "react";
import { useHistory } from "react-router";
import { Column, Row, useSortBy, useTable } from "react-table";
import { useCustomList } from "store/selectors/bridge";
import { useTenantSettings } from "store/selectors/hooks";
import { ResponsiveMode } from "../../../store/reducers/responsive";
import { ClientPersonaLink } from "./client-persona-link";
import { IClientDataSource } from "./list-client-types";

interface Props {
    clients: ReferralBasicsFragment["client"][];
    showAssignedToColumn: boolean;
    users: { [id: string]: Common.User };
    responsiveMode?: ResponsiveMode;
    tableShort?: boolean;
    sourceType: IClientDataSource;
}

interface DisplayableClient {
    clientId: ClientId;
    primaryName: string;
    clientName: string;
    bestContactName: string;
    dateAdded: Date;
    statusListItemId: CustomListItemId;
    nextTask: string | null;
    nextTaskDueDate: Date | null;
    assignedTo: string | null;
    moveIn: CalendarDate | null;
    moveClassification: null | "estimated" | "planned" | "actual";
    serial: number | null | undefined;
}

const caseInsensitiveAlpha =
    (accessor: keyof DisplayableClient) =>
    (rowA: Row<DisplayableClient>, rowB: Row<DisplayableClient>): number => {
        const a = Maybe.fromValue(rowA)
            .map(row => row.original)
            .map(r => r[accessor])
            .map(x => x.toString())
            .map(x => x.toLocaleLowerCase())
            .getOrElse("");
        const b = Maybe.fromValue(rowB)
            .map(row => row.original)
            .map(r => r[accessor])
            .map(x => x.toString())
            .map(x => x.toLocaleLowerCase())
            .getOrElse("");
        return a.localeCompare(b);
    };

const forgivingDate =
    (accessor: keyof DisplayableClient) =>
    (rowA: Row<DisplayableClient>, rowB: Row<DisplayableClient>): number => {
        const a = Maybe.fromValue(rowA)
            .map(row => row.original)
            .map(r => r[accessor] as Date)
            .map(x => x.getTime())
            .getOrElse(4099766400000);
        const b = Maybe.fromValue(rowB)
            .map(row => row.original)
            .map(r => r[accessor] as Date)
            .map(x => x.getTime())
            .getOrElse(4099766400000);
        return a - b;
    };

const forgivingCalendarDate =
    (accessor: keyof DisplayableClient) =>
    (rowA: Row<DisplayableClient>, rowB: Row<DisplayableClient>): number => {
        const a = Maybe.fromValue(rowA)
            .map(row => row.original)
            .map(r => r[accessor] as CalendarDate)
            .map(cd => cd.toDate())
            .map(x => x.getTime())
            .getOrElse(4099766400000);
        const b = Maybe.fromValue(rowB)
            .map(row => row.original)
            .map(r => r[accessor] as CalendarDate)
            .map(cd => cd.toDate())
            .map(x => x.getTime())
            .getOrElse(4099766400000);
        return a - b;
    };

const statusSort = (
    compareListItems: (a: CustomListItemId, b: CustomListItemId) => number,
): ((a: Row<DisplayableClient>, b: Row<DisplayableClient>) => number) => {
    return (rowA: Row<DisplayableClient>, rowB: Row<DisplayableClient>): number => {
        const a = Maybe.fromValue(rowA)
            .map(row => row.original)
            .map(r => r.statusListItemId)
            .getOrElse(null);

        const b = Maybe.fromValue(rowB)
            .map(row => row.original)
            .map(r => r.statusListItemId)
            .getOrElse(null);

        return compareListItems(a, b);
    };
};

export const ListClient: React.FC<Props> = props => {
    const { users } = props;
    const tenantSettings = useTenantSettings();
    const showClientFirst = tenantSettings.showClientFirst;
    const showClientSerial = tenantSettings.showClientSerial;
    const { getListItemName, compareListItems } = useCustomList(
        Common.CustomListKey.ClientStatus,
    );
    const clientToDisplayable = React.useCallback(
        (c: Client): DisplayableClient => {
            if (!c) {
                throw new BadArgumentException(
                    "ListClientModern.annotatedToDisplayable, client cannot be falsy",
                );
            }
            const nextTask = c.nextTask;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
            const moveDetails: any = { moveIn: null, moveClassification: null };
            if (c.moveTimeline) {
                moveDetails.moveClassification = "Estimated";
                moveDetails.moveIn = CalendarDate.parse(c.moveTimeline).getOrElse(null);
            }
            if (c.moveTimelineActual) {
                moveDetails.moveClassification = "Actual";
                moveDetails.moveIn = CalendarDate.parse(c.moveTimelineActual).getOrElse(
                    null,
                );
            }
            return {
                primaryName: showClientFirst ? c.name ?? "" : c.bestContactName || "",
                clientName: c.name ?? "",
                bestContactName: c.bestContactName ?? "",
                clientId: c.id,
                statusListItemId: c.statusListItemId,
                dateAdded: new Date(c.dateAdded),
                nextTask: nextTask?.text || "",
                nextTaskDueDate: nextTask?.compositeDueDateTime
                    ? new Date(nextTask.compositeDueDateTime)
                    : null,
                ...moveDetails,
                assignedTo:
                    users && users[c.assignedUserId]
                        ? displayName(users[c.assignedUserId])
                        : "unassigned",
                serial: c.serial && c.serial !== 0 ? c.serial : undefined,
            };
        },
        [showClientFirst, users],
    );

    const columns: Column<DisplayableClient>[] = React.useMemo(() => {
        const allColumns: (Column<DisplayableClient> & { show: boolean })[] = [
            { accessor: "serial", Header: "ID", show: showClientSerial },
            {
                show: true,
                accessor: "primaryName",
                Header: "Client",
                sortType: caseInsensitiveAlpha("primaryName"),
                Cell: myArgs => (
                    <ClientPersonaLink
                        client={{
                            appLink: `/clients/show/${myArgs.row.original.clientId}`,
                            name: myArgs.row.original.clientName,
                            bestContactName: myArgs.row.original.bestContactName,
                        }}
                    />
                ),
            },
            {
                show: true,
                accessor: "dateAdded",
                Cell: myArgs => <div>{formatDateNoTime(myArgs.cell.value)}</div>,
                Header: "Date Created",
                sortType: forgivingDate("dateAdded"),
            },
            {
                show: true,
                accessor: "statusListItemId",
                Header: "Status",
                sortType: statusSort(compareListItems),
                Cell: myArgs => <div>{getListItemName(myArgs.value)}</div>,
            },
            {
                show: true,
                accessor: "nextTaskDueDate",
                Cell: myArgs => (
                    <div>
                        <div className="text-sm truncate text-gray-900">
                            {myArgs.row.original.nextTask}
                        </div>
                        <div className="text-sm text-gray-500">
                            {formatDateNoTime(myArgs.value)}
                        </div>
                    </div>
                ),
                Header: "Next Task",
                sortType: forgivingDate("nextTaskDueDate"),
            },
            {
                show: true,
                accessor: "moveIn",
                Cell: myArgs => (
                    <div>
                        <div className="text-sm text-gray-900">
                            {myArgs.cell.value?.humanFriendly()}
                        </div>
                        <div className="text-sm text-gray-500">
                            {myArgs.row.original.moveClassification}
                        </div>
                    </div>
                ),
                Header: "Target Move-In",
                sortType: forgivingCalendarDate("moveIn"),
            },
            {
                show: true,
                accessor: "assignedTo",
                Header: "Assigned to",
            },
        ];
        return allColumns.filter(x => x.show) as Column<DisplayableClient>[];
    }, [getListItemName, showClientSerial, compareListItems]);
    const memoizedClients = useMemo(
        () => props.clients.map(clientToDisplayable),
        [props.clients, clientToDisplayable],
    );
    const initialState = {
        sortBy: [
            {
                id: "dateAdded",
                desc: true,
            },
        ],
    };
    const { getTableBodyProps, getTableProps, headerGroups, rows, prepareRow } =
        useTable<DisplayableClient>(
            { columns, data: memoizedClients, initialState },
            useSortBy,
        );

    const router = useHistory();

    return (
        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table {...getTableProps()} className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                    {headerGroups.map(headerGroup => (
                        // eslint-disable-next-line react/jsx-key
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                // eslint-disable-next-line react/jsx-key
                                <th
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                                    {...column.getHeaderProps(
                                        column.getSortByToggleProps(),
                                    )}>
                                    <div className="flex items-center">
                                        <span className="-ml-4 mr-1 w-3">
                                            {column.isSorted ? (
                                                column.isSortedDesc ? (
                                                    <SortDown />
                                                ) : (
                                                    <SortUp />
                                                )
                                            ) : (
                                                ""
                                            )}
                                        </span>
                                        <span>{column.render("Header")}</span>
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody
                    {...getTableBodyProps()}
                    className="bg-white divide-y divide-gray-200">
                    {rows.map(row => {
                        prepareRow(row);
                        return (
                            // eslint-disable-next-line react/jsx-key
                            <tr
                                onClick={() =>
                                    router.push(`/clients/show/${row.original.clientId}`)
                                }
                                {...row.getRowProps()}
                                className={`hover:bg-gray-100`}>
                                {row.cells.map(cell => {
                                    return (
                                        // eslint-disable-next-line react/jsx-key
                                        <td
                                            {...cell.getCellProps()}
                                            className="px-6 py-4 truncate max-w-xs">
                                            {cell.render("Cell")}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

declare module "react-table" {
    // take this file as-is, or comment out the sections that don't apply to your plugin configuration

    interface TableOptions<D extends object>
        extends UseExpandedOptions<D>,
            UseGroupByOptions<D>,
            UsePaginationOptions<D>,
            UseResizeColumnsOptions<D>,
            UseRowSelectOptions<D>,
            UseRowStateOptions<D>,
            UseSortByOptions<D> {}

    // eslint-disable-next-line @typescript-eslint/ban-types
    interface TableInstance<D extends object = {}>
        extends UseColumnOrderInstanceProps<D>,
            UseExpandedInstanceProps<D>,
            UseGroupByInstanceProps<D>,
            UsePaginationInstanceProps<D>,
            UseRowSelectInstanceProps<D>,
            UseRowStateInstanceProps<D>,
            UseSortByInstanceProps<D> {
        editable: boolean;
    }

    // eslint-disable-next-line @typescript-eslint/ban-types
    interface TableState<D extends object = {}>
        extends UseColumnOrderState<D>,
            UseExpandedState<D>,
            UseGroupByState<D>,
            UsePaginationState<D>,
            UseResizeColumnsState<D>,
            UseRowSelectState<D>,
            UseRowStateState<D>,
            UseSortByState<D> {}

    // eslint-disable-next-line @typescript-eslint/ban-types
    interface ColumnInterface<D extends object = {}>
        extends UseGroupByColumnOptions<D>,
            UseResizeColumnsColumnOptions<D>,
            UseSortByColumnOptions<D> {}

    // eslint-disable-next-line @typescript-eslint/ban-types
    interface ColumnInstance<D extends object = {}>
        extends UseGroupByColumnProps<D>,
            UseResizeColumnsColumnProps<D>,
            UseSortByColumnProps<D> {}

    // eslint-disable-next-line @typescript-eslint/ban-types
    interface Cell<D extends object = {}>
        extends UseGroupByCellProps<D>,
            UseRowStateCellProps<D> {}

    // eslint-disable-next-line @typescript-eslint/ban-types
    interface Row<D extends object = {}>
        extends UseExpandedRowProps<D>,
            UseGroupByRowProps<D>,
            UseRowSelectRowProps<D>,
            UseRowStateRowProps<D> {}
}
