import { IClient } from "@sp-crm/core";
import React, { FunctionComponent } from "react";
import { Header } from "../../../header";
import Details from "./details";

type SingleClientProps = {
    client: IClient;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    onFieldChange: (fieldName: string, newValue: any) => void;
};

const SingleClient: FunctionComponent<SingleClientProps> = ({
    client,
    onFieldChange,
}) => {
    return (
        <div className="input-form-block-no-bottom-margin">
            <Header iconName="53_insurance">Health Insurance Details</Header>
            <Details client={client} onFieldChange={onFieldChange} />
        </div>
    );
};

export default SingleClient;
