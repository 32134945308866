import { StarIcon } from "@heroicons/react/20/solid";
import React from "react";

interface RatingProps {
    rating: number;
    onChange: (rating: number) => void;
}

export const Rating: React.FC<RatingProps> = props => {
    const [hoveredIndex, setHoveredIndex] = React.useState<number | null>(null);
    return (
        <div className="flex flex-row items-center flex-nowrap">
            {[1, 2, 3, 4, 5].map((_, index) => {
                return (
                    <StarIcon
                        key={index}
                        className={`h-5 w-5  cursor-pointer ${
                            hoveredIndex === index
                                ? "text-yellow-500"
                                : index < (hoveredIndex ?? props.rating)
                                ? "text-yellow-400"
                                : "text-gray-300"
                        }`}
                        onClick={() => props.onChange(index + 1)}
                        onMouseEnter={() => setHoveredIndex(index)}
                        onMouseLeave={() => setHoveredIndex(null)}
                    />
                );
            })}
        </div>
    );
};

interface RatingDisplayProps {
    rating: number;
}

export const RatingDisplay: React.FC<RatingDisplayProps> = props => {
    return (
        <div className="flex flex-row items-center flex-nowrap">
            {Array.from({ length: props.rating }, (_, index) => (
                <StarIcon key={index} className="h-5 w-5 text-yellow-500" />
            ))}
        </div>
    );
};
