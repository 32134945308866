import { CalendarDate, CustomListKey, Maybe } from "@sp-crm/core";
import { ClientSearch } from "components/clients/client-search/client-search";
import { Badge } from "components/ui/badge";
import { Spinner } from "components/ui/spinner";
import {
    DashboardDefaultsFragment,
    GetClientsForTimelineQuery,
    GetCustomListQuery,
    useGetClientsForTimelineQuery,
} from "generated/graphql";
import React, { useCallback, useMemo } from "react";
import { useCustomList } from "store/selectors/bridge";
import { usePipelineState } from "store/selectors/hooks";
import { ClientCardv2 } from "./card";
import { DashboardNavigation } from "./dashboard-navigation";
import { GlobalClientSearchCriteria } from "./types";

const moveTimelineTitles = {
    pastDue: "Past Due",
    oneWeek: "Next 7 Days",
    oneToTwoWeeks: "1-2 weeks",
    twoToFourWeeks: "2-4 weeks",
    fourOrMoreWeeks: "4+ weeks",
    unscheduled: "Unscheduled",
};

interface TimelineV2ColumnProps {
    moveTimelineWindow: string;
    title: string;
    isLoading: boolean;
    results: GetClientsForTimelineQuery["pastDue"];
    showAssignedTo: boolean;
    getListItem: (
        idOrKey: string,
    ) => GetCustomListQuery["getCustomList"]["items"][0] | null;
}

const getMoveDate = (client: DashboardDefaultsFragment["clients"][0]) => {
    if (client.moveTimelineActual) {
        return CalendarDate.parse(client.moveTimelineActual)
            .map(c => c.dayEnd())
            .getOrElse(null);
    }
    if (client.moveTimeline) {
        return CalendarDate.parse(client.moveTimeline)
            .map(c => c.dayEnd())
            .getOrElse(null);
    }
    return null;
};

const sortByMoveDate = (
    a: DashboardDefaultsFragment["clients"][0],
    b: DashboardDefaultsFragment["clients"][0],
) => {
    const moveDateA = getMoveDate(a);
    const moveDateB = getMoveDate(b);

    if (!moveDateA && !moveDateB) return 0;

    if (!moveDateA) return -1;

    if (!moveDateB) return 1;

    return moveDateA.valueOf() - moveDateB.valueOf();
};

const TimelineV2Column: React.FC<TimelineV2ColumnProps> = props => {
    const { moveTimelineWindow, title, isLoading, results, showAssignedTo, getListItem } =
        props;

    const unmovedClients = useMemo(() => {
        return results.clients.sort((a, b) => sortByMoveDate(a, b));
    }, [results.clients]);

    const getMoveStatusDescription = useCallback(
        (client: DashboardDefaultsFragment["clients"][0]) => {
            if (client.moveTimelineActual) {
                return {
                    descriptor: "Move Date",
                    date: CalendarDate.parse(client.moveTimelineActual),
                };
            }

            if (client.moveTimeline) {
                return {
                    descriptor: "Estimated Move",
                    date: CalendarDate.parse(client.moveTimeline),
                };
            }

            const listItem = getListItem(client.statusListItemId);
            if (listItem?.pipelineDateField) {
                const typedDatedField =
                    listItem.pipelineDateField as keyof DashboardDefaultsFragment["clients"][0];

                return {
                    descriptor: listItem.pipelineDateLabel || "",
                    date: Maybe.fromValue(client?.[typedDatedField]).flatMap(v =>
                        CalendarDate.parse(v),
                    ),
                };
            } else {
                return {
                    descriptor: "",
                    date: Maybe.none<CalendarDate>(),
                };
            }
        },
        [getListItem],
    );

    return (
        <div className="col pipeline-stage" key={moveTimelineWindow}>
            <div className="pipeline-innards timeline-view">
                {/* Extra div wrapper below is necessary for some reason to handle the overflow-y scrollbar
            properly if it ever renders for the pipeline-content div. */}
                <div>
                    <div className={"column-status status-" + moveTimelineWindow} />
                    <div className="pipeline-header">
                        <div className="pipeline-header-title">{title}</div>
                        <div className="pipeline-header-badge">
                            {isLoading ? (
                                <Spinner />
                            ) : (
                                <Badge type="info" value={unmovedClients.length} />
                            )}
                        </div>
                    </div>
                </div>
                <div className="pipeline-content">
                    {unmovedClients.map(c => (
                        <ClientCardv2
                            key={c.id}
                            client={c}
                            highlightCard={false}
                            pendingCard={false}
                            getClientStatus={getMoveStatusDescription}
                            showAssignedTo={showAssignedTo}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

interface TimelineV2BoardProps {
    searchCriteria: GlobalClientSearchCriteria;
}

const TimelineV2Board: React.FC<TimelineV2BoardProps> = props => {
    const { searchCriteria } = props;

    const { customList, getListItem } = useCustomList(CustomListKey.ClientStatus);

    const statusIds = useMemo(() => {
        const movedItem = getListItem("moved");
        const checkLaterItem = getListItem("checkLater");

        if (!movedItem || !checkLaterItem || !customList) {
            return [];
        }

        return customList.items
            .filter(i => {
                return i.order < movedItem.order || i.id === checkLaterItem.id;
            })
            .map(i => i.id);
    }, [customList, getListItem]);

    const clientsQuery = useGetClientsForTimelineQuery(
        {
            ...searchCriteria,
            includeStatuses: statusIds,
        },
        {
            enabled: !!searchCriteria && statusIds.length > 0,
            keepPreviousData: true,
        },
    );

    return (
        <div className="pipeline row main-body">
            {Object.keys(moveTimelineTitles).map(k => {
                const typedKey = k as keyof typeof moveTimelineTitles;
                return (
                    <TimelineV2Column
                        key={typedKey}
                        moveTimelineWindow={typedKey}
                        title={moveTimelineTitles[typedKey]}
                        results={
                            clientsQuery.data && clientsQuery.data[typedKey]
                                ? clientsQuery.data[typedKey]
                                : { clients: [], total: 0 }
                        }
                        isLoading={!clientsQuery.data}
                        showAssignedTo={typeof searchCriteria.assignedUserId !== "string"}
                        getListItem={getListItem}
                    />
                );
            })}
        </div>
    );
};

export const TimelineV2: React.FC<unknown> = props => {
    const pipelineState = usePipelineState();

    return (
        <div className="flex flex-col md:inset-0 md:absolute">
            <div>
                <DashboardNavigation />
            </div>
            <div className="pipeline-wrapper flex-1 h-screen md:h-auto md:overflow-hidden">
                <ClientSearch />
                {pipelineState && pipelineState.isReady ? (
                    <TimelineV2Board searchCriteria={pipelineState.searchCriteria} />
                ) : null}
            </div>
        </div>
    );
};
