import { UserGroupId, UserId } from "@sp-crm/core";
import { Radio } from "components/ui/radio";
import { UserAudience, UserAudienceType } from "generated/graphql";
import React from "react";
import { useVisibleUsers } from "store/selectors/hooks";
import { MultiGroupSelect } from "./multi-group-select";
import { MultiUserSelect } from "./multi-user-select";

interface UserAudienceSelectorProps {
    userAudience: UserAudience;
    onChange: (userAudience: UserAudience) => void;
}

export const UserAudienceSelector: React.FC<UserAudienceSelectorProps> = props => {
    const { userAudience, onChange } = props;

    const [type, setType] = React.useState<UserAudienceType>(userAudience.type);
    const [specificGroupIds, setSpecificGroupIds] = React.useState<UserGroupId[]>(
        userAudience.specific?.groupIds ?? [],
    );
    const [specificUserIds, setSpecificUserIds] = React.useState<UserId[]>(
        userAudience.specific?.userIds ?? [],
    );
    const users = useVisibleUsers();

    return (
        <div className="space-y-1">
            <p>Recipients</p>
            <Radio
                checked={type === UserAudienceType.Everyone}
                onChange={() => {
                    setType(UserAudienceType.Everyone);
                    onChange({ type: UserAudienceType.Everyone });
                }}
                label="Everyone"
            />
            <Radio
                checked={userAudience.type === UserAudienceType.Specific}
                onChange={() => {
                    setType(UserAudienceType.Specific);
                    onChange({
                        type: UserAudienceType.Specific,
                        specific: {
                            groupIds: specificGroupIds,
                            userIds: specificUserIds,
                        },
                    });
                }}
                label="Specific Users and Groups"
            />
            {type === UserAudienceType.Specific ? (
                <div className="ml-4 space-y-1">
                    <p>Groups</p>
                    <MultiGroupSelect
                        value={specificGroupIds}
                        onChange={ids => {
                            setSpecificGroupIds(ids);
                            onChange({
                                type: UserAudienceType.Specific,
                                specific: {
                                    groupIds: ids,
                                    userIds: specificUserIds,
                                },
                            });
                        }}
                    />
                    <MultiUserSelect
                        label="Users"
                        value={specificUserIds}
                        onChange={ids => {
                            setSpecificUserIds(ids);
                            onChange({
                                type: UserAudienceType.Specific,
                                specific: {
                                    groupIds: specificGroupIds,
                                    userIds: ids,
                                },
                            });
                        }}
                        viewableUsersMap={users}
                    />
                </div>
            ) : null}
        </div>
    );
};
