import { ActionType } from "@sp-crm/core";
import { ContentContainer } from "components/layout";
import { SubnavMenu, SubnavMenuItem } from "components/navigation/subnav";
import React from "react";
import { useIsAllowedInWholeTenant } from "store/selectors/hooks";

export const SettingsNavigation: React.FC<unknown> = () => {
    const editAgencySettingsAllowed = useIsAllowedInWholeTenant(ActionType.UpdateTenant);
    const manageUsersAllowed = useIsAllowedInWholeTenant(ActionType.SelfManageModifyUser);
    return (
        <ContentContainer className="mb-4">
            <SubnavMenu>
                <SubnavMenuItem to="/settings/account">My settings</SubnavMenuItem>
                <SubnavMenuItem to="/settings/templates">
                    Email Templates & Signatures
                </SubnavMenuItem>
                {editAgencySettingsAllowed ? (
                    <SubnavMenuItem to="/settings/agency">Agency settings</SubnavMenuItem>
                ) : null}
                {manageUsersAllowed ? (
                    <>
                        <SubnavMenuItem to="/settings/users">
                            User accounts
                        </SubnavMenuItem>
                        <SubnavMenuItem to="/settings/integrations">
                            Integrations
                        </SubnavMenuItem>
                    </>
                ) : null}
            </SubnavMenu>
        </ContentContainer>
    );
};
