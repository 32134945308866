import { IQuestion } from "@sp-crm/core";
import { fancyConfirm } from "components/ui/fancy-confirm";
import { Input } from "components/ui/input";
import { SecondaryButton } from "components/ui/secondary-button";
import * as React from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from "store/state";

interface EditKeyProps {
    onChange: (key: string) => void;
    question: IQuestion;
}

const checkIsUsed = (key: string, questions: IQuestion[]) => {
    return questions.some(
        q =>
            (q.legacyClientRules && q.legacyClientRules.dependsOnKey(key)) ||
            (q.legacyCommunityRules && q.legacyCommunityRules.dependsOnKey(key)),
    );
};

export const EditKey: React.FC<EditKeyProps> = props => {
    const { question, onChange } = props;
    const questions = useSelector(
        (state: ApplicationState) => state.settings.legacyQuestions,
    );
    const [isEditing, setIsEditing] = React.useState(false);

    const isUsed = checkIsUsed(question.key, questions);

    const safeEdit = () => {
        if (!isUsed) {
            setIsEditing(true);
        } else {
            (async () => {
                const shouldEnable = await fancyConfirm(
                    "Key in use",
                    `This key is already in use. Are you sure you want to change it?`,
                    "Change",
                    "Cancel",
                );
                if (shouldEnable) {
                    setIsEditing(true);
                }
            })();
        }
    };
    return (
        <div className="space-y-2">
            <div className="flex space-x-2 items-center">
                <div>Key</div>
                <div className="text-bold">
                    {question.key && question.key !== "" ? (
                        <pre>{question.key}</pre>
                    ) : (
                        <em>no key set</em>
                    )}
                </div>
            </div>
            <div className="flex space-x-2 items-center">
                <div>In use?</div>
                <div className="text-bold">{isUsed ? "Yes" : "No"}</div>
            </div>
            <div>
                {isEditing ? (
                    <Input
                        onChange={e => onChange(e.target.value)}
                        value={question.key}
                        label="Key"
                    />
                ) : (
                    <SecondaryButton onClick={safeEdit}>Edit</SecondaryButton>
                )}
            </div>
        </div>
    );
};
