import { TenantRegion, getAggregateFieldTitle, getEntityField } from "@sp-crm/core";
import { DefaultTable } from "components/table/default-table";
import { CrmTablePagination } from "components/table/pagination";
import { SortDown, SortUp } from "components/ui/icon";
import {
    AdvancedSearchAggregate,
    AdvancedSearchRequest,
    AdvancedSearchRequestSummaryInput,
    AdvancedSearchResultSummary,
    AdvancedSearchTotalResult,
    BridgeEntityResult,
    BridgeFieldResult,
    BridgeRelationResult,
} from "generated/graphql";
import { produce } from "immer";
import React, { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { useRegions, useUsers } from "store/selectors/hooks";
import { UserMap } from "store/selectors/users";

interface AdvancedSearchSummaryProps {
    request: AdvancedSearchRequest;
    onRequestChange: (request: AdvancedSearchRequest) => void;
    summaries: AdvancedSearchResultSummary[];
    entityMetadata: BridgeEntityResult;
}

export const AdvancedSearchSummary: React.FC<AdvancedSearchSummaryProps> = props => {
    const { request, summaries, entityMetadata, onRequestChange } = props;

    const handleSummaryRequestChange = useCallback(
        (summaryRequest: AdvancedSearchRequestSummaryInput, index: number) => {
            const newRequest = produce(request, draft => {
                draft.summaries[index] = summaryRequest;
            });

            onRequestChange(newRequest);
        },
        [request, onRequestChange],
    );

    if (
        !summaries ||
        summaries.length === 0 ||
        !request.summaries ||
        request.summaries.length === 0
    ) {
        return null;
    }

    return (
        <ul>
            {summaries.map((summary, index) => (
                <li key={index}>
                    <AdvancedSearchSummaryItem
                        summary={summary}
                        summaryRequest={request.summaries[index]}
                        index={index}
                        entityMetadata={entityMetadata}
                        onSummaryRequestChange={handleSummaryRequestChange}
                    />
                </li>
            ))}
        </ul>
    );
};

interface AdvancedSearchAggregateHeaderProps {
    field: BridgeFieldResult;
    request: AdvancedSearchRequestSummaryInput;
    aggregate: AdvancedSearchAggregate;
    index: number;
    onClick: (aggregate: AdvancedSearchAggregate, index: number) => void;
}

const AdvancedSearchAggregateHeader: React.FC<
    AdvancedSearchAggregateHeaderProps
> = props => {
    const { field, onClick, aggregate, index, request } = props;

    const handleClick = useCallback(
        (e: React.MouseEvent) => {
            e.preventDefault();
            onClick(aggregate, index);
        },
        [onClick, aggregate, index],
    );

    const title = getAggregateFieldTitle(field, aggregate.operator);
    const showSort = request.sort?.aggregateIndex === index;

    return (
        <a href="#" onClick={handleClick}>
            <p className="text-gray-900 flex items-center space-x-2">
                <span>{title}</span>
                {showSort ? request.sort?.ascending ? <SortUp /> : <SortDown /> : null}
            </p>
        </a>
    );
};

const aggregateToTableColumn = (
    aggregate: AdvancedSearchAggregate,
    index: number,
    entityMetadata: BridgeEntityResult,
    request: AdvancedSearchRequestSummaryInput,
    total: AdvancedSearchTotalResult,
    onHeaderClick: (aggregate: AdvancedSearchAggregate, index: number) => void,
): { key: string; header: JSX.Element; footer: string } => {
    const field = getEntityField(entityMetadata, aggregate.column);

    const header = (
        <AdvancedSearchAggregateHeader
            field={field}
            aggregate={aggregate}
            index={index}
            request={request}
            onClick={onHeaderClick}
        />
    );

    return {
        key: `aggregate-${index}`,
        header,
        ...(total?.formattedValue ? { footer: total.formattedValue } : null),
    };
};

const summaryGroupToTableRow = (
    group: AdvancedSearchResultSummary["groups"][0],
    groupByFields: (BridgeFieldResult | BridgeRelationResult)[],
    regions: TenantRegion[],
    users: UserMap,
): Record<string, unknown> => {
    let groupValue = "";

    if (groupByFields.length > 0) {
        groupValue = group.groupKey?.[0]?.formattedValue || "(blank)";
    }

    return {
        groupValue,
        ...group.aggregates.reduce(
            (acc, aggregate, index) => ({
                ...acc,
                [`aggregate-${index}`]: aggregate.formattedValue,
            }),
            {},
        ),
        appLink: group.groupKey?.[0]?.appLink,
    };
};

const summaryGroupsToTableRows = (
    groups: AdvancedSearchResultSummary["groups"],
    groupByFields: (BridgeFieldResult | BridgeRelationResult)[],
    regions: TenantRegion[],
    users: UserMap,
): Record<string, unknown>[] => {
    if (groupByFields.length > 0) {
        return groups.map(g => summaryGroupToTableRow(g, groupByFields, regions, users));
    }

    return [];
};

interface AdvancedSearchSummaryItemProps {
    summary: AdvancedSearchResultSummary;
    summaryRequest: AdvancedSearchRequest["summaries"][0];
    index: number;
    entityMetadata: BridgeEntityResult;
    onSummaryRequestChange: (
        summaryRequest: AdvancedSearchRequestSummaryInput,
        index: number,
    ) => void;
}

const AdvancedSearchSummaryItem: React.FC<AdvancedSearchSummaryItemProps> = props => {
    const { summary, summaryRequest, entityMetadata, onSummaryRequestChange, index } =
        props;

    const userMap = useUsers().users;
    const regions = useRegions();

    const handleHeaderClick = useCallback(
        (_aggregate: AdvancedSearchAggregate, aggregateIndex: number) => {
            const newSummaryRequest = produce(summaryRequest, draft => {
                const sort = draft.sort;

                if (!sort || sort.aggregateIndex !== aggregateIndex) {
                    draft.sort = {
                        aggregateIndex,
                        ascending: false,
                    };
                } else {
                    sort.ascending = !sort.ascending;
                }
            });

            onSummaryRequestChange(newSummaryRequest, index);
        },
        [summaryRequest, onSummaryRequestChange, index],
    );

    const changePage = useCallback(
        (pageIndex: number) => {
            const newSummaryRequest = produce(summaryRequest, draft => {
                draft.page = pageIndex;
            });

            onSummaryRequestChange(newSummaryRequest, index);
        },
        [summaryRequest, onSummaryRequestChange, index],
    );

    const changePageSize = useCallback(
        (pageSize: number) => {
            const newSummaryRequest = produce(summaryRequest, draft => {
                draft.pageSize = pageSize;
            });

            onSummaryRequestChange(newSummaryRequest, index);
        },
        [summaryRequest, onSummaryRequestChange, index],
    );

    const groupByFields = useMemo(() => {
        let result: (BridgeFieldResult | BridgeRelationResult)[] = [];

        if (summaryRequest.groupBy?.length > 0) {
            result = summaryRequest.groupBy.map(groupBy => {
                return (
                    getEntityField(entityMetadata, groupBy) ||
                    entityMetadata.relations.find(r => r.name === groupBy) ||
                    null
                );
            });
        }

        return result;
    }, [summaryRequest, entityMetadata]);

    const tableColumns = useMemo(() => {
        return [
            groupByFields.length > 0
                ? {
                      key: "groupValue",
                      header: groupByFields?.[0]?.title || "",
                      footer: "Total",
                      renderCell: (props: { record: Record<string, unknown> }) => {
                          if (props.record.appLink) {
                              return (
                                  <Link to={props.record.appLink}>
                                      {props.record.groupValue as string}
                                  </Link>
                              );
                          }

                          return <>{props.record.groupValue}</>;
                      },
                  }
                : null,
            ...summaryRequest.aggregates.map((a, index) =>
                aggregateToTableColumn(
                    a,
                    index,
                    entityMetadata,
                    summaryRequest,
                    summary.totals[index],
                    handleHeaderClick,
                ),
            ),
        ].filter(x => !!x);
    }, [
        summaryRequest,
        entityMetadata,
        groupByFields,
        summary.totals,
        handleHeaderClick,
    ]);

    const tableRows = [
        ...summaryGroupsToTableRows(summary.groups, groupByFields, regions, userMap),
    ];

    const page = summaryRequest.page || 0;
    const pageSize = summaryRequest.pageSize || 25;

    return (
        <div>
            <DefaultTable columns={tableColumns} data={tableRows} useSort={false} />
            {summary.groupTotal > pageSize ? (
                <CrmTablePagination
                    pageIndex={page}
                    count={summary.groupTotal}
                    pageSize={pageSize}
                    pageCount={Math.ceil(summary.groupTotal / pageSize)}
                    canNextPage={page < Math.ceil(summary.groupTotal / pageSize) - 1}
                    canPreviousPage={page > 0}
                    nextPage={() => changePage(page + 1)}
                    previousPage={() => changePage(page - 1)}
                    gotoPage={changePage}
                    setPageSize={changePageSize}
                    allowAll={false}
                />
            ) : null}
        </div>
    );
};
