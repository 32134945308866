import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Callout } from "components/ui/callout";
import * as React from "react";

interface StatelessSearchbarItemProps {
    content: JSX.Element;
    children: React.ReactNode;
}

export const StatelessSearchbarItemContext = React.createContext(() => {
    /* nop */
});

export const StatelessSearchbarItem: React.FC<StatelessSearchbarItemProps> = props => {
    const { content, children } = props;
    const [isOpen, setIsOpen] = React.useState(false);
    const close = React.useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);
    const open = React.useCallback(() => {
        setIsOpen(true);
    }, [setIsOpen]);

    const renderButton = React.useCallback(() => {
        return (
            <div className="flex items-center space-x-1 cursor-pointer text-gray-700">
                <div className="text-lg">{content}</div>
                <ChevronDownIcon className="w-5 h-5 text-gray-500" />
            </div>
        );
    }, [content]);

    return (
        <div className="searchbar-item-component">
            <Callout renderButton={renderButton} onOpen={open} forceClose={!isOpen}>
                <StatelessSearchbarItemContext.Provider value={close}>
                    {children}
                </StatelessSearchbarItemContext.Provider>
            </Callout>
        </div>
    );
};
