import { EnvelopeIcon } from "@heroicons/react/24/outline";
import { defaultLinkStyle } from "components/ui/link";
import { SavedQueryScheduleResult } from "generated/graphql";
import React from "react";
import { useFeature } from "store/selectors/hooks";

interface AdvancedSearchScheduleButtonProps {
    schedule: SavedQueryScheduleResult | null;
    onClick: () => void;
}

export const AdvancedSearchScheduleButton: React.FC<
    AdvancedSearchScheduleButtonProps
> = props => {
    const { schedule, onClick } = props;

    const schedulingEnabled = useFeature("customReportsSchedule");

    const handleClick = React.useCallback(
        async (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
            e.preventDefault();
            onClick();
        },
        [onClick],
    );

    if (!schedulingEnabled) {
        return null;
    }

    return (
        <div>
            <a
                href="#"
                className={`${defaultLinkStyle} flex items-center space-x-2`}
                onClick={handleClick}>
                <EnvelopeIcon className={`h-4 w-4`} />
                <div>{schedule ? `Change email schedule` : `Schedule email`}</div>
            </a>
        </div>
    );
};
