import { ClientId, formatCurrency } from "@sp-crm/core";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useGetClientQuery } from "../../../../generated/graphql";
import { Col } from "../../../shared/grid";

interface Props {
    clientId: ClientId;
}

export const InvoiceSummary: React.FC<Props> = props => {
    const queryKey = { id: props.clientId };
    const router = useHistory();

    const navigate = React.useCallback(() => {
        router.push(`/clients/show/${props.clientId}/invoices`);
    }, [router, props.clientId]);
    const query = useGetClientQuery(queryKey);
    if (
        query.data?.getClient?.invoices?.length !== 0 ||
        query.data?.getClient?.totalInvoiceAmountCents !== 0
    ) {
        const invoiceCount = (query.data?.getClient?.invoices || []).filter(
            i => i.status !== "void",
        ).length;
        return (
            <Col className="widget">
                <div className="action-bar-component" onClick={navigate}>
                    <div className="action-bar-label">Invoice</div>
                    <div className="action-bar-value">
                        <Link to={`/clients/show/${props.clientId}/invoices`}>
                            {formatCurrency(
                                (query.data?.getClient?.totalInvoiceAmountCents ?? 0) /
                                    100,
                            )}
                            {invoiceCount > 1 ? (
                                <>
                                    &nbsp;
                                    <span>({invoiceCount} invoices)</span>
                                </>
                            ) : null}
                        </Link>
                    </div>
                </div>
            </Col>
        );
    }
    return null;
};
