import {
    CommunityId,
    ILocation,
    getDistanceLatLng,
    isValidLocation,
    locationToCoordinates,
    makeLocation,
} from "@sp-crm/core";
import { ClientGraphqlError } from "backend/fetcher";
import {
    SearchResultsMapCluster,
    SearchResultsMapMarker,
} from "components/community-search/community-map/map-marker";
import {
    HoveredMapEntity,
    MapMarkerId,
} from "components/community-search/community-map/types";
import { Error } from "components/error";
import { GeoCenter, MapLoadingIndicator, useMapEvents } from "components/map/map-common";
import { InlineBanner } from "components/ui/inline-banner";
import { defaultLinkStyle } from "components/ui/link";
import { SecondaryButton } from "components/ui/secondary-button";
import { Spinner } from "components/ui/spinner";
import { BoundsInput } from "generated/graphql";
import { produce } from "immer";
import maplibregl, { LngLat, LngLatBounds } from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import React from "react";
import Map, {
    FullscreenControl,
    GeolocateControl,
    LngLatBoundsLike,
    MapRef,
    NavigationControl,
    Popup,
    ScaleControl,
} from "react-map-gl";
import { Link } from "react-router-dom";
import { useWindowSize } from "react-use";
import { NO_NAME } from "util/text";
import {
    MapSearchCluster,
    MapSearchCoordinate,
    MapSearchResults,
    MapView,
} from "./types";

const latLngToILocation = (latLng: LngLat | mapboxgl.LngLat): ILocation =>
    makeLocation(latLng.lat, latLng.lng);

const defaultZoomLevel = 5;

interface ComponentState {
    isFreeMapView: boolean;
    showUpdateSearch: boolean;
    isLoading: boolean;
    bounds: BoundsInput | null;
    popupId: MapMarkerId | null;
    zoomLevel: number | null;
}

type Action =
    | { type: "endUserMapMove"; bounds: BoundsInput; zoomLevel: number }
    | { type: "endAutoMapMove" }
    | { type: "disableFreeMap" }
    | { type: "startExplicitBoundsSearch" }
    | { type: "startLoading" }
    | { type: "endLoading" }
    | { type: "clearPopup" }
    | { type: "setPopup"; popupId: MapMarkerId }
    | { type: "setZoom"; zoom: number };

const reducer: React.Reducer<ComponentState, Action> = (state, action): ComponentState =>
    produce(state, draft => {
        if (action.type === "endUserMapMove") {
            draft.isFreeMapView = true;
            draft.bounds = action.bounds;
            draft.showUpdateSearch = true;
            draft.zoomLevel = action.zoomLevel;
        } else if (action.type === "endAutoMapMove") {
            draft.isFreeMapView = false;
        } else if (action.type === "disableFreeMap") {
            draft.isFreeMapView = false;
        } else if (action.type === "startExplicitBoundsSearch") {
            draft.showUpdateSearch = false;
        } else if (action.type === "startLoading") {
            draft.isLoading = true;
        } else if (action.type === "endLoading") {
            draft.isLoading = false;
        } else if (action.type === "clearPopup") {
            draft.popupId = null;
        } else if (action.type === "setPopup") {
            draft.popupId = action.popupId;
        } else if (action.type === "setZoom") {
            if (!draft.zoomLevel) {
                draft.zoomLevel = action.zoom;
            }
        }
    });

const initialState: ComponentState = {
    isFreeMapView: false,
    showUpdateSearch: false,
    isLoading: false,
    bounds: null,
    popupId: null,
    zoomLevel: null,
};

interface SearchResultsMapProps {
    hoveredEntity: null | HoveredMapEntity;
    isLoading: boolean;
    results: MapSearchResults | null;
    explicitBounds: BoundsInput | null;
    onExplicitBoundsChanged: (northWest: ILocation, southEast: ILocation) => void;
    onMapViewChanged: (mapView: MapView) => void;
    renderMarkerControl?: (id: MapMarkerId) => JSX.Element;
    error?: unknown;
}

export const SearchResultsMap: React.FC<SearchResultsMapProps> = props => {
    const {
        hoveredEntity,
        explicitBounds,
        results,
        isLoading,
        onExplicitBoundsChanged,
        renderMarkerControl,
        onMapViewChanged,
        error,
    } = props;

    const wrapperDiv = React.useRef<HTMLDivElement>(null);
    const fixedMapDiv = React.useRef<HTMLDivElement>(null);
    const mapRef = React.useRef<MapRef>(null);
    const windowSize = useWindowSize();

    const [state, localDispatch] = React.useReducer(reducer, initialState);

    const mapView: MapView = React.useMemo(() => {
        return {
            zoomLevel: state.zoomLevel,
            bounds: state.bounds,
            viewportHeight: windowSize.height * 0.7,
            viewportWidth: windowSize.width * 0.45,
        };
    }, [state.zoomLevel, state.bounds, windowSize]);

    React.useEffect(() => {
        onMapViewChanged(mapView);
    }, [mapView]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        if (results?.recommendedZoom) {
            localDispatch({
                type: "setZoom",
                zoom: results.recommendedZoom,
            });
        }
        if (isLoading) {
            localDispatch({ type: "startLoading" });
        } else {
            localDispatch({ type: "endLoading" });
        }
    }, [results, isLoading]);
    React.useEffect(() => {
        if (!explicitBounds && state.isFreeMapView) {
            localDispatch({ type: "disableFreeMap" });
        }
    }, [explicitBounds]); // eslint-disable-line react-hooks/exhaustive-deps

    const mapBounds: LngLatBounds | null = React.useMemo(() => {
        if (
            !results ||
            (results.entityClusters.length === 0 &&
                results.entityCoordinates.length === 0 &&
                !results.searchCoordinates)
        ) {
            return null;
        }
        const bounds = new LngLatBounds();
        results?.entityClusters.forEach(c => {
            bounds.extend([c.lng, c.lat]);
        });
        results?.entityCoordinates.forEach(c => {
            bounds.extend([c.lng, c.lat]);
        });
        if (results.searchCoordinates) {
            bounds.extend([
                parseFloat(results.searchCoordinates.lng),
                parseFloat(results.searchCoordinates.lat),
            ]);
        }
        return bounds;
    }, [results]);

    const shouldProcessEvents = useMapEvents(
        !state.isFreeMapView,
        wrapperDiv,
        fixedMapDiv,
        mapRef,
        mapBounds,
    );

    React.useEffect(() => {
        if (!state.popupId || !results) {
            return;
        }
        if (!results.entityCoordinates.some(c => c.id === state.popupId)) {
            localDispatch({ type: "clearPopup" });
        }
    }, [results, state.popupId]);

    const onBoundsUpdated = React.useCallback(
        (northWest: ILocation, southEast: ILocation) => {
            localDispatch({
                type: "endUserMapMove",
                zoomLevel: mapRef.current?.getMap().getZoom() ?? defaultZoomLevel,
                bounds: {
                    northWest: locationToCoordinates(northWest),
                    southEast: locationToCoordinates(southEast),
                },
            });

            onExplicitBoundsChanged(northWest, southEast);
        },
        [onExplicitBoundsChanged],
    );

    const onMoveEnd = React.useCallback(
        (e: unknown) => {
            if (shouldProcessEvents()) {
                if (
                    Object.prototype.hasOwnProperty.call(e, "automaticOperationType") &&
                    (e as { automaticOperationType: string }).automaticOperationType ===
                        "fitBounds"
                ) {
                    localDispatch({
                        type: "endAutoMapMove",
                    });
                } else {
                    const bounds = mapRef.current?.getMap().getBounds();
                    localDispatch({
                        type: "endUserMapMove",
                        zoomLevel: mapRef.current?.getMap().getZoom() ?? defaultZoomLevel,
                        bounds: {
                            northWest: locationToCoordinates(
                                latLngToILocation(bounds.getNorthWest()),
                            ),
                            southEast: locationToCoordinates(
                                latLngToILocation(bounds.getSouthEast()),
                            ),
                        },
                    });
                }
            }
        },
        [shouldProcessEvents],
    );

    const doMapSearch = React.useCallback(() => {
        if (mapRef.current) {
            const bounds = mapRef.current.getMap().getBounds();
            onBoundsUpdated(
                latLngToILocation(bounds.getNorthWest()),
                latLngToILocation(bounds.getSouthEast()),
            );
            localDispatch({ type: "startExplicitBoundsSearch" });
        }
    }, [mapRef, onBoundsUpdated]);

    const flyTo = React.useCallback((lng: number, lat: number) => {
        if (mapRef.current) {
            mapRef.current.flyTo({
                center: [lng, lat],
                zoom: mapRef.current?.getMap().getZoom() + 2,
            });
        }
    }, []);

    const closePopup = React.useCallback(() => localDispatch({ type: "clearPopup" }), []);
    const setPopup = React.useCallback(
        (communityId: CommunityId) =>
            localDispatch({ type: "setPopup", popupId: communityId }),
        [],
    );

    if (isLoading && !results) {
        return (
            <div className="full-width">
                <Spinner />
            </div>
        );
    }

    if (error) {
        const gqlError = error as ClientGraphqlError;
        if (
            typeof gqlError.hasErrorType === "function" &&
            gqlError.hasErrorType("INVALID_RECORD")
        ) {
            return (
                <InlineBanner type="warning">
                    The location data provided does not correspond to a real-world map
                    location. Please review your location search and try again.
                </InlineBanner>
            );
        }

        return (
            <Error componentName="NextgenFilteredCommunityList">
                {/* eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction */}
                {(error as any).message}
            </Error>
        );
    }

    if (!results) {
        return (
            <div className="full-width">
                <Spinner />
            </div>
        );
    }

    const geoReference: ILocation | null = results.searchCoordinates
        ? {
              loc: [
                  parseFloat(results.searchCoordinates.lng),
                  parseFloat(results.searchCoordinates.lat),
              ],
          }
        : null;

    let actualMarkerHighlighted = false;
    const clusterMarkers: JSX.Element[] = [];
    results.entityCoordinates.forEach(c => {
        const isHovered = c.id === hoveredEntity?.entityId;
        if (isHovered) {
            actualMarkerHighlighted = true;
        }
        clusterMarkers.push(
            <SearchResultsMapMarker
                key={c.id}
                coordinate={c}
                isHovered={isHovered}
                onSelected={setPopup}
            />,
        );
    });

    const bounds = mapRef.current?.getMap().getBounds();
    const shouldHighlightCluster =
        !actualMarkerHighlighted &&
        bounds &&
        hoveredEntity &&
        hoveredEntity.location &&
        hoveredEntity.location.lat > bounds.getSouth() &&
        hoveredEntity.location.lat < bounds.getNorth() &&
        hoveredEntity.location.lng > bounds.getWest() &&
        hoveredEntity.location.lng < bounds.getEast() &&
        results.entityClusters.length > 0;
    const highlightedCluster: MapSearchCluster = shouldHighlightCluster
        ? results.entityClusters.reduce((acc, cluster) => {
              const distanceToCluster = getDistanceLatLng(
                  hoveredEntity.location,
                  cluster,
              );
              const distanceToClusterAcc = getDistanceLatLng(hoveredEntity.location, acc);
              return distanceToCluster < distanceToClusterAcc ? cluster : acc;
          })
        : null;
    results.entityClusters.forEach(c => {
        clusterMarkers.push(
            <SearchResultsMapCluster
                isHovered={highlightedCluster === c}
                flyTo={flyTo}
                key={`${c.lat}${c.lng}`}
                cluster={c}
            />,
        );
    });

    const entityPopupActual = results.entityCoordinates.find(c => c.id === state.popupId);

    return (
        <div className="full-width community-map-anchor">
            <div className="top-0 sticky min-h-screen" ref={wrapperDiv}>
                <div
                    ref={fixedMapDiv}
                    style={{
                        position: "absolute",
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                    }}>
                    <Map
                        ref={mapRef}
                        onMoveEnd={onMoveEnd}
                        mapLib={maplibregl}
                        attributionControl={false}
                        initialViewState={{
                            bounds: mapBounds as unknown as LngLatBoundsLike,
                            fitBoundsOptions: { maxZoom: 10 },
                        }}
                        style={{
                            position: "absolute",
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                        }}
                        mapStyle="https://api.maptiler.com/maps/8d80423a-4979-4e17-a487-ce521f75ff8e/style.json?key=Xs35dG5Gh6ueSw4mF06I">
                        <MapLoadingIndicator isLoading={state.isLoading} />
                        <GeolocateControl position="top-left" />
                        <FullscreenControl position="top-left" />
                        <NavigationControl position="top-left" />
                        {isValidLocation(geoReference) ? (
                            <GeoCenter location={geoReference} />
                        ) : null}
                        {clusterMarkers}
                        {entityPopupActual ? (
                            <MarkerPopup
                                onClose={closePopup}
                                coordinate={entityPopupActual}
                                childControlGenerator={renderMarkerControl}
                            />
                        ) : null}
                        <ScaleControl />
                    </Map>
                    <div className="top-0 right-0 absolute m-2">
                        {state.showUpdateSearch ? (
                            <SecondaryButton onClick={doMapSearch}>
                                Search this area
                            </SecondaryButton>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
};

const MarkerPopup: React.FC<{
    onClose: () => void;
    coordinate: MapSearchCoordinate;
    childControlGenerator?: (id: MapMarkerId, mapExperience?: boolean) => JSX.Element;
}> = props => {
    const { coordinate, childControlGenerator, onClose } = props;
    if (!location) {
        return null;
    }
    return (
        <Popup
            offset={30}
            longitude={coordinate.lng}
            latitude={coordinate.lat}
            closeButton={false}
            maxWidth="none"
            closeOnClick={true}
            onClose={onClose}
            anchor="bottom">
            <div>
                <Link to={coordinate.appLink}>
                    <div className={`${defaultLinkStyle} text-lg`}>
                        {coordinate.name ?? NO_NAME}
                    </div>
                </Link>
                {childControlGenerator ? (
                    <div>{childControlGenerator(coordinate.id)}</div>
                ) : null}
            </div>
        </Popup>
    );
};
