import {
    EmailTemplateId,
    IContact,
    UserId,
    parseEntityIdSafe,
    validEmail,
} from "@sp-crm/core";
import { RecipientsField } from "components/messages/recipients-field";
import { UserSelect } from "components/shared/user-select";
import { InlineEmailTemplateSetting } from "components/templates/inline-email-template-setting";
import { AutosavingCheckbox } from "components/ui/autosaving-checkbox";
import { AutosavingInput } from "components/ui/autosaving-input";
import { Checkbox } from "components/ui/checkbox";
import { HtmlInput } from "components/ui/html-input";
import { AdvancedSearchEntityType, WorkflowSettingType } from "generated/graphql";
import React from "react";
import { useVisibleUsers } from "store/selectors/hooks";
import { EditableWorkflow } from "./types";

interface FieldProps {
    scope: "user" | "tenant";
    onSave: (field: string, newValue: string) => void;
    workflowSettings: Record<string, string>;
    setting: EditableWorkflow["settings"][0];
    workflow: EditableWorkflow;
}

export const WorkflowSettingsField: React.FC<FieldProps> = props => {
    const { onSave, workflowSettings, setting, workflow } = props;
    const preferenceKey = `$workflow.${workflow.id}.${setting.key}`;
    const users = useVisibleUsers();

    if (setting.type === WorkflowSettingType.String) {
        return (
            <div className="group">
                <AutosavingInput
                    onCommit={newValue => onSave(preferenceKey, newValue)}
                    label={setting.label}
                    initial={
                        workflowSettings[preferenceKey] ?? setting.defaultValue ?? ""
                    }
                />
                <div className="ml-6 text-gray-500 group-hover:text-gray-900 text-sm">
                    {setting.description}
                </div>
            </div>
        );
    }
    if (setting.type === WorkflowSettingType.Number) {
        return (
            <div className="group">
                <AutosavingInput
                    onCommit={newValue => onSave(preferenceKey, newValue)}
                    label={setting.label}
                    initial={
                        workflowSettings[preferenceKey] ?? setting.defaultValue ?? ""
                    }
                    type="number"
                    step="1"
                    min="0"
                />
                <div className="ml-6 text-gray-500 group-hover:text-gray-900 text-sm">
                    {setting.description}
                </div>
            </div>
        );
    }
    if (setting.type === WorkflowSettingType.Boolean) {
        return (
            <div className="group">
                <AutosavingCheckbox
                    onCommit={newValue =>
                        onSave(preferenceKey, newValue ? "TRUE" : "FALSE")
                    }
                    label={setting.label}
                    initial={
                        (workflowSettings[preferenceKey] ??
                            setting.defaultValue ??
                            "") === "TRUE"
                    }
                />
                <div className="ml-6 text-gray-500 group-hover:text-gray-900 text-sm">
                    {setting.description}
                </div>
            </div>
        );
    }
    if (setting.type === WorkflowSettingType.EmailTemplateId) {
        return (
            <div className="group">
                <InlineEmailTemplateSetting
                    onChange={templateId => onSave(preferenceKey, templateId)}
                    value={(workflowSettings[preferenceKey] as EmailTemplateId) || null}
                    bodyLabel="Email body"
                    subjectLabel="Email subject"
                    includeAttachments={true}
                    placeholderTypes={[AdvancedSearchEntityType.Client]}
                />
                <div className="ml-6 text-gray-500 group-hover:text-gray-900 text-sm">
                    {setting.description}
                </div>
            </div>
        );
    }
    if (setting.type === WorkflowSettingType.User) {
        let userValue: string = "";
        if (workflowSettings[preferenceKey]) {
            const userId = parseEntityIdSafe<UserId>(workflowSettings[preferenceKey]);
            if (userId.hasValue && users[userId.get()]) {
                userValue = userId.get();
            }
        }

        return (
            <div>
                <UserSelect
                    label={setting.label}
                    includeEveryone={false}
                    includeUnassigned={true}
                    unassignedLabel=""
                    value={userValue as UserId}
                    onChange={userId => onSave(preferenceKey, userId)}
                />
            </div>
        );
    }
    if (setting.type === WorkflowSettingType.Html) {
        return (
            <HtmlInput
                label={setting.label}
                initialContent={workflowSettings[preferenceKey] ?? ""}
                onCommit={newValue => onSave(preferenceKey, newValue)}
                placeholderTypes={[AdvancedSearchEntityType.Client]}
            />
        );
    }

    if (setting.type === WorkflowSettingType.ClientEmails) {
        return (
            <ClientEmailsSetting
                label={setting.label}
                value={workflowSettings[preferenceKey] ?? setting.defaultValue}
                onChange={newValue => onSave(preferenceKey, newValue)}
            />
        );
    }

    const exhaustiveCheck: never = setting.type;
    console.warn(`Unhandled workflow setting type: ${exhaustiveCheck}`);
    return <span />;
};

interface ClientEmailsSettingProps {
    label: string;
    value: string;
    onChange: (newValue: string) => void;
}

const ClientEmailsSetting: React.FC<ClientEmailsSettingProps> = props => {
    const { label, value, onChange } = props;

    const emails = value.split(",").map(e => e.trim());

    const actualEmailContacts: IContact[] = emails
        .filter(e => validEmail(e))
        .map(e => ({ email1: e } as IContact));
    const [hasAdditionalEmails, setHasAdditionalEmails] = React.useState(
        actualEmailContacts.length > 0,
    );

    const addToken = (token: string) => {
        onChange(emails.concat(token).join(","));
    };

    const removeToken = (token: string) => {
        onChange(emails.filter(e => e !== token).join(","));
    };

    const removeAdditionalEmails = () => {
        setHasAdditionalEmails(false);
        onChange(emails.filter(e => !validEmail(e)).join(","));
    };

    const setActualEmailContacts = (contacts: IContact[]) => {
        onChange(
            emails
                .filter(e => !validEmail(e))
                .concat(contacts.map(c => c.email1))
                .join(","),
        );
    };

    const tokens = [
        {
            label: "Client email",
            token: "{{client.email}}",
        },
        {
            label: "Best contact email",
            token: "{{client.bestContactEntity.email1}}",
        },
        {
            label: "Referral contact email",
            token: "{{client.referralContact.contactEntity.email1}}",
        },
        {
            label: "Chosen community email",
            token: "{{client.chosenCommunity.email}}",
        },
        {
            label: "Chosen community primary contact emails",
            token: "{{client.chosenCommunity.primaryContactEmails}}",
        },
    ];

    return (
        <div>
            <p>{label}</p>
            {tokens.map(token => (
                <Checkbox
                    key={token.token}
                    label={token.label}
                    checked={emails.includes(token.token)}
                    onChange={e => {
                        if (e.target.checked) {
                            addToken(token.token);
                        } else {
                            removeToken(token.token);
                        }
                    }}
                />
            ))}
            <Checkbox
                label="Additional emails"
                checked={hasAdditionalEmails}
                onChange={e => {
                    if (e.target.checked) {
                        setHasAdditionalEmails(true);
                    } else {
                        removeAdditionalEmails();
                    }
                }}
            />
            {hasAdditionalEmails ? (
                <RecipientsField
                    label=""
                    recipients={actualEmailContacts}
                    onChange={setActualEmailContacts}
                    suggestions={[]}
                />
            ) : null}
        </div>
    );
};
