import { CheckCircleIcon, NoSymbolIcon } from "@heroicons/react/20/solid";
import {
    IQuestion,
    InteractionType,
    QuestionType,
    UserId,
    renderAnswer,
    renderSelections,
} from "@sp-crm/core";
import React from "react";
import { isUrl, sanitizeUri } from "util/uri";
import { ListableCommunity } from "../props";

interface CommunityCardAnswerProps {
    question: IQuestion;
    answer: ListableCommunity["answers"][0];
    users: Record<UserId, { id: UserId; name: string }>;
}

const CommunityCardAnswerBinary: React.FC<CommunityCardAnswerProps> = props => {
    return (
        <div className="flex items-start space-x-1">
            {props.answer.booleanAnswer ? (
                <CheckCircleIcon className="h-4 w-4 text-gray-400 mt-0.5" />
            ) : (
                <NoSymbolIcon className="h-4 w-4 text-gray-400 mt-0.5" />
            )}
            <p>{renderAnswer(props.question, props.answer, props.users)}</p>
        </div>
    );
};

const CommunityCardAnswerCurrency: React.FC<CommunityCardAnswerProps> = props => (
    <p>{renderAnswer(props.question, props.answer, props.users)}</p>
);
const CommunityCardAnswerDate: React.FC<CommunityCardAnswerProps> = props => (
    <p>{renderAnswer(props.question, props.answer, props.users)}</p>
);
const CommunityCardAnswerNary: React.FC<CommunityCardAnswerProps> = props => {
    const renderedSelections = renderSelections(props.question, props.answer);

    return (
        <ul>
            {renderedSelections.map((selection, index) => (
                <li key={index}>{selection}</li>
            ))}
        </ul>
    );
};
const CommunityCardAnswerNumber: React.FC<CommunityCardAnswerProps> = props => (
    <p>{renderAnswer(props.question, props.answer, props.users)}</p>
);
const CommunityCardAnswerRange: React.FC<CommunityCardAnswerProps> = props => (
    <p>{renderAnswer(props.question, props.answer, props.users)}</p>
);
const CommunityCardAnswerUser: React.FC<CommunityCardAnswerProps> = props => (
    <p>{renderAnswer(props.question, props.answer, props.users)}</p>
);
const CommunityCardAnswerText: React.FC<CommunityCardAnswerProps> = props => (
    <p className="line-clamp-6">
        {renderAnswer(props.question, props.answer, props.users)}
    </p>
);

const CommunityCardAnswerUrl: React.FC<CommunityCardAnswerProps> = props => {
    const textAnswer = props.answer.textAnswer || "";
    return (
        <a
            className="truncate"
            target="_blank"
            rel="noreferrer"
            href={sanitizeUri(textAnswer)}>
            {textAnswer}
        </a>
    );
};

export const CommunityCardAnswer: React.FC<CommunityCardAnswerProps> = props => {
    const { question, answer, users } = props;

    if (props.question.questionType === QuestionType.nary) {
        return (
            <CommunityCardAnswerNary question={question} answer={answer} users={users} />
        );
    }
    if (props.question.questionType === QuestionType.binary) {
        return (
            <CommunityCardAnswerBinary
                question={question}
                answer={answer}
                users={users}
            />
        );
    }
    if (props.question.questionType === QuestionType.number) {
        return (
            <CommunityCardAnswerNumber
                question={question}
                answer={answer}
                users={users}
            />
        );
    }
    if (props.question.questionType === QuestionType.currency) {
        return (
            <CommunityCardAnswerCurrency
                question={question}
                answer={answer}
                users={users}
            />
        );
    }
    if (props.question.questionType === QuestionType.text) {
        if (
            props.question.interaction === InteractionType.url &&
            isUrl(answer.textAnswer)
        ) {
            return (
                <CommunityCardAnswerUrl
                    question={question}
                    answer={answer}
                    users={users}
                />
            );
        }
        return (
            <CommunityCardAnswerText question={question} answer={answer} users={users} />
        );
    }
    if (props.question.questionType === QuestionType.date) {
        return (
            <CommunityCardAnswerDate question={question} answer={answer} users={users} />
        );
    }
    if (props.question.questionType === QuestionType.range) {
        return (
            <CommunityCardAnswerRange question={question} answer={answer} users={users} />
        );
    }
    if (props.question.questionType === QuestionType.user) {
        return (
            <CommunityCardAnswerUser question={question} answer={answer} users={users} />
        );
    }
    console.warn(`Unknown question type: ${props.question.questionType}`);
    return null;
};
