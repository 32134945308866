import React, { useEffect } from "react";
import { handleEvent } from "util/user-events";

interface PremiumCalloutProps {
    children: React.ReactNode;
}

export const PremiumCallout: React.FC<PremiumCalloutProps> = props => {
    useEffect(() => {
        handleEvent("premium-callout");
    }, []);
    return <>{props.children}</>;
};
