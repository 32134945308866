import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import React from "react";

interface ExpandIndicatorProps {
    expanded: boolean;
}

export const ExpandIndicator: React.FC<ExpandIndicatorProps> = props => {
    const { expanded } = props;
    return (
        <div className="flex items-center space-x-1 text-gray-500 group hover:text-gray-800 transition-all duration-100 ease-in-out">
            {expanded ? <div>collapse</div> : <div>expand</div>}
            {expanded ? (
                <ChevronUpIcon className="w-4 h-4 rounded group-hover:bg-white" />
            ) : (
                <ChevronDownIcon className="w-4 h-4 rounded group-hover:bg-white" />
            )}
        </div>
    );
};
