import { FileId } from "@sp-crm/core";
import { FileIcon } from "components/files/file-icon";
import { ComposeMessageQuery } from "generated/graphql";
import React from "react";
import { useGlobalAttachmentScope } from "store/selectors/hooks";

interface ReadOnlyAttachmentsProps {
    attachmentIds: FileId[];
}

export const ReadOnlyAttachments: React.FC<ReadOnlyAttachmentsProps> = props => {
    const { attachmentIds } = props;
    const attachmentScopes = useGlobalAttachmentScope();

    const humanReadableSelectedFiles: ComposeMessageQuery["composeMessage"]["files"][0]["files"] =
        [];

    attachmentScopes.forEach(s => {
        const files = s.files
            .slice()
            .sort((a, b) =>
                (a.originalFilename ?? "")
                    .toLocaleLowerCase()
                    .localeCompare(b.originalFilename ?? ""),
            );
        files.forEach(f => {
            if (attachmentIds.includes(f.id)) {
                humanReadableSelectedFiles.push(f);
            }
        });
    });

    if (humanReadableSelectedFiles.length === 0) {
        return null;
    }

    return (
        <div className="space-y-1">
            <p className="text-sm">Attachments</p>
            <ul>
                {humanReadableSelectedFiles.map(f => (
                    <li className="flex items-center space-x-1" key={f.id}>
                        <FileIcon mimeType={f.mimeType} />
                        <p className="text-sm">{f.originalFilename}</p>
                    </li>
                ))}
            </ul>
        </div>
    );
};
