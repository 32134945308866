import { MegaphoneIcon } from "@heroicons/react/24/outline";
import React, { useEffect } from "react";
import { initializeUpdates } from "util/updates";

export const UpdatesIcon: React.FC<unknown> = () => {
    useEffect(() => {
        initializeUpdates();
    }, []);
    return (
        <div className="relative">
            <MegaphoneIcon className="h-6 w-6 rounded-full text-gray-600 hover:text-gray-900" />
            <div id="product-updates" className="absolute top-0 left-0" />
        </div>
    );
};
