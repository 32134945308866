import { BellIcon } from "@heroicons/react/24/outline";
import React, { useCallback, useContext } from "react";
import { ReminderContext } from "../../contexts/reminder";

export const ReminderIcon: React.FunctionComponent = () => {
    const [interacted, setInteracted] = React.useState(false);
    const { isReminderEnabled, setVisible, reminders } = useContext(ReminderContext);
    const onClick = useCallback(() => {
        setInteracted(true);
        setVisible(v => !v);
    }, [setVisible, setInteracted]);
    if (!isReminderEnabled) return null;
    const showReminders = reminders?.length > 0;
    const remindersCount = reminders ? reminders.length : 0;
    const showPulse = showReminders && !interacted;
    return (
        <div className="notification-button">
            <button
                className="rounded-full p-1 flex items-center text-gray-600 hover:text-gray-900 hover:bg-brand-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-brand-500 relative"
                onClick={onClick}
                title="Notification">
                <BellIcon className="w-6 h-6" />
                {showReminders ? (
                    <div
                        className={`${
                            showPulse ? "animate-pulse" : ""
                        } rounded-full w-4 h-4 text-xs flex items-center justify-around absolute top-0 right-0 bg-brand-600 text-white`}>
                        {remindersCount}
                    </div>
                ) : null}
            </button>
        </div>
    );
};
