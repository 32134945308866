import { Bars3Icon } from "@heroicons/react/24/outline";
import * as React from "react";
import { connect } from "react-redux";
import { Action, Dispatch } from "redux";
import { ApplicationState } from "store/state";
import * as actions from "../../store/actions/responsive";
import { ResponsiveMode } from "../../store/reducers/responsive";

interface MenuControlFromDispatch {
    hideMenu: () => void;
    showMenu: () => void;
}

interface MenuControlFromState {
    menuVisible: boolean;
    responsiveMode: ResponsiveMode;
}

type MenuControlProps = MenuControlFromState & MenuControlFromDispatch;

class MenuControl extends React.Component<MenuControlProps, undefined> {
    constructor(p: MenuControlProps) {
        super(p);
        this.toggleMenu = this.toggleMenu.bind(this);
    }
    private toggleMenu() {
        if (this.props.menuVisible) {
            this.props.hideMenu();
        } else {
            this.props.showMenu();
        }
    }
    render(): JSX.Element {
        if (this.props.responsiveMode < ResponsiveMode.large) {
            return (
                <button onClick={this.toggleMenu}>
                    <Bars3Icon className="w-6 h-6 mr-2" />
                </button>
            );
        }
        return null;
    }
}

function mapState(state: ApplicationState) {
    return {
        menuVisible: state.responsive.showMenu,
        responsiveMode: state.responsive.mode,
    };
}

function mapDispatch(dispatch: Dispatch<Action>): MenuControlFromDispatch {
    return {
        hideMenu: () => {
            dispatch(actions.hideMenu());
        },
        showMenu: () => {
            dispatch(actions.showMenu());
        },
    };
}

const connected = connect(mapState, mapDispatch)(MenuControl);
export { connected as MenuControl };
