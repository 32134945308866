import { PowerOfAttorneySelect } from "components/clients/show-client/power-of-attorney-select";
import * as React from "react";
import { InputContactPerson, InputContactPersonProps } from "./input-contact-person";

export const InputClientContact: React.FunctionComponent<
    InputContactPersonProps
> = props => {
    const { onFieldChange, contact } = props;

    const handleChange = React.useCallback(
        (newVal: string[]) => {
            onFieldChange("clientPowerOfAttorney", newVal);
        },
        [onFieldChange],
    );

    const existingSelections: string[] = contact.clientPowerOfAttorney
        ? typeof contact.clientPowerOfAttorney === "string"
            ? [contact.clientPowerOfAttorney]
            : contact.clientPowerOfAttorney
        : [];

    return (
        <InputContactPerson {...props}>
            <PowerOfAttorneySelect value={existingSelections} onChange={handleChange} />
        </InputContactPerson>
    );
};
