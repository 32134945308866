import { AnnotationType, Answer, IAnswer, IQuestion } from "@sp-crm/core";
import { AutosavingInput } from "components/ui/autosaving-input";
import * as React from "react";
import { InDepthQuestionProps } from "./props";

interface Props {
    shouldShow: (q: IQuestion, a: Answer) => boolean;
}

export const SingleAnnotation: React.FunctionComponent<
    InDepthQuestionProps & Props
> = props => {
    const { requestAnswerModification } = props;

    const onCommit = React.useCallback(
        (val: string) => {
            requestAnswerModification((a: IAnswer) => {
                a.textAnswer = val;
            });
        },
        [requestAnswerModification],
    );

    const title = () => {
        return props.question.title;
    };

    if (props.question.annotation !== AnnotationType.single) {
        return null;
    }

    if (!props.shouldShow(props.question, props.answer)) {
        return null;
    }

    return (
        <div className="single-annotation" data-annotation="single">
            <AutosavingInput
                multiLine={true}
                onCommit={onCommit}
                initial={props.answer.textAnswer}
                label={title() + " (Notes)"}
            />
        </div>
    );
};
