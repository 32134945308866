import { ActionType, ClientId } from "@sp-crm/core";
import { ClientInvoices, InvoiceInput } from "components/clients/invoices";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "store/state";
import { loadClient } from "../../../../store/actions";
import { isAllowed } from "../../../../util/permissions";

interface Props {
    clientId: ClientId;
    invoiceInput: InvoiceInput;
}

export const InvoiceTab: React.FC<Props> = props => {
    const { clientId } = props;
    const dispatch = useDispatch();

    const updateClient = (clientId: ClientId) => {
        loadClient(clientId, dispatch);
    };

    const canModifyInvoices = useSelector((state: ApplicationState) =>
        isAllowed(ActionType.CreateModifyInvoice, state.permissions, state.region),
    );
    return (
        <div>
            <ClientInvoices
                updateClient={updateClient}
                canModifyInvoices={canModifyInvoices}
                clientId={clientId}
                invoiceInput={props.invoiceInput}
            />
        </div>
    );
};
