import { Bars3Icon } from "@heroicons/react/24/outline";
import { ClientId, CommunityId, CommunityRelationshipType } from "@sp-crm/core";
import * as React from "react";
import { ClientCommunitiesFragment } from "../../../../generated/graphql";
import { useReorderableListDrag, useReorderableListDrop } from "../../../../util/dnd";
import { CommunityCardThumbnail } from "../../../community-search/community-card/community-card-thumbnail";
import { cardBasicsToListableCommunity } from "../../../community-search/listable-community-utility";
import { ShowInFamilyViewCheckbox } from "./show-in-family-view-checkbox";

const isHidden = (rel: CommunityRelationshipType): boolean =>
    rel === CommunityRelationshipType.matched;

interface Props {
    clientId: ClientId;
    clientCommunity: ClientCommunitiesFragment["clientCommunities"][0];
    index: number;
    reorder: (a: number, b: number) => void;
    commitOrder: () => void;
    revertOrder: () => void;
    onClientCommunityChange: (
        clientId: ClientId,
        communityId: CommunityId,
        type: CommunityRelationshipType,
    ) => void;
    refetch: () => void;
    onCommunityHoverStart: (communityId: CommunityId) => void;
    onCommunityHoverEnd: (communityId: CommunityId) => void;
}

export const ClientCommunityCardCompact: React.FC<Props> = props => {
    const {
        clientCommunity,
        index,
        reorder,
        commitOrder,
        revertOrder,
        onCommunityHoverStart,
        onCommunityHoverEnd,
        onClientCommunityChange,
        clientId,
    } = props;

    const ref = React.useRef<HTMLLIElement>(null);
    const type = "card";
    const [{ isDragging }, drag, preview] = useReorderableListDrag({
        type,
        commitOrder,
        revertOrder,
        id: clientCommunity.community.id,
        index,
    });
    // eslint-disable-next-line no-empty-pattern
    const [{}, drop] = useReorderableListDrop({
        index,
        ref,
        reorder,
        type,
    });
    drop(ref);
    return (
        <li
            ref={ref}
            onMouseEnter={() => onCommunityHoverStart(clientCommunity.community.id)}
            onMouseLeave={() => onCommunityHoverEnd(clientCommunity.community.id)}>
            <div
                ref={preview}
                className={`py-2 px-4 space-y-2 rounded ${
                    isHidden(clientCommunity.relationship) ? "bg-gray-50" : "bg-brand-100"
                } ${isDragging ? "opacity-0" : ""} shadow flex items-begin space-x-2`}>
                <div className="cursor-move px-1 py-3" ref={drag}>
                    <Bars3Icon className="w-6 h-6 text-gray-500" />
                </div>
                <div className="flex-1 flex items-begin space-x-4">
                    <CommunityCardThumbnail
                        community={cardBasicsToListableCommunity(
                            clientCommunity.community,
                        )}
                        className="h-16 w-16 flex-shrink-0 rounded-sm mt-1"
                    />
                    <div className="flex-1">
                        <div className="flex justify-between">
                            <h2 className={`text-lg text-gray-900 font-bold`}>
                                {clientCommunity.community.name}
                            </h2>
                            <div className="text-xs pt-1">
                                <ShowInFamilyViewCheckbox
                                    clientId={clientId}
                                    clientCommunity={clientCommunity}
                                    onClientCommunityChange={onClientCommunityChange}
                                />
                            </div>
                        </div>
                        <div className="mt-1">
                            <div>{clientCommunity.community.address}</div>
                            <div>
                                {clientCommunity.community.city},{" "}
                                {clientCommunity.community.state}{" "}
                                {clientCommunity.community.zip}
                            </div>
                        </div>
                        <div className="text-sm mt-1 line-clamp-1">
                            {clientCommunity.publicNotes}
                        </div>
                    </div>
                </div>
            </div>
        </li>
    );
};
