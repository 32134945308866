import { Badge } from "components/ui/badge";
import * as React from "react";

interface Props {
    counter: number;
    children?: React.ReactNode;
}

export const SearchbarItemBadge: React.FC<Props> = props => {
    return (
        <div className="flex space-x-2 items-center">
            <div>{props.children}</div>
            <Badge type="highlight" value={props.counter} />
        </div>
    );
};
