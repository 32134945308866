import { MapSearchCluster, MapSearchCoordinate } from "components/map/types";
import "maplibre-gl/dist/maplibre-gl.css";
import * as React from "react";
import { MapboxEvent, Marker } from "react-map-gl";
import { getMarkerIconUrl } from "./nextgen-marker";
import { MapMarkerId } from "./types";

export const SearchResultsMapMarker: React.FC<{
    coordinate: MapSearchCoordinate;
    isHovered: boolean;
    onSelected?: (entityId: MapMarkerId) => void;
}> = props => {
    const { coordinate, isHovered, onSelected } = props;
    const onClick = React.useCallback(
        (e: MapboxEvent<MouseEvent>) => {
            e.originalEvent.stopPropagation();
            if (onSelected) {
                onSelected(coordinate.id);
            }
        },
        [coordinate, onSelected],
    );
    if (!location) {
        return null;
    }

    return (
        <Marker
            longitude={coordinate.lng}
            latitude={coordinate.lat}
            anchor="bottom"
            onClick={onClick}>
            <img
                src={getMarkerIconUrl(coordinate.color)}
                className={isHovered ? "w-10 h-16" : "w-6 h-10"}
            />
        </Marker>
    );
};

export const SearchResultsMapCluster: React.FC<{
    cluster: MapSearchCluster;
    flyTo: (lng: number, lat: number) => void;
    isHovered: boolean;
}> = props => {
    const { flyTo, cluster, isHovered } = props;
    const onClick = React.useCallback(
        (e: MapboxEvent<MouseEvent>) => {
            e.originalEvent.stopPropagation();
            flyTo(cluster.lng, cluster.lat);
        },
        [cluster, flyTo],
    );
    if (!location) {
        return null;
    }

    return (
        <Marker
            longitude={cluster.lng}
            latitude={cluster.lat}
            anchor="bottom"
            onClick={onClick}>
            <div
                className={`${
                    isHovered ? "w-16 h-16" : "w-10 h-10"
                } rounded-full bg-green-200 border-2 border-green-400 flex items-center justify-center`}>
                <div className="text-sm font-bold text-green-700">{cluster.count}</div>
            </div>
        </Marker>
    );
};
