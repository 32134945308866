import * as C from "@sp-crm/core";
import {
    AnnotationType,
    Answer,
    IQuestion,
    Maybe,
    parseEntityId,
    PromptId,
    SelectionAnnotation,
} from "@sp-crm/core";
import { AutosavingInput } from "components/ui/autosaving-input";
import { Checkbox } from "components/ui/checkbox";
import { SelectPro } from "components/ui/select-pro";
import { SelectProOption } from "helpers/select-defs";
import * as React from "react";
import Multiselect from "react-widgets/Multiselect";
import { getUniqueKey } from "../../util/browser";
import { SingleAnnotation } from "./annotation";
import { InDepthQuestionProps } from "./props";

const shouldShowAnnotation = (question: IQuestion, answer: Answer) => {
    const promptsWithAnnotations = Maybe.fromValue(question)
        .map(q => q.prompts)
        .map(p => p.filter(prompt => prompt.annotation).map(prompt => prompt.id))
        .getOrElse([]);
    const answerCount = Maybe.fromValue(answer)
        .map(a => a.selections)
        .map(s => s.filter(selection => promptsWithAnnotations.includes(selection)))
        .map(s => s.length)
        .getOrElse(0);
    return answerCount > 0;
};

export class NaryPickOneRadioQuestion extends React.Component<
    InDepthQuestionProps,
    undefined
> {
    constructor(props: InDepthQuestionProps) {
        super(props);
        this.onClearAnswer = this.onClearAnswer.bind(this);
        this.onUpdateAnswer = this.onUpdateAnswer.bind(this);
    }

    onUpdateAnswer(prompt: C.IPrompt): void {
        this.props.requestAnswerModification(a => {
            a.selections = [prompt.id];
        });
    }

    onClearAnswer(): void {
        this.props.requestAnswerModification(a => {
            a.selections = [];
        });
    }

    title(): string {
        return this.props.question.title;
    }

    isChecked(question: C.IPrompt): boolean {
        if (
            this.props.answer &&
            this.props.answer.selections &&
            this.props.answer.selections.indexOf(question.id) >= 0
        ) {
            return true;
        }
        return false;
    }

    radioButtons(): JSX.Element[] {
        return this.props.question.prompts.map(p => {
            const prompt = p.title;
            const isChecked = this.isChecked(p);
            const controlId = `radio-${getUniqueKey()}`;

            let className = "option";
            if (p.primaryOption) {
                className += " primary-option";
            }

            return (
                <div key={p.id} className={className}>
                    <input
                        type="radio"
                        id={controlId}
                        checked={isChecked}
                        value={p.id}
                        data-promptid={p.id}
                        onChange={() => this.onUpdateAnswer(p)}
                    />
                    <label htmlFor={controlId}>
                        &nbsp;
                        {prompt}
                    </label>
                </div>
            );
        });
    }

    naButton(): JSX.Element {
        const prompt = "-----";
        const isChecked =
            this.props.answer &&
            this.props.answer.selections &&
            this.props.answer.selections.length == 0;
        const controlId = `radio-${getUniqueKey()}`;
        return (
            <div key={`${this.props.question.id}-na`} className="option">
                <input
                    type="radio"
                    id={controlId}
                    checked={isChecked}
                    value={undefined}
                    data-promptid="na"
                    onChange={this.onClearAnswer}
                />
                <label htmlFor={controlId}>
                    &nbsp;
                    {prompt}
                </label>
            </div>
        );
    }

    render(): JSX.Element {
        return (
            <div className="nary-pick-one-question nary-options">
                <div className="prompt">{this.title()}</div>
                <div className="options">
                    {this.naButton()}
                    {this.radioButtons()}
                </div>
                <SingleAnnotation shouldShow={shouldShowAnnotation} {...this.props} />
            </div>
        );
    }
}

export class NaryPickOneDropdownQuestion extends React.Component<
    InDepthQuestionProps,
    undefined
> {
    constructor(props: InDepthQuestionProps) {
        super(props);
        this.onClearAnswer = this.onClearAnswer.bind(this);
        this.onUpdateAnswer = this.onUpdateAnswer.bind(this);
    }

    onUpdateAnswer(newAnswerId: string): void {
        if (newAnswerId === this.naAnswerId()) {
            this.onClearAnswer();
            return;
        }

        this.props.requestAnswerModification(a => {
            a.selections = [parseEntityId<PromptId>(newAnswerId)];
        });
    }

    onClearAnswer(): void {
        this.props.requestAnswerModification(a => {
            a.selections = [];
        });
    }

    title(): string {
        return this.props.question.title;
    }

    naAnswerId(): string {
        return `${this.props.question.id}-na`;
    }

    dropdownOptions(): SelectProOption[] {
        let options: SelectProOption[] = [{ value: this.naAnswerId(), text: "-----" }];

        options = options.concat(
            this.props.question.prompts.map(p => {
                return { value: p.id, text: p.title };
            }),
        );

        return options;
    }

    render(): JSX.Element {
        return (
            <>
                <SelectPro
                    includePlaceholderOption={false}
                    label={this.title()}
                    value={
                        this.props.answer.selections && this.props.answer.selections[0]
                            ? this.props.answer.selections[0]
                            : this.naAnswerId()
                    }
                    onChange={e => this.onUpdateAnswer(e.target.value)}
                    options={this.dropdownOptions()}
                />
                <SingleAnnotation shouldShow={shouldShowAnnotation} {...this.props} />
            </>
        );
    }
}

export class NaryPickManyQuestion extends React.Component<
    InDepthQuestionProps,
    undefined
> {
    constructor(props: InDepthQuestionProps) {
        super(props);
        this.updateNotesForAnswer = this.updateNotesForAnswer.bind(this);
    }

    onUpdateAnswer(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
        event: any /* TODO React.FormEvent<HTMLInputElement> */,
        prompt: C.IPrompt,
    ): void {
        const targetValue = event.target.checked; // Grab this before the event is disposed
        this.props.requestAnswerModification(a => {
            // Double clicking a checkbox registers as two checked events instead of an checked event followed by an unchecked event so clear the selection either way then re-add it if it is checked.
            a.selections = this.existingSelections().slice(0);
            a.selections = a.selections.filter(x => x !== prompt.id);
            if (targetValue) {
                a.selections.push(prompt.id);
            }
        });
    }

    title(): string {
        return this.props.question.title;
    }

    existingSelections(): PromptId[] {
        if (this.props.answer && this.props.answer.selections)
            return this.props.answer.selections;
        return [];
    }

    isChecked(question: C.IPrompt): boolean {
        if (this.existingSelections().indexOf(question.id) >= 0) {
            return true;
        }
        return false;
    }

    updateNotesForAnswer(p: C.IPrompt, text: string) {
        this.props.requestAnswerModification(a => {
            const existingAnnotation = a.selectionAnnotations.find(
                sa => sa.selectionId === p.id,
            );
            if (existingAnnotation) {
                existingAnnotation.text = text;
            } else {
                const annotation = new SelectionAnnotation();
                annotation.selectionId = p.id;
                annotation.text = text;
                a.selectionAnnotations.push(annotation);
            }
        });
    }

    checkboxes(): JSX.Element[] {
        const showPerCheckboxNotes =
            this.props.question.annotation === AnnotationType.perAnswer;

        return this.props.question.prompts.map(p => {
            const prompt = p.title;
            const isChecked = this.isChecked(p);
            const wrapperId = `wrapper-${p.id}`;
            const showNotes = showPerCheckboxNotes && isChecked && p.annotation;
            const notes = Maybe.fromValue(this.props)
                .map(p => p.answer)
                .map(a => a.selectionAnnotations)
                .map(sa => sa.find(annotation => annotation.selectionId === p.id))
                .map(sa => sa.text)
                .getOrElse("");

            return (
                <div key={wrapperId} className="option">
                    <div
                        className={
                            p.primaryOption ? "bg-yellow-200 -ml-1 pl-1 rounded" : null
                        }>
                        <Checkbox
                            checked={isChecked}
                            value={p.id}
                            data-promptid={p.id}
                            onChange={e => this.onUpdateAnswer(e, p)}
                            label={prompt}
                        />
                    </div>
                    {showNotes && (
                        <div className="ml-6 my-1" data-annotation={p.id}>
                            <AutosavingInput
                                multiLine={true}
                                onCommit={text => this.updateNotesForAnswer(p, text)}
                                initial={notes}
                            />
                        </div>
                    )}
                </div>
            );
        });
    }

    render(): JSX.Element {
        return (
            <div
                className={
                    this.props.question.primaryQuestion
                        ? "bg-yellow-100 -my-2 -mx-4 px-4 py-2 rounded"
                        : ""
                }>
                <div className="font-bold mb-1">{this.title()}</div>
                <div className="options">{this.checkboxes()}</div>
                <SingleAnnotation shouldShow={shouldShowAnnotation} {...this.props} />
            </div>
        );
    }
}

export class NaryPickManyDropdownQuestion extends React.Component<
    InDepthQuestionProps,
    undefined
> {
    constructor(props: InDepthQuestionProps) {
        super(props);
        this.updateNotesForAnswer = this.updateNotesForAnswer.bind(this);
        this.onUpdateAnswer = this.onUpdateAnswer.bind(this);
    }

    onUpdateAnswer(
        values: {
            value: string;
            text: string;
        }[],
    ): void {
        this.props.requestAnswerModification(a => {
            a.selections = values.map(x => parseEntityId<PromptId>(x.value));
        });
    }

    title(): string {
        return this.props.question.title;
    }

    existingSelections(): string[] {
        if (this.props.answer && this.props.answer.selections)
            return this.props.answer.selections;
        return [];
    }

    isChecked(question: C.IPrompt): boolean {
        if (this.existingSelections().indexOf(question.id) >= 0) {
            return true;
        }
        return false;
    }

    updateNotesForAnswer(p: C.IPrompt, text: string) {
        this.props.requestAnswerModification(a => {
            const existingAnnotation = a.selectionAnnotations.find(
                sa => sa.selectionId === p.id,
            );
            if (existingAnnotation) {
                existingAnnotation.text = text;
            } else {
                const annotation = new SelectionAnnotation();
                annotation.selectionId = p.id;
                annotation.text = text;
                a.selectionAnnotations.push(annotation);
            }
        });
    }

    dropdown(): JSX.Element {
        const options = this.props.question.prompts.map(prompt => {
            return {
                value: prompt.id,
                text: prompt.title,
            };
        });
        return (
            <Multiselect
                data={options}
                value={this.existingSelections()}
                dataKey="value"
                textField="text"
                onChange={this.onUpdateAnswer}
            />
        );
    }

    render(): JSX.Element {
        return (
            <div className="nary-pick-many-question nary-options">
                <div className="prompt">{this.title()}</div>
                <div className="options">{this.dropdown()}</div>
                <SingleAnnotation shouldShow={shouldShowAnnotation} {...this.props} />
            </div>
        );
    }
}
