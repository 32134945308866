import { ContentHeader, Tile } from "components/layout";
import { LargeNumber } from "components/reports/show/summary";
import { Spinner } from "components/ui/spinner";
import { AdvancedSearchRequest, BridgeEntityResult } from "generated/graphql";
import React from "react";
import { useAdvancedSearchQuery } from "./advanced-search-request";
import { AdvancedSearchSummary } from "./advanced-search-summary";

interface AdvancedSearchCountProps {
    request: AdvancedSearchRequest;
    title: string;
    entityMetadataList: BridgeEntityResult[];
}

export const AdvancedSearchCountTile: React.FC<AdvancedSearchCountProps> = props => {
    const { request, title, entityMetadataList } = props;

    const query = useAdvancedSearchQuery(request, entityMetadataList);

    return (
        <Tile>
            <ContentHeader>{title}</ContentHeader>
            {query.isLoading || !query.data ? (
                <Spinner />
            ) : (
                <LargeNumber>{query.data.advancedSearch.total || 0}</LargeNumber>
            )}
        </Tile>
    );
};

export const AdvancedSearchSummaryTile: React.FC<AdvancedSearchCountProps> = props => {
    const { request, title, entityMetadataList } = props;

    const entityMetadata = entityMetadataList.find(e => e.name === request.entityType);

    const [workingRequest, setWorkingRequest] = React.useState(request);

    const query = useAdvancedSearchQuery(workingRequest, entityMetadataList);

    return (
        <Tile>
            <ContentHeader>{title}</ContentHeader>
            {!query.data ? (
                <Spinner />
            ) : (
                <AdvancedSearchSummary
                    request={workingRequest}
                    entityMetadata={entityMetadata}
                    onRequestChange={setWorkingRequest}
                    summaries={query.data?.advancedSearch?.summaries || []}
                />
            )}
        </Tile>
    );
};
