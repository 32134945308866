import { IBridgeSelect } from "@sp-crm/core";
import {
    FIELD_PICKER_TYPE_SEPARATOR,
    FieldPickerField,
    getNormalizedFields,
} from "components/reports/show/custom-reports/custom-reports-helpers";
import { BridgeEntityResult } from "generated/graphql";
import React, { useMemo } from "react";

interface EntityNestedFieldPickerProps {
    entityMetadata: BridgeEntityResult;
    value: IBridgeSelect;
    entities: BridgeEntityResult[];
}

export const EntityNestedFieldDisplay: React.FC<EntityNestedFieldPickerProps> = props => {
    const { entityMetadata, value, entities } = props;

    const normalizedFields = useMemo((): FieldPickerField[] => {
        const [_groups, fields] = getNormalizedFields(
            entityMetadata,
            entities,
            value,
            [],
            "",
            true,
        );

        return fields;
    }, [entityMetadata, value, entities]);
    const actualValue = value.questionId
        ? `questionId${FIELD_PICKER_TYPE_SEPARATOR}${value.questionId}`
        : `field${FIELD_PICKER_TYPE_SEPARATOR}${value.fieldName}`;
    const field = normalizedFields.find(f => f.value === actualValue);
    if (!field) return null;
    return (
        <div>{`${field.relation ? `${field.categoryLabel}: ` : ""}${field.title}`}</div>
    );
};
