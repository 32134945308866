export type TaskOwnerViewableKind =
    | "ALL"
    | "UNOWNED"
    | "CLIENT"
    | "COMMUNITY"
    | "REFERENCE";

export const AnnotatedViewableTaskTypes = [
    { name: "All Associations", kind: "ALL" },
    // { name: 'Unaffiliated', kind: 'UNOWNED' },
    { name: "Client", kind: "CLIENT" },
    { name: "Community", kind: "COMMUNITY" },
    { name: "Referral Source", kind: "REFERENCE" },
];
