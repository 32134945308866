import { UserId } from "@sp-crm/core";
import { UserSelect } from "components/shared/user-select";
import React, { useCallback } from "react";
import { InDepthQuestionProps } from "./props";

export const UserQuestion: React.FC<InDepthQuestionProps> = props => {
    const { question, answer, requestAnswerModification } = props;

    const handleUserChange = useCallback(
        (value: UserId | null) => {
            requestAnswerModification(a => {
                a.idReferenceAnswer = value || null;
            });
        },
        [requestAnswerModification],
    );

    return (
        <UserSelect
            includeEveryone={false}
            includeUnassigned={true}
            value={answer?.idReferenceAnswer || ""}
            onChange={handleUserChange}
            label={question.title}
            unassignedLabel="Unselected"
        />
    );
};
