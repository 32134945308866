import { SignatureRequestState } from "generated/graphql";
import React from "react";

interface SignatureRequestStateTextProps {
    state: SignatureRequestState;
}

export const SignatureRequestStateText: React.FC<
    SignatureRequestStateTextProps
> = props => {
    const { state } = props;

    switch (state) {
        case SignatureRequestState.InProgress:
            return <span>In Progress</span>;
        case SignatureRequestState.Complete:
            return <span>Complete</span>;
        case SignatureRequestState.Abandoned:
            return <span>Cancelled</span>;
        default: {
            const exhaustiveCheck: never = state;
            return <span>{exhaustiveCheck}</span>;
        }
    }
};
