import { User, UserSettingsField } from "@sp-crm/core";
import { ContentFull, ContentHeader } from "components/layout";
import { AutosavingInput } from "components/ui/autosaving-input";
import { Input } from "components/ui/input";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "store/actions";
import { useProductName } from "store/selectors/branding";
import { ApplicationState } from "store/state";
import { UserCheckboxPref } from "./account-settings-checkbox";

export const AccountSettingsPersonal: React.FC<unknown> = () => {
    const user = useSelector(
        (state: ApplicationState) => state.users.users[state.userState.userId],
    );
    const dispatch = useDispatch();
    const commitUserValue = React.useCallback(
        (field: UserSettingsField | string, newValue: string) => {
            const commonUser = User.load(user);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (commonUser as any)[field] = newValue;
            updateUser(commonUser, dispatch);
        },
        [dispatch, user],
    );
    const productName = useProductName();

    return (
        <div className="space-y-4 lg:space-y-8">
            <ContentFull>
                <ContentHeader>Personal information</ContentHeader>
                <div className="space-y-4">
                    <AutosavingInput
                        label="Display name"
                        initial={user.name}
                        onCommit={(newValue: string) => commitUserValue("name", newValue)}
                    />
                    <AutosavingInput
                        label="Phone number"
                        initial={user.phone}
                        onCommit={(newValue: string) =>
                            commitUserValue("phone", newValue)
                        }
                    />
                    <Input label="Login email" disabled value={user.email} />
                    <div className="group">
                        <AutosavingInput
                            label="Display email"
                            initial={user.displayEmail}
                            onCommit={(newValue: string) =>
                                commitUserValue("displayEmail", newValue)
                            }
                            onEmpty={() => user.email}
                        />
                        <p className="ml-6 mt-1 text-gray-500 text-sm group-hover:text-gray-900">
                            Specify an email to display on shared documents (e.g. client
                            referrals and community comparison documents) instead of using
                            your login email.
                        </p>
                    </div>
                </div>
            </ContentFull>
            <ContentFull>
                <ContentHeader>Emails</ContentHeader>
                <div className="space-y-4">
                    <UserCheckboxPref
                        field="bccMyself"
                        label="BCC myself by default"
                        description={`When sending emails from ${productName}, BCC myself by default`}
                    />
                </div>
            </ContentFull>
        </div>
    );
};
