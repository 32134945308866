import { UserPlusIcon } from "@heroicons/react/24/outline";
import { PrimaryButton } from "components/ui/primary-button";
import { useCreateClientMutation } from "generated/graphql";
import React, { MouseEventHandler, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useRegionId } from "store/selectors/hooks";
import { handleEvent } from "util/user-events";

const useAddNewClientAction = (
    source: string,
): [boolean, MouseEventHandler<HTMLButtonElement>] => {
    const { push } = useHistory();
    const regionId = useRegionId();
    const isAvailable = !!regionId;
    const createClient = useCreateClientMutation();
    const callback = useCallback(
        async (e: React.MouseEvent<HTMLButtonElement>) => {
            if (e.preventDefault) {
                e.preventDefault();
            }
            handleEvent("create-client", { source });
            const response = await createClient.mutateAsync({ regionId });
            if (response?.createClient?.id) {
                push("/clients/show/" + response.createClient.id);
            }
        },
        [regionId, createClient, push, source],
    );
    return [isAvailable, callback];
};

export const AddClientButton: React.FC<unknown> = () => {
    const [isAvailable, onClick] = useAddNewClientAction("button");
    if (!isAvailable) {
        return null;
    }
    return <PrimaryButton onClick={onClick}>Add a new client</PrimaryButton>;
};

export const AddClientIcon: React.FC<unknown> = () => {
    const [isAvailable, onClick] = useAddNewClientAction("topbar");
    if (!isAvailable) {
        return null;
    }
    return (
        <div className="new-client-button">
            <button
                key="headerButton"
                className="rounded-full p-1 flex items-center text-gray-600 hover:text-gray-900 hover:bg-brand-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-brand-500"
                onClick={onClick}
                title="Add a new client">
                <UserPlusIcon className="w-6 h-6" />
            </button>
        </div>
    );
};
