import { InlineBanner } from "components/ui/inline-banner";
import { defaultLinkStyle } from "components/ui/link";
import { GetSavedQueriesQuery, SavedQueryWarningLocation } from "generated/graphql";
import React from "react";

interface CustomReportWarningsProps {
    report: GetSavedQueriesQuery["getSavedQueries"]["savedQueries"][0];
}

export const CustomReportWarnings: React.FC<CustomReportWarningsProps> = props => {
    const { report } = props;

    const [showAdvanced, setShowAdvanced] = React.useState(false);

    const toggleAdvanced = React.useCallback(
        async (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
            e.preventDefault();
            setShowAdvanced(prev => !prev);
        },
        [setShowAdvanced],
    );

    const conditionWarnings =
        report.warnings?.filter(
            w => w.location === SavedQueryWarningLocation.Condition,
        ) || [];

    if (conditionWarnings.length > 0) {
        return (
            <InlineBanner type="warning">
                <div className="space-y-2">
                    <p>{`This report's query contains fields that no longer exist, which may cause unexpected query results. Review this report's query, then re-save the report to dismiss this warning.`}</p>
                    {showAdvanced ? (
                        <ul>
                            {report.warnings.map((warning, index) => (
                                <li
                                    className="text-sm list-disc list-outside"
                                    key={index}>
                                    {warning.message}
                                </li>
                            ))}
                        </ul>
                    ) : null}
                    <p>
                        <a href="#" onClick={toggleAdvanced} className={defaultLinkStyle}>
                            {showAdvanced
                                ? `Hide advanced details`
                                : `Show advanced details`}
                        </a>
                    </p>
                </div>
            </InlineBanner>
        );
    }

    return null;
};
