import { UserId } from "@sp-crm/core";
import { useCopyToClipboard } from "components/shared/hooks";
import { Input } from "components/ui/input";
import { PrimaryButton } from "components/ui/primary-button";
import { SecondaryButton } from "components/ui/secondary-button";
import { Spinner } from "components/ui/spinner";
import { useUserManagementGetApiKeyQuery } from "generated/graphql";
import React from "react";

interface IntegrationUserApiKeyProps {
    userId: UserId;
}

export const IntegrationUserApiKey: React.FC<IntegrationUserApiKeyProps> = props => {
    const { userId } = props;
    const [shouldFetch, setShouldFetch] = React.useState(false);
    const [copy, isCopied] = useCopyToClipboard();
    const query = useUserManagementGetApiKeyQuery(
        { userId },
        {
            enabled: shouldFetch,
            refetchInterval: false,
            refetchOnMount: false,
            cacheTime: 0,
        },
    );

    const apiKey = query.data?.userManagementGetApiKey.apiKey;

    return (
        <div className="w-96">
            {apiKey ? (
                <div className="flex space-x-1">
                    <div className="flex-1">
                        <Input
                            readOnly
                            textSize="sm"
                            value={isCopied ? "Copied" : apiKey}
                        />
                    </div>
                    <div>
                        <PrimaryButton
                            onClick={e => {
                                e.preventDefault();
                                copy(apiKey);
                            }}>
                            <span className="text-sm">Copy to clipboard</span>
                        </PrimaryButton>
                    </div>
                </div>
            ) : (
                <div className="flex items-center space-x-2">
                    <SecondaryButton
                        disabled={query.isLoading}
                        onClick={e => {
                            e.preventDefault();
                            setShouldFetch(true);
                        }}>
                        <span className="text-sm">Reveal</span>
                    </SecondaryButton>
                    {query.isLoading ? <Spinner /> : null}
                </div>
            )}
        </div>
    );
};
