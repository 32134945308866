import { SupportEmailLink } from "components/app/support-email-link";
import { InlineBanner } from "components/ui/inline-banner";
import { Input } from "components/ui/input";
import { PrimaryButton } from "components/ui/primary-button";
import * as React from "react";
import { connect } from "react-redux";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { Action, Dispatch } from "redux";
import { ApplicationState } from "store/state";
import { setPassword } from "../../store/actions/index";
import { ISetPasswordState } from "../../store/reducers/set-password";
import { ValidationError } from "../shared/validation-error";

interface ResetPasswordEmailPropsFromDispatch {
    doSetPassword: (password: string, token: string) => void;
}

interface ResetPasswordEmailPropsFromState {
    setPassword: ISetPasswordState;
    token: string;
}

interface ResetPasswordEmailState {
    password: string;
    passwordAgain: string;
    formIsSubmitted: boolean;
    errors: IResetPasswordErrors;
}

interface IResetPasswordErrors {
    passwordNotValid: boolean;
    passwordAgainNotValid: boolean;
    notMatching: boolean;
}

type ResetPasswordEmailProps = ResetPasswordEmailPropsFromState &
    ResetPasswordEmailPropsFromDispatch;

class ResetPasswordEmail extends React.Component<
    ResetPasswordEmailProps,
    ResetPasswordEmailState
> {
    constructor(props: ResetPasswordEmailProps) {
        super(props);
        this.state = {
            password: null,
            passwordAgain: null,
            formIsSubmitted: false,
            errors: {
                passwordNotValid: true,
                passwordAgainNotValid: true,
                notMatching: true,
            },
        };
    }

    isFormValid(errors: IResetPasswordErrors): boolean {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
        return Object.keys(errors).every((error: any) => !(errors as any)[error]);
    }

    isPasswordValid(password: string): boolean {
        return password && password.length >= 5;
    }

    arePasswordsMatching(password: string, passwordAgain: string): boolean {
        return password !== passwordAgain;
    }

    onPasswordChange(newPass: string, passwordKey: "password" | "passwordAgain") {
        const oppositePasswordKey =
            passwordKey === "password" ? "passwordAgain" : "password";
        const passwordNotValid =
            passwordKey === "password"
                ? !this.isPasswordValid(newPass)
                : this.state.errors.passwordNotValid;
        const passwordAgainNotValid =
            passwordKey === "password"
                ? this.state.errors.passwordAgainNotValid
                : !this.isPasswordValid(newPass);

        this.setState({
            ...this.state,
            [passwordKey]: newPass,
            errors: {
                passwordNotValid: passwordNotValid,
                passwordAgainNotValid: passwordAgainNotValid,
                notMatching: this.arePasswordsMatching(
                    newPass,
                    this.state[oppositePasswordKey],
                ),
            },
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    onSubmit(e: any) {
        e.preventDefault();
        this.setState({ ...this.state, formIsSubmitted: true });

        if (!this.isFormValid(this.state.errors)) {
            return;
        }
        this.props.doSetPassword(this.state.password, this.props.token);
    }

    render() {
        const { isSuccessful, isFailed } = this.props.setPassword;

        return (
            <div className="reset-password">
                <div className="page-header row">
                    <div className="page-title col">
                        <h2>Reset password</h2>
                    </div>
                    <div className="toolbar col" />
                </div>
                <div className="row main-body">
                    <div className="reset-password-limited">
                        {isFailed && (
                            <InlineBanner type="error">
                                Something went wrong. Please contact <SupportEmailLink />{" "}
                                for further information
                            </InlineBanner>
                        )}
                        {isSuccessful && (
                            <InlineBanner type="success">
                                Your password has been reset, please{" "}
                                <Link to="/login">log in</Link> with your new password
                            </InlineBanner>
                        )}
                        <form
                            onSubmit={this.onSubmit.bind(this)}
                            noValidate
                            className="reset-password-form">
                            <Input
                                type="password"
                                value={this.state.password}
                                onChange={e =>
                                    this.onPasswordChange(e.target.value, "password")
                                }
                                label="New password"
                            />
                            {this.state.errors.passwordNotValid &&
                                this.state.formIsSubmitted && (
                                    <ValidationError hasError>
                                        Password should contain at least 5 characters
                                    </ValidationError>
                                )}

                            <Input
                                type="password"
                                value={this.state.passwordAgain}
                                onChange={e =>
                                    this.onPasswordChange(e.target.value, "passwordAgain")
                                }
                                label="New password (again)"
                            />
                            {this.state.errors.passwordAgainNotValid &&
                                this.state.formIsSubmitted && (
                                    <ValidationError hasError>
                                        Password should contain at least 5 characters
                                    </ValidationError>
                                )}
                            {this.state.errors.notMatching &&
                                this.state.formIsSubmitted && (
                                    <ValidationError hasError>
                                        Passwords do not match
                                    </ValidationError>
                                )}

                            <div className="reset-password-buttons">
                                <PrimaryButton type="submit">Reset</PrimaryButton>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(
    state: ApplicationState,
    ownProps: RouteComponentProps,
): ResetPasswordEmailPropsFromState {
    const queryParams = new URLSearchParams(ownProps.location.search);
    const token = queryParams.get("token") || "ERR_MISSING";

    return {
        setPassword: state.setPassword,
        token,
    };
}

function mapDispatchToProps(
    dispatch: Dispatch<Action>,
): ResetPasswordEmailPropsFromDispatch {
    return {
        doSetPassword: (password: string, token: string) =>
            setPassword(password, token, dispatch),
    };
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ResetPasswordEmail),
);
