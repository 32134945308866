import { ContentSection2Up, Stage } from "components/layout";
import React from "react";
import { CustomReportsQuery } from "./custom-reports/custom-reports-query";
import { CustomReportsSidebar } from "./custom-reports/custom-reports-sidebar";

export const CustomReports: React.FC = () => {
    return (
        <Stage>
            <ContentSection2Up>
                <CustomReportsSidebar />
                <CustomReportsQuery />
            </ContentSection2Up>
        </Stage>
    );
};
