import { Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "store/state";
import { makeGlobalSuccessClear } from "../../store/actions/global-notifications";
import { GlobalNotificationsState } from "../../store/reducers/failures";

export const GlobalSuccess: React.FunctionComponent = () => {
    const successes = useSelector(
        (s: ApplicationState) => s.globalNotifications.successes,
    );
    return (
        <div
            aria-live="assertive"
            className="fixed inset-0 flex items-end px-4 py-12 pointer-events-none sm:px-6 sm:py-12 sm:items-start z-1400">
            <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
                {Object.keys(successes).map(k => (
                    <SuccessToast key={k} id={k} message={successes[k]} />
                ))}
            </div>
        </div>
    );
};

interface SuccessToastProps {
    id: string;
    message: GlobalNotificationsState["successes"]["id"];
}
const SuccessToast: React.FC<SuccessToastProps> = props => {
    const { id, message } = props;
    const dispatch = useDispatch();
    const clear = React.useCallback(
        () => dispatch(makeGlobalSuccessClear(id)),
        [id, dispatch],
    );
    const [show, setShow] = useState(true);
    useEffect(() => {
        const handle = setTimeout(clear, 7000);
        return () => clearTimeout(handle);
    }, [dispatch, id]); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        const handle = setTimeout(() => setShow(false), 5000);
        return () => clearTimeout(handle);
    }, [setShow, id]);
    return (
        <Toast show={show} messageTitle={message.messageTitle}>
            {message.messageBody}
        </Toast>
    );
};

interface ToastProps {
    messageTitle: string;
    show: boolean;
    children?: React.ReactNode;
}

const Toast: React.FC<ToastProps> = props => {
    const { messageTitle, children, show: upstreamShow } = props;
    const [show, setShow] = useState(true);
    return (
        <Transition
            show={show && upstreamShow}
            appear={true}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden z-1400">
                <div className="p-4">
                    <div className="flex items-start">
                        <div className="flex-shrink-0">
                            <CheckCircleIcon
                                className="h-6 w-6 text-green-400"
                                aria-hidden="true"
                            />
                        </div>
                        <div className="ml-3 w-0 flex-1 pt-0.5">
                            <p className="text-sm font-medium text-gray-900">
                                {messageTitle}
                            </p>
                            <p className="mt-1 text-sm text-gray-500">{children}</p>
                        </div>
                        <div className="ml-4 flex-shrink-0 flex">
                            <button
                                type="button"
                                className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
                                onClick={() => {
                                    setShow(false);
                                }}>
                                <span className="sr-only">Close</span>
                                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Transition>
    );
};
