import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import { Badge } from "components/ui/badge";
import * as React from "react";
import { SubnavItem, SubnavPropsOwnProps } from "./subnav-types";

export class SubnavCompact extends React.Component<SubnavPropsOwnProps, undefined> {
    anchors: { [page: string]: HTMLElement } = {};

    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    componentDidUpdate(prevProps: SubnavPropsOwnProps): void {
        const element = this.anchors[this.props.selectedSubpage];
        if (this.props.selectedSubpage !== prevProps.selectedSubpage && element) {
            element.scrollIntoView();
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    private handleListClick(i: SubnavItem, event: any) {
        if (event && event.preventDefault) {
            event.preventDefault();
        }
        const isVisible = this.props.selectedSubpage === i.subpage;
        if (isVisible) {
            this.props.closeSection && this.props.closeSection();
        } else {
            this.props.navigate(i.subpage);
        }
    }

    private storeRef(subpage: string, element: HTMLElement): void {
        this.anchors[subpage] = element;
    }

    /**
     * 
     * @returns   a.subnav-accordian-header {
-            font-size: 120%;
-            padding: 0.5rem 0;
-            border-bottom: 1px solid #eeeeef;
-            display: flex;
-            justify-content: space-between;
-
-            .title {
-                flex: 1 0 200px;
-            }
-
-            .icon {
-                flex: 0 0 32px;
-            }
-        }
-    }
     */

    render(): JSX.Element {
        const items: JSX.Element[] = this.props.subpages.map(i => {
            const isVisible = this.props.selectedSubpage === i.subpage;
            const icon: JSX.Element = isVisible ? (
                <ChevronUpIcon className="w-4 h-4" />
            ) : (
                <ChevronDownIcon className="w-4 h-4" />
            );
            return (
                <li key={i.subpage} ref={r => this.storeRef(i.subpage, r)}>
                    <a
                        className="twoverride text-lg text-gray-700 flex justify-between items-center block"
                        href="#"
                        onClick={x => this.handleListClick(i, x)}>
                        <div>
                            {i.linkText}
                            {i.badgeCount !== null && i.badgeCount !== undefined ? (
                                <>
                                    &nbsp;
                                    <Badge
                                        type={i.badgeType || "info"}
                                        value={i.badgeCount}
                                    />
                                </>
                            ) : null}
                        </div>
                        <div className="text-gray-400">{icon}</div>
                    </a>
                    {isVisible ? <div className="pt-2 pb-1">{i.render()}</div> : null}
                </li>
            );
        });
        return <ul className="divide-y divide-gray-100 space-y-1">{items}</ul>;
    }
}
