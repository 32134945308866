import { Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import * as C from "@sp-crm/core";
import { UserPreferences } from "@sp-crm/core";
import { Checkbox } from "components/ui/checkbox";
import { defaultLinkStyle } from "components/ui/link";
import { TextArea } from "components/ui/textarea";
import * as React from "react";
import { useTenantSettings } from "store/selectors/hooks";
import { strings } from "../../../../constants/strings";
import Shared from "../../../shared";

export function defaultClientPDFOptions(
    client: C.IClient,
    userPreferences: UserPreferences,
): C.ClientIntakeCustomizationOptions {
    const userSettingsIncludeAdditionalContacts =
        userPreferences.showAdditionalContactsForReferrals;
    const userSettingsIncludeContactPhoneNumbers =
        userPreferences.showContactPhoneNumbersForReferrals;
    const userSettingsIncludeContactEmailAddrs =
        userPreferences.showContactEmailAddrsForReferrals;
    const userSettingsIncludeContactLocations =
        userPreferences.showContactLocationsForReferrals;
    const userSettingsIncludeClientBudget = userPreferences.showClientBudget;
    const userSettingsIncludeClientLocation = userPreferences.showClientLocation;
    const userSettingsIncludeCareProviders =
        userPreferences.showCareProvidersForReferrals;
    const userSettingsIncludeHealthInsurance =
        userPreferences.showHealthInsuranceForReferrals;
    return {
        includeAdditionalContacts: userSettingsIncludeAdditionalContacts,
        includeContactPhoneNumbers: userSettingsIncludeContactPhoneNumbers,
        includeContactEmailAddrs: userSettingsIncludeContactEmailAddrs,
        includeContactLocations: userSettingsIncludeContactLocations,
        includeMyContact: true,
        includeClientBudget: userSettingsIncludeClientBudget,
        includeClientLocation: userSettingsIncludeClientLocation,
        includeCareProviders: userSettingsIncludeCareProviders,
        includeHealthInsurance: userSettingsIncludeHealthInsurance,
        notesOption: "notesField",
        dateStamp: new Date(),
        freeformNotes: "",
        prebuiltNotes: {
            sameForBoth: client.additionalClient
                ? client.additionalClient.otherDetailsAnswersSameAsPrimaryClient
                : false,
            primaryClientNotes: client.otherHealthNotes,
            additionalClientNotes:
                client.additionalClient &&
                client.additionalClient.otherDetailsAnswersSameAsPrimaryClient === false
                    ? client.additionalClient.otherHealthNotes
                    : null,
        },
        filename: "",
    };
}

interface PdfCustomizationProps {
    options: C.ClientIntakeCustomizationOptions;
    callback: (newVal: C.ClientIntakeCustomizationOptions) => void;
}

export const ClientReferralPdfCustomization: React.FunctionComponent<
    PdfCustomizationProps
> = props => {
    const tenantSettings = useTenantSettings();
    const monthlyBudgetEnabled = tenantSettings.showClientMonthlyBudget;
    const [isVisible, setIsVisible] = React.useState(false);
    const toggleVisible = React.useCallback(
        (
            e?: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>,
        ) => {
            if (e && e.preventDefault) {
                e.preventDefault();
            }
            setIsVisible(v => !v);
        },
        [setIsVisible],
    );
    const addedTextChange = (str: string) => {
        const newOptions = { ...props.options, freeformNotes: str };
        props.callback(newOptions);
    };

    const inputControl: () => JSX.Element = () => {
        const freeform = (
            <div>
                <div>
                    <p>Type Notes to include in this Generated Client PDF.</p>
                    <p>
                        These notes will be saved in the generated PDF, but they will not
                        be saved permanently in the client profile.
                    </p>
                </div>
                <TextArea
                    rows={4}
                    value={props.options.freeformNotes}
                    onChange={e => addedTextChange(e.target.value)}
                />
            </div>
        );

        const notesField = (
            <div>
                <p>
                    Notes from this client&apos;s profile. They will be included in the
                    generated PDF.
                </p>
                <TextArea
                    disabled={true}
                    rows={4}
                    value={props.options.prebuiltNotes.primaryClientNotes}
                />
                {props.options.prebuiltNotes.additionalClientNotes !== null && (
                    <p>
                        <TextArea
                            disabled={true}
                            rows={4}
                            value={props.options.prebuiltNotes.additionalClientNotes}
                        />
                    </p>
                )}
            </div>
        );

        const none = (
            <div>
                <p>Only basic client profile data will be shown in the Generated PDF.</p>
                <p>No Notes Text will be included.</p>
            </div>
        );

        if (props.options.notesOption === "freeform") {
            return freeform;
        } else if (props.options.notesOption === "notesField") {
            return notesField;
        } else {
            return none;
        }
    };

    return (
        <div>
            <div className="mb-2">
                <button
                    onClick={toggleVisible}
                    className="flex items-center space-x-2 cursor-pointer">
                    <h3>Optional Client / Contact Details</h3>
                    <ChevronDownIcon
                        className={`w-6 h-6 transform transition duration-50 ${
                            isVisible ? "rotate-180" : "rotate-0"
                        }`}
                    />
                </button>
                {!isVisible ? (
                    <a
                        href="#"
                        className={`text-sm ${defaultLinkStyle}`}
                        onClick={toggleVisible}>
                        Show advanced options
                    </a>
                ) : null}
            </div>
            <Transition
                as="div"
                enter="transition-all duration-100 ease-in transform"
                enterFrom="opacity-0 -translate-y-16"
                enterTo="opacity-100 translate-y-0"
                leave="transition-all duration-75 ease-in transform"
                leaveTo="opacity-0 -translate-y-8"
                leaveFrom="opacity-100 translate-y-0"
                show={isVisible}>
                <div className="pdf-dialog-panel--section">
                    <Checkbox
                        checked={props.options.includeMyContact}
                        onChange={e =>
                            props.callback({
                                ...props.options,
                                includeMyContact: e.target.checked,
                            })
                        }
                        label="Include my contact details (name, phone, email)"
                    />
                    <Checkbox
                        checked={props.options.includeAdditionalContacts}
                        onChange={e =>
                            props.callback({
                                ...props.options,
                                includeAdditionalContacts: e.target.checked,
                            })
                        }
                        label="Include Additional Contacts' names"
                    />
                    <Checkbox
                        checked={props.options.includeContactEmailAddrs}
                        onChange={e =>
                            props.callback({
                                ...props.options,
                                includeContactEmailAddrs: e.target.checked,
                            })
                        }
                        label="Include contacts' email addresses"
                    />
                    <Checkbox
                        checked={props.options.includeContactPhoneNumbers}
                        onChange={e =>
                            props.callback({
                                ...props.options,
                                includeContactPhoneNumbers: e.target.checked,
                            })
                        }
                        label="Include contacts' phone numbers"
                    />
                    <Checkbox
                        checked={props.options.includeContactLocations}
                        onChange={e =>
                            props.callback({
                                ...props.options,
                                includeContactLocations: e.target.checked,
                            })
                        }
                        label="Include contacts' location details"
                    />
                    {monthlyBudgetEnabled ? (
                        <Checkbox
                            checked={props.options.includeClientBudget}
                            onChange={e =>
                                props.callback({
                                    ...props.options,
                                    includeClientBudget: e.target.checked,
                                })
                            }
                            label="Include client's monthly budget info"
                        />
                    ) : null}
                    <Checkbox
                        checked={props.options.includeClientLocation}
                        onChange={e =>
                            props.callback({
                                ...props.options,
                                includeClientLocation: e.target.checked,
                            })
                        }
                        label="Include client's current location details"
                    />
                    {tenantSettings.showPhysicianContacts ? (
                        <Checkbox
                            checked={props.options.includeCareProviders}
                            onChange={e =>
                                props.callback({
                                    ...props.options,
                                    includeCareProviders: e.target.checked,
                                })
                            }
                            label="Include client's care providers"
                        />
                    ) : null}
                    {tenantSettings.showInsuranceInfo ? (
                        <Checkbox
                            checked={props.options.includeHealthInsurance}
                            onChange={e =>
                                props.callback({
                                    ...props.options,
                                    includeHealthInsurance: e.target.checked,
                                })
                            }
                            label="Include client's health insurance details"
                        />
                    ) : null}
                </div>
                <div className="pdf-dialog-panel--section">
                    <h3>Notes</h3>
                    <div className="pdf-dialog-options">
                        <div>
                            <label>
                                <input
                                    type="radio"
                                    onChange={() =>
                                        props.callback({
                                            ...props.options,
                                            notesOption: "none",
                                        })
                                    }
                                    checked={props.options.notesOption === "none"}
                                />
                                &nbsp;Don&apos;t Include any Notes information in the PDF
                            </label>
                        </div>
                        <div>
                            <label>
                                <input
                                    type="radio"
                                    onChange={() =>
                                        props.callback({
                                            ...props.options,
                                            notesOption: "notesField",
                                        })
                                    }
                                    checked={props.options.notesOption === "notesField"}
                                />
                                &nbsp;Include the &quot;
                                {strings.clients.otherNotesLabel}
                                &quot; from client&apos;s profile (shown below)
                            </label>
                        </div>
                        <div>
                            <label>
                                <input
                                    type="radio"
                                    onChange={() =>
                                        props.callback({
                                            ...props.options,
                                            notesOption: "freeform",
                                        })
                                    }
                                    checked={props.options.notesOption === "freeform"}
                                />
                                &nbsp;Type new notes just for PDF (don&apos;t re-use the
                                &quot;
                                {strings.clients.otherNotesLabel}&quot; information)
                            </label>
                        </div>
                    </div>

                    <div className="pdf-dialog-text">{inputControl()}</div>
                </div>
                <div className="pdf-dialog-panel--section">
                    <h3>PDF Date Stamp</h3>
                    <Shared.DateInput
                        initial={props.options.dateStamp}
                        label=""
                        showTime={false}
                        onChange={e => props.callback({ ...props.options, dateStamp: e })}
                    />
                </div>
            </Transition>
        </div>
    );
};
