import { CalendarDate, formatCurrency } from "@sp-crm/core";
import { CalendarDateInput } from "components/ui/calendar-date";
import { Checkbox } from "components/ui/checkbox";
import { SortDown, SortUp } from "components/ui/icon";
import { Spinner } from "components/ui/spinner";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSortBy, useTable } from "react-table";
import { ApplicationState } from "store/state";
import { useClientPlacementRevenueQuery } from "../../../generated/graphql";
import { Actions } from "../../../store/actions";
import { Error } from "../../error";
import { Content, SectionHeader, Stage } from "../../layout";

export const ClientPlacementRevenue: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const endDateOnChangeHandler = (newdate: CalendarDate) => {
        dispatch({
            type: Actions[Actions.REPORT_PARAMETER_CLIENT_PLACEMENT_END_DATE_UPDATE],
            value: newdate,
        });
    };

    const startDateOnChangeHandler = (newdate: CalendarDate) => {
        dispatch({
            type: Actions[Actions.REPORT_PARAMETER_CLIENT_PLACEMENT_START_DATE_UPDATE],
            value: newdate,
        });
    };

    const showPerUserOnChangeHandler = (newVal: boolean) => {
        dispatch({
            type: Actions[Actions.REPORT_PARAMETER_CLIENT_PLACEMENT_SHOW_PER_USER_UPDATE],
            value: newVal,
        });
    };

    const startDate = useSelector(
        (state: ApplicationState) => state.reportParameters.clientPlacement.startDate,
    );

    const endDate = useSelector(
        (state: ApplicationState) => state.reportParameters.clientPlacement.endDate,
    );

    const userPivot = useSelector(
        (state: ApplicationState) => state.reportParameters.clientPlacement.showPerUser,
    );

    const region = useSelector((state: ApplicationState) => state.region.selectedRegion);

    const validQuery = region && region !== "" && startDate && endDate;

    return (
        <Stage>
            <SectionHeader title="Client Placements">
                <div className="max-w-lg lg:max-w-none lg:flex justify-between lg:space-x-3 items-end">
                    <Checkbox
                        label="Show per user"
                        checked={userPivot}
                        onChange={e => showPerUserOnChangeHandler(e.target.checked)}
                    />
                    <CalendarDateInput
                        label="Start"
                        value={startDate}
                        onChange={startDateOnChangeHandler}
                    />
                    <CalendarDateInput
                        label="End"
                        value={endDate}
                        onChange={endDateOnChangeHandler}
                    />
                </div>
            </SectionHeader>
            <Content>
                {validQuery && (
                    <Query
                        startDate={startDate.dayStart().toISOString()}
                        endDate={endDate.dayEnd().toISOString()}
                        region={region}
                        userPivot={userPivot}
                    />
                )}

                <em className="mt-8 block text-sm font-italic">
                    Move counts are shown based on the clients&apos;{" "}
                    <strong>Move Date</strong>, and revenue shown is based on the{" "}
                    <strong>Invoice Date</strong>.
                </em>
            </Content>
        </Stage>
    );
};

const Query: React.FC<{
    startDate: string;
    endDate: string;
    userPivot: boolean;
    region: string;
}> = props => {
    const queryResult = useClientPlacementRevenueQuery({
        startDate: props.startDate,
        endDate: props.endDate,
        region: props.region,
    });

    const columns = React.useMemo(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
        const result: any = [
            {
                Header: "User",
                accessor: "rowLabel",
            },
        ];
        if (
            queryResult &&
            queryResult.data &&
            queryResult.data.reportClientPlacementRevenue &&
            queryResult.data.reportClientPlacementRevenue.segments
        ) {
            queryResult.data.reportClientPlacementRevenue.segments.forEach(s => {
                result.push({
                    Header: s.startDate,
                    columns: [
                        {
                            Header: "Moves",
                            accessor: `${s.startDate}-placements`,
                        },
                        {
                            Header: "$",
                            accessor: `${s.startDate}-revenue`,
                        },
                    ],
                });
            });
        }
        return result;
    }, [queryResult?.data]); // eslint-disable-line react-hooks/exhaustive-deps

    const data = React.useMemo(() => {
        const result = [];
        if (
            props.userPivot &&
            queryResult &&
            queryResult.data &&
            queryResult.data.reportClientPlacementRevenue &&
            queryResult.data.reportClientPlacementRevenue.segments &&
            queryResult.data.reportClientPlacementRevenue.segments.length > 0 &&
            queryResult.data.reportClientPlacementRevenue.segments[0].users &&
            queryResult.data.reportClientPlacementRevenue.segments[0].users.length > 0
        ) {
            queryResult.data.reportClientPlacementRevenue.segments[0].users.forEach(
                (user, index) => {
                    const row = {
                        rowLabel: user.user ? user.user.displayName : "(unassigned)",
                    };
                    queryResult.data.reportClientPlacementRevenue.segments.forEach(
                        segment => {
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
                            (row as any)[`${segment.startDate}-revenue`] = formatCurrency(
                                segment.users[index].revenue,
                            );
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
                            (row as any)[`${segment.startDate}-placements`] =
                                segment.users[index].moves;
                        },
                    );
                    result.push(row);
                },
            );
        }
        if (
            queryResult &&
            queryResult.data &&
            queryResult.data.reportClientPlacementRevenue &&
            queryResult.data.reportClientPlacementRevenue.segments
        ) {
            const row = { rowLabel: "Total", special: true };
            queryResult.data.reportClientPlacementRevenue.segments.forEach(segment => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
                (row as any)[`${segment.startDate}-revenue`] = formatCurrency(
                    segment.revenue,
                );
                // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
                (row as any)[`${segment.startDate}-placements`] = segment.moves;
            });
            result.push(row);
        }
        return result;
    }, [queryResult?.data, props.userPivot]); // eslint-disable-line react-hooks/exhaustive-deps

    const table = useTable(
        {
            columns,
            data,
        },
        useSortBy,
    );

    if (queryResult.isLoading) {
        return <Spinner />;
    }
    if (queryResult.error) {
        return (
            <Error componentName="ClientPlacementRevenue.Query">
                {/* eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction */}
                {(queryResult.error as any).message}
            </Error>
        );
    }
    return (
        <table {...table.getTableProps()}>
            <thead>
                {table.headerGroups.map(headerGroup => (
                    // eslint-disable-next-line react/jsx-key
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => {
                            return (
                                // eslint-disable-next-line react/jsx-key
                                <th
                                    className={`py-1 px-2 bg-gray-200 text-gray-600 ${
                                        column.Header === "Moves" || column.Header === "$"
                                            ? "text-xs leading-tight"
                                            : "uppercase text-sm leading-normal"
                                    } ${
                                        column.headers || column.Header === "Moves"
                                            ? "border-l border-gray-300 pl-4"
                                            : ""
                                    }`}
                                    {...column.getHeaderProps(
                                        column.getSortByToggleProps(),
                                    )}>
                                    <span>
                                        {column.isSorted ? (
                                            column.isSortedDesc ? (
                                                <SortDown className="mr-1" />
                                            ) : (
                                                <SortUp className="mr-1" />
                                            )
                                        ) : (
                                            ""
                                        )}
                                    </span>
                                    <span>{column.render("Header")}</span>
                                </th>
                            );
                        })}
                    </tr>
                ))}
            </thead>
            <tbody {...table.getTableBodyProps()}>
                {table.rows.map((row, i) => {
                    table.prepareRow(row);
                    return (
                        // eslint-disable-next-line react/jsx-key
                        <tr
                            className={`border-b border-gray-200 hover:bg-gray-100 ${
                                i % 2 === 0 ? "bg-gray-50" : "bg-white"
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
                            } ${(row.original as any).special && "font-bold"}`}
                            {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                return (
                                    // eslint-disable-next-line react/jsx-key
                                    <td
                                        className={`py-1 px-2 ${
                                            cell.column.Header === "Moves"
                                                ? "border-l border-gray-300 pl-4"
                                                : ""
                                        }`}
                                        {...cell.getCellProps()}>
                                        {cell.render("Cell")}
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};
