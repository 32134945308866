import { CommunityRelationshipType, IClient, Localization } from "@sp-crm/core";
import { Modal } from "components/ui/panel/modal";
import { SecondaryButton } from "components/ui/secondary-button";
import { Toggle } from "components/ui/toggle";
import React, { useState } from "react";
import { ClientCommunityComparisonTable } from "./community-comparison/client-community-comparison-table";

interface CompareChartModalProps {
    client: IClient;
    locale: Localization;
}

export const CompareChartModal: React.FC<CompareChartModalProps> = props => {
    const [isFamilyView, setIsFamilyView] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const hide = () => setIsOpen(false);
    const show = () => setIsOpen(true);
    return (
        <div>
            <SecondaryButton onClick={show}>
                <span className="text-sm">Comparison chart</span>
            </SecondaryButton>
            <Modal onDismiss={hide} isOpen={isOpen} headerText="Community Comparison">
                <div className="mb-2 flex items-center space-x-2">
                    <Toggle
                        label="Family view"
                        checked={isFamilyView}
                        onChange={setIsFamilyView}
                    />

                    <div
                        className={`text-gray-600 text-sm transition delay-75 duration-75 ease-in-out ${
                            isFamilyView ? "opacity-0" : "opactiy-1"
                        }`}>
                        Showing all matched communities
                    </div>
                </div>
                <ClientCommunityComparisonTable
                    clientId={props.client.id}
                    baseLocation={props.client.baseLocation}
                    locale={props.locale}
                    excludedRelationshipTypes={
                        isFamilyView
                            ? [
                                  CommunityRelationshipType.excluded,
                                  CommunityRelationshipType.matched,
                              ]
                            : [CommunityRelationshipType.excluded]
                    }
                />
            </Modal>
        </div>
    );
};
