import { IClientIntakeDetailInfo, IInsurance } from "@sp-crm/core";
import { CrmPersona } from "components/ui/persona";
import React, { FunctionComponent, useCallback, useState } from "react";
import { ExpandIndicator } from "../../../shared/expand-indicator";
import Details from "./details";

const isInsuranceInfoEmpty = (insuranceInfo: IInsurance) => {
    if (!insuranceInfo) {
        return true;
    }

    for (const pair of Object.entries(insuranceInfo)) {
        const value = pair[1];
        if (value && value !== "") {
            return false;
        }
    }
    return true;
};

type ClientDetailsProps = {
    client: IClientIntakeDetailInfo;
    personaTitle: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    onFieldChange: (fieldName: string, newValue: any) => void;
    additionalClassNames?: string;
};

const ClientDetails: FunctionComponent<ClientDetailsProps> = ({
    client,
    personaTitle,
    onFieldChange,
    additionalClassNames,
}) => {
    const [expandClientQuestions, setExpandClientQuestions] = useState(
        !isInsuranceInfoEmpty(client.insuranceInfo),
    );
    const toggleExpandClientQuestions = useCallback(
        () => setExpandClientQuestions(!expandClientQuestions),
        [expandClientQuestions],
    );

    return (
        <div className={additionalClassNames}>
            <div
                className="flex-space-between flex-row-no-bottom-margin expandable-section"
                onClick={toggleExpandClientQuestions}>
                <CrmPersona name={personaTitle} size="small" />
                <ExpandIndicator expanded={expandClientQuestions} />
            </div>
            {expandClientQuestions && (
                <Details client={client} onFieldChange={onFieldChange} />
            )}
        </div>
    );
};

export default ClientDetails;
