import {
    AnnotationType,
    InteractionType,
    IQuestion,
    isCustomizableSection,
    Perspective,
    QuestionCategories,
} from "@sp-crm/core";
import { Radio, RadioOption } from "components/shared/radio";
import * as React from "react";

const annotationOptionsForNarySingle: RadioOption[] = [
    { key: "none", text: "Disabled" },
    { key: "single", text: "Enabled" },
];

const annotationOptionsForNaryMultiple: RadioOption[] = [
    { key: "none", text: "Disabled" },
    {
        key: "single",
        text: "Single notes box after the multiple choice group",
        helpText: "Show single notes field if any options are checked.",
    },
    {
        key: "perAnswer",
        text: "Inline notes field per checked option",
        helpText: "Show a notes field for each option that is checked.",
    },
];

interface Props {
    question: IQuestion;
    // eslint-disable-next-line @typescript-eslint/ban-types -- eslintintroduction
    updateQuestion: <T extends {}>(
        cb: (q: IQuestion, val: T) => void,
    ) => (val: T) => void;
}
export const EditAnnotations: React.FunctionComponent<Props> = props => {
    const annotationLabel = "Show additional notes field when options are checked";
    const additionalClassNameColumn1 =
        props.question.legacyClientInteraction != InteractionType.none
            ? "input-form-block-no-bottom-margin setting-column1"
            : "";
    const additionalClassNameColumn2 =
        props.question.legacyCommunityInteraction != InteractionType.none
            ? "input-form-block-no-bottom-margin setting-column2"
            : "";

    return (
        <div className="flex-row-no-bottom-margin">
            <div className={"full-width " + additionalClassNameColumn1}>
                {props.question.legacyClientInteraction !== InteractionType.none &&
                    isCustomizableSection(
                        Perspective.Client,
                        props.question.category as QuestionCategories,
                    ) && (
                        <Radio
                            options={
                                props.question.legacyClientInteraction ===
                                InteractionType.multiple
                                    ? annotationOptionsForNaryMultiple
                                    : annotationOptionsForNarySingle
                            }
                            value={AnnotationType[props.question.legacyClientAnnotation]}
                            label={annotationLabel}
                            onChange={props.updateQuestion((q, v) => {
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
                                const annotationType = AnnotationType[v as any] as any;
                                const enabled = annotationType !== AnnotationType.none;
                                q.legacyClientAnnotation = annotationType;
                                q.prompts.forEach(
                                    p => (p.legacyClientAnnotation = enabled),
                                );
                            })}
                        />
                    )}
            </div>
            <div className={"full-width " + additionalClassNameColumn2}>
                {props.question.legacyCommunityInteraction !== InteractionType.none &&
                    isCustomizableSection(
                        Perspective.Community,
                        props.question.category as QuestionCategories,
                    ) && (
                        <Radio
                            options={
                                props.question.legacyCommunityInteraction ===
                                InteractionType.multiple
                                    ? annotationOptionsForNaryMultiple
                                    : annotationOptionsForNarySingle
                            }
                            value={
                                AnnotationType[props.question.legacyCommunityAnnotation]
                            }
                            label={annotationLabel}
                            onChange={props.updateQuestion((q, v) => {
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
                                const annotationType = AnnotationType[v as any] as any;
                                const enabled = annotationType !== AnnotationType.none;
                                q.legacyCommunityAnnotation = annotationType;
                                q.prompts.forEach(
                                    p => (p.legacyCommunityAnnotation = enabled),
                                );
                            })}
                        />
                    )}
            </div>
            <div className="full-width"></div>
        </div>
    );
};
