import * as C from "@sp-crm/core";
import { ClientId, ContactId, IClient, parseEntityId } from "@sp-crm/core";
import { BridgeQuestionShim } from "components/questions/bridge-question-shim";
import { EntityTasksShim } from "components/tasks/child/entity-tasks-shim";
import * as React from "react";
import { connect } from "react-redux";
import { Action, Dispatch } from "redux";
import { createChangeRegionAction } from "store/actions/region";
import { getClient } from "store/selectors/selectors";
import { usersForCurrentRegion } from "store/selectors/users";
import { ApplicationState } from "store/state";
import { AdvancedSearchEntityType, CommunityOrdering } from "../../../generated/graphql";
import * as actions from "../../../store/actions";
import {
    loadClient,
    updateClient,
    updateClientAnswer,
    updateClientMultiField,
    updateClientPromise,
} from "../../../store/actions";
import {
    getShareLink,
    reactivateClient,
    updateClientCommunityOrder,
} from "../../../store/actions/clients";
import { loadLeafElementsForEntity } from "../../../store/actions/entity";
import { SingleUserState } from "../../../store/reducers/user";
import { UserState } from "../../../store/reducers/users";
import Util from "../../../util";
import { ShowClient, Subpage } from "./index";

type ViewState = "loading" | "viewable";

interface ShowClientFromRouteOwnProps {
    clientId: ClientId;
    activeSubpage: string;
}

interface ShowClientFromRoutePropsFromState {
    client: IClient;
    users: C.User[];
    user: C.User;
    viewState: ViewState;
    now: Date;
    pdfRenderable: boolean;
    tenant: C.ITenant;
    fileCount: number;
    newlyAddedContactPerson?: ContactId;
    selectedRegionId: C.RegionId | null;
}

interface ShowClientFromRoutePropsFromDispatch {
    onUpdateAnswer: (clientId: ClientId, answer: C.IAnswer) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    onFieldChange: (clientId: ClientId, fieldName: string, newValue: any) => void;
    onFieldChangePromise: (
        clientId: ClientId,
        fieldName: string,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
        newValue: any,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    ) => Promise<any>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    onMultiFieldChange: (clientId: ClientId, newValues: any) => void;
    triggerClientLoad: (id: string) => void;
    navigate: (path: string) => void;
    reactivate: () => void;
    getShareLink: () => Promise<string>;
    onClientCommunityOrderChange: (
        clientId: ClientId,
        ordering: CommunityOrdering[],
    ) => void;
    changeRegion: (regionKey: string) => void;
}

type ShowClientFromRouteProps = ShowClientFromRoutePropsFromState &
    ShowClientFromRoutePropsFromDispatch &
    ShowClientFromRouteOwnProps;

class ShowClientFromRoute extends React.Component<ShowClientFromRouteProps, undefined> {
    UNSAFE_componentWillMount() {
        this.props.triggerClientLoad(this.props.clientId);
    }

    componentDidUpdate(): void {
        if (
            this.props.selectedRegionId &&
            this.props.client &&
            this.props.viewState === "viewable" &&
            this.props.client.regionId &&
            this.props.client.regionId !== this.props.selectedRegionId
        ) {
            this.props.changeRegion(this.props.client.region);
        }
    }

    onFieldChange(fieldName: string, newValue: string): void {
        this.props.onFieldChange(this.props.clientId, fieldName, newValue);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    onFieldChangePromise(fieldName: string, newValue: string): Promise<any> {
        return this.props.onFieldChangePromise(this.props.clientId, fieldName, newValue);
    }

    onMultiFieldChange(newValues: string): void {
        this.props.onMultiFieldChange(this.props.clientId, newValues);
    }

    onUpdateAnswer(answer: C.IAnswer) {
        this.props.onUpdateAnswer(this.props.clientId, answer);
    }

    activeSubpage(): Subpage {
        if (this.props.activeSubpage) {
            if (this.props.activeSubpage == "overview") return "overview";
            if (this.props.activeSubpage == "needs") return "needs";
            if (this.props.activeSubpage == "communities") return "communities";
            if (this.props.activeSubpage == "tasks") return "tasks";
            if (this.props.activeSubpage == "compareCommunities")
                return "compareCommunities";
            if (this.props.activeSubpage == "files") return "files";
            if (this.props.activeSubpage == "invoices") return "invoices";
            if (this.props.activeSubpage == "moves") return "moves";
            if (this.props.activeSubpage == "referrals") return "referrals";
            if (this.props.activeSubpage == "emailsSent") return "emailsSent";
            if (this.props.activeSubpage == "activity") return "activity";
        }
        return null;
    }

    selectSubpage(section: Subpage) {
        this.props.navigate(`/clients/show/${this.props.clientId}/${section}`);
    }

    closeSection() {
        this.props.navigate(`/clients/show/${this.props.clientId}`);
    }

    delete() {
        this.props.onFieldChange(this.props.clientId, "deleted", true);
        this.props.navigate("/clients");
    }

    navigateToClients() {
        this.props.navigate("/clients");
    }

    showClient(): JSX.Element {
        const client = (
            <BridgeQuestionShim
                entityName={AdvancedSearchEntityType.Client}
                regionKeys={[this.props.client.region]}>
                {questions => (
                    <EntityTasksShim parentId={this.props.clientId}>
                        {({ tasks, refetchTasks, tasksLoaded }) => (
                            <ShowClient
                                key={`show-client-${this.props.client.id}`}
                                client={this.props.client}
                                onFieldChange={(n, v) => this.onFieldChange(n, v)}
                                onFieldChangeMulti={v => this.onMultiFieldChange(v)}
                                newlyAddedContactPerson={
                                    this.props.newlyAddedContactPerson
                                }
                                onUpdateAnswer={answer => this.onUpdateAnswer(answer)}
                                onClientCommunityOrderChange={
                                    this.props.onClientCommunityOrderChange
                                }
                                delete={this.delete.bind(this)}
                                questions={questions}
                                users={this.props.users}
                                user={this.props.user}
                                tenant={this.props.tenant}
                                now={this.props.now}
                                activeSubpage={this.activeSubpage()}
                                selectSection={(section: Subpage) =>
                                    this.selectSubpage(section)
                                }
                                closeSection={() => this.closeSection()}
                                pdfRenderable={this.props.pdfRenderable}
                                fileCount={this.props.fileCount}
                                reactivate={this.props.reactivate}
                                getShareLink={this.props.getShareLink}
                                navigateToClients={() => this.navigateToClients()}
                                tasks={tasks}
                                refetchTasks={refetchTasks}
                                tasksLoaded={tasksLoaded}
                            />
                        )}
                    </EntityTasksShim>
                )}
            </BridgeQuestionShim>
        );
        return <span>{client}</span>;
    }

    render() {
        if (this.props.viewState == "viewable") {
            return this.showClient();
        } else if (this.props.viewState == "loading") {
            return <div>Loading...</div>;
        } else {
            return <div>Sorry, we&apos;ve encountered an error. Please reload.</div>;
        }
    }
}

function mapDispatchToProps(
    dispatch: Dispatch<Action>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    ownProps: any,
): ShowClientFromRoutePropsFromDispatch {
    const clientId: ClientId = ownProps.clientId;
    const val = {
        triggerClientLoad: (clientId: ClientId) => {
            loadClient(clientId, dispatch);
            loadLeafElementsForEntity(clientId, dispatch);
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
        onFieldChange: (clientId: ClientId, fieldName: string, newValue: any) => {
            updateClient(clientId, fieldName, newValue, dispatch);
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
        onFieldChangePromise: (clientId: ClientId, fieldName: string, newValue: any) => {
            return updateClientPromise(clientId, fieldName, newValue, dispatch);
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
        onMultiFieldChange: (clientId: ClientId, newValues: any) => {
            updateClientMultiField(clientId, newValues, dispatch);
        },

        onUpdateAnswer: (clientId: ClientId, answer: C.IAnswer) => {
            updateClientAnswer(clientId, answer, dispatch);
        },
        navigate: (path: string) => {
            actions.navigate(path);
        },
        reactivate: () => {
            reactivateClient(clientId, dispatch);
        },
        getShareLink: () => {
            return getShareLink(clientId, dispatch);
        },
        onClientCommunityOrderChange: (
            clientId: ClientId,
            ordering: CommunityOrdering[],
        ) => {
            updateClientCommunityOrder(clientId, ordering, dispatch);
        },
        changeRegion: (regionKey: string) => {
            dispatch(createChangeRegionAction(regionKey));
        },
    };
    return val;
}

function mapStateToProps(
    state: ApplicationState,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    ownProps: any,
): ShowClientFromRoutePropsFromState {
    const clientId = parseEntityId<ClientId>(ownProps.clientId);
    const allUsers = (state.users as UserState).users;
    const loggedInUserId = (state.userState as SingleUserState).userId;
    const users = Util.mapToArray(usersForCurrentRegion(state));
    const user = allUsers[loggedInUserId];
    const clientState = state.clients;
    let client: IClient = null;
    let viewState: ViewState = "loading";
    if (clientState.loading.find(x => x === clientId)) {
        viewState = "loading";
    }
    if (
        clientState.clients[clientId] &&
        clientState.fullyLoadedClients.find(x => x === clientId)
    ) {
        client = getClient(state, clientId);
        viewState = "viewable";
    }

    let fileCount = 0;
    if (state.files.files && state.files.files[clientId]) {
        fileCount = state.files.files[clientId].length;
    }

    const newlyAddedContactPerson = state.selectedContacts.selections[clientId];

    if (!client) {
        viewState = "loading";
    }

    return {
        client,
        users,
        user,
        viewState,
        now: state.time.now,
        pdfRenderable: true,
        tenant: state.tenant.tenant,
        fileCount,
        newlyAddedContactPerson,
        selectedRegionId:
            state.region.regions.find(r => r.key === state.region.selectedRegion)?.id ||
            null,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ShowClientFromRoute);
