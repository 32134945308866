import { EntityNotFoundError, parseEntityId } from "@sp-crm/core";

export const parseRouteId = <T>(entityType: string, routeId: string): T => {
    try {
        return parseEntityId<T>(routeId);
    } catch (e) {
        if (e?.name === "InvalidUUIDException") {
            throw new EntityNotFoundError(entityType, routeId as string);
        }

        throw e;
    }
};
