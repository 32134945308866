import React, { useCallback, useEffect } from "react";
import { defaultLinkStyle } from "./link";

const clampOptions = {
    3: "line-clamp-3",
} as const;

interface ClampedTextProps {
    lines: keyof typeof clampOptions;
    children: React.ReactNode;
}

export const ClampedText: React.FC<ClampedTextProps> = props => {
    const { lines, children } = props;
    const [isShowAllToggled, setIsShowAllToggled] = React.useState(false);
    const [hasTextOverflowed, setHasTextOverflowed] = React.useState(false);
    const textRef = React.useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (!textRef.current) {
            return;
        }
        if (textRef.current.scrollHeight > textRef.current.clientHeight) {
            setHasTextOverflowed(true);
        }
    }, [textRef.current]); // eslint-disable-line react-hooks/exhaustive-deps
    const toggle = useCallback(() => {
        setIsShowAllToggled(t => !t);
    }, []);
    return (
        <div>
            <div ref={textRef} className={isShowAllToggled ? "" : clampOptions[lines]}>
                {children}
            </div>
            {hasTextOverflowed ? (
                <button onClick={toggle} className={`${defaultLinkStyle} text-sm`}>
                    {isShowAllToggled ? "Show less" : "Show more"}
                </button>
            ) : null}
        </div>
    );
};
