import { ICommunity } from "@sp-crm/core";
import { QueryRenderer } from "components/clients/show-client/community-comparison/query-renderer";
import { useSearchClientsQuery } from "generated/graphql";
import * as React from "react";
import { useRegionId, useResponsiveMode, useUsers } from "store/selectors/hooks";
import { ListClient } from "../../clients/list-client";

interface CommunityInboundReferralsProps {
    community: ICommunity;
}

export const CommunityInboundReferrals: React.FC<
    CommunityInboundReferralsProps
> = props => {
    const { community } = props;
    const users = useUsers();
    const responsiveMode = useResponsiveMode();
    const regionId = useRegionId();
    const getClientsQuery = useSearchClientsQuery({
        regionId,
        referralCommunityId: community.id,
        page: 1,
        perPage: 1000,
    });

    return (
        <QueryRenderer
            name="CommunityInboundReferrals.getClientsQuery"
            query={getClientsQuery}>
            {data => {
                if (data.getClients.clients.length === 0) {
                    return (
                        <div>
                            <h3>
                                No Inbound Client Referrals have been received from this
                                community yet.
                            </h3>
                            <br />
                            <p>
                                Note: when editing a Client profile, if you mark any
                                client&apos;s &quot;Referral Source&quot; as this
                                community, that client will then be listed here.
                            </p>
                        </div>
                    );
                }

                return (
                    <div>
                        <p>
                            The table below shows all Clients referred to you (or your
                            agency) that have come from this community ({community.name}
                            ).
                        </p>
                        <br />
                        <ListClient
                            showAssignedToColumn={false}
                            users={users.users}
                            clients={data.getClients.clients}
                            tableShort={true}
                            responsiveMode={responsiveMode}
                            sourceType={"fromReferral"}
                        />
                    </div>
                );
            }}
        </QueryRenderer>
    );
};
