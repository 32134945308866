import { TenantRegion } from "@sp-crm/core";
import {
    UserManagementGetRolesQuery,
    UserManagementGetUsersQuery,
} from "generated/graphql";
import React from "react";

interface UserPermissionsCellProps {
    regions: TenantRegion[];
    permissions: UserManagementGetUsersQuery["userManagementGetUsers"][0]["user"]["permissions"];
    roles: UserManagementGetRolesQuery["userManagementGetRoles"] | null;
}

const getRoleName = (
    permissions: UserManagementGetUsersQuery["userManagementGetUsers"][0]["user"]["permissions"],
    regionKey: string,
    roles: UserManagementGetRolesQuery["userManagementGetRoles"] | null,
): string => {
    const matchingPermission = permissions.find(p => p.region.key === regionKey);

    if (!matchingPermission) {
        return "No access";
    }

    if (matchingPermission.role?.startsWith("custom:")) {
        return "Custom";
    }

    const matchingRole = roles?.find(r => r.key === matchingPermission.role);

    if (matchingRole?.humanReadableName) {
        return matchingRole.humanReadableName;
    }

    return "No access";
};

export const UserPermissionsCell: React.FC<UserPermissionsCellProps> = props => {
    const { regions, permissions, roles } = props;

    const multiRegion = regions.length > 1;

    return (
        <ul>
            {regions.map(region => {
                const roleName = getRoleName(permissions, region.key, roles);

                return (
                    <li key={region.key}>
                        {multiRegion ? `${region.name}: ${roleName}` : roleName}
                    </li>
                );
            })}
        </ul>
    );
};
