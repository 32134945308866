import { DateInput } from "components/ui/date-input";
import { PrimaryButton } from "components/ui/primary-button";
import { Radio } from "components/ui/radio";
import { SecondaryButton } from "components/ui/secondary-button";
import { Spinner } from "components/ui/spinner";
import { DisclosureAcknowledgementResponse } from "generated/graphql";
import React, { useCallback, useState } from "react";

export interface ManualDisclosureAcknowledgementPayload {
    agreed: boolean;
    response: DisclosureAcknowledgementResponse;
    dateAdded: Date;
}

interface ManualDisclosureAcknowledgementFormProps {
    onSubmit: (payload: ManualDisclosureAcknowledgementPayload) => void;
    onCancel: () => void;
}

export const ManualDisclosureAcknowledgementForm: React.FC<
    ManualDisclosureAcknowledgementFormProps
> = props => {
    const { onSubmit, onCancel } = props;
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [response, setResponse] = useState<DisclosureAcknowledgementResponse>(
        DisclosureAcknowledgementResponse.Agreed,
    );
    const [dateAdded, setDateAdded] = useState<Date>(new Date());
    const formDisabled = submitting;

    const handleSubmit = useCallback(
        async (e: React.FormEvent<HTMLFormElement>) => {
            e && e.preventDefault && e.preventDefault();
            setSubmitting(true);
            onSubmit({
                agreed: response === DisclosureAcknowledgementResponse.Agreed,
                dateAdded,
                response,
            });
        },
        [response, dateAdded, onSubmit],
    );

    const handleResponseChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setResponse(e.target.value as DisclosureAcknowledgementResponse);
            if (e.target.value === DisclosureAcknowledgementResponse.NotRequired) {
                setDateAdded(new Date());
            }
        },
        [setResponse],
    );

    const handleCancel = React.useCallback(
        async (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
            e.preventDefault();
            onCancel();
        },
        [onCancel],
    );

    return (
        <div>
            <form onSubmit={handleSubmit} className="space-y-3 mt-2">
                <Radio
                    checked={response === DisclosureAcknowledgementResponse.NotRequired}
                    value={DisclosureAcknowledgementResponse.NotRequired}
                    label="No disclosure required (older adult is independent)"
                    onChange={handleResponseChange}
                />
                <Radio
                    checked={response === DisclosureAcknowledgementResponse.Declined}
                    value={DisclosureAcknowledgementResponse.Declined}
                    label="Older adult declines to acknowledge"
                    onChange={handleResponseChange}
                />
                <Radio
                    checked={response === DisclosureAcknowledgementResponse.Agreed}
                    value={DisclosureAcknowledgementResponse.Agreed}
                    label="The client acknowledged and disclosure is attached"
                    onChange={handleResponseChange}
                />
                {response === DisclosureAcknowledgementResponse.Agreed ||
                response === DisclosureAcknowledgementResponse.Declined ? (
                    <DateInput label="Date" value={dateAdded} onChange={setDateAdded} />
                ) : null}
                <div className="flex space-x-4 items-center">
                    <PrimaryButton type="submit" disabled={formDisabled}>
                        Submit
                    </PrimaryButton>
                    <SecondaryButton onClick={handleCancel}>Cancel</SecondaryButton>
                    {submitting ? <Spinner /> : null}
                </div>
            </form>
        </div>
    );
};
