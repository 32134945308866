import { IDetails } from "@sp-crm/core";

export const categoryLabel = (details: IDetails) => {
    if (details.clientEnabled && details.communityEnabled) {
        if (details.clientHeading === details.communityHeading) {
            return details.clientHeading;
        }
        return `${details.clientHeading}; ${details.communityHeading}`;
    }
    if (details.clientEnabled && !details.communityEnabled) {
        return details.clientHeading + " (Client Intake form only)";
    }
    if (!details.clientEnabled && details.communityEnabled) {
        return details.communityHeading + " (Community Attributes Only)";
    }

    return "(unknown)";
};
