import { CommunityId } from "@sp-crm/core";
import React from "react";
import { useGetCommunityAdvancedQuery } from "../../../generated/graphql";
import { ErrorMessage } from "../../ui/error-message";
import { Spinner } from "../../ui/spinner";

interface CommunityAdvancedDetailProps {
    communityId: CommunityId;
}

interface CommunityMasterDetails {
    masterId: string | null;
    masterVersion: number | null;
}

export const CommunityAdvancedDetail: React.FC<CommunityAdvancedDetailProps> = props => {
    const [masterInfo, setMasterInfo] = React.useState<CommunityMasterDetails>({
        masterId: null,
        masterVersion: null,
    });
    const query = useGetCommunityAdvancedQuery({
        id: props.communityId,
    });

    if (query.isLoading) {
        return <Spinner />;
    }

    if (query.isError) {
        return (
            <ErrorMessage component="CommunityAdvancedDetail">
                <pre>{JSON.stringify(query.error, null, 2)}</pre>
            </ErrorMessage>
        );
    }

    if (
        query.data &&
        query.data.getCommunity &&
        (masterInfo.masterId !== query.data.getCommunity.communityMasterId ||
            query.data.getCommunity.communityMasterVersion !== masterInfo.masterVersion)
    ) {
        setMasterInfo({
            masterId: query.data.getCommunity.communityMasterId,
            masterVersion: query.data.getCommunity.communityMasterVersion ?? null,
        });
    }

    return (
        <div>
            <h4 className="text-lg">Detail view</h4>
            <h4>Combined</h4>
            <pre>{JSON.stringify(query.data?.getCommunity, null, 2)}</pre>
        </div>
    );
};
