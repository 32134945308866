import { PrimaryButton } from "components/ui/primary-button";
import { SecondaryButton } from "components/ui/secondary-button";
import * as React from "react";

interface SearchCalloutButtonsProps {
    onClear: () => void;
    onSubmit: () => void;
}

export class SearchCalloutButtons extends React.Component<
    SearchCalloutButtonsProps,
    undefined
> {
    render() {
        return (
            <div className="flex space-x-2 mt-4 items-center justify-end">
                <SecondaryButton onClick={() => this.props.onClear()} type="button">
                    Clear
                </SecondaryButton>
                <PrimaryButton onClick={() => this.props.onSubmit()}>Apply</PrimaryButton>
            </div>
        );
    }
}
