import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { persistor, store } from "store/state";
import { crmHistory } from "util/history";
import { trackRegion } from "util/sync-state";
import { App } from "./components/app/index";
import "./master.css";
import { startHeartbeats, startTicks } from "./store/actions";
import { configureErrorTracking } from "./util/error-tracking";
import { configureUserEvents } from "./util/user-events";
import { userEvents } from "./util/version";

configureErrorTracking();
if (userEvents) {
    configureUserEvents();
}

/**
 * When the browsing history is changes (back, forward buttons etc) blur the current active element to ensure changes are saved.
 *
 * @param callback callback to run when changing the browsing history
 */
const editPopstate = (callback: () => void) => {
    window.onpopstate = () => {
        callback();
    };
};

editPopstate(() => {
    const element = document.activeElement as HTMLElement;
    if (typeof element.blur === "function") {
        element.blur();
    }
});

startTicks(store.dispatch);
startHeartbeats(store.dispatch);
store.subscribe(() => trackRegion(store.getState().region));

function render(): void {
    const rootElement = document.getElementById("root");
    const root = ReactDOM.createRoot(rootElement);
    root.render(<App store={store} persistor={persistor} history={crmHistory} />);
}

render();
