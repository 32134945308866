import { ReferenceBusinessId } from "@sp-crm/core";
import {
    AdvancedSearchResults,
    RenderRowActionParams,
} from "components/advanced-search/advanced-search-results";
import { SearchInputDebounced } from "components/ui/search-input-debounced";
import { SecondaryButton } from "components/ui/secondary-button";
import { produce } from "immer";
import React, { useState } from "react";
import { useRegionId, useTenantSettings } from "store/selectors/hooks";
import {
    AdvancedSearchConditionNodeType,
    AdvancedSearchEntityType,
    AdvancedSearchRequest,
    FieldConditionOperator,
} from "../../../generated/graphql";

interface ReferenceBusinessPickerProps {
    onSelect: (referenceId: ReferenceBusinessId) => void;
}

export const ReferenceBusinessPicker: React.FC<ReferenceBusinessPickerProps> = props => {
    const { onSelect } = props;
    const regionId = useRegionId();
    const isReferralPreferenceEnabled = useTenantSettings().ownedReferralSources;

    const initialRequest: AdvancedSearchRequest = {
        entityType: AdvancedSearchEntityType.ReferenceBusiness,
        select: [
            {
                title: "Name",
                fieldName: "businessContactEntity.name",
            },
            isReferralPreferenceEnabled ? { fieldName: "ownerId" } : null,
        ].filter(s => !!s),
        sort: { column: "businessContactEntity.name", ascending: true },
        condition: {
            nodeType: AdvancedSearchConditionNodeType.And,
            children: [
                {
                    nodeType: AdvancedSearchConditionNodeType.RelationCondition,
                    fieldName: "businessContactEntity",
                    children: [
                        {
                            nodeType: AdvancedSearchConditionNodeType.FieldCondition,
                            fieldName: "name",
                            operator: FieldConditionOperator.StringContains,
                            textValue: "",
                        },
                    ],
                },
                {
                    nodeType: AdvancedSearchConditionNodeType.FieldCondition,
                    operator: FieldConditionOperator.StringEquals,
                    fieldName: "regionId",
                    textValue: regionId,
                },
            ],
        },
    };

    const [searchRequest, setSearchRequest] =
        useState<AdvancedSearchRequest>(initialRequest);

    const handleSearchInput = React.useCallback(
        (value: string) => {
            setSearchRequest(prev =>
                produce(prev, draft => {
                    draft.condition.children[0].children[0].textValue = value;
                }),
            );
        },
        [setSearchRequest],
    );

    const renderRowAction = React.useCallback(
        (params: RenderRowActionParams) => {
            return (
                <SecondaryButton
                    className="text-sm"
                    onClick={e => {
                        e.preventDefault();
                        onSelect(params.record.id as ReferenceBusinessId);
                    }}>
                    Select
                </SecondaryButton>
            );
        },
        [onSelect],
    );

    return (
        <div>
            <SearchInputDebounced
                initial={""}
                onChange={handleSearchInput}
                placeholder="Search for organization"
                autoFocus
            />
            <AdvancedSearchResults
                request={searchRequest}
                onChangeRequest={setSearchRequest}
                renderRowAction={renderRowAction}
            />
        </div>
    );
};
