import { ICommunity, MessageOptions } from "@sp-crm/core";
import { SelectPro } from "components/ui/select-pro";
import * as React from "react";

export const SelectCommunities = ({
    communities,
    message,
    updateSelectedCommunity,
}: {
    communities: ICommunity[];
    message: MessageOptions;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    updateSelectedCommunity: (newValue: any) => void;
}): JSX.Element => {
    const options = React.useMemo(
        () =>
            (communities || [])
                .sort((a, b) => (a.name || "").localeCompare(b.name || ""))
                .map(community => {
                    return {
                        value: community.id,
                        text: community.name,
                    };
                }),
        [communities],
    );

    let initial = message.communityId || "";
    if (!initial) {
        initial = options[0].value;
        updateSelectedCommunity(initial);
    }

    return (
        <SelectPro
            // no placeholder option - the customer has to select something,
            // and by calling updateSelectedCommunity above, we guarantee
            // some community's recipients got selected and populated.
            includePlaceholderOption={false}
            value={initial}
            options={options}
            onChange={e => updateSelectedCommunity(e.target.value)}
            label="Community"
        />
    );
};
