import { ContentFull, ContentHeader } from "components/layout";
import { Select } from "components/ui/select";
import React, { useCallback } from "react";
import { useTenantSettings } from "store/selectors/hooks";
import { useCommitTenantSetting } from "./agency-settings-hooks";

export const AgencySettingsGeneral: React.FC<unknown> = () => {
    const updateTimezone = useCommitTenantSetting("timezone");
    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLSelectElement>) => {
            updateTimezone(e.target.value);
        },
        [updateTimezone],
    );
    const { timezone } = useTenantSettings();
    return (
        <div className="space-y-4 lg:space-y-8">
            <ContentFull>
                <ContentHeader>General</ContentHeader>
                <div className="space-y-4 max-w-lg">
                    <div className="space-y-1 lg:space-y-2 group">
                        <Select
                            label="Time zone"
                            value={timezone}
                            onChange={handleChange}>
                            <option value="America/Anchorage">Alaska</option>
                            <option value="America/Honolulu">Hawaii</option>
                            <option value="America/Los_Angeles">Pacific</option>
                            <option value="America/Denver">Mountain</option>
                            <option value="America/Phoenix">Mountain (no DST)</option>
                            <option value="America/Chicago">Central</option>
                            <option value="America/New_York">Eastern</option>
                            <option value="America/Vancouver">Pacific (Canada)</option>
                            <option value="America/Edmonton">Mountain (Canada)</option>
                            <option value="America/Halifax">Atlantic (Canada)</option>
                            <option value="America/Winnipeg">Central (Canada)</option>
                            <option value="America/Toronto">Eastern (Canada)</option>
                            <option value="America/St_Johns">
                                Newfoundland (Canada)
                            </option>
                        </Select>
                        <p className="text-gray-500 group-hover:text-gray-900 text-sm">
                            Sets the default time zone for your agency.
                            <br />A user can change this option for their own account if
                            they operate in a different timezone than the rest of the
                            agency.
                        </p>
                    </div>
                </div>
            </ContentFull>
        </div>
    );
};
