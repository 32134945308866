import * as React from "react";
import { connect } from "react-redux";
import { Action, Dispatch } from "redux";
import * as actions from "../../store/actions/responsive";
import { ResponsiveMode } from "../../store/reducers/responsive";

const RESPONSIVE_MAX_CONSTRAINT = [479, 639, 1023, 1365, 1919, 99999999];

interface ResponsiveProps {
    updateMode: (m: ResponsiveMode) => void;
}

class responsive extends React.Component<ResponsiveProps, undefined> {
    render(): JSX.Element {
        return null;
    }
    updateDimensions() {
        const w = window,
            d = document,
            documentElement = d.documentElement,
            body = d.getElementsByTagName("body")[0],
            width = w.innerWidth || documentElement.clientWidth || body.clientWidth;
        this.props.updateMode(this.getResponsiveMode(width));
    }
    UNSAFE_componentWillMount() {
        this.updateDimensions();
    }
    componentDidMount() {
        window.addEventListener("resize", () => this.updateDimensions());
    }
    componentWillUnmount() {
        window.removeEventListener("resize", () => this.updateDimensions());
    }
    private getResponsiveMode(width: number): ResponsiveMode {
        let responsiveMode = ResponsiveMode.small;
        while (width > RESPONSIVE_MAX_CONSTRAINT[responsiveMode]) {
            responsiveMode++;
        }
        return responsiveMode;
    }
}

function mapDispatch(dispatch: Dispatch<Action>): ResponsiveProps {
    return {
        updateMode: (mode: ResponsiveMode) => {
            dispatch(actions.updateSize(mode));
        },
    };
}

const connected = connect(undefined, mapDispatch)(responsive);
export { connected as Responsive };
