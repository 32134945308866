import { FileId } from "@sp-crm/core";
import { Attachments } from "components/messages/attachments";
import React from "react";
import { useGlobalAttachmentScope } from "store/selectors/hooks";

interface TemplateAttachmentsProps {
    attachmentIds: FileId[];
    onAttachmentIdsChanged: (attachmentIds: FileId[]) => void;
    label?: string;
}

export const TemplateAttachments: React.FC<TemplateAttachmentsProps> = props => {
    const { attachmentIds, onAttachmentIdsChanged, label } = props;
    const attachmentScopes = useGlobalAttachmentScope();

    const handleAttachmentSelected = React.useCallback(
        (fileId: FileId) => {
            if (attachmentIds.includes(fileId)) {
                onAttachmentIdsChanged(attachmentIds.filter(id => id !== fileId));
            } else {
                onAttachmentIdsChanged([...attachmentIds, fileId]);
            }
        },
        [attachmentIds, onAttachmentIdsChanged],
    );

    return (
        <div className="space-y-2">
            <p>{label ?? "Attachments"}</p>
            <div className="bg-white p-4">
                <Attachments
                    scopes={attachmentScopes}
                    selectedFileIds={attachmentIds}
                    onSelectFile={handleAttachmentSelected}
                />
            </div>
        </div>
    );
};
