import { Callout } from "components/ui/callout";
import * as React from "react";

interface ActionBarChildrenParams {
    close: () => void;
}

interface IActionBarComponentProps {
    label: string;
    value: string;
    children?: ((params: ActionBarChildrenParams) => React.ReactNode) | React.ReactNode;
    className?: string;
}

export const ActionBarComponent: React.FunctionComponent<IActionBarComponentProps> = (
    props: IActionBarComponentProps,
) => {
    const { label, value, children, className } = props;
    const [isCalloutOpen, setIsCalloutOpen] = React.useState(false);
    const openCallout = React.useCallback(() => {
        setIsCalloutOpen(true);
    }, [setIsCalloutOpen]);

    const renderButton = React.useCallback(() => {
        return (
            <div className={`action-bar-component ${className}`} onClick={openCallout}>
                <div className="action-bar-label">{label}</div>
                <div className="action-bar-value">
                    <a href="#" onClick={openCallout}>
                        {value}
                    </a>
                </div>
            </div>
        );
    }, [label, value, openCallout, className]);

    const params = React.useMemo(() => {
        return {
            close: () => {
                setIsCalloutOpen(false);
            },
        };
    }, [setIsCalloutOpen]);

    return (
        <Callout
            renderButton={renderButton}
            onOpen={openCallout}
            forceClose={!isCalloutOpen}>
            <div className="w-80">
                {typeof children === "function" ? children(params) : children}
            </div>
        </Callout>
    );
};
