import { useDebouncedState } from "components/shared/hooks";
import React from "react";
import { SearchInput } from "./search-input";

interface Props {
    initial: string;
    onChange: (value: string) => void;
    label?: string | JSX.Element;
}

type SearchInputDebouncedProps = Props &
    Omit<React.InputHTMLAttributes<HTMLInputElement>, "value" | "onChange">;

export const SearchInputDebounced: React.FC<SearchInputDebouncedProps> = props => {
    const { initial, onChange, ...rest } = props;

    const [displayedSearchTerm, setDisplayedSearchTerm, searchTermDebounced] =
        useDebouncedState(initial, 400);

    const handleSearchInputChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setDisplayedSearchTerm(e.target.value);
        },
        [setDisplayedSearchTerm],
    );

    React.useEffect(() => {
        onChange(searchTermDebounced);
    }, [searchTermDebounced]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <SearchInput
            value={displayedSearchTerm}
            onChange={handleSearchInputChange}
            {...rest}
        />
    );
};
