import { CustomListKey } from "@sp-crm/core";
import { SupportEmailLink } from "components/app/support-email-link";
import { Checkbox } from "components/ui/checkbox";
import { PipelineVisibility, usePatchCustomListItemMutation } from "generated/graphql";
import React from "react";
import { useCustomList } from "store/selectors/bridge";

export const AgencySettingsOngoingPref: React.FC<unknown> = props => {
    const list = useCustomList(CustomListKey.ClientStatus);
    const ongoingItem = list.customList?.items?.find(item => item.key === "ongoing");
    const isChecked = ongoingItem && !ongoingItem.isArchived;
    const patchItem = usePatchCustomListItemMutation();
    const onChange = React.useCallback(
        async (e: React.ChangeEvent<HTMLInputElement>) => {
            await patchItem.mutateAsync({
                params: {
                    id: ongoingItem?.id,
                    isArchived: !e.target.checked,
                    pipelineVisibility: e.target.checked
                        ? PipelineVisibility.Show
                        : PipelineVisibility.Hide,
                },
            });
            list.refetch();
        },
        [patchItem, ongoingItem, list],
    );
    if (!ongoingItem) {
        return (
            <div className="group">
                <Checkbox
                    checked={false}
                    label="Show 'Ongoing' client status (unavailable, please contact support for assistance)"
                    disabled
                />
                <div className="ml-6 text-gray-500 group-hover:text-gray-900 text-sm">
                    On the Client status list, show <em>Ongoing</em> meant to track
                    perpetual invoices.{" "}
                    <em>
                        Please{" "}
                        <SupportEmailLink
                            subject="Ongoing status enable request"
                            label="contact support"
                        />{" "}
                        to enable this option.
                    </em>
                </div>
            </div>
        );
    }
    return (
        <div className="group">
            <Checkbox
                checked={isChecked}
                onChange={onChange}
                label="Show 'Ongoing' client status"
            />
            <div className="ml-6 text-gray-500 group-hover:text-gray-900 text-sm">
                On the Client status list, show <em>Ongoing</em> meant to track perpetual
                invoices.
            </div>
        </div>
    );
};
