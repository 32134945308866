import { FileEntityId, SignatureRequestId, formatDate } from "@sp-crm/core";
import { CrmTable, CrmTableProps } from "components/table/crm-table";
import { defaultLinkStyle } from "components/ui/link";
import { Panel } from "components/ui/panel/panel";
import { PanelType } from "components/ui/panel/panel-type";
import { GetSignatureRequestsForEntityQuery } from "generated/graphql";
import React from "react";
import { SignatureRequestExisting } from "./signature-request-existing";
import { SignatureRequestRecipients } from "./signature-request-recipients";
import { SignatureRequestStateText } from "./signature-request-state-text";

interface SignatureRequestListProps {
    signatureRequests: GetSignatureRequestsForEntityQuery["getSignatureRequestsForEntity"];
    refetchSignatures: () => void;
    entityId: FileEntityId;
}

export const SignatureRequestList: React.FC<SignatureRequestListProps> = props => {
    const { signatureRequests, refetchSignatures, entityId } = props;

    const [selectedRequestId, setSelectedRequestId] =
        React.useState<SignatureRequestId | null>(null);

    const columns: CrmTableProps<
        GetSignatureRequestsForEntityQuery["getSignatureRequestsForEntity"][0]
    >["columns"] = React.useMemo(
        () => [
            {
                key: "filename",
                header: "Template",
                renderCell: ({ record }) => {
                    return (
                        <a
                            className={defaultLinkStyle}
                            href="#"
                            onClick={e => {
                                e.preventDefault();
                                setSelectedRequestId(record.id);
                            }}>
                            {record.filename}
                        </a>
                    );
                },
            },
            {
                key: "state",
                header: "Status",
                renderCell: ({ record }) => {
                    return <SignatureRequestStateText state={record.state} />;
                },
            },
            {
                key: "signatureRequestRecipients",
                header: "Recipients",
                renderCell: ({ record }) => {
                    return (
                        <SignatureRequestRecipients
                            request={record}
                            entityId={entityId}
                            onRefetch={refetchSignatures}
                        />
                    );
                },
            },
            {
                key: "createdAt",
                header: "Sent",
                renderCell: ({ record }) => <span>{formatDate(record.createdAt)}</span>,
            },
        ],
        [entityId, refetchSignatures],
    );

    return (
        <div>
            <CrmTable data={signatureRequests} columns={columns} />
            <Panel
                headerText="Signature Request Details"
                type={PanelType.extraLarge}
                isOpen={!!selectedRequestId}
                onDismiss={() => {
                    setSelectedRequestId(null);
                }}>
                {selectedRequestId ? (
                    <SignatureRequestExisting
                        signatureRequestId={selectedRequestId}
                        entityId={entityId}
                        onRefetch={refetchSignatures}
                    />
                ) : null}
            </Panel>
        </div>
    );
};
