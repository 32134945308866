import * as React from "react";
import { Edit } from "./edit";
import { List } from "./list";

interface SettingsProps {
    match?: {
        url: string;
        params?: {
            entityName?: string;
            questionId?: string;
        };
    };
}

export const Settings: React.FunctionComponent<SettingsProps> = props => {
    const { match } = props;
    const questionId = match?.params?.questionId;
    if (questionId) {
        return <Edit questionId={questionId} />;
    }
    return <List />;
};
