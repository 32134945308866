import React from "react";
import { Redirect, Route, Switch } from "react-router";
import { ManageUsersEdit } from "./manage-users-edit";
import { ManageUsersList } from "./manage-users-list";

export const ManageUsers: React.FC<unknown> = () => {
    return (
        <Switch>
            <Route exact path="/settings/users" component={ManageUsersList} />
            <Route path="/settings/users/:userId" component={ManageUsersEdit} />
            <Route
                path="/settings/users"
                render={() => <Redirect to="/settings/users" />}
            />
        </Switch>
    );
};
