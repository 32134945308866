import { CheckCircleIcon, NoSymbolIcon } from "@heroicons/react/24/outline";
import { CommunityId, CommunityRelationshipType, IClient } from "@sp-crm/core";
import { fancyConfirm } from "components/ui/fancy-confirm";
import { defaultLinkStyle } from "components/ui/link";
import React from "react";
import { useDispatch } from "react-redux";
import { removeCommunityFromClient, updateCommunityClient } from "../../../store/actions";
import { CommunitySearchResults } from "../../community-search/community-search-results";
import {
    SearchCommunity,
    SearchCommunityState,
} from "../../community-search/search-bar/search-bar";
import {
    ClientCommunityRelationship,
    UpdateRelationshipPayload,
} from "../../community-search/types";

interface Props {
    searchKey: string;
    needsAndDesiresFilters: SearchCommunityState;
    client: IClient;
}

interface CommunityRelationshipActionsProps {
    relationship: CommunityRelationshipType;
    updateRelationship: (payload: UpdateRelationshipPayload) => void;
}

const CommunityRelationshipActions: React.FC<
    CommunityRelationshipActionsProps
> = props => {
    const { relationship, updateRelationship } = props;

    const addAsPotentialMatch = React.useCallback(
        (e: React.MouseEvent) => {
            e.preventDefault();
            updateRelationship({
                action: "updateRelationship",
                relationship: CommunityRelationshipType.featured,
            });
        },
        [updateRelationship],
    );

    const excludeCommunity = React.useCallback(
        (e: React.MouseEvent) => {
            e.preventDefault();
            updateRelationship({
                action: "updateRelationship",
                relationship: CommunityRelationshipType.excluded,
            });
        },
        [updateRelationship],
    );

    const removeRelationship = React.useCallback(
        (e: React.MouseEvent) => {
            e.preventDefault();
            updateRelationship({
                action: "removeRelationship",
            });
        },
        [updateRelationship],
    );
    return relationship === null || relationship === undefined ? (
        <div>
            <button onClick={addAsPotentialMatch} className={`${defaultLinkStyle} flex`}>
                <span>Add as potential match</span>
            </button>
            <button
                onClick={excludeCommunity}
                className={`${defaultLinkStyle} flex text-gray-500`}>
                <span>Exclude community</span>
            </button>
        </div>
    ) : relationship === CommunityRelationshipType.featured ||
      relationship === CommunityRelationshipType.matched ? (
        <div>
            <button
                onClick={removeRelationship}
                className={`${defaultLinkStyle} flex items-center space-x-1`}>
                <CheckCircleIcon className="w-3 h-3" />
                <span>Potential match</span>
            </button>
            <button
                onClick={excludeCommunity}
                className={`${defaultLinkStyle} flex text-gray-500`}>
                <span>Exclude community</span>
            </button>
        </div>
    ) : relationship === CommunityRelationshipType.excluded ? (
        <div>
            <button
                onClick={removeRelationship}
                className={`${defaultLinkStyle} flex items-center space-x-1 text-gray-500`}>
                <NoSymbolIcon className="w-3 h-3" />
                <span>Excluded</span>
            </button>
        </div>
    ) : null;
};

export const FindCommunities: React.FC<Props> = props => {
    const { searchKey, client, needsAndDesiresFilters } = props;

    const dispatch = useDispatch();

    const clientCommunityRelationshipProvider = React.useCallback(
        (communityId: CommunityId): ClientCommunityRelationship => {
            const existingRelationship = (client.clientCommunities || []).find(
                clientCommunity =>
                    clientCommunity.community &&
                    clientCommunity.community.id === communityId,
            );
            const result: ClientCommunityRelationship = {
                updateRelationship: (payload: UpdateRelationshipPayload) => {
                    (async () => {
                        if (payload.action === "updateRelationship") {
                            if (
                                existingRelationship &&
                                existingRelationship.relationship !==
                                    CommunityRelationshipType.excluded &&
                                payload.relationship ===
                                    CommunityRelationshipType.excluded
                            ) {
                                const shouldExclude = await fancyConfirm(
                                    "Exclude community?",
                                    "Really exclude this community from the list?",
                                    "Yes, exclude",
                                    "Cancel",
                                );
                                if (!shouldExclude) {
                                    return;
                                }
                            }

                            await updateCommunityClient(
                                client.id,
                                communityId,
                                payload.relationship,
                                dispatch,
                            );
                        } else if (payload.action === "removeRelationship") {
                            if (
                                existingRelationship &&
                                (existingRelationship.relationship ===
                                    CommunityRelationshipType.featured ||
                                    existingRelationship.relationship ===
                                        CommunityRelationshipType.matched)
                            ) {
                                const shouldRemove = await fancyConfirm(
                                    "Remove match?",
                                    "Really remove this community as a potential match?",
                                    "Yes, remove",
                                    "Cancel",
                                );
                                if (!shouldRemove) {
                                    return;
                                }
                            }
                            removeCommunityFromClient(client.id, communityId, dispatch);
                        } else {
                            throw new Error(`Unknown action ${payload.action}`);
                        }
                    })();
                },
            };

            if (existingRelationship) {
                result.relationship = existingRelationship.relationship;
            }
            return result;
        },
        [client, dispatch],
    );

    const generatePopupControls = React.useCallback(
        (communityId: CommunityId) => {
            const relationshipProvider = clientCommunityRelationshipProvider(communityId);

            return (
                <CommunityRelationshipActions
                    relationship={relationshipProvider.relationship}
                    updateRelationship={relationshipProvider.updateRelationship}
                />
            );
        },
        [clientCommunityRelationshipProvider],
    );

    return (
        <div>
            <SearchCommunity
                searchKey={searchKey}
                needsAndDesiresFilters={needsAndDesiresFilters}
                includedExcludedEnabled={true}
            />
            <div className="padding-top">
                <CommunitySearchResults
                    communityCardChildControlGenerator={generatePopupControls}
                    clientCommunityRelationshipProvider={
                        clientCommunityRelationshipProvider
                    }
                    searchKey={searchKey}
                    client={client}
                />
            </div>
        </div>
    );
};
