import { ILocation } from "@sp-crm/core";
import { getMarkerIconColorFromContractStatus } from "components/map/map-common";
import { SearchResultsMap } from "components/map/search-results-map";
import { MapSearchResults, MapView } from "components/map/types";
import "maplibre-gl/dist/maplibre-gl.css";
import React, { useMemo } from "react";
import {
    CommunitySearchRequest,
    useCommunitySearchMapViewQuery,
} from "../../../generated/graphql";
import { HoveredMapEntity, MapMarkerId } from "./types";

const staleTime = 1000 * 60 * 10; // 10m

interface CommunitySearchResultsMapProps {
    hoveredCommunity: null | HoveredMapEntity;
    searchParams: Omit<CommunitySearchRequest, "questionIds">;
    onMapMoved: (northWest: ILocation, southEast: ILocation) => void;
    renderMarkerControl?: (id: MapMarkerId) => JSX.Element;
}

export const CommunitySearchResultsMap: React.FC<
    CommunitySearchResultsMapProps
> = props => {
    const { hoveredCommunity, searchParams, onMapMoved, renderMarkerControl } = props;
    const [mapView, setMapView] = React.useState<MapView | null>(null);

    const query = useCommunitySearchMapViewQuery(
        {
            searchParams,
            mapView,
        },
        {
            staleTime,
            keepPreviousData: true,
            enabled: !!mapView,
        },
    );

    const searchResults: MapSearchResults = useMemo(() => {
        if (!query.data) {
            return null;
        }

        return {
            entityClusters: query.data.communitySearchMapView.communityClusters.map(
                cluster => ({
                    lat: cluster.lat,
                    lng: cluster.lng,
                    count: cluster.count,
                }),
            ),
            entityCoordinates: query.data.communitySearchMapView.communityCoordinates.map(
                coordinate => ({
                    id: coordinate.id,
                    lat: coordinate.lat,
                    lng: coordinate.lng,
                    name: coordinate.name,
                    color: getMarkerIconColorFromContractStatus(
                        coordinate.contractStatus,
                    ),
                    appLink: coordinate.appLink,
                }),
            ),
            searchCoordinates: query.data.communitySearchMapView.coordinates,
            recommendedZoom: query.data.communitySearchMapView.recommendedZoom,
        };
    }, [query.data]);

    return (
        <SearchResultsMap
            explicitBounds={searchParams.bounds}
            onExplicitBoundsChanged={onMapMoved}
            hoveredEntity={hoveredCommunity}
            isLoading={query.isLoading}
            onMapViewChanged={setMapView}
            results={searchResults}
            error={query.error}
            renderMarkerControl={renderMarkerControl}
        />
    );
};
