import { CommunityId, ICommunity } from "@sp-crm/core";
import { ListClient } from "components/clients/list-client";
import { SentMessagesTable } from "components/messages/sent";
import { Col, Row } from "components/shared/grid";
import {
    GetMessagesForEntityQuery,
    useGetMessagesForEntityQuery,
    useGetReferralsToCommunityQuery,
} from "generated/graphql";
import React from "react";
import { useProductName } from "store/selectors/branding";
import { useResponsiveMode, useUsers } from "store/selectors/hooks";

interface CommunityOutboundDirectReferralsProps {
    communityId: CommunityId;
}

const CommunityOutboundDirectReferrals: React.FC<
    CommunityOutboundDirectReferralsProps
> = props => {
    const { communityId } = props;

    const getReferralsQuery = useGetReferralsToCommunityQuery({ communityId });

    const directlyReferredClients = React.useMemo(() => {
        return (getReferralsQuery.data?.getReferralsToCommunity || [])
            .map(r => r.client)
            .filter(c => !!c);
    }, [getReferralsQuery.data]);

    const users = useUsers().users;
    const responsiveMode = useResponsiveMode();

    if (directlyReferredClients.length === 0) {
        return null;
    }
    return (
        <>
            <hr />
            <div>
                <p>
                    The table below shows all Clients you have referred out to this
                    community (marked as &quot;Professional Referrals&quot; on a Client
                    profile).
                </p>
                <br />
                <Row type="flex" className="collapsible">
                    <Col className="full-width">
                        <div className="list-reference">
                            <ListClient
                                showAssignedToColumn={false}
                                users={users}
                                clients={directlyReferredClients}
                                tableShort={true}
                                responsiveMode={responsiveMode}
                                sourceType={"fromReferralBusiness"}
                            />
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
};

interface OutboundCommunityEmailsSentProps {
    community: ICommunity;
}

export const OutboundCommunityEmailsSent: React.FC<
    OutboundCommunityEmailsSentProps
> = props => {
    const { community } = props;
    const messagesQuery = useGetMessagesForEntityQuery(
        { id: community.id },
        { keepPreviousData: true },
    );
    const productName = useProductName();

    const emailsSent: GetMessagesForEntityQuery["getMessagesForEntity"] | null =
        React.useMemo(() => {
            if (!messagesQuery.data) {
                return null;
            }

            return messagesQuery.data.getMessagesForEntity.filter(
                m => m.messageType === "ClientIntake",
            );
        }, [messagesQuery.data]);

    if (!Array.isArray(emailsSent)) {
        return null;
    }

    if (emailsSent.length === 0) {
        return (
            <div>
                <h3>No Outbound Referral Emails have been sent to this community yet.</h3>
                <br />
                <p>
                    From client profile pages, you can use {productName} to send any
                    Client Intake PDF referral emails to any community.
                </p>
                <p>
                    Any outgoing referral emails you send to this particular community
                    will be shown here.
                </p>
            </div>
        );
    }
    return (
        <div>
            <div className="mb-4">
                The table below shows all Client Intake PDF Referral emails that you (or
                your agency) have sent to this community ({community.name}
                ).
            </div>
            <SentMessagesTable
                messages={emailsSent}
                showClient={true}
                showCommunity={false}
            />
        </div>
    );
};

interface CommunityOutboundReferralsProps {
    community: ICommunity;
}

export const CommunityOutboundReferrals: React.FC<
    CommunityOutboundReferralsProps
> = props => {
    return (
        <>
            <OutboundCommunityEmailsSent community={props.community} />
            <CommunityOutboundDirectReferrals communityId={props.community.id} />
        </>
    );
};
