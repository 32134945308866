import {
    CalendarDate,
    ClientId,
    CustomListItemId,
    CustomListKey,
    Maybe,
} from "@sp-crm/core";
import { Badge } from "components/ui/badge";
import { Spinner } from "components/ui/spinner";
import * as React from "react";
import {
    DashboardDefaultsFragment,
    GetClientsForDetailsQueryVariables,
    useGetClientsForDetailsQuery,
} from "../../generated/graphql";
import { DraggableProps, DroppableWrapper, makeDraggable } from "../../util/dnd";
import { Icon } from "../icon";
import { CardProps, ClientCardv2, EmptyCard } from "./card";

import { useCustomList } from "store/selectors/bridge";
import { clientCardType } from "./types";

interface DashboardPlaceholderColumnProps {
    token: string;
    pipelinePrefix: string;
    humanFriendly: string;
    icon: string;
}

export const DashboardPlaceholderColumn: React.FC<DashboardPlaceholderColumnProps> = ({
    token,
    pipelinePrefix,
    humanFriendly,
    icon,
}) => {
    return (
        <div className={`col pipeline-stage pipeline-stage-${token}`}>
            <div className={"pipeline-innards"}>
                <div className="pipeline-header">
                    <div className="pipeline-header-icon">
                        <Icon name={icon || "49_Info"} />
                    </div>
                    <div className="pipeline-header-title">
                        {pipelinePrefix + humanFriendly}
                    </div>
                    <div className="pipeline-header-badge">
                        <Spinner />
                    </div>
                </div>
            </div>
        </div>
    );
};

interface DashboardColumnProps {
    id: CustomListItemId;
    token: string;
    pipelinePrefix: string;
    humanFriendly: string;
    icon: string;
    dragHandler: (
        clientId: ClientId,
        existingStatus: CustomListItemId,
        newStatus: CustomListItemId,
    ) => void;
    pendingClients: string[];
    highlightedClients: string[];
    showAssignedTo: boolean;
    searchCriteria: GetClientsForDetailsQueryVariables;
}

const DraggableClientCard = makeDraggable<DraggableProps & CardProps>(ClientCardv2);

export const DraggableDashboardColumn: React.FC<DashboardColumnProps> = props => {
    const {
        id,
        token,
        pipelinePrefix,
        humanFriendly,
        icon,
        dragHandler,
        pendingClients,
        highlightedClients,
        showAssignedTo,
        searchCriteria,
    } = props;

    const { getListItem } = useCustomList(CustomListKey.ClientStatus);
    const getClientsQuery = useGetClientsForDetailsQuery({
        ...searchCriteria,
        status: id,
    });

    const getStatusDescription = React.useCallback(
        (client: DashboardDefaultsFragment["clients"][0]) => {
            const listItem = getListItem(client.statusListItemId);
            if (listItem?.pipelineDateField) {
                const typedDatedField =
                    listItem.pipelineDateField as keyof DashboardDefaultsFragment["clients"][0];

                return {
                    descriptor: listItem.pipelineDateLabel || "",
                    date: Maybe.fromValue(client?.[typedDatedField]).flatMap(v =>
                        CalendarDate.parse(v),
                    ),
                };
            } else {
                return {
                    descriptor: "",
                    date: Maybe.none<CalendarDate>(),
                };
            }
        },
        [getListItem],
    );

    const clients: DashboardDefaultsFragment["clients"] = React.useMemo(() => {
        if (getClientsQuery.data) {
            return getClientsQuery.data.getClients.clients;
        } else {
            return null;
        }
    }, [getClientsQuery.data]);

    if (!clients) {
        return <DashboardPlaceholderColumn {...props} />;
    }

    return (
        <div className={`col pipeline-stage pipeline-stage-${token}`}>
            <DroppableWrapper accept={clientCardType} id={id}>
                <div className={"pipeline-innards"}>
                    <div className="pipeline-header">
                        <div className="pipeline-header-icon">
                            <Icon name={icon || "49_Info"} />
                        </div>
                        <div className="pipeline-header-title">
                            {pipelinePrefix + humanFriendly}
                        </div>
                        <div className="pipeline-header-badge">
                            <Badge type="info" value={clients.length} />
                        </div>
                    </div>
                    <div className="pipeline-content">
                        {clients.length > 0 ? (
                            clients.map(c => (
                                <DraggableClientCard
                                    highlightCard={highlightedClients.includes(c.id)}
                                    pendingCard={pendingClients.includes(c.id)}
                                    key={c.id}
                                    client={c}
                                    id={c.id}
                                    type={clientCardType}
                                    onDrop={(target: CustomListItemId) =>
                                        dragHandler(c.id, c.statusListItemId, target)
                                    }
                                    showAssignedTo={showAssignedTo}
                                    getClientStatus={getStatusDescription}
                                />
                            ))
                        ) : (
                            <EmptyCard />
                        )}
                    </div>
                </div>
            </DroppableWrapper>
        </div>
    );
};
