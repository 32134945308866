import {
    CommunityComparisonPdfOptions,
    EmailTemplateId,
    LayoutSectionKey,
} from "@sp-crm/core";
import { Panel } from "components/ui/panel/panel";
import { PanelType } from "components/ui/panel/panel-type";
import { PrimaryButton } from "components/ui/primary-button";
import { SecondaryButton } from "components/ui/secondary-button";
import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "store/state";
import { tenantSettings, userPreferences } from "../../../../store/selectors/preferences";
import { CommunityComparePdfLayoutCustomization } from "./pdf-layout-customization";

interface CommunitiesComparePdfDialogOwnProps {
    action: string;
    onDismiss: () => void;
}

interface CommunitiesComparePdfDialogFromState
    extends CommunitiesComparePdfDialogOwnProps {
    showCommunityRecordsDefaultValue: boolean;
    defaultCoverPageTemplateId: EmailTemplateId;
    defaultShowCoverPage: boolean;
}

type CommunitiesComparePdfDialogProps = CommunitiesComparePdfDialogFromState;
type CommunitiesComparePdfDialogState = CommunityComparisonPdfOptions & {
    canSubmit: boolean;
};

class CommunitiesComparePdfDialog extends React.Component<
    CommunitiesComparePdfDialogProps,
    CommunitiesComparePdfDialogState
> {
    downloadForm: HTMLFormElement;

    constructor(p: CommunitiesComparePdfDialogProps) {
        super(p);
        this.state = {
            showPrimaryContacts: true,
            showFinancialDetails: true,
            showCommunityRecords: this.props.showCommunityRecordsDefaultValue,
            showCoverPage: this.props.defaultShowCoverPage,
            coverPageTemplate: { templateId: this.props.defaultCoverPageTemplateId },
            sectionKeys: [],
            canSubmit: false,
        };
    }

    download() {
        this.downloadForm.submit();
        this.dismiss(
            777,
        ); /* 777 ms -- time for the new window to open and the form to submit */
    }

    dismiss(delay = 0): boolean {
        setTimeout(this.props.onDismiss.bind(this), delay);
        return true;
    }

    handleShowPrimaryContacts = (selection: boolean) => {
        this.setState({ ...this.state, showPrimaryContacts: selection });
    };

    handleShowFinanceDetails = (selection: boolean) => {
        this.setState({ ...this.state, showFinancialDetails: selection });
    };

    handleShowCommunityRecords = (selection: boolean) => {
        this.setState({ ...this.state, showCommunityRecords: selection });
    };

    handleShowCoverPage = (selection: boolean) => {
        this.setState({ ...this.state, showCoverPage: selection });
    };

    handleCoverPageTemplateId = (selection: EmailTemplateId | null | undefined) => {
        this.setState({ ...this.state, coverPageTemplate: { templateId: selection } });
    };

    handleSectionKeys = (sectionKeys: LayoutSectionKey[]) => {
        this.setState({ ...this.state, sectionKeys, canSubmit: true });
    };

    render() {
        return (
            <Panel
                type={PanelType.largeFixed}
                onDismiss={this.props.onDismiss}
                isOpen={true}
                headerText="Save Community Comparison PDF">
                <div>
                    <div>
                        <CommunityComparePdfLayoutCustomization
                            coverPageTemplate={{
                                templateId: this.state.coverPageTemplate.templateId,
                            }}
                            showCoverPage={this.state.showCoverPage}
                            sectionKeys={this.state.sectionKeys}
                            handleCoverPageTemplateId={this.handleCoverPageTemplateId}
                            handleShowCoverPage={this.handleShowCoverPage}
                            handleSectionKeys={this.handleSectionKeys}
                        />
                    </div>
                    <div className="mt-6">
                        <form
                            target="_blank"
                            method="GET"
                            ref={x => (this.downloadForm = x)}
                            action={this.props.action}>
                            <input
                                type="hidden"
                                value={this.state.showPrimaryContacts.toString()}
                                name="showPrimaryContacts"
                            />
                            <input
                                type="hidden"
                                value={this.state.showFinancialDetails.toString()}
                                name="showFinancialDetails"
                            />
                            <input
                                type="hidden"
                                value={this.state.showCommunityRecords.toString()}
                                name="showCommunityRecords"
                            />
                            <input
                                type="hidden"
                                value={this.state.showCoverPage.toString()}
                                name="showCoverPage"
                            />
                            <input
                                type="hidden"
                                value={this.state.coverPageTemplate?.templateId?.toString()}
                                name="coverPageTemplateId"
                            />
                            <input
                                type="hidden"
                                value={(this.state.sectionKeys || []).join(",")}
                                name="sectionKeys"
                            />
                            <div className="flex items-center justify-end space-x-2">
                                <SecondaryButton
                                    type="button"
                                    onClick={() => this.dismiss()}>
                                    Cancel
                                </SecondaryButton>
                                <PrimaryButton
                                    type="button"
                                    disabled={!this.state.canSubmit}
                                    onClick={() => this.download()}>
                                    Download
                                </PrimaryButton>
                            </div>
                        </form>
                    </div>
                </div>
            </Panel>
        );
    }
}

function mapStateToProps(
    state: ApplicationState,
    ownProps: CommunitiesComparePdfDialogOwnProps,
): CommunitiesComparePdfDialogFromState {
    const val = {
        action: ownProps.action,
        onDismiss: ownProps.onDismiss,
        showCommunityRecordsDefaultValue:
            tenantSettings(state).showCommunityRecordsInCommunityComparePDF,
        defaultShowCoverPage: userPreferences(state).defaultShowCoverPage,
        defaultCoverPageTemplateId:
            userPreferences(state).defaultCommunityCompareTemplateId,
    };

    return val;
}

const component = connect(mapStateToProps)(CommunitiesComparePdfDialog);

export { component as CommunitiesComparePdfDialog };
