import { workflowPayloadFromWorkflow } from "components/admin/tenant/workflow/workflow-edit";
import { Radio, RadioOption } from "components/shared/radio";
import { DeleteButton } from "components/ui/action-button";
import { fancyConfirm } from "components/ui/fancy-confirm";
import { Input } from "components/ui/input";
import { PrimaryButton } from "components/ui/primary-button";
import { SecondaryButton } from "components/ui/secondary-button";
import { Spinner } from "components/ui/spinner";
import {
    WorkflowStatus,
    useDeleteWorkflowMutation,
    useUpdateWorkflowMutation,
} from "generated/graphql";
import React from "react";
import { EditableWorkflow } from "./types";

interface EditWorkflowPanelProps {
    workflow: EditableWorkflow;
    onSaved: (workflow: EditableWorkflow) => void;
    onCancel: () => void;
    onDeleted: (workflowId: string) => void;
}

const workflowStatusOptions: RadioOption[] = [
    {
        key: WorkflowStatus.Draft,
        text: "Draft",
        helpText:
            "Workflows in the draft state will not trigger. Keep a workflow in the draft state until you have finalized its settings",
    },
    {
        key: WorkflowStatus.Published,
        text: "Published",
        helpText:
            "Workflows in the published state will trigger when their criteria is met. Publish a workflow after you have finalized its settings",
    },
];

export const EditWorkflowPanel: React.FC<EditWorkflowPanelProps> = props => {
    const { workflow, onSaved, onCancel, onDeleted } = props;

    const [workflowName, setWorkflowName] = React.useState(workflow.name || "");
    const [workflowDescription, setWorkflowDescription] = React.useState(
        workflow.description || "",
    );
    const workflowDelay = workflow.delay || 0;
    const [workflowStatus, setWorkflowStatus] = React.useState<WorkflowStatus>(
        workflow.status,
    );

    const updateWorkflowMutation = useUpdateWorkflowMutation();
    const deleteWorkflowMutation = useDeleteWorkflowMutation();

    const handleSave = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        const payload = workflowPayloadFromWorkflow(workflow);
        payload.name = workflowName;
        payload.description = workflowDescription;
        payload.delay = workflowDelay;
        payload.status = workflowStatus;

        const result = await updateWorkflowMutation.mutateAsync({
            workflowId: workflow.id,
            workflow: payload,
        });

        onSaved(result.updateWorkflow);
    };

    const handleCancel = React.useCallback(
        async (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            onCancel();
        },
        [onCancel],
    );

    const handleDelete = React.useCallback(async () => {
        const confirmed = await fancyConfirm(
            "Delete workflow?",
            "Are you sure you want to delete this workflow?",
            "Yes, I'm sure",
            "Cancel",
        );

        if (!confirmed) {
            return;
        }

        await deleteWorkflowMutation.mutateAsync({
            workflowId: workflow.id,
        });
        onDeleted(workflow.id);
    }, [deleteWorkflowMutation, onDeleted, workflow]);

    return (
        <div className="space-y-4">
            <Input
                label="Name"
                value={workflowName}
                onChange={e => setWorkflowName(e.target.value)}
            />
            <Input
                label="Description"
                value={workflowDescription}
                onChange={e => setWorkflowDescription(e.target.value)}
            />
            <Radio
                onChange={val => setWorkflowStatus(val as WorkflowStatus)}
                options={workflowStatusOptions}
                value={workflowStatus}
                label="Status"
            />
            <div className="flex items-center space-x-2">
                <PrimaryButton onClick={handleSave}>Save</PrimaryButton>
                <SecondaryButton onClick={handleCancel}>Cancel</SecondaryButton>
                <DeleteButton backgroundColor="bg-white" onClick={handleDelete} />
            </div>
            {updateWorkflowMutation.isLoading ? <Spinner /> : null}
        </div>
    );
};
