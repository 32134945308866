import { CalendarDate, ClientId, IClient, Maybe } from "@sp-crm/core";
import { CalendarDateInput } from "components/ui/calendar-date";
import { SecondaryButton } from "components/ui/secondary-button";
import * as React from "react";
import { connect } from "react-redux";
import { Action, Dispatch } from "redux";
import { ApplicationState } from "store/state";
import { updateClient } from "../../../store/actions";
import util from "../../../util";
import {
    ActionBarComponentRenderer,
    IActionBarComponent,
} from "./../../shared/legacy-action-bar-component-renderer";

interface ActionBarDateComponentOwnProps {
    className?: string;
    client: IClient;
    datePropertyName: string;
    dateVal: CalendarDate;
    displayLabel: string;
}

type ActionBarDateComponentPropsFromState = ActionBarDateComponentOwnProps;

interface ActionBarDateComponentPropsFromDispatch {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    onFieldChange: (fieldName: string, newValue: any) => void;
}

type ActionBarDateComponentProps = ActionBarDateComponentPropsFromDispatch &
    ActionBarDateComponentPropsFromState;

class ActionBarDateComponent
    extends React.Component<ActionBarDateComponentProps>
    implements IActionBarComponent
{
    private forceDismiss: () => void = null;

    public constructor(p: ActionBarDateComponentProps) {
        super(p);
        this.onCommitChange = this.onCommitChange.bind(this);
    }

    displayLabel(): string {
        return this.props.displayLabel;
    }

    displayValue(): string {
        return this.props.dateVal
            ? util.date.formatLongDateNoTime(this.props.dateVal.toString())
            : ActionBarComponentRenderer.defaultUnspecifiedValue;
    }

    onCommitChange(newVal: CalendarDate | null) {
        this.props.onFieldChange(
            this.props.datePropertyName,
            newVal ? newVal.toString() : null,
        );

        if (this.forceDismiss) {
            this.forceDismiss();
        }
    }

    editableContent(): JSX.Element {
        return (
            <div className="space-y-1">
                <div>
                    <CalendarDateInput
                        label={this.props.displayLabel}
                        initial={this.props.dateVal}
                        onCommit={this.onCommitChange}
                    />
                </div>
                <div>
                    <SecondaryButton onClick={() => this.onCommitChange(null)}>
                        Clear
                    </SecondaryButton>
                </div>
            </div>
        );
    }

    public render() {
        return (
            <ActionBarComponentRenderer
                className={this.props.className}
                actionBarObject={this}
                setForceDismiss={forceDismiss => (this.forceDismiss = forceDismiss)}
            />
        );
    }
}

function mapStateToProps(
    state: ApplicationState,
    ownProps: ActionBarDateComponentOwnProps,
): ActionBarDateComponentPropsFromState {
    return {
        ...ownProps,
    };
}

function mapDispatchToProps(
    dispatch: Dispatch<Action>,
    ownProps: ActionBarDateComponentOwnProps,
): ActionBarDateComponentPropsFromDispatch {
    const clientId: ClientId = Maybe.fromValue(ownProps)
        .map(p => p.client)
        .map(c => c.id)
        .getOrElse(null);
    const val = {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
        onFieldChange: (fieldName: string, newValue: any) => {
            if (clientId) {
                updateClient(clientId, fieldName, newValue, dispatch);
            }
        },
    };
    return val;
}
const component = connect(mapStateToProps, mapDispatchToProps)(ActionBarDateComponent);
export { component as ActionBarDateComponent };
