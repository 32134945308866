import { ActionType, UserId } from "@sp-crm/core";
import { QueryRenderer } from "components/clients/show-client/community-comparison/query-renderer";
import { ContentFull, ContentHeader } from "components/layout";
import { CrmTable, CrmTableProps } from "components/table/crm-table";
import { defaultLinkStyle } from "components/ui/link";
import {
    UserManagementGetUsersQuery,
    useUserManagementGetRolesQuery,
    useUserManagementGetUsersQuery,
} from "generated/graphql";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { useRegionsWithPermission } from "store/selectors/hooks";
import { stableQueryOptions } from "util/requests";
import { statusForUser } from "./helper";
import { ManageUsersNewAccount } from "./manage-users-new";
import { UserPermissionsCell } from "./user-permissions-cell";

interface DisplayableUser {
    id: UserId;
    email: string;
    name: string;
    status: string;
    placeholder: unknown;
    permissions: UserManagementGetUsersQuery["userManagementGetUsers"][0]["user"]["permissions"];
}

const initialSort = [
    {
        id: "email",
        desc: false,
    },
];

const generateDisplayableUser = (
    user: UserManagementGetUsersQuery["userManagementGetUsers"][0],
): DisplayableUser => ({
    id: user.user.id,
    email: user.user.email,
    name: user.user.name,
    status: statusForUser(user.user),
    permissions: user.user.permissions,
    placeholder: null,
});

export const ManageUsersList: React.FC<unknown> = () => {
    const users = useUserManagementGetUsersQuery();

    const roles = useUserManagementGetRolesQuery({}, stableQueryOptions());
    const regionsWithModifyAccess = useRegionsWithPermission(
        ActionType.SelfManageModifyUser,
    );

    const columns: CrmTableProps<DisplayableUser>["columns"] = useMemo(
        () => [
            {
                key: "placeholder",
                header: "",
                renderCell: ({ record }) => (
                    <Link
                        to={`/settings/users/${record.id}`}
                        className={defaultLinkStyle}>
                        Edit
                    </Link>
                ),
            },
            {
                key: "email",
                header: "Email",
                renderCell: ({ record }) => (
                    <Link
                        to={`/settings/users/${record.id}`}
                        className={defaultLinkStyle}>
                        {record.email}
                    </Link>
                ),
            },
            {
                key: "name",
                header: "Name",
            },
            {
                key: "status",
                header: "Status",
            },
            {
                key: "permissions",
                header: "Permissions",
                enableSorting: false,
                renderCell: ({ record }) => {
                    return (
                        <UserPermissionsCell
                            regions={regionsWithModifyAccess}
                            permissions={record.permissions}
                            roles={roles.data?.userManagementGetRoles}
                        />
                    );
                },
            },
        ],
        [roles.data?.userManagementGetRoles, regionsWithModifyAccess],
    );

    return (
        <QueryRenderer name="ManageUsersList" query={users}>
            {data => {
                return (
                    <div className="space-y-4 lg:space-y-0 lg:grid lg:grid-cols-12 lg:gap-x-5">
                        <div className="lg:col-span-8">
                            <ContentFull>
                                <ContentHeader>Accounts</ContentHeader>
                                <CrmTable
                                    initialSort={initialSort}
                                    columns={columns}
                                    data={data.userManagementGetUsers.map(
                                        generateDisplayableUser,
                                    )}
                                />
                            </ContentFull>
                        </div>
                        <div className="lg:col-span-4">
                            <ContentFull>
                                <ContentHeader>Create new account</ContentHeader>
                                <ManageUsersNewAccount />
                            </ContentFull>
                        </div>
                    </div>
                );
            }}
        </QueryRenderer>
    );
};
