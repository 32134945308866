import * as React from "react";

interface Props {
    hasError: boolean;
    children: React.ReactNode;
}
export const ValidationError: React.FC<Props> = props => {
    return (
        <div
            className="validation-error"
            style={props.hasError ? { color: "#e81123" } : { color: "inherit" }}>
            {props.children}
        </div>
    );
};
