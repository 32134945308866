import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import {
    ago,
    ClientId,
    DisclosureAcknowledgementResponseLabels,
    formattedDateTimeDefault,
} from "@sp-crm/core";
import {
    ManualDisclosureAcknowledgementForm,
    ManualDisclosureAcknowledgementPayload,
} from "components/client-disclosure/manual-disclosure-acknowledgment-form";
import { Header } from "components/header";
import { defaultLinkStyle } from "components/ui/link";
import { PrimaryButton } from "components/ui/primary-button";
import { SecondaryButton } from "components/ui/secondary-button";
import {
    GetClientQuery,
    useCreateDisclosureAcknowledgementMutation,
    useCreateDisclosureMutation,
    useGetClientQuery,
} from "generated/graphql";
import { produce } from "immer";
import React, { useCallback, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useUsers } from "store/selectors/hooks";
import { parsePhone } from "util/phone";
import { QueryRenderer } from "./community-comparison/query-renderer";

interface ClientDisclosureProps {
    client: GetClientQuery["getClient"];
    disclosure: GetClientQuery["getClient"]["disclosures"][0];
    onResend: () => void;
}

const classNames = {
    contentColumn: "px-6 py-3 whitespace-nowrap",
    headerColumn: "px-6 py-2 text-left",
};

const ClientDisclosure: React.FC<ClientDisclosureProps> = props => {
    const { disclosure, onResend, client } = props;
    const [acknowledgementFormVisible, setAcknowledgementFormVisible] =
        useState<boolean>(false);
    const [inAdvancedMode, setInAdvancedMode] = useState<boolean>(false);
    const handleResendClick = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e && e.preventDefault && e.preventDefault();
            onResend();
        },
        [onResend],
    );

    const handleAddAcknowledgementClick = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e && e.preventDefault && e.preventDefault();
            setAcknowledgementFormVisible(true);
        },
        [setAcknowledgementFormVisible],
    );

    const hideAcknowledgementForm = useCallback(() => {
        setAcknowledgementFormVisible(false);
    }, [setAcknowledgementFormVisible]);

    const queryClient = useQueryClient();

    const mutation = useCreateDisclosureAcknowledgementMutation();
    const usersState = useUsers();
    const allUsers = React.useMemo(() => usersState.users || {}, [usersState.users]);

    const toggleAdvanced = React.useCallback(
        async (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
            e.preventDefault();
            setInAdvancedMode(a => !a);
        },
        [setInAdvancedMode],
    );

    const createAcknowledgementAsync = useCallback(
        async (input: ManualDisclosureAcknowledgementPayload) => {
            const result = await mutation.mutateAsync({
                disclosureId: disclosure.id,
                acknowledgement: {
                    ...input,
                    name: client.bestContactName || "",
                    email: client.bestContactEmail || "",
                    phone: client.bestContactPhone || "",
                },
            });

            const data = queryClient.getQueryData<GetClientQuery>([
                "getClient",
                { id: client.id },
            ]);

            const newData = produce(data, draft => {
                const foundDisclosure = (draft.getClient.disclosures || []).find(
                    d => d.id === disclosure.id,
                );
                if (foundDisclosure) {
                    (foundDisclosure.acknowledgements || []).unshift(
                        result.createDisclosureAcknowledgement,
                    );
                }
            });

            queryClient.setQueryData<GetClientQuery>(
                ["getClient", { id: client.id }],
                newData,
            );

            hideAcknowledgementForm();
            return result.createDisclosureAcknowledgement;
        },
        [mutation, disclosure, queryClient, client, hideAcknowledgementForm],
    );

    const formatAddedBy = useCallback(
        (
            acknowledgement: GetClientQuery["getClient"]["disclosures"][0]["acknowledgements"][0],
        ) => {
            if (acknowledgement.createdBy) {
                if (allUsers[acknowledgement.createdBy]) {
                    return allUsers[acknowledgement.createdBy].name;
                }

                return "Agent";
            }

            return acknowledgement.name;
        },
        [allUsers],
    );

    const showAddedBy =
        inAdvancedMode && disclosure.acknowledgements.some(a => !!a.createdBy);
    const showAgreed = disclosure.acknowledgements.some(a => !a.agreed);

    return (
        <div className="space-y-4">
            <table className="w-full text-sm">
                <thead>
                    <tr>
                        <th scope="col" className={classNames.headerColumn}>
                            Name
                        </th>
                        <th scope="col" className={classNames.headerColumn}>
                            Email
                        </th>
                        <th scope="col" className={classNames.headerColumn}>
                            Phone
                        </th>
                        <th scope="col" className={classNames.headerColumn}>
                            Date
                        </th>
                        <th scope="col" className={classNames.headerColumn}>
                            Type
                        </th>
                        {showAgreed ? (
                            <th scope="col" className={classNames.headerColumn}>
                                Response
                            </th>
                        ) : null}
                        {showAddedBy ? (
                            <th scope="col" className={classNames.headerColumn}>
                                Added by
                            </th>
                        ) : null}
                        {inAdvancedMode ? (
                            <th scope="col" className={classNames.headerColumn}>
                                IP
                            </th>
                        ) : null}
                    </tr>
                </thead>
                <tbody>
                    {disclosure.acknowledgements.length === 0 ? (
                        <tr>
                            <td colSpan={4} className="text-center">
                                No one has acknowleged this disclosure yet
                            </td>
                        </tr>
                    ) : null}
                    {disclosure.acknowledgements.length > 0
                        ? disclosure.acknowledgements
                              .sort((a, b) => b.dateAdded.localeCompare(a.dateAdded))
                              .map(acknowledgement => {
                                  const phone = parsePhone(acknowledgement.phone);
                                  return (
                                      <tr key={acknowledgement.id}>
                                          <td
                                              scope="col"
                                              className={classNames.contentColumn}>
                                              {acknowledgement.name}
                                          </td>
                                          <td
                                              scope="col"
                                              className={classNames.contentColumn}>
                                              {acknowledgement.email}
                                          </td>
                                          <td
                                              scope="col"
                                              className={classNames.contentColumn}>
                                              {phone.digits ? (
                                                  <a href={`tel:${phone.digits}`}>
                                                      {phone.display}
                                                  </a>
                                              ) : (
                                                  acknowledgement.phone
                                              )}
                                          </td>
                                          <td
                                              scope="col"
                                              className={classNames.contentColumn}>
                                              {formattedDateTimeDefault(
                                                  acknowledgement.dateAdded,
                                              )}
                                          </td>
                                          <td
                                              scope="col"
                                              className={classNames.contentColumn}>
                                              {acknowledgement.createdBy
                                                  ? "Manual"
                                                  : "Email"}
                                          </td>
                                          {showAgreed ? (
                                              <td
                                                  scope="col"
                                                  className={classNames.contentColumn}>
                                                  {DisclosureAcknowledgementResponseLabels[
                                                      acknowledgement.response
                                                  ] || ""}
                                              </td>
                                          ) : null}
                                          {showAddedBy ? (
                                              <td
                                                  scope="col"
                                                  className={classNames.contentColumn}>
                                                  {formatAddedBy(acknowledgement)}
                                              </td>
                                          ) : null}
                                          {inAdvancedMode ? (
                                              <td
                                                  scope="col"
                                                  className={classNames.contentColumn}>
                                                  {acknowledgement.ipAddress}
                                              </td>
                                          ) : null}
                                      </tr>
                                  );
                              })
                        : null}
                    <tr>
                        <td className={classNames.contentColumn} colSpan={4}>
                            <a
                                className={`${defaultLinkStyle} text-xs cursor-pointer`}
                                onClick={toggleAdvanced}>
                                advanced
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div className="flex items-center space-x-4 mt-4">
                <SecondaryButton onClick={handleResendClick}>
                    Re-send disclosure
                </SecondaryButton>
                <a href={disclosure.publicUrl} target="_blank" rel="noreferrer">
                    <p className="flex items-center">
                        View disclosure form{" "}
                        {<ArrowTopRightOnSquareIcon className="w-4 h-4 ml-1" />}
                    </p>
                </a>
                <button className="text-gray-600" onClick={handleAddAcknowledgementClick}>
                    Manually add acknowledgement
                </button>
            </div>
            {acknowledgementFormVisible ? (
                <ManualDisclosureAcknowledgementForm
                    onSubmit={createAcknowledgementAsync}
                    onCancel={hideAcknowledgementForm}
                />
            ) : null}
            <p className="text-xs">
                Disclosure initially sent {ago(disclosure.dateAdded)}
            </p>
        </div>
    );
};

interface ClientDisclosureListProps {
    client: GetClientQuery["getClient"];
    onSend: () => void;
}

const ClientDisclosureList: React.FC<ClientDisclosureListProps> = props => {
    const { client, onSend } = props;

    const [acknowledgementFormVisible, setAcknowledgementFormVisible] =
        useState<boolean>(false);

    const handleSendDisclosure = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e && e.preventDefault && e.preventDefault();
            onSend();
        },
        [onSend],
    );

    const handleAddAcknowledgementClick = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e && e.preventDefault && e.preventDefault();
            setAcknowledgementFormVisible(true);
        },
        [setAcknowledgementFormVisible],
    );

    const hideAcknowledgementForm = useCallback(() => {
        setAcknowledgementFormVisible(false);
    }, [setAcknowledgementFormVisible]);

    const disclosureMutation = useCreateDisclosureMutation();
    const acknowledgementMutation = useCreateDisclosureAcknowledgementMutation();
    const queryClient = useQueryClient();
    const disclosures = client.disclosures || [];

    const createAcknowledgementAsync = useCallback(
        async (input: ManualDisclosureAcknowledgementPayload) => {
            const newDisclosure = await disclosureMutation.mutateAsync({
                clientId: client.id,
            });
            const newAcknowledgement = await acknowledgementMutation.mutateAsync({
                disclosureId: newDisclosure.createDisclosure.id,
                acknowledgement: {
                    ...input,
                    name: client.bestContactName || "",
                    email: client.bestContactEmail || "",
                    phone: client.bestContactPhone || "",
                },
            });

            const data = queryClient.getQueryData<GetClientQuery>([
                "getClient",
                { id: client.id },
            ]);

            const newData = produce(data, draft => {
                draft.getClient.disclosures = [{ ...newDisclosure.createDisclosure }];
                draft.getClient.disclosures[0].acknowledgements = [
                    { ...newAcknowledgement.createDisclosureAcknowledgement },
                ];
            });

            queryClient.setQueryData<GetClientQuery>(
                ["getClient", { id: client.id }],
                newData,
            );

            hideAcknowledgementForm();
            return newAcknowledgement.createDisclosureAcknowledgement;
        },
        [
            disclosureMutation,
            client,
            acknowledgementMutation,
            queryClient,
            hideAcknowledgementForm,
        ],
    );

    if (disclosures.length === 0) {
        return (
            <div className="space-y-2">
                <p>No disclosures have been sent for this client.</p>
                <div className="space-x-4">
                    <PrimaryButton onClick={handleSendDisclosure}>
                        Send disclosure
                    </PrimaryButton>
                    <button
                        className="text-gray-500"
                        onClick={handleAddAcknowledgementClick}>
                        Manually add acknowledgement
                    </button>
                </div>
                {acknowledgementFormVisible ? (
                    <ManualDisclosureAcknowledgementForm
                        onSubmit={createAcknowledgementAsync}
                        onCancel={hideAcknowledgementForm}
                    />
                ) : null}
            </div>
        );
    }

    return (
        <div>
            {disclosures.map(disclosure => (
                <ClientDisclosure
                    key={disclosure.id}
                    client={client}
                    disclosure={disclosure}
                    onResend={onSend}
                />
            ))}
        </div>
    );
};

interface ClientDisclosureSummaryProps {
    clientId: ClientId;
    onSendDisclosure: () => void;
    mailShowing: boolean;
}

export const ClientDisclosureSummary: React.FC<ClientDisclosureSummaryProps> = props => {
    const { clientId, onSendDisclosure, mailShowing } = props;
    const [currentMailShowing, setCurrentMailShowing] = useState<boolean>(mailShowing);

    const query = useGetClientQuery({ id: clientId });

    useEffect(() => {
        if (!mailShowing && currentMailShowing) {
            query.refetch();
        }

        if (mailShowing !== currentMailShowing) {
            setCurrentMailShowing(mailShowing);
        }
    }, [currentMailShowing, mailShowing]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <Header headerSize="minor">Disclosure Acknowledgements</Header>
            <QueryRenderer query={query} name="ClientDisclosureSummary.getClient">
                {data => (
                    <ClientDisclosureList
                        client={data.getClient}
                        onSend={onSendDisclosure}
                    />
                )}
            </QueryRenderer>
        </div>
    );
};
