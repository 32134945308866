import { IQuestion } from "@sp-crm/core";
import { AdvancedSearchEntityType } from "generated/graphql";
import React from "react";
import { useBridgeQuestionsForRegions } from "store/selectors/bridge";
import { useRegionId, useRegions } from "store/selectors/hooks";

interface BridgeQuestionShimProps {
    entityName: AdvancedSearchEntityType;
    regionKeys?: string[];
    children: (questions: IQuestion[]) => React.ReactElement | null;
}

export const BridgeQuestionShim: React.FC<BridgeQuestionShimProps> = props => {
    const { entityName, children } = props;
    const regionId = useRegionId();
    const regions = useRegions();
    const region = regions.find(r => r.id === regionId);
    const regionKeys = props.regionKeys || (region?.key ? [region.key] : []);

    const questions = useBridgeQuestionsForRegions(entityName, regionKeys);

    return <>{children(questions)}</>;
};
