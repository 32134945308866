import {
    ChatBubbleLeftEllipsisIcon,
    PhoneArrowUpRightIcon,
} from "@heroicons/react/24/solid";
import { formatPhoneNumber } from "@sp-crm/core";
import { CrmEntityContactType } from "generated/graphql";
import React from "react";
import { Taskv2 } from "./types";

interface TaskInlineCallProps {
    contact: Taskv2["entity"]["contact"];
}

const contactTypeToLabel = (type: CrmEntityContactType): string => {
    switch (type) {
        case CrmEntityContactType.BestContact:
            return "Best Contact";
        case CrmEntityContactType.Main:
            return "Main";
        case CrmEntityContactType.Cell:
            return "Cell";
        case CrmEntityContactType.Other:
            return "Alternate";
        default: {
            const _exhaustiveCheck: never = type;
            console.warn(`Unhandled contact type: ${_exhaustiveCheck}`);
            return "";
        }
    }
};

const phoneButtonClasses =
    "inline-flex items-center py-2 px-4 border-2 border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-800 bg-white " +
    "hover:bg-gray-200 hover:text-black " +
    "focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 " +
    "space-x-1 lg:space-x-2 text-sm truncate " +
    "min-w-0 max-w-full";

export const TaskInlineCall: React.FC<TaskInlineCallProps> = props => {
    const { contact } = props;
    if (!contact) return <div />;
    if (!contact.phoneNumber) return <div />;
    return (
        <a
            className={`twoverride ${phoneButtonClasses}`}
            href={`tel:${contact.phoneNumber}`}>
            <PhoneArrowUpRightIcon className="flex-0 h-4 w-4 text-green-600" />
            <span className="max-w-[calc(100vw-10rem)] overflow-hidden truncate">
                {contact.name || formatPhoneNumber(contact.phoneNumber)} (
                {contactTypeToLabel(contact.contactType)})
            </span>
        </a>
    );
};

export const TaskInlineText: React.FC<TaskInlineCallProps> = props => {
    const { contact } = props;
    if (!contact) return <div />;
    if (!contact.phoneNumber) return <div />;
    return (
        <a
            className={`twoverride ${phoneButtonClasses}`}
            href={`sms:${contact.phoneNumber}`}>
            <ChatBubbleLeftEllipsisIcon className="flex-0 h-4 w-4 text-green-600" />
            <span className="max-w-[calc(100vw-10rem)] overflow-hidden truncate">
                {contact.name || formatPhoneNumber(contact.phoneNumber)} (
                {contactTypeToLabel(contact.contactType)})
            </span>
        </a>
    );
};
