import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { Callout } from "components/ui/callout";
import * as React from "react";
import { usePreferences, useTenantSettings } from "store/selectors/hooks";
import { ReferenceDashboardAspect } from "store/slices/reference-dashboard";

type ReferenceSearchSortValue = {
    sort: string;
    sortDirection: string;
};

export type ReferenceSearchSortValues = {
    contact: ReferenceSearchSortValue;
    business: ReferenceSearchSortValue;
    community: ReferenceSearchSortValue;
};

interface ReferenceSearchSortProps {
    aspect: ReferenceDashboardAspect;
    values: ReferenceSearchSortValues;
    onChange: (
        aspect: ReferenceDashboardAspect,
        sort: string,
        sortDirection: string,
    ) => void;
}

interface SortOption {
    key: string;
    title: string;
    direction: "ASC" | "DESC";
    hidden?: boolean;
}

export const ReferenceSearchSort: React.FC<ReferenceSearchSortProps> = props => {
    const { aspect, values, onChange } = props;
    const chooseOption = (sortOption: string, direction: "ASC" | "DESC") => {
        onChange(aspect, sortOption, direction);
    };

    const { showReferralCityOnMainTable, showReferralLastUpdatedDateOnMainTable } =
        usePreferences();
    const { ownedReferralSources } = useTenantSettings();

    const renderButton = React.useCallback(() => {
        return (
            <div className="flex items-center space-x-1 cursor-pointer text-gray-700">
                <div className="text-lg">Sort</div>
                <ChevronDownIcon className="w-5 h-5 text-gray-500" />
            </div>
        );
    }, []);

    const availableSorts: Record<ReferenceDashboardAspect, SortOption[]> =
        React.useMemo(() => {
            return {
                contact: [
                    { key: "name", title: "Contact name (A-Z)", direction: "ASC" },
                    { key: "name", title: "Contact name (Z-A)", direction: "DESC" },
                    { key: "role", title: "Role/Title (A-Z)", direction: "ASC" },
                    { key: "role", title: "Role/Title (Z-A)", direction: "DESC" },
                    {
                        key: "updatedAt",
                        title: "Last updated (most recent)",
                        direction: "DESC",
                        hidden: !showReferralLastUpdatedDateOnMainTable,
                    },
                    {
                        key: "updatedAt",
                        title: "Last updated (oldest)",
                        direction: "ASC",
                        hidden: !showReferralLastUpdatedDateOnMainTable,
                    },
                    {
                        key: "organizationName",
                        title: "Organization name (A-Z)",
                        direction: "ASC",
                    },
                    {
                        key: "organizationName",
                        title: "Organization name (Z-A)",
                        direction: "DESC",
                    },
                    {
                        key: "organizationType",
                        title: "Organization type (A-Z)",
                        direction: "ASC",
                    },
                    {
                        key: "organizationType",
                        title: "Organization type (Z-A)",
                        direction: "DESC",
                    },
                    {
                        key: "city",
                        title: "City (A-Z)",
                        direction: "ASC",
                        hidden: !showReferralCityOnMainTable,
                    },
                    {
                        key: "city",
                        title: "City (Z-A)",
                        direction: "DESC",
                        hidden: !showReferralCityOnMainTable,
                    },
                    {
                        key: "numberOfReferrals",
                        title: "Number of referrals (most)",
                        direction: "DESC",
                    },
                    {
                        key: "numberOfReferrals",
                        title: "Number of referrals (least)",
                        direction: "ASC",
                    },
                    {
                        key: "mostRecentReferral",
                        title: "Most recent referral (latest)",
                        direction: "DESC",
                    },
                    {
                        key: "mostRecentReferral",
                        title: "Most recent referral (earliest)",
                        direction: "ASC",
                    },
                    {
                        key: "assignedTo",
                        title: "Assigned to (A-Z)",
                        direction: "ASC",
                        hidden: !ownedReferralSources,
                    },
                    {
                        key: "assignedTo",
                        title: "Assigned to (Z-A)",
                        direction: "DESC",
                        hidden: !ownedReferralSources,
                    },
                ],
                business: [
                    { key: "name", title: "Business name (A-Z)", direction: "ASC" },
                    { key: "name", title: "Business name (Z-A)", direction: "DESC" },
                    {
                        key: "updatedAt",
                        title: "Last updated (most recent)",
                        direction: "DESC",
                        hidden: !showReferralLastUpdatedDateOnMainTable,
                    },
                    {
                        key: "updatedAt",
                        title: "Last updated (oldest)",
                        direction: "ASC",
                        hidden: !showReferralLastUpdatedDateOnMainTable,
                    },
                    {
                        key: "organizationType",
                        title: "Organization type (A-Z)",
                        direction: "ASC",
                    },
                    {
                        key: "organizationType",
                        title: "Organization type (Z-A)",
                        direction: "DESC",
                    },
                    {
                        key: "city",
                        title: "City (A-Z)",
                        direction: "ASC",
                        hidden: !showReferralCityOnMainTable,
                    },
                    {
                        key: "city",
                        title: "City (Z-A)",
                        direction: "DESC",
                        hidden: !showReferralCityOnMainTable,
                    },
                    {
                        key: "numberOfReferrals",
                        title: "Number of referrals (most)",
                        direction: "DESC",
                    },
                    {
                        key: "numberOfReferrals",
                        title: "Number of referrals (least)",
                        direction: "ASC",
                    },
                    {
                        key: "mostRecentReferral",
                        title: "Most recent referral (latest)",
                        direction: "DESC",
                    },
                    {
                        key: "mostRecentReferral",
                        title: "Most recent referral (earliest)",
                        direction: "ASC",
                    },
                    {
                        key: "assignedTo",
                        title: "Assigned to (A-Z)",
                        direction: "ASC",
                        hidden: !ownedReferralSources,
                    },
                    {
                        key: "assignedTo",
                        title: "Assigned to (Z-A)",
                        direction: "DESC",
                        hidden: !ownedReferralSources,
                    },
                ],
                community: [
                    { key: "name", title: "Community name (A-Z)", direction: "ASC" },
                    { key: "name", title: "Community name (Z-A)", direction: "DESC" },
                    {
                        key: "updatedAt",
                        title: "Last updated (most recent)",
                        direction: "DESC",
                        hidden: !showReferralLastUpdatedDateOnMainTable,
                    },
                    {
                        key: "updatedAt",
                        title: "Last updated (oldest)",
                        direction: "ASC",
                        hidden: !showReferralLastUpdatedDateOnMainTable,
                    },
                    {
                        key: "city",
                        title: "City (A-Z)",
                        direction: "ASC",
                        hidden: !showReferralCityOnMainTable,
                    },
                    {
                        key: "city",
                        title: "City (Z-A)",
                        direction: "DESC",
                        hidden: !showReferralCityOnMainTable,
                    },
                    {
                        key: "numberOfReferrals",
                        title: "Number of referrals (most)",
                        direction: "DESC",
                    },
                    {
                        key: "numberOfReferrals",
                        title: "Number of referrals (least)",
                        direction: "ASC",
                    },
                    {
                        key: "mostRecentReferral",
                        title: "Most recent referral (latest)",
                        direction: "DESC",
                    },
                    {
                        key: "mostRecentReferral",
                        title: "Most recent referral (earliest)",
                        direction: "ASC",
                    },
                ],
            };
        }, [
            showReferralCityOnMainTable,
            showReferralLastUpdatedDateOnMainTable,
            ownedReferralSources,
        ]);

    const currentSorts = availableSorts[aspect];
    const value = values[aspect];

    return (
        <Callout renderButton={renderButton}>
            <div className="w-60">
                <h2 className="text-lg">Sort by</h2>
                <ul className="mt-2">
                    {currentSorts.map(d =>
                        d.hidden ? null : (
                            <li
                                key={`${aspect}_${d.key}_${d.direction}`}
                                className={`cursor-pointer hover:text-brand-500 ${
                                    value.sort === d.key &&
                                    value.sortDirection === d.direction
                                        ? "font-bold"
                                        : ""
                                }`}
                                onClick={() => chooseOption(d.key, d.direction)}>
                                <input
                                    className="mr-1"
                                    type="radio"
                                    checked={
                                        value.sort === d.key &&
                                        value.sortDirection === d.direction
                                    }
                                />
                                {d.title}
                            </li>
                        ),
                    )}
                </ul>
            </div>
        </Callout>
    );
};
