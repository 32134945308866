import {
    COMMUNITY_REFERRAL,
    Community,
    CommunityId,
    ContactId,
    Maybe,
    ReferenceBusiness,
    ReferenceBusinessId,
    ReferenceContactId,
    ReferenceMap,
    parseEntityId,
} from "@sp-crm/core";
import {
    AdvancedSearchResults,
    RenderRowActionParams,
} from "components/advanced-search/advanced-search-results";
import { Subnav } from "components/shared/subnav";
import { SubnavItem } from "components/shared/subnav/subnav-types";
import { SearchInputDebounced } from "components/ui/search-input-debounced";
import { SecondaryButton } from "components/ui/secondary-button";
import {
    AdvancedSearchRequest,
    ContactEntity,
    useGetEntitiesQuery,
    usePromoteCommunityContactMutation,
} from "generated/graphql";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ResponsiveMode } from "store/reducers/responsive";
import { useRegionId } from "store/selectors/hooks";
import { ApplicationState } from "store/state";
import { stableQueryOptions } from "util/requests";
import { GeneralReferralSourcePicker } from "./GeneralReferralSourcePicker";
import { useReferralSearchBadgeCounts, useReferralSearchQueries } from "./queries";

export interface SelectedReferenceContact {
    referenceContactId: ReferenceContactId;
    businessId: ReferenceBusinessId | null;
    communityId: CommunityId | null;
    contactId: ContactId;
}

interface ReferenceSearchBusinessPickerProps {
    onSelect: (referenceId: ReferenceBusinessId) => void;
    searchRequest: AdvancedSearchRequest;
}

const ReferenceSearchBusinessPicker: React.FC<
    ReferenceSearchBusinessPickerProps
> = props => {
    const { onSelect, searchRequest: initialSearchRequest } = props;

    const [searchRequest, setSearchRequest] =
        useState<AdvancedSearchRequest>(initialSearchRequest);

    useEffect(() => setSearchRequest(initialSearchRequest), [initialSearchRequest]);
    const renderRowAction = React.useCallback(
        (params: RenderRowActionParams) => {
            return (
                <SecondaryButton
                    className="text-sm"
                    onClick={e => {
                        e.preventDefault();
                        onSelect(params.record.id as ReferenceBusinessId);
                    }}>
                    Select
                </SecondaryButton>
            );
        },
        [onSelect],
    );

    return (
        <AdvancedSearchResults
            request={searchRequest}
            onChangeRequest={setSearchRequest}
            renderRowAction={renderRowAction}
        />
    );
};

interface ReferenceSearchContactPickerProps {
    onSelect: (selected: SelectedReferenceContact) => void;
    searchRequest: AdvancedSearchRequest;
}

const ReferenceSearchContactPicker: React.FC<
    ReferenceSearchContactPickerProps
> = props => {
    const { onSelect, searchRequest: initialSearchRequest } = props;

    const [searchRequest, setSearchRequest] =
        useState<AdvancedSearchRequest>(initialSearchRequest);

    useEffect(() => setSearchRequest(initialSearchRequest), [initialSearchRequest]);

    const renderRowAction = React.useCallback(
        (params: RenderRowActionParams) => {
            return (
                <SecondaryButton
                    className="text-sm"
                    onClick={e => {
                        e.preventDefault();
                        let businessId: ReferenceBusinessId = null;
                        let communityId: CommunityId = null;
                        let contactId: ContactId = null;
                        if (
                            typeof params.record.business === "object" &&
                            params.record.business
                        ) {
                            const business = params.record.business as ReferenceBusiness;
                            businessId = business.id;
                        }
                        if (
                            typeof params.record.community === "object" &&
                            params.record.community
                        ) {
                            const community = params.record.community as Community;
                            communityId = community.id;
                        }
                        if (
                            typeof params.record.contactEntity === "object" &&
                            params.record.contactEntity
                        ) {
                            const contact = params.record.contactEntity as ContactEntity;
                            contactId = contact.id;
                        }

                        onSelect({
                            referenceContactId: params.record.id as ReferenceContactId,
                            contactId,
                            businessId,
                            communityId,
                        });
                    }}>
                    Select
                </SecondaryButton>
            );
        },
        [onSelect],
    );

    return (
        <AdvancedSearchResults
            request={searchRequest}
            onChangeRequest={setSearchRequest}
            renderRowAction={renderRowAction}
        />
    );
};

interface ReferenceSearchCommunityPickerProps {
    onSelect: (comunityId: CommunityId) => void;
    searchRequest: AdvancedSearchRequest;
}

const ReferenceSearchCommunityPicker: React.FC<
    ReferenceSearchCommunityPickerProps
> = props => {
    const { onSelect, searchRequest: initialSearchRequest } = props;

    const [searchRequest, setSearchRequest] =
        useState<AdvancedSearchRequest>(initialSearchRequest);
    useEffect(() => setSearchRequest(initialSearchRequest), [initialSearchRequest]);

    const renderRowAction = React.useCallback(
        (params: RenderRowActionParams) => {
            return (
                <SecondaryButton
                    className="text-sm"
                    onClick={e => {
                        e.preventDefault();
                        onSelect(params.record.id as CommunityId);
                    }}>
                    Select
                </SecondaryButton>
            );
        },
        [onSelect],
    );

    return (
        <AdvancedSearchResults
            request={searchRequest}
            onChangeRequest={setSearchRequest}
            renderRowAction={renderRowAction}
        />
    );
};

interface ReferenceSearchCommunityContactPickerProps {
    onSelect: (contact: ReferenceContactId, community: CommunityId) => void;
    searchRequest: AdvancedSearchRequest;
}

const ReferenceSearchCommunityContactPicker: React.FC<
    ReferenceSearchCommunityContactPickerProps
> = props => {
    const { onSelect, searchRequest: initialSearchRequest } = props;

    const [searchRequest, setSearchRequest] =
        useState<AdvancedSearchRequest>(initialSearchRequest);

    useEffect(() => setSearchRequest(initialSearchRequest), [initialSearchRequest]);

    const regionId = useRegionId();

    const promoteCommunityContactMutation = usePromoteCommunityContactMutation();

    const handleSelect = React.useCallback(
        async (params: RenderRowActionParams) => {
            const contactId = params.record.contactId as ContactId;
            const communityId = params.record.communityId as CommunityId;
            const result = await promoteCommunityContactMutation.mutateAsync({
                regionId,
                contactId,
                communityId,
            });

            if (result.promoteCommunityContact) {
                onSelect(
                    result.promoteCommunityContact.referenceContact.id,
                    result.promoteCommunityContact.communityId,
                );
            }
        },
        [onSelect, promoteCommunityContactMutation, regionId],
    );

    const renderRowAction = React.useCallback(
        (params: RenderRowActionParams) => {
            return (
                <SecondaryButton
                    className="text-sm"
                    onClick={e => {
                        e.preventDefault();
                        handleSelect(params);
                    }}>
                    Select
                </SecondaryButton>
            );
        },
        [handleSelect],
    );

    return (
        <AdvancedSearchResults
            request={searchRequest}
            onChangeRequest={setSearchRequest}
            renderRowAction={renderRowAction}
        />
    );
};

export type ReferralSourceSearchTab =
    | "referralContact"
    | "communityContact"
    | "referralBusiness"
    | "referralCommunity"
    | "general";

interface ReferralSourceSearchProps {
    onSelect: (referenceMap: ReferenceMap) => void;
    exclude?: ReferralSourceSearchTab[];
}

export const ReferralSourceSearch: React.FC<ReferralSourceSearchProps> = props => {
    const { onSelect } = props;

    const responsiveMode = useSelector(
        (state: ApplicationState) => state.responsive.mode,
    );

    const [searchInput, setSearchInput] = useState("");

    const [activeTab, setActiveTab] = useState(
        responsiveMode < ResponsiveMode.large ? null : "referralContact",
    );

    const handleReferenceContactSelect = useCallback(
        (selected: SelectedReferenceContact) => {
            onSelect({
                identifier: selected.communityId
                    ? Maybe.fromValue(COMMUNITY_REFERRAL)
                    : Maybe.none(),
                business: selected.businessId
                    ? Maybe.fromValue(selected.businessId)
                    : selected.communityId
                    ? Maybe.fromValue(
                          parseEntityId<ReferenceBusinessId>(selected.communityId),
                      )
                    : Maybe.none(),
                contact: Maybe.fromValue(selected.referenceContactId),
            });
        },
        [onSelect],
    );

    const handleReferenceBusinessSelect = useCallback(
        (selected: ReferenceBusinessId) => {
            onSelect({
                identifier: Maybe.none(),
                business: Maybe.fromValue(selected),
                contact: Maybe.none(),
            });
        },
        [onSelect],
    );

    const handleReferenceCommunitySelect = useCallback(
        (selected: CommunityId) => {
            onSelect({
                identifier: Maybe.fromValue(COMMUNITY_REFERRAL),
                business: Maybe.fromValue(parseEntityId<ReferenceBusinessId>(selected)),
                contact: Maybe.none(),
            });
        },
        [onSelect],
    );

    const handleGeneralSelect = useCallback(
        (value: string) => {
            onSelect({
                identifier: Maybe.fromValue(value),
                business: Maybe.none(),
                contact: Maybe.none(),
            });
        },
        [onSelect],
    );

    const handleCommunityContactSelect = useCallback(
        (selected: ReferenceContactId, community: CommunityId) => {
            onSelect({
                identifier: Maybe.fromValue(COMMUNITY_REFERRAL),
                business: Maybe.fromValue(parseEntityId<ReferenceBusinessId>(community)),
                contact: Maybe.fromValue(selected),
            });
        },
        [onSelect],
    );

    const handleCloseSection = useCallback(() => {
        setActiveTab(null);
    }, [setActiveTab]);

    const regionId = useRegionId();

    const searchQueries = useReferralSearchQueries(searchInput, regionId);

    const getEntitiesQuery = useGetEntitiesQuery({}, stableQueryOptions());

    const renderReferenceContactPicker = useCallback(() => {
        return (
            <ReferenceSearchContactPicker
                onSelect={handleReferenceContactSelect}
                searchRequest={searchQueries.referenceContact}
            />
        );
    }, [handleReferenceContactSelect, searchQueries.referenceContact]);

    const renderReferenceBusinessPicker = useCallback(() => {
        return (
            <ReferenceSearchBusinessPicker
                onSelect={handleReferenceBusinessSelect}
                searchRequest={searchQueries.referenceOrganization}
            />
        );
    }, [handleReferenceBusinessSelect, searchQueries.referenceOrganization]);

    const renderReferenceCommunityPicker = useCallback(() => {
        return (
            <ReferenceSearchCommunityPicker
                onSelect={handleReferenceCommunitySelect}
                searchRequest={searchQueries.community}
            />
        );
    }, [handleReferenceCommunitySelect, searchQueries.community]);

    const renderGeneralReferralSourcePicker = useCallback(() => {
        return <GeneralReferralSourcePicker onSelect={handleGeneralSelect} />;
    }, [handleGeneralSelect]);

    const renderCommunityContactPicker = useCallback(() => {
        return (
            <ReferenceSearchCommunityContactPicker
                onSelect={handleCommunityContactSelect}
                searchRequest={searchQueries.communityContact}
            />
        );
    }, [handleCommunityContactSelect, searchQueries.communityContact]);

    const badgeCounts = useReferralSearchBadgeCounts(
        searchQueries,
        getEntitiesQuery.data?.getEntities ?? [],
    );

    const tabs: SubnavItem[] = [
        {
            linkText: "Contacts",
            subpage: "referralContact",
            render: renderReferenceContactPicker,
            badgeCount: badgeCounts.referenceContact,
        },
        {
            linkText: "Community Contacts",
            subpage: "communityContact",
            render: renderCommunityContactPicker,
            badgeCount: badgeCounts.communityContact,
        },
        {
            linkText: "Organizations",
            subpage: "organization",
            render: renderReferenceBusinessPicker,
            badgeCount: badgeCounts.referenceOrganization,
        },
        {
            linkText: "Communities",
            subpage: "community",
            render: renderReferenceCommunityPicker,
            badgeCount: badgeCounts.community,
        },
        {
            linkText: "General",
            subpage: "general",
            render: renderGeneralReferralSourcePicker,
        },
    ].filter(tab => !props.exclude?.includes(tab.subpage as ReferralSourceSearchTab));

    return (
        <div className="space-y-1 lg:space-y-2">
            <SearchInputDebounced
                initial={searchInput}
                onChange={setSearchInput}
                placeholder="Search"
                autoFocus
            />
            <Subnav
                subpages={tabs}
                defaultSubpage={null}
                selectedSubpage={activeTab}
                navigate={setActiveTab}
                closeSection={handleCloseSection}
            />
        </div>
    );
};
