import { InlineBanner } from "components/ui/inline-banner";
import React from "react";

interface CustomLabelProps extends React.LabelHTMLAttributes<HTMLLabelElement> {
    errorMessage?: string;
    className?: string;
}

export const CustomLabel: React.FC<CustomLabelProps> = props => {
    const { className, errorMessage } = props;
    return (
        <div
            className={`${
                className ? className : ""
            } custom-label flex-row-no-wrap-space-apart-no-bottom-margin`}>
            <label {...props} />
            {errorMessage && (
                <InlineBanner type="error">Error: {errorMessage}</InlineBanner>
            )}
        </div>
    );
};
