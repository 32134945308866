import { AutosavingInput } from "components/ui/autosaving-input";
import { SelectPro } from "components/ui/select-pro";
import React, { PureComponent } from "react";
import Shared from "../../shared";

type OptionsType = { value: string; text: string };

type ClientBiographicalFieldProps = {
    type: "text" | "phone" | "select";
    label: string;
    fieldName: string;
    initial: string;
    onFieldChange: (fieldName: string, newVal: string) => void;
    disabled?: boolean;
    additionalClassName?: string;
    options?: OptionsType[];
};

class ClientBiographicalField extends PureComponent<ClientBiographicalFieldProps> {
    render() {
        const {
            type,
            label,
            fieldName,
            initial,
            onFieldChange,
            disabled = false,
            additionalClassName = "",
            options = [],
        } = this.props;
        if (type === "phone") {
            if (disabled) {
                return (
                    <div className="form-group">
                        <div className="label-group flex-row-no-bottom-margin">
                            <div className="flex-column">{label}</div>
                        </div>
                        <div className={`flex-row ${additionalClassName}`}>
                            <div className="text-input-box">
                                <div className="phone-number-line">
                                    <a href={`tel:${initial}`}>{initial}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
            return (
                <Shared.PhoneInput
                    key={initial}
                    label={label}
                    initial={initial}
                    onCommit={newVal => onFieldChange(fieldName, newVal)}
                    disabled={disabled}
                />
            );
        }

        if (type === "select") {
            return (
                <div className="form-group-no-bottom-margin">
                    <SelectPro
                        includePlaceholderOption
                        key={initial}
                        label={label}
                        value={initial}
                        onChange={e => onFieldChange(fieldName, e.target.value)}
                        options={options}
                    />
                </div>
            );
        }

        return (
            <AutosavingInput
                key={initial}
                label={label}
                initial={initial}
                onCommit={newVal => onFieldChange(fieldName, newVal)}
                disabled={disabled}
            />
        );
    }
}

export default ClientBiographicalField;
