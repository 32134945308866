import { ClientId } from "@sp-crm/core";
import { SearchClientsQuery, useSearchClientsQuery } from "generated/graphql";
import React from "react";
import { useRegionId } from "store/selectors/hooks";

interface ClientMovesDataProps {
    clientId: ClientId;
    sourceId: ClientId;
    onSuccess: (clients: SearchClientsQuery["getClients"]["clients"]) => void;
}

export const ClientMovesData: React.FC<ClientMovesDataProps> = props => {
    const { clientId, sourceId, onSuccess } = props;
    const regionId = useRegionId();
    useSearchClientsQuery(
        {
            regionId,
            page: 1,
            perPage: 100,
            relatedTo: [clientId, sourceId].filter(x => !!x),
        },
        {
            keepPreviousData: true,
            onSuccess: data => {
                onSuccess(
                    data?.getClients?.clients?.filter(c => c.id !== clientId) || [],
                );
            },
        },
    );

    return <></>;
};
