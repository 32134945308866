import { ClientId, CustomListItemId, CustomListKey } from "@sp-crm/core";
import { produce } from "immer";
import React from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { useCustomList } from "store/selectors/bridge";
import { handleEvent } from "util/user-events";
import {
    ClientSearchSort,
    GetClientsForDetailsQuery,
    GetClientsForDetailsQueryVariables,
    PipelineVisibility,
} from "../../generated/graphql";
import { updateClient } from "../../store/actions";
import { reactivateClientWithoutNavigation } from "../../store/actions/clients";
import { promptForStatusIdChange } from "../../util/clientStatus";
import { DraggableDashboardColumn } from "./column";

interface PipelineInnerProps {
    searchCriteria: GetClientsForDetailsQueryVariables;
    sortOrder: ClientSearchSort;
}

export const PipelineInner: React.FC<PipelineInnerProps> = props => {
    const { searchCriteria } = props;
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const [highlightedClients, setHighlightedClients] = React.useState<string[]>([]);
    const [pendingClients, setPendingClients] = React.useState<string[]>([]);

    const {
        customList: clientStatusList,
        itemHasCategory,
        getListItemKey,
    } = useCustomList(CustomListKey.ClientStatus);
    const dragHandler = React.useCallback(
        async (
            clientId: ClientId,
            oldStatusId: CustomListItemId,
            newStatusId: CustomListItemId,
        ) => {
            const oldStatus = getListItemKey(oldStatusId);
            const newStatus = getListItemKey(newStatusId);
            handleEvent("client-status-change", {
                source: "pipeline",
                oldStatus,
                newStatus,
            });
            const statusOrReactivate = await promptForStatusIdChange(
                clientStatusList,
                oldStatusId,
                newStatusId,
            );

            setHighlightedClients(list => [...list, clientId]);
            setPendingClients(list => [...list, clientId]);
            const oldQueryData: GetClientsForDetailsQuery = queryClient.getQueryData([
                "getClientsForDetails",
                { ...searchCriteria, status: oldStatusId },
            ]);
            const oldClient = oldQueryData.getClients.clients.find(
                c => c.id === clientId,
            );

            if (oldClient) {
                queryClient.setQueryData(
                    ["getClientsForDetails", { ...searchCriteria, status: oldStatusId }],
                    (oldStatusData: GetClientsForDetailsQuery) => {
                        return produce(oldStatusData, draft => {
                            draft.getClients.clients = draft.getClients.clients.filter(
                                client => client.id !== clientId,
                            );
                            draft.getClients.total = draft.getClients.clients.length;
                        });
                    },
                );
                queryClient.setQueryData(
                    ["getClientsForDetails", { ...searchCriteria, status: newStatusId }],
                    (newStatusData: GetClientsForDetailsQuery) => {
                        return produce(newStatusData, draft => {
                            draft.getClients.clients.unshift(oldClient);
                            draft.getClients.total = draft.getClients.clients.length;
                        });
                    },
                );
            }
            if (statusOrReactivate === "statusChange") {
                await updateClient(clientId, "statusListItemId", newStatusId, dispatch);
            } else {
                await reactivateClientWithoutNavigation(clientId, dispatch);
            }
            queryClient.refetchQueries("getClientsForDetails");
            setPendingClients(list => list.filter(id => id !== clientId));
            setTimeout(
                () => setHighlightedClients(list => list.filter(id => id !== clientId)),
                1000,
            );
        },
        [
            setHighlightedClients,
            setPendingClients,
            dispatch,
            clientStatusList,
            queryClient,
            searchCriteria,
            getListItemKey,
        ],
    );

    if (!clientStatusList) {
        return null;
    }

    return (
        <div className="pipeline row main-body">
            {clientStatusList.items.map(item => {
                if (item.pipelineVisibility !== PipelineVisibility.Show) {
                    return null;
                }

                return (
                    <DraggableDashboardColumn
                        key={item.id}
                        id={item.id}
                        humanFriendly={item.name}
                        icon={item.icon}
                        token={item.key}
                        pipelinePrefix={
                            itemHasCategory(item.id, "Closed") ? "Recently " : ""
                        }
                        dragHandler={dragHandler}
                        highlightedClients={highlightedClients}
                        pendingClients={pendingClients}
                        showAssignedTo={typeof searchCriteria.assignedUserId !== "string"}
                        searchCriteria={searchCriteria}
                    />
                );
            })}
        </div>
    );
};
