import { IContact } from "@sp-crm/core";
import React from "react";
import { parsePhone } from "./phone";

interface PhoneOneLineProps {
    contact?: IContact;
}

export const PhoneOneLine: React.FC<PhoneOneLineProps> = props => {
    const { contact } = props;
    if (!contact) {
        return null;
    }
    if (
        (!contact.cellPhone || contact.cellPhone === "") &&
        (!contact.phone1 || contact.phone1 === "")
    ) {
        return null;
    }
    const numbers: JSX.Element[] = [];
    if (contact.cellPhone && contact.cellPhone !== "") {
        numbers.push(
            <PhoneSingleNumber
                key="cell"
                phoneNumber={contact.cellPhone}
                displayName="cell"
            />,
        );
    }
    if (contact.phone1 && contact.phone1 !== "") {
        numbers.push(
            <PhoneSingleNumber
                key="other"
                phoneNumber={contact.phone1}
                displayName="other"
            />,
        );
    }
    return <div>{numbers}</div>;
};

interface PhoneSingleNumberProps {
    displayName: string;
    phoneNumber?: string;
}

export const PhoneSingleNumber: React.FC<PhoneSingleNumberProps> = props => {
    const { displayName, phoneNumber } = props;
    if (!phoneNumber || phoneNumber === "") {
        return null;
    }
    const parsedNumber = parsePhone(phoneNumber);
    if (parsedNumber.digits && parsedNumber.digits.length > 0) {
        return (
            <div className="mr-2">
                <a href={"tel:" + parsedNumber.digits}>{parsedNumber.display}</a>
                &nbsp;<span>({displayName})</span>
            </div>
        );
    }
    return null;
};
