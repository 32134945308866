import { Answer, IAnswer, IQuestion, Maybe } from "@sp-crm/core";
import { Checkbox } from "components/ui/checkbox";
import * as React from "react";
import { SingleAnnotation } from "./annotation";
import { InDepthQuestionProps } from "./props";

const shouldShowAnnotation = (question: IQuestion, answer: Answer) => {
    return Maybe.fromValue(answer)
        .map(a => a.booleanAnswer)
        .getOrElse(false);
};

export class BinaryQuestion extends React.Component<InDepthQuestionProps, undefined> {
    constructor(props: InDepthQuestionProps) {
        super(props);
        this.updateAnswer = this.updateAnswer.bind(this);
    }

    updateAnswer(newVal: boolean): void {
        this.props.requestAnswerModification((a: IAnswer) => {
            a.booleanAnswer = newVal;
        });
    }

    title(): string {
        return this.props.question.title;
    }

    render(): JSX.Element {
        return (
            <div>
                <Checkbox
                    onChange={e => this.updateAnswer(e.target.checked)}
                    checked={this.props.answer.booleanAnswer}
                    key={this.props.question.id}
                    label={this.title()}
                />
                <SingleAnnotation shouldShow={shouldShowAnnotation} {...this.props} />
            </div>
        );
    }
}
