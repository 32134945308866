import { CalendarDate, CustomListKey, displayName, formatDateNoTime } from "@sp-crm/core";
import { DashboardNavigation } from "components/dashboard/dashboard-navigation";
import { ContentFull } from "components/layout";
import { Subnav } from "components/shared/subnav";
import { SubnavItem } from "components/shared/subnav/subnav-types";
import { CrmControlledTable } from "components/table/crm-controlled-table";
import { CrmTableColumn } from "components/table/types";
import { CrmPersona } from "components/ui/persona";
import {
    GetClientsForDetailsQuery,
    useGetClientsForDetailsQuery,
} from "generated/graphql";
import React, { useCallback, useEffect, useMemo } from "react";
import { useRouteMatch } from "react-router";
import { Link } from "react-router-dom";
import { navigate } from "store/actions";
import { useCustomList } from "store/selectors/bridge";
import { usePipelineState, useTenantSettings, useUsers } from "store/selectors/hooks";
import { ClientSearch } from "../client-search/client-search";
import { QueryRenderer } from "../show-client/community-comparison/query-renderer";

type DashboardModes = "Active" | "Closed" | "Ongoing";

interface ClientDashboardClientsProps {
    category: DashboardModes;
}

const ClientDashboardClients: React.FC<ClientDashboardClientsProps> = props => {
    const { category } = props;
    const pipelineState = usePipelineState();
    const [currentPage, setCurrentPage] = React.useState(1);
    useEffect(() => {
        setCurrentPage(1);
    }, [pipelineState.searchCriteria, category]);
    const {
        customList: statusCustomList,
        getListItemName,
        itemHasCategory,
    } = useCustomList(CustomListKey.ClientStatus);

    const statusIds = useMemo(() => {
        if (!statusCustomList) {
            return [];
        }

        return statusCustomList.items
            .filter(x => itemHasCategory(x.id, category))
            .map(x => x.id);
    }, [itemHasCategory, statusCustomList, category]);

    const searchCriteria = useMemo(() => {
        return {
            ...pipelineState.searchCriteria,
            includeStatuses: statusIds,
            perPage: 30,
            page: currentPage,
        };
    }, [pipelineState, currentPage, statusIds]);

    const clientsQuery = useGetClientsForDetailsQuery(searchCriteria, {
        enabled: !!searchCriteria && statusIds.length > 0,
    });

    const tenantSettings = useTenantSettings();
    const showClientFirst = tenantSettings.showClientFirst;
    const showClientSerial = tenantSettings.showClientSerial;
    const users = useUsers().users;
    const columns: CrmTableColumn<
        GetClientsForDetailsQuery["getClients"]["clients"][0]
    >[] = React.useMemo(() => {
        const allColumns: (CrmTableColumn<
            GetClientsForDetailsQuery["getClients"]["clients"][0]
        > & { show: boolean })[] = [
            { key: "serial", header: "ID", show: showClientSerial },
            {
                show: true,
                key: "name",
                header: "Client",
                renderCell: ({ record }) => (
                    <Link to={`/clients/show/${record.id}`}>
                        {showClientFirst ? (
                            <div className="flex items-center">
                                <div className="flex-shrink-0 h-10 w-10">
                                    <CrmPersona
                                        size="large"
                                        initialsOnly
                                        name={record.name}
                                    />
                                </div>
                                <div className="ml-4">
                                    <div className="text-sm font-medium truncate text-gray-900">
                                        {record.name}
                                    </div>
                                    <div className="text-sm truncate text-gray-500">
                                        {record.bestContactName}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="flex items-center">
                                <div className="flex-shrink-0 h-10 w-10">
                                    <CrmPersona
                                        size="large"
                                        initialsOnly
                                        name={record.bestContactName}
                                    />
                                </div>
                                <div className="ml-4">
                                    <div className="text-sm font-medium truncate text-gray-900">
                                        {record.bestContactName}
                                    </div>
                                    <div className="text-sm truncate text-gray-500">
                                        {record.name}
                                    </div>
                                </div>
                            </div>
                        )}
                    </Link>
                ),
            },
            {
                show: true,
                key: "dateAdded",
                renderCell: ({ record }) => (
                    <div>{formatDateNoTime(record.dateAdded)}</div>
                ),
                header: "Date Created",
            },
            {
                show: true,
                key: "statusListItemId",
                header: "Status",
                renderCell: ({ record }) => (
                    <div>{getListItemName(record.statusListItemId)}</div>
                ),
            },
            {
                show: true,
                key: "id",
                renderCell: ({ record }) =>
                    record.nextTask ? (
                        <div>
                            <div className="text-sm truncate text-gray-900">
                                {record.nextTask.text}
                            </div>
                            <div className="text-sm text-gray-500">
                                {formatDateNoTime(
                                    record.nextTask.dueDate ||
                                        record.nextTask.dueDateTime ||
                                        null,
                                )}
                            </div>
                        </div>
                    ) : (
                        <span />
                    ),

                header: "Next Task",
            },
            {
                show: true,
                key: "moveTimeline",
                renderCell: ({ record }) =>
                    record.moveTimelineActual ? (
                        <div>
                            <div className="text-sm text-gray-900">
                                {CalendarDate.parse(record.moveTimelineActual)
                                    .map(x => x.humanFriendly())
                                    .getOrElse("")}
                            </div>
                            <div className="text-sm text-gray-500">Actual</div>
                        </div>
                    ) : record.moveTimeline ? (
                        <div>
                            <div className="text-sm text-gray-900">
                                {CalendarDate.parse(record.moveTimeline)
                                    .map(x => x.humanFriendly())
                                    .getOrElse("")}
                            </div>
                            <div className="text-sm text-gray-500">Estimated</div>
                        </div>
                    ) : (
                        <span />
                    ),
                header: "Target Move-In",
            },
            {
                show: true,
                key: "assignedUserId",
                header: "Assigned to",
                renderCell: ({ record }) => (
                    <div>
                        {users && users[record.assignedUserId]
                            ? displayName(users[record.assignedUserId])
                            : "unassigned"}
                    </div>
                ),
            },
        ];
        return allColumns.filter(x => x.show);
    }, [showClientFirst, users, getListItemName, showClientSerial]);

    return (
        <ContentFull>
            <QueryRenderer name="ClientDashboardClients" query={clientsQuery}>
                {data => (
                    <CrmControlledTable
                        columns={columns}
                        goToPage={setCurrentPage}
                        currentPage={currentPage}
                        data={data.getClients.clients}
                        rowCount={data.getClients.total}
                        pageSize={searchCriteria.perPage}
                    />
                )}
            </QueryRenderer>
        </ContentFull>
    );
};

type ClientDashboardRouteMatch = {
    params?: {
        subpage?: string;
    };
} | null;

export const ClientDashboardV2: React.FC<unknown> = props => {
    const handleNavigate = useCallback((page: string) => {
        navigate(`/clients/${page}`);
    }, []);
    const handleCloseSection = useCallback(() => {
        navigate(`/clients`);
    }, []);

    const routeMatch: ClientDashboardRouteMatch = useRouteMatch(`/clients/:subpage`);
    const subpages: SubnavItem[] = [
        {
            href: `/clients/active`,
            subpage: "active",
            linkText: "Active",
            render: () => <ClientDashboardClients category="Active" />,
        },
        {
            href: `/clients/closed`,
            subpage: "closed",
            linkText: `Closed`,
            render: () => <ClientDashboardClients category="Closed" />,
        },
        {
            href: `/clients/ongoing`,
            subpage: "ongoing",
            linkText: `Ongoing`,
            render: () => <ClientDashboardClients category="Ongoing" />,
        },
    ];
    return (
        <div>
            <div className="noprint">
                <DashboardNavigation />
                <ClientSearch includeCardSort={true} />
            </div>
            <div className="col main-body full-width bg-gray-100 min-h-screen">
                <Subnav
                    navigate={handleNavigate}
                    selectedSubpage={routeMatch?.params?.subpage || null}
                    subpages={subpages}
                    closeSection={handleCloseSection}
                    defaultSubpage="active"
                />
            </div>
        </div>
    );
};
