import { IAnswer, IClient, IDetails, IQuestion, QuestionCategories } from "@sp-crm/core";
import React, { PureComponent } from "react";
import Header from "./header";
import QuestionList from "./question-list";

type SingleClientProps = {
    client: IClient;
    details: IDetails;
    questions: IQuestion[];
    category: QuestionCategories;
    answers: IAnswer[];
    onUpdateAnswer: (answer: IAnswer) => Promise<IAnswer>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    onFieldChange: (fieldName: string, newValue: any) => void;
    preJSX: JSX.Element;
    posJSX: JSX.Element;
};

class SingleClient extends PureComponent<SingleClientProps> {
    render() {
        return (
            <div className="input-form-block-no-bottom-margin">
                <Header
                    icon={this.props.details.icon}
                    clientHeading={this.props.details.clientHeading}
                />
                <QuestionList
                    questions={this.props.questions}
                    category={this.props.category}
                    answers={this.props.answers}
                    onUpdateAnswer={this.props.onUpdateAnswer}
                    preJSX={this.props.preJSX}
                    posJSX={this.props.posJSX}
                />
            </div>
        );
    }
}

export default SingleClient;
