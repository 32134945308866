import { CloudArrowDownIcon } from "@heroicons/react/24/outline";
import { ActionType } from "@sp-crm/core";
import { defaultLinkStyle } from "components/ui/link";
import { AdvancedSearchRequest } from "generated/graphql";
import React from "react";
import { useFeature, useIsAllowed } from "store/selectors/hooks";

interface AdvancedSearchExportProps {
    searchRequest: AdvancedSearchRequest;
    reportName?: string;
}

export const AdvancedSearchDownload: React.FC<AdvancedSearchExportProps> = props => {
    const { searchRequest, reportName } = props;
    const formRef = React.useRef<HTMLFormElement>(null);
    const fieldRef = React.useRef<HTMLInputElement>(null);
    const handleClick = React.useCallback(
        async (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
            e.preventDefault();
            if (formRef.current && fieldRef.current) {
                fieldRef.current.value = JSON.stringify(searchRequest);
                formRef.current.submit();
            }
        },
        [searchRequest],
    );
    const downloadEnabled = useFeature("customReportsDownload");

    const canDownload = useIsAllowed(ActionType.ExportCsv);
    if (!downloadEnabled || !canDownload || !searchRequest) {
        return null;
    }
    return (
        <div>
            <a
                href="#"
                className={`${defaultLinkStyle} flex items-center space-x-2`}
                onClick={handleClick}>
                <CloudArrowDownIcon className={`h-4 w-4`} />
                <div>Download results</div>
            </a>
            <div>
                <form
                    ref={formRef}
                    target="_blank"
                    method="POST"
                    action="/api/reports/export">
                    <input type="hidden" name="params" ref={fieldRef} />
                    {reportName?.length > 0 ? (
                        <input type="hidden" name="filename" value={reportName} />
                    ) : null}
                </form>
            </div>
        </div>
    );
};
