import { CommunityId } from "@sp-crm/core";
import { Checkbox } from "components/ui/checkbox";
import { InlineBanner } from "components/ui/inline-banner";
import { Input } from "components/ui/input";
import React from "react";
import { ClientIntakeFaxRecipient } from "../../../../generated/graphql";

export type FaxRecipient = ClientIntakeFaxRecipient & { selected: boolean };

interface FaxRecipientsProps {
    recipients: FaxRecipient[];
    setRecipients: (recipients: FaxRecipient[]) => void;
    showErrors: boolean;
}

export const FaxRecipients: React.FC<FaxRecipientsProps> = props => {
    const { recipients, setRecipients, showErrors } = props;
    const setSelected = (id: CommunityId, selected: boolean) => {
        const newRecipients = recipients.map(r => {
            if (r.communityId === id) {
                return { ...r, selected };
            }
            return r;
        });
        setRecipients(newRecipients);
    };
    const setFaxNumber = (id: CommunityId, faxNumber: string) => {
        const newRecipients = recipients.map(r => {
            if (r.communityId === id) {
                return { ...r, faxNumber };
            }
            return r;
        });
        setRecipients(newRecipients);
    };
    const countSelectedCommunites = recipients.filter(r => r.selected).length;
    return (
        <div className="space-y-2">
            {showErrors && countSelectedCommunites === 0 ? (
                <InlineBanner type="error">
                    Must specify at least one community.
                </InlineBanner>
            ) : null}
            {recipients.map(r => (
                <div key={r.communityId} className="space-y-1">
                    <Checkbox
                        label={r.communityName}
                        checked={r.selected}
                        onChange={e => setSelected(r.communityId, e.target.checked)}
                    />
                    <Input
                        placeholder="Fax number"
                        type="tel"
                        value={r.faxNumber}
                        onChange={e => setFaxNumber(r.communityId, e.target.value)}
                        disabled={!r.selected}
                    />
                    {showErrors &&
                    r.selected &&
                    (!r.faxNumber || r.faxNumber.trim().length === 0) ? (
                        <InlineBanner type="error">
                            Must specify a fax number.
                        </InlineBanner>
                    ) : null}
                </div>
            ))}
        </div>
    );
};
