import * as C from "@sp-crm/core";
import { Maybe } from "@sp-crm/core";
import { SelectPro } from "components/ui/select-pro";
import * as _ from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "store/state";

interface RegionSelectPropsFromState {
    selectedRegion: C.TenantRegion;
    regions: C.TenantRegion[];
    onChange: (newKey: string) => void;
}

interface RegionSelectOwnProps {
    selectedRegionKey: string;
    onChange: (newKey: string) => void;
}

class RegionSelect extends React.Component<RegionSelectPropsFromState, undefined> {
    selectedKey(): string {
        if (this.props.selectedRegion) {
            return this.props.selectedRegion.key;
        }
        return null;
    }
    render() {
        if (
            Maybe.fromValue(this.props.regions)
                .map(r => r.length)
                .getOrElse(0) <= 1
        )
            return null;
        return (
            <div className="region-select">
                <SelectPro
                    label="Region"
                    includePlaceholderOption={false}
                    value={this.selectedKey()}
                    onChange={e => this.props.onChange(e.target.value)}
                    options={this.props.regions.map(r => ({
                        value: r.key,
                        text: r.name,
                    }))}
                />
            </div>
        );
    }
}

const c = connect(
    (
        state: ApplicationState,
        ownProps: RegionSelectOwnProps,
    ): RegionSelectPropsFromState => ({
        selectedRegion: _.find(
            state.region.regions,
            r => r.key === ownProps.selectedRegionKey,
        ),
        regions: state.region.regions,
        onChange: (key: string) => ownProps.onChange(key),
    }),
)(RegionSelect);
export { c as RegionSelect };
