import { PencilIcon } from "@heroicons/react/20/solid";
import { DocumentIcon } from "@heroicons/react/24/outline";
import { ErrorMessage } from "components/ui/error-message";
import { primaryClasses } from "components/ui/primary-button";
import { Spinner } from "components/ui/spinner";
import { GetTemplatesQuery, useGetTemplatesQuery } from "generated/graphql";
import React from "react";
import { Link } from "react-router-dom";

type Template = GetTemplatesQuery["getTemplates"]["templates"][0];
export const TemplateIndex: React.FC<unknown> = () => {
    const query = useGetTemplatesQuery();
    if (query.isLoading) {
        return <Spinner />;
    }
    if (query.isError) {
        return (
            <ErrorMessage component="Template Index">
                <pre>{JSON.stringify(query.error, null, 2)}</pre>
            </ErrorMessage>
        );
    }
    if (!query.data) {
        return <Spinner />;
    }

    const signatures: Template[] = [];
    const templates: Template[] = [];
    query.data.getTemplates.templates.forEach(t => {
        if (t.template.type === "signature") {
            signatures.push(t);
        } else if (t.template.type === "insertable") {
            templates.push(t);
        }
    });

    return (
        <div className="space-y-8">
            {templates.length > 0 ? (
                <div>
                    <h3 className="text-xl mb-2">Templates</h3>
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                        {templates.map(t => (
                            <TemplateCard key={t.template.id} t={t} />
                        ))}
                    </div>
                </div>
            ) : null}
            {signatures.length > 0 ? (
                <div>
                    <h3 className="text-xl mb-2">Signatures</h3>
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                        {signatures.map(t => (
                            <SignatureCard
                                isDefault={t.isDefaultSignature}
                                key={t.template.id}
                                t={t}
                            />
                        ))}
                    </div>
                </div>
            ) : null}
            <Link className={primaryClasses} to={`/settings/templates/new`}>
                Create new template or signature
            </Link>
        </div>
    );
};

interface TemplateCardProps {
    t: Template;
}
const TemplateCard: React.FC<TemplateCardProps> = props => (
    <div className="relative rounded-lg border border-gray-300 bg-white p-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-brand-500">
        <div className="flex-shrink-0">
            <DocumentIcon className="w-8 h-8 rounded-full bg-brand-500 text-white p-1" />
        </div>
        <div className="flex-1 min-w-0">
            <Link
                to={`/settings/templates/${props.t.template.id}`}
                className="focus:outline-none">
                <span className="absolute inset-0" aria-hidden="true" />
                <p className="text-sm font-medium text-gray-900">
                    {props.t.template.description ?? "Untitled"}
                </p>
                <p className="text-sm text-gray-500 truncate">
                    {props.t.template.isShared ? (
                        <span>
                            Shared by{" "}
                            <em>{props.t.author.name ?? props.t.author.email}</em>
                        </span>
                    ) : (
                        <span>Not shared</span>
                    )}
                </p>
            </Link>
        </div>
    </div>
);

const SignatureCard: React.FC<TemplateCardProps & { isDefault: boolean }> = props => (
    <div className="relative rounded-lg border border-gray-300 bg-white p-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-brand-500">
        <div className="flex-shrink-0">
            <PencilIcon className="w-8 h-8 rounded-full bg-brand-700 text-white p-2" />
        </div>
        <div className="flex-1 min-w-0">
            <Link
                to={`/settings/templates/${props.t.template.id}`}
                className="focus:outline-none">
                <span className="absolute inset-0" aria-hidden="true" />
                <p className="text-sm font-medium text-gray-900">
                    {props.t.template.description ?? "Untitled"}
                </p>
                <p className="text-sm text-gray-500 truncate">
                    {props.isDefault ? <span>Default signature</span> : null}
                </p>
            </Link>
        </div>
    </div>
);
