import {
    currencyInCentsToString,
    genEntityId,
    stringToCurrencyInCents,
} from "@sp-crm/core";
import { Spinner } from "components/ui/spinner";
import { Formik, FormikHelpers } from "formik";
import React, { Reducer, useReducer } from "react";
import {
    InvoiceLineItemInput,
    InvoicePaymentScheduleInput,
    UpdateInvoiceMutationVariables,
    useDeleteInvoiceMutation,
    useUpdateInvoiceMutation,
} from "../../generated/graphql";
import { PrimaryButton } from "../ui/primary-button";
import { SecondaryButton } from "../ui/secondary-button";
import { InvoiceDetailsEditor } from "./invoice-details-editor";
import { ClientInvoice, validatePaymentSchedule } from "./invoice-helpers";
import { InvoicePaymentScheduleErrors } from "./invoice-payment-schedule-errors";

interface Props {
    invoice: ClientInvoice;
    close: () => void;
    refetch: () => void;
}

interface ComponentState {
    state: "closed" | "open" | "submitting";
    errorMessage: string | null;
    isWorking: boolean;
    lineItems: InvoiceLineItemInput[];
    paymentSchedule: InvoicePaymentScheduleInput | null;
}

type Action =
    | { type: "open" }
    | { type: "close" }
    | { type: "submit" }
    | { type: "submitFailure"; error: string | null }
    | { type: "submitSuccess" }
    | { type: "setLineItems"; lineItems: InvoiceLineItemInput[] }
    | { type: "setPaymentSchedule"; paymentSchedule: InvoicePaymentScheduleInput | null };

const initialState = (invoice: ClientInvoice): ComponentState => {
    return {
        state: "open",
        errorMessage: null,
        isWorking: false,
        lineItems: Array.isArray(invoice.lineItems)
            ? invoice.lineItems
            : invoice.amountCents
            ? [
                  {
                      id: genEntityId(),
                      description: "Placement",
                      quantity: 1,
                      unitPriceInCents: invoice.amountCents,
                  },
              ]
            : [],
        paymentSchedule: invoice.paymentSchedule,
    };
};
const reducer: Reducer<ComponentState, Action> = (p, a) => {
    switch (a.type) {
        case "close":
            return { ...p, state: "closed" };
        case "open":
            return { ...p, state: "open" };
        case "submit":
            return { ...p, isWorking: true };
        case "submitSuccess":
            return { ...p, state: "closed", isWorking: false, errorMessage: null };
        case "submitFailure":
            return { ...p, state: "open", isWorking: false, errorMessage: a.error };
        case "setLineItems":
            return { ...p, lineItems: a.lineItems };
        case "setPaymentSchedule":
            return { ...p, paymentSchedule: a.paymentSchedule };
    }
};

interface FormValues {
    status: string;
    paidDate: string | undefined;
    sentDate: string | undefined;
    amount: string;
    serial: string;
    notes: string | undefined;
    dueDate: string | undefined;
    externalReference: string | undefined;
}

export const InvoiceEdit: React.FC<Props> = props => {
    const { close, invoice, refetch } = props;
    const initialValues: FormValues = {
        status: invoice.status,
        paidDate: invoice.paidDate ?? undefined,
        sentDate: invoice.sentDate ?? undefined,
        amount: currencyInCentsToString(invoice.amountCents),
        serial: invoice.serial ?? undefined,
        notes: invoice.notes ?? undefined,
        dueDate: invoice.dueDate ?? undefined,
        externalReference: invoice.externalReference ?? undefined,
    };
    const [{ state, errorMessage, lineItems, paymentSchedule }, dispatch] = useReducer(
        reducer,
        initialState(invoice),
    );
    React.useEffect(() => {
        if (state === "closed") {
            close();
        }
    }, [state]); // eslint-disable-line react-hooks/exhaustive-deps
    const updateInvoice = useUpdateInvoiceMutation();
    const deleteInvoice = useDeleteInvoiceMutation();

    const submitForm = React.useCallback(
        (values: FormValues, helpers: FormikHelpers<FormValues>) => {
            const payload: UpdateInvoiceMutationVariables = {
                invoiceId: invoice.id,
                paidDate: values.paidDate === "" ? null : values.paidDate,
                sentDate: values.sentDate === "" ? null : values.sentDate,
                dueDate: values.dueDate === "" ? null : values.dueDate,
                status: values.status,
                amountCents: stringToCurrencyInCents(values.amount),
                serial: values.serial,
                notes: values.notes,
                lineItems: lineItems,
                paymentSchedule: paymentSchedule,
                externalReference: values.externalReference,
            };
            (async () => {
                helpers.setSubmitting(true);
                dispatch({ type: "submit" });
                const result = await updateInvoice.mutateAsync(payload);
                helpers.setSubmitting(false);
                if (result.updateInvoice.errors) {
                    dispatch({
                        type: "submitFailure",
                        error:
                            result.updateInvoice.errors.message ??
                            "Sorry, there were errors.",
                    });
                    result.updateInvoice.errors.fields.forEach(e => {
                        helpers.setFieldError(
                            e.name === "amountCents" ? "amount" : e.name,
                            e.message,
                        );
                    });
                } else {
                    helpers.resetForm();
                    dispatch({ type: "submitSuccess" });
                    refetch();
                }
            })();
        },
        [dispatch, updateInvoice, lineItems, paymentSchedule, invoice.id, refetch],
    );

    const handleDelete = React.useCallback(async () => {
        await deleteInvoice.mutateAsync({ invoiceId: invoice.id });
        dispatch({ type: "close" });
        refetch();
    }, [deleteInvoice, invoice.id, dispatch, refetch]);

    const paymentScheduleValidationErrors = validatePaymentSchedule(
        lineItems,
        paymentSchedule,
    );

    if (state === "open" || state === "submitting") {
        return (
            <div className="mb-10 space-y-4">
                <Formik onSubmit={submitForm} initialValues={initialValues}>
                    {({
                        values,
                        errors,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        setFieldValue,
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="space-y-6 text-base">
                                {errorMessage ? (
                                    <div className="bg-red-200 text-red-700 p-4 rounded font-bold mb-2">
                                        {errorMessage}
                                    </div>
                                ) : null}
                                <InvoiceDetailsEditor
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    setFieldValue={setFieldValue}
                                    values={values}
                                    touched={touched}
                                    errors={errors}
                                    lineItems={lineItems}
                                    onLineItemsChange={lineItems =>
                                        dispatch({ type: "setLineItems", lineItems })
                                    }
                                    includePaidDate={true}
                                    paymentSchedule={paymentSchedule}
                                    onPaymentScheduleChange={paymentSchedule =>
                                        dispatch({
                                            type: "setPaymentSchedule",
                                            paymentSchedule,
                                        })
                                    }
                                    onDelete={handleDelete}
                                />
                                <InvoicePaymentScheduleErrors
                                    errors={paymentScheduleValidationErrors}
                                />
                                <div className="flex space-x-8 my-4 items-center">
                                    <PrimaryButton
                                        type="submit"
                                        disabled={
                                            isSubmitting ||
                                            paymentScheduleValidationErrors.length > 0
                                        }>
                                        Save
                                    </PrimaryButton>
                                    <SecondaryButton
                                        onClick={e => {
                                            e.preventDefault();
                                            dispatch({ type: "close" });
                                        }}>
                                        Cancel
                                    </SecondaryButton>
                                    <div className="flex space-x-2 items-center">
                                        {isSubmitting ? <Spinner /> : null}
                                        {isSubmitting && invoice.fileId ? (
                                            <p className="text-sm italic">
                                                Re-generating PDF...
                                            </p>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        );
    }
    return <span />;
};
