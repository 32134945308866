import * as React from "react";
import { PipelineState, usePipelineState } from "store/selectors/hooks";
import { ClientSearch } from "../clients/client-search/client-search";
import { DashboardNavigation } from "./dashboard-navigation";
import { PipelineInner } from "./pipeline-inner";

export const PipelineV2: React.FunctionComponent = () => {
    const pipelineState: PipelineState = usePipelineState();

    return (
        <div className="flex flex-col md:inset-0 md:absolute">
            <div>
                <DashboardNavigation />
            </div>
            <div className="pipeline-wrapper flex-1 h-screen md:h-auto md:overflow-hidden">
                <ClientSearch includeCardSort />
                {pipelineState && pipelineState.isReady ? (
                    <PipelineInner
                        sortOrder={pipelineState.sortOrder}
                        searchCriteria={pipelineState.searchCriteria}
                    />
                ) : null}
            </div>
        </div>
    );
};
