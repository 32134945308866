import * as React from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from "store/state";
import { ResponsiveMode } from "../../store/reducers/responsive";
import { SubnavCompact } from "./subnav/subnav-compact";
import { SubnavRegular } from "./subnav/subnav-regular";
import { SubnavPropsOwnProps } from "./subnav/subnav-types";

export const Subnav: React.FC<SubnavPropsOwnProps> = props => {
    const responsiveMode = useSelector(
        (state: ApplicationState) => state.responsive.mode,
    );
    if (responsiveMode < ResponsiveMode.large) {
        return <SubnavCompact {...props} />;
    }
    return <SubnavRegular {...props} />;
};
