import { ResponsiveMode } from "../reducers/responsive";
import { Actions } from "./index";

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
export function updateSize(mode: ResponsiveMode): any {
    return { type: Actions[Actions.UPDATE_SIZE], mode };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
export function hideMenu(): any {
    return { type: Actions[Actions.HIDE_MENU] };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
export function showMenu(): any {
    return { type: Actions[Actions.SHOW_MENU] };
}
