import * as Common from "@sp-crm/core";
import { ITask, taskStatus } from "@sp-crm/core";
import { DeleteButton, EditButton } from "components/ui/action-button";
import { Checkbox } from "components/ui/checkbox";
import { useDeleteTaskMutation, useUpdateTaskMutation } from "generated/graphql";
import * as React from "react";
import { useCurrentUserId } from "store/selectors/hooks";
import { TaskType } from "store/slices/tasks-dashboard";
import { handleEvent } from "util/user-events";
import util from "../../../util";
import { breakNewlines } from "../../../util/text";
import { TaskTypeContext } from "../task-type-provider";
import { EditTask } from "./edit";

interface ShowTaskProps {
    task: ITask;
    onComplete: (task: ITask) => void;
    onUpdated: (task: ITask) => void;
    onDeleted: (task: ITask) => void;
    users: Common.User[];
}

export const ShowTask: React.FC<ShowTaskProps> = props => {
    const { task, onComplete, onUpdated, users, onDeleted } = props;
    const [isEditing, setIsEditing] = React.useState(false);

    const updateTask = useUpdateTaskMutation();
    const deleteTask = useDeleteTaskMutation();

    const toggleEdit = React.useCallback(() => {
        setIsEditing(prev => !prev);
    }, [setIsEditing]);

    const handleUpdateTask = React.useCallback(
        async (
            userId: Common.UserId,
            text: string,
            notes: string,
            dueDate: Common.CalendarDate,
            dueDateTime: Date,
            taskTypeId: Common.TaskTypeId,
        ) => {
            await updateTask.mutateAsync({
                taskId: task.id,
                params: {
                    ownerId: userId,
                    text,
                    notes,
                    dueDate: dueDate?.toString() || null,
                    dueDateTime,
                    taskTypeId,
                },
            });
            onUpdated(task);
        },
        [updateTask, onUpdated, task],
    );

    const handleCompleteChange = React.useCallback(
        async (isComplete: boolean) => {
            if (isComplete) {
                handleEvent("task-complete", { source: "task-detail" });
                onComplete(task);

                await updateTask.mutateAsync({
                    taskId: task.id,
                    params: {
                        completedAt: new Date(),
                        status: taskStatus.done.key,
                        ownerId: task.ownerId,
                    },
                });
                onUpdated(task);
            } else {
                await updateTask.mutateAsync({
                    taskId: task.id,
                    params: {
                        completedAt: null,
                        status: taskStatus.new.key,
                        ownerId: task.ownerId,
                    },
                });
                onUpdated(task);
            }
        },
        [onComplete, task, onUpdated, updateTask],
    );

    const handleDeleted = React.useCallback(async () => {
        await deleteTask.mutateAsync({ taskId: task.id });
        onDeleted(task);
    }, [deleteTask, task, onDeleted]);

    return isEditing ? (
        <div className="p-2 lg:p-4">
            <EditTask
                onToggleEdit={toggleEdit}
                onUpdateTask={handleUpdateTask}
                task={task}
                users={users}
            />
        </div>
    ) : (
        <div className="show-task-control">
            <div className="task-mainline">
                <div className="task-text">
                    <TaskTypeContext.Consumer>
                        {types => (
                            <>
                                <Checkbox
                                    onChange={e => handleCompleteChange(e.target.checked)}
                                    checked={task.status == taskStatus.done.key}
                                    label={<TaskLabel task={task} taskTypes={types} />}
                                />
                                <div className="task-notes">
                                    {breakNewlines(task.notes)}
                                </div>
                            </>
                        )}
                    </TaskTypeContext.Consumer>
                </div>

                <div className="task-edit text-gray-500">
                    <EditButton onClick={toggleEdit} backgroundColor="bg-white" />
                    <DeleteButton
                        onClick={handleDeleted}
                        backgroundColor="bg-white"
                        confirm={{
                            title: `Delete task?`,
                            message: `Really delete ${task.text}?`,
                        }}
                    />
                </div>
            </div>
            <div className="task-meta">
                <div className="assigned-to">
                    <TaskAssignedTo task={task} users={users} />
                </div>
                <div className="task-due">
                    <TaskDueDate task={task} />
                </div>
            </div>
            <div className="task-meta-align-right">
                <div className="task-due">
                    <TaskCompletedDate task={task} />
                </div>
            </div>
        </div>
    );
};

interface TaskLabelProps {
    task: ITask;
    taskTypes: TaskType[];
}

const TaskLabel: React.FC<TaskLabelProps> = ({ task, taskTypes }) => {
    const taskType = task.taskTypeId
        ? taskTypes.find(type => type.id === task.taskTypeId)
        : null;
    return (
        <div className="flex space-x-2 items-center">
            <div>{task.text}</div>
            {taskType ? (
                <div className="bg-indigo-100 text-indigo-800 mr-3 inline-block flex-shrink-0 rounded-full px-3 py-0.5 text-sm font-medium">
                    {taskType.name}
                </div>
            ) : null}
        </div>
    );
};

interface TaskAssignedToProps {
    task: ITask;
    users: Common.User[];
}

const TaskAssignedTo: React.FC<TaskAssignedToProps> = ({ task, users }) => {
    const currentUserId = useCurrentUserId();
    const currentUser = users.find(user => user.id === currentUserId) || null;
    const owner = task.ownerId ? users.find(user => user.id === task.ownerId) : null;

    if (!owner) {
        return <em>unassigned</em>;
    }
    let userNameNode: JSX.Element = <span />;

    if (currentUser && owner && owner.id === currentUser.id) {
        userNameNode = <em>&nbsp;(me)</em>;
    }

    return (
        <span>
            Assigned to:{" "}
            <strong className="semi-bold">
                {util.user.displayName(owner)}
                {userNameNode}
            </strong>
        </span>
    );
};

interface TaskDueDateProps {
    task: ITask;
}

const TaskDueDate: React.FC<TaskDueDateProps> = ({ task }) => {
    if (task.when()) {
        return (
            <span>
                Due:{" "}
                <strong className="semi-bold">
                    {task.dueDate
                        ? util.date.formatCalendarDate(task.dueDate)
                        : util.date.formattedDateTimeDefault(task.dueDateTime)}
                </strong>
            </span>
        );
    }
    return <span>No due date</span>;
};

interface TaskCompletedDateProps {
    task: ITask;
}

const TaskCompletedDate: React.FC<TaskCompletedDateProps> = ({ task }) => {
    if (task.completedAt && task.status === taskStatus.done.key) {
        return (
            <span>
                Completed:{" "}
                <strong className="semi-bold">
                    {util.date.formatDateNoTime(task.completedAt)}
                </strong>
            </span>
        );
    }
    return null;
};
