import { Checkbox } from "components/ui/checkbox";
import { Select } from "components/ui/select";
import { useGetTaskTypesQuery, WorkflowActionTaskEntity } from "generated/graphql";
import { produce } from "immer";
import React from "react";
import { stableQueryOptions } from "util/requests";
import { EditableWorkflow } from "./types";

interface TaskActionEditorProps {
    workflow: EditableWorkflow;
    onChange: (workflow: EditableWorkflow) => void;
}

export const hasSingleTaskAction = (workflow: EditableWorkflow) => {
    return (
        workflow?.actions?.length === 1 &&
        workflow.actions[0].__typename === "WorkflowActionTaskEntity"
    );
};

export const TaskActionEditor: React.FC<TaskActionEditorProps> = props => {
    const { workflow, onChange } = props;

    const taskTypes = useGetTaskTypesQuery(
        {},
        {
            ...stableQueryOptions(),
            enabled: hasSingleTaskAction(workflow),
        },
    );

    const handleTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newWorkflow = produce(workflow, draft => {
            const action = draft.actions[0] as WorkflowActionTaskEntity;

            if (action) {
                action.taskTypeId = e.target.value || null;
            }
        });

        onChange(newWorkflow);
    };

    const handleNotifyOwnerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newWorkflow = produce(workflow, draft => {
            const action = draft.actions[0] as WorkflowActionTaskEntity;

            if (action) {
                action.notifyOwner = e.target.checked;
            }
        });

        onChange(newWorkflow);
    };

    const action = workflow.actions[0] as WorkflowActionTaskEntity;

    return (
        <div className="space-y-2">
            <div>
                <Select
                    label="Task type"
                    value={action.taskTypeId || ""}
                    onChange={handleTypeChange}>
                    <option value=""></option>
                    {taskTypes.data?.getTaskTypes
                        .filter(t => !t.isArchived || t.id === action.taskTypeId)
                        .map(t => (
                            <option key={t.id} value={t.id}>
                                {t.name}
                            </option>
                        ))}
                </Select>
                <div className="ml-6 text-gray-500 group-hover:text-gray-900 text-sm">
                    Tasks created by this workflow will use this type.
                </div>
            </div>
            <div>
                <Checkbox
                    checked={!!action.notifyOwner}
                    onChange={handleNotifyOwnerChange}
                    label="Notify task owner"
                />
                <div className="ml-6 text-gray-500 group-hover:text-gray-900 text-sm">
                    When checked, the task owner will receive an email notification when
                    the task is created.
                </div>
            </div>
        </div>
    );
};
