import { AnswerEntityId, Contact, ReferenceContactId } from "@sp-crm/core";
import { ContactCard } from "components/contacts/card";
import React from "react";
import {
    AdvancedSearchEntityType,
    useGetReferenceContactQuery,
} from "../../generated/graphql";
import { ErrorMessage } from "../ui/error-message";
import { Spinner } from "../ui/spinner";

interface ReferenceContactCardProps {
    referenceId: ReferenceContactId;
    parentEntityType: AdvancedSearchEntityType;
    parentEntityId: AnswerEntityId;
}

export const ReferenceContactCard: React.FC<ReferenceContactCardProps> = props => {
    const query = useGetReferenceContactQuery({ id: props.referenceId });
    if (query.isError) {
        return (
            <ErrorMessage component="ReferenceContactCard">
                <pre>{JSON.stringify(query.error, null, 2)}</pre>
            </ErrorMessage>
        );
    }
    if (query.isLoading) {
        return <Spinner />;
    }
    const contact = query.data?.getReferenceContact.contactEntity;
    if (!contact) {
        return <div>Sorry, no contact information found</div>;
    }

    return (
        <div className="single-contact-info">
            <ContactCard
                contact={Contact.load(contact)}
                parentId={props.parentEntityId}
                parentEntityType={props.parentEntityType}
            />
        </div>
    );
};
