import { ActionType, Reminder } from "@sp-crm/core";
import { RegionToggle } from "components/region-toggle";
import { Panel } from "components/ui/panel/panel";
import { PanelType } from "components/ui/panel/panel-type";
import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Action, Dispatch } from "redux";
import { ApplicationState } from "store/state";
import { push } from "util/history";
import { ReminderContext } from "../../../contexts/reminder";
import * as actions from "../../../store/actions";
import * as menuActions from "../../../store/actions/responsive";
import { hideMenu } from "../../../store/actions/responsive";
import { ResponsiveMode } from "../../../store/reducers/responsive";
import { isAllowed } from "../../../util/permissions";

export const MobileNav: React.FunctionComponent = () => {
    const { mode, showMenu } = useSelector((state: ApplicationState) => state.responsive);
    const dispatch = useDispatch();
    const dismissMenu = React.useCallback(() => {
        dispatch(hideMenu());
    }, [dispatch]);
    if (mode < ResponsiveMode.large) {
        return (
            <Panel
                headerText=""
                isOpen={showMenu}
                isLightDismiss={true}
                type={PanelType.smallFixedNear}
                onDismiss={dismissMenu}>
                <ConnectedCrmNav />
            </Panel>
        );
    }
    return null;
};

interface PropsFromState {
    editAgencySettingsAllowed: boolean;
}

interface PropsFromDispatch {
    navigate: (path: string) => void;
    logout: () => void;
    closeMenu: () => void;
}

type Props = PropsFromDispatch & PropsFromState;

interface NavLink {
    key: string;
    url: string;
    name: string;
}

interface NavAction {
    key: string;
    onClick: () => void;
    name: string;
}

type NavItem = NavAction | NavLink;

const linkClassName =
    "block p-2 text-lg rounded-sm text-gray-700 hover:bg-brand-100 hover:text-brand-800";

class CrmNav extends React.Component<Props> {
    getItems(
        isReminderEnabled: boolean,
        reminders: Reminder[],
        setVisible: (visible: boolean | ((oldState: boolean) => void)) => void,
    ): NavItem[] {
        const settingsLinks: NavItem[] = [
            {
                key: "usersettings",
                url: "/settings",
                name: "Settings",
            },
        ];

        const result: NavItem[] = [
            {
                name: "Dashboard",
                url: "/pipeline",
                key: "dashboard",
            },
            { name: "Tasks", url: "/tasks", key: "tasks" },
            { name: "Clients", url: "/clients", key: "clients" },
            {
                name: "Communities",
                url: "/communities",
                key: "communities",
            },
            {
                name: "Referral Sources",
                url: "/references",
                key: "references",
            },
            {
                name: "Files",
                url: "/files",
                key: "files",
            },
            { name: "Reports", url: "/reports", key: "reports" },
            ...settingsLinks,
            ...(isReminderEnabled
                ? [
                      {
                          name: `Reminders${
                              reminders.length > 0 ? ` (${reminders.length})` : ""
                          }`,
                          url: "#",
                          onClick: () => setVisible(visible => !visible),
                          key: "reminder",
                      },
                  ]
                : []),
            {
                name: "Log out",
                onClick: this.props.logout,
                key: "logout",
            },
        ];
        return result;
    }

    private renderItem(item: NavItem) {
        if ((item as NavAction).onClick) {
            const action = item as NavAction;
            const handler: React.MouseEventHandler<HTMLAnchorElement> = e => {
                e.preventDefault();
                action.onClick();
                this.props.closeMenu();
            };
            return (
                <li key={action.key}>
                    <a className={linkClassName} href="#" onClick={handler}>
                        {action.name}
                    </a>
                </li>
            );
        }
        if ((item as NavLink).url) {
            const link = item as NavLink;
            const handler: React.MouseEventHandler<HTMLAnchorElement> = () =>
                this.props.closeMenu();
            return (
                <li key={link.key}>
                    <Link className={linkClassName} to={link.url} onClick={handler}>
                        {link.name}
                    </Link>
                </li>
            );
        }
        console.warn("Missing nav check");
        return null;
    }

    render() {
        return (
            <ReminderContext.Consumer>
                {({ isReminderEnabled, reminders, setVisible }) => (
                    <>
                        <ul className="space-y-1 mb-4">
                            {this.getItems(isReminderEnabled, reminders, setVisible).map(
                                i => this.renderItem(i),
                            )}
                        </ul>
                        <RegionToggle />
                    </>
                )}
            </ReminderContext.Consumer>
        );
    }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
function mapStateToProps(state: ApplicationState): PropsFromState {
    return {
        editAgencySettingsAllowed: isAllowed(
            ActionType.UpdateTenant,
            state.permissions,
            state.region,
        ),
    };
}

function mapDispatchToProps(dispatch: Dispatch<Action>): PropsFromDispatch {
    const val = {
        navigate: (path: string) => {
            push(path);
        },
        logout: () => {
            actions.logout(dispatch);
        },
        closeMenu: () => {
            dispatch(menuActions.hideMenu());
        },
    };
    return val;
}

const ConnectedCrmNav = connect(mapStateToProps, mapDispatchToProps)(CrmNav);
