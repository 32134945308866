import React from "react";
import { Redirect, Route, Switch } from "react-router";
import { ManageIntegrationAccounts } from "./manage-integration-accounts";
import { ManageIntegrationsNav } from "./manage-integrations-nav";
import { ManageIntegrationsQuickBooks } from "./manage-integrations-quick-books";

export const ManageIntegrations: React.FC<unknown> = () => {
    return (
        <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
            <div className="lg:col-span-3">
                <ManageIntegrationsNav />
            </div>
            <div className="lg:col-span-9 pb-4">
                <Switch>
                    <Route
                        path="/settings/integrations/accounts"
                        component={ManageIntegrationAccounts}
                    />
                    <Route
                        path="/settings/integrations/quickbooks"
                        component={ManageIntegrationsQuickBooks}
                    />
                    <Route
                        path="/settings/integrations"
                        render={() => <Redirect to="/settings/integrations/accounts" />}
                    />
                </Switch>
            </div>
        </div>
    );
};
