import {
    Dialog,
    DialogPanel,
    DialogTitle,
    Transition,
    TransitionChild,
} from "@headlessui/react";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import React from "react";
import ReactDOM from "react-dom/client";
import { breakNewlines } from "util/text";
import { PrimaryButton } from "./primary-button";
import { SecondaryButton } from "./secondary-button";

interface FancyModalSelectOption<T extends string> {
    label: string;
    value: T;
    buttonStyle?: "primary" | "secondary";
}

interface Props<T extends string> {
    title: string;
    prompt: string;
    resolve: (value: T | PromiseLike<T>) => void;
    actions: FancyModalSelectOption<T>[];
}

const ModalSelectDialog = <T extends string>(props: Props<T>) => {
    const { actions, prompt, resolve, title } = props;
    const [isHidden, setIsHidden] = React.useState(false);

    return (
        <Transition show={!isHidden} appear={true}>
            <Dialog as="div" className="relative z-1500" onClose={close}>
                <TransitionChild
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </TransitionChild>

                <div className="fixed z-1500 inset-0 overflow-y-auto">
                    <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                        <TransitionChild
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                            <DialogPanel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-3xl sm:w-full sm:p-6">
                                <div className="sm:flex sm:items-start">
                                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-brand-100 sm:mx-0 sm:h-10 sm:w-10">
                                        <QuestionMarkCircleIcon
                                            className="h-6 w-6 text-brand-600"
                                            aria-hidden="true"
                                        />
                                    </div>

                                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                        <DialogTitle
                                            as="h3"
                                            className="text-lg leading-6 font-medium text-gray-900">
                                            {title}
                                        </DialogTitle>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                {breakNewlines(prompt)}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-3 sm:gap-3 sm:grid-flow-row-dense">
                                    {actions.map(action =>
                                        action.buttonStyle === "primary" ? (
                                            <PrimaryButton
                                                key={action.value}
                                                onClick={() => {
                                                    setIsHidden(true);
                                                    resolve(action.value);
                                                }}>
                                                {action.label}
                                            </PrimaryButton>
                                        ) : (
                                            <SecondaryButton
                                                key={action.value}
                                                onClick={() => {
                                                    setIsHidden(true);
                                                    resolve(action.value);
                                                }}>
                                                {action.label}
                                            </SecondaryButton>
                                        ),
                                    )}
                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

export const fancyModalSelect = async <T extends string>(
    title: string,
    prompt: string,
    actions: FancyModalSelectOption<T>[],
): Promise<T> => {
    const wrapper = document.body.appendChild(document.createElement("div"));
    const root = ReactDOM.createRoot(wrapper);
    const promise = new Promise<T>(resolve => {
        root.render(
            <ModalSelectDialog
                title={title}
                resolve={resolve}
                prompt={prompt}
                actions={actions}
            />,
        );
    });
    const result = await promise;
    root.unmount();
    setTimeout(() => wrapper.remove());
    return result;
};
