import { CommunityId } from "@sp-crm/core";
import { SecondaryButton } from "components/ui/secondary-button";
import * as React from "react";
import { disableAdvancedMode } from "util/browser";
import { CommunityAdvancedDetail } from "./advanced/advanced-detail";

interface CommunityAdvancedViewProps {
    communityId: CommunityId;
}

export const CommunityAdvancedView: React.FC<CommunityAdvancedViewProps> = props => {
    const { communityId } = props;
    return (
        <div className="flex-grow">
            <div className="space-y-4">
                <CommunityAdvancedDetail communityId={communityId} />
                <SecondaryButton onClick={disableAdvancedMode}>
                    Hide advanced tab
                </SecondaryButton>
            </div>
        </div>
    );
};
