import {
    CommunityRelationshipType,
    IClient,
    MAX_ALLOWED_BULK_CLIENT_COMMUNITIES,
    QuestionId,
} from "@sp-crm/core";
import { fancyAlert } from "components/ui/fancy-alert";
import { fancyModalSelect } from "components/ui/fancy-modal-select";
import { SecondaryButton } from "components/ui/secondary-button";
import { Spinner } from "components/ui/spinner";
import {
    CommunitySearchRequest,
    useAddClientCommunitiesMutation,
    useCommunitySearchQuery,
} from "generated/graphql";
import { produce } from "immer";
import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { loadClient } from "store/actions";
import { useSupportEmailAddress } from "store/selectors/branding";
import { useTenantSettings } from "store/selectors/hooks";
import { pluralize } from "util/pluralize";

interface ClientCommunityBulkAddProps {
    total: number;
    searchParams: Omit<CommunitySearchRequest, "questionIds">;
    questionIds: QuestionId[];
    client: IClient;
}

const showTooManyAlert = () =>
    fancyAlert(
        "Too many matches",
        `Adding communities in bulk is only allowed if the resulting match list will have ${MAX_ALLOWED_BULK_CLIENT_COMMUNITIES} matches or less. Refine your search, remove existing matches, or add communities individually to proceed.`,
        "warning",
    );

export const ClientCommunityBulkAdd: React.FC<ClientCommunityBulkAddProps> = props => {
    const { total, searchParams, questionIds, client } = props;

    const dispatch = useDispatch();

    const searchCommunityIdsParams = useMemo(() => {
        return produce(searchParams, draft => {
            draft.page = 0;
            draft.perPage = total;
            draft.includeQuestionIds = questionIds;
        });
    }, [searchParams, total, questionIds]);

    const addClientCommunitiesMutation = useAddClientCommunitiesMutation();
    const supportEmail = useSupportEmailAddress();

    const communitySearchQuery = useCommunitySearchQuery(
        {
            params: searchCommunityIdsParams,
        },
        {
            enabled: false,
            onSuccess: async data => {
                const existingMatchCount = client.clientCommunities.filter(
                    cc => cc.relationship !== CommunityRelationshipType.excluded,
                );
                const toAdd = data.communitySearch.hits.filter(
                    h => !client.clientCommunities.some(cc => cc.community.id === h.id),
                );

                if (
                    existingMatchCount.length + toAdd.length >
                    MAX_ALLOWED_BULK_CLIENT_COMMUNITIES
                ) {
                    await showTooManyAlert();
                } else {
                    if (toAdd.length === 0) {
                        await fancyAlert(
                            "No new matches",
                            "All of the communities in the search results are already potential matches.",
                            "success",
                        );
                    } else {
                        const result = await fancyModalSelect(
                            "Add communities",
                            `Add ${toAdd.length} new ${pluralize(
                                toAdd.length,
                                "community",
                                "communities",
                            )} as potential matches?`,
                            [
                                {
                                    label: "Yes, add",
                                    value: "add",
                                    buttonStyle: "primary",
                                },
                                { label: "Yes, add to family view", value: "family" },
                                { label: "Cancel", value: "cancel" },
                            ],
                        );

                        if (result === "cancel") {
                            return;
                        }

                        try {
                            await addClientCommunitiesMutation.mutateAsync({
                                clientId: client.id,
                                questionIds,
                                params: data.communitySearch.hits.map(hit => ({
                                    communityId: hit.id,
                                    familyView: result === "family",
                                })),
                            });
                            loadClient(client.id, dispatch);
                        } catch (e) {
                            if (
                                typeof e.hasErrorType === "function" &&
                                e.hasErrorType("INVALID_RECORD")
                            ) {
                                await showTooManyAlert();
                            } else {
                                await fancyAlert(
                                    "Error",
                                    `An error occurred while adding communities. Please try again. If the problem persists, contact ${supportEmail}.`,
                                    "warning",
                                );
                            }
                        }
                    }
                }
            },
        },
    );

    const handleAddClick = React.useCallback(
        async (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
            e.preventDefault();

            // This is to prevent making requests that are too large,
            // but also ensure we account for excluded communities in
            // search results. It's not a perfect approach if they exclude
            // more than 2/3 of the results, but it's good enough for now.
            if (total > MAX_ALLOWED_BULK_CLIENT_COMMUNITIES * 3) {
                await showTooManyAlert();
            } else {
                communitySearchQuery.refetch();
            }
        },
        [total, communitySearchQuery],
    );

    const enabledForTenant = useTenantSettings().enableBulkCommunityOptions;

    const hasResults = total > 0;
    const loading =
        communitySearchQuery.isLoading || addClientCommunitiesMutation.isLoading;

    if (!enabledForTenant) {
        return null;
    }

    return (
        <div className="flex items-center space-x-2">
            {loading ? <Spinner /> : null}
            <SecondaryButton disabled={!hasResults || loading} onClick={handleAddClick}>
                <span className="text-sm">Add all as potential matches</span>
            </SecondaryButton>
        </div>
    );
};
