import { WhiteLabelContentMode } from "generated/graphql";
import React from "react";
import { useWhiteLabelContentMode } from "store/selectors/branding";

interface WhiteLabelRestrictedProps {
    children: React.ReactNode;
}

export const WhiteLabelRestricted: React.FC<WhiteLabelRestrictedProps> = props => {
    const contentMode = useWhiteLabelContentMode();

    if (contentMode === WhiteLabelContentMode.Full) {
        return <>{props.children}</>;
    }

    return null;
};
