import { QueryRenderer } from "components/clients/show-client/community-comparison/query-renderer";
import { ContentFull, ContentHeader } from "components/layout";
import { useGetEntitiesQuery } from "generated/graphql";
import React from "react";
import { stableQueryOptions } from "util/requests";
import { AgencyCheckboxPref } from "./agency-settings-checkbox";
import { EntitySettings } from "./customize/entity-settings";

export const AgencySettingsReferrals: React.FC<unknown> = () => {
    const entitiesQuery = useGetEntitiesQuery({}, stableQueryOptions());
    return (
        <div className="space-y-4 lg:space-y-8">
            <ContentFull>
                <ContentHeader>Referrals</ContentHeader>
                <div className="space-y-4">
                    <AgencyCheckboxPref
                        field="ownedReferralSources"
                        label="Referral sources are assignable to users"
                        description="Allow referral sources (both businesses and contacts) to be assigned to 1 user within the organization."
                    />
                </div>
            </ContentFull>
            <QueryRenderer query={entitiesQuery} name="Settings.getEntities">
                {data => (
                    <>
                        <ContentFull>
                            <ContentHeader>Customize Organizations</ContentHeader>

                            <EntitySettings
                                entityName="ReferenceBusiness"
                                entities={data.getEntities}
                            />
                        </ContentFull>

                        <ContentFull>
                            <ContentHeader>Customize Contacts</ContentHeader>

                            <EntitySettings
                                entityName="ReferenceContact"
                                entities={data.getEntities}
                            />
                        </ContentFull>
                    </>
                )}
            </QueryRenderer>
        </div>
    );
};
