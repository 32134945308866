import { InvoiceEdit } from "components/invoices/invoice-edit";
import { InvoiceResend } from "components/invoices/invoice-resend";
import { Panel } from "components/ui/panel/panel";
import { PanelType } from "components/ui/panel/panel-type";
import React from "react";
import { UseQueryResult } from "react-query";
import { GetCommunityQuery } from "../../generated/graphql";
import { InvoiceRow } from "../invoices/invoice-row";
import { ErrorMessage } from "../ui/error-message";
import { Spinner } from "../ui/spinner";

interface Props {
    canModifyInvoices: boolean;
    query: UseQueryResult<GetCommunityQuery>;
    refetch: () => void;
}

type CommunityInvoice = GetCommunityQuery["getCommunity"]["invoices"][0];

export const CommunityInvoiceList: React.FC<Props> = props => {
    const { query, refetch, canModifyInvoices } = props;

    const [editingInvoice, setEditingInvoice] = React.useState<CommunityInvoice>(null);
    const [sendingInvoice, setSendingInvoice] = React.useState<CommunityInvoice>(null);

    const handleEdit = React.useCallback(
        (invoice: CommunityInvoice) => {
            setEditingInvoice(invoice);
        },
        [setEditingInvoice],
    );

    const handleDismissEdit = React.useCallback(() => {
        setEditingInvoice(null);
    }, [setEditingInvoice]);

    const handleSend = React.useCallback(
        (invoice: CommunityInvoice) => {
            setSendingInvoice(invoice);
        },
        [setSendingInvoice],
    );

    const handleDismissSend = React.useCallback(() => {
        setSendingInvoice(null);
    }, [setSendingInvoice]);

    if (query.isLoading) {
        return <Spinner />;
    }
    if (query.isError) {
        return (
            <ErrorMessage component="InvoiceList">
                <pre>{JSON.stringify(query.error, null, 2)}</pre>
            </ErrorMessage>
        );
    }
    if (!query.data) {
        return <Spinner />;
    }

    const sortedInvoices = query.data.getCommunity.invoices.sort((b, a) =>
        `${a.serial ?? "9999"}${a.id}`.localeCompare(`${b.serial ?? "9999"}${b.id}`),
    );

    return (
        <div className="shadow-md overflow-hidden border-b border-gray-200 sm:rounded">
            <table className="min-w-full divide-y divide-gray-200 md:rounded">
                <thead className="bg-gray-100">
                    <tr>
                        <th scope="col" colSpan={4}></th>
                        <th
                            scope="col"
                            className="px-6 py-3 text-left text-sm font-medium text-gray-600 uppercase">
                            status
                        </th>
                        <th
                            scope="col"
                            className="px-6 py-3 text-left text-sm font-medium text-gray-600 uppercase">
                            Number
                        </th>
                        <th
                            scope="col"
                            className="px-6 py-3 text-left text-sm font-medium text-gray-600 uppercase">
                            Client
                        </th>
                        <th
                            scope="col"
                            className="px-6 py-3 text-left text-sm font-medium text-gray-600 uppercase">
                            Sent On
                        </th>
                        <th
                            scope="col"
                            className="px-6 py-3 text-left text-sm font-medium text-gray-600 uppercase">
                            Paid On
                        </th>
                        <th
                            scope="col"
                            className="px-6 py-3 text-left text-sm font-medium text-gray-600 uppercase">
                            Due
                        </th>
                        <th
                            scope="col"
                            className="px-6 py-3 text-left text-sm font-medium text-gray-600 uppercase text-right">
                            Amount
                        </th>
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-100 bg-white">
                    {!sortedInvoices || sortedInvoices.length === 0 ? (
                        <tr>
                            <td className="p-5 text-center text-base" colSpan={11}>
                                <em>No invoices created yet</em>
                            </td>
                        </tr>
                    ) : null}
                    {sortedInvoices && sortedInvoices.length !== 0
                        ? sortedInvoices.map(i => (
                              <InvoiceRow
                                  descriptionBehavior="client"
                                  canModifyInvoices={canModifyInvoices}
                                  refetch={refetch}
                                  key={i.id}
                                  invoice={i}
                                  onEdit={handleEdit}
                                  onSend={handleSend}
                              />
                          ))
                        : null}
                </tbody>
            </table>
            <Panel
                headerText="Edit Invoice"
                isOpen={!!editingInvoice}
                onDismiss={handleDismissEdit}
                type={PanelType.extraLarge}>
                {editingInvoice ? (
                    <InvoiceEdit
                        refetch={refetch}
                        invoice={editingInvoice}
                        close={handleDismissEdit}
                    />
                ) : null}
            </Panel>
            <Panel
                headerText={sendingInvoice ? `Send Invoice ${sendingInvoice.serial}` : ""}
                isOpen={!!sendingInvoice}
                onDismiss={handleDismissSend}
                type={PanelType.extraLarge}>
                {sendingInvoice ? (
                    <InvoiceResend
                        refetch={refetch}
                        invoice={sendingInvoice}
                        onCancel={handleDismissSend}
                    />
                ) : null}
            </Panel>
        </div>
    );
};
