import { CalendarDate, ClientId, ReferralId } from "@sp-crm/core";
import { CrmTable, CrmTableProps } from "components/table/crm-table";
import { Panel } from "components/ui/panel/panel";
import { PanelType } from "components/ui/panel/panel-type";
import { PrimaryButton } from "components/ui/primary-button";
import {
    GetOutboundClientReferralsQuery,
    MutationPatchClientReferralArgs,
    useCreateClientReferralMutation,
    useGetOutboundClientReferralsQuery,
    usePatchClientReferralMutation,
} from "generated/graphql";
import React, { useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { ClientReferralEdit } from "./referral-edit";

type ClientReferralResult = GetOutboundClientReferralsQuery["getClientReferrals"][0];

interface DetailRow {
    id: ReferralId;
    when: CalendarDate;
    referenceBusiness: ClientReferralResult["referenceBusiness"];
    referenceContact: ClientReferralResult["referenceContact"];
    community: ClientReferralResult["community"];
    original: ClientReferralResult;
}

interface EditReferralLinkProps {
    referral: ClientReferralResult;
    onClick: (referral: ClientReferralResult) => void;
}

export const EditReferralLink: React.FC<EditReferralLinkProps> = props => {
    const { referral, onClick } = props;

    const handleClick = React.useCallback(
        async (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
            e.preventDefault();
            onClick(referral);
        },
        [referral, onClick],
    );

    return (
        <a href="#" onClick={handleClick}>
            edit
        </a>
    );
};

interface ShowClientReferralsProps {
    clientId: ClientId;
}

export const ShowClientReferrals: React.FC<ShowClientReferralsProps> = props => {
    const { clientId } = props;
    const [editingReferral, setEditingReferral] = useState<ClientReferralResult>(null);

    const getReferralsQuery = useGetOutboundClientReferralsQuery(
        { id: clientId },
        { keepPreviousData: true },
    );
    const createReferralMutation = useCreateClientReferralMutation();
    const patchReferralMutation = usePatchClientReferralMutation();

    const handleAddReferral = useCallback(
        async (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
            e.preventDefault();
            const response = await createReferralMutation.mutateAsync({
                clientId,
                referralType: "outbound",
            });

            setEditingReferral(response.createClientReferral);
        },
        [createReferralMutation, clientId, setEditingReferral],
    );

    const hide = useCallback(() => {
        setEditingReferral(null);
        getReferralsQuery.refetch();
    }, [setEditingReferral, getReferralsQuery]);

    const handleClose = React.useCallback(
        async (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
            e.preventDefault();
            hide();
        },
        [hide],
    );

    const handleReferralChange = useCallback(
        async (params: MutationPatchClientReferralArgs) => {
            const response = await patchReferralMutation.mutateAsync(params);
            if (params.deleted) {
                hide();
            } else {
                setEditingReferral(response.patchClientReferral);
            }
        },
        [patchReferralMutation, hide, setEditingReferral],
    );

    const handleEditReferral = useCallback(
        (referral: ClientReferralResult) => {
            setEditingReferral(referral);
        },
        [setEditingReferral],
    );

    const referralData = useMemo(() => {
        return (getReferralsQuery.data?.getClientReferrals || []).map(r => ({
            id: r.id,
            when: CalendarDate.parse(r.when).getOrElse(CalendarDate.epoch),
            referenceBusiness: r.referenceBusiness,
            referenceContact: r.referenceContact,
            community: r.community,
            original: r,
        }));
    }, [getReferralsQuery.data?.getClientReferrals]);

    const columns: CrmTableProps<DetailRow>["columns"] = useMemo(
        () => [
            {
                key: "id",
                header: "",
                renderCell: ({ record }) => (
                    <EditReferralLink
                        referral={record.original}
                        onClick={handleEditReferral}
                    />
                ),
            },
            {
                key: "when",
                header: "When",
                renderCell: ({ record }) => <span>{record.when.toString()}</span>,
            },
            {
                key: "referenceBusiness",
                header: "Organization",
                renderCell: ({ record }) =>
                    record.referenceBusiness ? (
                        <Link
                            to={`/references/organizations/show/${record.referenceBusiness.id}`}>
                            {record.referenceBusiness.businessContactEntity.name}
                        </Link>
                    ) : null,
            },
            {
                key: "referenceContact",
                header: "Contact",
                renderCell: ({ record }) =>
                    record.referenceContact ? (
                        <Link
                            to={`/references/contacts/show/${record.referenceContact.id}`}>
                            {record.referenceContact.contactEntity.name}
                        </Link>
                    ) : null,
            },
            {
                key: "community",
                header: "Community",
                renderCell: ({ record }) =>
                    record.community ? (
                        <Link to={`/communities/show/${record.community.id}`}>
                            {record.community.name}
                        </Link>
                    ) : null,
            },
        ],
        [handleEditReferral],
    );

    return (
        <div>
            <p>
                Professional Referrals allow you to track when you&apos;ve referred your
                client out to another professional (i.e. to a Realtor, Accountant, etc.).
            </p>
            <br />
            <div className="list-actions">
                <PrimaryButton onClick={handleAddReferral}>Add a referral</PrimaryButton>
            </div>
            {getReferralsQuery.data?.getClientReferrals.length > 0 && (
                <CrmTable columns={columns} data={referralData} />
            )}
            <Panel
                isOpen={!!editingReferral}
                onDismiss={hide}
                type={PanelType.largeFixed}
                className="app-component edit-contact-panel"
                headerText={"Edit Referral"}>
                <div className="panel-body">
                    {editingReferral ? (
                        <ClientReferralEdit
                            referral={editingReferral}
                            onChange={handleReferralChange}
                        />
                    ) : null}
                    <div className="save-cancel-buttons">
                        <PrimaryButton onClick={handleClose}>Close</PrimaryButton>
                    </div>
                </div>
            </Panel>
        </div>
    );
};
