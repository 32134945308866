import {
    AdditionalClientContact,
    ClientId,
    ContactId,
    ReferenceContactId,
} from "@sp-crm/core";
import {
    ReferenceContactPicker,
    SelectedReferenceContact,
} from "components/references/select-reference/reference-contact-picker";
import { Panel } from "components/ui/panel/panel";
import { PanelType } from "components/ui/panel/panel-type";
import { CrmPersona } from "components/ui/persona";
import { SecondaryButton } from "components/ui/secondary-button";
import React, { FunctionComponent, useCallback } from "react";
import ContactList from "./contact-list";

type AdditionalClientContactListProps = {
    name: string;
    clientId: ClientId;
    onAddNewContact: () => void;
    onLinkContact: (referenceContactId: ReferenceContactId) => void;
    contacts: AdditionalClientContact[];
    editContact: (contactId: ContactId) => void;
    deleteContact: (contactId: ContactId) => void;
};

const AdditionalClientContactList: FunctionComponent<
    AdditionalClientContactListProps
> = ({
    name,
    onAddNewContact,
    contacts,
    editContact,
    deleteContact,
    onLinkContact,
    clientId,
}) => {
    const [showReferralPicker, setShowReferralPicker] = React.useState(false);

    const handleAddContact = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            e.stopPropagation();
            onAddNewContact();
        },
        [onAddNewContact],
    );

    const handleLinkContact = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            e.stopPropagation();
            setShowReferralPicker(true);
        },
        [setShowReferralPicker],
    );

    const handleReferenceContactSelected = useCallback(
        (selected: SelectedReferenceContact) => {
            setShowReferralPicker(false);
            onLinkContact(selected.referenceContactId);
        },
        [onLinkContact, setShowReferralPicker],
    );

    return (
        <div>
            <div className="flex justify-between">
                <CrmPersona name={name} size="small" />
                <div className="flex space-x-2">
                    <SecondaryButton onClick={handleAddContact}>
                        <span className="text-sm">Add contact</span>
                    </SecondaryButton>
                    <SecondaryButton onClick={handleLinkContact}>
                        <span className="text-sm">Link referral contact</span>
                    </SecondaryButton>
                </div>
            </div>
            <ContactList
                clientId={clientId}
                contacts={contacts}
                editContact={editContact}
                deleteContact={deleteContact}
            />
            <Panel
                headerText="Choose referral contact"
                isOpen={showReferralPicker}
                type={PanelType.extraLarge}
                onDismiss={() => setShowReferralPicker(false)}>
                <ReferenceContactPicker onSelect={handleReferenceContactSelected} />
            </Panel>
        </div>
    );
};

export default AdditionalClientContactList;
