import { CustomListKey } from "@sp-crm/core";
import { Select } from "components/ui/select";
import React, { useCallback } from "react";
import { useCustomList } from "store/selectors/bridge";

interface CustomListSelectProps {
    customListKey: CustomListKey;
    value: string | null;
    onChange: (value: string | null) => void;
    valueMode: "id" | "key";
    includeArchived?: boolean;
    label?: string;
}

export const CustomListSelect: React.FC<CustomListSelectProps> = props => {
    const { customListKey, value, onChange, valueMode, label, includeArchived } = props;

    const { customList } = useCustomList(customListKey);

    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLSelectElement>) => {
            onChange(e.target.value ? e.target.value : null);
        },
        [onChange],
    );

    if (!customList) {
        return (
            <Select disabled>
                <option>Loading...</option>
            </Select>
        );
    }

    return (
        <Select value={value} onChange={handleChange} label={label}>
            {customList.categories
                .filter(category =>
                    customList.items.some(
                        item =>
                            item.customListCategoryId === category.id &&
                            (!item.isArchived ||
                                (valueMode === "id" && item.id === value) ||
                                (valueMode === "key" && item.key === value)),
                    ),
                )
                .map(category => (
                    <optgroup key={category.id} label={category.name}>
                        {customList.items
                            .filter(
                                item =>
                                    item.customListCategoryId === category.id &&
                                    (!item.isArchived ||
                                        (valueMode === "id" && item.id === value) ||
                                        (valueMode === "key" && item.key === value)),
                            )
                            .map(item => (
                                <option
                                    key={item.id}
                                    value={valueMode === "id" ? item.id : item.key}>
                                    {item.name}
                                </option>
                            ))}
                    </optgroup>
                ))}
            {includeArchived ? (
                <optgroup label="Archived">
                    {customList.items
                        .filter(item => item.isArchived)
                        .map(item => (
                            <option
                                key={item.id}
                                value={valueMode === "id" ? item.id : item.key}>
                                {item.name}
                            </option>
                        ))}
                </optgroup>
            ) : null}
        </Select>
    );
};
