import { CurrencyInCents, currencyInCentsToString, formatCurrency } from "@sp-crm/core";
import {
    GetClientQuery,
    GetCommunityQuery,
    InvoiceLineItemInput,
    InvoicePaymentScheduleInput,
} from "generated/graphql";

export type ClientInvoice = NonNullable<GetClientQuery["getClient"]["invoices"]>[0];
export type CommunityInvoice = NonNullable<
    GetCommunityQuery["getCommunity"]["invoices"]
>[0];

export const validatePaymentSchedule = (
    lineItems: InvoiceLineItemInput[] | null,
    paymentSchedule: InvoicePaymentScheduleInput | null,
): string[] => {
    const errors: string[] = [];

    if (!paymentSchedule) {
        return errors;
    }

    lineItems = lineItems || [];

    const totalLineItemAmountInCents = lineItems.reduce((total, lineItem) => {
        return total + lineItem.quantity * lineItem.unitPriceInCents;
    }, 0);

    const totalPaymentScheduleAmountInCents = paymentSchedule.items.reduce(
        (total, paymentScheduleItem) => {
            return total + paymentScheduleItem.amountInCents;
        },
        0,
    );

    if (totalLineItemAmountInCents !== totalPaymentScheduleAmountInCents) {
        errors.push(
            `The payment schedule total amount (${formatCurrency(
                currencyInCentsToString(
                    totalPaymentScheduleAmountInCents as CurrencyInCents,
                ),
            )}) needs to match the invoice total amount (${formatCurrency(
                currencyInCentsToString(totalLineItemAmountInCents as CurrencyInCents),
            )})`,
        );
    }

    if (!paymentSchedule.items.length) {
        errors.push(`The payment schedule must have at least one payment`);
    }

    if (!paymentSchedule.items.every(item => !!item.dueDate)) {
        errors.push(`All scheduled payments must have a due date`);
    }

    return errors;
};
