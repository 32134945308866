import { ITask, Task, TaskParentId } from "@sp-crm/core";
import { useGetTasksForEntityQuery } from "generated/graphql";
import { useCallback, useMemo } from "react";

export const useEntityTasks = (
    parentId: TaskParentId,
): { tasks: ITask[]; refetchTasks: () => void; tasksLoaded: boolean } => {
    const getTasks = useGetTasksForEntityQuery({ parentId });

    const tasks = useMemo(() => {
        return (getTasks.data?.getTasksForEntity || []).map(t => Task.load(t));
    }, [getTasks.data?.getTasksForEntity]);

    const refetchTasks = useCallback(() => {
        getTasks.refetch();
    }, [getTasks]);

    return { tasks, refetchTasks, tasksLoaded: getTasks.isSuccess };
};
