import { ClientId } from "@sp-crm/core";
import { QueryRenderer } from "components/clients/show-client/community-comparison/query-renderer";
import React from "react";
import { stableQueryOptions } from "util/requests";
import {
    InvoiceCreateExperience,
    useGetInvoiceCreateExperienceQuery,
} from "../../generated/graphql";
import { InvoiceInput } from "../clients/invoices";
import { InvoiceCreateExternal } from "./invoice-create-external";
import { InvoiceCreateInternal } from "./invoice-create-internal";

interface Props {
    clientId: ClientId;
    invoiceInput: InvoiceInput;
    refetch: () => void;
}

export const InvoiceCreate: React.FC<Props> = props => {
    const invoiceCreateExperience = useGetInvoiceCreateExperienceQuery(
        {},
        stableQueryOptions(),
    );
    return (
        <QueryRenderer query={invoiceCreateExperience} name="InvoiceCreate">
            {data =>
                data.getInvoiceCreateExperience.experience ===
                InvoiceCreateExperience.External ? (
                    <InvoiceCreateExternal {...props} />
                ) : (
                    <InvoiceCreateInternal {...props} />
                )
            }
        </QueryRenderer>
    );
};
