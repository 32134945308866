import { Bars3Icon } from "@heroicons/react/24/outline";
import { CustomListCategoryId, CustomListKey, parseLayoutIcon } from "@sp-crm/core";
import { QueryRenderer } from "components/clients/show-client/community-comparison/query-renderer";
import { IconPicker } from "components/layout/icon-picker";
import { DeleteButton, RestoreButton } from "components/ui/action-button";
import { AutosavingCheckbox } from "components/ui/autosaving-checkbox";
import { AutosavingInput } from "components/ui/autosaving-input";
import { fancyConfirm } from "components/ui/fancy-confirm";
import { Input } from "components/ui/input";
import { PrimaryButton } from "components/ui/primary-button";
import { Select } from "components/ui/select";
import {
    CustomListItemUpdateBehavior,
    GetCustomListQuery,
    PipelineVisibility,
    useCreateCustomListItemMutation,
    useGetCustomListQuery,
    usePatchCustomListItemMutation,
    usePatchCustomListItemsMutation,
} from "generated/graphql";
import { produce } from "immer";
import React, { useCallback, useMemo } from "react";
import { useQueryClient } from "react-query";
import {
    calculateNewOrder,
    useReorderableListDrag,
    useReorderableListDrop,
} from "util/dnd";

interface CustomListEditorProps {
    disabled?: boolean;
    customListKey: CustomListKey;
    getHelpText?: (item: GetCustomListQuery["getCustomList"]["items"][0]) => string;
}

export const CustomListEditor: React.FC<CustomListEditorProps> = props => {
    const { customListKey, getHelpText, disabled } = props;

    const [newStatusText, setNewStatusText] = React.useState("");
    const getCustomList = useGetCustomListQuery(
        { key: customListKey },
        { keepPreviousData: true },
    );
    const queryClient = useQueryClient();
    const patchItems = usePatchCustomListItemsMutation();
    const createItem = useCreateCustomListItemMutation();

    const handleCommitOrder = useCallback(async () => {
        if (getCustomList.data?.getCustomList?.items?.length > 0) {
            await patchItems.mutateAsync({
                params: getCustomList.data.getCustomList.items.map((item, index) => ({
                    id: item.id,
                    order: index,
                })),
            });
            getCustomList.refetch();
        }
    }, [patchItems, getCustomList]);

    const handleRevertOrder = useCallback(() => {
        getCustomList.refetch();
    }, [getCustomList]);

    const handleReorder = useCallback(
        (draggingIndex: number, targetIndex: number) => {
            queryClient.setQueryData(
                ["getCustomList", { key: customListKey }],
                (oldData: GetCustomListQuery) => {
                    return produce(oldData, draft => {
                        const newOrder = calculateNewOrder(
                            draft.getCustomList.items,
                            draggingIndex,
                            targetIndex,
                        );

                        const current = draft.getCustomList.items[draggingIndex];
                        const currentActualElement = draft.getCustomList.items.find(
                            i => i.id === current.id,
                        );
                        currentActualElement.order = newOrder;
                        draft.getCustomList.items.sort((a, b) => a.order - b.order);
                    });
                },
            );
        },
        [queryClient, customListKey],
    );

    const handleStatusTextChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setNewStatusText(e.target.value);
        },
        [setNewStatusText],
    );

    const handleAddStatus = useCallback(
        async (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            if (getCustomList.data?.getCustomList?.id) {
                await createItem.mutateAsync({
                    params: {
                        customListId: getCustomList.data.getCustomList.id,
                        name: newStatusText,
                    },
                });
                setNewStatusText("");
                getCustomList.refetch();
            }
        },
        [newStatusText, createItem, getCustomList, setNewStatusText],
    );

    const handleSaved = useCallback(
        (item: GetCustomListQuery["getCustomList"]["items"][0]) => {
            queryClient.setQueryData(
                ["getCustomList", { key: customListKey }],
                (oldData: GetCustomListQuery) => {
                    return produce(oldData, draft => {
                        const index = draft.getCustomList.items.findIndex(
                            i => i.id === item.id,
                        );
                        draft.getCustomList.items[index] = item;
                    });
                },
            );
        },
        [queryClient, customListKey],
    );

    const archivedItems = useMemo(() => {
        return getCustomList.data?.getCustomList.items.filter(i => i.isArchived) ?? [];
    }, [getCustomList.data?.getCustomList.items]);

    return (
        <QueryRenderer name="CustomListEditor.getCustomList" query={getCustomList}>
            {data => {
                return (
                    <div className="space-y-4">
                        <ul className="divide-y divide-gray-200">
                            <li className="flex space-x-4 py-2">
                                {disabled ? null : <p className="w-7">&nbsp;</p>}
                                {disabled ? null : <p className="w-12">Icon</p>}
                                <p className="w-96">Name</p>
                                <p className="w-48">Category</p>
                                <p className="w-36">&nbsp;</p>
                                <p className="w-8">&nbsp;</p>
                                {getHelpText ? <p>Description</p> : null}
                            </li>
                            {data.getCustomList.items.map((item, index) => (
                                <CustomListItemEditor
                                    key={item.id}
                                    customList={data.getCustomList}
                                    index={index}
                                    item={item}
                                    commitOrder={handleCommitOrder}
                                    revertOrder={handleRevertOrder}
                                    reorder={handleReorder}
                                    onSaved={handleSaved}
                                    getHelpText={getHelpText}
                                    disabled={disabled}
                                />
                            ))}
                        </ul>
                        {disabled ? null : (
                            <div className="flex items-center space-x-4">
                                <p className="w-7">&nbsp;</p>
                                <p className="w-12">&nbsp;</p>
                                <div className="w-96">
                                    <Input
                                        placeholder="Enter item name"
                                        value={newStatusText}
                                        onChange={handleStatusTextChange}
                                        disabled={createItem.isLoading}
                                    />
                                </div>
                                <PrimaryButton
                                    onClick={handleAddStatus}
                                    disabled={createItem.isLoading}>
                                    Add status
                                </PrimaryButton>
                            </div>
                        )}
                        {archivedItems.length > 0 ? (
                            <div className="rounded bg-gray-50 p-4">
                                <p className="text-xl">Archived items</p>
                                <ul>
                                    {archivedItems.map(item => (
                                        <ArchivedCustomListItemEditor
                                            key={item.id}
                                            item={item}
                                            onSaved={handleSaved}
                                            disabled={disabled}
                                        />
                                    ))}
                                </ul>
                            </div>
                        ) : null}
                    </div>
                );
            }}
        </QueryRenderer>
    );
};

interface CustomListItemEditorProps {
    customList: GetCustomListQuery["getCustomList"];
    index: number;
    item: GetCustomListQuery["getCustomList"]["items"][0];
    commitOrder: () => void;
    revertOrder: () => void;
    reorder: (draggingIndex: number, targetIndex: number) => void;
    onSaved: (item: GetCustomListQuery["getCustomList"]["items"][0]) => void;
    getHelpText?: (item: GetCustomListQuery["getCustomList"]["items"][0]) => string;
    disabled?: boolean;
}

const CustomListItemEditor: React.FC<CustomListItemEditorProps> = props => {
    const {
        customList,
        item,
        index,
        commitOrder,
        revertOrder,
        reorder,
        onSaved,
        getHelpText,
        disabled,
    } = props;

    const updateListItem = usePatchCustomListItemMutation();

    const ref = React.useRef<HTMLLIElement>(null);
    const type = `custom-list-${customList.id}`;
    const [{ isDragging }, drag, preview] = useReorderableListDrag({
        type,
        index,
        commitOrder,
        revertOrder,
        id: item.id,
    });
    // eslint-disable-next-line no-empty-pattern
    const [{}, drop] = useReorderableListDrop({
        ref,
        index,
        reorder,
        type,
    });
    drop(ref);

    const handleNameChange = useCallback(
        async (name: string) => {
            const result = await updateListItem.mutateAsync({
                params: {
                    id: item.id,
                    name,
                },
            });
            onSaved(result.patchCustomListItem);
        },
        [item.id, updateListItem, onSaved],
    );

    const handleCategoryChange = useCallback(
        async (e: React.ChangeEvent<HTMLSelectElement>) => {
            const result = await updateListItem.mutateAsync({
                params: {
                    id: item.id,
                    categoryId: e.target.value as CustomListCategoryId,
                },
            });
            onSaved(result.patchCustomListItem);
        },
        [item.id, updateListItem, onSaved],
    );

    const handleVisibilityChange = useCallback(
        async (visibility: boolean) => {
            const result = await updateListItem.mutateAsync({
                params: {
                    id: item.id,
                    pipelineVisibility: visibility
                        ? PipelineVisibility.Show
                        : PipelineVisibility.Hide,
                },
            });
            onSaved(result.patchCustomListItem);
        },
        [item.id, updateListItem, onSaved],
    );

    const handleArchive = useCallback(async () => {
        const shouldDelete = await fancyConfirm(
            "Archive status",
            `Are you sure you want to archive the "${item.name}" status?`,
            "Yes, archive",
            "No, cancel",
        );

        if (shouldDelete) {
            const result = await updateListItem.mutateAsync({
                params: {
                    id: item.id,
                    isArchived: true,
                },
            });
            onSaved(result.patchCustomListItem);
        }
    }, [item.id, updateListItem, onSaved, item.name]);

    const handleIconChange = useCallback(
        async (icon: string) => {
            const result = await updateListItem.mutateAsync({
                params: {
                    id: item.id,
                    icon,
                },
            });
            onSaved(result.patchCustomListItem);
        },
        [item.id, updateListItem, onSaved],
    );

    if (item.isArchived) {
        return null;
    }

    const icon = parseLayoutIcon(item.icon).getOrElse("49_Info");

    return (
        <li className="py-3" ref={ref}>
            <div
                ref={preview}
                className={`flex space-x-4 items-center ${
                    isDragging ? "opacity-0" : ""
                }`}>
                {disabled ? null : (
                    <div className="cursor-move px-1 py-3" ref={drag}>
                        <Bars3Icon className="w-5 h-5 text-gray-500" />
                    </div>
                )}
                {disabled ? null : (
                    <div className="w-12">
                        <IconPicker icon={icon} onChange={handleIconChange} />
                    </div>
                )}
                <div className="w-96">
                    <AutosavingInput
                        initial={item.name}
                        onCommit={handleNameChange}
                        disabled={disabled}
                    />
                </div>
                <div className="w-48">
                    <Select
                        value={item.customListCategoryId}
                        disabled={
                            item.updateBehavior !== CustomListItemUpdateBehavior.Full ||
                            disabled
                        }
                        onChange={handleCategoryChange}>
                        {customList.categories.map(category => (
                            <option key={category.id} value={category.id}>
                                {category.name}
                            </option>
                        ))}
                    </Select>
                </div>
                <div className="w-36 p-1">
                    <AutosavingCheckbox
                        initial={item.pipelineVisibility === PipelineVisibility.Show}
                        onCommit={handleVisibilityChange}
                        label={<span className="text-sm">Show in pipeline</span>}
                        disabled={
                            item.updateBehavior !== CustomListItemUpdateBehavior.Full ||
                            disabled
                        }
                    />
                </div>
                <div className="w-8">
                    {item.updateBehavior === CustomListItemUpdateBehavior.Full &&
                    !disabled ? (
                        <DeleteButton
                            backgroundColor="bg-white"
                            onClick={handleArchive}
                        />
                    ) : null}
                </div>
                {getHelpText ? (
                    <div>
                        <p className="text-gray-500 text-sm">{getHelpText(item)}</p>
                    </div>
                ) : null}
            </div>
        </li>
    );
};

interface ArchivedCustomListItemEditorProps {
    item: GetCustomListQuery["getCustomList"]["items"][0];
    onSaved: (item: GetCustomListQuery["getCustomList"]["items"][0]) => void;
    disabled?: boolean;
}

const ArchivedCustomListItemEditor: React.FC<
    ArchivedCustomListItemEditorProps
> = props => {
    const { item, onSaved, disabled } = props;

    const updateListItem = usePatchCustomListItemMutation();

    const handleRestore = useCallback(async () => {
        const result = await updateListItem.mutateAsync({
            params: {
                id: item.id,
                isArchived: false,
            },
        });
        onSaved(result.patchCustomListItem);
    }, [item.id, updateListItem, onSaved]);

    return (
        <li className="flex items-center py-2">
            <p className="w-96">{item.name}</p>
            {disabled ? null : (
                <RestoreButton backgroundColor="bg-white" onClick={handleRestore} />
            )}
        </li>
    );
};
