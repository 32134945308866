import { fancyConfirm } from "components/ui/fancy-confirm";

export const sendEmptySubjectConfirmation = async (): Promise<boolean> => {
    return await fancyConfirm(
        "Send with empty subject?",
        "This message contains no subject; would you like to send anyways?",
        "Send anyways",
        "Edit message",
    );
};

export const discardEmailConfirmation = async (): Promise<boolean> => {
    return await fancyConfirm(
        "Discard message?",
        "Are you sure you would like to close and discard this message?",
        "Close",
        "Keep open",
    );
};
