import {
    DocumentIcon,
    PencilSquareIcon,
    PhotoIcon,
    TableCellsIcon,
} from "@heroicons/react/24/outline";
import React from "react";

interface FileIconProps {
    mimeType: string;
}

const iconClass = "w-6 h-6 text-gray-500";
export const FileIcon: React.FC<FileIconProps> = props => {
    const match = (props.mimeType ?? "").toLocaleLowerCase();
    if (match.indexOf("pdf") >= 0) {
        return <DocumentIcon className={iconClass} />;
    }
    if (match.indexOf("sheet") >= 0) {
        return <TableCellsIcon className={iconClass} />;
    }
    if (match.indexOf("image") >= 0) {
        return <PhotoIcon className={iconClass} />;
    }
    return <DocumentIcon className={iconClass} />;
};

export const SignableDocumentIcon: React.FC<unknown> = () => {
    return <PencilSquareIcon className={iconClass} />;
};
