import axios from "axios";
import { PrimaryButton } from "components/ui/primary-button";
import { Spinner } from "components/ui/spinner";
import * as React from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from "store/state";
import { Content, SectionHeader, Stage } from "../components/layout";

export const UploadClients: React.FunctionComponent = () => {
    const region = useSelector((state: ApplicationState) => state.region.selectedRegion);
    const [isUploading, setIsUploading] = React.useState(false);
    const [uploadResult, setUploadResult] = React.useState<string | null>(null);

    const handleSubmit = React.useCallback(
        (e: React.FormEvent<HTMLFormElement>) => {
            if (!region) {
                alert("Sorry, a loading error has occurred");
                throw new Error("Cannot upload clients without region");
            }
            e.preventDefault();
            setIsUploading(true);
            setUploadResult(null);
            const data = new FormData();
            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
            data.append("users", (e.target as any).users.files[0]);
            data.append("region", region);
            (async () => {
                const result = await axios.post(
                    "/api/admin/clients/upload-template.csv",
                    data,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    },
                );
                setIsUploading(false);
                setUploadResult(result.data);
            })();
        },
        [setIsUploading, setUploadResult, region],
    );
    return (
        <Stage>
            <SectionHeader title="Upload clients" />
            <Content>
                <div className="space-y-8">
                    <div>
                        <a href="/api/admin/clients/upload-template.csv" target="_blank">
                            Download template
                        </a>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <label>
                            Select client CSV
                            <br />
                            <input type="file" name="users" accept="text/csv" />
                        </label>
                        <PrimaryButton disabled={isUploading} type="submit">
                            Upload
                        </PrimaryButton>
                    </form>
                    <div>{isUploading ? <Spinner /> : <div>{uploadResult}</div>}</div>
                </div>
            </Content>
        </Stage>
    );
};
