import { UserGroupIcon } from "@heroicons/react/24/outline";
import { displayName } from "@sp-crm/core";
import React from "react";
import { useRegionId, useUsersForRegion } from "store/selectors/hooks";
import { EntityActivitySummary } from "./details/entity-activity-summary";
import { EntityActivityEntryProps } from "./types";

export const EntityActivityReassign: React.FC<EntityActivityEntryProps> = props => {
    const { activity } = props;
    const currentRegion = useRegionId();
    const users = useUsersForRegion(currentRegion);
    let fromName = "unknown";
    let toName = "unknown";
    if (activity?.activity?.metadata?.events?.length > 0) {
        const event = activity.activity.metadata.events[0].entityReassign;
        if (event) {
            fromName = displayName(users[event.from]);
            toName = displayName(users[event.to]);
        }
    }
    return (
        <div className="relative flex items-start space-x-3">
            <div className="relative px-1">
                <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white">
                    <UserGroupIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                </div>
            </div>
            <div className="min-w-0 flex-1 group pt-1">
                <EntityActivitySummary {...props}>
                    assigned <strong>{toName}</strong>.
                </EntityActivitySummary>
                <div className="mt-2 text-sm text-gray-600">
                    Was previously assigned to <strong>{fromName}</strong>.
                </div>
            </div>
        </div>
    );
};
