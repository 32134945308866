import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { ApplicationState } from "store/state";
import { buildVersion } from "../../util/version";
import { useWindowFocus } from "../shared/hooks";

const randomMsInRange = (minHours: number, maxHours: number): number => {
    const min = minHours * 60 * 60 * 1000;
    const max = maxHours * 60 * 60 * 1000;
    return Math.random() * (max - min) + min;
};

/** React component to detect when to automatically reload page for new client version releases. */
export const AutoReload: React.FunctionComponent = () => {
    const serverBuild = useSelector(
        (state: ApplicationState) => state.status.serverBuild,
    );
    const location = useLocation();
    const [showWarning, setShowWarning] = React.useState(false);
    const reload = React.useCallback(() => {
        window.location.reload();
    }, []);
    const isFocused = useWindowFocus();
    React.useEffect(() => {
        if (serverBuild !== buildVersion) {
            console.warn(
                `Updating client (client:${buildVersion} -> server:${serverBuild})`,
            );
            setTimeout(() => setShowWarning(true), 5000);
            reload();
        }
    }, [location, setShowWarning]); // eslint-disable-line react-hooks/exhaustive-deps
    React.useEffect(() => {
        if (serverBuild !== buildVersion) {
            setTimeout(() => setShowWarning(true), 1000 * 60 * 60);
            if (isFocused) {
                setTimeout(reload, randomMsInRange(0.5, 3));
            } else {
                setTimeout(reload, randomMsInRange(4, 24));
            }
        }
    }, [serverBuild, buildVersion, isFocused, setShowWarning, reload]); // eslint-disable-line react-hooks/exhaustive-deps
    if (showWarning) {
        return (
            <div className="p-4 text-bold text-lg bg-gray-800 text-white hover:bg-black text-center">
                A new version is available! Please click{" "}
                <a onClick={reload} className="underline hover:bg-gray-800" href="#">
                    reload
                </a>
                .
            </div>
        );
    }
    if (serverBuild !== buildVersion) {
        return (
            <div className="relative">
                <div className="border-2 border-brand-500 rounded-full absolute top-0 right-1 bg-brand-500 w-0" />
            </div>
        );
    }

    return null;
};
