import { forwardRef, useImperativeHandle } from "react";
import { useQueryClient } from "react-query";

// Define the methods that will be exposed via the ref
export interface QueryInvalidatorHandle {
    invalidateQueries: (queryKey?: string | readonly unknown[]) => Promise<void>;
    invalidateQueriesWithPrefix: (prefix: string) => Promise<void>;
    invalidateAllQueries: () => Promise<void>;
}

// Props interface instead of Record<string, never>
export interface QueryInvalidatorProps {
    // You can add props here if needed
}

export const QueryInvalidator = forwardRef<QueryInvalidatorHandle, QueryInvalidatorProps>(
    (props, ref) => {
        const queryClient = useQueryClient();

        useImperativeHandle(ref, () => ({
            invalidateQueries: async queryKey => {
                if (queryKey) {
                    await queryClient.invalidateQueries(queryKey);
                }
            },

            invalidateQueriesWithPrefix: async (prefix: string) => {
                await queryClient.invalidateQueries({
                    predicate: query => {
                        const queryKey = query.queryKey;
                        if (Array.isArray(queryKey) && queryKey.length > 0) {
                            const firstKey = queryKey[0];
                            return (
                                typeof firstKey === "string" &&
                                firstKey.startsWith(prefix)
                            );
                        }
                        return false;
                    },
                });
            },

            invalidateAllQueries: async () => {
                await queryClient.invalidateQueries();
            },
        }));

        return null;
    },
);

QueryInvalidator.displayName = "QueryInvalidator";
