import { UserGroupId } from "@sp-crm/core";
import { useGetUserGroupsQuery } from "generated/graphql";
import React from "react";
import Multiselect from "react-widgets/Multiselect";

interface MultiGroupSelectProps {
    value: UserGroupId[];
    onChange: (groupIds: UserGroupId[]) => void;
}

export const MultiGroupSelect: React.FC<MultiGroupSelectProps> = props => {
    const { value, onChange } = props;

    const getUserGroups = useGetUserGroupsQuery();

    if (!getUserGroups.data || getUserGroups.data.getUserGroups.length === 0) {
        return null;
    }

    return (
        <Multiselect
            data={getUserGroups.data.getUserGroups}
            value={value}
            textField="name"
            dataKey="id"
            onChange={values => {
                const newGroupIds = values.map(a => a.id);
                onChange(newGroupIds);
            }}
        />
    );
};
