import { Bars3Icon, Cog6ToothIcon, PlusIcon } from "@heroicons/react/24/outline";
import { IBridgeSelect, QuestionId } from "@sp-crm/core";
import {
    FIELD_PICKER_TYPE_SEPARATOR,
    FieldPickerField,
    getNormalizedFields,
} from "components/reports/show/custom-reports/custom-reports-helpers";
import { linkStyle } from "components/reports/show/custom-reports/custom-reports-sidebar";
import { DeleteButton, EditButton } from "components/ui/action-button";
import { Panel } from "components/ui/panel/panel";
import { PanelType } from "components/ui/panel/panel-type";
import { PrimaryButton } from "components/ui/primary-button";
import { SecondaryButton } from "components/ui/secondary-button";
import {
    AdvancedSearchEntityType,
    AdvancedSearchRequest,
    AdvancedSearchSelectInput,
    BridgeEntityResult,
} from "generated/graphql";
import { produce } from "immer";
import React, { useCallback, useState } from "react";
import { useReorderableListDrag, useReorderableListDrop } from "util/dnd";
import { EntityNestedFieldDisplay } from "./entity-nested-field-display";
import { EntityNestedFieldPicker } from "./entity-nested-field-picker";
import { RelationDescription } from "./types";

function toSelectValue(value: AdvancedSearchSelectInput) {
    if (value.fieldName) {
        return `field${FIELD_PICKER_TYPE_SEPARATOR}${value.fieldName}`;
    }

    if (value.questionId) {
        return `question${FIELD_PICKER_TYPE_SEPARATOR}${value.questionId}`;
    }

    return "";
}

function toInput(pickerValue: FieldPickerField): AdvancedSearchSelectInput {
    const [type, value] = pickerValue.value.split(FIELD_PICKER_TYPE_SEPARATOR);

    if (type === "field") {
        return {
            fieldName: value,
            title: pickerValue.title,
        };
    } else if (type === "question") {
        return {
            questionId: value as QuestionId,
            title: pickerValue.title,
        };
    }

    return null;
}

interface SelectItemsEditorListItemProps {
    entityMetadata: BridgeEntityResult;
    value: AdvancedSearchSelectInput;
    index: number;
    reorder: (draggingIndex: number, targetIndex: number) => void;
    onChange: (value: AdvancedSearchSelectInput, index: number) => void;
    onDelete: (index: number) => void;
    exclude: (AdvancedSearchSelectInput | RelationDescription)[];
    entities: BridgeEntityResult[];
}

const SelectItemsEditorListItem: React.FC<SelectItemsEditorListItemProps> = props => {
    const {
        value,
        index,
        onChange,
        onDelete,
        reorder,
        entityMetadata,
        exclude,
        entities,
    } = props;

    const [isEditing, setIsEditing] = useState(false);

    const handleFieldChange = useCallback(
        (value: IBridgeSelect) => {
            setIsEditing(false);
            onChange(value, index);
        },
        [index, onChange, setIsEditing],
    );

    const handleDeleteClick = React.useCallback(() => onDelete(index), [index, onDelete]);

    const handleEditClick = React.useCallback(() => setIsEditing(true), [setIsEditing]);

    const ref = React.useRef<HTMLLIElement>(null);
    const type = `advanced-search-columns-${entityMetadata.name}`;
    const [{ isDragging }, drag, preview] = useReorderableListDrag({
        type,
        index,
        id: `item-${toSelectValue(value)}`,
    });
    const [_, drop] = useReorderableListDrop({
        ref,
        index,
        reorder,
        type,
    });
    drop(ref);

    return (
        <li className="py-1" ref={ref}>
            {isEditing ? (
                <div className="border-gray-300 border rounded-sm">
                    <EntityNestedFieldPicker
                        value={value}
                        onChange={handleFieldChange}
                        entityMetadata={entityMetadata}
                        exclude={exclude}
                        entities={entities}
                    />
                </div>
            ) : (
                <div
                    className={`flex items-center space-x-2 ${
                        isDragging ? "opacity-0" : ""
                    }`}
                    ref={preview}>
                    <div className="cursor-move px-1 py-3" ref={drag}>
                        <Bars3Icon className="w-4 h-4 text-gray-500" />
                    </div>
                    <div className="flex-1">
                        <EntityNestedFieldDisplay
                            value={value}
                            entityMetadata={entityMetadata}
                            entities={entities}
                        />
                    </div>
                    <EditButton onClick={handleEditClick} backgroundColor="bg-white" />
                    <DeleteButton
                        onClick={handleDeleteClick}
                        backgroundColor="bg-white"
                    />
                </div>
            )}
        </li>
    );
};

interface SelectItemsEditorProps {
    entityType: AdvancedSearchEntityType;
    entities: BridgeEntityResult[];
    select: AdvancedSearchSelectInput[];
    onSave: (select: AdvancedSearchSelectInput[]) => void;
    onCancel: () => void;
}

const SelectItemsEditor: React.FC<SelectItemsEditorProps> = props => {
    const { select, entities, entityType, onCancel, onSave } = props;

    const entityMetadata = entities.find(e => e.name === entityType);

    const [selectItems, setSelectItems] = useState<AdvancedSearchSelectInput[]>(select);

    const normalizedFields = React.useMemo(() => {
        const [_groups, fields] = getNormalizedFields(
            entityMetadata,
            entities,
            null,
            [],
            "",
            true,
        );

        return fields;
    }, [entityMetadata, entities]);

    const maxSelectItemsLength = normalizedFields.length;

    const handleSelectItemChange = useCallback(
        (value: AdvancedSearchSelectInput, index: number) => {
            setSelectItems(old =>
                produce(old, draft => {
                    draft[index] = value;
                }),
            );
        },
        [setSelectItems],
    );

    const handleDelete = useCallback(
        (index: number) => {
            setSelectItems(old =>
                produce(old, draft => {
                    draft.splice(index, 1);
                }),
            );
        },
        [setSelectItems],
    );

    const handleReorder = useCallback(
        (draggingIndex: number, targetIndex: number) => {
            setSelectItems(old =>
                produce(old, draft => {
                    const [draggingItem] = draft.splice(draggingIndex, 1);
                    draft.splice(targetIndex, 0, draggingItem);
                }),
            );
        },
        [setSelectItems],
    );

    const handleAddItem = React.useCallback(
        async (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
            e.preventDefault();
            setSelectItems(old =>
                produce(old, draft => {
                    const firstUnselectedField = normalizedFields.find(
                        f => !old.some(s => toSelectValue(s) === f.value),
                    );
                    if (firstUnselectedField) {
                        const input = toInput(firstUnselectedField);
                        if (input) {
                            draft.push(input);
                        }
                        return;
                    }
                }),
            );
        },
        [setSelectItems, normalizedFields],
    );

    const handleSave = React.useCallback(
        async (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
            e.preventDefault();
            onSave(selectItems);
        },
        [onSave, selectItems],
    );

    const handleCancel = React.useCallback(
        async (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
            e.preventDefault();
            onCancel();
        },
        [onCancel],
    );

    return (
        <div>
            <ul>
                {selectItems.map((selectedItem, index, allItems) => (
                    <SelectItemsEditorListItem
                        key={toSelectValue(selectedItem)}
                        entityMetadata={entityMetadata}
                        value={selectedItem}
                        index={index}
                        onChange={handleSelectItemChange}
                        onDelete={handleDelete}
                        reorder={handleReorder}
                        entities={entities}
                        exclude={allItems}
                    />
                ))}
            </ul>
            <button
                className={`${linkStyle} flex items-center disabled:text-gray-200`}
                onClick={handleAddItem}
                disabled={selectItems.length === maxSelectItemsLength}>
                <PlusIcon className="w-4 h-4" /> Add column
            </button>
            <div className="flex items-center space-x-2 mt-4">
                <PrimaryButton onClick={handleSave}>Save</PrimaryButton>
                <SecondaryButton onClick={handleCancel}>Cancel</SecondaryButton>
            </div>
        </div>
    );
};

interface AdvancedSearchSelectProps {
    entityType: AdvancedSearchEntityType;
    entities: BridgeEntityResult[];
    request: AdvancedSearchRequest;
    onChange: (request: AdvancedSearchRequest) => void;
}

export const AdvancedSearchSelect: React.FC<AdvancedSearchSelectProps> = props => {
    const { request, entityType, entities, onChange } = props;

    const [editing, setEditing] = useState(false);

    const handleEditClick = useCallback(
        () => setEditing(true),

        [setEditing],
    );

    const handleDismiss = useCallback(() => {
        setEditing(false);
    }, [setEditing]);

    const handleSave = useCallback(
        (select: AdvancedSearchSelectInput[]) => {
            onChange(
                produce(request, draft => {
                    draft.select = select;
                }),
            );
            setEditing(false);
        },
        [request, onChange, setEditing],
    );

    if (!request) {
        return null;
    }

    return (
        <div className="space-y-1 max-w-advanced-search">
            <SecondaryButton
                className="flex items-center space-x-2"
                onClick={handleEditClick}>
                <Cog6ToothIcon className="w-4 h-4" />
                <span>Change columns</span>
            </SecondaryButton>
            <Panel
                type={PanelType.medium}
                hasCloseButton={true}
                isOpen={editing}
                headerText="Report columns"
                onDismiss={handleDismiss}>
                <SelectItemsEditor
                    select={request.select}
                    entityType={entityType}
                    entities={entities}
                    onCancel={handleDismiss}
                    onSave={handleSave}
                />
            </Panel>
        </div>
    );
};
