import {
    CurrencyInCents,
    InvoiceLineItemId,
    currencyInCentsToString,
    formatCurrency,
    genEntityId,
    stringToCurrencyInCents,
} from "@sp-crm/core";
import { DeleteButton } from "components/ui/action-button";
import { Input } from "components/ui/input";
import { SecondaryButton } from "components/ui/secondary-button";
import { TextArea } from "components/ui/textarea";
import {
    InvoiceLineItem,
    InvoiceLineItemInput,
    InvoicePaymentSchedule,
    InvoicePaymentScheduleItemStatus,
} from "generated/graphql";
import React, { useCallback, useMemo, useState } from "react";

const headerClasses = "p-1 text-sm font-medium text-gray-500";
const cellClasses = "p-1 text-sm align-top";

interface InvoiceLineItemsEditorProps {
    lineItems: InvoiceLineItem[];
    onChange?: (lineItems: InvoiceLineItem[]) => void;
    paidDate: string;
    paymentSchedule: InvoicePaymentSchedule | null;
}

export const InvoiceLineItemsEditor: React.FC<InvoiceLineItemsEditorProps> = props => {
    const { lineItems, onChange, paymentSchedule, paidDate } = props;

    const handleAddItem = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            onChange([
                ...lineItems,
                {
                    id: genEntityId(),
                    description: "",
                    quantity: 1,
                    unitPriceInCents: 0 as CurrencyInCents,
                },
            ]);
        },
        [lineItems, onChange],
    );

    const updateLineItem = useCallback(
        (lineItem: InvoiceLineItemInput) => {
            const index = lineItems.findIndex(x => x.id === lineItem.id);
            if (index === -1) {
                return;
            }
            const newLineItems = [...lineItems];
            newLineItems[index] = lineItem;
            onChange(newLineItems);
        },
        [lineItems, onChange],
    );

    const removeLineItem = useCallback(
        (id: InvoiceLineItemId) => {
            const newLineItems = lineItems.filter(x => x.id !== id);
            onChange(newLineItems);
        },
        [lineItems, onChange],
    );

    const invoiceSubtotal = useMemo(() => {
        return lineItems.reduce(
            (agg, current) => agg + current.unitPriceInCents * current.quantity,
            0,
        );
    }, [lineItems]);

    const totalPaid = useMemo(() => {
        if (!paymentSchedule) {
            return paidDate ? invoiceSubtotal : 0;
        }
        return paymentSchedule.items
            .filter(item => item.status === InvoicePaymentScheduleItemStatus.Paid)
            .reduce((agg, current) => agg + current.amountInCents, 0);
    }, [paymentSchedule, paidDate, invoiceSubtotal]);

    return (
        <div>
            <p>Line Items</p>
            <table className="min-w-full">
                <thead className="text-sm">
                    <tr>
                        <th className={`w-3/4 text-left ${headerClasses}`}>
                            Description
                        </th>
                        <th className={`${headerClasses} text-right`}>Amount</th>
                        <th className="w-8"></th>
                    </tr>
                </thead>
                <tbody>
                    {lineItems.map(lineItem => (
                        <InvoiceLineItemEditor
                            key={lineItem.id}
                            lineItem={lineItem}
                            onChange={onChange ? updateLineItem : null}
                            onDelete={
                                onChange
                                    ? () => {
                                          removeLineItem(lineItem.id);
                                      }
                                    : null
                            }
                        />
                    ))}
                </tbody>
            </table>
            <div className="px-1 flex justify-between items-start">
                <div>
                    {onChange ? (
                        <SecondaryButton onClick={handleAddItem}>
                            <span className="text-sm">Add item</span>
                        </SecondaryButton>
                    ) : null}
                </div>
                <div>
                    <div className="flex items-center mr-8">
                        <p className="font-bold flex-1 text-right">Total amount</p>
                        <p className="w-24 text-right">
                            {formatCurrency(
                                currencyInCentsToString(
                                    invoiceSubtotal as CurrencyInCents,
                                ),
                            )}
                        </p>
                    </div>
                    <div className="flex items-center mr-8">
                        <p className="font-bold flex-1 text-right">Total paid</p>
                        <p className="w-24 text-right">
                            {formatCurrency(
                                currencyInCentsToString(-totalPaid as CurrencyInCents),
                            )}
                        </p>
                    </div>
                    <div className="flex items-center mr-8">
                        <p className="font-bold flex-1 text-right">Remaining balance</p>
                        <p className="w-24 text-right">
                            {formatCurrency(
                                currencyInCentsToString(
                                    (invoiceSubtotal - totalPaid) as CurrencyInCents,
                                ),
                            )}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

interface InvoiceLineItemEditorProps {
    lineItem: InvoiceLineItemInput;
    onChange?: ((lineItem: InvoiceLineItemInput) => void) | null;
    onDelete?: (() => void) | null;
}

const InvoiceLineItemEditor: React.FC<InvoiceLineItemEditorProps> = props => {
    const { lineItem, onChange, onDelete } = props;

    const [unitPriceFieldValue, setUnitPriceFieldValue] = useState(
        currencyInCentsToString(lineItem.unitPriceInCents),
    );

    return (
        <tr key={lineItem.id}>
            <td className={`w-3/4 text-left ${cellClasses}`}>
                <TextArea
                    textSize="sm"
                    autoGrow={true}
                    value={lineItem.description}
                    disabled={!onChange}
                    onChange={e => {
                        if (onChange) {
                            onChange({
                                ...lineItem,
                                description: e.target.value,
                            });
                        }
                    }}
                />
            </td>
            <td className={`${cellClasses} text-right`}>
                <div className="flex items-center justify-end">
                    <span className="mr-1 text-gray-500">$</span>
                    <Input
                        className="w-24"
                        textSize="sm"
                        type="number"
                        value={unitPriceFieldValue}
                        disabled={!onChange}
                        onChange={e => {
                            if (onChange) {
                                setUnitPriceFieldValue(e.target.value);
                                onChange({
                                    ...lineItem,
                                    unitPriceInCents: stringToCurrencyInCents(
                                        e.target.value,
                                    ),
                                });
                            }
                        }}
                    />
                </div>
            </td>
            <td className={`${cellClasses} w-8 pt-2.5`}>
                {onDelete ? (
                    <DeleteButton
                        backgroundColor="bg-white"
                        size="sm"
                        onClick={() => {
                            onDelete();
                        }}
                    />
                ) : null}
            </td>
        </tr>
    );
};
