import { CommunityId } from "@sp-crm/core";
import React from "react";
import { useGetCommunityQuery } from "../../generated/graphql";
import { CommunityInvoiceList } from "./invoice-list";

interface Props {
    communityId: CommunityId;
    canModifyInvoices: boolean;
}

export const CommunityInvoices: React.FC<Props> = props => {
    const queryKey = { id: props.communityId };
    const query = useGetCommunityQuery(queryKey);
    const refetch = React.useCallback(() => {
        query.refetch();
    }, [query]);
    return (
        <div>
            <CommunityInvoiceList
                refetch={refetch}
                query={query}
                canModifyInvoices={props.canModifyInvoices}
            />
        </div>
    );
};
