import { CommunityRelationshipType, CustomListKey, IClient } from "@sp-crm/core";
import { Col, Row } from "components/shared/grid";
import React, { useMemo } from "react";
import { useCustomList } from "store/selectors/bridge";
import { Subpage } from ".";
import { ActionBarAssignedTo } from "./action-bar-assigned-to";
import { ActionBarDateComponent } from "./action-bar-date-component";
import { ActionBarMoveCommunity } from "./action-bar-move-community";
import { ActionBarStatus } from "./action-bar-status";
import { InvoiceSummary } from "./invoices/invoice-summary";

interface ShowClientActionBarProps {
    reactivate(): void;
    client: IClient;
    goToTab?: (section: Subpage) => void;
}

export const ShowClientActionBar: React.FC<ShowClientActionBarProps> = props => {
    const { client, reactivate, goToTab } = props;

    const communities = useMemo(() => {
        const clientCommunities = client?.clientCommunities || [];
        return clientCommunities
            .filter(
                cc =>
                    cc.relationship === CommunityRelationshipType.featured ||
                    client.chosenCommunityId === cc.community?.id,
            )
            .map(cc => cc.community);
    }, [client?.clientCommunities, client?.chosenCommunityId]);

    const { getListItemKey } = useCustomList(CustomListKey.ClientStatus);

    const clientStatus = getListItemKey(client.statusListItemId);

    let scheduledClassName = "";

    switch (clientStatus) {
        case "moved":
        case "scheduled":
            scheduledClassName = " action-bar-accented-component";
            break;
    }
    return (
        <div className="action-bar">
            <Row className={"flex-stacking-columns"}>
                <Col className="widget">
                    <ActionBarStatus client={client} reactivate={reactivate} />
                </Col>
                {clientStatus === "checkLater" ? (
                    <Col className="widget">
                        <ActionBarDateComponent
                            className="check-back-later-date"
                            client={client}
                            datePropertyName="statusCheckLaterDate"
                            dateVal={client.statusCheckLaterDate}
                            displayLabel="Check Back Later Date"
                        />
                    </Col>
                ) : null}
                <Col className="widget">
                    <ActionBarDateComponent
                        client={client}
                        datePropertyName="moveTimeline"
                        dateVal={client.moveTimeline}
                        displayLabel="Estimated Move Timeline"
                    />
                </Col>
                <Col className="widget">
                    <ActionBarMoveCommunity
                        className={scheduledClassName}
                        client={client}
                        communities={communities}
                        goToTab={(section: Subpage) => goToTab(section)}
                    />
                </Col>
                <Col className="widget">
                    <ActionBarDateComponent
                        className={scheduledClassName}
                        client={client}
                        datePropertyName="moveTimelineActual"
                        dateVal={client.moveTimelineActual}
                        displayLabel="Move Date"
                    />
                </Col>
                <InvoiceSummary clientId={client.id} />
                <Col className="widget">
                    <ActionBarAssignedTo client={client} />
                </Col>
            </Row>
        </div>
    );
};
