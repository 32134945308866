import { AdditionalClientContact, ClientId, ContactId } from "@sp-crm/core";
import React, { FunctionComponent } from "react";
import Contact from "./contact";

type ContactsListProps = {
    clientId: ClientId;
    contacts: AdditionalClientContact[];
    editContact: (contactId: ContactId) => void;
    deleteContact: (contactId: ContactId) => void;
};

const ContactsList: FunctionComponent<ContactsListProps> = ({
    contacts,
    clientId,
    editContact,
    deleteContact,
}) => {
    return (
        <div className="flex-row-no-bottom-margin">
            <div className="all-group-contacts">
                {contacts.map((contact: AdditionalClientContact) => (
                    <Contact
                        key={contact.id}
                        clientId={clientId}
                        contact={contact}
                        editContact={editContact}
                        deleteContact={deleteContact}
                    />
                ))}
            </div>
        </div>
    );
};

export default ContactsList;
