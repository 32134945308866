import { AnnotationType, InteractionType, QuestionType } from "@sp-crm/core";
import { Radio } from "components/shared/radio";
import { BridgeQuestionInput } from "generated/graphql";
import React, { useCallback } from "react";

interface QuestionAnnotationInputProps {
    question: BridgeQuestionInput;
    onChange: (question: BridgeQuestionInput) => void;
    editable: boolean;
}

export const QuestionAnnotationInput: React.FC<QuestionAnnotationInputProps> = props => {
    const { question, onChange, editable } = props;

    const annotationQuestionTypes = [
        QuestionType[QuestionType.binary],
        QuestionType[QuestionType.nary],
    ];

    const handleAnnotationChange = useCallback(
        (value: string) => {
            onChange({
                ...question,
                annotation: value,
            });
        },
        [question, onChange],
    );

    if (!annotationQuestionTypes.includes(question.questionType)) {
        return null;
    }

    let options: { key: string; text: string; helpText?: string }[] = [
        { key: AnnotationType[AnnotationType.none], text: "Disabled" },
        { key: AnnotationType[AnnotationType.single], text: "Enabled" },
    ];

    if (
        question.questionType === QuestionType[QuestionType.nary] &&
        question.interaction === InteractionType[InteractionType.multiple]
    ) {
        options = [
            { key: AnnotationType[AnnotationType.none], text: "Disabled" },
            {
                key: AnnotationType[AnnotationType.single],
                text: "Single",
                helpText: "Show single notes field if any options are checked.",
            },
            {
                key: AnnotationType[AnnotationType.perAnswer],
                text: "Multiple",
                helpText: "Show a notes field for each option that is checked.",
            },
        ];
    }

    return (
        <Radio
            value={question.annotation || AnnotationType[AnnotationType.none]}
            options={options}
            label="Show additional notes field when options are checked"
            onChange={handleAnnotationChange}
            disabled={!editable}
        />
    );
};
