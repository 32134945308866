import { CheckIcon, EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { LayoutIcon } from "@sp-crm/core";
import { DeleteButton, EditButton, IconButton } from "components/ui/action-button";
import { AutosavingInput } from "components/ui/autosaving-input";
import React, { useCallback } from "react";
import { Icon } from "../icon";
import { IconPicker } from "./icon-picker";
import { LayoutSectionResult } from "./layout-items";

interface LayoutSectionHeaderProps {
    layoutSection: LayoutSectionResult;
    visible: boolean;
    onVisibleClicked: () => void;
    onDelete?: () => void;
    allowEditVisibility: boolean;
    onUpdateTitle?: (title: string) => void;
    onUpdateIcon?: (icon: LayoutIcon) => void;
}

export const LayoutSectionHeader: React.FC<LayoutSectionHeaderProps> = props => {
    const {
        layoutSection,
        allowEditVisibility,
        visible,
        onDelete,
        onUpdateIcon,
        onUpdateTitle,
    } = props;

    const allowEdit = !!onUpdateIcon || !!onUpdateTitle;

    const [editing, setEditing] = React.useState<boolean>(false);

    const handleEdit = useCallback(() => {
        setEditing(true);
    }, [setEditing]);

    return (
        <div className="flex items-center space-x-1">
            {layoutSection.icon ? (
                editing ? (
                    <IconPicker
                        icon={layoutSection.icon as LayoutIcon}
                        onChange={onUpdateIcon}
                    />
                ) : (
                    <div className="w-6 mr-2">
                        <Icon name={layoutSection.icon} />
                    </div>
                )
            ) : null}

            <div className="text-base font-medium -mt-0.5 flex-grow">
                <div className="flex items-center space-x-2">
                    {editing ? (
                        <AutosavingInput
                            initial={layoutSection.title}
                            onCommit={onUpdateTitle}
                        />
                    ) : (
                        <p>{layoutSection.title}</p>
                    )}
                    {allowEdit ? (
                        editing ? (
                            <IconButton
                                size="sm"
                                Icon={CheckIcon}
                                onClick={() => setEditing(false)}
                                backgroundColor="bg-white"
                            />
                        ) : (
                            <EditButton
                                size="sm"
                                onClick={handleEdit}
                                backgroundColor="bg-white"
                            />
                        )
                    ) : null}
                </div>
            </div>
            {allowEditVisibility ? (
                <button onClick={props.onVisibleClicked}>
                    {visible ? (
                        <EyeIcon className="w-4 h-4 text-gray-500" />
                    ) : (
                        <EyeSlashIcon className="w-4 h-4 text-gray-500" />
                    )}
                </button>
            ) : null}
            {onDelete ? (
                <DeleteButton
                    size="sm"
                    onClick={onDelete}
                    backgroundColor="bg-white"
                    confirm={{
                        title: "Delete section?",
                        message:
                            "Are you sure you want to delete this section? This cannot be undone.",
                        confirmLabel: "Delete",
                        cancelLabel: "Cancel",
                    }}
                />
            ) : null}
        </div>
    );
};
