import { linkStyle } from "components/reports/show/custom-reports/custom-reports-sidebar";
import { fancyConfirm } from "components/ui/fancy-confirm";
import { Input } from "components/ui/input";
import { BridgeQuestionInput } from "generated/graphql";
import React, { useCallback, useState } from "react";

interface QuestionKeyInputProps {
    editable: boolean;
    question: BridgeQuestionInput;
    onChange: (question: BridgeQuestionInput) => void;
}

export const QuestionKeyInput: React.FC<QuestionKeyInputProps> = props => {
    const { question, editable, onChange } = props;

    const [editing, setEditing] = useState<boolean>(false);
    const showChangeButton = editable && !editing;

    const handleChangeKeyButton = useCallback(
        async (e: React.MouseEvent<HTMLButtonElement>) => {
            e && e.preventDefault && e.preventDefault();

            const confirmed = await fancyConfirm(
                "Are you sure?",
                "It is not recommended to change the question key. Are you sure you want to change it? This could impact existing data.",
                "Change Key",
                "Cancel",
            );

            if (confirmed) {
                setEditing(true);
            }
        },
        [setEditing],
    );

    const handleKeyInputChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            onChange({
                ...question,
                key: e.target.value,
            });
        },
        [question, onChange],
    );

    return (
        <div className="flex justify-between items-center">
            {editing ? (
                <Input
                    value={question.key}
                    onChange={handleKeyInputChange}
                    label="Key"
                    required
                />
            ) : (
                <pre>Key {question.key}</pre>
            )}
            {showChangeButton ? (
                <button className={linkStyle} onClick={handleChangeKeyButton}>
                    Change key
                </button>
            ) : null}
        </div>
    );
};
