import { CommunitySortOptions } from "@sp-crm/core";
import * as React from "react";
import { useContext } from "react";
import { StatelessSearchbarItemContext } from "./stateless-searchbar-item";

export const CommunitySortOptionDescriptions = {
    distance: "Distance",
    name: "Name",
    number: "Number of placements",
    recent: "Most recent placement",
    rating: "Rating",
};

interface SortCommunityProps {
    value: CommunitySortOptions;
    onChange: (newVal: CommunitySortOptions) => void;
    showDistance?: boolean;
}

export const SortCommunity: React.FC<SortCommunityProps> = props => {
    const { showDistance, value, onChange } = props;
    const closeCalloutCallback = useContext(StatelessSearchbarItemContext);
    const chooseOption = (sortOption: CommunitySortOptions) => {
        if (closeCalloutCallback) {
            closeCalloutCallback();
        }
        onChange(sortOption);
    };

    const itemData = Object.keys(CommunitySortOptionDescriptions).map(untypedKey => {
        const key = untypedKey as CommunitySortOptions;

        return {
            value: key,
            description: CommunitySortOptionDescriptions[key],
            shouldDisplay: key !== "distance" || showDistance,
        };
    });

    return (
        <div className="w-48">
            <h2 className="text-lg">Sort by</h2>
            <ul className="mt-2">
                {itemData.map(d =>
                    d.shouldDisplay ? (
                        <li
                            key={d.value}
                            className={`cursor-pointer hover:text-brand-500 ${
                                value === d.value ? "font-bold" : ""
                            }`}
                            onClick={() => chooseOption(d.value)}>
                            <input
                                className="mr-1"
                                type="radio"
                                checked={value === d.value}
                            />
                            {d.description}
                        </li>
                    ) : null,
                )}
            </ul>
        </div>
    );
};
