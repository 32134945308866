import { CalendarIcon, PlusIcon, UserIcon } from "@heroicons/react/24/outline";
import { CalendarDate, TaskParentId, formattedDateTimeDefault } from "@sp-crm/core";
import { DeleteButton, EditButton } from "components/ui/action-button";
import { fancyConfirm } from "components/ui/fancy-confirm";
import { defaultLinkStyle } from "components/ui/link";
import {
    GetUsersForRegionQuery,
    UpdateTaskPayload,
    useDeleteTaskMutation,
    useUpdateTaskMutation,
} from "generated/graphql";
import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { TaskFormV2 } from "./task-form-v2";
import { TaskInlineCall, TaskInlineText } from "./task-inline-call";
import { Taskv2 } from "./types";

interface TaskLineItemProps {
    task: Taskv2;
    refetch: () => Promise<void>;
    isSelected: boolean;
    onSelected: (task: Taskv2) => void;
    onUnselected: (task: Taskv2) => void;
    modifyLocalTask: (task: Taskv2) => void;
    onAddTask: (source: Taskv2) => void;
    users: GetUsersForRegionQuery["getUsersForRegion"];
}

export const TaskLineItem: React.FC<TaskLineItemProps> = props => {
    const { task, entity } = props.task;
    const {
        refetch,
        isSelected,
        onSelected,
        onUnselected,
        onAddTask,
        modifyLocalTask,
        users,
    } = props;
    const updateTask = useUpdateTaskMutation();
    const deleteTask = useDeleteTaskMutation();

    const doUpdate = useCallback(
        async (_parentId: TaskParentId, params: UpdateTaskPayload) => {
            const updatedTask: Taskv2 = {
                task: { ...task, ...params, done: params.status === "done" },
                entity: entity,
            };
            modifyLocalTask(updatedTask);
            await updateTask.mutateAsync({
                taskId: task.id,
                params,
            });
            await refetch();
        },
        [updateTask, refetch, task, entity, modifyLocalTask],
    );

    const handleToggle = useCallback(
        async (e: React.ChangeEvent<HTMLInputElement>) => {
            await doUpdate(task.parentId, {
                status: e.target.checked ? "done" : "new",
                completedAt: e.target.checked ? new Date().toISOString() : null,
            });
        },
        [doUpdate, task.parentId],
    );

    const handleDelete = useCallback(async () => {
        const confirm = await fancyConfirm(
            "Delete task?",
            `Are you sure you want to delete "${task.text ?? ""}"?`,
            "Yes, delete",
            "No, cancel",
        );

        if (confirm) {
            await deleteTask.mutateAsync({ taskId: task.id });
            await refetch();
        }
    }, [deleteTask, task.id, task.text, refetch]);

    const handleAdd = useCallback(
        (source: Taskv2) => {
            onAddTask(source);
        },
        [onAddTask],
    );

    if (isSelected) {
        return (
            <TaskFormV2
                initial={props.task}
                mode="update"
                onClose={() => onUnselected(props.task)}
                saveTask={doUpdate}
            />
        );
    }
    return (
        <TaskLineItemShow
            handleDelete={handleDelete}
            handleToggle={handleToggle}
            handleAdd={handleAdd}
            task={props.task}
            onSelected={onSelected}
            users={users}
        />
    );
};

interface TaskLineItemShowProps {
    task: Taskv2;
    onSelected: (task: Taskv2) => void;
    users: GetUsersForRegionQuery["getUsersForRegion"];
    handleToggle: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleDelete: () => void;
    handleAdd: (source: Taskv2) => void;
}

const TaskLineItemShow: React.FC<TaskLineItemShowProps> = props => {
    const { entity, task } = props.task;
    const { users, handleToggle, onSelected, handleDelete, handleAdd } = props;
    const matchingUser = users?.find(u => u.id === task.ownerId);

    const dueDate: CalendarDate | null = task.dueDate
        ? CalendarDate.parse(task.dueDate).getOrElse(null)
        : null;

    const handleAddAnotherClicked = React.useCallback(
        async (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            handleAdd(props.task);
        },
        [props.task, handleAdd],
    );

    return (
        <div>
            <div className="md:space-x-1 lg:space-x-2 flex items-start lg:-mx-2 lg:px-2 py-4 hover:bg-brand-100">
                <div className="space-y-1 md:space-y-0 md:grid md:grid-cols-3 lg:grid-cols-6 md:gap-2 lg:gap-4 flex-1">
                    <div className="flex space-x-2 items-center row-span-2 lg:row-span-1 lg:col-span-2">
                        <div className="w-6 h-6">
                            <input
                                onChange={handleToggle}
                                type="checkbox"
                                checked={task.done}
                                className="w-6 h-6"
                            />
                        </div>
                        <div
                            className={`font-medium
                            ${task.done ? "line-through text-gray-600" : ""}
                        `}>
                            {task.text || <>&nbsp;</>}
                        </div>

                        {task.taskType ? (
                            <div className="bg-indigo-100 text-indigo-800 inline-block flex-shrink-0 rounded-full px-3 py-0.5 text-sm font-medium">
                                {task.taskType.name}
                            </div>
                        ) : null}
                    </div>
                    <div className="ml-8 md:ml-0">
                        <Link to={`${entity.path}/tasks`}>{entity.displayName}</Link>
                    </div>
                    <div className="ml-8 md:ml-0 space-y-1">
                        <TaskInlineCall contact={entity.contact} />
                        <TaskInlineText contact={entity.contact} />
                    </div>
                    <div className="ml-8 md:ml-0 text-sm flex items-center space-x-1 text-gray-600">
                        <CalendarIcon className="w-4 h-4" />
                        <span>
                            {dueDate
                                ? dueDate.humanFriendly()
                                : task.dueDateTime
                                ? formattedDateTimeDefault(task.dueDateTime)
                                : "No due date"}
                        </span>
                    </div>
                    {matchingUser?.preferredName ? (
                        <div className="ml-8 md:ml-0 text-sm flex items-center space-x-1 text-gray-600">
                            <UserIcon className="w-4 h-4" />
                            <span>{matchingUser.preferredName}</span>
                        </div>
                    ) : null}
                </div>
                <div className="flex items-start space-x-1">
                    <EditButton
                        onClick={() => onSelected(props.task)}
                        backgroundColor="bg-white"
                    />
                    <DeleteButton onClick={handleDelete} backgroundColor="bg-white" />
                </div>
            </div>
            {task.done ? (
                <button onClick={handleAddAnotherClicked} className={defaultLinkStyle}>
                    <div className="flex items-center space-x-1">
                        <PlusIcon className="w-5 h-5" />
                        <span className="text-sm">
                            Add another task for {entity.displayName}
                        </span>
                    </div>
                </button>
            ) : null}
        </div>
    );
};
