import { CommunityId } from "@sp-crm/core";
import { DeleteButton } from "components/ui/action-button";
import { UrlButton } from "components/ui/url-button";
import React, { useCallback, useState } from "react";
import {
    GetCommunityQuery,
    UpdateCommunityLicenseMutationVariables,
    useCreateCommunityLicenseMutation,
    useDeleteCommunityLicenseMutation,
    useGetCommunityQuery,
    useUpdateCommunityLicenseMutation,
} from "../../generated/graphql";
import { AutosavingInput } from "../ui/autosaving-input";
import { ErrorMessage } from "../ui/error-message";
import { SecondaryButton } from "../ui/secondary-button";
import { Spinner } from "../ui/spinner";

interface CommunityLicensesProps {
    communityId: CommunityId;
}

export const CommunityLicenses: React.FC<CommunityLicensesProps> = props => {
    const communityId = props.communityId;
    const query = useGetCommunityQuery({ id: communityId });
    const addMutation = useCreateCommunityLicenseMutation();
    const [isAdding, setIsAdding] = useState(false);
    const addCallback = useCallback(() => {
        (async () => {
            setIsAdding(true);
            await addMutation.mutateAsync({ communityId });
            await query.refetch();
            setIsAdding(false);
        })();
    }, [query, communityId, setIsAdding, addMutation]);
    if (query.isLoading) {
        return <Spinner />;
    }
    if (query.isError) {
        return (
            <ErrorMessage component="CommunityLicenses">
                <pre>{JSON.stringify(query.error, null, 2)}</pre>
            </ErrorMessage>
        );
    }
    if (!query.data) {
        return <Spinner />;
    }
    return (
        <div className="space-y-4">
            <ul className="space-y-4">
                {query.data.getCommunity.licenses.map(l => (
                    <li key={l.id}>
                        <CommunityLicenseRow
                            communityId={props.communityId}
                            communityLicense={l}
                            refetch={query.refetch}
                        />
                    </li>
                ))}
            </ul>
            <SecondaryButton disabled={isAdding} onClick={addCallback}>
                {isAdding ? <Spinner /> : "Add license"}
            </SecondaryButton>
        </div>
    );
};

interface CommunityLicenseRowProps {
    communityLicense: GetCommunityQuery["getCommunity"]["licenses"][0];
    communityId: CommunityId;
    refetch: () => Promise<unknown>;
}

const CommunityLicenseRow: React.FC<CommunityLicenseRowProps> = props => {
    const { communityLicense, communityId, refetch } = props;
    const deleteMutation = useDeleteCommunityLicenseMutation();
    const updateMutation = useUpdateCommunityLicenseMutation();
    const deleteAction = useCallback(async () => {
        await deleteMutation.mutateAsync({
            communityId: communityId,
            licenseId: communityLicense.id,
        });
        await refetch();
    }, [refetch, deleteMutation, communityId, communityLicense.id]);
    const update = useCallback(
        (payload: Partial<UpdateCommunityLicenseMutationVariables["license"]>) =>
            (async () => {
                await updateMutation.mutateAsync({
                    license: {
                        tenantId: communityLicense.tenantId, // don't love this pattern; how could this round trip be avoided without creating some frankenstein type server-side
                        communityId: communityLicense.communityId,
                        id: communityLicense.id,
                        ...payload,
                    },
                    licenseId: communityLicense.id,
                });
                await refetch();
            })(),
        [refetch, updateMutation, communityLicense],
    );
    return (
        <div>
            <div className="flex justify-between space-x-2">
                <div className="space-y-1 flex-1">
                    <div className="md:flex md:justify-between md:space-x-2 space-y-1 md:space-y-0">
                        <AutosavingInput
                            initial={communityLicense.licenseNumber ?? ""}
                            onCommit={v => update({ licenseNumber: v })}
                            label="License number"
                        />
                        <AutosavingInput
                            initial={communityLicense.licensee ?? ""}
                            onCommit={v => update({ licensee: v })}
                            label="Licensee"
                        />
                    </div>
                    <div className="md:flex md:justify-between md:space-x-2 space-y-1 md:space-y-0">
                        <AutosavingInput
                            type="date"
                            initial={communityLicense.licenseStartDate ?? ""}
                            onCommit={v => update({ licenseStartDate: v })}
                            label="Licensed date"
                        />
                        <AutosavingInput
                            type="date"
                            initial={communityLicense.licenseEndDate ?? ""}
                            onCommit={v => update({ licenseEndDate: v })}
                            label="Close date"
                        />
                    </div>
                    <div className="md:flex md:justify-between md:space-x-2 space-y-1 md:space-y-0">
                        <div className="flex-1 flex items-end space-x-1">
                            <AutosavingInput
                                initial={communityLicense.href ?? ""}
                                onCommit={v => update({ href: v })}
                                label="Website/link"
                            />
                            <UrlButton url={communityLicense.href} />
                        </div>
                        <div className="flex-1 flex items-end space-x-1">
                            <AutosavingInput
                                initial={communityLicense.status ?? ""}
                                onCommit={v => update({ status: v })}
                                label="Status"
                            />
                        </div>
                    </div>
                </div>
                <div className="block">
                    <DeleteButton
                        backgroundColor="bg-gray-200"
                        confirm={{
                            title: "Delete license?",
                            message: "Really delete this license?",
                            confirmLabel: "Yes, delete",
                            cancelLabel: "Cancel",
                        }}
                        onClick={deleteAction}
                    />
                </div>
            </div>
        </div>
    );
};
