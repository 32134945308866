import {
    BridgeQuestionInput,
    GetQuestionPermissionsQuery,
    useGetQuestionPermissionsQuery,
} from "generated/graphql";
import React, { useCallback } from "react";
import Multiselect from "react-widgets/Multiselect";
import { stableQueryOptions } from "util/requests";

interface QuestionRegionInputProps {
    question: BridgeQuestionInput;
    onChange: (question: BridgeQuestionInput) => void;
    editable: boolean;
}

export const QuestionRegionInput: React.FC<QuestionRegionInputProps> = props => {
    const { editable, question, onChange } = props;

    const questionPermissions = useGetQuestionPermissionsQuery(
        {},
        {
            ...stableQueryOptions(),
            enabled: editable,
        },
    );

    const handleChange = useCallback(
        (
            regions: GetQuestionPermissionsQuery["getQuestionPermissions"]["editableRegions"],
        ) => {
            onChange({
                ...question,
                regions: regions.map(r => r.id),
            });
        },
        [onChange, question],
    );

    if (
        editable &&
        questionPermissions.data?.getQuestionPermissions?.editableRegions?.length > 1
    ) {
        return (
            <div>
                <label>Region visibility</label>
                <Multiselect
                    data={questionPermissions.data.getQuestionPermissions.editableRegions}
                    value={question.regions}
                    dataKey="id"
                    textField="name"
                    onChange={handleChange}
                />
            </div>
        );
    }

    return null;
};
