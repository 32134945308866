import { CommunityId, ReferenceBusinessId } from "@sp-crm/core";
import { Subnav } from "components/shared/subnav";
import { SubnavItem } from "components/shared/subnav/subnav-types";
import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { ResponsiveMode } from "store/reducers/responsive";
import { ApplicationState } from "store/state";
import { ReferenceBusinessPicker } from "./reference-business-picker";
import { ReferenceCommunityPicker } from "./reference-community-picker";

interface OrganizationSearchProps {
    onCommunity: (community: CommunityId) => void;
    onBusiness: (business: ReferenceBusinessId) => void;
}

export const OrganizationSearch: React.FC<OrganizationSearchProps> = props => {
    const { onCommunity, onBusiness } = props;

    const responsiveMode = useSelector(
        (state: ApplicationState) => state.responsive.mode,
    );

    const [activeTab, setActiveTab] = useState(
        responsiveMode < ResponsiveMode.large ? null : "organization",
    );

    const handleReferenceBusinessSelect = useCallback(
        (selected: ReferenceBusinessId) => {
            onBusiness(selected);
        },
        [onBusiness],
    );

    const handleReferenceCommunitySelect = useCallback(
        (selected: CommunityId) => {
            onCommunity(selected);
        },
        [onCommunity],
    );

    const handleCloseSection = useCallback(() => {
        setActiveTab(null);
    }, [setActiveTab]);

    const renderReferenceBusinessPicker = useCallback(() => {
        return <ReferenceBusinessPicker onSelect={handleReferenceBusinessSelect} />;
    }, [handleReferenceBusinessSelect]);

    const renderReferenceCommunityPicker = useCallback(() => {
        return <ReferenceCommunityPicker onSelect={handleReferenceCommunitySelect} />;
    }, [handleReferenceCommunitySelect]);

    const tabs: SubnavItem[] = [
        {
            linkText: "Organizations",
            subpage: "organization",
            render: renderReferenceBusinessPicker,
        },
        {
            linkText: "Communities",
            subpage: "community",
            render: renderReferenceCommunityPicker,
        },
    ];

    return (
        <div>
            <Subnav
                subpages={tabs}
                defaultSubpage={null}
                selectedSubpage={activeTab}
                navigate={setActiveTab}
                closeSection={handleCloseSection}
            />
        </div>
    );
};
