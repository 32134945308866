import * as Common from "@sp-crm/core";
import {
    ActiveTaskSortTypes,
    CalendarDate,
    CompletedTaskSortTypes,
    ITask,
    TaskParentId,
    UserId,
} from "@sp-crm/core";
import { SelectPro } from "components/ui/select-pro";
import { useCreateTaskMutation } from "generated/graphql";
import { SelectBaseOption } from "helpers/select-defs";
import * as _f from "lodash/fp";
import * as React from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { useCurrentUserId, usePreferences, useUsers } from "store/selectors/hooks";
import { mapToArray } from "util/generic";
import { refetchReminders, updateUser } from "../../../store/actions";
import util from "../../../util";
import { Header } from "../../header";
import { TaskTypeProvider } from "../task-type-provider";
import { NewTask } from "./new";
import { ShowTask } from "./show";

const sortByDueDateAscending = (taskA: ITask, taskB: ITask): number => {
    const result =
        (taskA.when() ? taskA.when() : util.date.futureDate).valueOf() -
        (taskB.when() ? taskB.when() : util.date.futureDate).valueOf();

    if (result === 0) {
        return taskA.id.localeCompare(taskB.id);
    }

    return result;
};

const sortByDueDateDescending = (taskA: ITask, taskB: ITask): number => {
    const result =
        (taskB.when() ? taskB.when() : util.date.futureDate).valueOf() -
        (taskA.when() ? taskA.when() : util.date.futureDate).valueOf();

    if (result === 0) {
        return taskA.id.localeCompare(taskB.id);
    }

    return result;
};

const sortByCompletedDateAscending = (taskA: ITask, taskB: ITask): number => {
    const result =
        // completed sort falls back to due date if no completed date exists
        // since legacy tasks won't have a completed date.
        (
            taskA.completedAt ||
            (taskA.when() && taskA.when()) ||
            util.date.futureDate
        ).valueOf() -
        (
            taskB.completedAt ||
            (taskB.when() && taskB.when()) ||
            util.date.futureDate
        ).valueOf();

    if (result === 0) {
        return taskA.id.localeCompare(taskB.id);
    }

    return result;
};

const sortByCompletedDateDescending = (taskA: ITask, taskB: ITask): number => {
    const result =
        // completed sort falls back to due date if no completed date exists
        // since legacy tasks won't have a completed date.
        (
            taskB.completedAt ||
            (taskB.when() && taskB.when()) ||
            util.date.futureDate
        ).valueOf() -
        (
            taskA.completedAt ||
            (taskA.when() && taskA.when()) ||
            util.date.futureDate
        ).valueOf();

    if (result === 0) {
        return taskA.id.localeCompare(taskB.id);
    }

    return result;
};

const getCompletedTaskSortOptions = (): SelectBaseOption[] => {
    const sorter = _f.sortBy([(opt: SelectBaseOption): string => opt.text]);
    const valueCreator = _f.map((k: string): SelectBaseOption => {
        return {
            value: k,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
            text: (Common.completedTaskSort as any)[k],
        };
    });
    return sorter(valueCreator(_f.keys(Common.completedTaskSort)));
};

const getActiveTaskSortOptions = (): SelectBaseOption[] => {
    const sorter = _f.sortBy([(opt: SelectBaseOption): string => opt.text]);
    const valueCreator = _f.map((k: string): SelectBaseOption => {
        return {
            value: k,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
            text: (Common.activeTaskSort as any)[k],
        };
    });
    return sorter(valueCreator(_f.keys(Common.activeTaskSort)));
};

const getActiveSorterToUse = (
    activeSort: ActiveTaskSortTypes,
): ((taskA: ITask, taskB: ITask) => number) => {
    switch (activeSort) {
        case "dueDateDescending":
            return sortByDueDateDescending;

        case "dueDateAscending":
        default:
            return sortByDueDateAscending;
    }
};

const getCompletedSorterToUse = (
    completedSort: CompletedTaskSortTypes,
): ((taskA: ITask, taskB: ITask) => number) => {
    switch (completedSort) {
        case "dueDateDescending":
            return sortByDueDateDescending;

        case "dueDateAscending":
            return sortByDueDateAscending;

        case "completedDateDescending":
            return sortByCompletedDateDescending;

        case "completedDateAscending":
        default:
            return sortByCompletedDateAscending;
    }
};

const taskMoveDelay = 1000 * 1;

interface TasksProps {
    parentId: TaskParentId;
    defaultAssigneeId?: UserId;
    tasks: ITask[];
    refetch: () => void;
}

const showInCompleteList = (
    t: ITask,
    tasksPendingCompleteMove: Common.TaskId[],
): boolean => {
    if (t.status !== Common.taskStatus.done.key) {
        return false;
    }

    if (tasksPendingCompleteMove.includes(t.id)) {
        return false;
    }

    return true;
};

export const Tasks: React.FC<TasksProps> = props => {
    const { parentId, defaultAssigneeId, tasks, refetch } = props;

    const createTask = useCreateTaskMutation();
    const queryClient = useQueryClient();

    const doRefetch = React.useCallback(() => {
        refetch();
        refetchReminders(queryClient);
    }, [refetch, queryClient]);

    const handleAddTask = React.useCallback(
        async (
            assignedUserId: UserId | null,
            text: string,
            notes: string,
            dueDate: CalendarDate | null,
            dueDateTime: Date | null,
            taskTypeId: Common.TaskTypeId | null,
        ) => {
            await createTask.mutateAsync({
                parentId,
                params: {
                    ownerId: assignedUserId,
                    text,
                    notes,
                    dueDate: dueDate?.toString() || null,
                    dueDateTime,
                    taskTypeId,
                },
            });
            doRefetch();
        },
        [parentId, createTask, doRefetch],
    );

    const usersState = useUsers();
    const currentUserId = useCurrentUserId();
    const users = mapToArray(usersState.users);
    const currentUser = usersState.users[currentUserId] ?? null;
    const defaultAssignedToId = defaultAssigneeId ?? currentUser?.id ?? null;
    const preferences = usePreferences();
    const dispatch = useDispatch();
    const [tasksPendingCompleteMove, setTasksPendingCompleteMove] = React.useState<
        Common.TaskId[]
    >([]);

    const handleActiveSortOrderChange = React.useCallback(
        (sortOrder: ActiveTaskSortTypes) => {
            const loadedUser = Common.User.load(currentUser);
            loadedUser.preferences = {
                ...loadedUser.preferences,
                activeTaskSortOrder: sortOrder,
            };
            updateUser(loadedUser, dispatch);
        },
        [dispatch, currentUser],
    );

    const activeTasks = React.useMemo(() => {
        const activeSorter = getActiveSorterToUse(preferences.activeTaskSortOrder);
        return tasks
            .filter(t => !showInCompleteList(t, tasksPendingCompleteMove))
            .sort(activeSorter);
    }, [tasks, preferences.activeTaskSortOrder, tasksPendingCompleteMove]);

    const completedTasks = React.useMemo(() => {
        const completedSorter = getCompletedSorterToUse(
            preferences.completedTaskSortOrder,
        );
        return tasks
            .filter(t => showInCompleteList(t, tasksPendingCompleteMove))
            .sort(completedSorter);
    }, [tasks, preferences.completedTaskSortOrder, tasksPendingCompleteMove]);

    const handleTaskComplete = React.useCallback(
        async (task: Common.ITask) => {
            setTasksPendingCompleteMove(prev => [...prev, task.id]);
            setTimeout(() => {
                setTasksPendingCompleteMove(prev => prev.filter(x => x !== task.id));
            }, taskMoveDelay);
        },
        [setTasksPendingCompleteMove],
    );

    const handleTaskUpdated = React.useCallback(
        (_task: Common.ITask) => {
            doRefetch();
        },
        [doRefetch],
    );

    const handleTaskDeleted = React.useCallback(
        (_task: Common.ITask) => {
            doRefetch();
        },
        [doRefetch],
    );

    const handleCompletedSortOrderChange = React.useCallback(
        (sortOrder: CompletedTaskSortTypes) => {
            const loadedUser = Common.User.load(currentUser);
            loadedUser.preferences = {
                ...loadedUser.preferences,
                completedTaskSortOrder: sortOrder,
            };
            updateUser(loadedUser, dispatch);
        },
        [dispatch, currentUser],
    );

    return (
        <div className="task-control">
            <TaskTypeProvider>
                <NewTask
                    onAddTask={handleAddTask}
                    users={users}
                    assignedToId={defaultAssignedToId}
                />
                <ActiveTasks
                    tasks={activeTasks}
                    sortOrder={preferences.activeTaskSortOrder}
                    onSortOrderChange={handleActiveSortOrderChange}
                    onTaskComplete={handleTaskComplete}
                    onTaskUpdated={handleTaskUpdated}
                    onTaskDeleted={handleTaskDeleted}
                    users={users}
                />
                <CompletedTasks
                    tasks={completedTasks}
                    sortOrder={preferences.completedTaskSortOrder}
                    onSortOrderChange={handleCompletedSortOrderChange}
                    onTaskComplete={handleTaskComplete}
                    onTaskUpdated={handleTaskUpdated}
                    onTaskDeleted={handleTaskDeleted}
                    users={users}
                />
            </TaskTypeProvider>
        </div>
    );
};

interface ActiveTasksProps {
    tasks: ITask[];
    sortOrder: ActiveTaskSortTypes;
    onSortOrderChange: (sortOrder: ActiveTaskSortTypes) => void;
    onTaskComplete: (task: ITask) => void;
    onTaskUpdated: (task: ITask) => void;
    onTaskDeleted: (task: ITask) => void;
    users: Common.User[];
}

const ActiveTasks: React.FC<ActiveTasksProps> = props => {
    const {
        sortOrder,
        onSortOrderChange,
        tasks,
        onTaskComplete,
        onTaskUpdated,
        onTaskDeleted,
        users,
    } = props;

    return (
        <div className="tasks-section active-tasks-section">
            <div className="task-section-header">
                <div className="header">
                    <Header iconName="25_ActiveTasks">Active tasks</Header>
                </div>
                <div className="task-sort-by-picker">
                    <SelectPro
                        label="Sort by"
                        includePlaceholderOption={false}
                        value={sortOrder}
                        onChange={e =>
                            onSortOrderChange(e.target.value as ActiveTaskSortTypes)
                        }
                        options={getActiveTaskSortOptions()}
                    />
                </div>
            </div>
            <ul className="active-tasks tasks">
                {tasks.map(t => (
                    <li key={t.id} className="show-tasks-list-item">
                        <ShowTask
                            task={t}
                            onComplete={onTaskComplete}
                            onUpdated={onTaskUpdated}
                            onDeleted={onTaskDeleted}
                            users={users}
                        />
                    </li>
                ))}
            </ul>
        </div>
    );
};

interface CompletedTaskProps {
    tasks: ITask[];
    sortOrder: CompletedTaskSortTypes;
    onSortOrderChange: (sortOrder: CompletedTaskSortTypes) => void;
    onTaskComplete: (task: ITask) => void;
    onTaskUpdated: (task: ITask) => void;
    onTaskDeleted: (task: ITask) => void;
    users: Common.User[];
}

const CompletedTasks: React.FC<CompletedTaskProps> = props => {
    const {
        tasks,
        sortOrder,
        onSortOrderChange,
        onTaskComplete,
        onTaskUpdated,
        onTaskDeleted,
        users,
    } = props;

    if (tasks.length > 0) {
        return (
            <div className="tasks-section">
                <div className="task-section-header">
                    <div className="header">
                        <Header iconName="23_TaskComplete">Completed tasks</Header>
                    </div>
                    <div className="task-sort-by-picker">
                        <SelectPro
                            label="Sort by"
                            includePlaceholderOption={false}
                            value={sortOrder}
                            onChange={e =>
                                onSortOrderChange(
                                    e.target.value as CompletedTaskSortTypes,
                                )
                            }
                            options={getCompletedTaskSortOptions()}
                        />
                    </div>
                </div>
                <ul className="tasks">
                    {tasks.map(t => (
                        <li key={t.id} className="show-tasks-list-item">
                            <ShowTask
                                task={t}
                                onComplete={onTaskComplete}
                                onUpdated={onTaskUpdated}
                                onDeleted={onTaskDeleted}
                                users={users}
                            />
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
    return null;
};
