import { Action } from "redux";
import { Actions } from "./index";

export type GlobalSuccessNotificationCreate = {
    messageId: string;
    messageTitle: string;
    messageBody: string;
} & Action;

export type GlobalSuccessNotificationClear = {
    messageId: string;
} & Action;

export const makeGlobalSuccessClear = (id: string): GlobalSuccessNotificationClear => {
    return {
        type: Actions[Actions.GLOBAL_SUCCESS_CLEAR],
        messageId: id,
    };
};
