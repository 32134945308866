import { ClientId } from "@sp-crm/core";
import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "store/state";
import { parseRouteId } from "util/route-id";
import ShowClientFromRoute from "./from-route";

interface ShowClientFromRouteWrapperPropsFromState {
    clientId: ClientId;
    subpage: string;
}

class ShowClientFromRouteWrapper extends React.Component<
    ShowClientFromRouteWrapperPropsFromState,
    undefined
> {
    render() {
        return (
            <ShowClientFromRoute
                key={this.props.clientId}
                clientId={this.props.clientId}
                activeSubpage={this.props.subpage}
            />
        );
    }
}

function mapStateToProps(
    state: ApplicationState,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    ownProps: any,
): ShowClientFromRouteWrapperPropsFromState {
    return {
        clientId: parseRouteId<ClientId>("Client", ownProps.match.params.clientId),
        subpage: ownProps.match.params.subpage,
    };
}

export default connect(mapStateToProps)(ShowClientFromRouteWrapper);
