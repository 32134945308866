import { QueryClient } from "react-query";

const layoutQueryKeys = [
    "getLayoutSection",
    "getLayoutSections",
    "getLayoutContainerByKey",
] as const;

export const invalidateLayoutQueriesExcept = (
    queryClient: QueryClient,
    except: typeof layoutQueryKeys[number][],
): void => {
    layoutQueryKeys.forEach(key => {
        if (!except.includes(key)) {
            queryClient.invalidateQueries(key);
        }
    });
};

export const invalidateLayoutQueries = (queryClient: QueryClient): void => {
    invalidateLayoutQueriesExcept(queryClient, []);
};
