import { EmailEntityId, FileEntityId, TaskParentId } from "@sp-crm/core";
import { Action, Dispatch } from "redux";
import { loadFilesAction } from "./files";

export function loadLeafElementsForEntity(
    entityId: EmailEntityId | TaskParentId | FileEntityId,
    dispatch: Dispatch<Action>,
) {
    loadFilesAction(entityId, dispatch);
}
