import { CommunityOrgTypeSentinel, ILocation, locationToCoordinates } from "@sp-crm/core";
import { HoveredMapEntity } from "components/community-search/community-map/types";
import { Content, ContentContainer, SectionHeader, Stage } from "components/layout";
import { useCommitUserPreference } from "components/manage/account-settings/account-settings-hooks";
import { Subnav } from "components/shared/subnav";
import { CrmTablePagination } from "components/table/pagination";
import { PrimaryButton } from "components/ui/primary-button";
import { Toggle } from "components/ui/toggle";
import {
    ReferenceBusinessSearchResult,
    ReferenceCommunitySearchResult,
    useCreateReferenceBusinessMutation,
    useCreateReferenceContactMutation,
    useReferenceBusinessSearchQuery,
    useReferenceCommunitySearchQuery,
    useReferenceContactSearchQuery,
} from "generated/graphql";
import React, { useCallback, useMemo } from "react";
import { useRouteMatch } from "react-router";
import { navigate } from "store/actions";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { ResponsiveMode } from "store/reducers/responsive";
import { usePreferences, useRegionId, useResponsiveMode } from "store/selectors/hooks";
import {
    ReferenceDashboardAspect,
    referenceDashboardSlice,
    ReferenceFilter,
} from "store/slices/reference-dashboard";
import { ReferenceSearchBar } from "./dashboard-reference/reference-search-bar";
import { ReferenceBusinessSearchList } from "./reference-business-search-list";
import { ReferenceCommunitySearchList } from "./reference-community-search-list";
import { ReferenceContactSearchList } from "./reference-contact-search-list";
import {
    ReferenceBusinessSearchResultsMap,
    ReferenceCommunitySearchResultsMap,
    ReferenceContactSearchResultsMap,
} from "./reference-search-results-map";

interface ISearchRequest {
    page: number;
    perPage: number;
    sort: string;
    sortDirection: string;
}

interface SearchRequestPaginationProps {
    request: ISearchRequest;
    total: number;
    onPageChange: (page: number) => void;
    onPageSizeChange: (pageSize: number) => void;
}

const SearchRequestPagination: React.FC<SearchRequestPaginationProps> = props => {
    const { request, total, onPageChange, onPageSizeChange } = props;
    const pageCount = Math.ceil(total / request.perPage);

    const handleNextPage = useCallback(() => {
        onPageChange(request.page + 1);
    }, [request.page, onPageChange]);

    const handlePreviousPage = useCallback(() => {
        onPageChange(request.page - 1);
    }, [request.page, onPageChange]);

    return (
        <CrmTablePagination
            pageIndex={request.page}
            count={total}
            pageSize={request.perPage}
            pageCount={pageCount}
            canNextPage={request.page < pageCount - 1}
            canPreviousPage={request.page > 0}
            nextPage={handleNextPage}
            previousPage={handlePreviousPage}
            gotoPage={onPageChange}
            setPageSize={onPageSizeChange}
        />
    );
};

type ReferenceDashboardMapRouteMatch = {
    params?: {
        subpage?: string;
    };
} | null;

export const referralMapThreshold: ResponsiveMode = ResponsiveMode.xLarge;

export const ReferenceDashboardMap: React.FC<unknown> = () => {
    const regionId = useRegionId();
    const dispatch = useAppDispatch();
    const newAppState = useAppSelector(state => state.referenceDashboard);
    const {
        updateSearch,
        changePage,
        changePageSize,
        changeSortWithDirection,
        setExplicitMapBounds,
        resetMapBounds,
    } = referenceDashboardSlice.actions;

    const onSearchUpdated = useCallback(
        (search: ReferenceFilter) => {
            dispatch(updateSearch(search));
        },
        [dispatch, updateSearch],
    );

    const handleContactPageChange = useCallback(
        (page: number) => dispatch(changePage({ aspect: "contact", page })),
        [dispatch, changePage],
    );

    const handleContactPageSizeChange = useCallback(
        (page: number) => dispatch(changePageSize({ aspect: "contact", pageSize: page })),
        [dispatch, changePageSize],
    );

    const handleBusinessPageChange = useCallback(
        (page: number) => dispatch(changePage({ aspect: "business", page })),
        [dispatch, changePage],
    );

    const handleBusinessPageSizeChange = useCallback(
        (page: number) =>
            dispatch(changePageSize({ aspect: "business", pageSize: page })),
        [dispatch, changePageSize],
    );

    const handleCommunityPageChange = useCallback(
        (page: number) => dispatch(changePage({ aspect: "community", page })),
        [dispatch, changePage],
    );

    const handleCommunityPageSizeChange = useCallback(
        (page: number) =>
            dispatch(changePageSize({ aspect: "community", pageSize: page })),
        [dispatch, changePageSize],
    );

    const mapSearchMode: boolean = !!newAppState.contactSearchRequest.geoParams?.bounds;

    const referenceContactSearchQuery = useReferenceContactSearchQuery(
        {
            search: {
                regionId,
                ...newAppState.contactSearchRequest,
                page: mapSearchMode ? 0 : newAppState.contactSearchRequest.page,
                perPage: mapSearchMode ? 9999 : newAppState.contactSearchRequest.perPage,
            },
        },
        { keepPreviousData: true },
    );

    const referenceBusinessSearchQuery = useReferenceBusinessSearchQuery(
        {
            search: {
                regionId,
                ...newAppState.businessSearchRequest,
                page: mapSearchMode ? 0 : newAppState.businessSearchRequest.page,
                perPage: mapSearchMode ? 9999 : newAppState.businessSearchRequest.perPage,
            },
        },
        { keepPreviousData: true },
    );

    const referenceCommunitySearchQuery = useReferenceCommunitySearchQuery(
        {
            search: {
                regionId,
                ...newAppState.communitySearchRequest,
                page: mapSearchMode ? 0 : newAppState.communitySearchRequest.page,
                perPage: mapSearchMode
                    ? 9999
                    : newAppState.communitySearchRequest.perPage,
            },
        },
        { keepPreviousData: true },
    );

    const handleSubpageSelected = useCallback((subpage: string) => {
        navigate(`/references/${subpage}`);
    }, []);

    const handleCloseSection = useCallback(() => {
        navigate(`/references`);
    }, []);

    const createReferenceContactMutation = useCreateReferenceContactMutation();
    const createReferenceBusinessMutation = useCreateReferenceBusinessMutation();

    const handleAddContact = useCallback(
        async (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
            e.preventDefault();
            const response = await createReferenceContactMutation.mutateAsync({
                regionId,
            });

            if (response?.createReferenceContact?.id) {
                navigate(
                    `/references/contacts/show/${response.createReferenceContact.id}`,
                );
            }
        },
        [createReferenceContactMutation, regionId],
    );

    const handleAddOrganization = useCallback(
        async (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
            e.preventDefault();
            const response = await createReferenceBusinessMutation.mutateAsync({
                regionId,
            });

            if (response?.createReferenceBusiness?.id) {
                navigate(
                    `/references/organizations/show/${response.createReferenceBusiness.id}`,
                );
            }
        },
        [createReferenceBusinessMutation, regionId],
    );

    const routeMatch: ReferenceDashboardMapRouteMatch =
        useRouteMatch(`/references/:subpage`);

    const handleExplicitBoundsChanged = useCallback(
        (northWest: ILocation, southEast: ILocation) => {
            dispatch(
                setExplicitMapBounds({
                    northWest: locationToCoordinates(northWest),
                    southEast: locationToCoordinates(southEast),
                }),
            );
        },
        [dispatch, setExplicitMapBounds],
    );

    const handleResetMapView = useCallback(() => {
        dispatch(resetMapBounds());
    }, [dispatch, resetMapBounds]);

    const contactHits = useMemo(() => {
        if (
            mapSearchMode &&
            referenceContactSearchQuery.data?.referenceContactSearch.hits.length > 0
        ) {
            return referenceContactSearchQuery.data?.referenceContactSearch.hits.slice(
                newAppState.contactSearchRequest.page *
                    newAppState.contactSearchRequest.perPage,
                newAppState.contactSearchRequest.page *
                    newAppState.contactSearchRequest.perPage +
                    newAppState.contactSearchRequest.perPage,
            );
        }

        return referenceContactSearchQuery.data?.referenceContactSearch.hits || [];
    }, [
        mapSearchMode,
        referenceContactSearchQuery.data?.referenceContactSearch.hits,
        newAppState.contactSearchRequest.page,
        newAppState.contactSearchRequest.perPage,
    ]);

    const [hoveredOrganization, setHoveredOrganization] =
        React.useState<HoveredMapEntity | null>(null);

    const responsiveMode = useResponsiveMode();

    const showMap = responsiveMode >= referralMapThreshold;

    const renderContactsTable = useCallback(() => {
        return (
            <>
                <div className="w-full flex">
                    <div className="full-width">
                        <ReferenceContactSearchList
                            contactSearchResults={contactHits}
                            setHoveredOrganization={setHoveredOrganization}
                        />
                        {referenceContactSearchQuery.data ? (
                            <SearchRequestPagination
                                request={newAppState.contactSearchRequest}
                                total={
                                    referenceContactSearchQuery.data
                                        .referenceContactSearch.total
                                }
                                onPageChange={handleContactPageChange}
                                onPageSizeChange={handleContactPageSizeChange}
                            />
                        ) : null}
                    </div>
                    {showMap ? (
                        <ReferenceContactSearchResultsMap
                            hoveredOrganization={hoveredOrganization}
                            searchParams={newAppState.contactSearchRequest}
                            regionId={regionId}
                            onExplicitBoundsChanged={handleExplicitBoundsChanged}
                        />
                    ) : null}
                </div>
            </>
        );
    }, [
        referenceContactSearchQuery.data,
        contactHits,
        newAppState.contactSearchRequest,
        handleContactPageChange,
        handleContactPageSizeChange,
        handleExplicitBoundsChanged,
        regionId,
        hoveredOrganization,
        showMap,
    ]);

    const businessResults: ReferenceBusinessSearchResult = useMemo(() => {
        if (newAppState.contactSearchRequest.contactCondition) {
            return {
                hits: [],
                total: 0,
            };
        }

        return (
            referenceBusinessSearchQuery.data?.referenceBusinessSearch || {
                hits: [],
                total: 0,
            }
        );
    }, [
        referenceBusinessSearchQuery.data?.referenceBusinessSearch,
        newAppState.contactSearchRequest.contactCondition,
    ]);

    const businessHits = useMemo(() => {
        if (
            mapSearchMode &&
            referenceBusinessSearchQuery.data?.referenceBusinessSearch.hits.length > 0
        ) {
            return referenceBusinessSearchQuery.data?.referenceBusinessSearch.hits.slice(
                newAppState.businessSearchRequest.page *
                    newAppState.businessSearchRequest.perPage,
                newAppState.businessSearchRequest.page *
                    newAppState.businessSearchRequest.perPage +
                    newAppState.businessSearchRequest.perPage,
            );
        }

        return referenceBusinessSearchQuery.data?.referenceBusinessSearch.hits || [];
    }, [
        mapSearchMode,
        referenceBusinessSearchQuery.data?.referenceBusinessSearch.hits,
        newAppState.businessSearchRequest.page,
        newAppState.businessSearchRequest.perPage,
    ]);

    const renderBusinessesTable = useCallback(() => {
        return (
            <>
                <div className="w-full flex">
                    <div className="full-width">
                        <ReferenceBusinessSearchList
                            businessSearchResults={businessHits}
                            setHoveredOrganization={setHoveredOrganization}
                        />
                        {referenceBusinessSearchQuery.data ? (
                            <SearchRequestPagination
                                request={newAppState.businessSearchRequest}
                                total={businessResults.total}
                                onPageChange={handleBusinessPageChange}
                                onPageSizeChange={handleBusinessPageSizeChange}
                            />
                        ) : null}
                    </div>
                    {showMap ? (
                        <ReferenceBusinessSearchResultsMap
                            hoveredOrganization={hoveredOrganization}
                            searchParams={newAppState.businessSearchRequest}
                            regionId={regionId}
                            onExplicitBoundsChanged={handleExplicitBoundsChanged}
                        />
                    ) : null}
                </div>
            </>
        );
    }, [
        businessHits,
        businessResults.total,
        handleBusinessPageChange,
        handleBusinessPageSizeChange,
        newAppState.businessSearchRequest,
        referenceBusinessSearchQuery.data,
        setHoveredOrganization,
        regionId,
        handleExplicitBoundsChanged,
        hoveredOrganization,
        showMap,
    ]);

    const communityResults: ReferenceCommunitySearchResult = useMemo(() => {
        if (
            (newAppState.contactSearchRequest.organizationTypes?.length > 0 &&
                !newAppState.contactSearchRequest.organizationTypes.includes(
                    CommunityOrgTypeSentinel,
                )) ||
            newAppState.contactSearchRequest.contactCondition ||
            newAppState.contactSearchRequest.businessCondition
        ) {
            return {
                hits: [],
                total: 0,
            };
        }

        return (
            referenceCommunitySearchQuery.data?.referenceCommunitySearch || {
                hits: [],
                total: 0,
            }
        );
    }, [
        referenceCommunitySearchQuery.data?.referenceCommunitySearch,
        newAppState.contactSearchRequest.organizationTypes,
        newAppState.contactSearchRequest.contactCondition,
        newAppState.contactSearchRequest.businessCondition,
    ]);

    const communityHits = useMemo(() => {
        if (
            mapSearchMode &&
            referenceCommunitySearchQuery.data?.referenceCommunitySearch.hits.length > 0
        ) {
            return referenceCommunitySearchQuery.data?.referenceCommunitySearch.hits.slice(
                newAppState.communitySearchRequest.page *
                    newAppState.communitySearchRequest.perPage,
                newAppState.communitySearchRequest.page *
                    newAppState.communitySearchRequest.perPage +
                    newAppState.communitySearchRequest.perPage,
            );
        }

        return referenceCommunitySearchQuery.data?.referenceCommunitySearch.hits || [];
    }, [
        mapSearchMode,
        referenceCommunitySearchQuery.data?.referenceCommunitySearch.hits,
        newAppState.communitySearchRequest.page,
        newAppState.communitySearchRequest.perPage,
    ]);

    const renderCommunitiesTable = useCallback(() => {
        return (
            <>
                <div className="w-full flex">
                    <div className="full-width">
                        <ReferenceCommunitySearchList
                            communitySearchResults={communityHits}
                            setHoveredOrganization={setHoveredOrganization}
                        />
                        {referenceCommunitySearchQuery.data ? (
                            <SearchRequestPagination
                                request={newAppState.communitySearchRequest}
                                total={communityResults.total}
                                onPageChange={handleCommunityPageChange}
                                onPageSizeChange={handleCommunityPageSizeChange}
                            />
                        ) : null}
                    </div>
                    {showMap ? (
                        <ReferenceCommunitySearchResultsMap
                            hoveredOrganization={hoveredOrganization}
                            searchParams={newAppState.communitySearchRequest}
                            regionId={regionId}
                            onExplicitBoundsChanged={handleExplicitBoundsChanged}
                        />
                    ) : null}
                </div>
            </>
        );
    }, [
        communityHits,
        communityResults.total,
        handleCommunityPageChange,
        handleCommunityPageSizeChange,
        newAppState.communitySearchRequest,
        referenceCommunitySearchQuery.data,
        setHoveredOrganization,
        regionId,
        handleExplicitBoundsChanged,
        hoveredOrganization,
        showMap,
    ]);

    const handleSortChange = useCallback(
        (
            aspect: ReferenceDashboardAspect,
            sort: string,
            sortDirection: "ASC" | "DESC",
        ) => {
            dispatch(
                changeSortWithDirection({
                    aspect,
                    sort,
                    direction: sortDirection,
                }),
            );
        },
        [changeSortWithDirection, dispatch],
    );

    const subpages = [
        {
            href: `/references/contacts`,
            linkText: `Contacts`,
            badgeCount: referenceContactSearchQuery.data?.referenceContactSearch.total,
            subpage: "contacts",
            render: renderContactsTable,
        },
        {
            href: `/references/organizations`,
            linkText: `Organizations`,
            badgeCount: businessResults.total,
            subpage: "organizations",
            render: renderBusinessesTable,
        },
        {
            href: `/references/communities`,
            linkText: `Communities`,
            badgeCount: communityResults.total,
            subpage: "communities",
            render: renderCommunitiesTable,
        },
    ];

    const currentAspect: ReferenceDashboardAspect = useMemo(() => {
        switch (routeMatch?.params?.subpage) {
            case "contacts":
                return "contact";
            case "organizations":
                return "business";
            case "communities":
                return "community";
            default:
                return "contact";
        }
    }, [routeMatch?.params?.subpage]);

    const { referralMapExperience } = usePreferences();

    const commitMap = useCommitUserPreference("referralMapExperience");

    return (
        <Stage>
            <SectionHeader title="Referral sources">
                <div className="flex items-center space-x-2">
                    <PrimaryButton onClick={handleAddContact}>
                        Add a new contact
                    </PrimaryButton>
                    <PrimaryButton onClick={handleAddOrganization}>
                        Add a new organization
                    </PrimaryButton>
                </div>
            </SectionHeader>
            <Content>
                <ReferenceSearchBar
                    value={newAppState.searchFilter}
                    onChange={onSearchUpdated}
                    sortValues={{
                        contact: {
                            sort: newAppState.contactSearchRequest.sort,
                            sortDirection: newAppState.contactSearchRequest.sortDirection,
                        },
                        business: {
                            sort: newAppState.businessSearchRequest.sort,
                            sortDirection:
                                newAppState.businessSearchRequest.sortDirection,
                        },
                        community: {
                            sort: newAppState.communitySearchRequest.sort,
                            sortDirection:
                                newAppState.communitySearchRequest.sortDirection,
                        },
                    }}
                    aspect={currentAspect}
                    onSortChange={handleSortChange}
                    onResetMapView={referralMapExperience ? handleResetMapView : null}
                />
            </Content>
            <ContentContainer>
                <div className="mt-2 lg:mt-4">
                    <Subnav
                        subpages={subpages}
                        navigate={handleSubpageSelected}
                        selectedSubpage={routeMatch?.params?.subpage}
                        defaultSubpage="contacts"
                        closeSection={handleCloseSection}
                        widthMode="auto"
                        headerComponent={
                            showMap ? (
                                <Toggle
                                    label="Use map experience"
                                    checked={true}
                                    onChange={newValue => {
                                        commitMap(newValue);
                                    }}
                                />
                            ) : null
                        }
                    />
                </div>
            </ContentContainer>
        </Stage>
    );
};
