import { ContentHeader, ContentSubheader } from "components/layout";
import { LayoutSettings } from "components/manage/layout-settings";
import { AdvancedSearchEntityType, BridgeEntityResult } from "generated/graphql";
import React from "react";
import { useBridgeQuestions } from "store/selectors/bridge";

interface EntityFormConfigurationProps {
    entityMetadata: BridgeEntityResult;
}

export const EntityLayoutConfiguration: React.FC<
    EntityFormConfigurationProps
> = props => {
    const { entityMetadata } = props;

    const hasLegacyQuestions =
        entityMetadata.name === "Community" || entityMetadata.name === "Client";

    const questions = useBridgeQuestions(entityMetadata.name as AdvancedSearchEntityType);
    const fullyCustomizable = entityMetadata.name === "Community";

    return (
        <div>
            <ContentHeader>
                {hasLegacyQuestions ? `Details Page - Layout` : `Layout`}
            </ContentHeader>
            <ContentSubheader>
                Configure where {entityMetadata.title} fields will appear on the details
                page.
            </ContentSubheader>
            <ul className="mt-4 space-y-4">
                {entityMetadata.layoutParents.map(parent => (
                    <li key={parent}>
                        <LayoutSettings
                            entityMetadataName={entityMetadata.name}
                            includeStandardFields={false}
                            sectionParentKey={parent}
                            label=""
                            questions={questions}
                            allowEditOrder={fullyCustomizable}
                            allowEditVisibility={false}
                            mode="alwaysEditing"
                            allowEditFields={true}
                            showContainerTitle={fullyCustomizable}
                            enableNaryFormat={false}
                            allowCreateSections={fullyCustomizable}
                            allowEditIcon="custom-only"
                            allowEditTitle="custom-only"
                            allowEditItemTitles={false}
                        />
                    </li>
                ))}
            </ul>
        </div>
    );
};
