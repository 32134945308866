import { ArrowsRightLeftIcon } from "@heroicons/react/24/outline";
import { CustomListKey } from "@sp-crm/core";
import { ActivityMetadataEventType } from "generated/graphql";
import React from "react";
import { useCustomList } from "store/selectors/bridge";
import { EntityActivitySummary } from "./details/entity-activity-summary";
import { EntityActivityEntryProps } from "./types";

export const EntityActivityClientStatus: React.FC<EntityActivityEntryProps> = props => {
    const { activity } = props;
    const { metadata } = activity.activity;
    const { getListItemName } = useCustomList(CustomListKey.ClientStatus);
    if (!metadata || !metadata.events || metadata.events.length === 0) return null;
    const statusChangeEvent = metadata.events.find(
        e =>
            e.type === ActivityMetadataEventType.EntityFieldChange && e.entityFieldChange,
    );
    if (!statusChangeEvent) return null;
    return (
        <div className="relative flex items-start space-x-3">
            <div className="relative px-1">
                <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white">
                    <ArrowsRightLeftIcon
                        className="h-5 w-5 text-gray-500"
                        aria-hidden="true"
                    />
                </div>
            </div>
            <div className="min-w-0 flex-1 group pt-1">
                <EntityActivitySummary {...props}>
                    changed status from{" "}
                    <strong>
                        {getListItemName(
                            statusChangeEvent.entityFieldChange.oldStringValue,
                        )}
                    </strong>{" "}
                    to{" "}
                    <strong>
                        {getListItemName(
                            statusChangeEvent.entityFieldChange.newStringValue,
                        )}
                    </strong>
                    .
                </EntityActivitySummary>
            </div>
        </div>
    );
};
