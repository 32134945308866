import { QueryRenderer } from "components/clients/show-client/community-comparison/query-renderer";
import { ContentFull, ContentHeader } from "components/layout";
import { DeleteButton, EditButton, RestoreButton } from "components/ui/action-button";
import { Input } from "components/ui/input";
import { PrimaryButton } from "components/ui/primary-button";
import { Spinner } from "components/ui/spinner";
import { Formik, FormikHelpers } from "formik";
import {
    CreateTaskTypePayload,
    GetTaskTypesQuery,
    UpdateTaskTypePayload,
    useCreateTaskTypeMutation,
    useGetTaskTypesQuery,
    useUpdateTaskTypeMutation,
} from "generated/graphql";
import React, { useCallback } from "react";
import { AgencyCheckboxPref } from "./agency-settings-checkbox";

export const AgencySettingsTasks: React.FC<unknown> = () => {
    const taskTypeQuery = useGetTaskTypesQuery();
    return (
        <>
            <ContentFull>
                <ContentHeader>Task Settings</ContentHeader>
                <AgencyCheckboxPref
                    field="alertClientsWithNoTasks"
                    label="Require active tasks for every client"
                    description="When enabled, you will be alerted when trying to leave a client that has no active tasks. This can help ensure every client is receiving the attention they need."
                />
            </ContentFull>
            <ContentFull>
                <ContentHeader>Task Types</ContentHeader>

                <QueryRenderer query={taskTypeQuery} name="Agency Settings Tasks">
                    {data => {
                        const taskTypes = data.getTaskTypes;
                        const archivedTaskTypes = taskTypes
                            .filter(taskType => taskType.isArchived)
                            .sort((a, b) =>
                                a.name
                                    .toLocaleLowerCase()
                                    .localeCompare(b.name.toLocaleLowerCase()),
                            );
                        const activeTaskTypes = taskTypes
                            .filter(taskType => !taskType.isArchived)
                            .sort((a, b) =>
                                a.name
                                    .toLocaleLowerCase()
                                    .localeCompare(b.name.toLocaleLowerCase()),
                            );
                        return (
                            <div className="space-y-4 w-72">
                                <ul className="divide-y divide-gray-200">
                                    {activeTaskTypes.map(taskType => (
                                        <li key={taskType.id} className="py-2">
                                            <TaskTypeRow
                                                taskType={taskType}
                                                refresh={taskTypeQuery.refetch}
                                            />
                                        </li>
                                    ))}
                                    <li className="py-2">
                                        <TaskTypeRowNew refresh={taskTypeQuery.refetch} />
                                    </li>
                                </ul>
                                {archivedTaskTypes.length > 0 ? (
                                    <div className="rounded bg-gray-50 p-4">
                                        <h2 className="text-lg twoverride font-bold">
                                            Archived
                                        </h2>
                                        <ul className="divide-y divide-gray-200">
                                            {archivedTaskTypes.map(taskType => (
                                                <li key={taskType.id} className="py-2">
                                                    <TaskTypeRow
                                                        taskType={taskType}
                                                        refresh={taskTypeQuery.refetch}
                                                    />
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                ) : null}
                            </div>
                        );
                    }}
                </QueryRenderer>
            </ContentFull>
        </>
    );
};

interface TaskTypeRowProps {
    taskType: GetTaskTypesQuery["getTaskTypes"][0];
    refresh: () => void;
}

interface TaskTypeEdit {
    name: string;
}

export const TaskTypeRow: React.FC<TaskTypeRowProps> = props => {
    const { taskType, refresh } = props;
    const [isPending, setIsPending] = React.useState(false);
    const [isEditing, setIsEditing] = React.useState(false);
    const updateTaskType = useUpdateTaskTypeMutation();
    const archive = useCallback(async () => {
        setIsPending(true);
        const payload: UpdateTaskTypePayload = {
            isArchived: true,
            taskTypeId: taskType.id,
            name: taskType.name,
        };
        await updateTaskType.mutateAsync({ payload });
        setIsPending(false);
        refresh();
    }, [taskType, refresh, updateTaskType]);
    const restore = useCallback(async () => {
        setIsPending(true);
        const payload: UpdateTaskTypePayload = {
            isArchived: false,
            taskTypeId: taskType.id,
            name: taskType.name,
        };
        await updateTaskType.mutateAsync({ payload });
        setIsPending(false);
        refresh();
    }, [taskType, refresh, updateTaskType]);
    const enableEditing = useCallback(() => {
        setIsEditing(true);
    }, []);
    const save = React.useCallback(
        async (values: TaskTypeEdit, formik: FormikHelpers<TaskTypeEdit>) => {
            setIsPending(true);
            const payload: UpdateTaskTypePayload = {
                isArchived: taskType.isArchived,
                taskTypeId: taskType.id,
                name: values.name,
            };
            await updateTaskType.mutateAsync({ payload });
            refresh();
            formik.resetForm();
            setIsEditing(false);
            setIsPending(false);
        },
        [taskType, refresh, updateTaskType],
    );
    if (isEditing) {
        return (
            <Formik
                initialValues={{
                    name: taskType.name,
                }}
                onSubmit={save}>
                {formValues => (
                    <form onSubmit={formValues.handleSubmit} className="space-y-1">
                        <div className="flex items-end space-x-1">
                            <Input
                                type="text"
                                label="Name"
                                name="name"
                                onChange={formValues.handleChange}
                                onBlur={formValues.handleBlur}
                                value={formValues.values.name}
                            />
                            {isPending ? (
                                <div className="w-7 h-7">
                                    <Spinner />
                                </div>
                            ) : (
                                <PrimaryButton disabled={isPending} type="submit">
                                    Save
                                </PrimaryButton>
                            )}
                        </div>
                    </form>
                )}
            </Formik>
        );
    }
    return (
        <div className="flex items-center justify-between">
            <div>{taskType.name.length > 0 ? taskType.name : "(unnamed)"}</div>
            <div className="flex items-center space-x-2">
                <EditButton backgroundColor={"bg-white"} onClick={enableEditing} />
                {isPending ? (
                    <div className="w-7 h-7">
                        <Spinner />
                    </div>
                ) : taskType.isArchived ? (
                    <RestoreButton onClick={restore} backgroundColor={"bg-white"} />
                ) : (
                    <DeleteButton onClick={archive} backgroundColor={"bg-white"} />
                )}
            </div>
        </div>
    );
};

interface TaskTypeRowNewProps {
    refresh: () => void;
}
export const TaskTypeRowNew: React.FC<TaskTypeRowNewProps> = props => {
    const { refresh } = props;
    const createTaskType = useCreateTaskTypeMutation();
    const save = React.useCallback(
        async (values: TaskTypeEdit, formik: FormikHelpers<TaskTypeEdit>) => {
            const payload: CreateTaskTypePayload = {
                name: values.name,
            };
            await createTaskType.mutateAsync({ payload });
            refresh();
            formik.resetForm();
        },
        [refresh, createTaskType],
    );
    return (
        <Formik
            initialValues={{
                name: "",
            }}
            onSubmit={save}>
            {formValues => (
                <form onSubmit={formValues.handleSubmit} className="space-y-1">
                    <div className="flex items-end space-x-1">
                        <Input
                            type="text"
                            label="Add new task type"
                            name="name"
                            onChange={formValues.handleChange}
                            onBlur={formValues.handleBlur}
                            value={formValues.values.name}
                        />
                        <PrimaryButton disabled={formValues.isSubmitting} type="submit">
                            Create
                        </PrimaryButton>
                    </div>
                </form>
            )}
        </Formik>
    );
};
