import {
    CalendarDate,
    CustomListKey,
    formatCalendarDate,
    formatDateNoTime,
} from "@sp-crm/core";
import { CrmTable, CrmTableProps } from "components/table/crm-table";
import { SearchClientsQuery } from "generated/graphql";
import * as React from "react";
import { Link } from "react-router-dom";
import { useCustomList } from "store/selectors/bridge";

interface MovesProps {
    clients: SearchClientsQuery["getClients"]["clients"];
}

interface MoveDetailRow {
    dateAdded: string;
    lastUpdateDate: string;
    moveDate: string;
    movedCommunity: string;
    id: string;
    lastStatus: string;
}

const columns: CrmTableProps<MoveDetailRow>["columns"] = [
    { header: "Created Date", key: "dateAdded" },
    { header: "Last Update Date", key: "lastUpdateDate" },
    {
        header: "Moved Community",
        key: "movedCommunity",
    },
    { header: "Move Date", key: "moveDate" },
    { header: "Status", key: "lastStatus" },
];

const link = (props: { record: MoveDetailRow }) => {
    return <Link to={`/clients/show/${props.record.id}`}>View</Link>;
};

export const Moves: React.FC<MovesProps> = props => {
    const { clients } = props;

    const { getListItemName } = useCustomList(CustomListKey.ClientStatus);

    const moveData: MoveDetailRow[] = clients.map(
        (c: SearchClientsQuery["getClients"]["clients"][0]): MoveDetailRow => {
            const humanFriendlyStatus = getListItemName(c.statusListItemId);

            return {
                id: c.id,
                dateAdded: formatDateNoTime(c.dateAdded),
                lastStatus: humanFriendlyStatus,
                lastUpdateDate: formatDateNoTime(c.updatedAt),
                moveDate: formatCalendarDate(
                    CalendarDate.parse(c.moveTimelineActual).getOrElse(null),
                ),
                movedCommunity: c.chosenCommunity?.name || "-",
            };
        },
    );

    return <CrmTable columns={columns} data={moveData} special={link} />;
};
