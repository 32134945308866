import XCircleIcon from "@heroicons/react/20/solid/XCircleIcon";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { Localization, LocalizedDistance, Meters } from "@sp-crm/core";
import { useLocalizedStrings } from "components/locale-provider/locale-provider";
import { Callout } from "components/ui/callout";
import { Input } from "components/ui/input";
import { Select } from "components/ui/select";
import { SelectPro } from "components/ui/select-pro";
import { SearchGeoParams } from "generated/graphql";
import { produce } from "immer";
import React, { ChangeEvent, useCallback, useMemo, useReducer } from "react";
import { getStatesArray } from "util/states";
import { SearchCalloutButtons } from "./search-callout-buttons";
import { SearchLocationPreview } from "./search-location-preview";

interface SearchGeoParamsCalloutProps {
    value: SearchGeoParams | null;
    onChange: (value: SearchGeoParams | null) => void;
    onResetMapView: () => void;
}

export const SearchGeoParamsCallout: React.FC<SearchGeoParamsCalloutProps> = props => {
    const { value, onChange, onResetMapView } = props;

    const renderButton = useCallback(() => {
        if (
            value?.county?.length > 0 ||
            value?.city?.length > 0 ||
            value?.zip?.length > 0
        ) {
            return (
                <div className="flex items-center space-x-1 cursor-pointer text-gray-700 text-lg">
                    <SearchLocationPreview
                        address={{
                            city: value.city || "",
                            county: value.county || "",
                            state: value.state || "",
                            street: value.address || "",
                            zip: value.zip || "",
                        }}
                        specificCity={value.matchType === "city"}
                        specificCounty={value.matchType === "county"}
                        specificZip={value.matchType === "zip"}
                    />
                    <ChevronDownIcon className="w-5 h-5 text-gray-500" />
                </div>
            );
        }

        return (
            <div className="flex items-center space-x-1 cursor-pointer text-gray-700">
                <div className="text-lg">Location</div>
                <ChevronDownIcon className="w-5 h-5 text-gray-500" />
            </div>
        );
    }, [value]);

    if (value?.bounds) {
        return (
            <div className="flex items-center space-x-1 text-gray-700">
                <div className="text-lg">Map view</div>
                <XCircleIcon
                    className="w-5 h-5 text-gray-300 hover:text-gray-700 cursor-pointer"
                    onClick={onResetMapView}
                />
            </div>
        );
    }

    return (
        <Callout renderButton={renderButton}>
            {({ close }) => (
                <SearchGeoParamsEditor
                    initial={value}
                    onCommit={newValue => {
                        onChange(newValue);
                        close();
                    }}
                />
            )}
        </Callout>
    );
};

type SearchMode = "city" | "zip" | "county" | number;

interface State {
    searchModeOrDistance: SearchMode;
    street: string;
    city: string;
    state: string;
    zip: string;
    county: string;
}

type Action =
    | { type: "onSearchModeChange"; value: SearchMode }
    | { type: "onStreetChange"; value: string }
    | { type: "onCityChange"; value: string }
    | { type: "onStateChange"; value: string }
    | { type: "onZipChange"; value: string }
    | { type: "onCountyChange"; value: string };

const reducer = (state: State, action: Action): State => {
    return produce(state, draft => {
        if (action.type === "onSearchModeChange") {
            const oldSearchModeType = typeof state.searchModeOrDistance;
            const newSearchModeType = typeof action.value;

            draft.searchModeOrDistance = action.value;

            if (oldSearchModeType === "string" || newSearchModeType === "string") {
                draft.street = "";
                draft.city = "";
                draft.state = "";
                draft.zip = "";
                draft.county = "";
            }
        }
        if (action.type === "onStreetChange") {
            draft.street = action.value;
        }
        if (action.type === "onCityChange") {
            draft.city = action.value;
        }
        if (action.type === "onStateChange") {
            draft.state = action.value;
        }
        if (action.type === "onZipChange") {
            draft.zip = action.value;
        }
        if (action.type === "onCountyChange") {
            draft.county = action.value;
        }
    });
};

const searchModeFromMatchType = (
    locale: Localization,
    value: SearchGeoParams | null,
): SearchMode => {
    if (value?.matchType === "city") {
        return "city";
    }

    if (value?.matchType === "county") {
        return "county";
    }

    if (value?.matchType === "zip") {
        return "zip";
    }

    if (value?.distance) {
        return Math.floor(locale.conversions.fromMeters(value.distance as Meters));
    }

    return 5;
};

const initialState = (locale: Localization, value: SearchGeoParams | null): State => ({
    searchModeOrDistance: searchModeFromMatchType(locale, value),
    street: value?.address || "",
    city: value?.city || "",
    state: value?.state || "",
    zip: value?.zip || "",
    county: value?.county || "",
});

interface SearchGeoParamsEditorProps {
    initial: SearchGeoParams;
    onCommit: (value: SearchGeoParams) => void;
}

const SearchGeoParamsEditor: React.FC<SearchGeoParamsEditorProps> = props => {
    const locale = useLocalizedStrings();
    const { initial, onCommit } = props;

    const [state, dispatch] = useReducer(reducer, initialState(locale, initial));

    const handleSearchModeChange = useCallback(
        (e: React.ChangeEvent<HTMLSelectElement>) => {
            const parsed = parseInt(e.target.value, 10);
            const searchMode: SearchMode = isNaN(parsed)
                ? (e.target.value as SearchMode)
                : parsed;
            dispatch({ type: "onSearchModeChange", value: searchMode });
        },
        [],
    );

    const handleStreetChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        dispatch({ type: "onStreetChange", value: e.target.value });
    }, []);

    const streetEnabled = useMemo(() => {
        return typeof state.searchModeOrDistance === "number";
    }, [state.searchModeOrDistance]);

    const handleCityChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        dispatch({ type: "onCityChange", value: e.target.value });
    }, []);

    const cityEnabled = useMemo(() => {
        return (
            typeof state.searchModeOrDistance === "number" ||
            state.searchModeOrDistance === "city"
        );
    }, [state.searchModeOrDistance]);

    const handleStateChange = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
        dispatch({ type: "onStateChange", value: e.target.value });
    }, []);

    const stateEnabled = useMemo(() => {
        return (
            typeof state.searchModeOrDistance === "number" ||
            state.searchModeOrDistance === "city" ||
            state.searchModeOrDistance === "county"
        );
    }, [state.searchModeOrDistance]);

    const handleZipChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        dispatch({ type: "onZipChange", value: e.target.value });
    }, []);

    const zipEnabled = useMemo(() => {
        return (
            typeof state.searchModeOrDistance === "number" ||
            state.searchModeOrDistance === "zip"
        );
    }, [state.searchModeOrDistance]);

    const handleCountyChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        dispatch({ type: "onCountyChange", value: e.target.value });
    }, []);

    const statesArray = useMemo(() => getStatesArray(), []);

    const handleClear = useCallback(() => {
        onCommit(null);
    }, [onCommit]);

    const handleSubmit = useCallback(() => {
        const newParams: SearchGeoParams = {
            address: state.street,
            city: state.city,
            state: state.state,
            zip: state.zip,
            county: state.county,
        };

        if (typeof state.searchModeOrDistance === "number") {
            newParams.matchType = "distance";
            newParams.distance = Math.ceil(
                locale.conversions.toMeters(
                    state.searchModeOrDistance as LocalizedDistance,
                ),
            );
        } else {
            newParams.matchType = state.searchModeOrDistance;
        }

        onCommit(newParams);
    }, [state, onCommit, locale]);

    return (
        <div className="space-y-2 w-112">
            <p>
                Enter at least City and {locale.strings.state} or {locale.strings.zip}
            </p>
            <Select value={state.searchModeOrDistance} onChange={handleSearchModeChange}>
                <optgroup label="In specific...">
                    <option value="city">City</option>
                    <option value="zip">Zip</option>
                    <option value="county">County</option>
                </optgroup>
                <optgroup label="Proximity">
                    {[1, 3, 5, 10, 15, 20, 30, 50].map(distance => (
                        <option key={distance} value={distance}>
                            Within {distance}{" "}
                            {distance > 1
                                ? locale.strings.distanceUnitPlural
                                : locale.strings.distanceUnit}{" "}
                            of
                        </option>
                    ))}
                </optgroup>
            </Select>
            {state.searchModeOrDistance === "county" ? (
                <div className="flex space-x-2">
                    <div className="flex-1">
                        <Input
                            value={state.county}
                            onChange={handleCountyChange}
                            placeholder="County"
                        />
                    </div>
                    <div className="flex-1">
                        <SelectPro
                            value={state.state}
                            includePlaceholderOption
                            placeholderStr={"(select)"}
                            onChange={handleStateChange}
                            options={statesArray}
                        />
                    </div>
                </div>
            ) : (
                <>
                    <div>
                        <Input
                            value={state.street}
                            onChange={handleStreetChange}
                            placeholder="Street Address (optional)"
                            disabled={!streetEnabled}
                        />
                    </div>

                    <div className="flex space-x-2">
                        <div className="flex-1">
                            <Input
                                value={state.city}
                                onChange={handleCityChange}
                                placeholder="City"
                                disabled={!cityEnabled}
                            />
                        </div>

                        <div className="flex-1">
                            <SelectPro
                                value={state.state}
                                includePlaceholderOption
                                placeholderStr={stateEnabled ? "(select)" : ""}
                                onChange={handleStateChange}
                                disabled={!stateEnabled}
                                options={statesArray}
                            />
                        </div>

                        <div className="flex-1">
                            <Input
                                value={state.zip}
                                onChange={handleZipChange}
                                placeholder={locale.strings.zip}
                                disabled={!zipEnabled}
                            />
                        </div>
                    </div>
                </>
            )}
            <SearchCalloutButtons onClear={handleClear} onSubmit={handleSubmit} />
        </div>
    );
};
