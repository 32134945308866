import { StarIcon as StarIconOutline } from "@heroicons/react/24/outline";
import { StarIcon as StarIconFull } from "@heroicons/react/24/solid";
import { defaultLinkStyle } from "components/ui/link";
import { Spinner } from "components/ui/spinner";
import {
    GetSavedQueriesQuery,
    useUpdateQueryFavoriteStatusMutation,
} from "generated/graphql";
import React from "react";

interface AdvancedSearchFavoriteButtonProps {
    report: GetSavedQueriesQuery["getSavedQueries"]["savedQueries"][0];
    reload: () => void;
}

export const AdvancedSearchFavoriteButton: React.FC<
    AdvancedSearchFavoriteButtonProps
> = props => {
    const { report, reload } = props;
    const [expectedState, setExpectedState] = React.useState(report.isFavorite);
    const isWorking = expectedState !== report.isFavorite;
    const mutation = useUpdateQueryFavoriteStatusMutation();
    const markAsFavorite = React.useCallback(
        async (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
            e.preventDefault();
            setExpectedState(true);
            await mutation.mutateAsync({
                savedQueryId: report.id,
                isFavorite: true,
            });
            reload();
        },
        [report.id, reload, mutation],
    );
    const markAsUnfavorite = React.useCallback(
        async (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
            e.preventDefault();
            setExpectedState(false);
            await mutation.mutateAsync({
                savedQueryId: report.id,
                isFavorite: false,
            });
            reload();
        },
        [report.id, reload, mutation],
    );
    const noOp = React.useCallback(
        async (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
            e.preventDefault();
        },
        [],
    );

    if (isWorking) {
        return (
            <div>
                <a
                    href="#"
                    className={`${defaultLinkStyle} flex items-center space-x-2`}
                    onClick={noOp}>
                    <Spinner />
                    <div>Favorite</div>
                </a>
            </div>
        );
    }
    if (report.isFavorite) {
        return (
            <div>
                <a
                    href="#"
                    className={`${defaultLinkStyle} flex items-center space-x-2`}
                    onClick={markAsUnfavorite}>
                    <StarIconFull className={`h-4 w-4`} />
                    <div>Favorite</div>
                </a>
            </div>
        );
    }
    return (
        <div>
            <a
                href="#"
                className={`${defaultLinkStyle} flex items-center space-x-2`}
                onClick={markAsFavorite}>
                <StarIconOutline className={`h-4 w-4`} />
                <div>Favorite</div>
            </a>
        </div>
    );
};
