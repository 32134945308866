import { User } from "@sp-crm/core";
import { ContentFull, ContentHeader } from "components/layout";
import { InlineBanner } from "components/ui/inline-banner";
import { Input } from "components/ui/input";
import { PrimaryButton } from "components/ui/primary-button";
import { SecondaryButton } from "components/ui/secondary-button";
import { Formik, FormikErrors, FormikHelpers } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import { updateUser } from "store/actions";
import { useCurrentUser } from "store/selectors/hooks";

interface FormValues {
    password: string;
    confirm: string;
}

const initialValues: FormValues = {
    password: "",
    confirm: "",
};

export const AccountSettingsPassword: React.FC<unknown> = () => {
    const [showSuccessNotification, setShowSuccessNotification] = React.useState(false);
    const validateFormInput = (values: FormValues): FormikErrors<FormValues> => {
        const errors: FormikErrors<FormValues> = {};
        if (
            values.password === undefined ||
            values.password === null ||
            values.password === ""
        ) {
            errors.password = "Password cannot be blank";
        } else if (values.password.length < 7) {
            errors.password = "Password must be at least 7 characters";
        }
        if (values.confirm !== values.password) {
            errors.confirm = "Passwords do not match";
        }
        return errors;
    };
    const user = useCurrentUser();
    const dispatch = useDispatch();
    const submitForm = React.useCallback(
        async (values: FormValues, helpers: FormikHelpers<FormValues>) => {
            helpers.setSubmitting(true);
            const commonUser = User.load(user);
            commonUser.password = values.password;
            await updateUser(commonUser, dispatch);
            helpers.setSubmitting(false);
            helpers.resetForm();
            setShowSuccessNotification(true);
            setTimeout(() => setShowSuccessNotification(false), 5000);
        },
        [dispatch, user],
    );

    return (
        <ContentFull>
            <ContentHeader>Change password</ContentHeader>
            <Formik
                validate={validateFormInput}
                onSubmit={submitForm}
                initialValues={initialValues}>
                {({
                    values,
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    touched,
                    isSubmitting,
                    isValid,
                    dirty,
                }) => (
                    <form onSubmit={handleSubmit} className="space-y-4">
                        <div>
                            <Input
                                type="password"
                                label="New password"
                                name="password"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.password}
                            />
                            {touched.password && errors.password ? (
                                <div className="text-red-700 font-bold mt-1">
                                    {errors.password}
                                </div>
                            ) : null}
                        </div>
                        <div>
                            <Input
                                type="password"
                                label="Confirm new password"
                                name="confirm"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.confirm}
                            />
                            {touched.confirm && errors.confirm ? (
                                <div className="text-red-700 font-bold mt-1">
                                    {errors.confirm}
                                </div>
                            ) : null}
                        </div>
                        {dirty && isValid && !isSubmitting ? (
                            <PrimaryButton type="submit">Save new password</PrimaryButton>
                        ) : (
                            <SecondaryButton disabled type="submit">
                                Save new password
                            </SecondaryButton>
                        )}

                        {showSuccessNotification ? (
                            <InlineBanner type="success">
                                Your password has been updated.
                            </InlineBanner>
                        ) : null}
                    </form>
                )}
            </Formik>
        </ContentFull>
    );
};
