import { Radio, RadioOption } from "components/shared/radio";
import { Input } from "components/ui/input";
import { PrimaryButton } from "components/ui/primary-button";
import { SecondaryButton } from "components/ui/secondary-button";
import { Spinner } from "components/ui/spinner";
import {
    CreateWorkflowMutation,
    CustomListKey,
    WorkflowPayload,
    useCreateWorkflowMutation,
} from "generated/graphql";
import React, { useEffect, useMemo } from "react";
import { useCustomList } from "store/selectors/bridge";
import { useCurrentUserId } from "store/selectors/hooks";
import { WorkflowTemplateKey, workflowTemplates } from "./workflow-template";

interface CreateWorkflowPanelProps {
    onSaved: (workflow: CreateWorkflowMutation["createWorkflow"]) => void;
    onCancel: () => void;
}

export const CreateWorkflowPanel: React.FC<CreateWorkflowPanelProps> = props => {
    const { onSaved, onCancel } = props;

    const [selectedTemplate, setSelectedTemplate] =
        React.useState<WorkflowTemplateKey>("referralSource");

    const [workflowName, setWorkflowName] = React.useState<string>("");
    const [workflowDescription, setWorkflowDescription] = React.useState<string>("");
    const currentUserId = useCurrentUserId();

    const createWorkflowMutation = useCreateWorkflowMutation();
    const { customList } = useCustomList(CustomListKey.ClientStatus);

    const workflowPayload: WorkflowPayload = useMemo(() => {
        if (!customList) {
            return null;
        }

        return workflowTemplates[selectedTemplate].toWorkflow({
            name: workflowName,
            description: workflowDescription,
            currentUserId,
            clientStatusCustomList: customList,
        });
    }, [selectedTemplate, workflowName, workflowDescription, currentUserId, customList]);

    const workflowTemplateOptions: RadioOption[] = useMemo(() => {
        return Object.values(workflowTemplates).map(template => ({
            key: template.key,
            text: template.text,
            helpText: template.helpText,
        }));
    }, []);

    useEffect(() => {
        const newTemplate = workflowTemplates[selectedTemplate];
        setWorkflowName(newTemplate.text);
        setWorkflowDescription(newTemplate.helpText);
    }, [selectedTemplate]);

    const handleCreate = React.useCallback(
        async (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            const result = await createWorkflowMutation.mutateAsync({
                workflow: workflowPayload,
            });
            onSaved(result.createWorkflow);
        },
        [createWorkflowMutation, onSaved, workflowPayload],
    );

    const handleCancel = React.useCallback(
        async (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            onCancel();
        },
        [onCancel],
    );

    if (!workflowPayload) {
        return null;
    }

    return (
        <div className="space-y-4">
            <p>
                Create a workflow to trigger automation when a client meets certain
                criteria. Choose a template, configure basic settings, then save.
            </p>
            <p>
                After you create this workflow, it will be in a draft state so you can
                finalize any settings before publishing it.
            </p>
            <Radio
                onChange={val => setSelectedTemplate(val as WorkflowTemplateKey)}
                options={workflowTemplateOptions}
                value={selectedTemplate}
                label="Template"
            />
            <Input
                label="Name"
                value={workflowName}
                onChange={e => setWorkflowName(e.target.value)}
            />
            <Input
                label="Description"
                value={workflowDescription}
                onChange={e => setWorkflowDescription(e.target.value)}
            />
            <div className="flex items-center space-x-2">
                <PrimaryButton onClick={handleCreate}>Create workflow</PrimaryButton>
                <SecondaryButton onClick={handleCancel}>Cancel</SecondaryButton>
            </div>
            {createWorkflowMutation.isLoading ? <Spinner /> : null}
        </div>
    );
};
