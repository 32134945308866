import { Badge } from "components/ui/badge";
import * as React from "react";
import { SubnavItem, SubnavPropsOwnProps } from "./subnav-types";

export const SubnavRegular: React.FC<SubnavPropsOwnProps> = props => {
    const { selectedSubpage, defaultSubpage, navigate, subpages, headerComponent } =
        props;
    const actualSelectedSubpage = subpages.find(
        page => page.subpage === (selectedSubpage || defaultSubpage),
    );
    const widthMode = props.widthMode || "screen";
    const onClick = React.useCallback(
        (i: SubnavItem) => (e: React.MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault();
            navigate(i.subpage);
        },
        [navigate],
    );

    const widthClassName = widthMode === "screen" ? "w-screen" : "";

    return (
        <div className="space-y-2">
            <div className="flex justify-between items-center">
                <div
                    className={`flex flex-wrap space-x-1 lg:space-x-2 -mx-4 px-4 ${widthClassName}`}>
                    {subpages.map(page => {
                        const isSelected = page === actualSelectedSubpage;
                        const className = isSelected
                            ? "twoverride whitespace-nowrap text-brand-700 bg-brand-100 px-3 py-2 font-semibold rounded-md flex items-center space-x-1"
                            : "twoverride whitespace-nowrap text-gray-500 hover:text-gray-700 hover:bg-gray-50 hover:shadow-sm px-3 py-2 font-semibold rounded-md flex items-center space-x-1";
                        return (
                            <a
                                className={className}
                                key={page.subpage}
                                href="#"
                                data-subpage={page.subpage}
                                onClick={onClick(page)}>
                                <div>{page.linkText}</div>
                                {page.badgeCount !== null &&
                                page.badgeCount !== undefined ? (
                                    <div>
                                        <Badge
                                            type={page.badgeType || "info"}
                                            value={page.badgeCount}
                                        />
                                    </div>
                                ) : null}
                            </a>
                        );
                    })}
                </div>
                {headerComponent}
            </div>
            <div>{actualSelectedSubpage ? actualSelectedSubpage.render() : null}</div>
        </div>
    );
};
