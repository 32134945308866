import { CrmTablePagination } from "components/table/pagination";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    communitySearchGoToPage,
    communitySearchNextPage,
    communitySearchPreviousPage,
    communitySearchSetPageSize,
} from "store/actions/communities";
import { CommunitySearchInstance } from "store/reducers/community-search";
import { ApplicationState } from "store/state";

interface CommunitySearchPaginationProps {
    searchKey: string;
    total?: number;
}

export const CommunitySearchPagination: React.FC<
    CommunitySearchPaginationProps
> = props => {
    const { searchKey, total } = props;
    const dispatch = useDispatch();
    const searchParams: CommunitySearchInstance = useSelector(
        (appState: ApplicationState) => appState.communitySearch.searches[searchKey],
    );
    const handleGoToPage = useCallback(
        (pageNumber: number) => {
            dispatch(communitySearchGoToPage(searchKey, pageNumber));
        },
        [dispatch, searchKey],
    );

    const nextPage = useCallback(() => {
        dispatch(communitySearchNextPage(searchKey));
    }, [dispatch, searchKey]);

    const previousPage = useCallback(() => {
        dispatch(communitySearchPreviousPage(searchKey));
    }, [dispatch, searchKey]);

    const setPageSize = useCallback(
        (pageSize: number) => {
            dispatch(communitySearchSetPageSize(searchKey, pageSize));
        },
        [dispatch, searchKey],
    );
    if (!searchParams) {
        return null;
    }
    const pageCount = Math.ceil((total ?? 0) / (searchParams.perPage ?? 1));
    const canNextPage = searchParams.pageNumber < pageCount - 1;
    const canPreviousPage = searchParams.pageNumber > 0;
    return (
        <CrmTablePagination
            allowAll={false}
            setPageSize={setPageSize}
            gotoPage={handleGoToPage}
            pageSize={searchParams.perPage}
            count={total ?? 0}
            canNextPage={canNextPage}
            canPreviousPage={canPreviousPage}
            pageIndex={searchParams.pageNumber}
            pageCount={pageCount}
            nextPage={nextPage}
            previousPage={previousPage}
        />
    );
};
