import { XMarkIcon } from "@heroicons/react/20/solid";
import { CalendarDateTime, formatReminderDate, Reminder } from "@sp-crm/core";
import { Checkbox } from "components/ui/checkbox";
import { defaultLinkStyle } from "components/ui/link";
import moment from "moment";
import * as React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { handleEvent } from "util/user-events";
import { useReminderContext } from "../../contexts/reminder";

export const Reminders: React.FunctionComponent = () => {
    const { isReminderVisible, reminders, setVisible, completeTask } =
        useReminderContext();

    return (
        <>
            {isReminderVisible && (
                <RemindersPopup
                    reminders={reminders}
                    setVisible={setVisible}
                    completeTask={completeTask}
                />
            )}
        </>
    );
};

export const RemindersPopup: React.FC<{
    reminders: Reminder[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    setVisible: (arg: boolean) => any;
    completeTask: (reminder: Reminder) => void;
}> = ({ reminders, setVisible, completeTask }) => {
    const headerHeight = document
        .getElementById("header")
        ?.getBoundingClientRect().height;

    return (
        <div
            className="reminders reminders-container"
            style={{ top: headerHeight + 8 + "px" }}>
            <button
                className="btn-close extra-space short-icon-button"
                type="button"
                onClick={() => setVisible(false)}>
                <XMarkIcon className="w-6 h-6" />
            </button>

            <RemindersPopupContent
                reminders={reminders}
                maxHeight={window.innerHeight - headerHeight - 100}
                completeTask={completeTask}
            />
        </div>
    );
};

const ReminderItem: React.FC<{
    reminder: Reminder;
    completeTask: (reminder: Reminder) => void;
}> = ({ reminder, completeTask }) => {
    const [dismissTimeout, setDismissTimeout] = useState(null);

    const taskDueTime = moment(reminder.when());
    const isDanger = taskDueTime.isBefore(moment().subtract(60, "minute"));

    const onToggleCheckbox = (isChecked: boolean) => {
        // this gives the user 1.5 seconds to uncheck the checkbox if they
        // accidentally clicked the wrong task to dismiss.
        if (isChecked) {
            setDismissTimeout(
                setTimeout(() => {
                    handleEvent("task-complete", { source: "reminders" });
                    completeTask(reminder);
                    setDismissTimeout(null);
                }, 1500),
            );
        } else {
            clearTimeout(dismissTimeout);
            setDismissTimeout(null);
        }
    };

    return (
        <div className="reminder" data-testid="reminder-item" key={reminder.id}>
            <div>
                <Checkbox
                    onChange={e => onToggleCheckbox(e.target.checked)}
                    checked={dismissTimeout !== null}
                />
            </div>
            <div className="reminder-info">
                <p className="reminder-title">{reminder.title}</p>
                <Link
                    to={reminder.parentHref}
                    className={"reminder-for " + defaultLinkStyle}>
                    {reminder.parentName}
                </Link>
            </div>
            <div className={`reminder-time ${isDanger ? "danger" : ""}`}>
                {formatReminderDate(
                    new CalendarDateTime(reminder.dueDate || reminder.dueDateTime),
                )}
            </div>
        </div>
    );
};

export const RemindersPopupContent: React.FC<{
    reminders: Reminder[];
    maxHeight: number;
    completeTask: (reminder: Reminder) => void;
}> = ({ reminders, maxHeight, completeTask }) => {
    // Change this one to set number of visible reminders
    const visibleTasks = Math.min(
        6, // Max views in bigger screens
        Math.floor((maxHeight - 50) / 65) - 2, // 50: reminder header size: 65: reminder item size
    );

    // OverDue reminders with time
    const overDueReminders = reminders
        .filter(reminder => moment(reminder.when()).isSameOrBefore(new Date()))
        .sort((a, b) => a.when().getTime() - b.when().getTime());
    const visibleOverDueReminders = overDueReminders.slice(0, visibleTasks);

    const moreOverDueReminders = overDueReminders.length - visibleOverDueReminders.length;

    const dueTodayReminders = reminders.filter(
        reminder => reminder.dueDate && moment(reminder.when()).isSame(new Date(), "day"),
    );
    const visibleDueTodayReminders = dueTodayReminders.slice(
        0,
        visibleTasks - visibleOverDueReminders.length,
    );
    const moreDueTodayReminders =
        dueTodayReminders.length - visibleDueTodayReminders.length;

    // Late today reminders
    const laterTodayReminders = reminders
        .filter(
            reminder =>
                reminder.dueDateTime && moment(reminder.when()).isAfter(new Date()),
        )
        .sort((a, b) => a.when().getTime() - b.when().getTime());
    const visibleLaterTodayReminders = laterTodayReminders.slice(
        0,
        visibleTasks - visibleOverDueReminders.length - visibleDueTodayReminders.length,
    );
    const moreLaterTodayReminders =
        laterTodayReminders.length - visibleLaterTodayReminders.length;

    const moreString = [
        moreOverDueReminders + moreDueTodayReminders > 0 &&
            `${moreOverDueReminders + moreDueTodayReminders} more already due`,
        moreLaterTodayReminders > 0 && `${moreLaterTodayReminders} more later today`,
    ]
        .filter(string => !!string)
        .join(" & ");

    if (reminders.length === 0) {
        return <ReminderHeader title="No more tasks due today" />;
    }

    return (
        <>
            {/* Reminders */}
            {visibleOverDueReminders.length + visibleDueTodayReminders.length > 0 && (
                <>
                    <ReminderHeader
                        title="Reminders"
                        count={overDueReminders.length + dueTodayReminders.length}
                    />
                    {visibleOverDueReminders.map(task => (
                        <ReminderItem
                            key={task.id}
                            reminder={task}
                            completeTask={completeTask}
                        />
                    ))}
                    {visibleDueTodayReminders.map(task => (
                        <ReminderItem
                            key={task.id}
                            reminder={task}
                            completeTask={completeTask}
                        />
                    ))}
                </>
            )}
            {/* Later today reminders */}
            {visibleLaterTodayReminders.length > 0 && (
                <>
                    <ReminderHeader
                        title="Later today"
                        count={laterTodayReminders.length}
                    />
                    {visibleLaterTodayReminders.map(task => (
                        <ReminderItem
                            key={task.id}
                            reminder={task}
                            completeTask={completeTask}
                        />
                    ))}
                </>
            )}
            {/* If we need to show more */}
            {reminders.length > visibleTasks && (
                <MoreReminders
                    title={moreString}
                    count={
                        overDueReminders.length +
                        dueTodayReminders.length +
                        laterTodayReminders.length -
                        visibleTasks
                    }
                />
            )}
        </>
    );
};

const ReminderHeader: React.FC<{ title: string; count?: number }> = ({
    title,
    count = 0,
}) => {
    return (
        <div className="reminder-header">
            <span className="reminder-title">{title}</span>
            &nbsp;
            {count > 0 && <span>({count})</span>}
        </div>
    );
};

const MoreReminders: React.FC<{ title: string; count: number }> = ({ title, count }) => {
    return (
        <div className="reminder-header">
            <div>
                <span className="reminder-title">More</span>
                &nbsp;
                {count > 0 && <span>({count})</span>}
            </div>
            <div>
                <Link to="/tasks">{title}</Link>
            </div>
        </div>
    );
};
