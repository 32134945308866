import { Bars3Icon, TrashIcon } from "@heroicons/react/24/outline";
import {
    IDetails,
    IQuestion,
    LayoutItemId,
    LayoutSectionId,
    NaryFormat,
    NaryFormatLabels,
    QuestionId,
    QuestionType,
    allQuestionMetadata,
    findRelatedField,
    genEntityId,
} from "@sp-crm/core";
import { EntityFieldPicker } from "components/advanced-search/entity-field-picker";
import { EntityConditionFieldPicker } from "components/advanced-search/types";
import { LayoutItemCustomTitle } from "components/layout/layout-item-custom-title";
import { LayoutItemResult } from "components/layout/layout-items";
import { invalidateLayoutQueriesExcept } from "components/layout/layout-query-helpers";
import { Checkbox } from "components/ui/checkbox";
import { SecondaryButton } from "components/ui/secondary-button";
import { Select } from "components/ui/select";
import { produce } from "immer";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { stableQueryOptions } from "util/requests";
import {
    BridgeEntityResult,
    BridgeFieldResult,
    BridgeRelationResult,
    GetLayoutSectionQuery,
    LayoutItemInput,
    useGetEntitiesQuery,
    useGetLayoutSectionQuery,
    usePatchLayoutItemMutation,
    usePatchLayoutSectionMutation,
} from "../../generated/graphql";
import {
    calculateNewOrder,
    useReorderableListDrag,
    useReorderableListDrop,
} from "../../util/dnd";
import { QueryRenderer } from "../clients/show-client/community-comparison/query-renderer";

interface LayoutQuestionOrFieldName {
    questionId?: QuestionId | null;
    entityFieldName?: string | null;
}

interface LayoutSectionItemSettingsProps {
    questions: IQuestion[];
    questionFilter: (question: IQuestion) => boolean;
    questionTitle: (question: IQuestion) => string;
    categoryTitle: (detail: IDetails) => string;
    categoryFilter: (detail: IDetails) => boolean;
    layoutSectionId: LayoutSectionId;
    label?: string;
    mode: "alwaysEditing" | "toggleEdit";
    enableShowWhenEmpty: boolean;
    enableNaryFormat: boolean;
    entityMetadataName: BridgeEntityResult["name"];
    includeStandardFields: boolean;
    relations: string[];
    allowEditFields: boolean;
    allowEditItemTitles: boolean;
}

interface LayoutItemProps {
    item: LayoutItemResult;
    onLayoutItemChange: (
        itemId: LayoutItemId,
        selection: LayoutQuestionOrFieldName,
        showIfBlank: boolean,
        naryFormat: NaryFormat | null,
        customTitle: string | null,
    ) => void;
    onLayoutItemPatched: (item: LayoutItemResult) => void;
    onDelete: (itemId: LayoutItemId) => void;
    questions: IQuestion[];
    questionFilter: (question: IQuestion) => boolean;
    questionTitle: (question: IQuestion) => string;
    categoryTitle: (detail: IDetails) => string;
    categoryFilter: (detail: IDetails) => boolean;
    index: number;
    reorder: (draggingIndex: number, targetIndex: number) => void;
    commitOrder: () => void;
    revertOrder: () => void;
    layoutSectionId: LayoutSectionId;
    enableShowWhenEmpty: boolean;
    enableNaryFormat: boolean;
    entityMetadata: BridgeEntityResult;
    entityMetadataList: BridgeEntityResult[];
    includeStandardFields: boolean;
    relations: string[];
    enableCustomTitle: boolean;
}

const LayoutItem: React.FC<LayoutItemProps> = props => {
    const {
        item,
        questions,
        questionFilter,
        onLayoutItemChange,
        onLayoutItemPatched,
        onDelete,
        categoryFilter,
        index,
        commitOrder,
        revertOrder,
        reorder,
        layoutSectionId,
        entityMetadata,
        enableShowWhenEmpty,
        enableNaryFormat,
        includeStandardFields,
        entityMetadataList,
        relations,
        enableCustomTitle,
    } = props;
    const ref = React.useRef<HTMLLIElement>(null);
    const patchLayoutItem = usePatchLayoutItemMutation();
    const type = `layout-item-${layoutSectionId}`;
    const [{ isDragging }, drag, preview] = useReorderableListDrag({
        type,
        index,
        commitOrder,
        revertOrder,
        id: item.id,
    });
    // eslint-disable-next-line no-empty-pattern
    const [{}, drop] = useReorderableListDrop({
        ref,
        index,
        reorder,
        type,
    });
    drop(ref);

    const allowSettingNaryFormat =
        enableNaryFormat &&
        questions.find(q => q.id === item.questionId)?.questionType === QuestionType.nary;

    const naryFormat = item.naryFormat ?? NaryFormat.SemicolonSeparated;

    const exclusions: EntityConditionFieldPicker[] = useMemo(() => {
        const result: EntityConditionFieldPicker[] = [];

        const availableCategories = allQuestionMetadata().filter(d => categoryFilter(d));

        const questionsNotInFilter = (questions || [])
            .filter(
                q =>
                    !questionFilter(q) ||
                    !availableCategories.some(d => d.category === q.category),
            )
            .map(q => ({ questionId: q.id }));

        result.push(...questionsNotInFilter);

        const excludedStandardFields = entityMetadata.fields
            .filter(f => {
                if (!includeStandardFields) {
                    return true;
                }

                if (!availableCategories.some(d => d.category === f.category)) {
                    return true;
                }

                return false;
            })
            .map(f => ({ fieldName: f.name }));

        result.push(...excludedStandardFields);

        const excludedRelations = entityMetadata.relations
            .filter(r => !relations.some(ir => ir === r.name))
            .map(r => ({
                relation: { name: r.name },
            }));

        result.push(...excludedRelations);

        return result;
    }, [
        questions,
        includeStandardFields,
        entityMetadata,
        questionFilter,
        categoryFilter,
        relations,
    ]);

    const visible = useMemo(() => {
        if (!item.questionId) {
            return true;
        }

        return questions.some(q => q.id === item.questionId);
    }, [item, questions]);

    const editable = useMemo(() => {
        if (!item.questionId) {
            return true;
        }

        return questions.some(q => q.id === item.questionId && q.editable);
    }, [item, questions]);

    const handleCustomTitleChange = useCallback(
        async (id: LayoutItemId, newTitle: string | null) => {
            const response = await patchLayoutItem.mutateAsync({
                params: {
                    id: item.id,
                    customTitle: newTitle,
                },
            });
            onLayoutItemPatched(response.patchLayoutItem);
        },
        [item.id, onLayoutItemPatched, patchLayoutItem],
    );

    if (!visible) {
        return null;
    }

    return (
        <li className="pt-3" ref={ref}>
            <div
                ref={preview}
                className={`flex items-center ${isDragging ? "opacity-0" : ""}`}>
                {editable ? (
                    <div className="cursor-move px-1 py-3" ref={drag}>
                        <Bars3Icon className="w-4 h-4 text-gray-500" />
                    </div>
                ) : (
                    <div className="px-1 py-3">
                        <div className="w-4 h-4" />
                    </div>
                )}
                <div className="flex-1 pr-4">
                    <EntityFieldPicker
                        entityMetadata={entityMetadata}
                        entityMetadataList={entityMetadataList}
                        value={{
                            questionId: item.questionId,
                            fieldName: item.entityFieldName,
                            relation: null,
                        }}
                        onChange={selection =>
                            onLayoutItemChange(
                                item.id,
                                {
                                    questionId: selection.questionId,
                                    entityFieldName: selection.fieldName,
                                },
                                item.showIfBlank,
                                item.naryFormat,
                                null,
                            )
                        }
                        allowEmpty={false}
                        includeSummaryFields={false}
                        textSize="sm"
                        exclude={exclusions}
                        relationMode="expanded"
                        disabled={!editable}
                    />
                </div>
                {enableCustomTitle ? (
                    <div className="flex-1 mr-2">
                        <LayoutItemCustomTitle
                            entityMetadata={entityMetadata}
                            entityMetadataList={entityMetadataList}
                            questions={questions}
                            layoutItem={item}
                            onTitleChange={handleCustomTitleChange}
                        />
                    </div>
                ) : null}
                {enableShowWhenEmpty ? (
                    <div className="flex-0 mr-2">
                        <Checkbox
                            disabled={!editable}
                            label="Show when empty"
                            checked={item.showIfBlank}
                            onChange={e =>
                                onLayoutItemChange(
                                    item.id,
                                    {
                                        questionId: item.questionId,
                                        entityFieldName: item.entityFieldName,
                                    },
                                    e.target.checked,
                                    item.naryFormat,
                                    item.customTitle,
                                )
                            }
                        />
                    </div>
                ) : null}
                {allowSettingNaryFormat ? (
                    <div className="flex-0 mr-2">
                        <Select
                            disabled={!editable}
                            textSize="sm"
                            value={naryFormat}
                            onChange={e =>
                                onLayoutItemChange(
                                    item.id,
                                    {
                                        questionId: item.questionId,
                                        entityFieldName: item.entityFieldName,
                                    },
                                    item.showIfBlank,
                                    e.target.value as NaryFormat,
                                    item.customTitle,
                                )
                            }>
                            {Object.values(NaryFormat).map(naryFormat => (
                                <option key={naryFormat} value={naryFormat}>
                                    {NaryFormatLabels[naryFormat]}
                                </option>
                            ))}
                        </Select>
                    </div>
                ) : null}
                {editable ? (
                    <button onClick={() => onDelete(item.id)} className="cursor-pointer">
                        <TrashIcon className="w-6 h-6 -m-1 p-1 rounded hover:bg-gray-100 transition-all duration-100 ease-in-out" />
                    </button>
                ) : (
                    <div className="w-6 h-6 -m-1 p-1" />
                )}
            </div>
        </li>
    );
};

interface ReadonlyLayoutItemProps {
    item: LayoutItemResult;
    questions: IQuestion[];
    questionTitle: (question: IQuestion) => string;
    entityMetadata: BridgeEntityResult;
    entityMetadataList: BridgeEntityResult[];
}

const ReadonlyLayoutItem: React.FC<ReadonlyLayoutItemProps> = props => {
    const { item, questions, entityMetadata, questionTitle, entityMetadataList } = props;

    let label = "";

    if (item.questionId) {
        const question = questions.find(q => q.id === item.questionId);
        if (!question) {
            return null;
        }
        label = questionTitle(question);
    } else if (item.entityFieldName) {
        const found = findRelatedField(
            item.entityFieldName,
            entityMetadata,
            entityMetadataList,
        );

        if (!found) {
            return null;
        }
        label = found.title;
    }

    return <p className="ml-2 my-1">{label}</p>;
};

export const LayoutSectionItemSettings: React.FC<
    LayoutSectionItemSettingsProps
> = props => {
    const {
        layoutSectionId,
        label,
        questions,
        questionTitle,
        questionFilter,
        categoryTitle,
        categoryFilter,
        mode,
        enableShowWhenEmpty,
        enableNaryFormat,
        entityMetadataName,
        includeStandardFields,
        relations,
        allowEditFields,
        allowEditItemTitles,
    } = props;

    const client = useQueryClient();
    const layoutSectionQuery = useGetLayoutSectionQuery({ id: layoutSectionId });
    const entitiesQuery = useGetEntitiesQuery({}, stableQueryOptions());
    const patchLayoutSection = usePatchLayoutSectionMutation({
        onSuccess: () => {
            invalidateLayoutQueriesExcept(client, ["getLayoutSection"]);
        },
    });

    const getAvailableFields = useCallback(
        (entityMetadata: BridgeEntityResult) => {
            const questionCategories = allQuestionMetadata().filter(categoryFilter);
            let availableStandardFields: BridgeFieldResult[] = [];
            let availableQuestions: IQuestion[] = [];
            const availableRelations: BridgeRelationResult[] =
                entityMetadata.relations.filter(entityRelation =>
                    relations.some(relationName => relationName === entityRelation.name),
                );

            if (questionCategories.length > 0) {
                for (const questionCategory of questionCategories) {
                    const category = questionCategory.category;
                    const categoryStandardFields = includeStandardFields
                        ? entityMetadata.fields.filter(f => f.category === category)
                        : [];
                    const categoryQuestions = questions.filter(
                        q => questionFilter(q) && q.category === category,
                    );

                    availableStandardFields = [
                        ...availableStandardFields,
                        ...categoryStandardFields,
                    ];
                    availableQuestions = [...availableQuestions, ...categoryQuestions];
                }
            }

            for (const availableRelation of availableRelations) {
                const relatedEntity = entitiesQuery.data?.getEntities.find(
                    e => e.name === availableRelation.otherEntityName,
                );
                if (!relatedEntity) {
                    continue;
                }

                for (const field of relatedEntity.fields) {
                    availableStandardFields.push({
                        ...field,
                        name: `${availableRelation.name}.${field.name}`,
                    });
                }
            }

            return {
                standardFields: availableStandardFields,
                questions: availableQuestions,
            };
        },
        [
            categoryFilter,
            entitiesQuery.data?.getEntities,
            includeStandardFields,
            questionFilter,
            questions,
            relations,
        ],
    );

    const makeNewItem = useCallback(
        (order: number, entityMetadata: BridgeEntityResult) => {
            const newItem: LayoutItemInput = {
                id: genEntityId<LayoutItemId>(),
                order,
                questionId: null,
                entityFieldName: null,
                showIfBlank: false,
            };
            const availableFields = getAvailableFields(entityMetadata);
            const standardField = availableFields.standardFields[0];
            const firstQuestion = availableFields.questions[0];

            if (standardField) {
                newItem.entityFieldName = standardField.name;
            } else if (firstQuestion) {
                newItem.questionId = firstQuestion.id;
            }

            return newItem;
        },
        [getAvailableFields],
    );

    const atLeastOneFieldAvailable = (entityMetadata: BridgeEntityResult) => {
        const availableFields = getAvailableFields(entityMetadata);

        return (
            availableFields.standardFields.length > 0 ||
            availableFields.questions.length > 0
        );
    };
    const addField = useCallback(
        async (entityMetadata: BridgeEntityResult) => {
            if (!layoutSectionQuery.data) return;
            if (!layoutSectionQuery.data.getLayoutSection) return;

            const data = client.getQueryData<GetLayoutSectionQuery>([
                "getLayoutSection",
                { id: layoutSectionId },
            ]);

            const items = data.getLayoutSection.layoutItems
                .slice()
                .sort((a, b) => a.order - b.order)
                .map((item, index) => ({ ...item, order: index }));

            const newItem = makeNewItem(items.length, entityMetadata);

            const newLayoutItems = [...items, newItem];
            const setLayoutResult = await patchLayoutSection.mutateAsync({
                params: {
                    id: layoutSectionId,
                    layoutItems: newLayoutItems,
                },
            });
            const getLayoutData = produce(layoutSectionQuery.data, draft => {
                draft.getLayoutSection.layoutItems =
                    setLayoutResult.patchLayoutSection.layoutItems;
            });
            client.setQueryData(
                ["getLayoutSection", { id: layoutSectionId }],
                getLayoutData,
            );
        },
        [layoutSectionQuery, layoutSectionId, patchLayoutSection, client, makeNewItem],
    );

    const updateLayoutItem = useCallback(
        async (
            itemId: LayoutItemId,
            selection: LayoutQuestionOrFieldName,
            showIfBlank: boolean,
            naryFormat: NaryFormat | null,
            customTitle: string | null,
        ) => {
            const data = client.getQueryData<GetLayoutSectionQuery>([
                "getLayoutSection",
                { id: layoutSectionId },
            ]);

            if (!data || !data.getLayoutSection) {
                return;
            }

            const updatedLayout = produce(data, draft => {
                const updatedItem = draft.getLayoutSection.layoutItems.find(
                    i => i.id === itemId,
                );
                if (updatedItem) {
                    updatedItem.questionId = selection.questionId;
                    updatedItem.entityFieldName = selection.entityFieldName;
                    updatedItem.showIfBlank = showIfBlank;
                    updatedItem.naryFormat = naryFormat;
                    updatedItem.customTitle = customTitle;
                }
            });
            const setLayoutResult = await patchLayoutSection.mutateAsync({
                params: {
                    id: layoutSectionId,
                    layoutItems: updatedLayout.getLayoutSection.layoutItems,
                },
            });
            const getLayoutData = produce(layoutSectionQuery.data, draft => {
                draft.getLayoutSection.layoutItems =
                    setLayoutResult.patchLayoutSection.layoutItems;
            });
            client.setQueryData(
                ["getLayoutSection", { id: layoutSectionId }],
                getLayoutData,
            );
        },
        [client, layoutSectionQuery, layoutSectionId, patchLayoutSection],
    );

    const handleLayoutItemPatched = useCallback(
        (item: LayoutItemResult) => {
            const data = client.getQueryData<GetLayoutSectionQuery>([
                "getLayoutSection",
                { id: layoutSectionId },
            ]);

            if (!data || !data.getLayoutSection) {
                return;
            }

            const updatedLayout = produce(data, draft => {
                const replaceIndex = draft.getLayoutSection.layoutItems.findIndex(
                    i => i.id === item.id,
                );

                if (replaceIndex !== -1) {
                    draft.getLayoutSection.layoutItems[replaceIndex] = item;
                }
            });

            client.setQueryData(
                ["getLayoutSection", { id: layoutSectionId }],
                updatedLayout,
            );
            invalidateLayoutQueriesExcept(client, ["getLayoutSection"]);
        },
        [client, layoutSectionId],
    );

    const deleteItem = useCallback(
        async (itemId: LayoutItemId) => {
            const data = client.getQueryData<GetLayoutSectionQuery>([
                "getLayoutSection",
                { id: layoutSectionId },
            ]);

            if (!data || !data.getLayoutSection) {
                return;
            }

            const updatedLayout = produce(data, draft => {
                draft.getLayoutSection.layoutItems =
                    draft.getLayoutSection.layoutItems.filter(i => i.id !== itemId);
            });

            const setLayoutResult = await patchLayoutSection.mutateAsync({
                params: {
                    id: layoutSectionId,
                    layoutItems: updatedLayout.getLayoutSection.layoutItems,
                },
            });
            const getLayoutData = produce(layoutSectionQuery.data, draft => {
                draft.getLayoutSection.layoutItems =
                    setLayoutResult.patchLayoutSection.layoutItems;
            });
            client.setQueryData(
                ["getLayoutSection", { id: layoutSectionId }],
                getLayoutData,
            );
        },
        [client, layoutSectionQuery, layoutSectionId, patchLayoutSection],
    );

    const reorder = useCallback(
        (draggingIndex: number, targetIndex: number) => {
            const data = client.getQueryData<GetLayoutSectionQuery>([
                "getLayoutSection",
                { id: layoutSectionId },
            ]);
            if (!data) {
                return;
            }

            const payload = produce(data, draft => {
                const newOrder = calculateNewOrder(
                    draft.getLayoutSection.layoutItems,
                    draggingIndex,
                    targetIndex,
                );

                const current = draft.getLayoutSection.layoutItems[draggingIndex];
                const currentActualElement = draft.getLayoutSection.layoutItems.find(
                    i => i.id === current.id,
                );
                currentActualElement.order = newOrder;
                draft.getLayoutSection.layoutItems.sort((a, b) => a.order - b.order);
            });

            client.setQueryData(["getLayoutSection", { id: layoutSectionId }], payload);
        },
        [client, layoutSectionId],
    );

    const commitOrder = useCallback(async () => {
        const layoutItems = produce(
            layoutSectionQuery.data.getLayoutSection.layoutItems,
            draft => {
                draft
                    .sort((a, b) => a.order - b.order)
                    .forEach((x, index) => {
                        x.order = index;
                    });
            },
        );
        const result = await patchLayoutSection.mutateAsync({
            params: {
                id: layoutSectionId,
                layoutItems,
            },
        });
        const getLayoutData = produce(layoutSectionQuery.data, draft => {
            draft.getLayoutSection.layoutItems = result.patchLayoutSection.layoutItems;
        });
        client.setQueryData(["getLayoutSection", { id: layoutSectionId }], getLayoutData);
    }, [client, layoutSectionQuery, layoutSectionId, patchLayoutSection]);
    const revertOrder = useCallback(() => {
        layoutSectionQuery.refetch();
    }, [layoutSectionQuery]);
    const [isEditing, setIsEditing] = useState(mode === "alwaysEditing");

    useEffect(() => {
        layoutSectionQuery.refetch();
    }, [questions]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {label ? <div>{label}</div> : null}
            <QueryRenderer
                name={`LayoutSectionItemSettings:${layoutSectionId}`}
                query={layoutSectionQuery}>
                {layoutData => (
                    <QueryRenderer
                        query={entitiesQuery}
                        name="LayoutSectionItemSettings.getEntities">
                        {entities => {
                            const entityMetadata = entities.getEntities.find(
                                e => e.name === entityMetadataName,
                            );
                            if (!atLeastOneFieldAvailable(entityMetadata)) {
                                return null;
                            }
                            return (
                                <>
                                    <ul className={`mb-3 ${isEditing ? "" : "mt-2"}`}>
                                        {layoutData.getLayoutSection.layoutItems.map(
                                            (item, index) =>
                                                isEditing ? (
                                                    <LayoutItem
                                                        key={item.id}
                                                        item={item}
                                                        questions={questions}
                                                        questionTitle={questionTitle}
                                                        questionFilter={questionFilter}
                                                        categoryFilter={categoryFilter}
                                                        categoryTitle={categoryTitle}
                                                        onLayoutItemChange={
                                                            updateLayoutItem
                                                        }
                                                        onLayoutItemPatched={
                                                            handleLayoutItemPatched
                                                        }
                                                        onDelete={deleteItem}
                                                        index={index}
                                                        layoutSectionId={layoutSectionId}
                                                        reorder={reorder}
                                                        commitOrder={commitOrder}
                                                        revertOrder={revertOrder}
                                                        entityMetadata={entityMetadata}
                                                        entityMetadataList={
                                                            entities.getEntities
                                                        }
                                                        enableShowWhenEmpty={
                                                            enableShowWhenEmpty
                                                        }
                                                        enableNaryFormat={
                                                            enableNaryFormat
                                                        }
                                                        enableCustomTitle={
                                                            allowEditItemTitles
                                                        }
                                                        includeStandardFields={
                                                            includeStandardFields
                                                        }
                                                        relations={relations}
                                                    />
                                                ) : (
                                                    <ReadonlyLayoutItem
                                                        key={item.id}
                                                        item={item}
                                                        questions={questions}
                                                        questionTitle={questionTitle}
                                                        entityMetadata={entityMetadata}
                                                        entityMetadataList={
                                                            entities.getEntities
                                                        }
                                                    />
                                                ),
                                        )}
                                    </ul>
                                    {isEditing ? (
                                        <div className="flex">
                                            <SecondaryButton
                                                className="ml-2"
                                                onClick={e => {
                                                    e &&
                                                        e.preventDefault &&
                                                        e.preventDefault();
                                                    addField(entityMetadata);
                                                }}>
                                                <span className="text-sm">Add field</span>
                                            </SecondaryButton>
                                            {mode === "toggleEdit" ? (
                                                <button
                                                    className="ml-2"
                                                    onClick={() => setIsEditing(false)}>
                                                    Done
                                                </button>
                                            ) : null}
                                        </div>
                                    ) : allowEditFields ? (
                                        <button
                                            className="ml-2 text-brand-600"
                                            onClick={() => setIsEditing(true)}>
                                            Place fields
                                        </button>
                                    ) : null}
                                </>
                            );
                        }}
                    </QueryRenderer>
                )}
            </QueryRenderer>
        </>
    );
};
