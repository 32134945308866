import { TertiarynavMenu, TertiarynavMenuItem } from "components/navigation/tertiarynav";
import React from "react";

export const ManageIntegrationsNav: React.FC<unknown> = () => {
    return (
        <TertiarynavMenu>
            <TertiarynavMenuItem to="/settings/integrations/accounts">
                Accounts
            </TertiarynavMenuItem>
            <TertiarynavMenuItem to="/settings/integrations/quickbooks">
                QuickBooks
            </TertiarynavMenuItem>
        </TertiarynavMenu>
    );
};
