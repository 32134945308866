import { Callout } from "components/ui/callout";
import { defaultLinkStyle } from "components/ui/link";
import React from "react";
import { useProductName } from "store/selectors/branding";

export const ManageUsersPermissionsLevels: React.FC<unknown> = () => {
    const productName = useProductName();

    return (
        <Callout
            renderButton={() => (
                <div className={`${defaultLinkStyle} text-sm cursor-pointer`}>
                    Permission levels explained
                </div>
            )}>
            <ul className="list-disc list-outside p-4">
                <li>
                    <div>
                        <strong>Administrator</strong>
                    </div>
                    <div>
                        No limitations (including editing the database settings and doing
                        the CSV export). Can see all clients, communities, and referral
                        sources.
                    </div>
                </li>
                <li>
                    <div>
                        <strong>Basic User</strong>
                    </div>
                    <div>
                        Same as <em>Power User</em>, but cannot use the CSV export
                        functionality, and cannot see some company reports.
                    </div>
                </li>
                <li>
                    <div>
                        <strong>Independent</strong>
                    </div>
                    <div>
                        Can see all communities and referral sources, but can only see
                        their own clients (cannot see anyone else&apos;s clients in the
                        system). Otherwise, same feature access as&nbsp;
                        <em>Basic User</em>.
                    </div>
                </li>
                <li>
                    <div>
                        <strong>Power User</strong>
                    </div>
                    <div>
                        Same as <em>Administrator</em>, but cannot edit the agency-wide
                        settings.
                    </div>
                </li>
                <li>
                    <div>
                        <strong>Restricted</strong>
                    </div>
                    <div>
                        Same as <em>Independent User</em>, except they do not have access
                        to the &quot;Contract Details&quot; section on community profiles.
                    </div>
                </li>
                <li>
                    <div>
                        <strong>Custom</strong>
                    </div>
                    <div>
                        Custom permission set for this user. Available to {productName}{" "}
                        Premium customers.
                    </div>
                </li>
            </ul>
        </Callout>
    );
};
