import { CommunityId } from "@sp-crm/core";
import { Panel } from "components/ui/panel/panel";
import { PanelType } from "components/ui/panel/panel-type";
import React from "react";
import { useRegionId } from "store/selectors/hooks";
import { Upload } from "../../files/upload";

interface Props {
    communityId: CommunityId;
    reloadCommunity: () => void;
}
export const ThumbnailUpload: React.FC<Props> = props => {
    const { communityId, reloadCommunity } = props;
    const [isModalVisible, setModalVisible] = React.useState(false);
    const [successMessage, setSuccess] = React.useState<string | null>(null);
    const [errorMessage, setError] = React.useState<string | null>(null);

    const regionId = useRegionId();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    const showModal = React.useCallback((e: any) => {
        if (e.preventDefault) e.preventDefault();
        setModalVisible(true);
    }, []);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    const hideModal = React.useCallback(() => {
        setModalVisible(false);
    }, []);
    const onComplete = React.useCallback(() => {
        setSuccess("Success");
        setError(null);
        reloadCommunity();
        setTimeout(hideModal, 2 * 1000);
    }, [reloadCommunity, hideModal]);

    return (
        <>
            <div className="flex justify-around items-center">
                <a
                    href="#"
                    className="text-sm text-gray-400 hover:underline"
                    onClick={showModal}>
                    Change photo
                </a>
            </div>
            <Panel
                type={PanelType.large}
                headerText="Upload photo"
                isOpen={isModalVisible}
                onDismiss={hideModal}>
                <Upload
                    onComplete={onComplete}
                    onError={setError}
                    endpoint={`/api/communities/${communityId}/thumbnail`}
                    accept="image/*"
                    regionId={regionId}
                />
                {successMessage ? (
                    <div className="text-green-600">{successMessage}</div>
                ) : null}
                {errorMessage ? <div className="text-red-600">{errorMessage}</div> : null}
            </Panel>
        </>
    );
};
