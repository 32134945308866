import {
    ClipboardDocumentCheckIcon,
    ClipboardDocumentIcon,
    ClipboardDocumentListIcon,
    ClipboardIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { breakNewlines } from "util/text";
import { EntityActivitySummary } from "./details/entity-activity-summary";
import { EntityActivityEntryProps } from "./types";

interface EntityActivityTaskCommonProps extends EntityActivityEntryProps {
    icon: React.ReactNode;
    userLabel: string;
    fallbackLabel: string;
}

const iconStyle = "h-5 w-5 text-gray-500";

const EntityActivityTaskCommon: React.FC<EntityActivityTaskCommonProps> = props => {
    const { activity, userLabel, fallbackLabel, icon } = props;
    return (
        <div className="relative flex items-start space-x-3">
            <div className="relative px-1">
                <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white">
                    {icon}
                </div>
            </div>
            <div className="min-w-0 flex-1 group pt-1">
                <EntityActivitySummary {...props}>{userLabel}</EntityActivitySummary>
                {activity.task ? (
                    <div className="mt-2 text-base text-gray-900">
                        <p>{breakNewlines(activity.task.text)}</p>
                    </div>
                ) : (
                    <div className="mt-2 text-xs text-gray-500 italic">
                        {fallbackLabel}
                    </div>
                )}
            </div>
        </div>
    );
};

export const EntityActivityTaskCreate: React.FC<EntityActivityEntryProps> = props => {
    return (
        <EntityActivityTaskCommon
            {...props}
            userLabel="added a task."
            fallbackLabel="Task created"
            icon={<ClipboardDocumentIcon className={iconStyle} aria-hidden="true" />}
        />
    );
};

export const EntityActivityTaskUpdate: React.FC<EntityActivityEntryProps> = props => {
    return (
        <EntityActivityTaskCommon
            {...props}
            userLabel="updated a task."
            fallbackLabel="Task updated"
            icon={<ClipboardDocumentListIcon className={iconStyle} aria-hidden="true" />}
        />
    );
};

export const EntityActivityTaskDelete: React.FC<EntityActivityEntryProps> = props => {
    return (
        <EntityActivityTaskCommon
            {...props}
            userLabel="deleted a task."
            fallbackLabel="Task deleted"
            icon={<ClipboardIcon className={iconStyle} aria-hidden="true" />}
        />
    );
};

export const EntityActivityTaskComplete: React.FC<EntityActivityEntryProps> = props => {
    return (
        <EntityActivityTaskCommon
            {...props}
            userLabel="completed a task."
            fallbackLabel="Task completed"
            icon={<ClipboardDocumentCheckIcon className={iconStyle} aria-hidden="true" />}
        />
    );
};

export const EntityActivityTaskUncomplete: React.FC<EntityActivityEntryProps> = props => {
    return (
        <EntityActivityTaskCommon
            {...props}
            userLabel="reactivated a task."
            fallbackLabel="Task reactivated"
            icon={<ClipboardDocumentListIcon className={iconStyle} aria-hidden="true" />}
        />
    );
};

export const EntityActivityTaskReassign: React.FC<EntityActivityEntryProps> = props => {
    return (
        <EntityActivityTaskCommon
            {...props}
            userLabel="reassigned a task."
            fallbackLabel="Task reassigned"
            icon={<ClipboardDocumentListIcon className={iconStyle} aria-hidden="true" />}
        />
    );
};
