import {
    CalendarDate,
    CurrencyInCents,
    currencyInCentsToString,
    genEntityId,
    stringToCurrencyInCents,
} from "@sp-crm/core";
import { Radio, RadioOption } from "components/shared/radio";
import { DeleteButton } from "components/ui/action-button";
import { Input } from "components/ui/input";
import { SecondaryButton } from "components/ui/secondary-button";
import { Select } from "components/ui/select";
import {
    InvoicePaymentScheduleInput,
    InvoicePaymentScheduleItemInput,
    InvoicePaymentScheduleItemStatus,
} from "generated/graphql";
import { produce } from "immer";
import React, { useEffect } from "react";

type PaymentMode = "single" | "multiple";

const headerClasses = "p-1 text-sm font-medium text-gray-500";
const cellClasses = "p-1 text-sm align-top";

interface InvoicePaymentScheduleEditorProps {
    paymentSchedule: InvoicePaymentScheduleInput | null;
    onChange?: (paymentSchedule: InvoicePaymentScheduleInput | null) => void;
    dueDate: string;
}

const radioOptions: RadioOption[] = [
    {
        key: "single",
        text: "Single Payment",
        helpText: "This invoice will be paid in full by the invoice due date",
    },
    {
        key: "multiple",
        text: "Multiple Payments",
        helpText: "This invoice will be paid in multiple payments",
    },
];

const newPaymentItem = (dueDate: string): InvoicePaymentScheduleItemInput => ({
    id: genEntityId(),
    amountInCents: 0 as CurrencyInCents,
    dueDate: dueDate ? dueDate : CalendarDate.today.toString(),
    paidDate: null,
    status: InvoicePaymentScheduleItemStatus.Due,
});

export const InvoicePaymentScheduleEditor: React.FC<
    InvoicePaymentScheduleEditorProps
> = props => {
    const { paymentSchedule, onChange, dueDate } = props;
    const [paymentMode, setPaymentMode] = React.useState<PaymentMode>(
        paymentSchedule ? "multiple" : "single",
    );
    const [lastMultiPaymentSchedule, setLastMultiPaymentSchedule] =
        React.useState(paymentSchedule);

    const updatePaymentItem = React.useCallback(
        (paymentItem: InvoicePaymentScheduleItemInput) => {
            if (!paymentSchedule) {
                return;
            }

            const newPaymentSchedule = produce(paymentSchedule, draft => {
                const index = draft.items.findIndex(x => x.id === paymentItem.id);
                if (index === -1) {
                    return;
                }
                draft.items[index] = paymentItem;
            });
            onChange(newPaymentSchedule);
        },
        [onChange, paymentSchedule],
    );

    const removePaymentItem = React.useCallback(
        (id: string) => {
            if (!paymentSchedule) {
                return;
            }

            const newPaymentSchedule = produce(paymentSchedule, draft => {
                draft.items = draft.items.filter(x => x.id !== id);
            });
            onChange(newPaymentSchedule);
        },
        [onChange, paymentSchedule],
    );

    const handleAddItem = React.useCallback(
        (e: React.MouseEvent) => {
            e.preventDefault();

            const newPaymentSchedule = produce(
                paymentSchedule || { items: [] },
                draft => {
                    draft.items.push(newPaymentItem(dueDate));
                },
            );
            onChange(newPaymentSchedule);
        },
        [onChange, paymentSchedule, dueDate],
    );

    useEffect(() => {
        if (onChange) {
            if (paymentMode === "single") {
                onChange(null);
            } else {
                onChange(
                    lastMultiPaymentSchedule || { items: [newPaymentItem(dueDate)] },
                );
            }
        }
    }, [paymentMode]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (paymentSchedule) {
            setLastMultiPaymentSchedule(paymentSchedule);
        }
    }, [paymentSchedule]);

    return (
        <div>
            {onChange ? (
                <Radio
                    label="Payment Schedule"
                    options={radioOptions}
                    value={paymentMode}
                    onChange={v => {
                        setPaymentMode(v as PaymentMode);
                    }}
                />
            ) : null}
            {paymentMode === "multiple" ? (
                <div className="pl-6 mt-1">
                    <table className="min-w-full">
                        <thead className="text-sm">
                            <tr>
                                <th className={`${headerClasses} text-left`}>Amount</th>
                                <th className={`text-left w-48 ${headerClasses}`}>
                                    Due Date
                                </th>

                                <th className={`${headerClasses} text-left`}>Status</th>
                                <th className={`${headerClasses} text-left w-48`}>
                                    Paid Date
                                </th>
                                <th className="w-8"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {paymentSchedule?.items.map(paymentItem => (
                                <InvoicePaymentScheduleItemEditor
                                    key={paymentItem.id}
                                    paymentItem={paymentItem}
                                    onChange={onChange ? updatePaymentItem : null}
                                    onDelete={
                                        onChange
                                            ? () => {
                                                  removePaymentItem(paymentItem.id);
                                              }
                                            : null
                                    }
                                />
                            ))}
                        </tbody>
                    </table>
                    {onChange ? (
                        <SecondaryButton className="mt-2 ml-1" onClick={handleAddItem}>
                            <span className="text-sm">Add payment</span>
                        </SecondaryButton>
                    ) : null}
                </div>
            ) : null}
        </div>
    );
};

interface InvoicePaymentScheduleItemEditorProps {
    paymentItem: InvoicePaymentScheduleItemInput;
    onChange?: ((paymentItem: InvoicePaymentScheduleItemInput) => void) | null;
    onDelete?: (() => void) | null;
}

const InvoicePaymentScheduleItemEditor: React.FC<
    InvoicePaymentScheduleItemEditorProps
> = props => {
    const { paymentItem, onChange, onDelete } = props;

    const [amountFieldValue, setAmountFieldValue] = React.useState(
        currencyInCentsToString(paymentItem.amountInCents),
    );

    return (
        <tr>
            <td className={`${cellClasses} text-left`}>
                <div className="flex items-center -ml-3">
                    <span className="mr-1 text-gray-500">$</span>
                    <Input
                        className="w-24"
                        textSize="sm"
                        type="number"
                        value={amountFieldValue}
                        disabled={!onChange}
                        onChange={e => {
                            if (onChange) {
                                setAmountFieldValue(e.target.value);
                                onChange({
                                    ...paymentItem,
                                    amountInCents: stringToCurrencyInCents(
                                        e.target.value,
                                    ),
                                });
                            }
                        }}
                    />
                </div>
            </td>
            <td className={`text-left w-48 pr-4 ${cellClasses}`}>
                <Input
                    type="date"
                    textSize="sm"
                    value={paymentItem.dueDate}
                    disabled={!onChange}
                    onChange={e => {
                        if (onChange) {
                            onChange({
                                ...paymentItem,
                                dueDate: e.target.value,
                            });
                        }
                    }}
                />
            </td>
            <td className={`${cellClasses} text-left`}>
                <Select
                    textSize="sm"
                    value={paymentItem.status}
                    disabled={!onChange}
                    onChange={e => {
                        if (onChange) {
                            onChange({
                                ...paymentItem,
                                status: e.target
                                    .value as InvoicePaymentScheduleItemStatus,
                            });
                        }
                    }}>
                    {Object.values(InvoicePaymentScheduleItemStatus).map(status => (
                        <option key={status} value={status}>
                            {status}
                        </option>
                    ))}
                </Select>
            </td>
            <td className={`text-left w-48 pr-4 ${cellClasses}`}>
                <Input
                    type="date"
                    textSize="sm"
                    value={paymentItem.paidDate}
                    disabled={!onChange}
                    onChange={e => {
                        if (onChange) {
                            onChange({
                                ...paymentItem,
                                paidDate: e.target.value,
                            });
                        }
                    }}
                />
            </td>
            <td className={`${cellClasses} w-8 pt-2.5`}>
                {onDelete ? (
                    <DeleteButton
                        backgroundColor="bg-white"
                        size="sm"
                        onClick={() => {
                            onDelete();
                        }}
                    />
                ) : null}
            </td>
        </tr>
    );
};
