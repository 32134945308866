import { LinkIcon } from "@heroicons/react/24/outline";
import { AdditionalClientContact, ClientId, ContactId } from "@sp-crm/core";
import { ContactCard } from "components/contacts/card";
import { ReferenceContactCard } from "components/references/reference-contact-card";
import { DeleteButton, EditButton } from "components/ui/action-button";
import { AdvancedSearchEntityType } from "generated/graphql";
import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";

type ContactProps = {
    clientId: ClientId;
    contact: AdditionalClientContact;
    editContact: (contactId: ContactId) => void;
    deleteContact: (contactId: ContactId) => void;
};

const Contact: FunctionComponent<ContactProps> = ({
    clientId,
    contact,
    editContact,
    deleteContact,
}) => {
    return contact.referenceId ? (
        <div className="displayed-contact">
            <ReferenceContactCard
                referenceId={contact.referenceId}
                parentEntityId={clientId}
                parentEntityType={AdvancedSearchEntityType.Client}
            />
            <div>
                <div className="flex items-center">
                    <Link
                        className="block"
                        to={`/references/contacts/show/${contact.referenceId}`}>
                        <LinkIcon className="w-4 h-4" />
                    </Link>
                    <DeleteButton
                        backgroundColor="bg-white"
                        onClick={() => deleteContact(contact.id)}
                        confirm={{
                            title: "Remove contact?",
                            message: "Are you sure you want to remove this contact?",
                        }}
                    />
                </div>
            </div>
        </div>
    ) : (
        <div className="displayed-contact">
            <ContactCard
                contact={contact}
                parentEntityType={AdvancedSearchEntityType.Client}
                parentId={clientId}
            />
            <div className="contact-icon-buttons text-gray-500">
                {contact.referenceId ? null : (
                    <EditButton
                        backgroundColor="bg-white"
                        onClick={() => editContact(contact.id)}
                    />
                )}
                <DeleteButton
                    backgroundColor="bg-white"
                    confirm={{
                        title: "Remove contact?",
                        message: `Are you sure you want to remove ${
                            contact.name ?? "(no name)"
                        }?`,
                    }}
                    onClick={() => deleteContact(contact.id)}
                />
            </div>
        </div>
    );
};

export default Contact;
