import {
    Community,
    CommunityId,
    ContactId,
    ReferenceBusinessId,
    ReferenceContactId,
} from "@sp-crm/core";
import {
    AdvancedSearchResults,
    RenderRowActionParams,
} from "components/advanced-search/advanced-search-results";
import { SearchInputDebounced } from "components/ui/search-input-debounced";
import { SecondaryButton } from "components/ui/secondary-button";
import { produce } from "immer";
import React, { useState } from "react";
import { useRegionId } from "store/selectors/hooks";
import {
    AdvancedSearchConditionNodeType,
    AdvancedSearchEntityType,
    AdvancedSearchRequest,
    ContactEntity,
    FieldConditionOperator,
    ReferenceBusiness,
} from "../../../generated/graphql";

export interface SelectedReferenceContact {
    referenceContactId: ReferenceContactId;
    businessId: ReferenceBusinessId | null;
    communityId: CommunityId | null;
    contactId: ContactId;
}

interface ReferenceContactPickerProps {
    onSelect: (selected: SelectedReferenceContact) => void;
}

export const ReferenceContactPicker: React.FC<ReferenceContactPickerProps> = props => {
    const { onSelect } = props;

    const regionId = useRegionId();

    const initialSearchRequest: AdvancedSearchRequest = {
        entityType: AdvancedSearchEntityType.ReferenceContact,
        select: [
            {
                title: "Name",
                fieldName: "contactEntity.name",
            },
            {
                title: "Business",
                fieldName: "business.businessContactEntity.name",
            },
            { fieldName: "community.name" },
        ].filter(s => !!s),
        sort: { column: "contactEntity.name", ascending: true },
        condition: {
            nodeType: AdvancedSearchConditionNodeType.And,
            children: [
                {
                    nodeType: AdvancedSearchConditionNodeType.Or,
                    children: [
                        {
                            nodeType: AdvancedSearchConditionNodeType.RelationCondition,
                            fieldName: "contactEntity",
                            children: [
                                {
                                    nodeType:
                                        AdvancedSearchConditionNodeType.FieldCondition,
                                    fieldName: "name",
                                    operator: FieldConditionOperator.StringContains,
                                    textValue: "",
                                },
                            ],
                        },
                        {
                            nodeType: AdvancedSearchConditionNodeType.RelationCondition,
                            fieldName: "business",
                            children: [
                                {
                                    nodeType:
                                        AdvancedSearchConditionNodeType.RelationCondition,
                                    fieldName: "businessContactEntity",
                                    children: [
                                        {
                                            nodeType:
                                                AdvancedSearchConditionNodeType.FieldCondition,
                                            fieldName: "name",
                                            operator:
                                                FieldConditionOperator.StringContains,
                                            textValue: "",
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            nodeType: AdvancedSearchConditionNodeType.RelationCondition,
                            fieldName: "community",
                            children: [
                                {
                                    nodeType:
                                        AdvancedSearchConditionNodeType.FieldCondition,
                                    fieldName: "name",
                                    operator: FieldConditionOperator.StringContains,
                                    textValue: "",
                                },
                            ],
                        },
                    ],
                },
                {
                    nodeType: AdvancedSearchConditionNodeType.FieldCondition,
                    operator: FieldConditionOperator.StringEquals,
                    fieldName: "regionId",
                    textValue: regionId,
                },
            ],
        },
    };

    const [searchRequest, setSearchRequest] =
        useState<AdvancedSearchRequest>(initialSearchRequest);

    const handleSearchInput = React.useCallback(
        (value: string) => {
            setSearchRequest(prev =>
                produce(prev, draft => {
                    draft.condition.children[0].children[0].children[0].textValue = value;
                    draft.condition.children[0].children[1].children[0].children[0].textValue =
                        value;
                    draft.condition.children[0].children[2].children[0].textValue = value;
                }),
            );
        },
        [setSearchRequest],
    );

    const renderRowAction = React.useCallback(
        (params: RenderRowActionParams) => {
            return (
                <SecondaryButton
                    className="text-sm"
                    onClick={e => {
                        e.preventDefault();
                        let businessId: ReferenceBusinessId = null;
                        let communityId: CommunityId = null;
                        let contactId: ContactId = null;
                        if (
                            typeof params.record.business === "object" &&
                            params.record.business
                        ) {
                            const business = params.record.business as ReferenceBusiness;
                            businessId = business.id;
                        }
                        if (
                            typeof params.record.community === "object" &&
                            params.record.community
                        ) {
                            const community = params.record.community as Community;
                            communityId = community.id;
                        }
                        if (
                            typeof params.record.contactEntity === "object" &&
                            params.record.contactEntity
                        ) {
                            const contact = params.record.contactEntity as ContactEntity;
                            contactId = contact.id;
                        }

                        onSelect({
                            referenceContactId: params.record.id as ReferenceContactId,
                            contactId,
                            businessId,
                            communityId,
                        });
                    }}>
                    Select
                </SecondaryButton>
            );
        },
        [onSelect],
    );

    return (
        <div>
            <SearchInputDebounced
                initial=""
                onChange={handleSearchInput}
                placeholder="Search for contact"
                autoFocus
            />
            <AdvancedSearchResults
                request={searchRequest}
                onChangeRequest={setSearchRequest}
                renderRowAction={renderRowAction}
            />
        </div>
    );
};
