import { LayoutSectionKey, LayoutSectionParentKey } from "@sp-crm/core";
import { ContentFull, ContentHeader } from "components/layout";
import { SelectPro } from "components/ui/select-pro";
import {
    AdvancedSearchEntityType,
    useGetEntitiesQuery,
    useGetLayoutSectionsQuery,
} from "generated/graphql";
import React from "react";
import { useBridgeQuestions } from "store/selectors/bridge";
import { useTenantSettings } from "store/selectors/hooks";
import { stableQueryOptions } from "util/requests";
import { getStatesArray } from "util/states";
import { LayoutSectionItemSettings } from "../layout-section-item-settings";
import { LayoutSettings } from "../layout-settings";
import { AgencyCheckboxPref } from "./agency-settings-checkbox";
import { useCommitTenantSetting } from "./agency-settings-hooks";
import { EntitySettings } from "./customize/entity-settings";

export const AgencySettingsCommunities: React.FC<unknown> = () => {
    const updateDefaultState = useCommitTenantSetting("defaultState");
    const settings = useTenantSettings();
    const questions = useBridgeQuestions(AdvancedSearchEntityType.Community);
    const layoutSections = useGetLayoutSectionsQuery(
        { parentKey: LayoutSectionParentKey.CommunityCard },
        stableQueryOptions(),
    );
    const entityTypes = useGetEntitiesQuery({}, stableQueryOptions());

    const communityCardSectionId =
        layoutSections.data?.getLayoutSections?.layoutSections?.find(
            s => s.sectionKey === LayoutSectionKey.CommunityCardKeyAttributes,
        )?.id;

    return (
        <div className="space-y-4 lg:space-y-8">
            <ContentFull>
                <ContentHeader>Community profile</ContentHeader>
                <div className="space-y-4">
                    <AgencyCheckboxPref
                        field="showCommunityBaseAndHighPrice"
                        label='Show "Monthly base price" and "Price (high end)"'
                    />

                    <AgencyCheckboxPref
                        field="showCommunitySecondPersonFee"
                        label='Show "Second Person Fee"'
                    />

                    <AgencyCheckboxPref
                        field="showCommunityPetDeposit"
                        label='Show "Pet Deposit"'
                    />

                    <AgencyCheckboxPref
                        field="showCommunityFee"
                        label='Show "Community Fee / Entrance Fee"'
                    />
                </div>
            </ContentFull>
            <ContentFull>
                <ContentHeader>Community search</ContentHeader>
                <div className="space-y-4">
                    <AgencyCheckboxPref
                        field="enableBulkCommunityOptions"
                        label={`Enable bulk add in "Find Ideal Communities"`}
                        description="Allow users to add multiple communities at once when searching for communities in the client profile."
                    />
                    <SelectPro
                        label="Default state when searching for communities"
                        includePlaceholderOption
                        value={settings.defaultState}
                        onChange={e => updateDefaultState(e.target.value)}
                        options={getStatesArray()}
                    />
                    {communityCardSectionId ? (
                        <LayoutSectionItemSettings
                            entityMetadataName="Community"
                            includeStandardFields={true}
                            questions={questions}
                            questionFilter={_ => true}
                            questionTitle={q => q.title}
                            categoryFilter={d => d.communityEnabled}
                            categoryTitle={d => d.communityHeading}
                            layoutSectionId={communityCardSectionId}
                            label="Additional fields to show on Community Search results:"
                            mode="alwaysEditing"
                            enableShowWhenEmpty={true}
                            enableNaryFormat={false}
                            relations={[]}
                            allowEditFields={true}
                            allowEditItemTitles={false}
                        />
                    ) : null}
                </div>
            </ContentFull>
            <ContentFull>
                <ContentHeader>Community comparison</ContentHeader>
                <div className="space-y-4">
                    <LayoutSettings
                        entityMetadataName="Community"
                        includeStandardFields={true}
                        questions={questions}
                        sectionParentKey={LayoutSectionParentKey.CommunityComparisonTable}
                        label="Configure the layout and visibility for the community comparison pages below"
                        allowEditOrder={true}
                        allowEditVisibility={true}
                        mode="toggleEdit"
                        allowEditFields={true}
                        showContainerTitle={false}
                        enableNaryFormat={true}
                        allowCreateSections={false}
                        allowEditIcon="never"
                        allowEditTitle="never"
                        allowEditItemTitles={false}
                    />
                </div>
            </ContentFull>
            {entityTypes.data?.getEntities?.length > 0 ? (
                <ContentFull>
                    <EntitySettings
                        entityName="Community"
                        entities={entityTypes.data.getEntities}
                    />
                </ContentFull>
            ) : null}
        </div>
    );
};
