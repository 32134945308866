import { FileEntityId } from "@sp-crm/core";
import { QueryRenderer } from "components/clients/show-client/community-comparison/query-renderer";
import { defaultLinkStyle } from "components/ui/link";
import { FileEntityType, useGetSignableTemplatesQuery } from "generated/graphql";
import React from "react";
import { Link } from "react-router-dom";
import { SignableDocumentIcon } from "../file-icon";

export interface SignatureRequestIndexProps {
    fileEntityType: FileEntityType;
    entityId: FileEntityId;
}

export const SignatureRequestIndex: React.FC<SignatureRequestIndexProps> = props => {
    const { fileEntityType, entityId } = props;
    const getSignableTemplates = useGetSignableTemplatesQuery({
        entityType: fileEntityType,
    });
    return (
        <>
            <QueryRenderer
                query={getSignableTemplates}
                name={"SignatureRequestIndex.getSignableTemplates"}>
                {data =>
                    data.getSignableTemplates.length > 0 ? (
                        <div className="space-y-2">
                            <p>Choose a template to start a SignWise workflow</p>
                            <ul>
                                {data.getSignableTemplates.map(template => (
                                    <li key={template.id}>
                                        <Link
                                            className={`${defaultLinkStyle} flex items-center space-x-2`}
                                            to={`/files/${template.id}/signatures/${entityId}/new`}>
                                            <SignableDocumentIcon />{" "}
                                            <p>
                                                {template.originalFilename ??
                                                    "(untitled)"}
                                            </p>
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ) : (
                        <em>
                            No SignWise templates available for signatures. Go to{" "}
                            <Link to="/files" className={defaultLinkStyle}>
                                files
                            </Link>{" "}
                            to create one.
                        </em>
                    )
                }
            </QueryRenderer>
        </>
    );
};
