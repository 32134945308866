import { InlineBanner } from "components/ui/inline-banner";
import React from "react";

interface InvoicePaymentScheduleErrorsProps {
    errors: string[];
}

export const InvoicePaymentScheduleErrors: React.FC<
    InvoicePaymentScheduleErrorsProps
> = props => {
    const { errors } = props;

    if (errors?.length === 0) {
        return null;
    }

    return (
        <InlineBanner type="info">
            <p>Saving will be enabled once these payment schedule issues are fixed: </p>
            <ul className="list-disc text-sm text-sm pl-4">
                {errors.map((error, index) => (
                    <li key={index}>{error}</li>
                ))}
            </ul>
        </InlineBanner>
    );
};
