import { ITenantBooleanSettings, LayoutSectionSettingType } from "@sp-crm/core";
import { LayoutSectionResult } from "components/layout/layout-items";
import React from "react";
import { AgencyCheckboxPref } from "./agency-settings/agency-settings-checkbox";

interface LayoutSectionAgencySettingsProps {
    layoutSection: LayoutSectionResult;
}

export const LayoutSectionAgencySettings: React.FC<
    LayoutSectionAgencySettingsProps
> = props => {
    const { layoutSection } = props;

    if (layoutSection?.settings?.length > 0) {
        return (
            <ul className="p-2 space-y-1">
                {layoutSection.settings.map(setting => (
                    <li key={setting.key}>
                        <LayoutSectionAgencySetting setting={setting} />
                    </li>
                ))}
            </ul>
        );
    }

    return null;
};

interface LayoutSectionAgencySettingProps {
    setting: LayoutSectionResult["settings"][0];
}

const LayoutSectionAgencySetting: React.FC<LayoutSectionAgencySettingProps> = props => {
    const { setting } = props;

    switch (setting.type) {
        case LayoutSectionSettingType.Boolean: {
            return (
                <AgencyCheckboxPref
                    field={setting.key as keyof ITenantBooleanSettings}
                    label={setting.label}
                />
            );
        }
        default: {
            console.warn(`Unhandled setting type: ${setting.type}`);
            return null;
        }
    }
};
