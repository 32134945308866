import { IAnswer, IQuestion, QuestionCategories } from "@sp-crm/core";
import React, { FunctionComponent } from "react";
import QuestionControl from "./control";

type QuestionListProps = {
    questions: IQuestion[];
    category: QuestionCategories;
    answers: IAnswer[];
    onUpdateAnswer: (answer: IAnswer) => Promise<IAnswer>;
    preJSX: JSX.Element;
    posJSX: JSX.Element;
};

const QuestionList: FunctionComponent<QuestionListProps> = ({
    questions,
    category,
    answers,
    onUpdateAnswer,
    preJSX,
    posJSX,
}) => {
    const questionControls = questions
        .filter(q => q.category == category && q.enabled(questions, answers))
        .map(q => (
            <QuestionControl
                key={q.id}
                question={q}
                answers={answers}
                onUpdateAnswer={onUpdateAnswer}
            />
        ));

    return (
        <div className="space-y-2 md:space-y-3">
            {preJSX}
            {questionControls}
            {posJSX}
        </div>
    );
};

export default QuestionList;
