import { Answer, IAnswer, InteractionType, IQuestion } from "@sp-crm/core";
import React, { FunctionComponent } from "react";
import { Question } from "../../../questions";

type QuestionControlProps = {
    question: IQuestion;
    answers: IAnswer[];
    onUpdateAnswer: (answer: IAnswer) => Promise<IAnswer>;
};

const QuestionControl: FunctionComponent<QuestionControlProps> = ({
    question,
    answers,
    onUpdateAnswer,
}) => {
    if (question.interaction == InteractionType.none) {
        return null;
    }

    const answer = answers.find(x => x.questionId == question.id) || new Answer();

    let className = "custom-question";

    if (question.primaryQuestion) {
        className += " primary-question";
    }

    return (
        <div className="custom-questions">
            <div className={className}>
                <Question
                    callback={a => onUpdateAnswer(a)}
                    question={question}
                    answer={answer}
                />
            </div>
        </div>
    );
};

export default QuestionControl;
