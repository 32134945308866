import React from "react";
import { NavLink, NavLinkProps } from "react-router-dom";

type NavMenuLinkProps = NavLinkProps & { label: string };

interface NavMenuProps {
    links: NavMenuLinkProps[];
}

/**
 * Render a cross between the topnav and subnav
 * @deprecated Prefer SubnavMenu instead
 */
export const NavMenu: React.FC<NavMenuProps> = props => {
    const { links } = props;
    return (
        <div>
            <ul className="ml-4 main-menu flex space-x-4 text-lg font-semibold items-end">
                {links.map(l => (
                    <li key={l.to.toString()}>
                        <NavLink
                            activeClassName="bg-brand-100 text-brand-800 hover:bg-brand-200 hover:text-brand-900"
                            className="px-3 py-2 rounded hover:bg-gray-100 hover:text-black text-gray-800 whitespace-nowrap block"
                            {...l}>
                            {l.label}
                        </NavLink>
                    </li>
                ))}
            </ul>
        </div>
    );
};
