import { UserPreferenceFields } from "@sp-crm/core";
import { Checkbox } from "components/ui/checkbox";
import { Input } from "components/ui/input";
import React from "react";
import { useCurrentUser } from "store/selectors/hooks";
import { useCommitUserPreference } from "./account-settings-hooks";

interface UserCheckboxPrefProps {
    description: string;
    field: UserPreferenceFields;
    label: string;
}

export const UserCheckboxPref: React.FC<UserCheckboxPrefProps> = props => {
    const { description, field, label } = props;
    const user = useCurrentUser();
    const commit = useCommitUserPreference(field);
    const onCommitCallback = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            commit(e.target.checked);
        },
        [commit],
    );
    return (
        <div className="group">
            <Checkbox
                checked={user.preferences[props.field] as boolean}
                onChange={onCommitCallback}
                label={label}
            />
            <div className="ml-6 text-gray-500 group-hover:text-gray-900 text-sm">
                {description}
            </div>
        </div>
    );
};

export const UserNumericPref: React.FC<UserCheckboxPrefProps> = props => {
    const { description, field, label } = props;
    const user = useCurrentUser();
    const commit = useCommitUserPreference(field);
    const onCommitCallback = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (!isNaN(e.target.valueAsNumber)) {
                commit(e.target.valueAsNumber);
            }
        },
        [commit],
    );
    return (
        <div className="group max-w-md">
            <Input
                type="number"
                value={(user.preferences[props.field] as unknown as number) || 0}
                onChange={onCommitCallback}
                label={label}
            />
            <div className="ml-6 text-gray-500 group-hover:text-gray-900 text-sm">
                {description}
            </div>
        </div>
    );
};
