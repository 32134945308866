import React from "react";
import { Redirect, Route, Switch } from "react-router";
import { AccountSettingsClient } from "./account-settings-client";
import { AccountSettingsCommunity } from "./account-settings-community";
import { AccountSettingsEmail } from "./account-settings-email";
import { AccountSettingsGeneral } from "./account-settings-general";
import { AccountSettingsNav } from "./account-settings-nav";
import { AccountSettingsNotifications } from "./account-settings-notifications";
import { AccountSettingsPassword } from "./account-settings-password";
import { AccountSettingsPersonal } from "./account-settings-personal";
import { AccountSettingsReferralSources } from "./account-settings-referral-sources";
import { AccountSettingsWorkflow } from "./account-settings-workflow";

export const AccountSettings: React.FC<unknown> = () => {
    return (
        <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
            <div className="lg:col-span-3">
                <AccountSettingsNav />
            </div>
            <div className="lg:col-span-9 pb-4">
                <Switch>
                    <Route
                        path="/settings/account/personal"
                        component={AccountSettingsPersonal}
                    />
                    <Route
                        path="/settings/account/client"
                        component={AccountSettingsClient}
                    />
                    <Route
                        path="/settings/account/community"
                        component={AccountSettingsCommunity}
                    />
                    <Route
                        path="/settings/account/email"
                        component={AccountSettingsEmail}
                    />
                    <Route
                        path="/settings/account/general"
                        component={AccountSettingsGeneral}
                    />
                    <Route
                        path="/settings/account/referral"
                        component={AccountSettingsReferralSources}
                    />
                    <Route
                        path="/settings/account/notifications"
                        component={AccountSettingsNotifications}
                    />
                    <Route
                        path="/settings/account/password"
                        component={AccountSettingsPassword}
                    />
                    <Route
                        exact
                        path="/settings/account/workflow"
                        component={AccountSettingsWorkflow}
                    />
                    <Route
                        exact
                        path="/settings/account/workflow/:workflowId"
                        component={AccountSettingsWorkflow}
                    />
                    <Route
                        path="/settings/account"
                        render={() => <Redirect to="/settings/account/personal" />}
                    />
                </Switch>
            </div>
        </div>
    );
};
