/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/ban-types */
import React, { useMemo } from "react";
import { useSortBy, useTable } from "react-table";
import { SortDown, SortUp } from "../ui/icon";

interface DefaultTableProps<T = unknown> {
    data: T[];
    columns: {
        key: keyof T;
        header: string | JSX.Element;
        footer?: string;
        renderCell?: (props: { record: T; key: keyof T }) => JSX.Element;
    }[];
    useSort?: boolean;
}

export const DefaultTable = <T extends object>(
    props: DefaultTableProps<T>,
): JSX.Element => {
    const columns = useMemo(
        () =>
            props.columns.map(c => {
                return {
                    accessor: c.key,
                    Header: c.header,
                    ...(typeof c.footer === "string" ? { Footer: c.footer } : null),
                    ...(c.renderCell
                        ? {
                              Cell: (p: { row: { original: T } }) => {
                                  return c.renderCell({
                                      record: p.row.original,
                                      key: c.key,
                                  });
                              },
                          }
                        : null),
                };
            }),
        [props.columns],
    );
    const data: T[] = useMemo(() => props.data, [props.data]);
    const useSort = props.useSort ?? true;
    const initialState = useMemo(() => {
        if (useSort && props.columns.some(c => c.key === "count")) {
            return { sortBy: [{ id: "count", desc: true }] };
        }
        return {};
    }, [useSort]); // eslint-disable-line react-hooks/exhaustive-deps
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        footerGroups,
    } = useTable<T>({ columns, data, initialState }, useSort ? useSortBy : null);
    return (
        <table className="w-full" {...getTableProps()}>
            <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th
                                className="py-1 px-2 bg-gray-200 text-gray-600 uppercase text-sm leading-normal"
                                {...column.getHeaderProps(
                                    useSort ? column.getSortByToggleProps() : undefined,
                                )}>
                                <div className="flex items-center">
                                    <span>
                                        {column.isSorted ? (
                                            column.isSortedDesc ? (
                                                <SortDown className="mr-1" />
                                            ) : (
                                                <SortUp className="mr-1" />
                                            )
                                        ) : (
                                            ""
                                        )}
                                    </span>
                                    <span>{column.render("Header")}</span>
                                </div>
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>

            <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                        <tr
                            className={`border-b border-gray-200 hover:bg-gray-100 ${
                                i % 2 === 1 ? "bg-gray-50" : "bg-white"
                            }`}
                            {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                return (
                                    <td className="py-1 px-2" {...cell.getCellProps()}>
                                        {cell.render("Cell")}
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
                {columns.some(c => typeof c.Footer === "string")
                    ? footerGroups.map(group => (
                          <tr
                              className={`border-b border-gray-200 ${
                                  rows.length % 2 === 0 ? `bg-white` : `bg-gray-50`
                              }`}
                              {...group.getFooterGroupProps()}>
                              {group.headers.map(column => (
                                  <td
                                      className="py-1 px-2 text-gray-600 font-bold"
                                      {...column.getFooterProps()}>
                                      {column.render("Footer")}
                                  </td>
                              ))}
                          </tr>
                      ))
                    : null}
            </tbody>
        </table>
    );
};
