import { ActionType, EmailTemplateType, FileId, RegionId } from "@sp-crm/core";
import { Editor, PlacementEditor } from "components/editor/editor";
import { Checkbox } from "components/ui/checkbox";
import { Input } from "components/ui/input";
import { PrimaryButton } from "components/ui/primary-button";
import { RegionAccessInput } from "components/ui/region-access-input";
import { Spinner } from "components/ui/spinner";
import {
    CreateOrUpdateTemplateMutationVariables,
    useCreateOrUpdateTemplateMutation,
} from "generated/graphql";
import React, { MutableRefObject, useState } from "react";
import { useHistory } from "react-router-dom";
import { TemplateAttachments } from "./template-attachments";
import { templatePlaceholderTypes } from "./template-helpers";

interface Props {
    canShareTemplates: boolean;
}

export const TemplateNew: React.FC<Props> = props => {
    const { canShareTemplates } = props;
    const query = useCreateOrUpdateTemplateMutation();
    const router = useHistory();
    const [description, setDescription] = React.useState("");
    const [isShared, setIsShared] = React.useState(false);
    const [isDefault, setIsDefault] = React.useState(false);
    const [manualRegions, setManualRegions] = useState<RegionId[] | null>(null);
    const [subject, setSubject] = React.useState("");
    const [signatureOrTemplate, setSignatureOrTemplate] =
        React.useState<EmailTemplateType>("insertable");
    const handleRadio = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setSignatureOrTemplate(e.target.value as EmailTemplateType);
        },
        [setSignatureOrTemplate],
    );
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const editorRef = React.useRef() as MutableRefObject<PlacementEditor>;
    const [attachmentFileIds, setAttachmentFileIds] = React.useState<FileId[]>([]);
    const submit = () => {
        setIsSubmitting(true);
        (async () => {
            const payload: CreateOrUpdateTemplateMutationVariables = {
                template: {
                    isDefaultSignature: signatureOrTemplate === "signature" && isDefault,
                    isSignature: signatureOrTemplate === "signature",
                    type: signatureOrTemplate,
                    isShared: isShared && signatureOrTemplate === "insertable",
                    subject: signatureOrTemplate === "insertable" ? subject : null,
                    description,
                    body: editorRef.current.getContent(),
                    migrationVersion: 1,
                    attachments:
                        signatureOrTemplate === "insertable" ? attachmentFileIds : null,
                    entityRegionVisibility: manualRegions
                        ? { regions: manualRegions }
                        : null,
                },
            };
            await query.mutateAsync(payload);
            router.push(`/settings/templates`);
        })();
    };

    const handleSubject = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setSubject(e.target.value);
        },
        [setSubject],
    );

    return (
        <div className="space-y-4">
            <h3 className="text-xl mb-2">New template</h3>
            <Input
                label="Title"
                value={description}
                onChange={e => setDescription(e.target.value)}
            />
            {signatureOrTemplate === "insertable" ? (
                <Input label="Subject" value={subject} onChange={handleSubject} />
            ) : null}
            <Editor ref={editorRef} placeholderTypes={templatePlaceholderTypes} />
            {signatureOrTemplate === "insertable" ? (
                <TemplateAttachments
                    attachmentIds={attachmentFileIds}
                    onAttachmentIdsChanged={setAttachmentFileIds}
                />
            ) : null}
            <div className="bg-white rounded-md -space-y-px">
                <label
                    className={
                        "rounded-tl-md rounded-tr-md relative border p-4 flex cursor-pointer focus:outline-none " +
                        (signatureOrTemplate === "insertable"
                            ? "bg-brand-100 border-brand-200 z-10"
                            : "border-gray-200")
                    }>
                    <input
                        onChange={handleRadio}
                        type="radio"
                        name="sinatureOrTemplate"
                        value="insertable"
                        className={
                            "h-4 w-4 mt-0.5 form-radio cursor-pointer text-brand-600 border-gray-300 focus:ring-brand-500"
                        }
                        checked={signatureOrTemplate === "insertable"}
                    />
                    <div className={"ml-3 flex flex-col"}>
                        <span
                            className={
                                "block text-sm font-medium " +
                                (signatureOrTemplate === "insertable"
                                    ? "text-brand-800"
                                    : "text-gray-900")
                            }>
                            Template
                        </span>
                        <span
                            className={
                                "block text-sm " +
                                (signatureOrTemplate === "insertable"
                                    ? "text-brand-700"
                                    : "text-gray-500")
                            }>
                            Templates can be inserted into an email using the{" "}
                            <em>Insert Template</em> menu while composing an email.
                        </span>
                    </div>
                </label>

                <label
                    className={
                        "rounded-bl-md rounded-br-md relative border p-4 flex cursor-pointer focus:outline-none " +
                        (signatureOrTemplate === "signature"
                            ? "bg-brand-100 border-brand-200 z-10"
                            : "border-gray-200")
                    }>
                    <input
                        onChange={handleRadio}
                        type="radio"
                        name="sinatureOrTemplate"
                        value="signature"
                        className={
                            "h-4 w-4 mt-0.5 form-radio cursor-pointer text-brand-600 border-gray-300 focus:ring-brand-500"
                        }
                        checked={signatureOrTemplate === "signature"}
                    />
                    <div className={"ml-3 flex flex-col"}>
                        <span
                            className={
                                "block text-sm font-medium " +
                                (signatureOrTemplate === "signature"
                                    ? "text-brand-800"
                                    : "text-gray-900")
                            }>
                            Signature
                        </span>
                        <span
                            className={
                                "block text-sm " +
                                (signatureOrTemplate === "signature"
                                    ? "text-brand-700"
                                    : "text-gray-500")
                            }>
                            A signature can be added to the bottom of any email.
                            Additionally, you can set a default signature to always be
                            included.
                        </span>
                    </div>
                </label>
            </div>
            {canShareTemplates && signatureOrTemplate === "insertable" ? (
                <Checkbox
                    label={`Share with all users`}
                    onChange={e => setIsShared(e.target.checked)}
                    checked={isShared}
                />
            ) : null}
            {isShared ? (
                <RegionAccessInput
                    editable={true}
                    actionType={ActionType.Noop}
                    label="Region visibility"
                    onChange={setManualRegions}
                    regions={manualRegions || []}
                />
            ) : null}
            {signatureOrTemplate === "signature" ? (
                <Checkbox
                    label={`Set as default signature`}
                    onChange={e => setIsDefault(e.target.checked)}
                    checked={isDefault}
                />
            ) : null}
            <PrimaryButton onClick={submit} disabled={isSubmitting}>
                {isSubmitting ? <Spinner /> : "Save"}
            </PrimaryButton>
        </div>
    );
};
