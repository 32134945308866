import { DocumentDuplicateIcon } from "@heroicons/react/24/outline";
import { defaultLinkStyle } from "components/ui/link";
import { GetSavedQueriesQuery, useCreateSavedQueryMutation } from "generated/graphql";
import React from "react";
import { push } from "util/history";

interface AdvancedSearchDuplicateButtonProps {
    report: GetSavedQueriesQuery["getSavedQueries"]["savedQueries"][0];
    reload: () => void;
}

export const AdvancedSearchDuplicateButton: React.FC<
    AdvancedSearchDuplicateButtonProps
> = props => {
    const { report, reload } = props;

    const createMutation = useCreateSavedQueryMutation();

    const duplicateReport = React.useCallback(
        async (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
            e.preventDefault();

            const savedQuery = {
                name: `Copy of ${report.name}`,
                condition: report.condition,
                sort: report.sort,
                isShared: report.isShared,
                entityType: report.entityType,
                select: report.select,
                summaries: report.summaries,
            };
            const result = await createMutation.mutateAsync({ savedQuery: savedQuery });
            push(`/reports/show/custom/${result.createSavedQuery.id}`);
            reload();
        },
        [report, reload, createMutation],
    );

    return (
        <div>
            <a
                href="#"
                className={`${defaultLinkStyle} flex items-center space-x-2`}
                onClick={duplicateReport}>
                <DocumentDuplicateIcon className={`h-4 w-4`} />
                <div>Duplicate report</div>
            </a>
        </div>
    );
};
