import { DeleteButton } from "components/ui/action-button";
import { Input } from "components/ui/input";
import { SelectPro } from "components/ui/select-pro";
import { FormikErrors, FormikTouched, FormikValues } from "formik";
import { InvoiceLineItemInput, InvoicePaymentScheduleInput } from "generated/graphql";
import React from "react";
import { InvoiceLineItemsEditor } from "./invoice-line-items-editor";
import { InvoicePaymentScheduleEditor } from "./invoice-payment-schedule-editor";

interface InvoiceDetailsEditorProps {
    handleChange: (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
    ) => void;
    handleBlur: (
        e: React.FocusEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
    ) => void;
    setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void;
    values: FormikValues;
    touched: FormikTouched<FormikValues>;
    errors: FormikErrors<FormikValues>;
    lineItems: InvoiceLineItemInput[];
    onLineItemsChange: (lineItems: InvoiceLineItemInput[]) => void;
    includePaidDate?: boolean;
    paymentSchedule: InvoicePaymentScheduleInput | null;
    onPaymentScheduleChange: (
        paymentSchedule: InvoicePaymentScheduleInput | null,
    ) => void;
    onDelete?: () => void;
}

export const InvoiceDetailsEditor: React.FC<InvoiceDetailsEditorProps> = props => {
    const {
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        lineItems,
        onLineItemsChange,
        includePaidDate,
        paymentSchedule,
        onPaymentScheduleChange,
        setFieldValue,
        onDelete,
    } = props;

    const handlePaymentScheduleChange = React.useCallback(
        (paymentSchedule: InvoicePaymentScheduleInput | null) => {
            onPaymentScheduleChange(paymentSchedule);
            if (paymentSchedule) {
                const latestDueDate = paymentSchedule.items
                    .filter(x => !!x.dueDate)
                    .map(x => x.dueDate)
                    .sort((a, b) => {
                        const dateA = new Date(a);
                        const dateB = new Date(b);

                        return dateA.getTime() - dateB.getTime();
                    })
                    .reverse()[0];

                if (latestDueDate) {
                    setFieldValue("dueDate", latestDueDate);
                } else {
                    setFieldValue("dueDate", "");
                }

                if (paymentSchedule.items.every(x => !!x.paidDate)) {
                    const latestPaidDate = paymentSchedule.items
                        .map(x => x.paidDate)
                        .sort((a, b) => {
                            const dateA = new Date(a);
                            const dateB = new Date(b);

                            return dateA.getTime() - dateB.getTime();
                        })
                        .reverse()[0];

                    if (latestPaidDate) {
                        setFieldValue("paidDate", latestPaidDate);
                    }
                } else {
                    setFieldValue("paidDate", "");
                }
            }
        },
        [onPaymentScheduleChange, setFieldValue],
    );

    return (
        <>
            <div className="flex justify-between items-center">
                <div className="text-xl font-semibold">Invoice Details</div>
                {onDelete ? (
                    <DeleteButton
                        backgroundColor="bg-white"
                        onClick={onDelete}
                        confirm={{
                            title: "Delete invoice?",
                            message: "Are you sure you want to delete this invoice?",
                            confirmLabel: "Delete",
                            cancelLabel: "Cancel",
                        }}
                    />
                ) : null}
            </div>
            <div className="space-y-4">
                <div className="grid grid-cols-2 gap-x-8 gap-y-4">
                    <div className="">
                        <SelectPro
                            includePlaceholderOption={false}
                            label="Status"
                            name="status"
                            onChange={handleChange}
                            value={values.status}
                            options={[
                                { value: "new", text: "New" },
                                { value: "sent", text: "Sent" },
                                { value: "paid", text: "Paid" },
                                { value: "void", text: "Void" },
                            ]}
                        />
                    </div>
                    <div>
                        <Input
                            type="date"
                            label="Invoice sent date"
                            name="sentDate"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.sentDate}
                        />
                        {touched.sentDate && errors.sentDate ? (
                            <div className="text-red-700 font-bold mt-1">
                                {errors.sentDate as string}
                            </div>
                        ) : null}
                    </div>
                    <div>
                        <Input
                            label="Invoice number"
                            placeholder="Leave blank to generate"
                            type="input"
                            name="serial"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.serial}
                        />
                        {touched.serial && errors.serial ? (
                            <div className="text-red-700 font-bold mt-1">
                                {errors.serial as string}
                            </div>
                        ) : null}
                    </div>
                    <div>
                        <Input
                            type="date"
                            label="Due date"
                            name="dueDate"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.dueDate}
                            disabled={!!paymentSchedule}
                        />
                        {touched.dueDate && errors.dueDate ? (
                            <div className="text-red-700 font-bold mt-1">
                                {errors.dueDate as string}
                            </div>
                        ) : null}
                    </div>
                    {includePaidDate ? (
                        <Input
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.paidDate}
                            label="Paid date"
                            name="paidDate"
                            type="date"
                            disabled={!!paymentSchedule}
                        />
                    ) : null}
                </div>
                <InvoiceLineItemsEditor
                    lineItems={lineItems}
                    onChange={onLineItemsChange}
                    paymentSchedule={paymentSchedule}
                    paidDate={values.paidDate}
                />
                <InvoicePaymentScheduleEditor
                    paymentSchedule={paymentSchedule}
                    onChange={handlePaymentScheduleChange}
                    dueDate={values.dueDate}
                />
                <div className="w-full">
                    <div className="mb-1">
                        <label htmlFor="invoice-notes-field">Notes (optional)</label>
                    </div>
                    <textarea
                        className="form-input rounded w-full disabled:bg-gray-100 border-gray-400 hover:border-brand-600 active:border-brand-400 focus:ring-2 focus:ring-brand-300"
                        id="invoice-notes-field"
                        name="notes"
                        rows={3}
                        value={values.notes}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    {touched.notes && errors.notes ? (
                        <div className="text-red-700 font-bold mt-1">
                            {errors.notes as string}
                        </div>
                    ) : null}
                    <div className="italic text-sm">
                        Notes will be included in generated PDF invoices
                    </div>
                </div>
                <div>
                    <Input
                        type="text"
                        label="External Invoice Link"
                        name="externalReference"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.externalReference}
                    />
                    <div className="text-xs italic">
                        Used to track a link to this invoice in another software platform,
                        such as QuickBooks Online.
                    </div>
                    {touched.externalReference && errors.externalReference ? (
                        <div className="text-red-700 font-bold mt-1">
                            {errors.externalReference as string}
                        </div>
                    ) : null}
                </div>
            </div>
        </>
    );
};
