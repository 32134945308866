import * as React from "react";
import Shared from "../shared";
import { InDepthQuestionProps } from "./props";

export class RangeCurrencyQuestion extends React.Component<
    InDepthQuestionProps,
    undefined
> {
    constructor(props: InDepthQuestionProps) {
        super(props);
        this.updateAnswerHigh = this.updateAnswerHigh.bind(this);
        this.updateAnswerLow = this.updateAnswerLow.bind(this);
    }

    updateAnswerHigh(newVal: string): void {
        this.props.requestAnswerModification(a => {
            a.numberAnswerHigh = parseInt(newVal, 10);
        });
    }

    updateAnswerLow(newVal: string): void {
        this.props.requestAnswerModification(a => {
            a.numberAnswer = parseInt(newVal, 10);
        });
    }

    title(): string {
        return this.props.question.title;
    }

    render(): JSX.Element {
        return (
            <span className="range-question">
                <Shared.InputCurrency
                    onCommit={this.updateAnswerLow}
                    initial={this.props.answer.numberAnswer}
                    key={`${this.props.question.id}-low`}
                    label={`${this.title()} (low)`}
                />
                <span className="range-question-separator">&#8212;</span>
                <Shared.InputCurrency
                    onCommit={this.updateAnswerHigh}
                    initial={this.props.answer.numberAnswerHigh}
                    key={`${this.props.question.id}-high`}
                    label={`${this.title()} (high)`}
                />
            </span>
        );
    }
}
