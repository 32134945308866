import {
    CalendarDate,
    formatDateNoTime,
    formattedDateTimeDefault,
    inPast,
    Maybe,
    withinSevenDays,
} from "@sp-crm/core";
import { Spinner } from "components/ui/spinner";
import * as React from "react";
import { Link } from "react-router-dom";
import { useTenantSettings, useUsers } from "store/selectors/hooks";
import { DashboardDefaultsFragment } from "../../generated/graphql";
import { parsePhone } from "../../util/phone";
import { NO_NAME } from "../../util/text";

export interface CardProps {
    client: DashboardDefaultsFragment["clients"][0];
    highlightCard: boolean;
    pendingCard: boolean;
    showAssignedTo: boolean;
    getClientStatus: (client: DashboardDefaultsFragment["clients"][0]) => {
        descriptor: string;
        date: Maybe<CalendarDate>;
    };
}

export const EmptyCard: React.FunctionComponent = () => {
    return (
        <div className={`pipeline-card pipeline-client-card empty-card`}>
            <div className="mainline">
                <div className="title">(none)</div>
            </div>
        </div>
    );
};

const StatusDetail: React.FC<CardProps> = props => {
    const status = props.getClientStatus(props.client);
    return (
        <div className="text-xs">
            {status.descriptor}:{" "}
            {status.date.map(x => x.humanFriendly()).getOrElse("(not specified)")}
        </div>
    );
};

const SerialDetail: React.FC<CardProps> = props => {
    const tenantSettings = useTenantSettings();
    if (tenantSettings.showClientSerial && props.client.serial) {
        return <div className="text-md font-semibold">{props.client.serial}</div>;
    }
    return null;
};

const AssignedTo: React.FC<CardProps> = props => {
    const users = useUsers().users;

    let text = "Unassigned";

    if (props.client.assignedUserId && users[props.client.assignedUserId]) {
        text = users[props.client.assignedUserId].name;
    }

    return <p className="text-xs italic">{text}</p>;
};

const NextAction: React.FC<CardProps> = props => {
    if (!props.client.nextTask) {
        return <div className="font-light text-xs text-gray-600">No tasks</div>;
    }
    if (props.client.nextTask.dueDateTime) {
        return (
            <div>
                <div className="font-semibold">{props.client.nextTask.text}</div>
                <div>
                    Due: {formattedDateTimeDefault(props.client.nextTask.dueDateTime)}
                </div>
            </div>
        );
    }
    if (props.client.nextTask.dueDate) {
        return (
            <div>
                <div className="font-semibold">{props.client.nextTask.text}</div>
                <div>Due: {formatDateNoTime(props.client.nextTask.dueDate)}</div>
            </div>
        );
    }
    return (
        <div>
            <div className="font-semibold">{props.client.nextTask.text}</div>
        </div>
    );
};

const Community: React.FC<CardProps> = props => {
    const chosenCommunity = props.client.chosenCommunity;
    if (!chosenCommunity) {
        return null;
    }
    return (
        <div>
            <Link to={`/communities/show/${chosenCommunity.id}`}>
                {chosenCommunity.name}
            </Link>
        </div>
    );
};

const badgeColor = (theDate: Maybe<Date>): string => {
    if (!theDate || !theDate.hasValue) {
        return "bg-gray-300";
    }
    const actualDate = theDate.getOrElse(new Date());
    if (inPast(actualDate)) {
        return "bg-red-600";
    }
    if (withinSevenDays(actualDate)) {
        return "bg-yellow-400";
    }
    return "bg-green-500";
};

const dueDateFromClient = (
    client: DashboardDefaultsFragment["clients"][0],
): Maybe<Date> => {
    if (!client.nextTask) {
        return Maybe.none();
    }
    if (!client.nextTask.dueDate && !client.nextTask.dueDateTime) {
        return Maybe.none();
    }
    if (client.nextTask.dueDate) {
        return Maybe.fromValue(new Date(client.nextTask.dueDate));
    }
    if (client.nextTask.dueDateTime) {
        return Maybe.fromValue(new Date(client.nextTask.dueDateTime));
    }
    return Maybe.none();
};

const computeClientName = (
    clientName: string | undefined | null,
    additionalClientName: string | undefined | null,
): JSX.Element => {
    if (
        (!clientName || clientName.trim().length === 0) &&
        (!additionalClientName || additionalClientName.trim().length === 0)
    ) {
        return <span>{NO_NAME}</span>;
    }
    if (!clientName || clientName.trim().length === 0) {
        return <span>{additionalClientName}</span>;
    }
    if (!additionalClientName || additionalClientName.trim().length === 0) {
        return <span>{clientName}</span>;
    }
    return (
        <span>
            {clientName}
            <br />
            {additionalClientName}
        </span>
    );
};

const ContactDisplay: React.FC<CardProps> = props => {
    const showClientFirst = useTenantSettings().showClientFirst;
    const clientName = computeClientName(
        props.client.name,
        props.client.additionalClientName,
    );
    let bestContactName = props.client.bestContactName;
    if (!bestContactName || bestContactName.trim().length === 0) {
        bestContactName = NO_NAME;
    }
    const computedBadgeColor = badgeColor(dueDateFromClient(props.client));
    const titleName = showClientFirst ? clientName : bestContactName;
    const subtitleName = showClientFirst ? bestContactName : clientName;
    const phone = parsePhone(props.client.bestContactPhone);
    return (
        <div>
            <Link to={`/clients/show/${props.client.id}`}>
                <div className="flex gap-1 justify-between">
                    <div className="flex-1">
                        <div className="font-bold">{titleName}</div>
                        <div className="">{subtitleName}</div>
                    </div>
                    <div className="flex-none">
                        {props.pendingCard ? (
                            <Spinner />
                        ) : (
                            <div
                                className={`${computedBadgeColor} rounded-full w-3 h-3`}
                            />
                        )}
                    </div>
                </div>
            </Link>
            {phone.digits !== "" ? (
                <div>
                    <a href={"tel:" + phone.digits}>{phone.display}</a>
                </div>
            ) : null}
        </div>
    );
};

export const ClientCardv2: React.FC<CardProps> = props => {
    let extraClass = "";
    if (props.highlightCard) {
        extraClass = " highlight-card";
    }
    return (
        <div className={"space-y-2 pipeline-card pipeline-client-card" + extraClass}>
            <ContactDisplay {...props} />
            <Community {...props} />
            <NextAction {...props} />
            <StatusDetail {...props} />
            <SerialDetail {...props} />
            {props.showAssignedTo ? <AssignedTo {...props} /> : null}
        </div>
    );
};
