import { IClient, Task, User, UserId } from "@sp-crm/core";
import { ListClient } from "components/clients/list-client";
import {
    GetNextTasksForParentIdsQuery,
    ReferralBasicsFragment,
    useGetNextTasksForParentIdsQuery,
} from "generated/graphql";
import React, { useMemo } from "react";
import { ResponsiveMode } from "store/reducers/responsive";
import { useNow } from "store/selectors/hooks";

interface CommunityPlacementHistoryProps {
    responsiveMode: ResponsiveMode;
    users: Record<UserId, User>;
    clients: IClient[];
}

const clientToGqlClient = (
    client: IClient,
    nextTasks: GetNextTasksForParentIdsQuery["getNextTasksForParentIds"],
    now: Date,
): ReferralBasicsFragment["client"] => {
    // const nextAction = this.nextAction(now);
    const nextTask = nextTasks.find(t => t.parentId === client.id) || null;
    const loadedNextTask = nextTask ? Task.load(nextTask) : null;

    return {
        id: client.id,
        name: client.name,
        statusListItemId: client.statusListItemId,
        serial: client.serial,
        assignedUserId: client.assignedUserId,
        nextTask: loadedNextTask
            ? {
                  id: null,
                  status: loadedNextTask.active() ? "inprogress" : "done",
                  text: loadedNextTask.description(),
                  dueDateTime: loadedNextTask.when(),
                  compositeDueDateTime: loadedNextTask.when(),
              }
            : null,
        bestContactName: client.bestContactEntity?.name || "",
        moveTimeline: client.moveTimeline?.toString() || "",
        moveTimelineActual: client.moveTimelineActual?.toString() || "",
        dateAdded: client.dateAdded,
        updatedAt: client.updatedAt,
    };
};

export const CommunityPlacementHistory: React.FC<
    CommunityPlacementHistoryProps
> = props => {
    const { responsiveMode, users, clients } = props;

    const now = useNow();
    const clientIds = useMemo(() => clients.map(c => c.id), [clients]);

    const getNextTasks = useGetNextTasksForParentIdsQuery({ parentIds: clientIds });

    const nextTasks = getNextTasks.data?.getNextTasksForParentIds || [];

    return (
        <section className="list-reference">
            <ListClient
                responsiveMode={responsiveMode}
                users={users}
                clients={clients.map(c => clientToGqlClient(c, nextTasks, now))}
                sourceType={"fromCommunity"}
                showAssignedToColumn={false}
            />
        </section>
    );
};
