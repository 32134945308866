import { ContentFull, ContentHeader } from "components/layout";
import { TemplateSelect } from "components/templates/template-select";
import React from "react";
import { useTenantSettings } from "store/selectors/hooks";
import { useCommitTenantSetting } from "./agency-settings-hooks";

export const AgencySettingsFax: React.FC<unknown> = () => {
    const updateFaxCoverPageTemplateId = useCommitTenantSetting("faxCoverPageTemplateId");
    const settings = useTenantSettings();
    return (
        <div className="space-y-4 lg:space-y-8">
            <ContentFull>
                <ContentHeader>Fax</ContentHeader>
                <div className="space-y-4">
                    <TemplateSelect
                        label="Cover page"
                        sharedOnly
                        selectedTemplateId={settings.faxCoverPageTemplateId}
                        onChange={updateFaxCoverPageTemplateId}
                    />
                </div>
            </ContentFull>
        </div>
    );
};
