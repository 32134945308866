import * as Common from "@sp-crm/core";
import { IClient, SettingsCommunitySortType } from "@sp-crm/core";
import {
    ISearchAddress,
    ISearchAnswer,
    ISearchPrice,
    ISearchRating,
    isSearchLocationValid,
} from "components/community-search/search-bar/util";
import * as React from "react";
import { connect } from "react-redux";
import { Action } from "redux";
import { communitySearchInitializeEvent } from "store/actions/communities";
import { regionIdFromState } from "store/selectors/hooks";
import { ApplicationState } from "store/state";
import {
    CommunitySearchInstance,
    buildDefaultSearchInstance,
} from "../../../store/reducers/community-search";
import { userPreferences } from "../../../store/selectors/preferences";
import {
    SearchCommunityState,
    getFirstTypeFilterQuestion,
} from "../../community-search/search-bar/search-bar";
import { Col, Row } from "../../shared/grid";
import { FindCommunities } from "./find-communities";
interface ClientCommunityOwnProps {
    client?: IClient;
    questions: Common.IQuestion[];
}

interface ClientCommunityPropsFromState extends ClientCommunityOwnProps {
    searches?: { [key: string]: CommunitySearchInstance };
    defaultCommunitySortOrder: SettingsCommunitySortType;
    regionId: Common.RegionId;
}

interface ClientCommunityPropsFromDispatch {
    initializeSearch: (
        searchKey: string,
        defaultCommunitySortOrder: SettingsCommunitySortType,
        regionId: string,
        searchInstance: CommunitySearchInstance,
    ) => void;
}

type ClientCommunityProps = ClientCommunityPropsFromState &
    ClientCommunityPropsFromDispatch;

export interface INeedsAndDesiresFilters {
    address: ISearchAddress;
    answers: ISearchAnswer[];
    rating: ISearchRating;
    price: ISearchPrice;
}

class ClientCommunityInternal extends React.Component<ClientCommunityProps, undefined> {
    componentDidMount() {
        const defaultSearchInstance = {
            ...buildDefaultSearchInstance(
                this.props.regionId,
                this.props.defaultCommunitySortOrder,
            ),
            ...this.buildSearchesDiff(this.props.questions),
        };
        this.props.initializeSearch(
            this.searchKey(),
            this.props.defaultCommunitySortOrder,
            this.props.regionId,
            defaultSearchInstance,
        );
    }

    private searchKey(): string {
        return `client-${this.props.client.id}`;
    }

    private isBinarySelected(question: Common.IQuestion): boolean {
        if (this.props.client) {
            const result = this.props.client.answers.find(
                q => q.questionId == question.id,
            );
            if (result && result.booleanAnswer) {
                return true;
            }
        }
        return false;
    }

    private selectedOptions(question: Common.IQuestion): string[] {
        if (this.props.client) {
            const result = this.props.client.answers.find(
                q => q.questionId == question.id,
            );
            if (result) {
                const selections = result.selections.slice(0);
                // filter out any selections that have a property mapped
                // such that they should not be matched
                return selections.filter(s => {
                    const prompt = question.prompts.find(p => p.id == s);
                    if (prompt && prompt.ignoreMatching) {
                        return false;
                    }
                    return true;
                });
            }
        }
        return [];
    }

    private searchAdvancedAnswers(questions: Common.IQuestion[]): ISearchAnswer[] {
        const firstTypeQuestion = getFirstTypeFilterQuestion(questions);

        if (!firstTypeQuestion) {
            return [];
        }

        return [firstTypeQuestion]
            .filter(q => {
                return (
                    (q.questionType == Common.QuestionType.binary &&
                        this.isBinarySelected(q)) ||
                    q.questionType == Common.QuestionType.nary
                );
            })
            .map(q => {
                if (
                    typeof q.matchingCriteria === "undefined" ||
                    q.matchingCriteria === null ||
                    q.interaction === Common.InteractionType.none ||
                    q.searchBehavior === Common.QuestionBehavior.alwaysHide
                ) {
                    return null;
                }
                if (
                    q.questionType == Common.QuestionType.binary &&
                    this.isBinarySelected(q)
                ) {
                    const theBool: ISearchAnswer = {
                        questionId: q.id,
                        booleanAnswer: "true",
                        selectionAnswer: [],
                    };
                    return theBool;
                }
                if (q.questionType == Common.QuestionType.nary) {
                    const theSelectionAnswer: ISearchAnswer = {
                        questionId: q.id,
                        booleanAnswer: "na",
                        selectionAnswer: this.selectedOptions(q),
                    };
                    return theSelectionAnswer;
                }
                return null;
            })
            .filter(x => !!x);
    }

    private bestLocation(): ISearchAddress {
        const address = {
            street: this.props.client.bestLocationAddress,
            city: this.props.client.bestLocationCity,
            state: this.props.client.bestLocationState,
            zip: this.props.client.bestLocationZip,
            county: "",
        };

        return isSearchLocationValid(address)
            ? address
            : { zip: "", street: "", state: "", city: "", county: "" };
    }

    private getNeedsAndDesiresFilters(
        questions: Common.IQuestion[],
    ): SearchCommunityState {
        const location: ISearchAddress = this.bestLocation();
        return {
            address: {
                zip: location.zip,
                street: location.street,
                city: location.city,
                state: location.state,
                county: "",
            },
            answers: this.searchAdvancedAnswers(questions),
            rating: {
                value: 0,
                includeUnrated: true,
            },
            price: { min: 0, max: 10000, includePriceUndefined: true },
            searchText: "",
            maxDistance: 5,
            specificZip: false,
            specificCity: false,
            specificCounty: false,
            bothGenders: false,
            maleOnly: false,
            femaleOnly: false,
            genderNotSet: false,
            contractNoState: false,
            contractPending: false,
            contractActive: false,
            contractOneOff: false,
            contractNone: false,
            contractTerminated: false,
            licensePending: false,
            licenseActive: false,
            licenseOnProbation: false,
            licenseClosed: false,
            licenseNotApplicable: false,
            licenseNotSet: false,
            minCapacity: null,
            maxCapacity: null,
            sort: this.props.defaultCommunitySortOrder,
            hideDontShow: true,
            matchHighScore: "",
            matchLowScore: "",
            matchRule: "",
        };
    }

    private buildSearchesDiff(
        questions: Common.IQuestion[],
    ): Partial<CommunitySearchInstance> {
        const defaultFilters = this.getNeedsAndDesiresFilters(questions);
        return {
            zip: defaultFilters.address.zip,
            address: defaultFilters.address.street,
            city: defaultFilters.address.city,
            state: defaultFilters.address.state,
            advancedAnswers: defaultFilters.answers,
            rating: defaultFilters.rating,
            price: defaultFilters.price,
            hideDontShow: defaultFilters.hideDontShow,
            sort: defaultFilters.sort,
            maxDistance: 5 as Common.LocalizedDistance,
        };
    }

    render() {
        return (
            <Row type="flex" className="collapsible">
                <Col className="full-width-extra-margin">
                    <FindCommunities
                        client={this.props.client}
                        searchKey={this.searchKey()}
                        needsAndDesiresFilters={this.getNeedsAndDesiresFilters(
                            this.props.questions,
                        )}
                    />
                </Col>
            </Row>
        );
    }
}

function mapStateToProps(
    state: ApplicationState,
    ownProps: ClientCommunityOwnProps,
): ClientCommunityPropsFromState {
    const client = ownProps.client as IClient;
    return {
        regionId: regionIdFromState(state, "community"),
        client,
        questions: ownProps.questions,
        searches: state.communitySearch.searches,
        defaultCommunitySortOrder: userPreferences(state).defaultCommunitySortOrder,
    };
}

function mapDispatchToProps(
    dispatch: React.Dispatch<Action>,
): ClientCommunityPropsFromDispatch {
    return {
        initializeSearch: (
            searchKey: string,
            defaultCommunitySortOrder: SettingsCommunitySortType,
            regionId: Common.RegionId,
            searchInstance: CommunitySearchInstance,
        ) => {
            dispatch(
                communitySearchInitializeEvent(
                    searchKey,
                    defaultCommunitySortOrder,
                    regionId,
                    searchInstance,
                ),
            );
        },
    };
}

const ClientCommunity = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ClientCommunityInternal);
export { ClientCommunity };
