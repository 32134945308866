import { ClientId, ClientReactivateResponse } from "@sp-crm/core";
import { Action, Dispatch } from "redux";
import { CommunityOrdering } from "../../generated/graphql";
import * as http from "../../util/http";
import { Actions, navigate } from "./index";

export function reactivateClient(clientId: ClientId, dispatch: Dispatch<Action>) {
    dispatch({ type: Actions[Actions.REACTIVATE_CLIENT_START] });
    const requestDescription = http.newRequestDescription();
    http.post(requestDescription, `/api/clients/${clientId}/reactivate`, {})
        .then(r => r.data)
        .then((responseData: ClientReactivateResponse) => {
            dispatch({
                type: Actions[Actions.REACTIVATE_CLIENT_FINISH],
                data: responseData,
            });
            return responseData;
        })
        .then(responseData => navigate(`/clients/show/${responseData.newClient.id}`))
        .catch(r => http.errorHandler(r, requestDescription, dispatch));
}

export function reactivateClientWithoutNavigation(
    clientId: ClientId,
    dispatch: Dispatch<Action>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
): Promise<any> {
    dispatch({ type: Actions[Actions.REACTIVATE_CLIENT_START] });
    const requestDescription = http.newRequestDescription();
    return http
        .post(requestDescription, `/api/clients/${clientId}/reactivate`, {})
        .then(r => r.data)
        .then((responseData: ClientReactivateResponse) => {
            dispatch({
                type: Actions[Actions.REACTIVATE_CLIENT_FINISH],
                data: responseData,
            });
            return responseData;
        })
        .catch(r => http.errorHandler(r, requestDescription, dispatch));
}

export async function getShareLink(clientId: ClientId, dispatch: Dispatch<Action>) {
    const requestDescription = http.newRequestDescription();
    try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
        const result: any = await http.get(
            requestDescription,
            `/api/clients/${clientId}/community-comparison/share`,
        );
        return result.data.url;
    } catch (e) {
        http.errorHandler(e, requestDescription, dispatch);
    }
}

export function updateClientCommunityOrder(
    clientId: ClientId,
    ordering: CommunityOrdering[],
    dispatch: Dispatch<Action>,
) {
    dispatch({
        type: Actions[Actions.UPDATE_CLIENT_COMMUNITY_ORDER],
        clientId,
        ordering,
    });
}
