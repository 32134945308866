import { CheckCircleIcon, ClockIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { formatDate, WorkflowTargetId } from "@sp-crm/core";
import { QueryRenderer } from "components/clients/show-client/community-comparison/query-renderer";
import { Header } from "components/header";
import { DeleteButton } from "components/ui/action-button";
import {
    GetWorkflowExecutionsForEntityQuery,
    useCancelWorkflowExecutionMutation,
    useGetWorkflowExecutionsForEntityQuery,
    WorkflowExecutionResult,
} from "generated/graphql";
import React, { useCallback, useEffect } from "react";

interface WorkflowEntity {
    id: WorkflowTargetId;
}

interface WorkflowEntityExecutionsProps {
    entity: WorkflowEntity;
}

export const WorkflowEntityExecutions: React.FC<
    WorkflowEntityExecutionsProps
> = props => {
    const { entity } = props;
    const workflowExecutionsQuery = useGetWorkflowExecutionsForEntityQuery({
        workflowTargetId: entity.id,
    });

    useEffect(() => {
        workflowExecutionsQuery.refetch();
    }, [entity]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <QueryRenderer name="WorkflowEntityExecutions" query={workflowExecutionsQuery}>
            {data =>
                data.getWorkflowExecutionsForEntity.length === 0 ? null : (
                    <div className="input-form-block-no-bottom-margin">
                        <Header iconName="36_CheckBackLater">Workflow automation</Header>
                        <div className="space-y-1 mt-2">
                            {data.getWorkflowExecutionsForEntity.map(execution => (
                                <WorkflowExecution
                                    key={execution.id}
                                    execution={execution}
                                    refetch={workflowExecutionsQuery.refetch}
                                />
                            ))}
                        </div>
                    </div>
                )
            }
        </QueryRenderer>
    );
};

interface WorkflowExecutionProps {
    execution: GetWorkflowExecutionsForEntityQuery["getWorkflowExecutionsForEntity"][0];
    refetch: () => void;
}

const WorkflowExecution: React.FC<WorkflowExecutionProps> = props => {
    const { execution, refetch } = props;
    const cancelMutation = useCancelWorkflowExecutionMutation();
    const cancel = useCallback(async () => {
        await cancelMutation.mutateAsync({ workflowExecutionId: execution.id });
        refetch();
    }, [cancelMutation, execution.id, refetch]);
    if (execution.finishedAt) {
        if (execution.result === WorkflowExecutionResult.Error) {
            return (
                <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-2">
                        <XCircleIcon className="h-5 w-5 text-red-300" />
                        <div className="text-red-600">{execution.workflow.name}</div>
                    </div>
                    <div className="flex items-center pr-9">
                        <div className="text-sm text-red-500">
                            completed with errors at {formatDate(execution.finishedAt)}
                        </div>
                    </div>
                </div>
            );
        }
        if (execution.result === WorkflowExecutionResult.Warning) {
            return (
                <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-2">
                        <XCircleIcon className="h-5 w-5 text-yellow-300" />
                        <div>{execution.workflow.name}</div>
                    </div>
                    <div className="flex items-center pr-9">
                        <div className="text-sm text-yellow-500">
                            completed with warnings at {formatDate(execution.finishedAt)}
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <div className="flex items-center justify-between">
                <div className="flex items-center space-x-2">
                    <CheckCircleIcon className="h-5 w-5 text-green-600" />
                    <div>{execution.workflow.name}</div>
                </div>
                <div className="flex items-center pr-9">
                    <div className="text-sm text-gray-700">
                        {formatDate(execution.finishedAt)}
                    </div>
                </div>
            </div>
        );
    }
    if (execution.isCancelRequested) {
        return (
            <div className="flex items-center justify-between">
                <div className="flex items-center space-x-2">
                    <XCircleIcon className="h-5 w-5 text-gray-300" />
                    <div className="text-gray-400">{execution.workflow.name}</div>
                </div>
                <div className="flex items-center pr-9">
                    <div className="text-sm text-gray-400">
                        canceled {formatDate(execution.cancelledAt)}
                    </div>
                </div>
            </div>
        );
    }
    if (execution.scheduledAt) {
        return (
            <div className="flex items-center justify-between">
                <div className="flex items-center space-x-2">
                    <ClockIcon className="h-5 w-5 text-yellow-500" />
                    <div className="italic">{execution.workflow.name}</div>
                </div>
                <div className="flex items-center space-x-2">
                    <div className="text-sm text-gray-700 italic">
                        scheduled {formatDate(execution.scheduledAt)}
                    </div>
                    <DeleteButton
                        confirm={{
                            title: "Remove",
                            message:
                                "Are you sure you want to stop this workflow from running?",
                            confirmLabel: "Yes, stop this workflow",
                            cancelLabel: "No, keep it running",
                        }}
                        onClick={cancel}
                        backgroundColor="bg-gray-200"
                    />
                </div>
            </div>
        );
    }
    return (
        <div className="flex items-center justify-between">
            <div className="flex items-center pl-7">
                <div>{execution.workflow.name}</div>
            </div>
        </div>
    );
};
