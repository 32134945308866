import { Tenant, TenantSettingsFields } from "@sp-crm/core";
import React from "react";
import { useDispatch } from "react-redux";
import { updateTenant } from "store/actions";
import { useCurrentTenant } from "store/selectors/hooks";

export const useCommitTenantSetting = (field: TenantSettingsFields) => {
    const tenant = useCurrentTenant();
    const dispatch = useDispatch();
    const callback = React.useCallback(
        (value: unknown) => {
            const reloadedTenant = Tenant.fromDocument(tenant);
            reloadedTenant.tenantId = tenant.tenantId;
            reloadedTenant.settings = { ...reloadedTenant.settings, [`${field}`]: value };
            updateTenant(reloadedTenant, dispatch);
        },
        [dispatch, tenant, field],
    );
    return callback;
};

export const useCommitTenantSettingGeneric = () => {
    const tenant = useCurrentTenant();
    const dispatch = useDispatch();
    const callback = React.useCallback(
        (field: TenantSettingsFields, value: unknown) => {
            const reloadedTenant = Tenant.fromDocument(tenant);
            reloadedTenant.tenantId = tenant.tenantId;
            reloadedTenant.settings = { ...reloadedTenant.settings, [`${field}`]: value };
            updateTenant(reloadedTenant, dispatch);
        },
        [dispatch, tenant],
    );
    return callback;
};
