import { EmailTemplateId, LayoutSectionKey } from "@sp-crm/core";
import { TemplateSelect } from "components/templates/template-select";
import { Checkbox } from "components/ui/checkbox";
import * as React from "react";
import { useEffect } from "react";
import {
    GetLayoutSectionsQuery,
    LayoutSectionParentKey,
    useGetLayoutSectionsQuery,
} from "../../../../generated/graphql";
import { LayoutSectionResult } from "../../../layout/layout-items";
import { LayoutSectionPicker } from "../../../layout/layout-section-picker";
import { QueryRenderer } from "./query-renderer";

type CommunityComparePdfLayoutCustomizationProps = {
    showCoverPage: boolean;
    coverPageTemplate?: { templateId?: EmailTemplateId; templateBody?: HTMLElement };
    sectionKeys: LayoutSectionKey[];
    handleShowCoverPage: (isChecked: boolean) => void;
    handleCoverPageTemplateId: (selection: string | undefined) => void;
    handleSectionKeys: (sectionKeys: LayoutSectionKey[]) => void;
};

export const CommunityComparePdfLayoutCustomization: React.FunctionComponent<
    CommunityComparePdfLayoutCustomizationProps
> = props => {
    const getSectionKeys = (layoutSections: LayoutSectionResult[]) =>
        layoutSections.filter(s => s.visible).map(s => s.sectionKey as LayoutSectionKey);
    const [showCoverPage, setShowCoverPage] = React.useState(props.showCoverPage);
    const [layoutSections, setLayoutSections] = React.useState([]);
    const query = useGetLayoutSectionsQuery(
        {
            parentKey: LayoutSectionParentKey.CommunityComparisonTable,
        },
        {
            onSuccess: (data: GetLayoutSectionsQuery) => {
                setLayoutSections(data.getLayoutSections.layoutSections);
            },
        },
    );
    useEffect(() => {
        props.handleSectionKeys(getSectionKeys(layoutSections));
    }, [layoutSections]); // eslint-disable-line react-hooks/exhaustive-deps

    const templatePicker = () => {
        if (!showCoverPage) return null;

        return (
            <div>
                <TemplateSelect
                    label="Template to use for Cover Page"
                    onChange={(templateId: EmailTemplateId) =>
                        props.handleCoverPageTemplateId(templateId)
                    }
                    selectedTemplateId={props.coverPageTemplate.templateId}
                />
            </div>
        );
    };

    return (
        <QueryRenderer query={query} name="Community Comparison PDF Customization">
            {() => (
                <>
                    <Checkbox
                        checked={props.showCoverPage}
                        onChange={e => {
                            setShowCoverPage(e.target.checked);
                            props.handleShowCoverPage(e.target.checked);
                        }}
                        label="Show cover page with branding and optional note"
                    />
                    {templatePicker()}
                    <div className="mt-4">
                        <LayoutSectionPicker
                            layoutSections={layoutSections}
                            onSectionsChanged={setLayoutSections}
                            hideSections={[LayoutSectionKey.CommunityComparisonPhotosRow]}
                        />
                    </div>
                </>
            )}
        </QueryRenderer>
    );
};
