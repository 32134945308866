import * as React from "react";
import Shared from "../shared";
import { InDepthQuestionProps } from "./props";

export class CurrencyQuestion extends React.Component<InDepthQuestionProps, undefined> {
    constructor(props: InDepthQuestionProps) {
        super(props);
        this.updateAnswer = this.updateAnswer.bind(this);
    }

    updateAnswer(newVal: string): void {
        this.props.requestAnswerModification(a => {
            a.numberAnswer = parseFloat(newVal);
        });
    }

    title(): string {
        return this.props.question.title;
    }

    render(): JSX.Element {
        return (
            <Shared.InputCurrency
                onCommit={newVal => this.updateAnswer(newVal)}
                initial={this.props.answer.numberAnswer}
                key={this.props.question.id}
                label={this.title()}
            />
        );
    }
}
