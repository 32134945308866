import { QueryRenderer } from "components/clients/show-client/community-comparison/query-renderer";
import { ContentFull, ContentHeader, ContentSubheader } from "components/layout";
import { linkStyle } from "components/reports/show/custom-reports/custom-reports-sidebar";
import { fancyConfirm } from "components/ui/fancy-confirm";
import { InlineBanner } from "components/ui/inline-banner";
import { Input } from "components/ui/input";
import { Panel } from "components/ui/panel/panel";
import { PanelType } from "components/ui/panel/panel-type";
import { PrimaryButton } from "components/ui/primary-button";
import { SecondaryButton } from "components/ui/secondary-button";
import { Select } from "components/ui/select";
import { Spinner } from "components/ui/spinner";
import {
    SmtpCredentialStatus,
    useCreateSmtpCredentialMutation,
    useDeleteSmtpCredentialForUserMutation,
    useGetSmtpCredentialForUserQuery,
} from "generated/graphql";
import React from "react";
import { useProductName } from "store/selectors/branding";
import { useFeature } from "store/selectors/hooks";

interface AccountSettingsEmailProps {}

export const AccountSettingsEmail: React.FC<AccountSettingsEmailProps> = props => {
    const featureEnabled = useFeature("smtpDirectEmail");

    const [showPanel, setShowPanel] = React.useState(false);

    const handleConnect = React.useCallback(
        async (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            setShowPanel(true);
        },
        [setShowPanel],
    );

    const handleDismiss = React.useCallback(() => {
        setShowPanel(false);
    }, [setShowPanel]);

    const getCredential = useGetSmtpCredentialForUserQuery(
        {},
        { enabled: featureEnabled },
    );

    const deleteCredential = useDeleteSmtpCredentialForUserMutation();

    const handleSaved = React.useCallback(() => {
        setShowPanel(false);
        getCredential.refetch();
    }, [setShowPanel, getCredential]);

    const handleDisconnect = React.useCallback(
        async (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            const confirmed = await fancyConfirm(
                "Disconnect provider?",
                "Are you sure you want to disconnect your email provider? Emails will still be send, but not directly from your email provider.",
                "Yes, disconnect",
                "Cancel",
            );

            if (confirmed) {
                await deleteCredential.mutateAsync({});
                getCredential.refetch();
            }
        },
        [deleteCredential, getCredential],
    );

    const productName = useProductName();

    return (
        <ContentFull>
            <ContentHeader>Email Integration</ContentHeader>
            <ContentSubheader>
                Send email directly from your own email provider to improve deliverability
                and save {productName} emails in your provider&apos;s sent folder.
            </ContentSubheader>
            {featureEnabled ? (
                <QueryRenderer
                    name="AccountSettingsEmail.getSmtpCredential"
                    query={getCredential}>
                    {data =>
                        data.getSmtpCredentialForUser ? (
                            <div className="space-y-2">
                                {data.getSmtpCredentialForUser.status ===
                                SmtpCredentialStatus.Active ? (
                                    <InlineBanner type="success">
                                        Your email provider is connected. Emails will be
                                        sent directly from your email provider.
                                    </InlineBanner>
                                ) : null}
                                {data.getSmtpCredentialForUser.status ===
                                SmtpCredentialStatus.Disconnected ? (
                                    <InlineBanner type="error">
                                        The username or password provided no longer
                                        authenticate with your email provider. To
                                        reconnect, disconnect the provider then connect
                                        again with new credentials.
                                    </InlineBanner>
                                ) : null}
                                <p>
                                    <strong>SMTP Server:</strong>{" "}
                                    {data.getSmtpCredentialForUser.host}
                                </p>
                                <p>
                                    <strong>SMTP Port:</strong>{" "}
                                    {data.getSmtpCredentialForUser.port}
                                </p>
                                <p>
                                    <strong>Username:</strong>{" "}
                                    {data.getSmtpCredentialForUser.username}
                                </p>
                                <p>
                                    <strong>Password:</strong> ********
                                </p>
                                <button onClick={handleDisconnect} className={linkStyle}>
                                    <span className="text-sm">Disconnect provider</span>
                                </button>
                            </div>
                        ) : (
                            <PrimaryButton onClick={handleConnect}>
                                Connect email provider
                            </PrimaryButton>
                        )
                    }
                </QueryRenderer>
            ) : (
                <InlineBanner type="info">
                    Direct email integration is only available to {productName} Premium
                    customers. Contact support to learn more about {productName} Premium.
                </InlineBanner>
            )}
            <Panel
                isOpen={showPanel}
                headerText="Connect email provider"
                type={PanelType.large}
                onDismiss={handleDismiss}>
                {showPanel ? (
                    <CreateSmtpIntegration
                        onCancel={handleDismiss}
                        onSaved={handleSaved}
                    />
                ) : null}
            </Panel>
        </ContentFull>
    );
};

interface CreateSmtpIntegrationProps {
    onSaved: () => void;
    onCancel: () => void;
}

interface EmailProvider {
    key: string;
    label: string;
    host: string;
    port: string;
    type: "managed" | "manual";
    appPasswordLink?: string;
}

const providers: EmailProvider[] = [
    {
        key: "gmail",
        label: "Google Workspace/Gmail",
        host: "smtp.gmail.com",
        port: "587",
        type: "managed",
        appPasswordLink: "https://myaccount.google.com/apppasswords",
    },
    {
        key: "outlook",
        label: "Outlook.com",
        host: "smtp-mail.outlook.com",
        port: "587",
        type: "managed",
        appPasswordLink: "https://account.live.com/proofs/manage/additional#AppPassword",
    },
    { key: "other", label: "Other Provider", type: "manual", host: "", port: "" },
];

const CreateSmtpIntegration: React.FC<CreateSmtpIntegrationProps> = props => {
    const { onCancel, onSaved } = props;

    const [selectedProvider, setSelectedProvider] = React.useState<EmailProvider>(
        providers[0],
    );
    const [smtpServer, setSmtpServer] = React.useState<string>(selectedProvider.host);
    const [smtpPort, setSmtpPort] = React.useState<string>(selectedProvider.port);
    const [username, setUsername] = React.useState<string>("");
    const [password, setPassword] = React.useState<string>("");
    const [error, setError] = React.useState<string | null>(null);

    const createSmtpCredential = useCreateSmtpCredentialMutation();

    const handleSave = React.useCallback(
        async (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            setError(null);
            const result = await createSmtpCredential.mutateAsync({
                params: {
                    host: smtpServer,
                    port: parseInt(smtpPort, 10),
                    username,
                    password,
                },
            });
            if (result.createSmtpCredential.success) {
                onSaved();
            } else {
                setError(result.createSmtpCredential.message);
            }
        },
        [smtpServer, smtpPort, username, password, onSaved, createSmtpCredential],
    );

    const handleCancel = React.useCallback(() => {
        onCancel();
    }, [onCancel]);

    const handleProviderChange = React.useCallback(
        (e: React.ChangeEvent<HTMLSelectElement>) => {
            const provider = providers.find(p => p.key === e.target.value);
            if (provider) {
                setSelectedProvider(provider);
                if (provider.type === "managed") {
                    setSmtpServer(provider.host);
                    setSmtpPort(provider.port);
                } else {
                    setSmtpServer("");
                    setSmtpPort("");
                }
            }
        },
        [setSelectedProvider, setSmtpPort, setSmtpServer],
    );

    const productName = useProductName();

    return (
        <div className="space-y-4">
            <p>{`Provide your email provider's SMTP credentials to have ${productName} send emails directly using your email provider.`}</p>
            <Select
                label="Email Provider"
                value={selectedProvider.key}
                onChange={handleProviderChange}>
                {providers.map(p => (
                    <option key={p.key} value={p.key}>
                        {p.label}
                    </option>
                ))}
            </Select>
            <Input
                disabled={selectedProvider.type === "managed"}
                label="SMTP Server"
                value={smtpServer}
                onChange={e => setSmtpServer(e.target.value)}
            />
            <Input
                disabled={selectedProvider.type === "managed"}
                label="SMTP Port"
                value={smtpPort}
                onChange={e => setSmtpPort(e.target.value)}
            />
            {selectedProvider.appPasswordLink ? (
                <InlineBanner type="info">
                    For security purposes, it is recommended that you set up an App
                    Password to connect your email provider. Navigate to the link below to
                    set up an app password. Once that is set up, use your email address as
                    your username and the app password as your password. <br />
                    <br />
                    <a
                        href={selectedProvider.appPasswordLink}
                        target="_blank"
                        rel="noreferrer">
                        {selectedProvider.appPasswordLink}
                    </a>
                </InlineBanner>
            ) : null}
            <Input
                autoComplete="off"
                label="Username"
                value={username}
                onChange={e => setUsername(e.target.value)}
            />
            <Input
                autoComplete="off"
                label="Password"
                value={password}
                onChange={e => setPassword(e.target.value)}
                type="password"
            />
            {error ? <InlineBanner type="error">{error}</InlineBanner> : null}
            <div className="flex justify-end items-center space-x-4">
                {createSmtpCredential.isLoading ? <Spinner /> : null}
                <PrimaryButton
                    disabled={createSmtpCredential.isLoading}
                    onClick={handleSave}>
                    Test connection &amp; save
                </PrimaryButton>
                <SecondaryButton
                    disabled={createSmtpCredential.isLoading}
                    onClick={handleCancel}>
                    Cancel
                </SecondaryButton>
            </div>
        </div>
    );
};
