import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    useReactTable,
} from "@tanstack/react-table";
import React from "react";
import { CrmTablePagination } from "./pagination";
import { CrmTableColumn } from "./types";

export interface CrmControlledTableProps<T = unknown> {
    data: T[];
    columns: CrmTableColumn<T>[];
    rowCount: number;
    /**
     * specify page
     * @param pageNumber 1-index page number
     */
    goToPage: (pageNumber: number) => void;
    currentPage: number;
    special?: (props: { record: T | undefined }) => JSX.Element;
    pageSize: number;
    showPaginationWithOnePage?: boolean;
}

/**
 * Controlled table meant for displaying data where pagination and sorting is handled server-side
 */
export const CrmControlledTable = <T,>(
    props: CrmControlledTableProps<T>,
): JSX.Element => {
    const { data, columns, special, currentPage, goToPage, rowCount, pageSize } = props;
    const columnHelper = createColumnHelper<T>();
    const showPaginationOnOnePage = props.showPaginationWithOnePage ?? false;
    const actualColumns = React.useMemo(() => {
        const result = columns.map(c => {
            const key = c.key as keyof T;
            if (c.renderCell) {
                return columnHelper.accessor((row: T) => row[key], {
                    id: key.toString(),
                    header: c.header ?? (key as string),
                    cell: p => {
                        return c.renderCell({ record: p.row.original });
                    },
                });
            }
            return columnHelper.accessor((row: T) => row[key], {
                id: key.toString(),
                header: c.header ?? (key as string),
            });
        });

        if (special) {
            result.push(
                columnHelper.display({
                    id: "_actions",
                    header: "",
                    cell: c => special({ record: c.row.original }),
                }),
            );
        }
        return result;
    }, [columns, columnHelper, special]);

    const table = useReactTable({
        columns: actualColumns,
        data,
        getCoreRowModel: getCoreRowModel(),
    });
    return (
        <div className="flex flex-col">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                            {table.getHeaderGroups().map(headerGroup => (
                                <tr key={headerGroup.id}>
                                    {headerGroup.headers.map(header => (
                                        <th
                                            key={header.id}
                                            colSpan={header.colSpan}
                                            className="py-3.5 text-left text-sm font-semibold text-gray-900 px-3">
                                            {header.isPlaceholder ? null : (
                                                <div>
                                                    <div className="flex items-center space-x-1">
                                                        <div>
                                                            {flexRender(
                                                                header.column.columnDef
                                                                    .header,
                                                                header.getContext(),
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody>
                            {table.getRowModel().rows.map(row => (
                                <tr key={row.id} className="hover:bg-gray-100">
                                    {row.getVisibleCells().map(cell => (
                                        <td
                                            key={cell.id}
                                            className={`px-3 whitespace-nowrap py-4  text-sm font-medium text-gray-900 `}>
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext(),
                                            )}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>

                        {showPaginationOnOnePage || rowCount > pageSize ? (
                            <tfoot>
                                <tr>
                                    <td colSpan={table.getVisibleFlatColumns().length}>
                                        <CrmTablePagination
                                            pageIndex={currentPage - 1}
                                            canPreviousPage={currentPage > 1}
                                            canNextPage={
                                                currentPage * pageSize < rowCount
                                            }
                                            pageCount={Math.ceil(rowCount / pageSize)}
                                            nextPage={() => goToPage(currentPage + 1)}
                                            previousPage={() => goToPage(currentPage - 1)}
                                            gotoPage={(index: number) =>
                                                goToPage(index + 1)
                                            }
                                            pageSize={pageSize}
                                            count={rowCount}
                                        />
                                    </td>
                                </tr>
                            </tfoot>
                        ) : null}
                    </table>
                </div>
            </div>
        </div>
    );
};
