import { CommunityRelationshipType } from "@sp-crm/core";
import { GetClientCommunitiesQuery } from "../../../../generated/graphql";

export const sortAndExcludeClientCommunities = (
    queryData: GetClientCommunitiesQuery,
    excludeRelationshipTypes: CommunityRelationshipType[] = [
        CommunityRelationshipType.excluded,
    ],
) => {
    return queryData.getClient.clientCommunities
        .map((cc, index) => ({
            ...cc,
            order: cc.order ?? index,
        }))
        .sort((a, b) => a.order - b.order)
        .filter(cc => !excludeRelationshipTypes.includes(cc.relationship));
};
