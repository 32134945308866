import * as C from "@sp-crm/core";
import { AutosavingInput } from "components/ui/autosaving-input";
import { UrlButton } from "components/ui/url-button";
import * as React from "react";
import { InDepthQuestionProps } from "./props";

export class TextQuestion extends React.Component<InDepthQuestionProps, undefined> {
    constructor(props: InDepthQuestionProps) {
        super(props);
        this.updateAnswer = this.updateAnswer.bind(this);
    }

    updateAnswer(newVal: string): void {
        this.props.requestAnswerModification(a => {
            a.textAnswer = newVal;
            a.numberAnswer = null;
        });
    }

    isMultiLine(): boolean {
        return this.props.question.interaction === C.InteractionType.multiline;
    }

    getClassName(): string {
        if (this.props.question.interaction === C.InteractionType.url)
            return "flex items-end space-x-1";

        return "";
    }

    initial(): string {
        // This code supports the scenario that a user converts a numberAnswer question (including number or price)
        // to the broader question type of text. This way they get to keep the number value that the old question
        // type had if any.
        return (
            this.props.answer.textAnswer ||
            (this.props.answer.numberAnswer &&
                this.props.answer.numberAnswer.toString()) ||
            ""
        );
    }

    render(): JSX.Element {
        return (
            <div className={this.getClassName()}>
                <AutosavingInput
                    multiLine={this.isMultiLine()}
                    onCommit={newVal => this.updateAnswer(newVal)}
                    initial={this.initial()}
                    key={this.props.question.id}
                    label={this.props.question.title}
                />
                {this.props.question.interaction === C.InteractionType.url ? (
                    <UrlButton url={this.initial()} />
                ) : null}
            </div>
        );
    }
}
