import {
    AdvancedSearchConditionNodeType,
    AdvancedSearchRequestCondition,
} from "generated/graphql";

export type ConditionEditorMode = "simple" | "advanced";

export const simplifiableCondition = (
    condition: AdvancedSearchRequestCondition,
): boolean => {
    if (!condition) {
        return false;
    }
    if (condition.nodeType !== AdvancedSearchConditionNodeType.And) {
        return false;
    }
    if (
        !condition.children.every(c => c.nodeType === AdvancedSearchConditionNodeType.Or)
    ) {
        return false;
    }
    if (
        !condition.children.every(c =>
            c.children.every(
                cc =>
                    cc.nodeType === AdvancedSearchConditionNodeType.FieldCondition ||
                    cc.nodeType === AdvancedSearchConditionNodeType.RelationCondition,
            ),
        )
    ) {
        return false;
    }
    if (
        !condition.children.every(c =>
            c.children.every(cc => {
                if (cc.nodeType !== AdvancedSearchConditionNodeType.RelationCondition) {
                    return true;
                }
                if (cc.children.length !== 1) {
                    return false;
                }
                const child = cc.children[0];
                return simplifiableCondition(child);
            }),
        )
    ) {
        return false;
    }
    return true;
};
