import { Callout } from "components/ui/callout";
import * as React from "react";

export interface IActionBarComponent {
    displayLabel: () => string;
    displayValue: () => string;
    actionBarContentOverride?: () => JSX.Element;
    editableContent: () => JSX.Element;
}

interface ActionBarComponentRendererProps {
    className?: string;
    actionBarObject: IActionBarComponent;
    setForceDismiss?: (forceDismiss: () => void) => void;
    setInitialFocus?: boolean;
}

interface ActionBarComponentRendererState {
    show: boolean;
}

export class ActionBarComponentRenderer extends React.Component<
    ActionBarComponentRendererProps,
    ActionBarComponentRendererState
> {
    public static defaultUnspecifiedValue = "(none)";

    public constructor(p: ActionBarComponentRendererProps) {
        super(p);
        this.state = {
            show: false,
        };
        this.renderButton = this.renderButton.bind(this);
        this.onDismissDialog = this.onDismissDialog.bind(this);
        this.onOpen = this.onOpen.bind(this);
    }

    componentDidMount() {
        if (this.props.setForceDismiss) {
            this.props.setForceDismiss(this.onDismissDialog.bind(this));
        }
    }

    private getStaticHyperlinkText(): string {
        // Note: we could use our defaultUnspecifiedValue here,
        // but I intentionally want to know if a customer ever tells
        // us that "no value set" is showing up since it will be easier
        // to track down any issues.
        let value = this.props.actionBarObject.displayValue();

        if (!value || value.length == 0) {
            value = "no value set";
        }

        return value;
    }

    private actionBarContents(): JSX.Element {
        return (
            <div>
                <div className="action-bar-label">
                    {this.props.actionBarObject.displayLabel()}
                </div>
                <div className="action-bar-value">
                    <a href="#">{this.getStaticHyperlinkText()}</a>
                </div>
            </div>
        );
    }

    private renderButton(): JSX.Element {
        return (
            <div className={"action-bar-component" + " " + this.props.className}>
                {this.props.actionBarObject.actionBarContentOverride
                    ? this.props.actionBarObject.actionBarContentOverride()
                    : this.actionBarContents()}
            </div>
        );
    }

    private onDismissDialog() {
        this.setState({ show: false });
    }

    private onOpen() {
        this.setState({ show: true });
    }

    public render() {
        return (
            <Callout
                renderButton={this.renderButton}
                forceClose={!this.state.show}
                onOpen={this.onOpen}>
                <div className="w-80">{this.props.actionBarObject.editableContent()}</div>
            </Callout>
        );
    }
}
