import { UserId } from "@sp-crm/core";
import {
    AdvancedSearchConditionNodeType,
    AdvancedSearchRelativeDateInterval,
    FieldConditionOperator,
    GetCustomListQuery,
    WorkflowActorMode,
    WorkflowEntityAttachment,
    WorkflowEventType,
    WorkflowPayload,
    WorkflowSettingType,
    WorkflowStatus,
} from "generated/graphql";

export type WorkflowTemplateKey =
    | "referralSource"
    | "moveInFollowUp"
    | "clientStatusTask"
    | "clientStatusEmail"
    | "clientEmailOpen"
    | "checkLaterTask"
    | "checkLaterEmail"
    | "inactiveClientTask";

interface WorkflowTemplateInput {
    name: string;
    description: string;
    currentUserId: UserId;
    clientStatusCustomList: GetCustomListQuery["getCustomList"];
}

interface WorkflowTemplate {
    key: WorkflowTemplateKey;
    text: string;
    helpText: string;
    toWorkflow(input: WorkflowTemplateInput): WorkflowPayload;
}

export const workflowTemplates: Record<WorkflowTemplateKey, WorkflowTemplate> = {
    referralSource: {
        key: "referralSource",
        text: "Referral source follow-up email",
        helpText:
            "Send a customizable email to the referral source when a client referral source is chosen",
        toWorkflow: ({ name, description, currentUserId }) => {
            return {
                name,
                description,
                settings: [
                    {
                        scope: "tenant",
                        key: "emailTemplate",
                        type: WorkflowSettingType.EmailTemplateId,
                        label: "",
                        description: "",
                        defaultValue: "",
                    },
                    {
                        scope: "tenant",
                        key: "bccMyself",
                        type: WorkflowSettingType.Boolean,
                        label: "BCC sender on email",
                        description: "The email sender will receive a copy of the email",
                        defaultValue: "false",
                    },
                ],
                triggerInput: {
                    eventType: WorkflowEventType.EntityUpdated,
                    entityUpdated: {
                        fieldMatchers: [
                            {
                                fieldName: "referralContactId",
                                current: {
                                    specialValue: "$NOT_EMPTY",
                                },
                            },
                        ],
                    },
                },
                attachTo: WorkflowEntityAttachment.Client,
                delay: 0,
                delayRecipe: "10m",
                actionEntities: [
                    {
                        email: {
                            body: "{{settings.emailTemplate}}",
                            subject: "{{settings.emailTemplate}}",
                            to: "{{client.referralContact}}",
                            attachments: "{{settings.emailTemplate}}",
                            bccMyself: "{{settings.bccMyself}}",
                        },
                    },
                ],
                status: WorkflowStatus.Draft,
                defaultActorId: currentUserId,
                actorMode: WorkflowActorMode.AssignedUser,
            };
        },
    },
    moveInFollowUp: {
        key: "moveInFollowUp",
        text: "Move-in follow-up email",
        helpText:
            "Send a customizable email to the client, referral source, or their chosen community after they move in",
        toWorkflow: ({ name, description, currentUserId }) => {
            return {
                name,
                description,
                settings: [
                    {
                        scope: "tenant",
                        key: "recipients",
                        type: WorkflowSettingType.ClientEmails,
                        label: "Choose recipients",
                        description: "",
                        defaultValue:
                            "{{client.bestContactEntity.email1}},{{client.email}}",
                    },
                    {
                        scope: "tenant",
                        key: "emailTemplate",
                        type: WorkflowSettingType.EmailTemplateId,
                        label: "",
                        description: "",
                        defaultValue: "",
                    },
                    {
                        scope: "tenant",
                        key: "bccMyself",
                        type: WorkflowSettingType.Boolean,
                        label: "BCC sender on email",
                        description: "The email sender will receive a copy of the email",
                        defaultValue: "false",
                    },
                    {
                        scope: "tenant",
                        key: "delay",
                        type: WorkflowSettingType.Number,
                        label: "Days after move to send email",
                        description: "",
                        defaultValue: "7",
                    },
                ],
                triggerInput: {
                    eventType: WorkflowEventType.EntityUpdated,
                    entityUpdated: {
                        fieldMatchers: [
                            {
                                fieldName: "moveTimelineActual",
                                current: {
                                    specialValue: "$NOT_EMPTY",
                                },
                            },
                            {
                                fieldName: "status",
                                current: {
                                    values: ["scheduled", "moved", "invoiced", "paid"],
                                },
                            },
                        ],
                    },
                },
                attachTo: WorkflowEntityAttachment.Client,
                delay: 0,
                delayRecipe: `{{client.moveTimelineActual}}+{{settings.delay}}d`,
                actionEntities: [
                    {
                        email: {
                            body: "{{settings.emailTemplate}}",
                            subject: "{{settings.emailTemplate}}",
                            to: "{{settings.recipients}}",
                            attachments: "{{settings.emailTemplate}}",
                            bccMyself: "{{settings.bccMyself}}",
                        },
                    },
                ],
                status: WorkflowStatus.Draft,
                defaultActorId: currentUserId,
                actorMode: WorkflowActorMode.AssignedUser,
            };
        },
    },
    clientStatusTask: {
        key: "clientStatusTask",
        text: "Create task on client status changes",
        helpText:
            "Create a customizable task when a client's status is changed to one of the selected statuses",
        toWorkflow: ({ name, description, currentUserId }) => {
            return {
                name,
                description,
                settings: [
                    {
                        scope: "tenant",
                        key: "description",
                        type: WorkflowSettingType.String,
                        label: "Task description",
                        description: "",
                        defaultValue: "",
                    },
                    {
                        scope: "tenant",
                        key: "dueDateDays",
                        type: WorkflowSettingType.Number,
                        label: "Task due date offset (days after status change)",
                        description: "",
                        defaultValue: "7",
                    },
                    {
                        scope: "tenant",
                        key: "assignedTo",
                        type: WorkflowSettingType.User,
                        label: "Assign task to (leave blank to use client's assigned user)",
                        description: "",
                        defaultValue: "7",
                    },
                ],
                triggerInput: {
                    eventType: WorkflowEventType.EntityUpdated,
                    entityUpdated: {
                        fieldMatchers: [
                            {
                                fieldName: "status",
                                current: {
                                    values: [],
                                },
                            },
                        ],
                    },
                },
                attachTo: WorkflowEntityAttachment.Client,
                delay: 0,
                delayRecipe: "1m",
                actionEntities: [
                    {
                        task: {
                            text: "{{settings.description}}",
                            dueDate: "+{{settings.dueDateDays}}d",
                            assignedTo: "{{settings.assignedTo}}",
                        },
                    },
                ],
                status: WorkflowStatus.Draft,
                defaultActorId: currentUserId,
                actorMode: WorkflowActorMode.AssignedUser,
            };
        },
    },
    clientStatusEmail: {
        key: "clientStatusEmail",
        text: "Send email on client status changes",
        helpText:
            "Send a customizable email when a client's status is changed to one of the selected statuses",
        toWorkflow: ({ name, description, currentUserId }) => {
            return {
                name,
                description,
                settings: [
                    {
                        scope: "tenant",
                        key: "recipients",
                        type: WorkflowSettingType.ClientEmails,
                        label: "Choose recipients",
                        description: "",
                        defaultValue:
                            "{{client.bestContactEntity.email1}},{{client.email}}",
                    },
                    {
                        scope: "tenant",
                        key: "emailTemplate",
                        type: WorkflowSettingType.EmailTemplateId,
                        label: "",
                        description: "",
                        defaultValue: "",
                    },
                    {
                        scope: "tenant",
                        key: "bccMyself",
                        type: WorkflowSettingType.Boolean,
                        label: "BCC sender on email",
                        description: "The email sender will receive a copy of the email",
                        defaultValue: "false",
                    },
                ],
                triggerInput: {
                    eventType: WorkflowEventType.EntityUpdated,
                    entityUpdated: {
                        fieldMatchers: [
                            {
                                fieldName: "status",
                                current: {
                                    values: [],
                                },
                            },
                        ],
                    },
                },
                attachTo: WorkflowEntityAttachment.Client,
                delay: 0,
                delayRecipe: "10m",
                actionEntities: [
                    {
                        email: {
                            body: "{{settings.emailTemplate}}",
                            subject: "{{settings.emailTemplate}}",
                            to: "{{settings.recipients}}",
                            attachments: "{{settings.emailTemplate}}",
                            bccMyself: "{{settings.bccMyself}}",
                        },
                    },
                ],
                status: WorkflowStatus.Draft,
                defaultActorId: currentUserId,
                actorMode: WorkflowActorMode.AssignedUser,
            };
        },
    },
    clientEmailOpen: {
        key: "clientEmailOpen",
        text: "Client re-engagement task",
        helpText:
            "Create follow-up task when a client email is opened after a period of inactivity.",
        toWorkflow: ({ name, description, currentUserId }) => {
            return {
                name,
                description,
                settings: [
                    {
                        scope: "tenant",
                        key: "description",
                        type: WorkflowSettingType.String,
                        label: "Task description",
                        description: "",
                        defaultValue: "Follow-up with re-engaged client {{client.name}}",
                    },
                    {
                        scope: "tenant",
                        key: "assignedTo",
                        type: WorkflowSettingType.User,
                        label: "Assign task to (leave blank to use client's assigned user)",
                        description: "",
                        defaultValue: "",
                    },
                ],
                triggerInput: {
                    eventType: WorkflowEventType.EmailOpened,
                    emailOpened: {
                        entityFieldMatchers: [],
                        activityThresholdDays: 21,
                    },
                },
                attachTo: WorkflowEntityAttachment.Client,
                delay: 0,
                delayRecipe: "1m",
                actionEntities: [
                    {
                        task: {
                            text: "{{settings.description}}",
                            dueDate: "+1d",
                            assignedTo: "{{settings.assignedTo}}",
                            notifyOwner: true,
                            notification: {
                                subject: "Client re-engagement task assigned to you",
                            },
                        },
                    },
                ],
                status: WorkflowStatus.Draft,
                defaultActorId: currentUserId,
                actorMode: WorkflowActorMode.AssignedUser,
            };
        },
    },
    checkLaterTask: {
        key: "checkLaterTask",
        text: "Check back later follow-up task",
        helpText: "Create a task when a client reaches their Check Back Later date",
        toWorkflow: ({ name, description, currentUserId }) => {
            return {
                name,
                description,
                settings: [
                    {
                        scope: "tenant",
                        key: "description",
                        type: WorkflowSettingType.String,
                        label: "Task description",
                        description: "",
                        defaultValue: "Check back later follow-up: {{client.name}}",
                    },
                    {
                        scope: "tenant",
                        key: "assignedTo",
                        type: WorkflowSettingType.User,
                        label: "Assign task to (leave blank to use client's assigned user)",
                        description: "",
                        defaultValue: "",
                    },
                    {
                        scope: "tenant",
                        key: "delay",
                        type: WorkflowSettingType.Number,
                        label: "Days after check later date to create task",
                        description: "",
                        defaultValue: "1",
                    },
                ],
                triggerInput: {
                    eventType: WorkflowEventType.EntityUpdated,
                    entityUpdated: {
                        fieldMatchers: [
                            {
                                fieldName: "statusCheckLaterDate",
                                current: {
                                    specialValue: "$NOT_EMPTY",
                                },
                            },
                            {
                                fieldName: "status",
                                current: {
                                    values: ["checkLater"],
                                },
                            },
                        ],
                    },
                },
                attachTo: WorkflowEntityAttachment.Client,
                delay: 0,
                delayRecipe: "{{client.statusCheckLaterDate}}+{{settings.delay}}d",
                actionEntities: [
                    {
                        task: {
                            text: "{{settings.description}}",
                            dueDate: "+1d",
                            assignedTo: "{{settings.assignedTo}}",
                            notifyOwner: true,
                            notification: {
                                subject: "Client check back later task assigned to you",
                            },
                        },
                    },
                ],
                status: WorkflowStatus.Draft,
                defaultActorId: currentUserId,
                actorMode: WorkflowActorMode.AssignedUser,
            };
        },
    },
    checkLaterEmail: {
        key: "checkLaterEmail",
        text: "Check back later follow-up email",
        helpText: "Send an email when a client reaches their Check Back Later date",
        toWorkflow: ({ name, description, currentUserId }) => {
            return {
                name,
                description,
                settings: [
                    {
                        scope: "tenant",
                        key: "recipients",
                        type: WorkflowSettingType.ClientEmails,
                        label: "Choose recipients",
                        description: "",
                        defaultValue:
                            "{{client.bestContactEntity.email1}},{{client.email}}",
                    },
                    {
                        scope: "tenant",
                        key: "emailTemplate",
                        type: WorkflowSettingType.EmailTemplateId,
                        label: "",
                        description: "",
                        defaultValue: "",
                    },
                    {
                        scope: "tenant",
                        key: "bccMyself",
                        type: WorkflowSettingType.Boolean,
                        label: "BCC sender on email",
                        description: "The email sender will receive a copy of the email",
                        defaultValue: "false",
                    },
                    {
                        scope: "tenant",
                        key: "delay",
                        type: WorkflowSettingType.Number,
                        label: "Days after check later date to send email",
                        description: "",
                        defaultValue: "1",
                    },
                ],
                triggerInput: {
                    eventType: WorkflowEventType.EntityUpdated,
                    entityUpdated: {
                        fieldMatchers: [
                            {
                                fieldName: "statusCheckLaterDate",
                                current: {
                                    specialValue: "$NOT_EMPTY",
                                },
                            },
                            {
                                fieldName: "status",
                                current: {
                                    values: ["checkLater"],
                                },
                            },
                        ],
                    },
                },
                attachTo: WorkflowEntityAttachment.Client,
                delay: 0,
                delayRecipe: "{{client.statusCheckLaterDate}}+{{settings.delay}}d",
                actionEntities: [
                    {
                        email: {
                            body: "{{settings.emailTemplate}}",
                            subject: "{{settings.emailTemplate}}",
                            to: "{{settings.recipients}}",
                            attachments: "{{settings.emailTemplate}}",
                            bccMyself: "{{settings.bccMyself}}",
                        },
                    },
                ],
                status: WorkflowStatus.Draft,
                defaultActorId: currentUserId,
                actorMode: WorkflowActorMode.AssignedUser,
            };
        },
    },
    inactiveClientTask: {
        key: "inactiveClientTask",
        text: "Inactive client follow-up task",
        helpText:
            "Create a task when a client in an active state has no activity for a period of time",
        toWorkflow: ({ name, description, currentUserId, clientStatusCustomList }) => {
            const activeCategory = clientStatusCustomList.categories.find(
                c => c.key === "Active",
            );
            const ongoingCategory = clientStatusCustomList.categories.find(
                c => c.key === "Ongoing",
            );

            if (!activeCategory || !ongoingCategory) {
                return null;
            }

            return {
                name,
                description,
                settings: [
                    {
                        scope: "tenant",
                        key: "description",
                        type: WorkflowSettingType.String,
                        label: "Task description",
                        description: "",
                        defaultValue: "Follow-up with inactive client {{client.name}}",
                    },
                    {
                        scope: "tenant",
                        key: "assignedTo",
                        type: WorkflowSettingType.User,
                        label: "Assign task to (leave blank to use client's assigned user)",
                        description: "",
                        defaultValue: "",
                    },
                ],
                triggerInput: {
                    eventType: WorkflowEventType.Schedule,
                    schedule: {
                        // every day at 8am Eastern time in UTC
                        cron: "0 12 * * *",
                        entityCondition: {
                            nodeType: AdvancedSearchConditionNodeType.And,
                            children: [
                                {
                                    nodeType: AdvancedSearchConditionNodeType.Or,
                                    children: [
                                        ...clientStatusCustomList.items
                                            .filter(
                                                i =>
                                                    i.customListCategoryId ===
                                                        activeCategory.id &&
                                                    !i.isArchived,
                                            )
                                            .map(i => ({
                                                nodeType:
                                                    AdvancedSearchConditionNodeType.FieldCondition,
                                                fieldName: "statusListItem",
                                                operator:
                                                    FieldConditionOperator.CustomListEquals,
                                                textValue: i.id,
                                            })),
                                        ...clientStatusCustomList.items
                                            .filter(
                                                i =>
                                                    i.customListCategoryId ===
                                                        ongoingCategory.id &&
                                                    !i.isArchived,
                                            )
                                            .map(i => ({
                                                nodeType:
                                                    AdvancedSearchConditionNodeType.FieldCondition,
                                                fieldName: "statusListItem",
                                                operator:
                                                    FieldConditionOperator.CustomListEquals,
                                                textValue: i.id,
                                            })),
                                    ],
                                },
                                {
                                    nodeType:
                                        AdvancedSearchConditionNodeType.FieldCondition,
                                    fieldName: "updatedAt",
                                    operator: FieldConditionOperator.DateOnOrBefore,
                                    relativeDate: {
                                        interval: AdvancedSearchRelativeDateInterval.Day,
                                        value: -30,
                                    },
                                },
                            ],
                        },
                    },
                },
                attachTo: WorkflowEntityAttachment.Client,
                delay: 0,
                delayRecipe: null,
                actionEntities: [
                    {
                        task: {
                            text: "{{settings.description}}",
                            dueDate: "+1d",
                            assignedTo: "{{settings.assignedTo}}",
                            notifyOwner: true,
                            notification: {
                                subject: "Inactive client follow-up task assigned to you",
                            },
                        },
                    },
                ],
                status: WorkflowStatus.Draft,
                defaultActorId: currentUserId,
                actorMode: WorkflowActorMode.AssignedUser,
            };
        },
    },
};
