import { ArrowLeftIcon } from "@heroicons/react/24/solid";
import {
    FileEntityId,
    SignatureRequestId,
    formatDate,
    formatSignatureFieldValue,
    validEmail,
} from "@sp-crm/core";
import { QueryRenderer } from "components/clients/show-client/community-comparison/query-renderer";
import {
    GetSignatureRequestQuery,
    SignatureFieldType,
    useGetSignatureRequestQuery,
} from "generated/graphql";
import { produce } from "immer";
import React from "react";
import { pdfjs } from "react-pdf";
import { DocumentCallback, PageCallback } from "react-pdf/dist/cjs/shared/types";
import { DocumentRender, PdfDocumentProxyMetadata } from "../pdf/document-render";
import { compareSignatureTemplateFields } from "../signature-defaults";
import {
    getSignExperienceBackgroundStyle,
    getSignatureBorderStyle,
    getSignaturePlaceholderStyle,
    getSignatureRingStyle,
} from "../signature-template/signature-colors";
import { SignatureFieldIcon } from "../signature-template/signature-field-icon";
import { SignatureRequestRecipients } from "./signature-request-recipients";
import { SignatureRequestStateText } from "./signature-request-state-text";
// Updated versions of this library should be added to s3://placement-crm-cdn/_static/
pdfjs.GlobalWorkerOptions.workerSrc = `https://d1alzjajiceuze.cloudfront.net/_static/${pdfjs.version}/pdf.worker.min.js`;

interface State {
    finishedInitialLoad: boolean;
    isLoading: boolean;
    numPages: number | null;
    pageNumber: number | null;
    currentPageDimensions: number[] | null;
    signatureRequest: GetSignatureRequestQuery["getSignatureRequest"] | null;
}

const initialState: State = {
    finishedInitialLoad: false,
    isLoading: false,
    numPages: null,
    pageNumber: null,
    currentPageDimensions: null,
    signatureRequest: null,
};

type Action =
    | { type: "setPage"; page: number }
    | {
          type: "onDocumentLoadSuccess";
          document: DocumentCallback;
          documentMetadata: PdfDocumentProxyMetadata;
      }
    | { type: "onPageLoadSuccess"; document: PageCallback }
    | {
          type: "initialLoadComplete";
          payload: GetSignatureRequestQuery["getSignatureRequest"];
      };

const reducer = (state: State, action: Action): State => {
    return produce(state, draft => {
        if (action.type === "setPage") {
            draft.pageNumber = action.page;
        }

        if (action.type === "onDocumentLoadSuccess") {
            draft.numPages = action.document.numPages;
            draft.pageNumber = 1;
        }
        if (
            action.type === "initialLoadComplete" &&
            !draft.finishedInitialLoad &&
            action.payload
        ) {
            draft.signatureRequest = action.payload;
            draft.finishedInitialLoad = true;
        }
    });
};

interface SignatureRequestExistingProps {
    signatureRequestId: SignatureRequestId;
    entityId: FileEntityId;
    onRefetch(): void;
}

export const SignatureRequestExisting: React.FC<
    SignatureRequestExistingProps
> = props => {
    const { signatureRequestId, entityId, onRefetch } = props;

    const [state, dispatch] = React.useReducer(reducer, initialState);
    const query = useGetSignatureRequestQuery(
        { signatureRequestId },
        {
            onSuccess: data => {
                dispatch({
                    type: "initialLoadComplete",
                    payload: data.getSignatureRequest,
                });
            },
        },
    );
    const onLoadSuccess = React.useCallback(async (document: DocumentCallback) => {
        const documentMetadata = await document.getMetadata();
        dispatch({ type: "onDocumentLoadSuccess", document, documentMetadata });
    }, []);
    const onPageRender = React.useCallback((document: PageCallback) => {
        dispatch({ type: "onPageLoadSuccess", document });
    }, []);
    const setPage = React.useCallback((pageNumber: number) => {
        dispatch({ type: "setPage", page: pageNumber });
    }, []);
    const refetch = React.useCallback(() => {
        query.refetch();
        onRefetch();
    }, [query, onRefetch]);

    const ccRecipients: string[] = React.useMemo(() => {
        const rawRecipients = state.signatureRequest?.ccRecipients || [];

        return rawRecipients
            .filter(r => validEmail(r.email))
            .map(r => {
                if (r.name && r.name !== r.email) {
                    return `${r.name} <${r.email}>`;
                }

                return r.email;
            });
    }, [state.signatureRequest?.ccRecipients]);

    const fieldsForPage = (state.signatureRequest?.signatureRequestFields || [])
        .filter(f => f.page === state.pageNumber)
        .sort(compareSignatureTemplateFields);

    return (
        <QueryRenderer query={query} name="SignatureRequestExisting.GetSignatureRequest">
            {data => (
                <div className="space-y-4">
                    <div className="space-y-2">
                        <div>
                            <p>Template</p>
                            <p className="font-bold">
                                {data.getSignatureRequest.filename}
                            </p>
                        </div>
                        <div>
                            <p>Status</p>
                            <p className="font-bold">
                                <SignatureRequestStateText
                                    state={data.getSignatureRequest.state}
                                />
                            </p>
                        </div>
                        <div>
                            <p>Sent</p>
                            <p className="font-bold">
                                {formatDate(data.getSignatureRequest.createdAt)}
                            </p>
                        </div>
                        <div>
                            <p>Recipients</p>
                            <SignatureRequestRecipients
                                request={data.getSignatureRequest}
                                entityId={entityId}
                                onRefetch={refetch}
                            />
                        </div>
                        {ccRecipients.length > 0 ? (
                            <div>
                                <p>CC Recipients</p>
                                <p className="font-bold">{ccRecipients.join(", ")}</p>
                            </div>
                        ) : null}
                    </div>
                    <div>
                        <p>Document preview</p>
                        <DocumentRender
                            documentUrl={`/api/files/download/${data.getSignatureRequest.signatureTemplateFileId}`}
                            pageNumber={state.pageNumber}
                            setPageNumber={setPage}
                            onRenderSuccess={onPageRender}
                            onLoadSuccess={onLoadSuccess}>
                            {fieldsForPage.map(f => (
                                <SignExperiencePlaceholderField
                                    field={f}
                                    key={f.id}
                                    isHighlighted={false}
                                />
                            ))}
                        </DocumentRender>
                    </div>
                </div>
            )}
        </QueryRenderer>
    );
};

interface SignExperiencePlaceholderFieldProps {
    field: GetSignatureRequestQuery["getSignatureRequest"]["signatureRequestFields"][number];
    isHighlighted: boolean;
}

const SignExperiencePlaceholderField: React.FC<
    SignExperiencePlaceholderFieldProps
> = props => {
    const { field, isHighlighted } = props;
    const fieldStyles: React.CSSProperties = {
        left: field.x,
        top: field.y,
        width: field.width,
        height: field.height,
        fontSize: field.fontSize,
        lineHeight: `${field.height - 1}px`,
    };
    const fieldClasses = `absolute rounded-sm p-1 border-2 flex items-center space-x-2 ${getSignaturePlaceholderStyle(
        field.recipientIndex,
    )} ${getSignatureBorderStyle(field.recipientIndex)} ${
        isHighlighted ? getSignatureRingStyle(field.recipientIndex) : ""
    } ${field.fieldType === SignatureFieldType.Checkbox ? "justify-center" : ""}`;
    const arrowStyles: React.CSSProperties = {
        left: field.x + field.width + 8,
        top: field.y + field.height / 2 - 14,
    };
    const arrowClasses = `absolute rounded-full p-1 w-8 h-8 text-white ${getSignExperienceBackgroundStyle(
        field.recipientIndex,
    )}`;

    const formattedValue = formatSignatureFieldValue(field);

    if (formattedValue || field.placeholder) {
        fieldStyles.fontFamily = field.font;
    }

    return (
        <>
            <div className={fieldClasses} style={fieldStyles}>
                {formattedValue || field.placeholder ? (
                    <p>{formattedValue || field.placeholder}</p>
                ) : (
                    <>
                        <SignatureFieldIcon
                            fieldType={field.fieldType}
                            recipientIndex={field.recipientIndex}
                        />
                        <p>{field.fieldType}</p>
                    </>
                )}
            </div>
            {isHighlighted ? (
                <ArrowLeftIcon style={arrowStyles} className={arrowClasses} />
            ) : null}
        </>
    );
};
