import {
    CalendarDate,
    CalendarDateTime,
    TaskParentId,
    TaskTypeId,
    UserId,
    getCalendarDateTime,
} from "@sp-crm/core";
import { UserSelect } from "components/shared/user-select";
import { CalendarDateTimeInput } from "components/ui/calendar-date-time";
import { Input } from "components/ui/input";
import { defaultLinkStyle } from "components/ui/link";
import { PrimaryButton } from "components/ui/primary-button";
import { SecondaryButton } from "components/ui/secondary-button";
import { Spinner } from "components/ui/spinner";
import { UpdateTaskPayload } from "generated/graphql";
import React, { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import {
    FilterableTaskTypes,
    allTaskTypesSentinel,
    unsetTaskTypeSentinel,
} from "store/slices/tasks-dashboard";
import { TaskTypeSelect } from "./task-type-select";
import { Taskv2 } from "./types";

interface TaskFormV2Props {
    initial: Taskv2;
    mode: "update" | "create";
    onClose: () => void;
    saveTask: (parentId: TaskParentId, payload: UpdateTaskPayload) => Promise<void>;
}

export const TaskFormV2: React.FC<TaskFormV2Props> = props => {
    const { task, entity } = props.initial;
    const { onClose, saveTask, mode } = props;
    const [changes, setChanges] = React.useState<UpdateTaskPayload>({});
    const [updating, setUpdating] = React.useState(false);

    const handleCancel = useCallback(
        (e: React.MouseEvent) => {
            e.preventDefault();
            e.stopPropagation();
            setChanges({});
            onClose();
        },
        [onClose, setChanges],
    );

    const handleSave = useCallback(
        async (e: React.MouseEvent) => {
            e.preventDefault();
            e.stopPropagation();
            const saveParams: UpdateTaskPayload =
                mode === "create"
                    ? {
                          completedAt: task.completedAt,
                          dueDate: task.dueDate,
                          dueDateTime: task.dueDateTime,
                          ownerId: task.ownerId,
                          taskTypeId: task.taskTypeId,
                          status: task.status,
                          text: task.text,
                          ...changes,
                      }
                    : changes;

            if (Object.keys(saveParams).length > 0) {
                setUpdating(true);
                await saveTask(task.parentId, saveParams);
                setUpdating(false);
            }
            setChanges({});
            onClose();
        },
        [saveTask, setChanges, onClose, task, changes, setUpdating, mode],
    );

    const handleDueDateChange = useCallback(
        (d: CalendarDateTime) => {
            if (d.value.hasTime) {
                setChanges({
                    ...changes,
                    dueDate: null,
                    dueDateTime: d.value.date,
                });
            } else {
                setChanges({
                    ...changes,
                    dueDate: d.value.date.toString(),
                    dueDateTime: null,
                });
            }
        },
        [changes, setChanges],
    );

    const dueDate: CalendarDate | null = useMemo(() => {
        if ("dueDate" in changes) {
            return CalendarDate.parse(changes.dueDate).getOrElse(null);
        }

        if (task.dueDate) {
            return CalendarDate.parse(task.dueDate).getOrElse(null);
        }

        return null;
    }, [changes.dueDate, task.dueDate]); // eslint-disable-line react-hooks/exhaustive-deps

    const dueDateTime: Date | null = useMemo(() => {
        if ("dueDateTime" in changes) {
            return changes.dueDateTime ? new Date(changes.dueDateTime) : null;
        }

        if (task.dueDateTime) {
            return new Date(task.dueDateTime);
        }

        return null;
    }, [changes.dueDateTime, task.dueDateTime]); // eslint-disable-line react-hooks/exhaustive-deps

    const taskTypeId: TaskTypeId | null = useMemo(() => {
        if ("taskTypeId" in changes) {
            return changes.taskTypeId;
        }

        return task.taskTypeId;
    }, [changes.taskTypeId, task.taskTypeId]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleTaskTypeChange = useCallback(
        (v: FilterableTaskTypes) => {
            setChanges({
                ...changes,
                taskTypeId:
                    v === unsetTaskTypeSentinel || v === allTaskTypesSentinel ? null : v,
            });
        },
        [changes, setChanges],
    );

    return (
        <div className="space-y-1">
            <div className="pb-4">
                <p>Associated with</p>
                {entity ? (
                    <div>
                        <Link className={defaultLinkStyle} to={`${entity.path}/tasks`}>
                            {entity.displayName}
                        </Link>
                    </div>
                ) : null}
            </div>
            <div className="space-y-1 lg:space-y-0 lg:flex lg:space-x-2 lg:items-center">
                <div className="flex-1">
                    <Input
                        label="Title"
                        autoFocus
                        value={changes.text ?? task.text ?? ""}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setChanges({ ...changes, text: e.target.value })
                        }
                    />
                </div>
                <div className="flex-1">
                    <TaskTypeSelect
                        value={taskTypeId ?? unsetTaskTypeSentinel}
                        onChange={handleTaskTypeChange}
                        allowUnsetTaskType
                        unsetLabel=""
                    />
                </div>
            </div>
            <CalendarDateTimeInput
                label="Due"
                value={getCalendarDateTime(dueDate, dueDateTime)}
                onChange={handleDueDateChange}
            />
            <UserSelect
                includeEveryone={false}
                includeUnassigned={false}
                label="Assigned to"
                value={changes.ownerId || task.ownerId}
                onChange={v => setChanges({ ...changes, ownerId: v as UserId })}
            />
            <div className="flex items-center space-x-2">
                <PrimaryButton onClick={handleSave}>
                    <span className="text-sm">Save</span>
                </PrimaryButton>
                <SecondaryButton onClick={handleCancel}>
                    <span className="text-sm">Cancel</span>
                </SecondaryButton>
                {updating ? <Spinner /> : null}
            </div>
        </div>
    );
};
