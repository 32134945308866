import { Checkbox } from "components/ui/checkbox";
import { PrimaryButton } from "components/ui/primary-button";
import React, { ChangeEvent, FormEvent, MouseEvent, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "store/state"; // Adjust the import path as necessary
import * as actions from "../../store/actions";

export const Eula: React.FC = () => {
    const dispatch = useDispatch();

    const userPayload = useSelector((state: ApplicationState) => {
        return state.eula.userPayload;
    });

    const [checked, setChecked] = useState<boolean>(false);
    const queryClient = useQueryClient();

    const handleSubmit = (
        e: FormEvent<HTMLFormElement> | MouseEvent<HTMLButtonElement>,
    ): void => {
        e.preventDefault();
        if (checked) {
            actions.signEula(userPayload, queryClient, dispatch);
        } else {
            alert(
                "You must agree to the Terms of Service and Privacy Policy to continue.",
            );
        }
    };

    const changeValue = (e: ChangeEvent<HTMLInputElement>): void => {
        setChecked(e.target.checked);
    };

    const label = (
        <span>
            I have read and agree to both the{" "}
            <a href="http://seniorplace.io/eula/" target="_blank" rel="noreferrer">
                Terms of Service
            </a>{" "}
            and the{" "}
            <a href="http://seniorplace.io/privacy/" target="_blank" rel="noreferrer">
                Privacy Policy
            </a>
            .
        </span>
    );

    return (
        <span>
            <div className="page-header row">
                <div className="page-title">
                    <h2>Terms of Service</h2>
                </div>
            </div>
            <div className="col main-body full-width">
                <form onSubmit={handleSubmit}>
                    <div className="space-y-2">
                        <Checkbox
                            id="agree"
                            checked={checked}
                            onChange={changeValue}
                            label={label}
                        />
                        <PrimaryButton id="proceed" onClick={handleSubmit}>
                            I Agree
                        </PrimaryButton>
                    </div>
                </form>
            </div>
        </span>
    );
};
