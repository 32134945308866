import { CrmPersona } from "components/ui/persona";
import React from "react";
import { Link } from "react-router-dom";
import { useTenantSettings } from "store/selectors/hooks";

interface ClientPersonaLinkProps {
    client: {
        appLink: string;
        name?: string;
        bestContactName?: string;
    };
}

export const ClientPersonaLink: React.FC<ClientPersonaLinkProps> = props => {
    const { client } = props;
    const stopPropagation: React.MouseEventHandler<HTMLAnchorElement> = React.useCallback(
        (e: React.MouseEvent<HTMLAnchorElement>) => {
            e.stopPropagation();
        },
        [],
    );

    const tenantSettings = useTenantSettings();
    const showClientFirst = tenantSettings.showClientFirst;

    const primaryName = (showClientFirst ? client.name : client.bestContactName) || "";
    const secondaryName = (showClientFirst ? client.bestContactName : client.name) || "";

    return (
        <Link onClick={stopPropagation} to={client.appLink}>
            <div className="flex items-center">
                <div className="flex-shrink-0 h-10 w-10">
                    <CrmPersona size="large" initialsOnly name={client.name} />
                </div>
                <div className="ml-4">
                    <div className="text-sm font-medium truncate text-gray-900">
                        {primaryName}
                    </div>
                    <div className="text-sm truncate text-gray-500">{secondaryName}</div>
                </div>
            </div>
        </Link>
    );
};
