import { formatDateNoTime, LatLng } from "@sp-crm/core";
import { HoveredMapEntity } from "components/community-search/community-map/types";
import { Icon } from "components/icon";
import { ClampedText } from "components/ui/clamped-text";
import { ReferenceCommunitySearchQuery } from "generated/graphql";
import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { usePreferences } from "store/selectors/hooks";
import { twoLineFormattedAddress } from "util/address";
import { PhoneSingleNumber } from "util/phone-display";
import { breakNewlines } from "util/text";

interface ReferenceCommunitySearchListProps {
    communitySearchResults: ReferenceCommunitySearchQuery["referenceCommunitySearch"]["hits"];
    setHoveredOrganization: (hoveredOrganization: HoveredMapEntity | null) => void;
}

export const ReferenceCommunitySearchList: React.FC<
    ReferenceCommunitySearchListProps
> = props => {
    const { communitySearchResults, setHoveredOrganization } = props;

    const handleEndHover = useCallback(() => {
        setHoveredOrganization(null);
    }, [setHoveredOrganization]);

    return (
        <ul className="space-y-4">
            {communitySearchResults.map(community => (
                <li key={community.id}>
                    <ReferenceCommunitySearchCard
                        community={community}
                        onHover={setHoveredOrganization}
                        onEndHover={handleEndHover}
                    />
                </li>
            ))}
        </ul>
    );
};

interface ReferenceCommunitySearchCardProps {
    community: ReferenceCommunitySearchQuery["referenceCommunitySearch"]["hits"][0];
    onHover: (hoveredOrganization: HoveredMapEntity | null) => void;
    onEndHover: () => void;
}

const ReferenceCommunitySearchCard: React.FC<
    ReferenceCommunitySearchCardProps
> = props => {
    const { community, onHover, onEndHover } = props;

    const { showReferralLastUpdatedDateOnMainTable } = usePreferences();

    const handleMouseEnter = () => {
        let location: LatLng = null;
        if (community.latitude && community.longitude) {
            location = {
                lat: parseFloat(community.latitude),
                lng: parseFloat(community.longitude),
            };
        }
        onHover({ entityId: community.id, location });
    };

    const handleMouseLeave = () => {
        onEndHover();
    };

    return (
        <div
            className="col-span-1 divide-y divide-gray-200 rounded md:rounded-lg bg-white shadow p-6"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}>
            <div className="flex-1 overflow-auto">
                <div className="flex space-x-2">
                    <div className="flex-1">
                        <h3 className="twoverride text-gray-900 text-lg">
                            <Link
                                to={`/communities/show/${community.id}`}
                                className="twoverride hover:underline">
                                <div className="flex space-x-2 items-center">
                                    <div className="w-6">
                                        <Icon name={"57_Community"} />
                                    </div>
                                    <div>{community.name || "(no name)"}</div>
                                </div>
                            </Link>
                        </h3>
                        {twoLineFormattedAddress(
                            community.address,
                            community.city,
                            community.state,
                            community.zip,
                            "text-sm",
                        )}
                        <div className="text-sm">
                            {community.phone ? (
                                <PhoneSingleNumber
                                    displayName="office"
                                    phoneNumber={community.phone}
                                />
                            ) : null}
                            {community.fax ? (
                                <PhoneSingleNumber
                                    displayName="fax"
                                    phoneNumber={community.fax}
                                />
                            ) : null}
                        </div>
                    </div>
                </div>
                <div className="mt-4 border-t border-gray-200 pt-2">
                    <dl className="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2">
                        {community.summary ? (
                            <div className="sm:col-span-2">
                                <dt className="text-sm font-medium text-gray-500">
                                    Summary
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900">
                                    <ClampedText lines={3}>
                                        {breakNewlines(community.summary)}
                                    </ClampedText>
                                </dd>
                            </div>
                        ) : null}
                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">
                                Number of referrals
                            </dt>
                            <dd className="mt-1 flex text-sm text-gray-900">
                                {community.numberOfReferrals}
                            </dd>
                        </div>
                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">
                                Most recent referral
                            </dt>
                            <dd className="mt-1 flex text-sm text-gray-900">
                                {formatDateNoTime(community.mostRecentReferral)}
                            </dd>
                        </div>
                        {showReferralLastUpdatedDateOnMainTable ? (
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">
                                    Last updated
                                </dt>
                                <dd className="mt-1 flex text-sm text-gray-900">
                                    {formatDateNoTime(community.updatedAt)}
                                </dd>
                            </div>
                        ) : null}
                    </dl>
                </div>
            </div>
        </div>
    );
};
