import * as C from "@sp-crm/core";
import * as React from "react";
import { ISearchFilterClosable } from "./search-filter-closable.interface";
import { SearchFilterProps } from "./search-filter-props.interface";
import { SearchQuestion } from "./search-question";
import { ISearchAnswer } from "./util";

interface SearchQuestionCalloutProps {
    question: C.IQuestion;
    answers: ISearchAnswer[];
}

interface SearchQuestionCalloutState {
    answers: ISearchAnswer[];
}

type SearchQuestionCalloutFilterProps = SearchQuestionCalloutProps & SearchFilterProps;
export class SearchQuestionCallout
    extends React.Component<SearchQuestionCalloutFilterProps, SearchQuestionCalloutState>
    implements ISearchFilterClosable
{
    constructor(props: SearchQuestionCalloutFilterProps) {
        super(props);
        this.state = { answers: props.answers || ([] as ISearchAnswer[]) };
        this.onClose = this.onClose.bind(this);
    }

    getClearedAnswers(): ISearchAnswer[] {
        return this.state.answers.filter(
            answer => answer.questionId !== this.props.question.id,
        );
    }

    onClose() {
        this.props.onClose({
            activeState: { answers: this.state.answers },
            defaultState: { answers: this.getClearedAnswers() },
        });
    }

    onValid() {
        this.props.onValid(true);
    }

    UNSAFE_componentWillMount() {
        this.onValid();
    }

    componentWillUnmount() {
        this.onClose();
    }

    answerForQuestion(): ISearchAnswer {
        const question = this.props.question;
        return (
            this.state.answers.find(x => x.questionId == question.id) || {
                selectionAnswer: [],
                questionId: question.id,
                booleanAnswer: "na",
            }
        );
    }

    updateAdvancedSearch(a: ISearchAnswer): void {
        const answers: ISearchAnswer[] = this.state.answers.filter(
            x => x.questionId != a.questionId,
        );
        answers.push(a);
        const newState: SearchQuestionCalloutState = { ...this.state, answers: answers };
        this.setState(newState, () => {
            this.onValid();
        });
    }

    render() {
        return (
            <div className="min-w-max">
                <SearchQuestion
                    searchAnswer={this.answerForQuestion()}
                    onChange={e => this.updateAdvancedSearch(e)}
                    key={this.props.question.id}
                    question={this.props.question}
                    noTitle={true}
                />
            </div>
        );
    }
}
