import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { PencilIcon } from "@heroicons/react/24/outline";
import { defaultLinkStyle } from "components/ui/link";
import { PrimaryButton } from "components/ui/primary-button";
import { SecondaryButton } from "components/ui/secondary-button";
import { SelectBaseOption } from "helpers/select-defs";
import * as React from "react";
import util from "../../util";
import Shared from "../shared";

interface ChangeDateCalloutProps {
    onDateChanged: (newDate: Date) => void; // callback for when a new date is picked
    calloutCaption: string; // Caption that will show at the top of the callout, if any
    revertBackToDate?: Date; // The "original" date value that can be reset back to.
    // If not specified, no "reset" option is available.
    revertBackDateLabel?: string; // The display string to explain the "revertBackToDate"
    initialDatePickerValue?: Date; // The currently stored date value (this is what will show in the date picker by default)
    // If not specified, "today" will be used.
}

interface ChangeDateCalloutState {
    isSubmitted: boolean; // Save has been pressed to commit and close the callout
    isCancelled: boolean; // Cancel has been pressed to dismiss the callout
    datePickerDate: Date; // new date value chosen in the date picker control
    isDatePickerUpdated: boolean; // true if the date picker value has been updated in any way
    timePickerTime?: number; // hour for the time of day
    isDatePickerReverted: boolean; // true if revert is the most recent user action
}

export class ChangeDateCallout extends React.Component<
    ChangeDateCalloutProps,
    ChangeDateCalloutState
> {
    private baseState: ChangeDateCalloutState = null;

    constructor(props: ChangeDateCalloutProps) {
        super(props);
        this.state = {
            isSubmitted: false,
            isCancelled: false,
            datePickerDate:
                this.props.initialDatePickerValue ||
                this.props.revertBackToDate ||
                new Date(),
            isDatePickerUpdated: false,
            isDatePickerReverted: false,
            timePickerTime: null,
        };
        this.baseState = this.state;
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    onSelectDate(newDate: Date) {
        this.setState({
            ...this.state,
            datePickerDate: newDate,
            isDatePickerUpdated: true,
            isDatePickerReverted: false,
        });
    }

    onResetDate() {
        // this function shouldn't even be callable if we don't have a real 'revertBackToDate'
        // but in case we somehow don't have one, we shouldn't call setState.
        if (this.props.revertBackToDate)
            this.setState({
                ...this.state,
                datePickerDate: this.props.revertBackToDate,
                isDatePickerUpdated: true,
                isDatePickerReverted: true,
                timePickerTime: null,
            });
    }

    onSave(callback?: () => void) {
        if (this.state.isDatePickerUpdated || this.state.timePickerTime) {
            if (this.state.isDatePickerReverted) {
                // if we're reverting the date picker date, then we're clearing it out.
                // Setting to null rather than revertBackToDate since revertBackToDate
                // doesn't have a time value, so setting it to that doesn't really "reset"
                // it all the way.
                this.props.onDateChanged(null);
            } else {
                let newDateTime = null;

                if (this.state.isDatePickerUpdated) {
                    newDateTime = this.state.datePickerDate;
                } else {
                    newDateTime = this.props.initialDatePickerValue;
                }

                if (this.state.timePickerTime) {
                    newDateTime.setHours(this.state.timePickerTime.valueOf());
                    newDateTime.setMinutes(0);
                    newDateTime.setSeconds(0);
                }

                this.props.onDateChanged(newDateTime);
            }
        }
        if (callback) {
            callback();
        }
    }

    onCancel() {
        this.setState(this.baseState);
    }

    showRevertBackDetails(): JSX.Element {
        if (
            this.props.revertBackToDate &&
            this.props.revertBackDateLabel != null &&
            this.props.revertBackDateLabel != ""
        ) {
            return (
                <div>
                    {this.props.revertBackDateLabel +
                        " " +
                        util.date.formatDateNoTime(this.props.revertBackToDate)}
                    <p>
                        <a
                            className={defaultLinkStyle}
                            href="#"
                            onClick={() => this.onResetDate()}>
                            (Revert to original value)
                        </a>
                    </p>
                </div>
            );
        }

        return null;
    }

    onSetTime(newVal: string) {
        this.setState({
            ...this.state,
            timePickerTime: newVal == "unchanged" ? null : Number(newVal),
            isDatePickerReverted: false,
        });
    }

    getDefaultTimePickerObject(): SelectBaseOption {
        if (
            this.state.isDatePickerReverted ||
            this.props.revertBackToDate.valueOf() ==
                this.props.initialDatePickerValue.valueOf()
        ) {
            return {
                value: "unchanged",
                text:
                    "Original time (" +
                    util.date.timeOnly(this.props.revertBackToDate) +
                    ")",
            };
        }

        return {
            value: "unchanged",
            text:
                "Current time (" +
                util.date.timeOnly(this.props.initialDatePickerValue) +
                ")",
        };
    }

    render() {
        return (
            <Popover className="relative text-left">
                {params => (
                    <>
                        <PopoverButton className="rounded-full text-gray-500 hover:bg-gray-100">
                            <PencilIcon className="w-4 h-4" />
                        </PopoverButton>
                        <PopoverPanel className="absolute top-0 right-0 z-10">
                            <div className="bg-white p-2 sm:p-4 rounded shadow-lg space-y-2 w-64">
                                <div>
                                    <Shared.DateInput
                                        label={this.props.calloutCaption}
                                        value={this.state.datePickerDate}
                                        onCommit={newDate => this.onSelectDate(newDate)}
                                    />
                                    {this.showRevertBackDetails()}
                                </div>
                                <div className="flex flex-row-reverse">
                                    <PrimaryButton
                                        className="ml-2"
                                        onClick={() => this.onSave(() => params.close())}>
                                        Save
                                    </PrimaryButton>
                                    <SecondaryButton onClick={() => params.close()}>
                                        Cancel
                                    </SecondaryButton>
                                </div>
                            </div>
                        </PopoverPanel>
                    </>
                )}
            </Popover>
        );
    }
}
