import { CustomListItemId } from "@sp-crm/core";
import { CustomListMultiselect } from "components/shared/custom-list-multiselect";
import { CustomListKey } from "generated/graphql";
import { produce } from "immer";
import React from "react";
import { EditableWorkflow } from "./types";

const isStatusFieldName = (fieldName: string) =>
    fieldName === "status" || fieldName === "statusListItem";

const findClientStatusFieldMatcher = (workflow: EditableWorkflow) => {
    return (
        workflow?.trigger?.entityUpdated?.fieldMatchers?.find(
            matcher =>
                isStatusFieldName(matcher.fieldName) &&
                Array.isArray(matcher.current?.values) &&
                !matcher.previous?.values &&
                !matcher.previous?.specialValue,
        ) || null
    );
};

export const hasClientStatusTrigger = (workflow: EditableWorkflow) => {
    return !!findClientStatusFieldMatcher(workflow);
};

interface ClientStatusTriggerEditorProps {
    workflow: EditableWorkflow;
    onChange: (newWorkflow: EditableWorkflow) => void;
}

export const ClientStatusTriggerEditor: React.FC<
    ClientStatusTriggerEditorProps
> = props => {
    const { workflow, onChange } = props;

    const handleChange = (selectedIds: CustomListItemId[]) => {
        const newWorkflow = produce(workflow, draft => {
            const matcher = findClientStatusFieldMatcher(draft);

            if (matcher) {
                matcher.fieldName = "statusListItem";
                matcher.current.values = selectedIds;
            }
        });

        onChange(newWorkflow);
    };

    const fieldMatcher = findClientStatusFieldMatcher(workflow);

    if (!fieldMatcher) {
        return null;
    }

    return (
        <div>
            <p>Client status filter</p>
            <CustomListMultiselect
                customListKey={CustomListKey.ClientStatus}
                value={fieldMatcher.current.values || []}
                onChange={handleChange}
            />
            <div className="ml-6 text-gray-500 group-hover:text-gray-900 text-sm">
                The workflow will run when the client reaches any of the selected statuses
            </div>
        </div>
    );
};
