import React from "react";
import { NavLink } from "react-router-dom";

interface SubnavMenuProps {
    children: React.ReactNode;
}

/**
 * Subnav is the preferred way to render a subnav on a page.
 * Mimics the "tabs" component in the design system.
 * @param props accepts children
 */
export const SubnavMenu: React.FC<SubnavMenuProps> = props => {
    return (
        <div>
            <ul className="flex space-x-1 lg:space-x-2">{props.children}</ul>
        </div>
    );
};

interface SubnavMenuItemProps {
    to: string;
    exact?: boolean;
    children: React.ReactNode;
}
export const SubnavMenuItem: React.FC<SubnavMenuItemProps> = props => {
    const { to, children, exact } = props;
    return (
        <li>
            <NavLink
                to={to}
                exact={exact}
                activeClassName="twoverride whitespace-nowrap text-brand-700 bg-brand-100 px-3 py-2 font-semibold rounded-md flex items-center space-x-1"
                className="twoverride whitespace-nowrap text-gray-500 hover:text-gray-700 hover:bg-gray-50 hover:shadow-sm px-3 py-2 font-semibold rounded-md flex items-center space-x-1">
                {children}
            </NavLink>
        </li>
    );
};
