import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { LayoutSectionKey } from "@sp-crm/core";
import React, { useCallback, useState } from "react";
import { LayoutSectionResult } from "./layout-items";
import { LayoutSectionHeader } from "./layout-section-header";

interface LayoutSectionPickerProps {
    layoutSections: LayoutSectionResult[];
    onSectionsChanged: (layoutSections: LayoutSectionResult[]) => void;
    hideSections?: LayoutSectionKey[];
}

export const LayoutSectionPicker: React.FC<LayoutSectionPickerProps> = props => {
    const { layoutSections, onSectionsChanged, hideSections } = props;

    const [expanded, setExpanded] = useState<boolean>(false);

    const toggleVisible = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e && e.preventDefault && e.preventDefault();
            setExpanded(v => !v);
        },
        [setExpanded],
    );

    return (
        <div>
            <div className="mb-2">
                <button
                    onClick={toggleVisible}
                    className="flex items-center space-x-2 cursor-pointer">
                    <h3>Customize section visibility</h3>
                    <ChevronDownIcon
                        className={`w-6 h-6 transform transition duration-50 ${
                            expanded ? "rotate-180" : "rotate-0"
                        }`}
                    />
                </button>
            </div>
            {expanded ? (
                <div>
                    <p className="text-sm">Configure which sections will be visible:</p>
                    <ul className="mt-2">
                        {layoutSections.map(layoutSection => {
                            const isHidden = !!hideSections?.includes(
                                layoutSection.sectionKey as LayoutSectionKey,
                            );

                            if (isHidden) {
                                return null;
                            }

                            return (
                                <li className="py-1" key={layoutSection.id}>
                                    <div
                                        className={`border p-1 ${
                                            layoutSection.visible ? "" : "opacity-40"
                                        }`}>
                                        <LayoutSectionHeader
                                            layoutSection={layoutSection}
                                            visible={layoutSection.visible}
                                            onVisibleClicked={() =>
                                                onSectionsChanged(
                                                    layoutSections.map(ls => {
                                                        if (ls.id === layoutSection.id) {
                                                            return {
                                                                ...ls,
                                                                visible: !ls.visible,
                                                            };
                                                        }

                                                        return ls;
                                                    }),
                                                )
                                            }
                                            allowEditVisibility={true}
                                        />
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            ) : null}
        </div>
    );
};
