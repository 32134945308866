import { Input } from "components/ui/input";
import { Select } from "components/ui/select";
import { useGetExternalInvoiceAccountSettingsQuery } from "generated/graphql";
import React, { useEffect, useMemo } from "react";
import { useNow } from "store/selectors/hooks";
import { stableQueryOptions } from "util/requests";

interface InvoiceDueDateAndTermsProps {
    dueDate: string;
    onDueDateChange: (dueDate: string) => void;
    termId?: string;
    onTermIdChange: (termId: string) => void;
}

export const InvoiceDueDateAndTerms: React.FC<InvoiceDueDateAndTermsProps> = props => {
    const { dueDate, onDueDateChange, termId, onTermIdChange } = props;

    const now = useNow();

    const getExternalInvoiceAccountSettings = useGetExternalInvoiceAccountSettingsQuery(
        {},
        stableQueryOptions(),
    );

    useEffect(() => {
        if (getExternalInvoiceAccountSettings.data && !termId) {
            const paymentTerms =
                getExternalInvoiceAccountSettings.data.getExternalInvoiceAccountSettings
                    .paymentTerms;

            const defaultTerm = paymentTerms.find(t => t.default) || paymentTerms[0];

            if (defaultTerm) {
                onTermIdChange(defaultTerm.id);
            }
        }
    }, [getExternalInvoiceAccountSettings.data]); // eslint-disable-line react-hooks/exhaustive-deps

    const currentTerm = useMemo(() => {
        return (
            getExternalInvoiceAccountSettings.data?.getExternalInvoiceAccountSettings?.paymentTerms?.find(
                t => t.id === termId,
            ) || null
        );
    }, [termId, getExternalInvoiceAccountSettings.data]);

    useEffect(() => {
        if (currentTerm && typeof currentTerm.dueDays === "number") {
            const dueDate = new Date(now);
            dueDate.setDate(dueDate.getDate() + currentTerm.dueDays);
            onDueDateChange(dueDate.toISOString().split("T")[0]);
        }
    }, [currentTerm]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleDueDateChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            onDueDateChange(e.target.value);
        },
        [onDueDateChange],
    );

    const loading = !currentTerm;

    return (
        <div className="flex items-center space-x-4">
            <div className="flex-1">
                <Select
                    disabled={loading}
                    label="Terms"
                    value={currentTerm?.id}
                    onChange={e => {
                        onTermIdChange(e.target.value);
                    }}>
                    {getExternalInvoiceAccountSettings.data ? (
                        getExternalInvoiceAccountSettings.data.getExternalInvoiceAccountSettings.paymentTerms.map(
                            t => {
                                return (
                                    <option key={t.id} value={t.id}>
                                        {t.name}
                                    </option>
                                );
                            },
                        )
                    ) : (
                        <option>Loading...</option>
                    )}
                </Select>
            </div>
            <div className="flex-1">
                <Input
                    disabled={loading}
                    label="Due date"
                    type="date"
                    value={dueDate}
                    onChange={handleDueDateChange}
                />
            </div>
        </div>
    );
};
