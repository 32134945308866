import * as C from "@sp-crm/core";
import { Checkbox } from "components/ui/checkbox";
import * as React from "react";
import { ISearchAnswer } from "./util";

interface SearchQuestionProps {
    question: C.IQuestion;
    searchAnswer: ISearchAnswer;
    noTitle?: boolean;
    onChange: (a: ISearchAnswer) => void;
}

export class SearchQuestion extends React.Component<SearchQuestionProps, undefined> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    updateBinaryAnswer(event: any) {
        const isChecked: boolean = event.target.checked;
        const newAnswer: ISearchAnswer = {
            questionId: this.props.question.id,
            booleanAnswer: isChecked ? "true" : "any",
            selectionAnswer: [],
        };
        this.props.onChange(newAnswer);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    updateNaryAnswer(event: any) {
        let newSelection = this.props.searchAnswer.selectionAnswer.slice(0);
        if (event.target.checked) {
            newSelection.push(event.target.value);
        } else {
            newSelection = newSelection.filter((x: string) => x !== event.target.value);
        }
        const newAnswer: ISearchAnswer = {
            questionId: this.props.question.id,
            booleanAnswer: "na",
            selectionAnswer: newSelection,
        };
        this.props.onChange(newAnswer);
    }

    renderBinaryField(): JSX.Element {
        const control = (
            <Checkbox
                checked={this.props.searchAnswer.booleanAnswer === "true"}
                value="true"
                onChange={e => this.updateBinaryAnswer(e)}
                label={this.props.question.title}
            />
        );
        if (this.props.noTitle) {
            return <div>{control}</div>;
        }
        return (
            <div>
                <div className="font-semibold">{this.props.question.title}</div>
                <div>{control}</div>
            </div>
        );
    }

    renderNaryField(): JSX.Element {
        const options = this.props.question.prompts.map(p => {
            return (
                <Checkbox
                    key={p.id}
                    checked={this.props.searchAnswer.selectionAnswer.indexOf(p.id) >= 0}
                    value={p.id}
                    onChange={e => this.updateNaryAnswer(e)}
                    label={p.title}
                />
            );
        });

        if (this.props.noTitle) {
            return <div>{options}</div>;
        }
        return (
            <div>
                <div className="font-semibold">{this.props.question.title}</div>
                <div>{options}</div>
            </div>
        );
    }

    render() {
        if (this.props.question.questionType === C.QuestionType.binary) {
            return this.renderBinaryField();
        }
        if (this.props.question.questionType === C.QuestionType.nary) {
            return this.renderNaryField();
        }

        console.warn(`control unknown ${this.props.question.title}`);
        return <span />;
    }
}

export function renderable(question: C.IQuestion): boolean {
    return (
        question.questionType === C.QuestionType.binary ||
        question.questionType === C.QuestionType.nary ||
        question.questionType === C.QuestionType.currency ||
        question.questionType === C.QuestionType.range
    );
}
