import { handleEvent } from "./user-events";

/* eslint-disable @typescript-eslint/no-explicit-any */
const config = {
    selector: "#product-updates",
    account: "x9p4My",
    callbacks: {
        onShowWidget: () => {
            handleEvent("open-feature-announcements");
        },
        // onWidgetReady: function (widget: any) {
        //     console.log("Widget is here!");
        //     console.log("unseen entries count: " + widget.getUnseenCount());
        // },

        // onShowDetails: function (changelog: any) {
        //     console.log(changelog.position); // position in the widget
        //     console.log(changelog.id); // unique id
        //     console.log(changelog.title); // title
        //     console.log(changelog.category); // category, lowercased
        // },
        // onReadMore: function (changelog: any) {
        //     console.log(changelog); // same changelog object as in onShowDetails callback
        // },
        // onHideWidget: function () {
        //     console.log("Who turned off the light?");
        // },
    },
};

declare const Headway: any;
let headwayInitializeAttempts = 0;
const maxHeadwayInitializeAttempts = 60;
export const initializeUpdates = () => {
    if (typeof Headway === "undefined") {
        headwayInitializeAttempts++;

        if (headwayInitializeAttempts <= maxHeadwayInitializeAttempts) {
            setTimeout(initializeUpdates, 1000);
        } else {
            console.warn(
                `Headway failed to initialize after ${maxHeadwayInitializeAttempts} attempts`,
            );
        }
    } else {
        Headway.init(config);
    }
};
