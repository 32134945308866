import { ClientId } from "@sp-crm/core";
import { CopyableLink } from "components/shared/copyable-link";
import { ErrorMessage } from "components/ui/error-message";
import { Panel } from "components/ui/panel/panel";
import { PanelType } from "components/ui/panel/panel-type";
import { PrimaryButton } from "components/ui/primary-button";
import { Spinner } from "components/ui/spinner";
import React, { useCallback } from "react";
import {
    GetTokenForClientQuery,
    LayoutSectionKey,
    LayoutSectionParentKey,
    UpdateTokenRequest,
    useGetLayoutSectionsQuery,
    useGetTokenForClientQuery,
    useUpdateTokenMutation,
} from "../../../../generated/graphql";
import { LayoutSectionResult } from "../../../layout/layout-items";
import { LayoutSectionPicker } from "../../../layout/layout-section-picker";
import { QueryRenderer } from "./query-renderer";

interface LiveCommunityComparisonLinkProps {
    onDismiss: () => void;
    clientId: ClientId;
}

export const LiveCommunityComparisonLink: React.FC<
    LiveCommunityComparisonLinkProps
> = props => {
    const { onDismiss, clientId } = props;
    return (
        <Panel
            type={PanelType.large}
            isOpen={true}
            onDismiss={onDismiss}
            headerText="Shareable Community Comparison Link">
            <div className="space-y-4">
                <LiveCommunityComparisonQuery clientId={clientId} />
                <div className="flex justify-end">
                    <PrimaryButton onClick={onDismiss}>Close</PrimaryButton>
                </div>
            </div>
        </Panel>
    );
};

interface LiveCommunityComparisonQueryProps {
    clientId: ClientId;
}

interface LayoutLiveCommunityComparisonQueryProps {
    token: GetTokenForClientQuery["getTokenForClient"];
    onSectionKeysChanged: (sectionKeys: LayoutSectionKey[]) => void;
}

const LayoutLiveCommunityComparisonQuery: React.FC<
    LayoutLiveCommunityComparisonQueryProps
> = props => {
    const { token, onSectionKeysChanged } = props;
    const query = useGetLayoutSectionsQuery({
        parentKey: LayoutSectionParentKey.CommunityComparisonTable,
    });

    const onSectionsChanged = useCallback(
        (layoutSections: LayoutSectionResult[]) => {
            onSectionKeysChanged(
                layoutSections
                    .filter(s => s.visible)
                    .map(s => s.sectionKey as LayoutSectionKey),
            );
        },
        [onSectionKeysChanged],
    );

    const overrideVisibility = (layoutSections: LayoutSectionResult[]) => {
        if (token.sectionKeys) {
            return layoutSections.map(ls => ({
                ...ls,
                visible: token.sectionKeys.includes(ls.sectionKey as LayoutSectionKey),
            }));
        }

        return layoutSections;
    };

    return (
        <QueryRenderer query={query} name="LayoutLiveCommunityComparisonQuery">
            {data => (
                <div>
                    <LayoutSectionPicker
                        layoutSections={overrideVisibility(
                            data.getLayoutSections.layoutSections,
                        )}
                        onSectionsChanged={onSectionsChanged}
                    />
                    <CopyableLink url={token.url} />
                </div>
            )}
        </QueryRenderer>
    );
};

const LiveCommunityComparisonQuery: React.FC<
    LiveCommunityComparisonQueryProps
> = props => {
    const query = useGetTokenForClientQuery({ id: props.clientId });
    const update = useUpdateTokenMutation();
    const onChange = (data: GetTokenForClientQuery["getTokenForClient"]) => {
        (async () => {
            const updateRequest: UpdateTokenRequest = {
                clientId: data.clientId,
                includeContacts: data.includeContacts,
                includeFinancial: data.includeFinancial,
                includeLicensing: data.includeLicensing,
                sectionKeys: data.sectionKeys,
            };
            await update.mutateAsync({ updateRequest });
            query.refetch();
        })();
    };
    if (query.isLoading) {
        return <Spinner />;
    }
    if (query.isError || !query.data) {
        return (
            <ErrorMessage component="LiveCommunityComparisonQuery">
                <pre>{JSON.stringify(query.error, null, 2)}</pre>
            </ErrorMessage>
        );
    }

    return (
        <QueryRenderer query={query} name="LiveCommunityComparisonQuery">
            {data => (
                <LayoutLiveCommunityComparisonQuery
                    token={data.getTokenForClient}
                    onSectionKeysChanged={sectionKeys =>
                        onChange({
                            ...query.data.getTokenForClient,
                            sectionKeys,
                        })
                    }
                />
            )}
        </QueryRenderer>
    );
};
