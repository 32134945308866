import { Location } from "history";
import React from "react";
import { match } from "react-router";
import { NavLink } from "react-router-dom";
import { AddClientButton } from "../clients/add-client-button/add-client-button";

const checkPipelineLink = (
    matchArg: match<unknown>,
    location: Location<unknown>,
): boolean => {
    if (matchArg && matchArg.isExact) {
        return true;
    }
    if (location && location.pathname === "/") return true;
    return false;
};

export const DashboardNavigation: React.FC<unknown> = () => (
    <div className="md:flex md:items-end md:justify-between px-2  md:px-0">
        <ul className="main-menu text-lg font-semibold space-y-2 md:items-end md:ml-4 md:flex md:space-x-4 md:space-y-0">
            <li>
                <NavLink
                    isActive={checkPipelineLink}
                    activeClassName="bg-brand-100 text-brand-800 hover:bg-brand-200 hover:text-brand-900"
                    className="px-3 py-2 rounded hover:bg-gray-100 hover:text-black text-gray-800 whitespace-nowrap block"
                    to="/pipeline">
                    Pipeline
                </NavLink>
            </li>
            <li>
                <NavLink
                    activeClassName="bg-brand-100 text-brand-800 hover:bg-brand-200 hover:text-brand-900"
                    className="px-3 py-2 rounded hover:bg-gray-100 hover:text-black text-gray-800 whitespace-nowrap block"
                    to="/timeline">
                    Move Timeline
                </NavLink>
            </li>
            <li>
                <NavLink
                    activeClassName="bg-brand-100 text-brand-800 hover:bg-brand-200 hover:text-brand-900"
                    className="px-3 py-2 rounded hover:bg-gray-100 hover:text-black text-gray-800 whitespace-nowrap block"
                    to="/clients">
                    All Clients
                </NavLink>
            </li>
        </ul>
        <div className="mr-3 my-2">
            <AddClientButton />
        </div>
    </div>
);
