import { Checkbox } from "components/ui/checkbox";
import * as React from "react";
import { Rating, RatingDisplay } from "../../ui/rating";
import { ISearchFilterClosable } from "./search-filter-closable.interface";
import { SearchFilterProps } from "./search-filter-props.interface";
import { ISearchRating } from "./util";

interface SearchRatingProps {
    ratingValue?: number;
    includeUnrated?: boolean;
    isPreview?: boolean;
}

type SearchRatingFilterProps = SearchRatingProps & SearchFilterProps;
export class SearchRating
    extends React.Component<SearchRatingFilterProps, ISearchRating>
    implements ISearchFilterClosable
{
    DEFAULT_VALUES = {
        rating: 0,
        includeUnrated: true,
    };

    constructor(props: SearchRatingFilterProps) {
        super(props);
        this.state = {
            value: props.ratingValue || 1,
            includeUnrated:
                typeof props.includeUnrated === "boolean"
                    ? props.includeUnrated
                    : this.DEFAULT_VALUES.includeUnrated,
        };

        this.onRatingChanged = this.onRatingChanged.bind(this);
        this.onCheckboxChanged = this.onCheckboxChanged.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    onClose() {
        this.props.onClose({
            activeState: {
                rating: {
                    value: this.state.value,
                    includeUnrated: this.state.includeUnrated,
                },
            },
            defaultState: {
                rating: {
                    value: this.DEFAULT_VALUES.rating,
                    includeUnrated: this.DEFAULT_VALUES.includeUnrated,
                },
            },
        });
    }

    onValid() {
        this.props.onValid(true);
    }

    UNSAFE_componentWillMount() {
        if (this.props.isPreview) {
            return;
        }

        // Always valid - min 1 star
        this.onValid();
    }

    componentWillUnmount() {
        if (this.props.isPreview) {
            return;
        }
        this.onClose();
    }

    onRatingChanged(rating: number) {
        this.setState({ ...this.state, value: rating });
    }

    onCheckboxChanged(checked: boolean) {
        this.setState({ ...this.state, includeUnrated: checked });
    }

    renderEditMode(): JSX.Element {
        return (
            <div className="search-rating-component min-w-80">
                <h3>My rating of:</h3>
                <div className="one-line">
                    <Rating onChange={this.onRatingChanged} rating={this.state.value} />
                    <span>and higher</span>
                </div>
                <Checkbox
                    label="Include unrated communities in results"
                    checked={this.state.includeUnrated}
                    onChange={e => this.onCheckboxChanged(e.target.checked)}
                />
            </div>
        );
    }

    renderPreviewMode(): JSX.Element {
        return <RatingDisplay rating={this.props.ratingValue} />;
    }

    render() {
        if (this.props.isPreview) {
            return this.renderPreviewMode();
        }

        return this.renderEditMode();
    }
}
