import { ContentFull, ContentHeader, ContentSubheader } from "components/layout";
import { LocaleContext } from "components/locale-provider/locale-provider";
import { NumberInput } from "components/shared/number-input";
import { AutosavingInput } from "components/ui/autosaving-input";
import { AutosavingStateInput } from "components/ui/autosaving-state-input";
import React from "react";
import { useTenantSettings } from "store/selectors/hooks";
import { useCommitTenantSettingGeneric } from "./agency-settings-hooks";

export const AgencySettingsInvoices: React.FC<unknown> = () => {
    const settings = useTenantSettings();
    const commitTenantSetting = useCommitTenantSettingGeneric();
    return (
        <LocaleContext.Consumer>
            {locale => (
                <div className="space-y-4 lg:space-y-8">
                    <ContentFull>
                        <ContentHeader>Invoices</ContentHeader>
                        <ContentSubheader>
                            To whom and where should communities send invoice payments?
                        </ContentSubheader>
                        <div className="space-y-4">
                            <AutosavingInput
                                label="Name invoices payable to"
                                multiLine={false}
                                initial={settings.invoicePayableName}
                                onCommit={newValue =>
                                    commitTenantSetting("invoicePayableName", newValue)
                                }
                            />
                            <AutosavingInput
                                label="Address"
                                multiLine={false}
                                initial={settings.invoicePayableAddress}
                                onCommit={newValue =>
                                    commitTenantSetting("invoicePayableAddress", newValue)
                                }
                            />
                            <AutosavingInput
                                label="City"
                                multiLine={false}
                                initial={settings.invoicePayableCity}
                                onCommit={newValue =>
                                    commitTenantSetting("invoicePayableCity", newValue)
                                }
                            />
                            <AutosavingStateInput
                                label={locale.strings.state}
                                initial={settings.invoicePayableState}
                                onCommit={newValue =>
                                    commitTenantSetting("invoicePayableState", newValue)
                                }
                            />
                            <AutosavingInput
                                label={locale.strings.zip}
                                multiLine={false}
                                initial={settings.invoicePayableZip}
                                onCommit={newValue =>
                                    commitTenantSetting("invoicePayableZip", newValue)
                                }
                            />
                            <AutosavingInput
                                label="Phone"
                                multiLine={false}
                                initial={settings.invoicePayablePhone}
                                onCommit={newValue =>
                                    commitTenantSetting("invoicePayablePhone", newValue)
                                }
                            />
                            <AutosavingInput
                                label="Email"
                                multiLine={false}
                                initial={settings.invoicePayableEmail}
                                onCommit={newValue =>
                                    commitTenantSetting("invoicePayableEmail", newValue)
                                }
                            />
                            <AutosavingInput
                                label={
                                    <div>
                                        <p>Payment notes</p>
                                        <p className="text-gray-500 text-sm">
                                            For payment provider information like Zelle or
                                            Paypal
                                        </p>
                                    </div>
                                }
                                multiLine={false}
                                initial={settings.invoicePayableNotes}
                                onCommit={newValue =>
                                    commitTenantSetting("invoicePayableNotes", newValue)
                                }
                            />
                            <NumberInput
                                label="How many days after client move-in date is invoice due?"
                                initial={settings.defaultDaysBeforeInvoiceDue}
                                onCommit={newValue =>
                                    commitTenantSetting(
                                        "defaultDaysBeforeInvoiceDue",
                                        newValue,
                                    )
                                }
                            />
                            <AutosavingInput
                                label={
                                    <div>
                                        <p>Default invoice notes</p>
                                        <p className="text-gray-500 text-sm">
                                            Pre-populate new invoices with these notes
                                        </p>
                                    </div>
                                }
                                multiLine={true}
                                initial={settings.invoiceDefaultNotes}
                                onCommit={newValue =>
                                    commitTenantSetting("invoiceDefaultNotes", newValue)
                                }
                            />
                        </div>
                    </ContentFull>
                </div>
            )}
        </LocaleContext.Consumer>
    );
};
