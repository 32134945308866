import { Maybe, RegionId } from "@sp-crm/core";
import { Checkbox } from "components/ui/checkbox";
import { Panel } from "components/ui/panel/panel";
import { PanelType } from "components/ui/panel/panel-type";
import { PrimaryButton } from "components/ui/primary-button";
import { SecondaryButton } from "components/ui/secondary-button";
import moment from "moment";
import * as React from "react";
import Shared from "../shared";
import { Radio, RadioOption } from "../shared/radio";

interface CSVDownloadProps {
    onDismiss: () => void;
    regionId: RegionId;
    regionName: string;
    canAccessMultipleRegions: boolean;
    clientDisclosuresEnabled: boolean;
}

const clientDownloadOptions: RadioOption[] = [
    {
        key: "all",
        text: "Download all clients regardless of date values",
    },
    {
        key: "creationDate",
        text: "Only download clients that were added within a certain date range",
    },
    {
        key: "placementDate",
        text: "Only download clients that have Move dates in a certain range",
    },
];

type ClientDownloadOption = "all" | "creationDate" | "placementDate";

interface CSVDownloadState {
    showPanel: boolean;
    downloadClients: boolean;
    downloadCommunities: boolean;
    downloadCommunityContacts: boolean;
    downloadReferralSources: boolean;
    downloadInvoices: boolean;
    downloadDisclosureAcknowledgements: boolean;
    downloadClientStartDate?: Date;
    downloadClientEndDate?: Date;
    downloadClientMoveDateStartRange?: Date;
    downloadCClientMoveDateEndRange?: Date;
    showClientDateOptions: boolean;
    clientsToDownload: ClientDownloadOption;
    showAdvancedClientDownloadOptions: boolean;
    showAdvancedCommunityDownloadOptions: boolean;
    myClientsOnly: boolean;
    includeClientCustomQuestions: boolean;
    includeCommunityCustomQuestions: boolean;
    regionIdOrAll: string;
}

type AdvancedOption =
    | "showAdvancedClientDownloadOptions"
    | "showAdvancedCommunityDownloadOptions";

export class CSVDownload extends React.Component<CSVDownloadProps, CSVDownloadState> {
    downloadForm: HTMLFormElement;

    constructor(props: CSVDownloadProps) {
        super(props);
        this.state = {
            showPanel: false,
            downloadClients: false,
            downloadCommunities: false,
            downloadCommunityContacts: false,
            downloadReferralSources: false,
            downloadInvoices: false,
            downloadDisclosureAcknowledgements: false,
            downloadClientStartDate: null,
            downloadClientEndDate: null,
            downloadClientMoveDateStartRange: null,
            downloadCClientMoveDateEndRange: null,
            showClientDateOptions: false,
            clientsToDownload: "all",
            showAdvancedClientDownloadOptions: false,
            showAdvancedCommunityDownloadOptions: false,
            myClientsOnly: false,
            includeClientCustomQuestions: false,
            includeCommunityCustomQuestions: false,
            regionIdOrAll: props.regionId,
        };
    }

    download() {
        this.downloadForm.submit();
        this.props.onDismiss(); /* 777 ms -- time for the new window to open and the form to submit */
    }

    dismiss(): boolean {
        return true;
    }

    toggleValue(value: boolean, stateProp: string): void {
        this.setState({ ...this.state, [`${stateProp}`]: value });
    }

    downloadCheckboxSetting(
        stateProp: string,
        initialValue: boolean,
        checkboxLabel: string,
        checkboxDescription?: string,
        advancedCheckboxOptions?: () => JSX.Element,
    ): JSX.Element {
        return (
            <div>
                <div>
                    <Checkbox
                        checked={initialValue}
                        onChange={newValue =>
                            this.toggleValue(newValue.target.checked, stateProp)
                        }
                        label={checkboxLabel}
                    />
                    <div>{checkboxDescription}</div>
                </div>
                <div className="advanced-checkbox-options">
                    {advancedCheckboxOptions && advancedCheckboxOptions()}
                </div>
            </div>
        );
    }

    regionSelection(): JSX.Element {
        if (!this.props.canAccessMultipleRegions) {
            return null;
        }
        const options: RadioOption[] = [
            {
                key: this.props.regionId,
                text: `This region only (${this.props.regionName})`,
            },
            {
                key: "all",
                text: "All regions I have access to",
            },
        ];
        return (
            <Radio
                options={options}
                value={this.state.regionIdOrAll}
                label="Download CSV for"
                onChange={val =>
                    this.setState({
                        regionIdOrAll: val,
                    })
                }
            />
        );
    }

    clientDateRestrictionChoices(): JSX.Element {
        return (
            <Radio
                options={clientDownloadOptions}
                value={this.state.clientsToDownload}
                label="Date Filters"
                onChange={newVal =>
                    this.setState({
                        ...this.state,
                        clientsToDownload: newVal as ClientDownloadOption,
                    })
                }
            />
        );
    }

    advancedOption(optionToEnable: AdvancedOption): JSX.Element {
        return (
            <a
                href="#"
                className={"link-clear visible"}
                onClick={() =>
                    this.setState({
                        ...this.state,
                        [optionToEnable]: true,
                    })
                }>
                Show Advanced Options
            </a>
        );
    }

    showAdvancedClientOptions(): JSX.Element {
        // Only show the advanced link if we're downloading clients,
        // and we are *not already* showing the advanced options.
        if (!this.state.downloadClients || this.state.showAdvancedClientDownloadOptions) {
            return null;
        }

        return this.advancedOption("showAdvancedClientDownloadOptions");
    }

    showAdvancedCommunityOptions(): JSX.Element {
        // Only show the advanced link if we're downloading communities,
        // and we are *not already* showing the advanced options.
        if (
            !this.state.downloadCommunities ||
            this.state.showAdvancedCommunityDownloadOptions
        ) {
            return null;
        }

        return this.advancedOption("showAdvancedCommunityDownloadOptions");
    }

    advancedClientOptions(): JSX.Element {
        if (!this.state.downloadClients || !this.state.showAdvancedClientDownloadOptions)
            return null;

        const dateSelection = (
            <div className="options">
                <div className="option">
                    <Shared.DateInput
                        label="Start date"
                        onCommit={newValue => {
                            this.setState({
                                ...this.state,
                                downloadClientStartDate: newValue
                                    ? moment(newValue).utc().toDate()
                                    : null,
                            });
                        }}
                    />
                </div>
                <div className="option">
                    <Shared.DateInput
                        label="End date"
                        onCommit={newValue => {
                            this.setState({
                                ...this.state,
                                downloadClientEndDate: newValue
                                    ? moment(newValue).utc().toDate()
                                    : null,
                            });
                        }}
                    />
                </div>
            </div>
        );

        return (
            <div className="csv--client-download-options">
                {this.downloadCheckboxSetting(
                    "myClientsOnly",
                    this.state.myClientsOnly,
                    "Only Download Clients Assigned to me (not all clients)",
                    null,
                )}
                {this.downloadCheckboxSetting(
                    "includeClientCustomQuestions",
                    this.state.includeClientCustomQuestions,
                    "Include Custom Question Fields/Answers",
                    null,
                )}
                {this.clientDateRestrictionChoices()}
                {this.state.clientsToDownload != "all" && dateSelection}
            </div>
        );
    }

    advancedCommunityOptions(): JSX.Element {
        if (
            !this.state.downloadCommunities ||
            !this.state.showAdvancedCommunityDownloadOptions
        )
            return null;

        return (
            <div className="csv--community-download-options">
                {this.downloadCheckboxSetting(
                    "includeCommunityCustomQuestions",
                    this.state.includeCommunityCustomQuestions,
                    "Include Custom Question Fields/Answers",
                    null,
                )}
            </div>
        );
    }

    downloadClientAdvancedOptions(): JSX.Element {
        return (
            <div>
                {this.showAdvancedClientOptions()}
                {this.advancedClientOptions()}
            </div>
        );
    }

    downloadCommunityAdvancedOptions(): JSX.Element {
        return (
            <div>
                {this.showAdvancedCommunityOptions()}
                {this.advancedCommunityOptions()}
            </div>
        );
    }

    isAnyCheckboxChecked(): boolean {
        return (
            this.state.downloadClients ||
            this.state.downloadCommunities ||
            this.state.downloadCommunityContacts ||
            this.state.downloadReferralSources ||
            this.state.downloadInvoices ||
            this.state.downloadDisclosureAcknowledgements
        );
    }

    render() {
        return (
            <Panel
                type={PanelType.large}
                hasCloseButton={true}
                onDismiss={() => this.props.onDismiss()}
                isOpen={true}
                headerText="Download CSV Data">
                <div className="space-y-4">
                    <h3>Select at least one set of data to download:</h3>
                    {this.downloadCheckboxSetting(
                        "downloadClients",
                        this.state.downloadClients,
                        "Clients",
                        "Download basic client data for active and closed clients.",
                        this.downloadClientAdvancedOptions.bind(this),
                    )}
                    {this.downloadCheckboxSetting(
                        "downloadCommunities",
                        this.state.downloadCommunities,
                        "Communities",
                        "Download basic community data for all communities.",
                        this.downloadCommunityAdvancedOptions.bind(this),
                    )}
                    {this.downloadCheckboxSetting(
                        "downloadCommunityContacts",
                        this.state.downloadCommunityContacts,
                        "Community Contacts",
                        "Download all contacts for all communities.",
                    )}
                    {this.downloadCheckboxSetting(
                        "downloadReferralSources",
                        this.state.downloadReferralSources,
                        "Referral Sources",
                        "Download basic referral source details.",
                    )}
                    {this.downloadCheckboxSetting(
                        "downloadInvoices",
                        this.state.downloadInvoices,
                        "Invoices",
                        "Download all invoice data.",
                    )}
                    {this.props.clientDisclosuresEnabled
                        ? this.downloadCheckboxSetting(
                              "downloadDisclosureAcknowledgements",
                              this.state.downloadDisclosureAcknowledgements,
                              "Disclosure Acknowledgements",
                              "Download all disclosure acknowledgements.",
                          )
                        : null}
                    {this.regionSelection()}
                    <form
                        target="_blank"
                        method="GET"
                        ref={x => (this.downloadForm = x)}
                        action={`/api/csv/${this.state.regionIdOrAll}`}>
                        <div className="flex items-center space-x-4 justify-end">
                            <SecondaryButton
                                type="button"
                                onClick={() => this.props.onDismiss()}>
                                Cancel
                            </SecondaryButton>
                            <PrimaryButton
                                type="button"
                                disabled={!this.isAnyCheckboxChecked()}
                                onClick={() => this.download()}>
                                Download
                            </PrimaryButton>
                        </div>
                        <input
                            type="hidden"
                            value={this.state.downloadClients + ""}
                            name="downloadClients"
                        />
                        <input
                            type="hidden"
                            value={this.state.myClientsOnly + ""}
                            name="myClientsOnly"
                        />
                        <input
                            type="hidden"
                            value={this.state.includeClientCustomQuestions + ""}
                            name="includeClientCustomQuestions"
                        />
                        <input
                            type="hidden"
                            value={Maybe.fromValue(this.state.downloadClientStartDate)
                                .map(d => d.toISOString())
                                .getOrElse("")}
                            name={
                                this.state.clientsToDownload === "creationDate"
                                    ? "clientStartDate"
                                    : "startMoveDate"
                            }
                        />
                        <input
                            type="hidden"
                            value={Maybe.fromValue(this.state.downloadClientEndDate)
                                .map(d => d.toISOString())
                                .getOrElse("")}
                            name={
                                this.state.clientsToDownload === "creationDate"
                                    ? "clientEndDate"
                                    : "endMoveDate"
                            }
                        />
                        <input
                            type="hidden"
                            value={this.state.downloadCommunities + ""}
                            name="downloadCommunities"
                        />
                        <input
                            type="hidden"
                            value={this.state.includeCommunityCustomQuestions + ""}
                            name="includeCommunityCustomQuestions"
                        />
                        <input
                            type="hidden"
                            value={this.state.downloadCommunityContacts + ""}
                            name="downloadCommunityContacts"
                        />
                        <input
                            type="hidden"
                            value={this.state.downloadReferralSources + ""}
                            name="downloadReferralSources"
                        />
                        <input
                            type="hidden"
                            value={this.state.downloadInvoices + ""}
                            name="downloadInvoices"
                        />
                        {this.props.clientDisclosuresEnabled ? (
                            <input
                                type="hidden"
                                value={this.state.downloadDisclosureAcknowledgements + ""}
                                name="downloadDisclosureAcknowledgements"
                            />
                        ) : null}
                    </form>
                </div>
            </Panel>
        );
    }
}
