import { CalendarDate } from "@sp-crm/core";
import * as React from "react";

interface CalendarDateProps {
    className?: string;
    label: string;
    initial?: CalendarDate | undefined | null;
    value?: CalendarDate | undefined | null;
    onChange?: (newValue: CalendarDate | null) => void;
    /**
     * @deprecated
     */
    onCommit?: (newValue: CalendarDate | null) => void;
}

interface CalendarDateState {
    modifiedValue: CalendarDate | undefined | null;
}

export class CalendarDateInput extends React.Component<
    CalendarDateProps,
    CalendarDateState
> {
    private inputRef = React.createRef<HTMLInputElement>();
    private lastCommitValue: CalendarDate | null = null;

    constructor(props: CalendarDateProps) {
        super(props);
        this.state = {
            modifiedValue: props.value || props.initial,
        };
        this.lastCommitValue = props.initial || props.value;
        this.changeDate = this.changeDate.bind(this);
        this.onBlur = this.onBlur.bind(this);
    }

    componentDidUpdate(prevProps: CalendarDateProps): void {
        if (prevProps.value !== this.props.value) {
            this.setState({ modifiedValue: this.props.value });
            this.updateInput(this.props.value);
        }
    }

    componentDidMount() {
        this.updateInput(this.state.modifiedValue);
    }

    private changeDate(e: React.ChangeEvent<HTMLInputElement>): void {
        const input = e.target.value;
        if (input === "") {
            return;
        }
        let calendarDate: CalendarDate | null = null;
        calendarDate = CalendarDate.parse(input).getOrElse(CalendarDate.future);
        this.setState({ modifiedValue: calendarDate });
        if (this.props.onChange) {
            this.props.onChange(calendarDate);
        }
    }

    private onBlur(): void {
        if (this.props.onCommit) {
            const potentialCommitValue = this.props.value ?? this.state.modifiedValue;
            if (
                (this.lastCommitValue ? this.lastCommitValue.toString() : null) !==
                (potentialCommitValue ? potentialCommitValue.toString() : null)
            ) {
                this.lastCommitValue = potentialCommitValue;
                this.props.onCommit(this.lastCommitValue);
            }
        }
    }

    private updateInput(dateValue: CalendarDate | null): void {
        const newValue = dateValue ? dateValue.toString() : "";
        if (this.inputRef.current) {
            this.inputRef.current.value = newValue;
        }
    }

    render(): JSX.Element {
        const label = this.props.label;
        return (
            <div className="w-full">
                <label>
                    {label ? <div className="mb-1">{label}</div> : null}
                    <input
                        className="form-input rounded-sm md:rounded w-full disabled:bg-gray-100 border-gray-300 hover:border-brand-600 active:border-brand-400 focus:ring-2 focus:ring-brand-300"
                        type="date"
                        ref={this.inputRef}
                        onChange={this.changeDate}
                        onBlur={this.onBlur}
                    />
                </label>
            </div>
        );
    }
}
