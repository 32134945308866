import * as React from "react";
import { Content, SectionHeader, Stage } from "../layout";

export const Debug: React.FunctionComponent = () => {
    return (
        <Stage>
            <SectionHeader title="Debug" />
            <Content>
                <h2>Debug</h2>
            </Content>
        </Stage>
    );
};
