import { IQuestion, PromptId, QuestionId } from "@sp-crm/core";
import React from "react";
import { useCommunityTypeQuestion, useRegionId, useRegions } from "store/selectors/hooks";
import { ListableCommunity } from "../props";

interface CommunityCardTypesProps {
    community: ListableCommunity;
}

// NB avoid the temptation to refactor!
// postcss looks for explicitly used class names, so
// these all must appear literally in code
const TailwindColors = [
    ["bg-blue-100", "text-blue-800"],
    ["bg-green-100", "text-green-800"],
    ["bg-purple-100", "text-purple-800"],
    ["bg-cyan-100", "text-cyan-800"],
    ["bg-teal-100", "text-teal-800"],
    ["bg-emerald-100", "text-emerald-800"],
    ["bg-lime-100", "text-lime-800"],
    ["bg-amber-100", "text-amber-800"],
    ["bg-yellow-100", "text-yellow-800"],
    ["bg-orange-100", "text-orange-800"],
    ["bg-fuchsia-100", "text-fuchsia-800"],
    ["bg-pink-100", "text-pink-800"],
    ["bg-indigo-100", "text-indigo-800"],
] as const;
type TailwindColorPair = typeof TailwindColors[number];

interface CommunityTypeResult {
    questionId: QuestionId;
    promptId: PromptId;
    promptText: string;
    generatedColorPair: TailwindColorPair;
    order: number;
}

const buildPromptColorPairs = (
    communityTypeQuestion: IQuestion,
): [PromptId, TailwindColorPair][] => {
    const promptColorPairs: [PromptId, TailwindColorPair][] = [];
    if (!communityTypeQuestion.prompts || communityTypeQuestion.prompts.length === 0) {
        return promptColorPairs;
    }
    const sortedPrompts = communityTypeQuestion.prompts
        .slice()
        .sort((a, b) => a.order - b.order);
    for (let i = 0; i < sortedPrompts.length; i++) {
        promptColorPairs.push([
            sortedPrompts[i].id,
            TailwindColors[i % TailwindColors.length],
        ]);
    }
    return promptColorPairs;
};

const buildTypeResults = (
    communityTypeQuestion: IQuestion,
    colorPairs: [PromptId, TailwindColorPair][],
    community: ListableCommunity,
): CommunityTypeResult[] => {
    const typeResults: CommunityTypeResult[] = [];
    if (!communityTypeQuestion.prompts || communityTypeQuestion.prompts.length === 0) {
        return typeResults;
    }
    for (const answer of community.answers) {
        if (answer.questionId !== communityTypeQuestion.id) {
            continue;
        }
        if (answer.selections && answer.selections.length > 0) {
            for (const selection of Array.from(new Set(answer.selections))) {
                const prompt = communityTypeQuestion.prompts.find(
                    p => p.id === selection,
                );
                if (prompt) {
                    const colorPair = colorPairs.find(p => p[0] === prompt.id);
                    if (colorPair) {
                        typeResults.push({
                            questionId: communityTypeQuestion.id,
                            promptId: prompt.id,
                            promptText: prompt.title,
                            generatedColorPair: colorPair[1],
                            order: prompt.order,
                        });
                    }
                }
            }
        }
    }
    return typeResults.sort((a, b) => a.order - b.order);
};

export const CommunityCardTypes: React.FC<CommunityCardTypesProps> = props => {
    const regionId = useRegionId();
    const regions = useRegions();
    const region = regions.find(r => r.id === regionId);
    const regionKey = region?.key;
    const communityTypeQuestion = useCommunityTypeQuestion(regionKey, regionId);
    if (!communityTypeQuestion) return null;
    const promptColorPairs = buildPromptColorPairs(communityTypeQuestion);
    const communityTypeResults = buildTypeResults(
        communityTypeQuestion,
        promptColorPairs,
        props.community,
    );
    return (
        <div className="flex items-center flex-wrap flex-1 mt-1 -mb-1.5 overflow-hidden">
            {communityTypeResults.map(r => (
                <span
                    key={r.promptId}
                    className={`mr-3 mb-2 inline-block flex-shrink-0 rounded-full ${r.generatedColorPair.join(
                        " ",
                    )} px-3 py-0.5 text-sm font-medium`}>
                    {r.promptText}
                </span>
            ))}
        </div>
    );
};
