import { SettingsCommunitySortType } from "@sp-crm/core";
import { ContentFull, ContentHeader, ContentSubheader } from "components/layout";
import { TemplateSelect } from "components/templates/template-select";
import { AutosavingInput } from "components/ui/autosaving-input";
import { Select } from "components/ui/select";
import React from "react";
import { useCurrentUser } from "store/selectors/hooks";
import { UserCheckboxPref } from "./account-settings-checkbox";
import { useCommitUserPreference } from "./account-settings-hooks";

type CommunitySortSelectInfo = {
    label: string;
};

type CommunitySortSelectInfos = {
    [communitySort in SettingsCommunitySortType]: CommunitySortSelectInfo;
};

const communitySortLabels: CommunitySortSelectInfos = {
    name: {
        label: "Name",
    },
    number: {
        label: "Number of placements",
    },
    recent: {
        label: "Most recent placement",
    },
    rating: {
        label: "Rating",
    },
};

export const AccountSettingsCommunity: React.FC<unknown> = () => {
    const user = useCurrentUser();
    const updateSortOrder = useCommitUserPreference("defaultCommunitySortOrder");
    const updateCompareTemplate = useCommitUserPreference(
        "defaultCommunityCompareTemplateId",
    );
    const updateLinkUrlText = useCommitUserPreference("liveLinkURLText");
    return (
        <div className="space-y-4 lg:space-y-8">
            <ContentFull>
                <ContentHeader>Communities</ContentHeader>
                <div className="space-y-4">
                    <div className="field__wrapper">
                        <Select
                            label="Default community sort"
                            value={user.preferences.defaultCommunitySortOrder}
                            onChange={e => updateSortOrder(e.target.value)}>
                            {Object.keys(communitySortLabels).map(untypedKey => {
                                const key = untypedKey as SettingsCommunitySortType;

                                return (
                                    <option key={key} value={key}>
                                        {communitySortLabels[key].label}
                                    </option>
                                );
                            })}
                        </Select>
                    </div>
                </div>
            </ContentFull>
            <ContentFull>
                <ContentHeader>Community comparison</ContentHeader>
                <ContentSubheader>Sharing community info with clients.</ContentSubheader>
                <div className="space-y-4">
                    <UserCheckboxPref
                        field="attachCommunityPDFByDefault"
                        label="Attach Community Comparison PDF by default"
                        description="When emailing Community Comparison to clients, attach the PDF by default."
                    />
                    <UserCheckboxPref
                        field="useLiveLinkByDefault"
                        label="Include Live Link by default"
                        description="When emailing Community Comparison to clients, insert the live link by default."
                    />
                    <div className="group">
                        <AutosavingInput
                            label="Text label for Live Link in emails"
                            initial={user.preferences.liveLinkURLText}
                            onCommit={updateLinkUrlText}
                        />
                        <p className="ml-6 mt-1 text-gray-500 text-sm group-hover:text-gray-900">
                            When emailing Community Comparison links to clients, this
                            label will show in the email instead of the raw URL.
                        </p>
                    </div>
                    <UserCheckboxPref
                        field="defaultShowCoverPage"
                        label="Show Cover Page on community comparison PDFs"
                        description="When downloading/emailing community comparison PDFs, include a cover page with my branding."
                    />
                    <div className="field__wrapper">
                        <TemplateSelect
                            label="Default cover page note (select from Email Templates)"
                            selectedTemplateId={
                                user.preferences.defaultCommunityCompareTemplateId
                            }
                            onChange={updateCompareTemplate}
                        />
                    </div>
                </div>
            </ContentFull>
        </div>
    );
};
