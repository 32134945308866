import { ContactId, IContact } from "@sp-crm/core";
import { Panel } from "components/ui/panel/panel";
import { PanelType } from "components/ui/panel/panel-type";
import { PrimaryButton } from "components/ui/primary-button";
import React, { FunctionComponent } from "react";
import Shared from "../../../shared";
import { ContactRenderInfo } from "../../../shared/input-contact-person";

type EditContactProps = {
    show: boolean;
    contact: IContact;
    onUpdateContact: (contactId: ContactId, contact: IContact) => void;
    onCloseEditPanel: () => void;
};

const EditCareProviderContact: FunctionComponent<EditContactProps> = ({
    show,
    contact,
    onUpdateContact,
    onCloseEditPanel,
}) => {
    if (!show) {
        return null;
    }

    const contactId = contact?.id;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    const saveContact = (field: string, val: any) => {
        const newContact = Object.assign({}, contact);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
        (newContact as any)[field] = val;
        onUpdateContact(contactId, newContact);
    };

    const contactRenderInfo: ContactRenderInfo = {
        contactGroupIcon: "54_physician",
        contactGroupHeading: "Care Providers (Physicians, Dentist, Pharmacy, etc.)",
        contactGroupHeadingShortName: "Care Providers",
        showAddressFields: true,
        showRelationshipField: true,
        showRoleField: true,
        specializedLayout: "Physician",
        hideContactNotes: false,
        allowReferenceLink: true,
        allowPreferredName: false,
    };

    return (
        <Panel
            isOpen={true}
            isLightDismiss={true}
            onDismiss={onCloseEditPanel}
            type={PanelType.extraLarge}
            className="app-component edit-contact-panel"
            headerText="Edit Contact Details">
            <div className="panel-body">
                <Shared.InputContactPerson
                    headerLabel={contact.name || "(No Name)"}
                    contact={contact}
                    onFieldChange={saveContact}
                    contactRenderInfo={contactRenderInfo}
                />
                <div className="save-cancel-buttons">
                    <PrimaryButton onClick={onCloseEditPanel}>Close</PrimaryButton>
                </div>
            </div>
        </Panel>
    );
};

export default EditCareProviderContact;
