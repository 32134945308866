import { getBounds, ILocation } from "@sp-crm/core";
import { MapMarkerId } from "components/community-search/community-map/types";
import maplibregl from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import * as React from "react";
import Map, { NavigationControl, ScaleControl } from "react-map-gl";
import { NextgenMapMarker } from "../community-search/community-map/nextgen-marker";
import { MarkerIconColor } from "./types";

interface NextgenStaticMapProps {
    entityId: MapMarkerId;
    location: ILocation | null;
    iconType: MarkerIconColor;
}

export const NextgenStaticMap: React.FC<NextgenStaticMapProps> = props => {
    const { location, entityId, iconType } = props;

    if (!location) {
        return <span />;
    }

    return (
        <Map
            mapLib={maplibregl}
            attributionControl={false}
            initialViewState={{
                bounds: getBounds([location]),
                fitBoundsOptions: { maxZoom: 13 },
            }}
            style={{ height: 200 }}
            mapStyle="https://api.maptiler.com/maps/8d80423a-4979-4e17-a487-ce521f75ff8e/style.json?key=Xs35dG5Gh6ueSw4mF06I">
            <NavigationControl position="top-left" />
            <NextgenMapMarker
                id={entityId}
                isHovered={false}
                iconType={iconType}
                location={location}
            />
            <ScaleControl />
        </Map>
    );
};
