import { Editor, PlacementEditor } from "components/editor/editor";
import { HtmlToken, Template } from "components/editor/template";
import { AdvancedSearchEntityType } from "generated/graphql";
import React, { MutableRefObject, useRef } from "react";

interface HtmlInputProps {
    label: string | React.ReactNode;
    onCommit: (newValue: string) => void;
    initialContent?: string;
    templates?: Template[];
    signatures?: Template[];
    placeholderTypes?: AdvancedSearchEntityType[];
    tokens?: HtmlToken[];
    tokenReplacements?: Record<string, string>;
    onTokenInserted?: (tokenValue: string) => boolean;
}

export const HtmlInput: React.FC<HtmlInputProps> = props => {
    const {
        initialContent,
        onCommit,
        templates,
        signatures,
        tokens,
        placeholderTypes,
        tokenReplacements,
        onTokenInserted,
    } = props;

    const editorRef = useRef() as MutableRefObject<PlacementEditor>;

    const handleBlur = React.useCallback(() => {
        if (editorRef.current) {
            onCommit(editorRef.current.getContent());
        }
    }, [onCommit]);

    return (
        <div>
            <div className="mb-1">{props.label}</div>
            <Editor
                ref={editorRef}
                initialContent={initialContent ?? ""}
                onBlur={handleBlur}
                placeholderTypes={placeholderTypes}
                templates={templates}
                signatures={signatures}
                tokens={tokens}
                tokenReplacements={tokenReplacements}
                onTokenInserted={onTokenInserted}
            />
        </div>
    );
};
