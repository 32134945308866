import { ContentFull, ContentHeader } from "components/layout";
import { Select } from "components/ui/select";
import React from "react";
import { useCurrentUser } from "store/selectors/hooks";
import { useCommitUserPreference } from "./account-settings-hooks";

export const AccountSettingsGeneral: React.FC<unknown> = () => {
    const user = useCurrentUser();

    const commitTimezonePref = useCommitUserPreference("timezone");

    const updateTimezone = React.useCallback(
        (e: React.ChangeEvent<HTMLSelectElement>) => {
            commitTimezonePref(e.target.value);
        },
        [commitTimezonePref],
    );

    return (
        <div className="space-y-4 lg:space-y-8">
            <ContentFull>
                <ContentHeader>General</ContentHeader>
                <div className="space-y-4 max-w-lg">
                    <Select
                        label="Time zone"
                        value={user.preferences.timezone || ""}
                        onChange={updateTimezone}>
                        <option value="">(Unspecified)</option>
                        <option value="America/Anchorage">Alaska</option>
                        <option value="America/Honolulu">Hawaii</option>
                        <option value="America/Los_Angeles">Pacific</option>
                        <option value="America/Denver">Mountain</option>
                        <option value="America/Phoenix">Mountain (no DST)</option>
                        <option value="America/Chicago">Central</option>
                        <option value="America/New_York">Eastern</option>
                        <option value="America/Vancouver">Pacific (Canada)</option>
                        <option value="America/Edmonton">Mountain (Canada)</option>
                        <option value="America/Halifax">Atlantic (Canada)</option>
                        <option value="America/Winnipeg">Central (Canada)</option>
                        <option value="America/Toronto">Eastern (Canada)</option>
                        <option value="America/St_Johns">Newfoundland (Canada)</option>
                    </Select>
                </div>
            </ContentFull>
        </div>
    );
};
