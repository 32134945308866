import { useGetTaskTypesQuery } from "generated/graphql";
import React from "react";
import { TaskType } from "store/slices/tasks-dashboard";

export const TaskTypeContext = React.createContext<TaskType[]>([]);

interface TaskTypeProviderProps {
    children: React.ReactNode;
}

export const TaskTypeProvider: React.FC<TaskTypeProviderProps> = props => {
    const query = useGetTaskTypesQuery();
    return (
        <TaskTypeContext.Provider value={query.data?.getTaskTypes ?? []}>
            {props.children}
        </TaskTypeContext.Provider>
    );
};
