import { ClientId, CommunityId, CommunityRelationshipType } from "@sp-crm/core";
import { Checkbox } from "components/ui/checkbox";
import React from "react";
import { ClientCommunitiesFragment } from "../../../../generated/graphql";

type Props = {
    clientId: ClientId;
    clientCommunity: ClientCommunitiesFragment["clientCommunities"][0];
    onClientCommunityChange: (
        clientId: ClientId,
        communityId: CommunityId,
        type: CommunityRelationshipType,
    ) => void;
};

const isHidden = (rel: CommunityRelationshipType): boolean =>
    rel === CommunityRelationshipType.matched;

export const ShowInFamilyViewCheckbox: React.FunctionComponent<Props> = (
    props: Props,
) => {
    const { clientId, clientCommunity, onClientCommunityChange } = props;

    return isHidden(clientCommunity.relationship) ? (
        <Checkbox
            checked={false}
            onChange={() => {
                onClientCommunityChange(
                    clientId,
                    clientCommunity.community.id,
                    CommunityRelationshipType.featured,
                );
            }}
            label="Show in family view"
        />
    ) : (
        <Checkbox
            checked={true}
            onChange={() => {
                onClientCommunityChange(
                    clientId,
                    clientCommunity.community.id,
                    CommunityRelationshipType.matched,
                );
            }}
            label="Show in family view"
        />
    );
};
