import { UserId } from "@sp-crm/core";
import { Radio, RadioOption } from "components/shared/radio";
import { SelectableUser, UserSelect } from "components/shared/user-select";
import { WorkflowActorMode } from "generated/graphql";
import { produce } from "immer";
import React from "react";
import { EditableWorkflow } from "./types";

interface EmailActionEditorProps {
    workflow: EditableWorkflow;
    onChange: (workflow: EditableWorkflow) => void;
}

const senderOptions: RadioOption[] = [
    {
        key: WorkflowActorMode.AssignedUser,
        text: "Client's assigned user",
        helpText: "Send the email using the client's assigned user",
    },
    {
        key: WorkflowActorMode.DefaultActor,
        text: "Specific user",
        helpText: "Send the email with a single user of your choice.",
    },
];

export const hasSingleEmailAction = (workflow: EditableWorkflow) => {
    return (
        workflow?.actions?.length === 1 &&
        workflow.actions[0].__typename === "WorkflowActionEmailEntity"
    );
};

export const EmailActionEditor: React.FC<EmailActionEditorProps> = props => {
    const { workflow, onChange } = props;

    const handleActorModeChange = (actorMode: WorkflowActorMode) => {
        const newWorkflow = produce(workflow, draft => {
            draft.actorMode = actorMode;
        });

        onChange(newWorkflow);
    };

    const handleActorIdChange = (actorId: SelectableUser) => {
        const newWorkflow = produce(workflow, draft => {
            draft.defaultActorId = actorId as UserId;
        });

        onChange(newWorkflow);
    };

    return (
        <div className="space-y-2">
            <Radio
                label="Email sender"
                options={senderOptions}
                value={workflow.actorMode}
                onChange={handleActorModeChange}
            />
            <div
                className={
                    workflow.actorMode === WorkflowActorMode.DefaultActor ? "pl-6" : ""
                }>
                <UserSelect
                    label={
                        workflow.actorMode === WorkflowActorMode.DefaultActor ? (
                            ""
                        ) : (
                            <div>
                                <p>Email sender fallback</p>
                                <p className="text-sm text-gray-500">{`This user will send the email if the client is unassigned`}</p>
                            </div>
                        )
                    }
                    value={workflow.defaultActorId}
                    onChange={handleActorIdChange}
                    includeEveryone={false}
                    includeUnassigned={false}
                />
            </div>
        </div>
    );
};
