import { QueryRenderer } from "components/clients/show-client/community-comparison/query-renderer";
import { fancyAlert } from "components/ui/fancy-alert";
import { InlineBanner } from "components/ui/inline-banner";
import { Input } from "components/ui/input";
import { PrimaryButton } from "components/ui/primary-button";
import { SecondaryButton } from "components/ui/secondary-button";
import { SelectPro } from "components/ui/select-pro";
import { Formik, FormikErrors, FormikHelpers } from "formik";
import {
    useUserManagementCreateUserMutation,
    useUserManagementGetRolesQuery,
} from "generated/graphql";
import React from "react";
import { useQueryClient } from "react-query";
import { useRegionId } from "store/selectors/hooks";
import { stableQueryOptions } from "util/requests";
import { CustomRolesBanner } from "./custom-roles-banner";
import { invalidateUserList } from "./helper";
import { ManageUsersPermissionsLevels } from "./manage-users-permissions";

interface FormValues {
    email: string;
    role: string;
}

const initialValues: FormValues = {
    email: "",
    role: "basic-user",
};

export const ManageUsersNewAccount: React.FC<unknown> = () => {
    const roles = useUserManagementGetRolesQuery({}, stableQueryOptions());
    const regionId = useRegionId();
    const mutation = useUserManagementCreateUserMutation();
    const [showSuccessNotification, setShowSuccessNotification] = React.useState(false);
    const validateFormInput = (values: FormValues): FormikErrors<FormValues> => {
        const errors: FormikErrors<FormValues> = {};
        if (values.email === undefined || values.email === null || values.email === "") {
            errors.email = "Email address cannot be blank";
        }
        return errors;
    };
    const queryClient = useQueryClient();
    const submitForm = React.useCallback(
        async (values: FormValues, helpers: FormikHelpers<FormValues>) => {
            helpers.setSubmitting(true);
            const payload = {
                userEmail: values.email,
                roleKey: values.role,
                regionId,
            };
            try {
                await mutation.mutateAsync(payload);
                helpers.setSubmitting(false);
                helpers.resetForm();
                invalidateUserList(queryClient);
                setShowSuccessNotification(true);
                setTimeout(() => setShowSuccessNotification(false), 5000);
            } catch (e) {
                helpers.setSubmitting(false);
                fancyAlert("Sorry, something went wrong", e.message, "warning");
            }
        },
        [regionId, mutation, queryClient],
    );

    return (
        <Formik
            validate={validateFormInput}
            onSubmit={submitForm}
            initialValues={initialValues}>
            {({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                touched,
                isSubmitting,
                isValid,
                dirty,
            }) => (
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <Input
                            type="email"
                            label="Email address"
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                        />
                        {touched.email && errors.email ? (
                            <div className="text-red-700 font-bold mt-1">
                                {errors.email}
                            </div>
                        ) : null}
                    </div>
                    <div>
                        <QueryRenderer name="New user roles" query={roles}>
                            {data => {
                                const roleOptions = [
                                    ...data.userManagementGetRoles.map(role => ({
                                        value: role.key,
                                        text: role.humanReadableName,
                                    })),
                                    {
                                        value: "custom",
                                        text: "Custom",
                                    },
                                ];

                                return (
                                    <SelectPro
                                        includePlaceholderOption={false}
                                        label="Permissions"
                                        name="role"
                                        onChange={handleChange}
                                        value={values.role}
                                        options={roleOptions}
                                    />
                                );
                            }}
                        </QueryRenderer>
                        {touched.role && errors.role ? (
                            <div className="text-red-700 font-bold mt-1">
                                {errors.role}
                            </div>
                        ) : null}
                        <ManageUsersPermissionsLevels />
                        {values.role === "custom" ? <CustomRolesBanner /> : null}
                    </div>
                    {dirty && isValid && !isSubmitting && values.role !== "custom" ? (
                        <PrimaryButton type="submit">Invite</PrimaryButton>
                    ) : (
                        <SecondaryButton disabled type="submit">
                            Invite
                        </SecondaryButton>
                    )}
                    <div className="text-sm italic">
                        Adding or removing a user may affect your next invoice.
                    </div>

                    {showSuccessNotification ? (
                        <InlineBanner type="success">Welcome email sent!</InlineBanner>
                    ) : null}
                </form>
            )}
        </Formik>
    );
};
