import { RegionId } from "@sp-crm/core";
import { Content, SectionHeader, Stage } from "components/layout";
import { fancyAlert } from "components/ui/fancy-alert";
import { PrimaryButton } from "components/ui/primary-button";
import { Select } from "components/ui/select";
import { useGetUserTenantRegionOptionsQuery } from "generated/graphql";
import * as React from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { changeRegion } from "store/actions/region";
import { switchUser } from "store/actions/session";
import { useRegionId } from "store/selectors/hooks";
import { stableQueryOptions } from "util/requests";

export const RegionToggle: React.FC<unknown> = props => {
    const query = useGetUserTenantRegionOptionsQuery({}, stableQueryOptions());
    const currentRegionId = useRegionId();
    const router = useHistory();
    const [regionId, setRegionId] = React.useState(currentRegionId);
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const submit = React.useCallback(
        async (e: React.FormEvent) => {
            e.preventDefault();
            if (regionId === currentRegionId) {
                router.push(`/`);
                return;
            }
            const currentRegion = query.data?.getUserTenantRegionOptions.options.find(
                o => o.regionId === currentRegionId,
            );
            const desiredRegion = query.data?.getUserTenantRegionOptions.options.find(
                o => o.regionId === regionId,
            );
            if (!currentRegion || !desiredRegion) {
                await fancyAlert(
                    "Sorry!",
                    "Sorry, could not switch region. Please try again.",
                    "warning",
                );
                return;
            }
            const isSameTenant = currentRegion.tenantId === desiredRegion.tenantId;
            if (isSameTenant) {
                changeRegion(desiredRegion.regionKey, dispatch);
            } else {
                switchUser(desiredRegion, queryClient, dispatch);
            }
        },
        [regionId, query.data, dispatch, router, currentRegionId, queryClient],
    );
    if (query.isLoading || !query.data) {
        return null;
    }
    const multipleTenantsAvailable =
        query.data.getUserTenantRegionOptions.options
            .map(o => o.tenantId)
            .reduce((acc, cur) => acc.add(cur), new Set()).size > 1;
    const options = query.data.getUserTenantRegionOptions.options.sort((a, b) =>
        (a.tenantName ?? "").localeCompare(b.tenantName ?? ""),
    );
    return (
        <Stage>
            <SectionHeader title="Select Region" />
            <Content>
                <form className="flex space-x-2" onSubmit={submit}>
                    <Select
                        value={regionId}
                        onChange={e => setRegionId(e.target.value as RegionId)}>
                        {options.map(o => (
                            <option key={o.regionId} value={o.regionId}>
                                {multipleTenantsAvailable
                                    ? `${o.tenantName}: ${o.regionName}`
                                    : o.regionName}
                            </option>
                        ))}
                    </Select>
                    <PrimaryButton>Go</PrimaryButton>
                </form>
            </Content>
        </Stage>
    );
};
