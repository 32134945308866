import { OffsetUnit, WorkflowId, getWorkflowOffset } from "@sp-crm/core";
import { Input } from "components/ui/input";
import { Select } from "components/ui/select";
import { Spinner } from "components/ui/spinner";
import { produce } from "immer";
import React from "react";
import { hasEmailOpenedTrigger, hasScheduleTrigger } from "./email-opened-trigger-editor";
import { EditableWorkflow } from "./types";

interface DelayRecipeEditorProps {
    workflow: EditableWorkflow;
    onCommit: (workflowId: WorkflowId, delayRecipe: string) => void;
}

interface State {
    status: "pristine" | "dirty" | "pending" | "ignore";
    workflowId: WorkflowId;
    upstreamDelay: number;
    upstreamUnits: OffsetUnit;
    currentDelay: number;
    currentUnit: OffsetUnit;
}

const computeInitialDelay = (seconds: number): [number, OffsetUnit] => {
    if (!seconds) {
        return [0, "m"];
    }

    if (seconds < 60) {
        return [0, "m"];
    }

    if (seconds < 60 * 60) {
        return [Math.floor(seconds / 60), "m"];
    }

    if (seconds < 60 * 60 * 24) {
        return [Math.floor(seconds / 60 / 60), "h"];
    }

    return [seconds / 60 / 60 / 24, "d"];
};

const computeWorkflowDelay = (workflow: EditableWorkflow): [number, OffsetUnit] => {
    if (workflow.delayRecipe?.includes("{{")) {
        return [0, "m"];
    }

    if (workflow.delayRecipe) {
        return getWorkflowOffset(workflow.delayRecipe);
    }

    return computeInitialDelay(workflow.delay);
};

const initialState = (workflow: EditableWorkflow): State => {
    if (workflow.delayRecipe?.includes("{{")) {
        return {
            workflowId: workflow.id,
            status: "ignore",
            upstreamDelay: workflow.delay,
            upstreamUnits: "d",
            currentDelay: workflow.delay,
            currentUnit: "d",
        };
    }

    const [delay, units] = computeWorkflowDelay(workflow);

    return {
        workflowId: workflow.id,
        status: "pristine",
        upstreamDelay: delay,
        upstreamUnits: units,
        currentDelay: delay,
        currentUnit: units,
    };
};

type Action =
    | { type: "changeDelay"; delay: number }
    | { type: "changeUnit"; unit: OffsetUnit }
    | { type: "upstreamChanged"; workflow: EditableWorkflow }
    | { type: "commit"; delay: number; unit: OffsetUnit };

const reducer = (state: State, action: Action): State => {
    return produce(state, draft => {
        if (action.type === "changeDelay") {
            draft.status = "dirty";
            draft.currentDelay = action.delay;
        }

        if (action.type === "changeUnit") {
            draft.status = "dirty";
            draft.currentUnit = action.unit;
        }

        if (action.type === "upstreamChanged") {
            if (action.workflow.id !== draft.workflowId) {
                Object.assign(draft, initialState(action.workflow));
            } else {
                const [delay, units] = computeWorkflowDelay(action.workflow);

                draft.upstreamDelay = delay;
                draft.upstreamUnits = units;

                if (
                    state.status === "pending" &&
                    state.currentDelay === delay &&
                    state.currentUnit === units
                ) {
                    draft.status = "pristine";
                }

                if (state.status === "pristine") {
                    draft.currentDelay = delay;
                    draft.currentUnit = units;
                }
            }
        }

        if (action.type === "commit") {
            draft.status =
                state.upstreamDelay === action.delay &&
                state.upstreamUnits === action.unit
                    ? "pristine"
                    : "pending";
            draft.currentDelay = action.delay;
            draft.currentUnit = action.unit;
        }
    });
};

const hasEditableDelay = (workflow: EditableWorkflow): boolean => {
    if (hasEmailOpenedTrigger(workflow) || hasScheduleTrigger(workflow)) {
        return false;
    }

    return true;
};

export const DelayRecipeEditor: React.FC<DelayRecipeEditorProps> = props => {
    const { workflow, onCommit } = props;
    const [state, dispatch] = React.useReducer(reducer, initialState(workflow));

    const handleDelayChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            dispatch({ type: "changeDelay", delay: e.target.valueAsNumber });
        },
        [],
    );

    const handleUnitsChange = React.useCallback(
        (e: React.ChangeEvent<HTMLSelectElement>) => {
            const newUnit = e.target.value as OffsetUnit;
            dispatch({ type: "changeUnit", unit: newUnit });
            dispatch({ type: "commit", delay: state.currentDelay, unit: newUnit });
            onCommit(workflow.id, `${state.currentDelay}${newUnit}`);
        },
        [workflow.id, state.currentDelay, onCommit],
    );

    const commitDelayChange = React.useCallback(() => {
        if (state.status !== "dirty") {
            return;
        }

        const delay = isNaN(state.currentDelay) ? 0 : Math.floor(state.currentDelay);
        const unit = state.currentUnit;

        dispatch({ type: "commit", delay, unit });
        onCommit(workflow.id, `${delay}${unit}`);
    }, [state.status, state.currentDelay, state.currentUnit, workflow.id, onCommit]);

    React.useEffect(() => {
        dispatch({ type: "upstreamChanged", workflow });
    }, [workflow.id, workflow.delay, workflow.delayRecipe]); // eslint-disable-line react-hooks/exhaustive-deps

    if (state.status === "ignore" || !hasEditableDelay(workflow)) {
        return null;
    }

    return (
        <div className="space-y-1">
            <p>Workflow delay</p>
            <div className="flex items-center space-x-2">
                <Input
                    textSize="sm"
                    className="flex-1"
                    value={state.currentDelay}
                    type="number"
                    onChange={handleDelayChange}
                    onBlur={commitDelayChange}
                />
                <Select
                    textSize="sm"
                    value={state.currentUnit}
                    onChange={handleUnitsChange}>
                    <option value="m">Minutes</option>
                    <option value="h">Hours</option>
                    <option value="d">Days</option>
                </Select>
                {state.status === "pending" ? <Spinner /> : <div className="w-4 h-4" />}
            </div>
            <p className="text-sm text-gray-500">
                Set the delay to run this workflow minutes, hours, or days after the
                criteria is met. We recommend setting this to at least 1 minute for all
                workflows.
            </p>
        </div>
    );
};
