import * as Common from "@sp-crm/core";
import { CalendarDate, ITaskBase, UserId } from "@sp-crm/core";
import * as React from "react";
import { TaskTypeContext } from "../task-type-provider";
import { TaskForm } from "./task-form";

interface EditTaskProps {
    onUpdateTask: (
        assignedUserId: UserId,
        text: string,
        notes: string,
        dueDate: CalendarDate | null,
        dueDateTime: Date | null,
        taskTypeId: Common.TaskTypeId | null,
    ) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    onToggleEdit: (event?: any) => void;
    task: ITaskBase;
    users: Common.User[];
}

export class EditTask extends React.Component<EditTaskProps> {
    constructor(props: EditTaskProps) {
        super(props);
    }

    save(
        assignedUserId: UserId,
        text: string,
        notes: string,
        dueDate: CalendarDate | null,
        dueDateTime: Date | null,
        taskTypeId: Common.TaskTypeId | null,
    ) {
        this.props.onUpdateTask(
            assignedUserId,
            text,
            notes,
            dueDate,
            dueDateTime,
            taskTypeId,
        );
        this.props.onToggleEdit();
    }

    render() {
        return (
            <TaskTypeContext.Consumer>
                {types => (
                    <TaskForm
                        onSaveTask={this.save.bind(this)}
                        onCancelTaskChanges={this.props.onToggleEdit}
                        task={this.props.task}
                        saveButtonLabel="Save"
                        users={this.props.users}
                        formHeader="Edit Task"
                        taskTypes={types}
                    />
                )}
            </TaskTypeContext.Consumer>
        );
    }
}
