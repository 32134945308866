import {
    ActionType,
    emailTokens,
    formatDateNoTime,
    HostedFormId,
    HostedFormTokenId,
    HostedFormTokenStatus,
    IClient,
} from "@sp-crm/core";
import { MandatoryEmailToken } from "components/messages/mandatory-token";
import { MessageComposeInline } from "components/messages/message-compose-internal";
import { PlacementMessageCompose } from "components/messages/types";
import { CrmTable } from "components/table/crm-table";
import { DeleteButton } from "components/ui/action-button";
import { fancyAlert } from "components/ui/fancy-alert";
import { InlineBanner } from "components/ui/inline-banner";
import { InternalLink } from "components/ui/internal-link";
import { defaultLinkStyle } from "components/ui/link";
import { Panel } from "components/ui/panel/panel";
import { PanelType } from "components/ui/panel/panel-type";
import { PrimaryButton } from "components/ui/primary-button";
import { SecondaryButton } from "components/ui/secondary-button";
import { Select } from "components/ui/select";
import { Spinner } from "components/ui/spinner";
import {
    AdvancedSearchEntityType,
    GetHostedFormsQuery,
    GetHostedFormTokensForEntityQuery,
    useDeleteHostedFormTokenMutation,
    useGetHostedFormsQuery,
    useGetHostedFormTokensForEntityQuery,
    useSendHostedFormWithTokenMutation,
} from "generated/graphql";
import React, { MutableRefObject, useCallback, useMemo } from "react";
import { useIsAllowed } from "store/selectors/hooks";

interface ShareInputFormLinkPanelProps {
    client: IClient;
    onDismiss: () => void;
}

const noop = () => 0;

export const ShareInputFormLinkPanel: React.FC<ShareInputFormLinkPanelProps> = props => {
    const { client, onDismiss } = props;

    const canCreateHostedForm = useIsAllowed(ActionType.CreateHostedForm);
    const [selectedFormId, setSelectedFormId] = React.useState<HostedFormId | null>(null);

    const getHostedForms = useGetHostedFormsQuery(
        {},
        {
            onSuccess: data => {
                if (!selectedFormId && data?.getHostedForms.length > 0) {
                    setSelectedFormId(data.getHostedForms[0].id);
                }
            },
            keepPreviousData: true,
        },
    );

    const getHostedFormTokens = useGetHostedFormTokensForEntityQuery(
        {
            entityId: client.id,
        },
        {
            keepPreviousData: true,
        },
    );

    const forms = useMemo(() => {
        if (!getHostedForms.data) {
            return null;
        }

        return getHostedForms.data.getHostedForms;
    }, [getHostedForms.data]);

    const tokens = useMemo(() => {
        if (!getHostedFormTokens.data) {
            return null;
        }

        return getHostedFormTokens.data.getHostedFormTokensForEntity;
    }, [getHostedFormTokens.data]);

    const inlineEditorRef =
        React.useRef<PlacementMessageCompose>() as MutableRefObject<PlacementMessageCompose>;

    const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

    const handleFormSelected = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedFormId(e.target.value as HostedFormId);
    }, []);

    const sendHostedForm = useSendHostedFormWithTokenMutation();
    const deleteHostedFormToken = useDeleteHostedFormTokenMutation();
    const containerRef = React.useRef<HTMLDivElement>(null);

    const handleSend = useCallback(async () => {
        const message = inlineEditorRef.current.getMessage();
        try {
            if (!(message.body || "").includes(emailTokens.intakeLiveLink)) {
                await fancyAlert(
                    "Link Placeholder Missing",
                    `To send an input form, the message body must contain ${emailTokens.intakeLiveLink}. To proceed, add ${emailTokens.intakeLiveLink} to the body and send again.`,
                );
                return;
            }
            setErrorMessage(null);
            await sendHostedForm.mutateAsync({
                params: {
                    message,
                    entityId: client.id,
                    hostedFormId: selectedFormId,
                },
            });
            getHostedFormTokens.refetch();
            containerRef.current?.scrollIntoView({ behavior: "smooth" });
        } catch (e) {
            setErrorMessage(e.message);
        }
    }, [selectedFormId, inlineEditorRef, sendHostedForm, client.id, getHostedFormTokens]);

    const handleTokenDeleted = useCallback(
        async (tokenId: HostedFormTokenId) => {
            await deleteHostedFormToken.mutateAsync({
                tokenId,
            });
            getHostedFormTokens.refetch();
        },
        [deleteHostedFormToken, getHostedFormTokens],
    );

    return (
        <Panel
            type={PanelType.extraLarge}
            onDismiss={onDismiss}
            isOpen={true}
            headerText="Share input form">
            <div className="space-y-4" ref={containerRef}>
                <p>
                    Share a form to allow someone else to fill in information for this
                    client.
                </p>
                {forms?.length === 0 ? (
                    <p>
                        No hosted intake forms are configured for your agency.{" "}
                        {canCreateHostedForm ? (
                            <>
                                Navigate to{" "}
                                <InternalLink to="/settings/agency/hosted-form">
                                    Hosted Intake Form settings
                                </InternalLink>{" "}
                                to configure a new form.
                            </>
                        ) : (
                            <>Contact your administrator to configure a hosted form</>
                        )}
                    </p>
                ) : null}
                {tokens?.length > 0 && forms?.length >= 0 ? (
                    <>
                        <p className="text-bold text-lg">Previously sent forms</p>
                        <HostedFormTokensTable
                            tokens={tokens}
                            forms={forms}
                            onDelete={handleTokenDeleted}
                        />
                    </>
                ) : null}
                {forms?.length > 0 && selectedFormId ? (
                    <>
                        {tokens?.length > 0 ? (
                            <p className="text-bold text-lg">Send new form</p>
                        ) : null}
                        {forms.length > 1 ? (
                            <>
                                <p>
                                    Pick the intake form you want to use, then send it to
                                    your desired recipients. Make sure to include{" "}
                                    {
                                        <pre className="inline">
                                            {emailTokens.intakeLiveLink}
                                        </pre>
                                    }{" "}
                                    in your email body.
                                </p>
                                <Select
                                    label="Choose form"
                                    value={selectedFormId}
                                    onChange={handleFormSelected}>
                                    {forms.map(form => (
                                        <option key={form.id} value={form.id}>
                                            {form.displayName}
                                        </option>
                                    ))}
                                </Select>
                            </>
                        ) : null}
                        <MessageComposeInline
                            entityId={client.id}
                            entityType={AdvancedSearchEntityType.Client}
                            onDismiss={noop}
                            ref={inlineEditorRef}
                            requestSpecialMessageExperience="hosted-form"
                        />
                        <div className="my-1">
                            <MandatoryEmailToken use="hosted-intake" />
                        </div>
                        <div className="flex items-center justify-end space-x-2">
                            {sendHostedForm.isLoading ? <Spinner /> : null}
                            <SecondaryButton type="button" onClick={onDismiss}>
                                Discard
                            </SecondaryButton>
                            <PrimaryButton type="button" onClick={handleSend}>
                                Send
                            </PrimaryButton>
                        </div>
                        {errorMessage ? (
                            <InlineBanner type="error">{errorMessage}</InlineBanner>
                        ) : null}
                    </>
                ) : null}
            </div>
        </Panel>
    );
};

interface HostedFormTokensTableProps {
    tokens: GetHostedFormTokensForEntityQuery["getHostedFormTokensForEntity"];
    forms: GetHostedFormsQuery["getHostedForms"];
    onDelete: (tokenId: HostedFormTokenId) => void;
}

const HostedFormTokensTable: React.FC<HostedFormTokensTableProps> = props => {
    const { tokens, forms, onDelete } = props;

    return (
        <CrmTable
            initialSort={[{ id: "expiresAt", desc: true }]}
            columns={[
                {
                    key: "hostedFormId",
                    header: "Form",
                    renderCell: x => {
                        const matchingForm = forms.find(
                            f => f.id === x.record.hostedFormId,
                        );

                        if (matchingForm) {
                            return x.record.status === HostedFormTokenStatus.Active ? (
                                <a
                                    className={defaultLinkStyle}
                                    href={`${window.location.origin}/hosted-form/${x.record.id}`}>
                                    {matchingForm.displayName}
                                </a>
                            ) : (
                                <>{matchingForm.displayName}</>
                            );
                        }

                        return <>Unknown</>;
                    },
                },
                {
                    key: "sentMessages",
                    header: "To",
                    renderCell: x => (
                        <>
                            {x.record.sentMessages.map((message, i) => (
                                <div key={i}>
                                    {message.to.map((to, j) => (
                                        <div key={j}>{to}</div>
                                    ))}
                                </div>
                            ))}
                        </>
                    ),
                },
                {
                    key: "expiresAt",
                    header: "Expires",
                    renderCell: x => <>{formatDateNoTime(x.record.expiresAt)}</>,
                },
                {
                    key: "status",
                    header: "Status",
                },
                {
                    key: "id",
                    header: "",
                    renderCell: x =>
                        x.record.completedAt ? null : (
                            <DeleteButton
                                backgroundColor="bg-white"
                                onClick={() => onDelete(x.record.id)}
                                confirm={{
                                    title: "Delete input form link",
                                    message:
                                        "Are you sure you want to revoke this input form link?",
                                }}
                            />
                        ),
                },
            ]}
            data={tokens}
        />
    );
};
