import { Icon } from "components/icon";
import React from "react";

interface CareProvidersHeaderProps {
    children?: React.ReactNode;
}

export const CareProvidersHeader: React.FC<CareProvidersHeaderProps> = props => {
    const { children } = props;
    return (
        <div className="flex items-center space-between">
            <div className="space-x-2 flex-1 flex items-center">
                <div className="w-7 mt-0.5">
                    <Icon name="54_physician" />
                </div>
                <div className="flex items-center space-x-2">
                    <p className="text-lg text-gray-700">Care Providers</p>
                    <p className="text-sm text-gray-500 pt-0.5">
                        Physicians, dentist, pharmacy, etc.
                    </p>
                </div>
            </div>
            <div>{children}</div>
        </div>
    );
};
