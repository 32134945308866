import { Schedule, defaultRunTime } from "@sp-crm/core";
import { Checkbox } from "components/ui/checkbox";
import { Input } from "components/ui/input";
import { Radio } from "components/ui/radio";
import React from "react";

interface ScheduleEditorProps {
    value: Schedule;
    onChange: (value: Schedule) => void;
}

export const ScheduleEditor: React.FC<ScheduleEditorProps> = props => {
    const { value, onChange } = props;

    const [mode, setMode] = React.useState<"weekly" | "monthly">(
        value.dayOfMonth === "*" ? "weekly" : "monthly",
    );
    const [dayOfMonth, setDayOfMonth] = React.useState<number>(
        value.dayOfMonth === "*"
            ? 1
            : Array.isArray(value.dayOfMonth)
            ? value.dayOfMonth[0]
            : value.dayOfMonth,
    );
    const [daysOfWeek, setDaysOfWeek] = React.useState<number[]>(
        value.dayOfWeek === "*"
            ? [1]
            : Array.isArray(value.dayOfWeek)
            ? value.dayOfWeek
            : [value.dayOfWeek],
    );

    return (
        <div className="space-y-1">
            <p>Set schedule</p>
            <div className="flex w-44">
                <Radio
                    checked={mode === "weekly"}
                    label="Weekly"
                    onChange={() => {
                        setMode("weekly");
                        onChange({
                            second: value.second,
                            minute: value.minute,
                            hour: value.hour,
                            dayOfMonth: "*",
                            dayOfWeek: daysOfWeek,
                            month: "*",
                        });
                    }}
                />
                <Radio
                    checked={mode === "monthly"}
                    label="Monthly"
                    onChange={() => {
                        setMode("monthly");
                        onChange({
                            second: value.second,
                            minute: value.minute,
                            hour: value.hour,
                            dayOfMonth,
                            dayOfWeek: "*",
                            month: "*",
                        });
                    }}
                />
            </div>
            <div className="ml-4">
                {mode === "monthly" ? (
                    <Input
                        label="Day of month"
                        type="number"
                        value={dayOfMonth || ""}
                        min={1}
                        max={31}
                        step={1}
                        onChange={e => {
                            const newDayOfMonth = parseInt(e.target.value, 10);
                            setDayOfMonth(newDayOfMonth);
                            onChange({
                                second: value.second,
                                minute: value.minute,
                                hour: value.hour,
                                dayOfMonth: newDayOfMonth,
                                dayOfWeek: value.dayOfWeek,
                                month: value.month,
                            });
                        }}
                    />
                ) : null}
                {mode === "weekly"
                    ? [
                          { value: 0, label: "Sunday" },
                          { value: 1, label: "Monday" },
                          { value: 2, label: "Tuesday" },
                          { value: 3, label: "Wednesday" },
                          { value: 4, label: "Thursday" },
                          { value: 5, label: "Friday" },
                          { value: 6, label: "Saturday" },
                      ].map(dayOfWeek => {
                          return (
                              <Checkbox
                                  key={dayOfWeek.value}
                                  checked={daysOfWeek.includes(dayOfWeek.value)}
                                  label={dayOfWeek.label}
                                  onChange={e => {
                                      let newDaysOfWeek = [...daysOfWeek];
                                      if (e.target.checked) {
                                          newDaysOfWeek.push(dayOfWeek.value);
                                      } else {
                                          newDaysOfWeek = newDaysOfWeek.filter(
                                              d => d !== dayOfWeek.value,
                                          );
                                      }

                                      newDaysOfWeek = newDaysOfWeek.sort();
                                      setDaysOfWeek(newDaysOfWeek);

                                      onChange({
                                          second: value.second,
                                          minute: value.minute,
                                          hour: value.hour,
                                          dayOfMonth: value.dayOfMonth,
                                          dayOfWeek: newDaysOfWeek,
                                          month: value.dayOfMonth,
                                      });
                                  }}
                              />
                          );
                      })
                    : null}
                <p className="text-xs italic mt-2">
                    This will run at {defaultRunTime().toLocaleTimeString()} on each
                    scheduled day
                </p>
            </div>
        </div>
    );
};
