import { CalendarDate, ClientId } from "@sp-crm/core";
import React from "react";
import { useGetClientQuery } from "../../generated/graphql";
import { InvoiceCreate } from "../invoices/invoice-create";
import { ClientInvoiceList } from "./invoice-list";

export interface InvoiceInput {
    communityName: string;
    moveDate?: CalendarDate;
    defaultDueDate?: CalendarDate;
    defaultNotes?: string;
}

interface Props {
    clientId: ClientId;
    canModifyInvoices: boolean;
    invoiceInput: InvoiceInput;
    updateClient: (clientId: ClientId) => void;
}

export const ClientInvoices: React.FC<Props> = props => {
    const { clientId, canModifyInvoices, invoiceInput, updateClient } = props;
    const queryKey = { id: clientId };
    const query = useGetClientQuery(queryKey);
    const refetch = React.useCallback(() => {
        query.refetch();
        updateClient(queryKey.id);
    }, [query, updateClient, queryKey.id]);
    return (
        <div>
            {canModifyInvoices ? (
                <InvoiceCreate
                    refetch={refetch}
                    clientId={clientId}
                    invoiceInput={invoiceInput}
                />
            ) : null}
            <div className={canModifyInvoices ? "mt-4" : ""}>
                <ClientInvoiceList
                    refetch={refetch}
                    query={query}
                    canModifyInvoices={canModifyInvoices}
                />
            </div>
        </div>
    );
};
