import { LinkIcon } from "@heroicons/react/24/outline";
import { ClientId, Contact, ContactId, formatPowerOfAttorney } from "@sp-crm/core";
import { QueryRenderer } from "components/clients/show-client/community-comparison/query-renderer";
import { ContactCard } from "components/contacts/card";
import { ContactEditor } from "components/contacts/contact-editor";
import { Icon } from "components/icon";
import {
    ReferenceContactPicker,
    SelectedReferenceContact,
} from "components/references/select-reference/reference-contact-picker";
import { DeleteButton, EditButton } from "components/ui/action-button";
import { Panel } from "components/ui/panel/panel";
import { PanelType } from "components/ui/panel/panel-type";
import { SecondaryButton } from "components/ui/secondary-button";
import {
    AdvancedSearchEntityType,
    ClientContactRelationship,
    ContactWithLinksFragment,
    GetClientContactsQuery,
    useCreateContactMutation,
    useDeleteClientContactMutation,
    useGetClientContactsQuery,
} from "generated/graphql";
import { produce } from "immer";
import React, { useCallback, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { ClientContactResult } from "types/contacts";
import { CareProvidersHeader } from "./care-providers/care-providers-header";

interface ClientContactsProps {
    clientId: ClientId;
    relationship: ClientContactRelationship;
    header?: React.ReactNode;
}

export const ClientContacts: React.FC<ClientContactsProps> = props => {
    const { clientId, relationship, header } = props;

    const [editingContactId, setEditingContactId] = useState<ContactId | null | "_new_">(
        null,
    );
    const [showReferralPicker, setShowReferralPicker] = useState(false);

    const queryParams = useMemo(() => {
        return {
            clientId,
            relationships: [relationship],
        };
    }, [relationship, clientId]);

    const clientContactsQuery = useGetClientContactsQuery({
        params: queryParams,
    });
    const queryClient = useQueryClient();
    const deleteMutation = useDeleteClientContactMutation();
    const createContactMutation = useCreateContactMutation();

    const handleAddContact = useCallback(
        async (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
            e.preventDefault();

            setEditingContactId("_new_");
        },
        [setEditingContactId],
    );

    const handleLinkContact = useCallback(
        async (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
            e.preventDefault();

            setShowReferralPicker(true);
        },
        [setShowReferralPicker],
    );

    const handleEdit = useCallback(
        (clientContact: ClientContactResult) => {
            setEditingContactId(clientContact.contactId);
        },
        [setEditingContactId],
    );

    const handleDelete = useCallback(
        async (clientContact: ClientContactResult) => {
            await deleteMutation.mutateAsync({
                clientId: clientContact.clientId,
                contactId: clientContact.contactId,
                relationship,
            });

            const data = queryClient.getQueryData<GetClientContactsQuery>([
                "getClientContacts",
                { params: queryParams },
            ]);

            const newData = produce(data, draft => {
                if (draft) {
                    draft.getClientContacts = draft.getClientContacts.filter(
                        cc =>
                            cc.clientId !== clientContact.clientId ||
                            cc.contactId !== clientContact.contactId,
                    );
                }
            });

            queryClient.setQueryData<GetClientContactsQuery>(
                ["getClientContacts", { params: queryParams }],
                newData,
            );
        },
        [deleteMutation, queryClient, queryParams, relationship],
    );

    const handleContactEditorDismiss = useCallback(() => {
        setEditingContactId(null);
    }, [setEditingContactId]);

    const handleReferralPickerDismiss = useCallback(() => {
        setShowReferralPicker(false);
    }, [setShowReferralPicker]);

    const handleSave = useCallback(
        (contact: ContactWithLinksFragment) => {
            const data = queryClient.getQueryData<GetClientContactsQuery>([
                "getClientContacts",
                { params: queryParams },
            ]);

            const newData = produce(data, draft => {
                if (draft) {
                    const matchingIndex = draft.getClientContacts.findIndex(
                        cc => cc.clientId === clientId && cc.contactId === contact.id,
                    );
                    const sourceClientContact = contact.clientContacts.find(
                        cc =>
                            cc.clientId === clientId &&
                            cc.relationship === relationship &&
                            cc.contactId === contact.id,
                    );

                    if (matchingIndex >= 0) {
                        draft.getClientContacts[matchingIndex].contact = contact;
                        draft.getClientContacts[matchingIndex].powerOfAttorney =
                            sourceClientContact?.powerOfAttorney || [];
                    } else {
                        draft.getClientContacts.push({
                            clientId,
                            contactId: contact.id,
                            relationship,
                            powerOfAttorney: sourceClientContact?.powerOfAttorney || [],
                            contact,
                        });
                    }
                }
            });

            queryClient.setQueryData<GetClientContactsQuery>(
                ["getClientContacts", { params: queryParams }],
                newData,
            );
        },
        [queryClient, queryParams, clientId, relationship],
    );

    const handleReferralContactSelected = useCallback(
        async (selected: SelectedReferenceContact) => {
            setShowReferralPicker(false);
            const result = await createContactMutation.mutateAsync({
                params: {
                    linkedEntityId: clientId,
                    linkedEntityType: AdvancedSearchEntityType.Client,
                    linkedContactId: selected.contactId,

                    clientContactParams: {
                        relationship,
                    },
                },
            });
            handleSave(result.createContact);
        },
        [
            createContactMutation,
            clientId,
            relationship,
            setShowReferralPicker,
            handleSave,
        ],
    );

    const allowLinkReferralContact =
        relationship === ClientContactRelationship.CareProvider;

    return (
        <div className="space-y-4">
            <div className="flex items-center justify-between">
                {header ? (
                    header
                ) : relationship === ClientContactRelationship.CareProvider ? (
                    <CareProvidersHeader />
                ) : (
                    <div className="flex items-center space-x-2">
                        <div className="w-7 mt-0.5">
                            <Icon name="46_MoreContacts" />
                        </div>
                        <div className="flex items-center space-x-2">
                            <p className="text-lg text-gray-700">Additional Contacts</p>
                        </div>
                    </div>
                )}
                <div className="flex items-center space-x-2">
                    <SecondaryButton onClick={handleAddContact}>
                        <span className="text-sm">Add contact</span>
                    </SecondaryButton>
                    {allowLinkReferralContact ? (
                        <SecondaryButton onClick={handleLinkContact}>
                            <span className="text-sm">Link referral contact</span>
                        </SecondaryButton>
                    ) : null}
                </div>
            </div>
            <QueryRenderer
                query={clientContactsQuery}
                name="ClientContacts.getClientContacts">
                {data => (
                    <>
                        <ul className="2xl:grid 2xl:grid-cols-2 2xl:gap-4 mt-4 space-y-4 2xl:space-y-0">
                            {data.getClientContacts.map(clientContact => (
                                <ClientContactItem
                                    key={clientContact.contactId}
                                    clientContact={clientContact}
                                    onEdit={handleEdit}
                                    onDelete={handleDelete}
                                />
                            ))}
                        </ul>
                    </>
                )}
            </QueryRenderer>
            <Panel
                isOpen={!!editingContactId}
                headerText={editingContactId === "_new_" ? "Add Contact" : "Edit Contact"}
                type={PanelType.large}
                hasCloseButton={false}>
                {editingContactId ? (
                    <ContactEditor
                        initialContactId={
                            editingContactId === "_new_" ? null : editingContactId
                        }
                        parentEntityType={AdvancedSearchEntityType.Client}
                        parentEntityId={clientId}
                        onSaved={handleSave}
                        onClose={handleContactEditorDismiss}
                        clientContactRelationship={relationship}
                    />
                ) : null}
            </Panel>
            <Panel
                isOpen={showReferralPicker}
                headerText="Choose referral contact"
                type={PanelType.extraLarge}
                onDismiss={handleReferralPickerDismiss}>
                <ReferenceContactPicker onSelect={handleReferralContactSelected} />
            </Panel>
        </div>
    );
};

interface ClientContactItemProps {
    clientContact: ClientContactResult;
    onEdit: (clientContact: ClientContactResult) => void;
    onDelete: (clientContact: ClientContactResult) => void;
}

const ClientContactItem: React.FC<ClientContactItemProps> = props => {
    const { clientContact, onEdit, onDelete } = props;

    const handleEdit = useCallback(() => {
        onEdit(clientContact);
    }, [clientContact, onEdit]);

    const handleDelete = useCallback(() => {
        onDelete(clientContact);
    }, [clientContact, onDelete]);

    return (
        <li
            key={clientContact.contactId}
            className="border border-gray-200 rounded-lg p-4">
            <div className="flex items-start">
                <div className="flex-1">
                    <ContactCard
                        parentEntityType={AdvancedSearchEntityType.Client}
                        parentId={clientContact.clientId}
                        contact={Contact.load(clientContact.contact)}>
                        <PowerOfAttorneyDisplay
                            powerOfAttorney={clientContact.powerOfAttorney}
                        />
                    </ContactCard>
                </div>
                <div className="flex items-center">
                    {clientContact.contact?.referenceContacts?.length > 0 ? (
                        <Link
                            className="block"
                            to={clientContact.contact.referenceContacts[0].appLink}>
                            <LinkIcon className="w-4 h-4" />
                        </Link>
                    ) : (
                        <EditButton backgroundColor="bg-white" onClick={handleEdit} />
                    )}
                    <DeleteButton
                        backgroundColor="bg-white"
                        onClick={handleDelete}
                        confirm={{
                            title: `Deleting ${clientContact.contact.name || "Contact"}`,
                            message: `Really delete the contact info for "${
                                clientContact.contact.name || "(No Name)"
                            }"?`,
                        }}
                    />
                </div>
            </div>
        </li>
    );
};

interface PowerOfAttorneyDisplayProps {
    powerOfAttorney: string[];
}

const PowerOfAttorneyDisplay: React.FC<PowerOfAttorneyDisplayProps> = props => {
    const { powerOfAttorney } = props;

    if (powerOfAttorney?.length > 0) {
        return (
            <div className="mt-2">
                <span>Power of Attorney: </span>
                <span className="font-medium">
                    {formatPowerOfAttorney(powerOfAttorney)}
                </span>
            </div>
        );
    }

    return null;
};
