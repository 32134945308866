import { fancyAlert } from "components/ui/fancy-alert";
import { fancyConfirm } from "components/ui/fancy-confirm";
import { PrimaryButton } from "components/ui/primary-button";
import { SecondaryButton } from "components/ui/secondary-button";
import { Spinner } from "components/ui/spinner";
import * as React from "react";
import { ClientIntakeEmailResponse } from "store/actions/email";
import { RequestDescription } from "types/request-description";
import { RequestState } from "../../../../constants/loading";

export const BottomPanel = (props: {
    requestDescription: RequestDescription;
    dismiss: () => void;
    sendMessage: () => void;
    newMessage: () => void;
    sendEnabled: boolean;
}): JSX.Element => {
    const { requestDescription } = props;

    React.useEffect(() => {
        (async () => {
            if (
                requestDescription.status === RequestState.DONE ||
                requestDescription.status === RequestState.ERROR
            ) {
                let accepted: string[] = [];
                let rejected: string[] = [];
                let authError = false;

                if (typeof requestDescription.data === "object") {
                    const response = requestDescription.data as ClientIntakeEmailResponse;

                    if (Array.isArray(response.deliveryResults)) {
                        response.deliveryResults.forEach(dr => {
                            if (Array.isArray(dr.accepted)) {
                                accepted = accepted.concat(dr.accepted);
                            }

                            if (Array.isArray(dr.rejected)) {
                                rejected = rejected.concat(dr.rejected);
                            }

                            if (dr.code === "EAUTH") {
                                authError = true;
                            }
                        });
                    }
                }

                if (authError) {
                    await fancyAlert(
                        "Update your email credentials",
                        "Sending this email failed because the configured username or password for your email provider needs to be updated. Navigate to your email settings page, reconnect your email provider, and try again.",
                    );
                } else if (rejected.length > 0) {
                    const sendAnother = await fancyConfirm(
                        "Some emails failed to send.",
                        `Mails to the recipients below failed to send, likely due to a formatting issue with the email address.\n\n${rejected.join(
                            "\n",
                        )}\n\nWould you like to go back to the email and try again?`,
                        "Yes",
                        "No",
                    );

                    if (sendAnother) {
                        props.newMessage();
                    } else {
                        props.dismiss();
                    }
                } else if (
                    accepted.length > 0 ||
                    requestDescription.status === RequestState.DONE
                ) {
                    const sendAnother = await fancyConfirm(
                        "Mail successfully sent.",
                        "Would you like to send another email?",
                        "Yes",
                        "No",
                        { confirmStyle: "question" },
                    );
                    if (sendAnother) {
                        props.newMessage();
                    } else {
                        props.dismiss();
                    }
                } else if (requestDescription.status === RequestState.ERROR) {
                    await fancyAlert(
                        "Sorry, something went wrong",
                        "Sorry, something went wrong! Please try reloading the page to fix the issue.",
                    );
                }
            }
        })();
    }, [requestDescription.status]); // eslint-disable-line react-hooks/exhaustive-deps
    if (requestDescription.status === RequestState.NOTSTARTED) {
        return (
            <div className="flex items-center justify-end space-x-3">
                <div>
                    <SecondaryButton onClick={props.dismiss}>Discard</SecondaryButton>
                </div>
                <div>
                    <PrimaryButton
                        onClick={props.sendMessage}
                        disabled={!props.sendEnabled}>
                        Send
                    </PrimaryButton>
                </div>
            </div>
        );
    }
    if (requestDescription.status === RequestState.REQUESTED) {
        return (
            <div className="flex items-center justify-center space-x-2">
                <div>
                    <Spinner />
                </div>
                <div className="font-bold">Sending...</div>
            </div>
        );
    }
    if (
        requestDescription.status === RequestState.DONE ||
        requestDescription.status === RequestState.ERROR
    ) {
        return null;
    }
    const exhaustiveCheck: never = requestDescription.status;
    throw new RangeError(`Expected ${exhaustiveCheck} to be matched, but wasn't.`);
};
