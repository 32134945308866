import { CalendarDate, DefaultReferences, Maybe } from "@sp-crm/core";
import { QueryRenderer } from "components/clients/show-client/community-comparison/query-renderer";
import { CalendarDateInput } from "components/ui/calendar-date";
import {
    ExpandDown,
    ExpandRight,
    GroupGrouped,
    GroupUngrouped,
    SortDown,
    SortUp,
} from "components/ui/icon";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Column, useExpanded, useGroupBy, useSortBy, useTable } from "react-table";
import { ApplicationState } from "store/state";
import { ReferralsQuery, useReferralsQuery } from "../../../generated/graphql";
import { Actions } from "../../../store/actions";
import {
    ContentContainer,
    ContentHeader,
    SectionHeader,
    Stage,
    Tile,
} from "../../layout";

declare module "react-table" {
    // take this file as-is, or comment out the sections that don't apply to your plugin configuration

    interface TableOptions<D extends object>
        extends UseExpandedOptions<D>,
            UseGroupByOptions<D>,
            UsePaginationOptions<D>,
            UseResizeColumnsOptions<D>,
            UseRowSelectOptions<D>,
            UseRowStateOptions<D>,
            UseSortByOptions<D> {}
    // eslint-disable-next-line @typescript-eslint/ban-types
    interface TableInstance<D extends object = {}>
        extends UseColumnOrderInstanceProps<D>,
            UseExpandedInstanceProps<D>,
            UseGroupByInstanceProps<D>,
            UsePaginationInstanceProps<D>,
            UseRowSelectInstanceProps<D>,
            UseRowStateInstanceProps<D>,
            UseSortByInstanceProps<D> {
        editable: boolean;
    }

    // eslint-disable-next-line @typescript-eslint/ban-types
    interface TableState<D extends object = {}>
        extends UseColumnOrderState<D>,
            UseExpandedState<D>,
            UseGroupByState<D>,
            UsePaginationState<D>,
            UseResizeColumnsState<D>,
            UseRowSelectState<D>,
            UseRowStateState<D>,
            UseSortByState<D> {}
    // eslint-disable-next-line @typescript-eslint/ban-types
    interface ColumnInterface<D extends object = {}>
        extends UseGroupByColumnOptions<D>,
            UseResizeColumnsColumnOptions<D>,
            UseSortByColumnOptions<D> {}
    // eslint-disable-next-line @typescript-eslint/ban-types
    interface ColumnInstance<D extends object = {}>
        extends UseGroupByColumnProps<D>,
            UseResizeColumnsColumnProps<D>,
            UseSortByColumnProps<D> {}
    // eslint-disable-next-line @typescript-eslint/ban-types
    interface Cell<D extends object = {}>
        extends UseGroupByCellProps<D>,
            UseRowStateCellProps<D> {}
    // eslint-disable-next-line @typescript-eslint/ban-types
    interface Row<D extends object = {}>
        extends UseExpandedRowProps<D>,
            UseGroupByRowProps<D>,
            UseRowSelectRowProps<D>,
            UseRowStateRowProps<D> {}
}

export const Referrals: React.FunctionComponent = () => {
    const region = useSelector((state: ApplicationState) => state.region.selectedRegion);
    const dispatch = useDispatch();
    const endDateOnChangeHandler = (newdate: CalendarDate) => {
        dispatch({
            type: Actions[Actions.REPORT_PARAMETER_INBOUND_REFERRAL_END_DATE_UPDATE],
            value: newdate,
        });
    };

    const startDateOnChangeHandler = (newdate: CalendarDate) => {
        dispatch({
            type: Actions[Actions.REPORT_PARAMETER_INBOUND_REFERRAL_START_DATE_UPDATE],
            value: newdate,
        });
    };

    const startDate = useSelector(
        (state: ApplicationState) => state.reportParameters.inboundReferral.startDate,
    );

    const endDate = useSelector(
        (state: ApplicationState) => state.reportParameters.inboundReferral.endDate,
    );

    const validQuery = region && region !== "" && startDate && endDate;

    return (
        <Stage>
            <SectionHeader title="Referrals">
                <div className="max-w-lg lg:max-w-none lg:flex justify-between lg:space-x-3 items-end">
                    <CalendarDateInput
                        label="Start"
                        value={startDate}
                        onChange={startDateOnChangeHandler}
                    />
                    <CalendarDateInput
                        label="End"
                        value={endDate}
                        onChange={endDateOnChangeHandler}
                    />
                </div>
            </SectionHeader>
            {validQuery ? (
                <Query
                    startDate={startDate.dayStart().toISOString()}
                    endDate={endDate.dayEnd().toISOString()}
                    region={region}
                />
            ) : (
                ""
            )}
        </Stage>
    );
};

const Query: React.FC<{
    startDate: string;
    endDate: string;
    region: string;
}> = props => {
    const queryResult = useReferralsQuery(props);

    return (
        <QueryRenderer name="Referrals.Query" query={queryResult}>
            {data => (
                <ContentContainer className="space-y-2">
                    <div className="grid grid-cols-1 w-full gap-0 lg:gap-2 lg:grid-cols-2">
                        <Tile>
                            <ContentHeader>Contacts</ContentHeader>
                            <ReferralsContactsPane data={data} />
                        </Tile>
                        <Tile>
                            <ContentHeader>Organizations</ContentHeader>
                            <ReferralsOrganizationPane data={data} />
                        </Tile>
                        <Tile>
                            <ContentHeader>General</ContentHeader>
                            <InboundReferralsGeneralPane data={data} />
                        </Tile>
                    </div>
                </ContentContainer>
            )}
        </QueryRenderer>
    );
};

interface GeneralReferral {
    referralToken: string;
    count: number;
}
const InboundReferralsGeneralPane: React.FC<{ data: ReferralsQuery }> = props => {
    const columns: Column<GeneralReferral>[] = useMemo(
        () => [
            {
                Header: "Source",
                accessor: "referralToken",
                Cell: cellRender =>
                    Maybe.fromValue(
                        DefaultReferences.find(r => r.key === cellRender.value),
                    )
                        .map(r => r.text)
                        .getOrElse("unknown"),
            },
            {
                Header: "Referrals",
                accessor: "count",
            },
        ],
        [],
    );
    const data: GeneralReferral[] = useMemo(
        () => props.data.reportReferrals.generic,
        [props.data],
    );
    const initialState = useMemo(() => {
        return { sortBy: [{ id: "count", desc: true }] };
    }, []);
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        useTable<GeneralReferral>({ columns, data, initialState }, useSortBy);
    return (
        <table className="w-full" {...getTableProps()}>
            <thead>
                {headerGroups.map(headerGroup => (
                    // eslint-disable-next-line react/jsx-key -- is defined in the interface
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            // eslint-disable-next-line react/jsx-key
                            <th
                                className="py-1 px-2 bg-gray-200 text-gray-600 uppercase text-sm leading-normal"
                                {...column.getHeaderProps(column.getSortByToggleProps())}>
                                <div className="flex items-center">
                                    <span>
                                        {column.isSorted ? (
                                            column.isSortedDesc ? (
                                                <SortDown className="mr-1" />
                                            ) : (
                                                <SortUp className="mr-1" />
                                            )
                                        ) : (
                                            ""
                                        )}
                                    </span>
                                    <span>{column.render("Header")}</span>
                                </div>
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>

            <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                        // eslint-disable-next-line react/jsx-key
                        <tr
                            className={`border-b border-gray-200 hover:bg-gray-100 ${
                                i % 2 === 1 ? "bg-gray-50" : "bg-white"
                            }`}
                            {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                return (
                                    // eslint-disable-next-line react/jsx-key
                                    <td className="py-1 px-2" {...cell.getCellProps()}>
                                        {cell.render("Cell")}
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

interface OrganizationReferral {
    inboundCount: number;
    outboundCount: number;
    difference: number;
    name: string;
    contactType: string;
    id: string;
}
const ReferralsOrganizationPane: React.FC<{
    data: ReferralsQuery;
}> = props => {
    const columns: Column<OrganizationReferral>[] = useMemo(
        (): Column<OrganizationReferral>[] => [
            {
                Header: "Type",
                accessor: "contactType",
            },
            {
                Header: "Source",
                disableGroupBy: true,
                accessor: "name",
                Cell: props =>
                    props?.row?.original?.id ? (
                        <Link
                            to={`/references/organizations/show/${props.row.original.id}`}>
                            {props.value}
                        </Link>
                    ) : (
                        ""
                    ),
            },
            {
                Header: "Inbound",
                accessor: "inboundCount",
                aggregate: "sum",
                disableGroupBy: true,
            },
            {
                Header: "Outbound",
                accessor: "outboundCount",
                aggregate: "sum",
                disableGroupBy: true,
            },
            {
                Header: "+/-",
                accessor: "difference",
                aggregate: "sum",
                disableGroupBy: true,
                sortType: "basic",
            },
        ],
        [],
    );
    const data: OrganizationReferral[] = useMemo(
        () =>
            props.data.reportReferrals.businesses.map(b => {
                return {
                    inboundCount: b.inboundCount,
                    outboundCount: b.outboundCount,
                    difference: b.inboundCount - b.outboundCount,
                    contactType:
                        b.business.businessContactEntity.contactType ?? "(unknown)",
                    name: b.business.businessContactEntity.name,
                    id: b.business.id,
                };
            }),
        [props.data],
    );
    const initialState = useMemo(() => {
        return { groupBy: ["contactType"], sortBy: [{ id: "inboundCount", desc: true }] };
    }, []);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        toggleAllRowsExpanded,
    } = useTable<OrganizationReferral>(
        { columns, data, initialState },
        useGroupBy,
        useSortBy,
        useExpanded,
    );

    React.useEffect(() => {
        toggleAllRowsExpanded(true); // NOTE expandAllSubComponents is a boolean I use to toggle open/closed
    }, [toggleAllRowsExpanded]);

    return (
        <table className="w-full" {...getTableProps()}>
            <thead>
                {headerGroups.map(headerGroup => (
                    // eslint-disable-next-line react/jsx-key
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        <th className="w-4 py-1 px-2 bg-gray-200 text-gray-600 uppercase text-sm leading-normal"></th>
                        {headerGroup.headers.map(column => (
                            // eslint-disable-next-line react/jsx-key
                            <th
                                className="py-1 px-2 bg-gray-200 text-gray-600 uppercase text-sm leading-normal"
                                {...column.getHeaderProps()}>
                                <div className="flex items-center">
                                    {column.canGroupBy ? (
                                        <span {...column.getGroupByToggleProps()}>
                                            {column.isGrouped ? (
                                                <GroupGrouped className="mr-1" />
                                            ) : (
                                                <GroupUngrouped className="mr-1" />
                                            )}
                                        </span>
                                    ) : null}
                                    <span {...column.getSortByToggleProps()}>
                                        {column.isSorted ? (
                                            column.isSortedDesc ? (
                                                <SortDown className="mr-1" />
                                            ) : (
                                                <SortUp className="mr-1" />
                                            )
                                        ) : (
                                            ""
                                        )}
                                    </span>
                                    <span {...column.getSortByToggleProps()}>
                                        {column.render("Header")}
                                    </span>
                                </div>
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>

            <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                        // eslint-disable-next-line react/jsx-key
                        <tr
                            className={`border-b border-gray-200 hover:bg-gray-100 ${
                                i % 2 === 1 ? "bg-gray-50" : "bg-white"
                            }`}
                            {...row.getRowProps()}>
                            <td className="w-4">
                                {row.canExpand ? (
                                    <span onClick={() => row.toggleRowExpanded()}>
                                        {row.isExpanded ? (
                                            <ExpandDown />
                                        ) : (
                                            <ExpandRight />
                                        )}
                                    </span>
                                ) : (
                                    ""
                                )}
                            </td>
                            {row.cells.map((cell, i) => {
                                return (
                                    // eslint-disable-next-line react/jsx-key
                                    <td
                                        className={`py-1 ${
                                            row.isGrouped ? "font-bold" : ""
                                        } ${
                                            i === 0 && !row.isGrouped
                                                ? "pl-4 pr-2"
                                                : "px-2"
                                        }`}
                                        {...cell.getCellProps()}>
                                        {cell.isAggregated
                                            ? cell.render("Aggregated")
                                            : cell.render("Cell")}
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

interface ContactReferral {
    inboundCount: number;
    outboundCount: number;
    difference: number;
    name: string;
    id: string;
}
const ReferralsContactsPane: React.FC<{
    data: ReferralsQuery;
}> = props => {
    const columns: Column<ContactReferral>[] = useMemo(
        () => [
            {
                Header: "Source",
                accessor: "name",
                Cell: props => (
                    <Link to={`/references/contacts/show/${props.row.original.id}`}>
                        {props.value}
                    </Link>
                ),
            },
            {
                Header: "Inbound",
                accessor: "inboundCount",
            },
            {
                Header: "Outbound",
                accessor: "outboundCount",
            },
            {
                Header: "+/-",
                accessor: "difference",
                sortType: "basic",
            },
        ],
        [],
    );
    const data: ContactReferral[] = useMemo(
        () =>
            props.data.reportReferrals.contacts.map(c => {
                return {
                    inboundCount: c.inboundCount,
                    outboundCount: c.outboundCount,
                    difference: c.inboundCount - c.outboundCount,
                    name: c.contact.contactEntity.name,
                    id: c.contact.id,
                };
            }),
        [props.data],
    );
    const initialState = useMemo(() => {
        return { sortBy: [{ id: "inboundCount", desc: true }] };
    }, []);
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        useTable<ContactReferral>({ columns, data, initialState }, useSortBy);

    return (
        <table className="w-full" {...getTableProps()}>
            <thead>
                {headerGroups.map(headerGroup => (
                    // eslint-disable-next-line react/jsx-key
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            // eslint-disable-next-line react/jsx-key
                            <th
                                className="py-1 px-2 bg-gray-200 text-gray-600 uppercase text-sm leading-normal"
                                {...column.getHeaderProps()}>
                                <div className="flex items-center">
                                    <span {...column.getSortByToggleProps()}>
                                        {column.isSorted ? (
                                            column.isSortedDesc ? (
                                                <SortDown className="mr-1" />
                                            ) : (
                                                <SortUp className="mr-1" />
                                            )
                                        ) : (
                                            ""
                                        )}
                                    </span>
                                    <span {...column.getSortByToggleProps()}>
                                        {column.render("Header")}
                                    </span>
                                </div>
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                        // eslint-disable-next-line react/jsx-key
                        <tr
                            className={`border-b border-gray-200 hover:bg-gray-100 ${
                                i % 2 === 1 ? "bg-gray-50" : "bg-white"
                            }`}
                            {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                return (
                                    // eslint-disable-next-line react/jsx-key
                                    <td className="py-1 px-2" {...cell.getCellProps()}>
                                        {cell.render("Cell")}
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};
