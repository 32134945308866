import { InvoiceStatusLabels, InvoiceStatuses } from "@sp-crm/core";
import { Input } from "components/ui/input";
import React from "react";
import { ClientInvoice, CommunityInvoice } from "./invoice-helpers";
import { InvoiceLineItemsEditor } from "./invoice-line-items-editor";
import { InvoicePaymentScheduleEditor } from "./invoice-payment-schedule-editor";

interface InvoiceViewProps {
    invoice: ClientInvoice | CommunityInvoice;
}

export const InvoiceView: React.FC<InvoiceViewProps> = props => {
    const { invoice } = props;
    return (
        <div>
            <div className="text-xl font-semibold">Invoice Details</div>
            <div className="space-y-4">
                <div className="grid grid-cols-2 gap-x-8 gap-y-4">
                    <div>
                        <Input
                            label="Invoice number"
                            placeholder="Leave blank to generate"
                            type="input"
                            name="serial"
                            disabled={true}
                            value={invoice.serial}
                        />
                    </div>
                    <div>
                        <Input
                            type="date"
                            label="Invoice sent date"
                            name="sentDate"
                            disabled={true}
                            value={invoice.sentDate}
                        />
                    </div>
                    <div className="">
                        <Input
                            label="Status"
                            type="input"
                            name="status"
                            disabled={true}
                            value={
                                InvoiceStatusLabels[
                                    invoice.status as typeof InvoiceStatuses[number]
                                ] || invoice.status
                            }
                        />
                    </div>
                    <div>
                        <Input
                            type="date"
                            label="Due date *"
                            name="dueDate"
                            value={invoice.dueDate}
                            disabled={true}
                        />
                    </div>
                    <div>
                        <Input
                            label="Paid date"
                            name="paidDate"
                            type="date"
                            value={invoice.paidDate}
                            disabled={true}
                        />
                    </div>
                </div>
                {invoice.lineItems ? (
                    <InvoiceLineItemsEditor
                        lineItems={invoice.lineItems}
                        paymentSchedule={invoice.paymentSchedule}
                        paidDate={invoice.paidDate}
                    />
                ) : null}
                {invoice.paymentSchedule ? (
                    <>
                        <p className="text-large font-semibold">Payment Schedule</p>
                        <InvoicePaymentScheduleEditor
                            paymentSchedule={invoice.paymentSchedule}
                            dueDate={invoice.dueDate}
                        />
                    </>
                ) : null}
                <div className="w-full">
                    <div className="mb-1">
                        <label htmlFor="invoice-notes-field">Notes (optional)</label>
                    </div>
                    <textarea
                        className="form-input rounded w-full disabled:bg-gray-100 border-gray-400 hover:border-brand-600 active:border-brand-400 focus:ring-2 focus:ring-brand-300"
                        id="invoice-notes-field"
                        name="notes"
                        disabled={true}
                        rows={3}
                        value={invoice.notes}
                    />
                </div>
            </div>
        </div>
    );
};
