import { Action, Dispatch } from "redux";
import { Actions, navigate } from "./index";

export type ChangeRegionAction = {
    selectedRegion: string;
} & Action;

export function createChangeRegionAction(selectedRegionKey: string): ChangeRegionAction {
    return {
        type: Actions[Actions.SELECT_REGION],
        selectedRegion: selectedRegionKey,
    };
}

export const changeRegion = (
    selectedRegionKey: string,
    dispatch: Dispatch<ChangeRegionAction>,
) => {
    dispatch(createChangeRegionAction(selectedRegionKey));
    navigate("/");
};
