import { AccessControlId, ActionType, UserGroupId, genEntityId } from "@sp-crm/core";
import { MultiGroupSelect } from "components/ui/multi-group-select";
import { Radio } from "components/ui/radio";
import React, { useMemo, useState } from "react";
import { useIsAllowed } from "store/selectors/hooks";

interface IAccessControl {
    id: AccessControlId;
    groupId: UserGroupId;
}

interface AccessControlEditorProps {
    label: string;
    accessControls: IAccessControl[];
    onChange: (accessControls: IAccessControl[]) => void;
}

export const AccessControlEditor: React.FC<AccessControlEditorProps> = props => {
    const { label, accessControls, onChange } = props;

    const originalAccessControls = useMemo(() => {
        return props.accessControls;
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const [mode, setMode] = useState<"everyone" | "specific">(
        accessControls.length === 0 ? "everyone" : "specific",
    );
    const [specificGroupIds, setSpecificGroupIds] = useState<UserGroupId[]>(
        accessControls.map(ac => ac.groupId),
    );

    const getAccessControlsFromGroupIds = (groupIds: UserGroupId[]) => {
        return groupIds.map(groupId => ({
            id:
                originalAccessControls.find(ac => ac.groupId === groupId)?.id ??
                genEntityId<AccessControlId>(),
            groupId,
        }));
    };

    const canModifyAcls = useIsAllowed(ActionType.ModifyAcls);

    if (!canModifyAcls) {
        return null;
    }

    return (
        <div>
            <p>{label}</p>
            <Radio
                checked={mode === "everyone"}
                onChange={() => {
                    setMode("everyone");
                    onChange([]);
                }}
                label="Everyone"
            />
            <Radio
                checked={mode === "specific"}
                onChange={() => {
                    setMode("specific");
                    onChange(getAccessControlsFromGroupIds(specificGroupIds));
                }}
                label="Specific Groups"
            />
            {mode === "specific" ? (
                <div className="space-y-1">
                    <p className="text-sm text-gray-600">
                        Each chosen user group will be allowed to see this record
                    </p>
                    <p className="text-sm text-gray-600">
                        {`For example, to limit this record's visibilty only to
                        Administrators, choose the "Administrators" group. To limit it to
                        both Administrators and Power Users, choose both groups.`}
                    </p>
                    <MultiGroupSelect
                        value={specificGroupIds}
                        onChange={groupIds => {
                            setSpecificGroupIds(groupIds);
                            onChange(getAccessControlsFromGroupIds(groupIds));
                        }}
                    />
                </div>
            ) : null}
        </div>
    );
};
