import { User, UserPreferenceFields } from "@sp-crm/core";
import React from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { updateUser } from "store/actions";
import { useCurrentUser } from "store/selectors/hooks";

export const useCommitUserPreference = (field: UserPreferenceFields) => {
    const user = useCurrentUser();
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const callback = React.useCallback(
        async (value: unknown) => {
            const reloadedUser = User.load(user);
            reloadedUser.preferences = {
                ...reloadedUser.preferences,
                [`${field}`]: value,
            };
            await updateUser(reloadedUser, dispatch);
            queryClient.invalidateQueries(["composeMessage"]);
        },
        [dispatch, user, field, queryClient],
    );
    return callback;
};
