import { IClientIntakeDetailInfo } from "@sp-crm/core";
import { AutosavingInput } from "components/ui/autosaving-input";
import { Checkbox } from "components/ui/checkbox";

import React, { FunctionComponent } from "react";

type DetailsProps = {
    client: IClientIntakeDetailInfo;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    onFieldChange: (fieldName: string, newValue: any) => void;
};

const Details: FunctionComponent<DetailsProps> = ({ client, onFieldChange }) => {
    return (
        <div className="space-y-2 md:space-y-3">
            <AutosavingInput
                label="Health Insurance Company"
                initial={client.insuranceInfo.healthInsuranceCompany}
                onCommit={newVal =>
                    onFieldChange("insuranceInfo.healthInsuranceCompany", newVal)
                }
            />
            <div className="flex space-x-2 items-begin">
                <div className="flex-1">
                    <AutosavingInput
                        label="Phone"
                        initial={client.insuranceInfo.phone}
                        onCommit={newVal => onFieldChange("insuranceInfo.phone", newVal)}
                    />
                </div>
                <div className="flex-1">
                    <AutosavingInput
                        label="Policy Number"
                        initial={client.insuranceInfo.policyNumber}
                        onCommit={newVal =>
                            onFieldChange("insuranceInfo.policyNumber", newVal)
                        }
                    />
                    <Checkbox
                        onChange={newVal =>
                            onFieldChange(
                                "insuranceInfo.preAuthRequired",
                                newVal.target.checked,
                            )
                        }
                        checked={client.insuranceInfo.preAuthRequired}
                        label="Pre-Authorization Required"
                    />
                </div>
            </div>
            <div />
            <AutosavingInput
                label="Additional Insurance Coverage"
                initial={client.insuranceInfo.additionalInsuranceCoverage}
                onCommit={newVal =>
                    onFieldChange("insuranceInfo.additionalInsuranceCoverage", newVal)
                }
            />
            <div className="flex space-x-2 items-begin">
                <div className="flex-1">
                    <AutosavingInput
                        label="Phone"
                        initial={client.insuranceInfo.additionalPhone}
                        onCommit={newVal =>
                            onFieldChange("insuranceInfo.additionalPhone", newVal)
                        }
                    />
                </div>
                <div className="flex-1">
                    <AutosavingInput
                        label="Policy Number"
                        initial={client.insuranceInfo.additionalPolicyNumber}
                        onCommit={newVal =>
                            onFieldChange("insuranceInfo.additionalPolicyNumber", newVal)
                        }
                    />
                </div>
            </div>
            <AutosavingInput
                label="Insurance Notes"
                multiLine
                initial={client.insuranceInfo.insuranceNotes}
                onCommit={newVal => onFieldChange("insuranceInfo.insuranceNotes", newVal)}
            />
        </div>
    );
};

export default Details;
