import { ClientId, IContact } from "@sp-crm/core";
import { RecipientsField } from "components/messages/recipients-field";
import { InlineBanner } from "components/ui/inline-banner";
import { useComposeMessageQuery } from "generated/graphql";
import React, { useCallback, useEffect, useMemo } from "react";

interface InvoiceRecipientsFieldProps {
    clientId: ClientId;
    initial?: string[];
    onChange: (recipients: string[]) => void;
}

export const InvoiceRecipientsField: React.FC<InvoiceRecipientsFieldProps> = props => {
    const { clientId, initial, onChange } = props;

    const recipientsQuery = useComposeMessageQuery(
        {
            entityId: clientId,
            specialMessageExperience: "invoice",
        },
        {
            enabled: !initial,
            onSuccess: data => {
                if (data?.composeMessage?.recipients) {
                    onChange(data.composeMessage.recipients);
                }
            },
        },
    );

    const initialRecipients = useMemo(() => {
        if (initial) {
            return initial;
        }

        return recipientsQuery.data?.composeMessage?.recipients;
    }, [initial, recipientsQuery.data?.composeMessage?.recipients]);

    if (!initialRecipients) {
        return null;
    }

    return (
        <InvoiceRecipientsFieldInner
            initialRecipients={initialRecipients}
            onChange={onChange}
        />
    );
};

interface InvoiceRecipientsFieldInnerProps {
    initialRecipients: string[];
    onChange: (recipients: string[]) => void;
}

const InvoiceRecipientsFieldInner: React.FC<InvoiceRecipientsFieldInnerProps> = props => {
    const { initialRecipients, onChange } = props;

    const initialRecipientContacts: IContact[] = React.useMemo(
        () => initialRecipients.map(r => ({ name: r, email1: r } as IContact)),
        [initialRecipients],
    );

    const [selectedRecipients, setSelectedRecipients] = React.useState<IContact[]>(
        initialRecipientContacts,
    );

    useEffect(() => {
        onChange(selectedRecipients.map(r => r.email1).filter(x => !!x));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleChange = useCallback(
        (recipients: IContact[]) => {
            setSelectedRecipients(recipients);
            onChange(recipients.map(r => r.email1).filter(x => !!x));
        },
        [onChange],
    );

    const recipientStringLength = useMemo(() => {
        return selectedRecipients
            .filter(x => !!x.email1)
            .map(r => r.email1)
            .join(",").length;
    }, [selectedRecipients]);

    return (
        <div>
            <RecipientsField
                label="Customer email"
                recipients={selectedRecipients}
                onChange={handleChange}
                suggestions={initialRecipientContacts}
            />
            {recipientStringLength > 100 ? (
                <InlineBanner type="warning">
                    QuickBooks invoices only allow 100 characters for email addresses.
                    Reduce the number of emails on this invoice before saving.
                </InlineBanner>
            ) : null}
        </div>
    );
};
