import { CustomListKey, IClient, ITask } from "@sp-crm/core";
import { Alert } from "components/ui/dialog/alert";
import { PrimaryButton } from "components/ui/primary-button";
import { SecondaryButton } from "components/ui/secondary-button";
import { Location } from "history";
import React, { useCallback, useEffect } from "react";
import { useHistory } from "react-router";
import { useCustomList } from "store/selectors/bridge";
import { useTenantSettings } from "store/selectors/hooks";

interface NoActiveTasksPromptProps {
    client: IClient;
    tasks: ITask[];
    tasksLoaded: boolean;
}

export const NoActiveTasksPrompt: React.FC<NoActiveTasksPromptProps> = props => {
    const { tasks, tasksLoaded, client } = props;

    const history = useHistory();
    const [showAlert, setShowAlert] = React.useState(false);
    const settings = useTenantSettings();
    const { itemHasCategory } = useCustomList(CustomListKey.ClientStatus);

    const handleDismiss = useCallback(() => {
        setShowAlert(false);
    }, [setShowAlert]);

    const shouldShow = useCallback(
        (targetLocation: Location | null) => {
            if (!tasksLoaded) {
                return false;
            }

            if (!settings.alertClientsWithNoTasks) {
                return false;
            }

            if (itemHasCategory(client.statusListItemId, "Closed") || client.archived) {
                return false;
            }

            if (targetLocation?.pathname?.includes(`/clients/show/${client.id}`)) {
                return false;
            }

            if (tasks.every(task => !task.active())) {
                return true;
            }

            return false;
        },
        [
            tasks,
            tasksLoaded,
            client.id,
            client.statusListItemId,
            itemHasCategory,
            settings.alertClientsWithNoTasks,
            client.archived,
        ],
    );

    useEffect(() => {
        const unblock = history.block((targetLocation: Location) => {
            if (shouldShow(targetLocation)) {
                setShowAlert(true);
                return false;
            }

            return undefined;
        });

        return () => {
            unblock();
        };
    }, [shouldShow]); // eslint-disable-line react-hooks/exhaustive-deps

    return showAlert ? (
        <Alert
            title="No active tasks"
            onDismiss={handleDismiss}
            buttons={({ close }) => {
                return (
                    <div className="flex items-center space-x-2">
                        <PrimaryButton
                            onClick={e => {
                                e.preventDefault();
                                history.push(`/clients/show/${client.id}/tasks`);
                                close();
                            }}>
                            Go to tasks
                        </PrimaryButton>
                        <SecondaryButton
                            onClick={e => {
                                e.preventDefault();
                                close();
                            }}>
                            Close
                        </SecondaryButton>
                    </div>
                );
            }}>
            <p>
                This client has no active tasks. Make sure to add a task or mark this
                client as closed.
            </p>
        </Alert>
    ) : (
        <></>
    );
};
