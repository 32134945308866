import { DocumentPlusIcon } from "@heroicons/react/24/outline";
import React from "react";
import { EntityActivitySummary } from "./details/entity-activity-summary";
import { ActivityEntityType, EntityActivityEntryProps } from "./types";

interface EntityActivityEntityCreateLabelProps {
    entityType: ActivityEntityType;
}

const EntityActivityEntityCreateLabel: React.FC<
    EntityActivityEntityCreateLabelProps
> = props => {
    const { entityType } = props;

    if (entityType === "Client") {
        return <>created a client.</>;
    }
    if (entityType === "Community") {
        return <>created a community.</>;
    }
    if (entityType === "ReferenceContact") {
        return <>created a contact.</>;
    }
    if (entityType === "ReferenceBusiness") {
        return <>created an organization.</>;
    }

    const exhaustiveCheck: never = entityType;
    throw new Error(`Unhandled entity type: ${exhaustiveCheck}`);
};

interface EntityActivityEntityCreateProps extends EntityActivityEntryProps {
    entityType: ActivityEntityType;
}

export const EntityActivityEntityCreate: React.FC<
    EntityActivityEntityCreateProps
> = props => {
    const { entityType } = props;

    return (
        <div className="relative flex items-start space-x-3">
            <div className="relative px-1">
                <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white">
                    <DocumentPlusIcon
                        className="h-5 w-5 text-gray-500"
                        aria-hidden="true"
                    />
                </div>
            </div>
            <div className="min-w-0 flex-1 group pt-1">
                <EntityActivitySummary {...props}>
                    <EntityActivityEntityCreateLabel entityType={entityType} />
                </EntityActivitySummary>
            </div>
        </div>
    );
};
