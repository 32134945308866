import { Bars4Icon, PlusIcon } from "@heroicons/react/20/solid";
import { TrashIcon } from "@heroicons/react/24/outline";
import {
    BridgeFieldFormatter,
    BridgeNaryValue,
    CalendarDate,
    CurrencyInCents,
    CustomListCategoryId,
    CustomListItemId,
    IBridgeSelect,
    IQuestion,
    PromptId,
    Question,
    QuestionType,
    TenantRegion,
    UserId,
    currencyInCentsToString,
    getEntityField,
    stringToCurrencyInCents,
} from "@sp-crm/core";
import {
    findPreferredFilterField,
    getInitialOperatorAndValue,
} from "components/reports/show/custom-reports/custom-reports-helpers";
import { CustomListCategorySelect } from "components/shared/custom-list-category-select";
import { CustomListSelect } from "components/shared/custom-list-select";
import { UserSelect } from "components/shared/user-select";
import { AbsoluteOrRelativeDateInput } from "components/ui/absolute-or-relative-date-input";
import { CalendarDateNoYearInput } from "components/ui/calendar-date-no-year";
import { Input } from "components/ui/input";
import { defaultLinkStyle } from "components/ui/link";
import { Select } from "components/ui/select";
import {
    AdvancedSearchConditionNodeType,
    AdvancedSearchRelativeDateInterval,
    AdvancedSearchRequestCondition,
    BridgeEntityResult,
    BridgeRelationKind,
    FieldConditionOperator,
    GetCustomListsQuery,
} from "generated/graphql";
import { produce } from "immer";
import React, { useCallback, useMemo } from "react";
import { useCurrentUserId, useRegions, useVisibleUsers } from "store/selectors/hooks";
import { ConditionEditorMode } from "./advanced-search-utility";
import { EntityFieldPicker } from "./entity-field-picker";
import { EntityConditionFieldPicker } from "./types";

interface ConditionEditorProps {
    condition: AdvancedSearchRequestCondition;
    onChange: (condition: AdvancedSearchRequestCondition) => void;
    depth: number;
    relativeDepth: number;
    entityMetadata: BridgeEntityResult;
    entities: BridgeEntityResult[];
    customLists: GetCustomListsQuery["getCustomLists"];
    addGroupLabel?: string;
    addConditionLabel?: string;
    mode: "advanced" | "simple";
}

const getInitialRelationOperatorValueAndChildren = (
    entities: BridgeEntityResult[],
    entityMetadata: BridgeEntityResult,
    relationName: string,
    regions: TenantRegion[],
    currentUserId: UserId,
    customLists: GetCustomListsQuery["getCustomLists"],
): Partial<AdvancedSearchRequestCondition> => {
    const relation = entityMetadata.relations.find(r => r.name === relationName);

    if (!relation) {
        throw new Error(
            `Could not find relation ${relationName} on entity ${entityMetadata.name}`,
        );
    }

    switch (relation.kind) {
        case BridgeRelationKind.OneToMany: {
            return {
                operator: FieldConditionOperator.CountGreaterThan,
                numberValue: 0,
                children: [
                    {
                        nodeType: AdvancedSearchConditionNodeType.And,
                        children: [],
                    },
                ],
            };
        }
        case BridgeRelationKind.OneToOne: {
            const relatedEntity = entities.find(e => e.name === relation.otherEntityName);
            const initialField = findPreferredFilterField(relatedEntity);

            return {
                operator: FieldConditionOperator.IsSet,
                children: [
                    {
                        nodeType: AdvancedSearchConditionNodeType.And,
                        children: relatedEntity
                            ? [
                                  {
                                      nodeType: AdvancedSearchConditionNodeType.Or,
                                      children: [
                                          {
                                              nodeType:
                                                  AdvancedSearchConditionNodeType.FieldCondition,
                                              fieldName: initialField.name,
                                              ...getInitialOperatorAndValue(
                                                  initialField.questionType,
                                                  regions,
                                                  currentUserId,
                                                  customLists,
                                                  initialField,
                                              ),
                                          },
                                      ],
                                  },
                              ]
                            : [],
                    },
                ],
            };
        }
        case BridgeRelationKind.ManyToOne: {
            const relatedEntity = entities.find(e => e.name === relation.otherEntityName);
            const initialField = findPreferredFilterField(relatedEntity);

            return {
                operator: FieldConditionOperator.RelationMatches,
                children: [
                    {
                        nodeType: AdvancedSearchConditionNodeType.And,
                        children: relatedEntity
                            ? [
                                  {
                                      nodeType: AdvancedSearchConditionNodeType.Or,
                                      children: [
                                          {
                                              nodeType:
                                                  AdvancedSearchConditionNodeType.FieldCondition,
                                              fieldName: initialField.name,
                                              ...getInitialOperatorAndValue(
                                                  initialField.questionType,
                                                  regions,
                                                  currentUserId,
                                                  customLists,
                                                  initialField,
                                              ),
                                          },
                                      ],
                                  },
                              ]
                            : [],
                    },
                ],
            };
        }
        default:
            throw new Error(
                `Unsupported relation kind ${relation.kind} for relation ${relationName} on entity ${entityMetadata.name}`,
            );
    }
};

const styleEntries = [
    "bg-white",
    "bg-white",
    "bg-brand-100 border border-brand-400",
    "bg-brand-200",
    "bg-brand-300",
    "bg-brand-400",
    "bg-brand-500",
    "bg-brand-600",
    "bg-brand-700",
];

const getConditionType = (
    entityMetadata: BridgeEntityResult,
    condition: AdvancedSearchRequestCondition,
    questions: IQuestion[],
): QuestionType => {
    if (condition.questionId) {
        const question = questions.find(q => q.id === condition.questionId);
        if (question) {
            return question.questionType as QuestionType;
        }
    } else if (condition.fieldName) {
        const standardField = getEntityField(entityMetadata, condition.fieldName);
        if (standardField) {
            return standardField.questionType;
        }
    }

    return QuestionType.text;
};

const getConditionFormatter = (
    entityMetadata: BridgeEntityResult,
    condition: AdvancedSearchRequestCondition,
): BridgeFieldFormatter | null => {
    if (condition.fieldName) {
        const standardField = getEntityField(entityMetadata, condition.fieldName);
        if (standardField) {
            return standardField.formatter;
        }
    }

    return null;
};

const getNaryValues = (
    entityMetadata: BridgeEntityResult,
    condition: AdvancedSearchRequestCondition,
    questions: IQuestion[],
): BridgeNaryValue[] => {
    if (condition.questionId) {
        const selectedQuestion = questions.find(q => q.id === condition.questionId);

        if (selectedQuestion && Array.isArray(selectedQuestion.prompts)) {
            return selectedQuestion.prompts
                .sort((a, b) => (a.order || 0) - (b.order || 0))
                .map(p => {
                    return {
                        value: p.id,
                        label: p.title,
                    };
                });
        }
    } else if (condition.fieldName) {
        const standardField = getEntityField(entityMetadata, condition.fieldName);

        if (
            standardField &&
            (standardField.questionType === QuestionType.nary ||
                standardField.questionType === QuestionType.naryArray) &&
            Array.isArray(standardField.naryValues)
        ) {
            return standardField.naryValues;
        }
    }

    return [];
};

interface FieldConditionOperatorInputProps {
    condition: AdvancedSearchRequestCondition;
    onChange: (condition: AdvancedSearchRequestCondition) => void;
    questions: IQuestion[];
    entityMetadata: BridgeEntityResult;
    customLists: GetCustomListsQuery["getCustomLists"];
    entities: BridgeEntityResult[];
    field: EntityConditionFieldPicker;
}

const FieldConditionOperatorInput: React.FC<FieldConditionOperatorInputProps> = props => {
    const {
        field,
        questions,
        condition,
        onChange,
        entityMetadata,
        customLists,
        entities,
    } = props;
    const currentUserId = useCurrentUserId();
    const regions = useRegions();
    const handleOperatorChange = useCallback(
        (newOperator: FieldConditionOperator) => {
            const oldOperator = condition.operator;
            const newCondition = {
                ...condition,
                operator: newOperator,
            };

            if (condition.fieldName) {
                const entityField = getEntityField(entityMetadata, condition.fieldName);

                if (entityField?.questionType === QuestionType.customList) {
                    if (
                        oldOperator.startsWith("Nary") &&
                        newOperator.startsWith("CustomList")
                    ) {
                        const matchingItem = customLists
                            ?.find(c => c.key === entityField.customListKey)
                            ?.items?.find(i => i.key === condition.textValue);
                        if (matchingItem) {
                            newCondition.textValue = matchingItem.id;
                        }
                    } else if (
                        oldOperator.startsWith("CustomList") &&
                        newOperator.startsWith("Nary")
                    ) {
                        const matchingItem = customLists
                            ?.find(c => c.key === entityField.customListKey)
                            ?.items?.find(i => i.id === condition.textValue);
                        if (matchingItem) {
                            newCondition.textValue = matchingItem.key;
                        }
                    }

                    if (newOperator === FieldConditionOperator.CustomListInCategory) {
                        const matchingCategory = customLists
                            ?.find(c => c.key === entityField.customListKey)
                            ?.categories?.find(c => c.id === condition.textValue);

                        if (!matchingCategory) {
                            const firstCategory = customLists?.find(
                                c => c.key === entityField.customListKey,
                            )?.categories?.[0];

                            newCondition.textValue = firstCategory?.id || null;
                        }
                    }
                }
            }

            if (
                newOperator === FieldConditionOperator.DateMonthEquals &&
                typeof condition.numberValue !== "number"
            ) {
                newCondition.numberValue = 1;
            }

            if (
                newOperator === FieldConditionOperator.RelationMatches &&
                condition.children.length === 0
            ) {
                const defaultInitial = getInitialRelationOperatorValueAndChildren(
                    entities,
                    entityMetadata,
                    field.relation.name,
                    regions,
                    currentUserId,
                    customLists,
                );
                newCondition.children = defaultInitial.children;
            }
            if (newOperator === FieldConditionOperator.IsNotSet) {
                newCondition.children = [];
            }

            onChange(newCondition);
        },
        [
            onChange,
            condition,
            entityMetadata,
            customLists,
            regions,
            currentUserId,
            entities,
            field,
        ],
    );

    if (condition.nodeType === AdvancedSearchConditionNodeType.RelationCondition) {
        const relation = entityMetadata.relations.find(
            r => r.name === condition.fieldName,
        );

        if (!relation) {
            return null;
        }

        switch (relation.kind) {
            case BridgeRelationKind.OneToMany:
                return (
                    <div>
                        <Select
                            value={condition.operator}
                            onChange={e =>
                                handleOperatorChange(
                                    e.target.value as FieldConditionOperator,
                                )
                            }>
                            <option value={FieldConditionOperator.CountEquals}>
                                Has count equal to
                            </option>
                            <option value={FieldConditionOperator.CountLessThan}>
                                Has count less than
                            </option>
                            <option value={FieldConditionOperator.CountLessThanOrEqual}>
                                Has count less than or equal
                            </option>
                            <option value={FieldConditionOperator.CountGreaterThan}>
                                Has count greater than
                            </option>
                            <option
                                value={FieldConditionOperator.CountGreaterThanOrEqual}>
                                Has count greater than or equal
                            </option>
                        </Select>
                    </div>
                );
            case BridgeRelationKind.OneToOne:
                return <p>Matches conditions below</p>;
            case BridgeRelationKind.ManyToOne: {
                let operator =
                    condition.operator ?? FieldConditionOperator.RelationMatches;
                // Previous condition editor used IsSet for ManyToOne relations, upgrade in place to RelationMatches
                if (operator === FieldConditionOperator.IsSet) {
                    operator = FieldConditionOperator.RelationMatches;
                }
                return (
                    <div>
                        <Select
                            value={operator}
                            onChange={e =>
                                handleOperatorChange(
                                    e.target.value as FieldConditionOperator,
                                )
                            }>
                            <option value={FieldConditionOperator.RelationMatches}>
                                Matches conditions below
                            </option>
                            <option value={FieldConditionOperator.IsNotSet}>
                                Is not set
                            </option>
                        </Select>
                    </div>
                );
            }
        }
    }

    const questionType = getConditionType(entityMetadata, condition, questions);

    switch (questionType) {
        case QuestionType.text:
        case QuestionType.phone:
            return (
                <div>
                    <Select
                        value={condition.operator}
                        onChange={e =>
                            handleOperatorChange(e.target.value as FieldConditionOperator)
                        }>
                        <option value={FieldConditionOperator.StringContains}>
                            Contains
                        </option>
                        <option value={FieldConditionOperator.StringDoesNotContain}>
                            Does not contain
                        </option>
                        <option value={FieldConditionOperator.StringEquals}>
                            Equals
                        </option>
                        <option value={FieldConditionOperator.IsSet}>Has value</option>
                        <option value={FieldConditionOperator.IsNotSet}>
                            Has no value
                        </option>
                    </Select>
                </div>
            );
        case QuestionType.date:
        case QuestionType.calendarDateNoYear:
            return (
                <div>
                    <Select
                        value={condition.operator}
                        onChange={e =>
                            handleOperatorChange(e.target.value as FieldConditionOperator)
                        }>
                        <option value={FieldConditionOperator.DateAfter}>After</option>
                        <option value={FieldConditionOperator.DateOnOrAfter}>
                            On or after
                        </option>
                        <option value={FieldConditionOperator.DateBefore}>Before</option>
                        <option value={FieldConditionOperator.DateOnOrBefore}>
                            On or before
                        </option>
                        <option value={FieldConditionOperator.DateOn}>Is</option>
                        <option value={FieldConditionOperator.IsSet}>Has value</option>
                        <option value={FieldConditionOperator.IsNotSet}>
                            Has no value
                        </option>
                        <option value={FieldConditionOperator.DateMonthEquals}>
                            Month equals
                        </option>
                    </Select>
                </div>
            );
        case QuestionType.nary:
            return (
                <div>
                    <Select
                        value={condition.operator}
                        onChange={e =>
                            handleOperatorChange(e.target.value as FieldConditionOperator)
                        }>
                        <option value={FieldConditionOperator.NaryContains}>
                            Has selected
                        </option>
                        <option value={FieldConditionOperator.NaryDoesNotContain}>
                            Has not selected
                        </option>
                        <option value={FieldConditionOperator.IsSet}>Has value</option>
                        <option value={FieldConditionOperator.IsNotSet}>
                            Has no value
                        </option>
                    </Select>
                </div>
            );
        case QuestionType.binary:
            return (
                <div>
                    <Select
                        value={condition.operator}
                        onChange={e => {
                            handleOperatorChange(
                                e.target.value as FieldConditionOperator,
                            );
                        }}>
                        <option value={FieldConditionOperator.IsTrue}>Is selected</option>
                        <option value={FieldConditionOperator.IsFalse}>
                            Is not selected
                        </option>
                    </Select>
                </div>
            );
        case QuestionType.region:
            return (
                <div className="text-xs text-gray-400 uppercase leading-loose">
                    In region
                </div>
            );
        case QuestionType.user:
            return (
                <div>
                    <Select
                        value={condition.operator}
                        onChange={e =>
                            handleOperatorChange(e.target.value as FieldConditionOperator)
                        }>
                        <option value={FieldConditionOperator.IdEquals}>Is</option>
                        <option value={FieldConditionOperator.UserIsMe}>Is me</option>
                        <option value={FieldConditionOperator.IsSet}>Has value</option>
                        <option value={FieldConditionOperator.IsNotSet}>
                            Has no value
                        </option>
                    </Select>
                </div>
            );
        case QuestionType.number:
        case QuestionType.currency:
            return (
                <div>
                    <Select
                        value={condition.operator}
                        onChange={e =>
                            handleOperatorChange(e.target.value as FieldConditionOperator)
                        }>
                        <option value={FieldConditionOperator.NumberEquals}>
                            Equals
                        </option>
                        <option value={FieldConditionOperator.NumberLessThan}>
                            Less than
                        </option>
                        <option value={FieldConditionOperator.NumberLessThanOrEqual}>
                            Less than or equal
                        </option>
                        <option value={FieldConditionOperator.NumberGreaterThan}>
                            Greater than
                        </option>
                        <option value={FieldConditionOperator.NumberGreaterThanOrEqual}>
                            Greater than or equal
                        </option>
                        <option value={FieldConditionOperator.IsSet}>Has value</option>
                        <option value={FieldConditionOperator.IsNotSet}>
                            Has no value
                        </option>
                    </Select>
                </div>
            );
        case QuestionType.range:
            return (
                <div>
                    <Select
                        value={condition.operator}
                        onChange={e =>
                            handleOperatorChange(e.target.value as FieldConditionOperator)
                        }>
                        <option value={FieldConditionOperator.RangeContains}>
                            Range contains
                        </option>
                        <option value={FieldConditionOperator.RangeBelow}>
                            Range below
                        </option>
                        <option value={FieldConditionOperator.RangeContainsOrBelow}>
                            Range contains or below
                        </option>
                        <option value={FieldConditionOperator.RangeAbove}>
                            Range above
                        </option>
                        <option value={FieldConditionOperator.RangeContainsOrAbove}>
                            Range contains or above
                        </option>

                        <option value={FieldConditionOperator.RangeDoesNotContain}>
                            Range does not contain
                        </option>
                        <option value={FieldConditionOperator.IsSet}>Has value</option>
                        <option value={FieldConditionOperator.IsNotSet}>
                            Has no value
                        </option>
                    </Select>
                </div>
            );
        case QuestionType.customList: {
            let valueFromLegacy = condition.operator;
            if (condition.operator === FieldConditionOperator.NaryContains) {
                valueFromLegacy = FieldConditionOperator.CustomListEquals;
            } else if (condition.operator === FieldConditionOperator.NaryDoesNotContain) {
                valueFromLegacy = FieldConditionOperator.CustomListDoesNotEqual;
            }
            return (
                <div>
                    <Select
                        value={valueFromLegacy}
                        onChange={e =>
                            handleOperatorChange(e.target.value as FieldConditionOperator)
                        }>
                        <option value={FieldConditionOperator.CustomListEquals}>
                            Has selected
                        </option>
                        <option value={FieldConditionOperator.CustomListDoesNotEqual}>
                            Has not selected
                        </option>
                        <option value={FieldConditionOperator.CustomListAfter}>
                            After
                        </option>
                        <option value={FieldConditionOperator.CustomListAfterOrEqual}>
                            Equal or after
                        </option>
                        <option value={FieldConditionOperator.CustomListBefore}>
                            Before
                        </option>
                        <option value={FieldConditionOperator.CustomListBeforeOrEqual}>
                            Equal or before
                        </option>
                        <option value={FieldConditionOperator.CustomListInCategory}>
                            In category
                        </option>
                        <option value={FieldConditionOperator.IsSet}>Has value</option>
                        <option value={FieldConditionOperator.IsNotSet}>
                            Has no value
                        </option>
                    </Select>
                </div>
            );
        }
        default:
            return (
                <div className="text-xs text-gray-400 uppercase leading-loose">
                    {condition.operator}
                </div>
            );
    }
};

interface FieldConditionInputProps {
    condition: AdvancedSearchRequestCondition;
    onChange: (condition: AdvancedSearchRequestCondition) => void;
    onDelete: () => void;
    entities: BridgeEntityResult[];
    entityMetadata: BridgeEntityResult;
    customLists: GetCustomListsQuery["getCustomLists"];
}

const FieldConditionInput: React.FC<FieldConditionInputProps> = props => {
    const { condition, onChange, onDelete, entityMetadata, entities, customLists } =
        props;
    const regions = useRegions();
    const currentUserId = useCurrentUserId();
    const questionSource = useMemo(
        () => entityMetadata.questions.map(q => Question.load(q)),
        [entityMetadata],
    );

    const handleFieldChange = useCallback(
        (field: EntityConditionFieldPicker) => {
            if (field.fieldName) {
                const standardField = getEntityField(entityMetadata, field.fieldName);
                onChange({
                    questionId: null,
                    fieldName: field.fieldName,
                    nodeType: AdvancedSearchConditionNodeType.FieldCondition,
                    ...getInitialOperatorAndValue(
                        standardField?.questionType,
                        regions,
                        currentUserId,
                        customLists,
                        standardField,
                    ),
                });
            } else if (field.questionId) {
                const question = questionSource.find(q => q.id === field.questionId);

                if (!question) {
                    console.warn("Unable to locate question");
                } else {
                    const promptId =
                        question.prompts && question.prompts.length > 0
                            ? question.prompts[0].id
                            : null;
                    onChange({
                        questionId: field.questionId,
                        fieldName: null,
                        promptId,
                        nodeType: AdvancedSearchConditionNodeType.FieldCondition,
                        ...getInitialOperatorAndValue(
                            question.questionType as QuestionType,
                            regions,
                            currentUserId,
                            customLists,
                            null,
                        ),
                    });
                }
            } else if (field.relation?.name) {
                onChange({
                    fieldName: field.relation.name,
                    nodeType: AdvancedSearchConditionNodeType.RelationCondition,
                    ...getInitialRelationOperatorValueAndChildren(
                        entities,
                        entityMetadata,
                        field.relation.name,
                        regions,
                        currentUserId,
                        customLists,
                    ),
                });
            } else {
                console.warn("Something seems to have gone terribly awry!");
            }
        },
        [
            onChange,
            questionSource,
            entityMetadata,
            customLists,
            regions,
            currentUserId,
            entities,
        ],
    );

    const fieldPickerValue: EntityConditionFieldPicker = useMemo(() => {
        if (condition.nodeType === AdvancedSearchConditionNodeType.FieldCondition) {
            const field = getEntityField(entityMetadata, condition.fieldName);
            if (field?.name) {
                return { fieldName: field.name };
            } else if (condition.questionId) {
                return { questionId: condition.questionId };
            }
        } else if (
            condition.nodeType === AdvancedSearchConditionNodeType.RelationCondition
        ) {
            return { relation: { name: condition.fieldName } };
        }

        console.warn(`Unable to determine field picker value for condition`);
        return {};
    }, [condition, entityMetadata]);

    const excludeFields: IBridgeSelect[] = entityMetadata.fields
        .filter(f => !f.reportBehavior.filterable)
        .map(f => ({
            fieldName: f.name,
        }));

    return (
        <div className="flex items-center space-x-2">
            <div className="flex-1">
                <EntityFieldPicker
                    value={fieldPickerValue}
                    onChange={handleFieldChange}
                    entityMetadata={entityMetadata}
                    entityMetadataList={entities}
                    exclude={excludeFields}
                />
            </div>
            <FieldConditionOperatorInput
                condition={condition}
                onChange={onChange}
                questions={questionSource}
                entityMetadata={entityMetadata}
                customLists={customLists}
                entities={entities}
                field={fieldPickerValue}
            />
            <div className="flex-1">
                <FieldConditionValueInput
                    condition={condition}
                    onChange={onChange}
                    questions={questionSource}
                    entityMetadata={entityMetadata}
                />
            </div>
            <button type="button" onClick={onDelete}>
                <TrashIcon className="w-4 h-4" />
            </button>
        </div>
    );
};

interface FieldConditionValueInputProps {
    condition: AdvancedSearchRequestCondition;
    onChange: (condition: AdvancedSearchRequestCondition) => void;
    questions: IQuestion[];
    entityMetadata: BridgeEntityResult;
}

const FieldConditionValueInput: React.FC<FieldConditionValueInputProps> = props => {
    const { condition, onChange, questions, entityMetadata } = props;

    const naryValues = getNaryValues(entityMetadata, condition, questions);
    const conditionType = getConditionType(entityMetadata, condition, questions);
    const conditionFormatter = getConditionFormatter(entityMetadata, condition);
    const regions = useRegions();
    const users = useVisibleUsers();
    if (
        condition.operator === FieldConditionOperator.IsSet ||
        condition.operator === FieldConditionOperator.IsNotSet ||
        condition.operator === FieldConditionOperator.UserIsMe ||
        condition.operator === FieldConditionOperator.RelationMatches
    ) {
        return null;
    }

    if (condition.operator && condition.operator.startsWith("Count")) {
        return (
            <Input
                type="number"
                value={condition.numberValue}
                onChange={e =>
                    onChange({ ...condition, numberValue: e.target.valueAsNumber })
                }
            />
        );
    }

    if (condition.operator === FieldConditionOperator.DateMonthEquals) {
        return (
            <Select
                value={
                    condition.relativeDate &&
                    condition.relativeDate.interval ===
                        AdvancedSearchRelativeDateInterval.Thismonth
                        ? "current"
                        : condition.numberValue
                }
                onChange={e => {
                    const value = e.target.value;
                    if (value === "current") {
                        onChange({
                            ...condition,
                            relativeDate: {
                                interval: AdvancedSearchRelativeDateInterval.Thismonth,
                                value: 999,
                            },
                            numberValue: null,
                        });
                    } else {
                        onChange({
                            ...condition,
                            numberValue: parseInt(value, 10),
                            relativeDate: null,
                        });
                    }
                }}>
                <option value={"1"}>January</option>
                <option value={"2"}>February</option>
                <option value={"3"}>March</option>
                <option value={"4"}>April</option>
                <option value={"5"}>May</option>
                <option value={"6"}>June</option>
                <option value={"7"}>July</option>
                <option value={"8"}>August</option>
                <option value={"9"}>September</option>
                <option value={"10"}>October</option>
                <option value={"11"}>November</option>
                <option value={"12"}>December</option>
                <option value="current">This Month</option>
            </Select>
        );
    }

    if (condition.operator === FieldConditionOperator.CustomListInCategory) {
        const field = getEntityField(entityMetadata, condition.fieldName);

        if (!field?.customListKey) {
            return null;
        }

        return (
            <CustomListCategorySelect
                customListKey={field.customListKey}
                value={condition.textValue as CustomListCategoryId}
                onChange={categoryId => onChange({ ...condition, textValue: categoryId })}
            />
        );
    }

    switch (conditionType) {
        case QuestionType.date:
            return (
                <AbsoluteOrRelativeDateInput
                    initial={
                        condition.dateValue
                            ? {
                                  absolute: CalendarDate.parse(
                                      condition.dateValue,
                                  ).getOrElse(null),
                              }
                            : {
                                  relative: condition.relativeDate || {
                                      interval: AdvancedSearchRelativeDateInterval.Day,
                                      value: 1,
                                  },
                              }
                    }
                    onChange={value => {
                        if (value.absolute) {
                            onChange({
                                ...condition,
                                dateValue: value.absolute.dayStart(),
                                relativeDate: null,
                            });
                        } else {
                            onChange({
                                ...condition,
                                relativeDate: value.relative,
                                dateValue: null,
                            });
                        }
                    }}
                />
            );
        case QuestionType.calendarDateNoYear:
            return (
                <CalendarDateNoYearInput
                    initial={
                        condition.calendarDateValue
                            ? CalendarDate.parse(condition.calendarDateValue).getOrElse(
                                  CalendarDate.today,
                              )
                            : null
                    }
                    onCommit={value =>
                        onChange({
                            ...condition,
                            calendarDateValue: value ? value.toString() : null,
                        })
                    }
                />
            );
        case QuestionType.text:
            return (
                <Input
                    value={condition.textValue}
                    onChange={e => onChange({ ...condition, textValue: e.target.value })}
                />
            );
        case QuestionType.phone:
            return (
                <Input
                    value={condition.textValue}
                    onChange={e =>
                        onChange({
                            ...condition,
                            textValue: (e.target.value ?? "").replace(/[^0-9-]/g, ""),
                        })
                    }
                />
            );
        case QuestionType.number:
        case QuestionType.range:
            return (
                <Input
                    type="number"
                    value={condition.numberValue}
                    onChange={e =>
                        onChange({ ...condition, numberValue: e.target.valueAsNumber })
                    }
                />
            );
        case QuestionType.currency:
            return (
                <div className="flex items-center space-x-1">
                    <p className="text-lg">$</p>
                    {conditionFormatter === BridgeFieldFormatter.CurrencyInCents ? (
                        <Input
                            type="number"
                            value={
                                condition.numberValue
                                    ? currencyInCentsToString(
                                          condition.numberValue as CurrencyInCents,
                                      )
                                    : 0
                            }
                            onChange={e =>
                                onChange({
                                    ...condition,
                                    numberValue: stringToCurrencyInCents(e.target.value),
                                })
                            }
                        />
                    ) : (
                        <Input
                            type="number"
                            value={condition.numberValue}
                            onChange={e =>
                                onChange({
                                    ...condition,
                                    numberValue: e.target.valueAsNumber,
                                })
                            }
                        />
                    )}
                </div>
            );
        case QuestionType.nary: {
            return naryValues && naryValues.length > 0 ? (
                <Select
                    value={
                        condition.questionId ? condition.promptId : condition.textValue
                    }
                    onChange={e =>
                        condition.questionId
                            ? onChange({
                                  ...condition,
                                  promptId: e.target.value as PromptId,
                              })
                            : onChange({
                                  ...condition,
                                  textValue: e.target.value,
                              })
                    }>
                    {naryValues.map(naryValue => (
                        <option key={naryValue.value} value={naryValue.value}>
                            {naryValue.label}
                        </option>
                    ))}
                </Select>
            ) : null;
        }
        case QuestionType.region: {
            return regions && regions.length > 0 ? (
                <Select
                    value={condition.textValue}
                    onChange={e =>
                        onChange({
                            ...condition,
                            textValue: e.target.value,
                        })
                    }>
                    {regions.map(region => (
                        <option key={region.id} value={region.id}>
                            {region.name}
                        </option>
                    ))}
                </Select>
            ) : null;
        }
        case QuestionType.user: {
            return users && Object.keys(users).length > 0 ? (
                <UserSelect
                    value={condition.textValue as UserId}
                    onChange={userId => onChange({ ...condition, textValue: userId })}
                    label=""
                    includeEveryone={false}
                    includeUnassigned={false}
                    annotateMe={false}
                />
            ) : null;
        }
        case QuestionType.customList: {
            const field = getEntityField(entityMetadata, condition.fieldName);

            if (!field?.customListKey) {
                return null;
            }

            let valueMode: "id" | "key" = "id";

            if (condition.operator?.startsWith("Nary")) {
                valueMode = "key";
            }

            return (
                <CustomListSelect
                    customListKey={field.customListKey}
                    value={condition.textValue as CustomListItemId}
                    onChange={itemKey => onChange({ ...condition, textValue: itemKey })}
                    valueMode={valueMode}
                    includeArchived={true}
                />
            );
        }
    }

    return null;
};

interface RelationConditionInputProps {
    condition: AdvancedSearchRequestCondition;
    onChange: (condition: AdvancedSearchRequestCondition) => void;
    depth: number;
    onDelete: () => void;
    entityMetadata: BridgeEntityResult;
    entities: BridgeEntityResult[];
    customLists: GetCustomListsQuery["getCustomLists"];
    mode: ConditionEditorMode;
}

const RelationConditionInput: React.FC<RelationConditionInputProps> = props => {
    const {
        condition,
        onChange,
        onDelete,
        depth,
        entityMetadata,
        entities,
        customLists,
        mode,
    } = props;

    const relation = entityMetadata.relations.find(r => r.name === condition.fieldName);
    const childAndOrCondition = condition.children[0];

    const updateChildCondition = useCallback(
        (c: AdvancedSearchRequestCondition) => {
            const newCondition = produce(condition, draft => {
                draft.children = [c];
            });
            onChange(newCondition);
        },
        [condition, onChange],
    );

    return (
        <div>
            <FieldConditionInput
                entities={entities}
                customLists={customLists}
                condition={condition}
                entityMetadata={entityMetadata}
                onChange={onChange}
                onDelete={onDelete}
            />
            {childAndOrCondition ? (
                <div className="pl-4 pt-4">
                    <ConditionEditor
                        entityMetadata={entities.find(
                            e => e.name === relation.otherEntityName,
                        )}
                        entities={entities}
                        customLists={customLists}
                        condition={childAndOrCondition}
                        onChange={updateChildCondition}
                        depth={depth + 1}
                        relativeDepth={1}
                        addConditionLabel={`Add ${relation.title} condition`}
                        addGroupLabel={`Add ${relation.title} group`}
                        mode={mode}
                    />
                </div>
            ) : null}
        </div>
    );
};

export const ConditionEditor: React.FC<ConditionEditorProps> = props => {
    const {
        condition,
        onChange,
        depth,
        relativeDepth,
        entityMetadata,
        addConditionLabel,
        addGroupLabel,
        entities,
        customLists,
        mode,
    } = props;
    const regions = useRegions();
    const users = useVisibleUsers();
    const loggedInUser = users[useCurrentUserId()];

    const addFieldCondition = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e && e.preventDefault && e.preventDefault();

            onChange(
                produce(condition, draft => {
                    const standardField = findPreferredFilterField(entityMetadata);

                    draft.children.push({
                        nodeType: AdvancedSearchConditionNodeType.FieldCondition,
                        fieldName: standardField.name,
                        ...getInitialOperatorAndValue(
                            standardField.questionType,
                            regions,
                            loggedInUser.id,
                            customLists,
                            standardField,
                        ),
                    });
                }),
            );
        },
        [condition, onChange, entityMetadata, regions, loggedInUser, customLists],
    );

    const addGroup = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e && e.preventDefault && e.preventDefault();
            onChange(
                produce(condition, draft => {
                    const standardField = findPreferredFilterField(entityMetadata);

                    draft.children.push({
                        nodeType: AdvancedSearchConditionNodeType.Or,
                        children: [
                            {
                                nodeType: AdvancedSearchConditionNodeType.FieldCondition,
                                fieldName: standardField.name,
                                ...getInitialOperatorAndValue(
                                    standardField.questionType,
                                    regions,
                                    loggedInUser.id,
                                    customLists,
                                    standardField,
                                ),
                            },
                        ],
                    });
                }),
            );
        },
        [condition, onChange, entityMetadata, regions, loggedInUser, customLists],
    );

    const updateFieldCondition = useCallback(
        (index: number, updatedChildCondition: AdvancedSearchRequestCondition) => {
            onChange(
                produce(condition, draft => {
                    draft.children[index] = updatedChildCondition;
                    draft.children = draft.children.filter(
                        c =>
                            c.nodeType ===
                                AdvancedSearchConditionNodeType.FieldCondition ||
                            c.nodeType ===
                                AdvancedSearchConditionNodeType.RelationCondition ||
                            (c.children && c.children.length > 0),
                    );
                }),
            );
        },
        [condition, onChange],
    );

    const deleteFieldCondition = useCallback(
        (index: number) => {
            onChange(
                produce(condition, draft => {
                    draft.children = condition.children.filter((_, i) => index !== i);
                }),
            );
        },
        [condition, onChange],
    );

    return (
        <div
            className={`space-y-1 pr-1 rounded ${styleEntries[depth]} ${
                depth > 0 ? "py-4 pl-4" : ""
            }`}>
            {condition.children.length > 1 && mode === "advanced" ? (
                <div className="flex items-center">
                    <Select
                        value={condition.nodeType}
                        onChange={e =>
                            onChange({
                                ...condition,
                                nodeType: e.target
                                    .value as AdvancedSearchConditionNodeType,
                            })
                        }>
                        <option value={AdvancedSearchConditionNodeType.And}>
                            If all of the following are true
                        </option>
                        <option value={AdvancedSearchConditionNodeType.Or}>
                            If any of the following are true
                        </option>
                    </Select>
                </div>
            ) : null}
            {condition.children.map((childCondition, index) => {
                const node =
                    childCondition.nodeType ===
                    AdvancedSearchConditionNodeType.FieldCondition ? (
                        <FieldConditionInput
                            entities={entities}
                            entityMetadata={entityMetadata}
                            customLists={customLists}
                            condition={childCondition}
                            onChange={c => updateFieldCondition(index, c)}
                            onDelete={() => deleteFieldCondition(index)}
                        />
                    ) : childCondition.nodeType ===
                      AdvancedSearchConditionNodeType.RelationCondition ? (
                        <RelationConditionInput
                            mode={mode}
                            entityMetadata={entityMetadata}
                            entities={entities}
                            customLists={customLists}
                            condition={childCondition}
                            onChange={c => updateFieldCondition(index, c)}
                            onDelete={() => deleteFieldCondition(index)}
                            depth={depth}
                        />
                    ) : (
                        <ConditionEditor
                            mode={mode}
                            entityMetadata={entityMetadata}
                            entities={entities}
                            customLists={customLists}
                            depth={depth + 1}
                            relativeDepth={relativeDepth + 1}
                            condition={childCondition}
                            onChange={c => updateFieldCondition(index, c)}
                        />
                    );
                return (
                    <React.Fragment key={index}>
                        {node}
                        {mode === "simple" &&
                        relativeDepth > 1 &&
                        index < condition.children.length - 1 ? (
                            <Separator>Or</Separator>
                        ) : null}
                        {mode === "simple" &&
                        relativeDepth <= 1 &&
                        index < condition.children.length - 1 ? (
                            <Separator>And</Separator>
                        ) : null}
                    </React.Fragment>
                );
            })}
            {mode === "advanced" ? (
                <div className="flex items-center space-x-3">
                    <button
                        type="button"
                        onClick={addFieldCondition}
                        className={defaultLinkStyle}>
                        <span className="flex space-x-1 items-center">
                            <PlusIcon className="w-4 h-4" />
                            <span>{addConditionLabel || "Add condition"}</span>
                        </span>
                    </button>
                    <button type="button" onClick={addGroup} className={defaultLinkStyle}>
                        <span className="flex space-x-1 items-center">
                            <Bars4Icon className="w-4 h-4" />
                            <span>{addGroupLabel || "Add group"}</span>
                        </span>
                    </button>
                </div>
            ) : null}
            {mode === "simple" ? (
                relativeDepth <= 1 ? (
                    <>
                        <Separator>And</Separator>
                        <div
                            className={`space-y-1 pr-1 border rounded ${
                                styleEntries[2]
                            } ${relativeDepth > 0 ? "py-4 pl-4" : ""}`}>
                            <button
                                type="button"
                                onClick={addGroup}
                                className={defaultLinkStyle}>
                                <span className="flex space-x-1 items-center">
                                    <PlusIcon className="w-4 h-4" />
                                    <span>Add condition</span>
                                </span>
                            </button>
                        </div>
                    </>
                ) : (
                    <button
                        type="button"
                        onClick={addFieldCondition}
                        className={defaultLinkStyle}>
                        <span className="flex space-x-1 items-center">
                            <PlusIcon className="w-4 h-4" />
                            <span>Add condition</span>
                        </span>
                    </button>
                )
            ) : null}
        </div>
    );
};

interface SeparatorProps {
    children?: React.ReactNode;
}

export const Separator: React.FC<SeparatorProps> = props => {
    return <div className="font-bold text-sm text-gray-800 p-2">{props.children}</div>;
};
