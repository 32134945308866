import { ago, ClientId } from "@sp-crm/core";
import { InlineBanner } from "components/ui/inline-banner";
import { CrmPersona } from "components/ui/persona";
import { SecondaryButton } from "components/ui/secondary-button";
import {
    GetClientMatchesQuery,
    useDeleteClientMatchesMutation,
    useGetClientMatchesQuery,
} from "generated/graphql";
import React, { useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import { stableQueryOptions } from "util/requests";

interface DuplicateClientProps {
    duplicate: GetClientMatchesQuery["getClient"]["potentialDuplicates"][0];
}

const DuplicateClient: React.FC<DuplicateClientProps> = props => {
    const { duplicate } = props;

    const personas = (
        <div className="flex items-center space-x-2">
            {duplicate.bestContactName ? (
                <div className="border-r pr-2 border-gray-400">
                    <CrmPersona size="small" name={duplicate.bestContactName} />
                </div>
            ) : null}
            <CrmPersona size="small" name={duplicate.name} />
        </div>
    );

    return (
        <li className="space-y-1">
            {duplicate.viewable ? (
                <Link to={`/clients/show/${duplicate.id}`}>{personas}</Link>
            ) : (
                personas
            )}
            <div className="space-y-1">
                <p className="text-xs italic text-gray-600">
                    {duplicate.assignedUser
                        ? `Assigned to ${duplicate.assignedUser.preferredName}`
                        : "Unassigned"}
                </p>
                <p className="text-xs italic text-gray-600">
                    Created {ago(duplicate.dateAdded)}
                </p>
            </div>
        </li>
    );
};

interface DuplicateClientBannerProps {
    clientId: ClientId;
    clientName: string;
}

export const DuplicateClientBanner: React.FC<DuplicateClientBannerProps> = props => {
    const { clientId, clientName } = props;

    const query = useGetClientMatchesQuery(
        { id: clientId },
        {
            ...stableQueryOptions(),
            refetchOnMount: true,
        },
    );
    const mutation = useDeleteClientMatchesMutation();

    useEffect(() => {
        query.refetch();
    }, [clientName]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleDismiss = useCallback(
        async (e: React.MouseEvent<HTMLButtonElement>) => {
            e && e.preventDefault && e.preventDefault();
            await mutation.mutateAsync({ id: clientId });
            query.refetch();
        },
        [mutation, clientId, query],
    );

    if (query.isLoading || query.isError || !query.data) {
        return null;
    }

    return query.data.getClient.potentialDuplicates.length > 0 ? (
        <InlineBanner type="warning" showIcon={false} fontStyle="semibold">
            <div className="flex justify-between items-center">
                <p>This client record may be a duplicate of one of the clients below.</p>
                <SecondaryButton onClick={handleDismiss}>Dismiss</SecondaryButton>
            </div>
            <ul className="text-sm space-y-2">
                {query.data.getClient.potentialDuplicates.map(duplicate => (
                    <DuplicateClient key={duplicate.id} duplicate={duplicate} />
                ))}
            </ul>
        </InlineBanner>
    ) : null;
};
