import {
    AnswerEntityId,
    IAnswer,
    LayoutSectionKey,
    LayoutSectionParentKey,
    QuestionSource,
    ReferenceContactId,
} from "@sp-crm/core";
import { Feature } from "components/feature";
import { LayoutFormSection } from "components/layout/layout-form-section";
import { PhoneInput } from "components/shared/phone-input";
import { AutosavingInput } from "components/ui/autosaving-input";

import { Checkbox } from "components/ui/checkbox";
import { ContactNameInput } from "components/ui/contact-name-input";
import { PrimaryButton } from "components/ui/primary-button";
import { Spinner } from "components/ui/spinner";
import {
    GetReferenceContactQuery,
    MutationPatchReferenceContactArgs,
    useGetLayoutContainerByKeyQuery,
    useGetReferenceContactQuery,
    usePatchReferenceContactMutation,
} from "generated/graphql";
import { produce } from "immer";
import React, { useCallback } from "react";
import { useQueryClient } from "react-query";
import { stableQueryOptions } from "util/requests";

interface NewInlineReferenceProps {
    referenceContactId: ReferenceContactId | null;
    dismiss: () => void;
}

export const NewInlineReference: React.FC<NewInlineReferenceProps> = props => {
    const { referenceContactId, dismiss } = props;

    if (!referenceContactId) {
        return <Spinner />;
    }
    return (
        <div className="space-y-2 lg:space-y-4 pr-2 lg:pr-4">
            <InlineReferenceEdit referenceId={referenceContactId} />
            <div className="flex justify-end">
                <PrimaryButton onClick={dismiss}>Done</PrimaryButton>
            </div>
        </div>
    );
};

interface InlineReferenceEditProps {
    referenceId: ReferenceContactId;
}

export const InlineReferenceEdit: React.FC<InlineReferenceEditProps> = props => {
    const { referenceId } = props;
    const getReferenceContactQuery = useGetReferenceContactQuery(
        { id: referenceId },
        { keepPreviousData: true },
    );
    const patchReferenceContactMutation = usePatchReferenceContactMutation();
    const client = useQueryClient();
    const onChange = useCallback(
        async (args: MutationPatchReferenceContactArgs) => {
            const result = await patchReferenceContactMutation.mutateAsync(args);

            if (result?.patchReferenceContact) {
                client.setQueryData<GetReferenceContactQuery>(
                    ["getReferenceContact", { id: referenceId }],
                    oldData => {
                        return produce(oldData, draft => {
                            if (draft) {
                                draft.getReferenceContact = result.patchReferenceContact;
                            }
                        });
                    },
                );
            }
        },
        [patchReferenceContactMutation, client, referenceId],
    );
    const onAnswerCommitted = useCallback(
        (entityId: AnswerEntityId, answer: IAnswer) => {
            getReferenceContactQuery.refetch();
        },
        [getReferenceContactQuery],
    );

    const getLayoutContainer = useGetLayoutContainerByKeyQuery(
        { key: LayoutSectionParentKey.ReferenceContact },
        stableQueryOptions(),
    );
    const contactInformationSection =
        getLayoutContainer.data?.getLayoutContainerByKey.layoutSections.find(
            s => s.sectionKey === LayoutSectionKey.ReferenceContactContactInformation,
        );
    const reference = getReferenceContactQuery.data?.getReferenceContact;
    if (!reference || !contactInformationSection) {
        return <Spinner />;
    }
    const contact = reference.contactEntity;
    return (
        <div className="space-y-1 lg:space-y-2">
            <ContactNameInput
                name={contact.name}
                preferredName={contact.preferredName}
                onCommit={(fieldName, newValue) =>
                    onChange({
                        id: reference.id,
                        contactEntity: { [fieldName]: newValue },
                    })
                }
            />
            <AutosavingInput
                label="Title / Role"
                initial={contact.role}
                onCommit={newVal =>
                    onChange({ id: reference.id, contactEntity: { role: newVal } })
                }
            />
            <div className="flex items-center space-x-1 lg:space-x-2">
                <PhoneInput
                    label="Cell Phone"
                    initial={contact.cellPhone}
                    onCommit={newVal =>
                        onChange({
                            id: reference.id,
                            contactEntity: { cellPhone: newVal },
                        })
                    }
                />

                <PhoneInput
                    label="Alternate Phone"
                    initial={contact.phone1}
                    onCommit={newVal =>
                        onChange({
                            id: reference.id,
                            contactEntity: { phone1: newVal },
                        })
                    }
                />
            </div>
            <AutosavingInput
                label="Email Address"
                initial={contact.email1}
                onCommit={newVal =>
                    onChange({ id: reference.id, contactEntity: { email1: newVal } })
                }
            />
            <Feature name="emailoptout">
                <Checkbox
                    checked={contact.email1OptOut}
                    label="Email Opt-out (for bulk/newsletter emails)"
                    onChange={e =>
                        onChange({
                            id: reference.id,
                            contactEntity: { email1OptOut: e.target.checked },
                        })
                    }
                />
            </Feature>
            <LayoutFormSection
                entityId={reference.id}
                entity={reference}
                entityType="ReferenceContact"
                layoutSection={contactInformationSection}
                mode="fieldsonly"
                answerMode={QuestionSource.Entity}
                onAnswerCommitted={onAnswerCommitted}
            />
        </div>
    );
};
