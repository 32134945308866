import { TaskId } from "@sp-crm/core";
import { QueryRenderer } from "components/clients/show-client/community-comparison/query-renderer";
import { useGetTaskQuery } from "generated/graphql";
import React from "react";

import { Redirect, match } from "react-router-dom";
import { useRegionId } from "store/selectors/hooks";

interface Props {
    match: match<{ taskId: string }>;
}

/**
 * Currently a component that serves as a link target.
 * Fetches the task and redirects to the task detail on the entity page.
 */
export const TaskDetail: React.FC<Props> = props => {
    const { match } = props;
    const regionId = useRegionId();
    const query = useGetTaskQuery({ regionId, taskId: match.params.taskId as TaskId });
    return (
        <QueryRenderer name="TaskDetail" query={query}>
            {data => <Redirect to={`${data.getTask.entity.path}/tasks`} />}
        </QueryRenderer>
    );
};
