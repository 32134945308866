import { IClient } from "@sp-crm/core";
import { InlineBanner } from "components/ui/inline-banner";
import { Input } from "components/ui/input";
import { Panel } from "components/ui/panel/panel";
import { PanelType } from "components/ui/panel/panel-type";
import { PrimaryButton } from "components/ui/primary-button";
import { SecondaryButton } from "components/ui/secondary-button";
import { Spinner } from "components/ui/spinner";
import { Formik } from "formik";
import { useRequestClientTransferMutation } from "generated/graphql";
import React from "react";
import { useProductName } from "store/selectors/branding";

interface TransferClientPanelProps {
    client: IClient;
    onDismiss: () => void;
}

interface FormValues {
    companyName: string;
    contactName: string;
    contactEmail: string;
}

export const TransferClientPanel: React.FC<TransferClientPanelProps> = props => {
    const { client, onDismiss } = props;

    const initialValues: FormValues = {
        companyName: "",
        contactName: "",
        contactEmail: "",
    };

    const requestTransfer = useRequestClientTransferMutation();
    const productName = useProductName();

    const submitForm = React.useCallback(
        async (values: FormValues) => {
            await requestTransfer.mutateAsync({
                params: {
                    clientId: client.id,
                    companyName: values.companyName,
                    contactName: values.contactName,
                    contactEmail: values.contactEmail,
                },
            });
        },
        [requestTransfer, client.id],
    );

    return (
        <Panel
            type={PanelType.large}
            headerText={`Transfer client ${client.preferredClientName}`}
            onDismiss={onDismiss}
            isOpen={true}>
            <div className="space-y-4">
                <p>
                    Request to transfer this client to another {productName} customer. It
                    may take up to 1-2 business days to process your request.
                </p>
                <Formik initialValues={initialValues} onSubmit={submitForm}>
                    {({ handleChange, handleBlur, handleSubmit, values }) => (
                        <form onSubmit={handleSubmit} className="space-y-4">
                            <Input
                                disabled={
                                    requestTransfer.isSuccess || requestTransfer.isLoading
                                }
                                type="text"
                                label="Company Name"
                                name="companyName"
                                required
                                value={values.companyName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            <Input
                                disabled={
                                    requestTransfer.isSuccess || requestTransfer.isLoading
                                }
                                type="text"
                                label="Contact Name"
                                name="contactName"
                                required
                                value={values.contactName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            <Input
                                disabled={
                                    requestTransfer.isSuccess || requestTransfer.isLoading
                                }
                                type="email"
                                label="Contact Email"
                                name="contactEmail"
                                required
                                value={values.contactEmail}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {requestTransfer.isSuccess ? (
                                <div className="space-y-4">
                                    <InlineBanner type="success">
                                        Thank you for your request. {productName} support
                                        staff will be in touch with you to confirm your
                                        request.
                                    </InlineBanner>
                                    <SecondaryButton
                                        type="button"
                                        onClick={e => {
                                            e.preventDefault();
                                            onDismiss();
                                        }}>
                                        Close
                                    </SecondaryButton>
                                </div>
                            ) : (
                                <div className="flex items-center space-x-2">
                                    <PrimaryButton
                                        disabled={requestTransfer.isLoading}
                                        type="submit">
                                        Submit
                                    </PrimaryButton>
                                    <SecondaryButton
                                        type="button"
                                        onClick={e => {
                                            e.preventDefault();
                                            onDismiss();
                                        }}>
                                        Cancel
                                    </SecondaryButton>
                                    {requestTransfer.isLoading ? <Spinner /> : null}
                                </div>
                            )}
                        </form>
                    )}
                </Formik>
            </div>
        </Panel>
    );
};
