import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { MagnifyingGlassIcon, PlusIcon } from "@heroicons/react/24/outline";
import { ClientId } from "@sp-crm/core";
import { InlineBanner } from "components/ui/inline-banner";
import { Input } from "components/ui/input";
import { PrimaryButton } from "components/ui/primary-button";
import { SearchInputDebounced } from "components/ui/search-input-debounced";
import { SecondaryButton, secondaryClasses } from "components/ui/secondary-button";
import { Spinner } from "components/ui/spinner";
import {
    SearchExternalInvoiceCustomersQuery,
    useCreateExternalInvoiceCustomerMutation,
    useGetExternalInvoiceInitialParametersQuery,
    useSearchExternalInvoiceCustomersQuery,
} from "generated/graphql";
import React from "react";

export type ExternalInvoiceCustomer = { id: string; name: string };

interface InvoiceCustomerFieldProps {
    clientId: ClientId;
    initial: ExternalInvoiceCustomer | null;
    onChange: (customer: ExternalInvoiceCustomer | null) => void;
}

export const InvoiceCustomerField: React.FC<InvoiceCustomerFieldProps> = props => {
    const { onChange, initial, clientId } = props;
    const [customer, setCustomer] = React.useState<ExternalInvoiceCustomer | null>(
        initial,
    );
    const [createCustomerName, setCreateCustomerName] = React.useState<string>("");

    const getInitialCustomer = useGetExternalInvoiceInitialParametersQuery(
        {
            params: { clientId },
        },
        {
            enabled: !initial,
            onSuccess: data => {
                if (data?.getExternalInvoiceInitialParameters?.customer) {
                    setCustomer(data.getExternalInvoiceInitialParameters.customer);
                    onChange(data.getExternalInvoiceInitialParameters.customer);
                }

                if (data.getExternalInvoiceInitialParameters?.createCustomerName) {
                    setCreateCustomerName(
                        data.getExternalInvoiceInitialParameters.createCustomerName,
                    );
                }
            },
        },
    );

    return (
        <div className="space-y-1">
            <p>QuickBooks customer</p>
            {getInitialCustomer.isLoading ? (
                <Spinner />
            ) : (
                <div className="flex items-center space-x-2 relative">
                    <div className="flex-1">
                        {customer ? (
                            <p className="font-bold">{customer.name || "(no name)"}</p>
                        ) : (
                            <InlineBanner type="info">{`No customer named "${createCustomerName}" could be found in QuickBooks. Use the magnifying glass to find an existing customer or the plus sign to create a new QuickBooks customer.`}</InlineBanner>
                        )}
                    </div>
                    <Popover as="div" className="z-20">
                        <PopoverButton className={`${secondaryClasses}`}>
                            <MagnifyingGlassIcon className="w-4 h-4" />
                        </PopoverButton>
                        <PopoverPanel className="origin-top-right absolute right-0 mt-2 w-96 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none z-30">
                            {({ close }) => (
                                <FindQuickBooksCustomer
                                    onSelected={c => {
                                        setCustomer(c);
                                        onChange(c);
                                        close();
                                    }}
                                />
                            )}
                        </PopoverPanel>
                    </Popover>
                    <Popover as="div" className="z-20">
                        <PopoverButton className={`${secondaryClasses}`}>
                            <PlusIcon className="w-4 h-4" />
                        </PopoverButton>
                        <PopoverPanel className="origin-top-right absolute right-0 mt-2 w-96 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none z-30">
                            {({ close }) => (
                                <CreateQuickBooksCustomer
                                    defaultName={createCustomerName}
                                    onCreated={c => {
                                        setCustomer(c);
                                        onChange(c);
                                        close();
                                    }}
                                    onCancel={close}
                                />
                            )}
                        </PopoverPanel>
                    </Popover>
                </div>
            )}
        </div>
    );
};

interface FindQuickBooksCustomerProps {
    onSelected: (customer: ExternalInvoiceCustomer) => void;
}

const FindQuickBooksCustomer: React.FC<FindQuickBooksCustomerProps> = props => {
    const { onSelected } = props;
    const [searchString, setSearchString] = React.useState<string>("");
    const [results, setResults] =
        React.useState<
            SearchExternalInvoiceCustomersQuery["searchExternalInvoiceCustomers"]
        >(null);

    const trimmedSearchString = React.useMemo(() => {
        return searchString.trim();
    }, [searchString]);

    const searchCustomers = useSearchExternalInvoiceCustomersQuery(
        {
            params: { searchString: trimmedSearchString },
        },
        {
            enabled: !!trimmedSearchString,
            onSuccess: data => {
                if (data?.searchExternalInvoiceCustomers) {
                    setResults(data.searchExternalInvoiceCustomers);
                }
            },
        },
    );

    return (
        <div className="p-4 space-y-2">
            <p>Find QuickBooks customer</p>
            <div className="relative">
                <SearchInputDebounced
                    className="mt-1"
                    initial={searchString}
                    onChange={setSearchString}
                />
                {searchCustomers.isLoading ? (
                    <div className="absolute bottom-0 pb-3 right-0 pr-3 flex items-center pointer-events-none">
                        <Spinner />
                    </div>
                ) : null}
            </div>
            {trimmedSearchString && results?.count === 0 ? (
                <p>No customers found matching &quot;{searchString}&quot;</p>
            ) : null}
            {trimmedSearchString && results?.count > 0 ? (
                <ul className="p-2">
                    {results.hits.map(customer => (
                        <li
                            onClick={e => {
                                e.preventDefault();
                                onSelected(customer);
                            }}
                            key={customer.id}
                            className="cursor-pointer -mx-4 -my-1 px-4 py-2 hover:bg-brand-100 hover:text-brand-700 rounded">
                            {customer.name}
                        </li>
                    ))}
                </ul>
            ) : null}
        </div>
    );
};

interface CreateQuickBooksCustomerProps {
    defaultName: string | null;
    onCreated: (customer: ExternalInvoiceCustomer) => void;
    onCancel: () => void;
}

const CreateQuickBooksCustomer: React.FC<CreateQuickBooksCustomerProps> = props => {
    const { onCreated, defaultName, onCancel } = props;

    const createCustomer = useCreateExternalInvoiceCustomerMutation();

    const [name, setName] = React.useState<string>(defaultName || "");
    const [saveError, setSaveError] = React.useState<string | null>(null);
    const handleSave = React.useCallback(
        async (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            setSaveError(null);

            try {
                const result = await createCustomer.mutateAsync({ params: { name } });
                if (result.createExternalInvoiceCustomer) {
                    onCreated(result.createExternalInvoiceCustomer);
                }
            } catch (e) {
                setSaveError(e.message);
            }
        },
        [createCustomer, name, onCreated, setSaveError],
    );

    const handleCancel = React.useCallback(
        async (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            onCancel();
        },
        [onCancel],
    );

    return (
        <div className="p-4 space-y-2">
            <p>Create QuickBooks customer</p>
            <Input
                label="Name"
                value={name}
                onChange={e => {
                    setName(e.target.value);
                }}
            />
            <div className="flex items-center space-x-2">
                <PrimaryButton disabled={!name} onClick={handleSave}>
                    Save
                </PrimaryButton>
                <SecondaryButton onClick={handleCancel}>Cancel</SecondaryButton>
                {createCustomer.isLoading ? <Spinner /> : null}
            </div>
            {saveError ? <InlineBanner type="error">{saveError}</InlineBanner> : null}
        </div>
    );
};
