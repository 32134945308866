import { ChevronDownIcon } from "@heroicons/react/20/solid";
import {
    ClientGenderOptions,
    ClientMaritalStatuses,
    IAdditionalClient,
} from "@sp-crm/core";
import { DateOfBirth } from "components/age-birthday/date-of-birth";
import { Checkbox } from "components/ui/checkbox";
import { InlineMenu } from "components/ui/inline-menu";
import { CrmPersona } from "components/ui/persona";
import React, { FunctionComponent } from "react";
import util from "../../../../util";
import { Feature } from "../../../feature";
import { Header } from "../../../header";
import ClientBiographicalField from "../client-biographical-field";

type AdditionalClientInfoProps = {
    clientInfo: IAdditionalClient;
    clientSameAsBestContact: boolean;
    showClientIntakeWeight: boolean;
    showClientIntakeHeight: boolean;
    emailOptOut: boolean;
    now: Date;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    onFieldChange: (fieldName: string, newValue: any) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    onMultiFieldChange: (newValues: any) => void;
    onDeleteAdditionalClient: () => void;
};

const AdditionalClientInfo: FunctionComponent<AdditionalClientInfoProps> = props => {
    const updateAge = (ageStr: string) => {
        let parsedAge = parseInt(ageStr, 10);
        if (isNaN(parsedAge) || parsedAge < 0 || parsedAge > 300) {
            parsedAge = null;
        }

        const birthday = parsedAge ? util.date.yearsAgo(props.now, parsedAge) : "";
        const delta = {
            birthday,
            isBirthdayFake: true,
        };
        props.onMultiFieldChange(delta);
    };

    const updateBirthday = (birthday: Date) => {
        const delta = {
            birthday,
            isBirthdayFake: false,
        };

        props.onMultiFieldChange(delta);
    };

    return (
        <div className="border-t border-gray-200 pt-5">
            <div className="flex-row flex-space-between flex-column">
                <Header>
                    <CrmPersona name={props.clientInfo.name} size="large" />
                </Header>
                <div>
                    <InlineMenu
                        options={[
                            {
                                key: "deleteAdditionalClient",
                                label: "Delete additional client",
                                action: props.onDeleteAdditionalClient,
                            },
                        ]}
                        anchor="left">
                        <div className="flex items-center space-x-1">
                            <div>Actions</div>
                            <div>
                                <ChevronDownIcon className="w-4 h-4" />
                            </div>
                        </div>
                    </InlineMenu>
                </div>
            </div>
            <div className="space-y-2 md:space-y-4">
                <div className="adjacent-inputs">
                    <ClientBiographicalField
                        type={"text"}
                        label={"Name"}
                        fieldName={"name"}
                        initial={props.clientInfo.name}
                        onFieldChange={props.onFieldChange}
                    />
                    <ClientBiographicalField
                        type={"text"}
                        label={"Relationship to primary client"}
                        fieldName={"relationshipToClient"}
                        initial={props.clientInfo.relationshipToClient}
                        onFieldChange={props.onFieldChange}
                    />
                </div>
                <div className="adjacent-inputs">
                    <ClientBiographicalField
                        type={"select"}
                        label={"Gender"}
                        fieldName={"gender"}
                        initial={props.clientInfo.gender}
                        onFieldChange={props.onFieldChange}
                        options={ClientGenderOptions}
                    />
                    <ClientBiographicalField
                        type={"select"}
                        label={"Marital status"}
                        fieldName={"maritalStatus"}
                        initial={props.clientInfo.maritalStatus}
                        onFieldChange={props.onFieldChange}
                        options={ClientMaritalStatuses}
                    />
                </div>
                <DateOfBirth
                    birthday={props.clientInfo.birthday}
                    isBirthdayFake={props.clientInfo.isBirthdayFake}
                    updateAge={updateAge.bind(this)}
                    updateBirthday={updateBirthday.bind(this)}
                />
                <div className="flex space-x-2 md:space-x-4 items-center">
                    {props.showClientIntakeWeight && (
                        <ClientBiographicalField
                            type="text"
                            label="Weight"
                            fieldName="weight"
                            initial={props.clientInfo.weight}
                            onFieldChange={props.onFieldChange}
                            additionalClassName="small-input-field"
                        />
                    )}
                    {props.showClientIntakeHeight && (
                        <ClientBiographicalField
                            type="text"
                            label="Height"
                            fieldName="height"
                            initial={props.clientInfo.height}
                            onFieldChange={props.onFieldChange}
                            additionalClassName="small-input-field"
                        />
                    )}
                </div>
                <div className="sm:flex sm:space-x-1 md:space-x-2 lg:space-x-4">
                    <ClientBiographicalField
                        type={"phone"}
                        label={"Cell phone number"}
                        fieldName={"cellPhone"}
                        initial={props.clientInfo.cellPhone}
                        onFieldChange={props.onFieldChange}
                    />
                    <ClientBiographicalField
                        type={"phone"}
                        label={"Phone number (other)"}
                        fieldName={"phone"}
                        initial={props.clientInfo.phone}
                        onFieldChange={props.onFieldChange}
                    />
                </div>

                <div className="adjacent-inputs">
                    <ClientBiographicalField
                        type={"text"}
                        label={"Email"}
                        fieldName={"email"}
                        initial={props.clientInfo.email}
                        onFieldChange={props.onFieldChange}
                    />
                </div>
                <Feature name="emailoptout">
                    <Checkbox
                        checked={props.emailOptOut}
                        onChange={newVal =>
                            props.onFieldChange("emailOptOut", newVal.target.checked)
                        }
                        label="Email Opt-out (for bulk/newsletter emails)"
                    />
                </Feature>
            </div>
        </div>
    );
};

export default AdditionalClientInfo;
