import { InlineBanner } from "components/ui/inline-banner";
import * as React from "react";

interface ErrorProps {
    componentName: string;
    children: React.ReactNode;
}

export const Error: React.FunctionComponent<ErrorProps> = props => {
    return (
        <div>
            <InlineBanner type="error">
                <h3 className="text-xl font-fold">Error: {props.componentName}</h3>
                <pre className="mt-2">{props.children}</pre>
            </InlineBanner>
        </div>
    );
};
