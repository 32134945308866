import { defaultLinkStyle } from "components/ui/link";
import { useGetUserTenantRegionOptionsQuery } from "generated/graphql";
import * as React from "react";
import { Link } from "react-router-dom";
import { useRegionId } from "store/selectors/hooks";
import { stableQueryOptions } from "util/requests";

export const RegionToggle: React.FC<unknown> = props => {
    const query = useGetUserTenantRegionOptionsQuery({}, stableQueryOptions());
    const currentRegionId = useRegionId();
    if (query.isLoading || !query.data) {
        return null;
    }
    if (query.data.getUserTenantRegionOptions.options.length <= 1) {
        return null;
    }
    const multipleTenantsAvailable =
        query.data.getUserTenantRegionOptions.options
            .map(o => o.tenantId)
            .reduce((acc, cur) => acc.add(cur), new Set()).size > 1;

    const currentRegion = query.data.getUserTenantRegionOptions.options.find(
        o => o.regionId === currentRegionId,
    );
    const currentRegionName = currentRegion ? currentRegion.regionName : "Unknown";
    const currentTenantName = currentRegion ? currentRegion.tenantName : "Unknown";
    return (
        <div className="text-gray-500 text-sm pb-2">
            <Link to="/region">
                Region&nbsp;
                <span className={`${defaultLinkStyle} font-bold`}>
                    {multipleTenantsAvailable
                        ? `${currentTenantName}: ${currentRegionName}`
                        : currentRegionName}
                </span>
            </Link>
        </div>
    );
};
