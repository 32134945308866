import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Callout } from "components/ui/callout";
import * as React from "react";
import { SearchCalloutButtons } from "./search-callout-buttons";
import { ISearchFilterClosable } from "./search-filter-closable.interface";

interface SearchbarItemProps {
    content: JSX.Element;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    onFilterSubmitted: (params: any) => void;
    children: React.ReactNode;
}

interface SearchbarItemState {
    isSubmitted: boolean;
    isCancelled: boolean;
    isValid: boolean;
    forceClose: boolean;
}

interface ISearchFilterValue {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    activeState: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    defaultState: any;
}

export class SearchbarItem extends React.Component<
    SearchbarItemProps,
    SearchbarItemState
> {
    private calloutRef: React.RefObject<HTMLDivElement>;

    constructor(props: SearchbarItemProps) {
        super(props);
        this.state = {
            isSubmitted: false,
            isCancelled: false,
            isValid: false,
            forceClose: false,
        };
        this.onOpen = this.onOpen.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onClear = this.onClear.bind(this);
        this.onFilterStateChanged = this.onFilterStateChanged.bind(this);
        this.onFilterValidityChanged = this.onFilterValidityChanged.bind(this);
        this.renderButton = this.renderButton.bind(this);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    isFilter(child: any) {
        return (child.type.prototype as ISearchFilterClosable).onClose !== undefined;
    }

    renderFilter() {
        return React.Children.map(this.props.children, child => {
            if (this.isFilter(child))
                // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
                return React.cloneElement(child as React.ReactElement<any>, {
                    onClose: this.onFilterStateChanged,
                    onValid: this.onFilterValidityChanged,
                    isSubmitted: this.state.isSubmitted,
                });
            else return child;
        });
    }

    onFilterStateChanged(params: ISearchFilterValue) {
        if (
            ((this.state.isSubmitted && !this.state.isValid) ||
                !this.state.isSubmitted) &&
            !this.state.isCancelled
        ) {
            return;
        }

        const filterState = this.state.isCancelled
            ? params.defaultState
            : params.activeState;
        this.setState({ ...this.state, isSubmitted: false, isCancelled: false });
        this.props.onFilterSubmitted(filterState);
    }

    onFilterValidityChanged(isValid: boolean) {
        this.setState({ ...this.state, isValid: isValid });
    }

    onSubmit() {
        const toBeSaved = {
            isSubmitted: true,
            forceClose: false,
        };

        if (this.state.isValid) {
            toBeSaved.forceClose = true;
        }

        this.setState({ ...this.state, ...toBeSaved });
    }

    onClear() {
        this.setState({ ...this.state, isCancelled: true, forceClose: true });
    }

    renderButton(): JSX.Element {
        return (
            <div className="flex items-center space-x-1 cursor-pointer text-gray-700">
                <div className="text-lg">{this.props.content}</div>
                <ChevronDownIcon className="w-5 h-5 text-gray-500" />
            </div>
        );
    }

    onOpen(): void {
        this.setState({ forceClose: false });
    }

    render() {
        return (
            <div className="searchbar-item-component" ref={this.calloutRef}>
                <Callout
                    renderButton={this.renderButton}
                    onOpen={this.onOpen}
                    forceClose={this.state.forceClose}>
                    {this.renderFilter()}
                    <SearchCalloutButtons
                        onSubmit={this.onSubmit}
                        onClear={this.onClear}
                    />
                </Callout>
            </div>
        );
    }
}
