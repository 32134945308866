import * as C from "@sp-crm/core";
import { Answer, IAnswer } from "@sp-crm/core";
import * as React from "react";
import { BinaryQuestion } from "./binary";
import { CurrencyQuestion } from "./currency";
import { DateQuestion } from "./date";
import {
    NaryPickManyDropdownQuestion,
    NaryPickManyQuestion,
    NaryPickOneDropdownQuestion,
    NaryPickOneRadioQuestion,
} from "./nary";
import { NumberQuestion } from "./number";
import { PhoneQuestion } from "./phone";
import { QuestionProps } from "./props";
import { RangeCurrencyQuestion } from "./rangeCurrency";
import { RangeNumberQuestion } from "./rangeNumber";
import { TextQuestion } from "./text";
import { UserQuestion } from "./user";

// Shows the correct question display
export class DispatchQuestion extends React.Component<QuestionProps, undefined> {
    private pendingModification: Promise<IAnswer | undefined>;

    constructor(props: QuestionProps) {
        super(props);
        this.requestAnswerModification = this.requestAnswerModification.bind(this);
        this.pendingModification = Promise.resolve(props.answer);
    }

    requestAnswerModification(cb: (answer: IAnswer) => void) {
        this.pendingModification = this.pendingModification.then(a => {
            const newAnswer = a ? { ...a } : new Answer();
            newAnswer.questionId = this.props.question.id;
            cb(newAnswer);
            return this.props.callback(newAnswer);
        });
    }

    render(): JSX.Element {
        const interactionModel: C.InteractionType = this.props.question
            .interaction as C.InteractionType;
        const questionType: C.QuestionType = this.props.question
            .questionType as C.QuestionType;

        if (interactionModel === C.InteractionType.none) {
            return null;
        }

        if (questionType === C.QuestionType.binary) {
            return (
                <BinaryQuestion
                    requestAnswerModification={this.requestAnswerModification}
                    question={this.props.question}
                    answer={this.props.answer}
                />
            );
        }

        if (
            questionType === C.QuestionType.nary &&
            interactionModel === C.InteractionType.singleRadio
        ) {
            return (
                <NaryPickOneRadioQuestion
                    requestAnswerModification={this.requestAnswerModification}
                    question={this.props.question}
                    answer={this.props.answer}
                />
            );
        }

        if (
            questionType === C.QuestionType.nary &&
            interactionModel === C.InteractionType.single
        ) {
            return (
                <NaryPickOneDropdownQuestion
                    requestAnswerModification={this.requestAnswerModification}
                    question={this.props.question}
                    answer={this.props.answer}
                />
            );
        }

        if (
            questionType === C.QuestionType.nary &&
            interactionModel === C.InteractionType.multipleDropdown
        ) {
            return (
                <NaryPickManyDropdownQuestion
                    requestAnswerModification={this.requestAnswerModification}
                    question={this.props.question}
                    answer={this.props.answer}
                />
            );
        }

        if (
            questionType === C.QuestionType.nary &&
            interactionModel === C.InteractionType.multiple
        ) {
            return (
                <NaryPickManyQuestion
                    requestAnswerModification={this.requestAnswerModification}
                    question={this.props.question}
                    answer={this.props.answer}
                />
            );
        }

        if (questionType === C.QuestionType.number) {
            return (
                <NumberQuestion
                    requestAnswerModification={this.requestAnswerModification}
                    question={this.props.question}
                    answer={this.props.answer}
                />
            );
        }

        if (questionType === C.QuestionType.currency) {
            return (
                <CurrencyQuestion
                    requestAnswerModification={this.requestAnswerModification}
                    question={this.props.question}
                    answer={this.props.answer}
                />
            );
        }

        if (
            questionType === C.QuestionType.range &&
            (interactionModel === C.InteractionType.rangeNumber ||
                interactionModel === C.InteractionType.number)
        ) {
            return (
                <RangeNumberQuestion
                    requestAnswerModification={this.requestAnswerModification}
                    question={this.props.question}
                    answer={this.props.answer}
                />
            );
        }

        if (
            questionType === C.QuestionType.range &&
            (interactionModel === C.InteractionType.rangePrice ||
                interactionModel === C.InteractionType.currency)
        ) {
            return (
                <RangeCurrencyQuestion
                    requestAnswerModification={this.requestAnswerModification}
                    question={this.props.question}
                    answer={this.props.answer}
                />
            );
        }

        if (questionType === C.QuestionType.text) {
            return (
                <TextQuestion
                    requestAnswerModification={this.requestAnswerModification}
                    question={this.props.question}
                    answer={this.props.answer}
                />
            );
        }

        if (questionType === C.QuestionType.date) {
            return (
                <DateQuestion
                    requestAnswerModification={this.requestAnswerModification}
                    question={this.props.question}
                    answer={this.props.answer}
                />
            );
        }

        if (questionType === C.QuestionType.user) {
            return (
                <UserQuestion
                    requestAnswerModification={this.requestAnswerModification}
                    question={this.props.question}
                    answer={this.props.answer}
                />
            );
        }

        if (questionType === C.QuestionType.phone) {
            return (
                <PhoneQuestion
                    requestAnswerModification={this.requestAnswerModification}
                    question={this.props.question}
                    answer={this.props.answer}
                />
            );
        }

        console.error({ msg: "Cannot determine control.", q: this.props.question });
        return <div />;
        // throw `Can't determine control for ${this.props.question.id}`;
    }
}
