import { ActionType, ICommunity } from "@sp-crm/core";
import * as React from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from "store/state";
import { isAllowed } from "../../../util/permissions";
import { CommunityInvoices } from "../invoices";

interface Props {
    community: ICommunity;
}

export const InvoiceTab: React.FC<Props> = props => {
    const canModifyInvoices = useSelector((state: ApplicationState) =>
        isAllowed(ActionType.CreateModifyInvoice, state.permissions, state.region),
    );
    const { community } = props;
    return (
        <div>
            <CommunityInvoices
                canModifyInvoices={canModifyInvoices}
                communityId={community.id}
            />
        </div>
    );
};
