import { CustomListKey, Tenant } from "@sp-crm/core";
import { SupportEmailLink } from "components/app/support-email-link";
import { FileSelect, SelectableFile } from "components/files/file-select";
import { ContentFull, ContentHeader, ContentSubheader } from "components/layout";
import { NumberInput } from "components/shared/number-input";
import { InlineEmailTemplateSetting } from "components/templates/inline-email-template-setting";
import { AutosavingInput } from "components/ui/autosaving-input";
import { InlineBanner } from "components/ui/inline-banner";
import { GetCustomListQuery } from "generated/graphql";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { updateTenant } from "store/actions";
import { useProductName } from "store/selectors/branding";
import { useCurrentTenant, useFeature, useTenantSettings } from "store/selectors/hooks";
import { AgencyCheckboxPref } from "./agency-settings-checkbox";
import { useCommitTenantSetting } from "./agency-settings-hooks";
import { AgencySettingsOngoingPref } from "./agency-settings-ongoing";
import { CustomListEditor } from "./custom-list-editor";

const isPdfFile = (file: SelectableFile): boolean => {
    const pdfMimeTypes = [
        "application/pdf",
        "application/x-pdf",
        "application/acrobat",
        "applications/vnd.pdf",
        "text/pdf",
    ];

    if (file.mimeType && pdfMimeTypes.includes(file.mimeType)) {
        return true;
    }

    if (file.originalFilename && file.originalFilename.endsWith(".pdf")) {
        return true;
    }

    return false;
};

const getClientStatusHelpText = (
    item: GetCustomListQuery["getCustomList"]["items"][0],
): string => {
    switch (item.key) {
        case "new":
            return "New clients will have this status";
        case "scheduled":
            return "For clients scheduled to move into a community";
        case "invoiced":
            return "To denote when an invoice has been sent for services";
        case "moved":
            return "For clients who have moved into a community";
        case "paid":
            return "For placements that have been fully paid";
        case "checkLater":
            return "For clients who should be checked on later";
        default:
            return "";
    }
};

export const AgencySettingsClients: React.FC<unknown> = () => {
    const clientDisclosuresEnabled = useFeature("clientDisclosures");
    const customClientStatusEnabled = useFeature("customizeClientStatus");
    const updateClientDisclosureFileId = useCommitTenantSetting("clientDisclosureFileId");
    const updateClientDisclosureSendTemplateId = useCommitTenantSetting(
        "clientDisclosureSendTemplateId",
    );
    const updateClientDisclosureReceiptTemplateId = useCommitTenantSetting(
        "clientDisclosureReceiptTemplateId",
    );
    const updateClosedPaidDuration = useCommitTenantSetting("closedPaidDuration");
    const tenant = useCurrentTenant();
    const dispatch = useDispatch();
    const updatePdfDisclaimer = useCallback(
        (val: string) => {
            const reloadedTenant = Tenant.fromDocument(tenant);
            reloadedTenant.tenantId = tenant.tenantId;
            reloadedTenant.pdfDisclaimer = val;
            updateTenant(reloadedTenant, dispatch);
        },
        [tenant, dispatch],
    );
    const settings = useTenantSettings();
    const productName = useProductName();

    return (
        <div className="space-y-4 lg:space-y-8">
            <ContentFull>
                <ContentHeader>Clients</ContentHeader>
                <div className="space-y-4">
                    <AgencyCheckboxPref
                        field="showClientFirst"
                        label="Show Client Info first, before the Best Contact Details"
                        description="Wherever Client and Best Contact info is shown, the Client info will show first."
                    />

                    <AgencyCheckboxPref
                        field="showClientIntakeWeight"
                        label="Show Client Weight Field"
                        description='On the Overview page, show the "Weight" field.'
                    />

                    <AgencyCheckboxPref
                        field="showClientIntakeHeight"
                        label="Show Client Height Field"
                        description='On the Overview page, show the "Height" field.'
                    />

                    <AgencyCheckboxPref
                        field="showClientMonthlyBudget"
                        label="Show Client Monthly Budget Field"
                        description='On the Intake Details page, show the "Monthly Budget" field.'
                    />

                    <AgencyCheckboxPref
                        field="showClientTotalFinances"
                        label="Show Client Total Finances Field"
                        description='On the Intake Details page, show the "Total Finances" field.'
                    />

                    <AgencyCheckboxPref
                        field="showPhysicianContacts"
                        label="Allow Data Entry for Care Providers on Client Intake Form"
                        description="On the Client Intake page, the intake form will show a box to enter care provider details."
                    />

                    <AgencyCheckboxPref
                        field="showInsuranceInfo"
                        label="Allow Data Entry for Health Insurance on Client Intake Form"
                        description="On the Client Intake page, the intake form will show a box to enter physician(s) details."
                    />

                    {customClientStatusEnabled ? null : <AgencySettingsOngoingPref />}

                    <AgencyCheckboxPref
                        field="showClientSerial"
                        label="Generate ID number for clients"
                        description="Give each client an ID number that can be used to quickly find them in the system."
                    />

                    <NumberInput
                        label="Number of days a client is visible on the dashboard after payment received"
                        initial={settings.closedPaidDuration}
                        onCommit={updateClosedPaidDuration}
                    />
                </div>
            </ContentFull>
            {clientDisclosuresEnabled ? (
                <ContentFull>
                    <ContentHeader>Disclosures</ContentHeader>
                    <div className="space-y-4">
                        <FileSelect
                            label="Choose PDF to use for client disclosures"
                            description="To add a PDF to this list, upload it using the Files tab"
                            fileFilter={isPdfFile}
                            value={settings.clientDisclosureFileId}
                            onChange={updateClientDisclosureFileId}
                        />
                        <InlineEmailTemplateSetting
                            subjectLabel="Client disclosure request subject"
                            bodyLabel="Client disclosure request body"
                            value={settings.clientDisclosureSendTemplateId}
                            onChange={updateClientDisclosureSendTemplateId}
                        />
                        <InlineEmailTemplateSetting
                            subjectLabel="Disclosure acknowledgement thank you subject"
                            bodyLabel="Disclosure acknowledgement thank you body"
                            value={settings.clientDisclosureReceiptTemplateId}
                            onChange={updateClientDisclosureReceiptTemplateId}
                        />
                    </div>
                </ContentFull>
            ) : null}
            <ContentFull>
                <ContentHeader>Referral PDF</ContentHeader>
                <ContentSubheader>Outbound referral to communities.</ContentSubheader>
                <div className="space-y-4">
                    <AutosavingInput
                        label="Client Referral PDF Disclaimer"
                        multiLine={true}
                        initial={tenant.pdfDisclaimer ?? ""}
                        onCommit={updatePdfDisclaimer}
                    />
                    <p>
                        The above disclaimer will be shown at the bottom of any PDFs
                        exported with Client details.
                    </p>
                </div>
            </ContentFull>
            <ContentFull>
                <ContentHeader>Client Statuses</ContentHeader>
                <ContentSubheader>
                    Customize client status labels, order, and categories to support your
                    placement workflow. Some statuses (e.g. New, Moved, Closed - Paid)
                    have specific purposes for {productName} functionality, so make sure
                    their names reflect their intended use.
                </ContentSubheader>
                {customClientStatusEnabled ? null : (
                    <InlineBanner type="info">
                        Client status customization is available to {productName} Premium
                        customers. Contact <SupportEmailLink /> to discuss an upgrade to
                        Premium.
                    </InlineBanner>
                )}
                <CustomListEditor
                    disabled={!customClientStatusEnabled}
                    customListKey={CustomListKey.ClientStatus}
                    getHelpText={getClientStatusHelpText}
                />
            </ContentFull>
        </div>
    );
};
