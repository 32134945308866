import {
    ActionType,
    CommunityId,
    emailTokens,
    HostedFormId,
    ReferenceBusinessId,
    ReferenceContactId,
} from "@sp-crm/core";
import { QueryRenderer } from "components/clients/show-client/community-comparison/query-renderer";
import { MandatoryEmailToken } from "components/messages/mandatory-token";
import { MessageComposeInline } from "components/messages/message-compose-internal";
import { PlacementMessageCompose } from "components/messages/types";
import { CopyableLink } from "components/shared/copyable-link";
import { fancyAlert } from "components/ui/fancy-alert";
import { InlineBanner } from "components/ui/inline-banner";
import { InternalLink } from "components/ui/internal-link";
import { Panel } from "components/ui/panel/panel";
import { PanelType } from "components/ui/panel/panel-type";
import { PrimaryButton } from "components/ui/primary-button";
import { Radio } from "components/ui/radio";
import { SecondaryButton } from "components/ui/secondary-button";
import { Select } from "components/ui/select";
import { Spinner } from "components/ui/spinner";
import {
    AdvancedSearchEntityType,
    useGetHostedFormsQuery,
    useSendHostedFormWithPermalinkMutation,
} from "generated/graphql";
import { noop } from "lodash";
import React, { MutableRefObject, useCallback } from "react";
import { useIsAllowed, useRegionId } from "store/selectors/hooks";

const getReferralSourceParams = (
    referenceContactId?: ReferenceContactId,
    referenceBusinessId?: ReferenceBusinessId,
    referenceCommunityId?: CommunityId,
) => {
    if (referenceContactId)
        return {
            entityId: referenceContactId,
            entityType: AdvancedSearchEntityType.ReferenceContact,
            text: "contact",
        };
    if (referenceBusinessId)
        return {
            entityId: referenceBusinessId,
            entityType: AdvancedSearchEntityType.ReferenceBusiness,
            text: "organization",
        };
    if (referenceCommunityId)
        return {
            entityId: referenceCommunityId,
            entityType: AdvancedSearchEntityType.Community,
            text: "community",
        };
    throw new Error("Must specify referral source");
};

interface CreateReferralLinkPanelProps {
    referenceContactId?: ReferenceContactId;
    referenceBusinessId?: ReferenceBusinessId;
    referenceCommunityId?: CommunityId;
    onDismiss: () => void;
}

export const CreateReferralLinkPanel: React.FC<CreateReferralLinkPanelProps> = props => {
    const { referenceContactId, referenceBusinessId, referenceCommunityId, onDismiss } =
        props;

    if (referenceBusinessId && referenceCommunityId) {
        throw new Error("Cannot specify both business and community as referrals");
    }

    const {
        entityId,
        entityType,
        text: referralSourceText,
    } = getReferralSourceParams(
        referenceContactId,
        referenceBusinessId,
        referenceCommunityId,
    );

    if (!referenceContactId && !referenceBusinessId && !referenceCommunityId) {
        throw new Error("Must specify referral source");
    }

    const regionId = useRegionId();
    const [shareMode, setShareMode] = React.useState<"link" | "email">("email");
    const canCreateHostedForm = useIsAllowed(ActionType.CreateHostedForm);
    const [selectedForm, setSelectedForm] = React.useState<{
        id: HostedFormId;
        url: string;
    } | null>(null);
    const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

    const containerRef = React.useRef<HTMLDivElement>(null);
    const inlineEditorRef =
        React.useRef<PlacementMessageCompose>() as MutableRefObject<PlacementMessageCompose>;

    const formLinkQueryParams = React.useMemo(() => {
        const params = new URLSearchParams();

        params.set("regionId", regionId);
        if (referenceBusinessId) params.set("referenceBusinessId", referenceBusinessId);
        if (referenceContactId) params.set("referenceContactId", referenceContactId);
        if (referenceCommunityId)
            params.set("referenceCommunityId", referenceCommunityId);

        return params.toString();
    }, [regionId, referenceBusinessId, referenceContactId, referenceCommunityId]);

    const getHostedForms = useGetHostedFormsQuery(
        {},
        {
            onSuccess: data => {
                if (!selectedForm && data?.getHostedForms.length > 0) {
                    setSelectedForm(data.getHostedForms[0]);
                }
            },
            keepPreviousData: true,
        },
    );

    const sendHostedForm = useSendHostedFormWithPermalinkMutation();

    const buildFormUrl = (formUrl: string): string => {
        return `${formUrl}?${formLinkQueryParams}`;
    };

    const handleFormSelected = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const form = getHostedForms.data?.getHostedForms.find(
            f => f.id === e.target.value,
        );
        if (form) {
            setSelectedForm({
                id: form.id,
                url: form.url,
            });
        }
    };

    const handleSend = useCallback(async () => {
        const message = inlineEditorRef.current.getMessage();
        try {
            if (!(message.body || "").includes(emailTokens.intakeLiveLink)) {
                await fancyAlert(
                    "Link Placeholder Missing",
                    `To send the form the message body must contain ${emailTokens.intakeLiveLink}. To proceed, add ${emailTokens.intakeLiveLink} to the body and send again.`,
                );
                return;
            }
            setErrorMessage(null);

            await sendHostedForm.mutateAsync({
                params: {
                    message,
                    hostedFormId: selectedForm.id,
                    formLinkQueryParams,
                },
            });
            containerRef.current?.scrollIntoView({ behavior: "smooth" });
            onDismiss();
        } catch (e) {
            setErrorMessage(e.message);
        }
    }, [selectedForm, inlineEditorRef, sendHostedForm, formLinkQueryParams, onDismiss]);

    return (
        <Panel
            type={PanelType.extraLarge}
            onDismiss={onDismiss}
            isOpen={true}
            headerText="Share referral link">
            <div className="space-y-4">
                <QueryRenderer query={getHostedForms} name="GetHostedForms">
                    {data => (
                        <>
                            <p>
                                Share a hosted intake form linked to this{" "}
                                {referralSourceText}.
                            </p>
                            {data.getHostedForms.length === 0 ? (
                                <p>
                                    No hosted intake forms are configured for your agency.{" "}
                                    {canCreateHostedForm ? (
                                        <>
                                            Navigate to{" "}
                                            <InternalLink to="/settings/agency/hosted-form">
                                                Hosted Intake Form settings
                                            </InternalLink>{" "}
                                            to configure a new form.
                                        </>
                                    ) : (
                                        <>
                                            Contact your administrator to configure a
                                            hosted form
                                        </>
                                    )}
                                </p>
                            ) : (
                                <>
                                    {data.getHostedForms.length > 1 ? (
                                        <Select
                                            label="Choose form"
                                            value={selectedForm?.id}
                                            onChange={handleFormSelected}>
                                            {data.getHostedForms.map(form => (
                                                <option key={form.id} value={form.id}>
                                                    {form.displayName}
                                                </option>
                                            ))}
                                        </Select>
                                    ) : null}
                                    {selectedForm ? (
                                        <>
                                            <div className="space-y-1">
                                                <Radio
                                                    label="Send email with referral link"
                                                    value="email"
                                                    checked={shareMode === "email"}
                                                    onChange={() => setShareMode("email")}
                                                />
                                                <Radio
                                                    label="Copy link to clipboard"
                                                    value="link"
                                                    checked={shareMode === "link"}
                                                    onChange={() => setShareMode("link")}
                                                />
                                            </div>
                                            {shareMode === "link" ? (
                                                <CopyableLink
                                                    url={buildFormUrl(selectedForm.url)}
                                                />
                                            ) : (
                                                <>
                                                    <MessageComposeInline
                                                        entityId={entityId}
                                                        entityType={entityType}
                                                        onDismiss={noop}
                                                        ref={inlineEditorRef}
                                                        requestSpecialMessageExperience="hosted-form"
                                                    />
                                                    <div className="my1">
                                                        <MandatoryEmailToken use="hosted-intake" />
                                                    </div>
                                                    <div className="flex items-center justify-end space-x-2">
                                                        {sendHostedForm.isLoading ? (
                                                            <Spinner />
                                                        ) : null}
                                                        <SecondaryButton
                                                            type="button"
                                                            onClick={onDismiss}>
                                                            Discard
                                                        </SecondaryButton>
                                                        <PrimaryButton
                                                            type="button"
                                                            onClick={handleSend}>
                                                            Send
                                                        </PrimaryButton>
                                                    </div>
                                                    {errorMessage ? (
                                                        <InlineBanner type="error">
                                                            {errorMessage}
                                                        </InlineBanner>
                                                    ) : null}
                                                </>
                                            )}
                                        </>
                                    ) : null}
                                </>
                            )}
                        </>
                    )}
                </QueryRenderer>
            </div>
        </Panel>
    );
};
