import { CalendarDate, RegionId } from "@sp-crm/core";
import {
    AdvancedSearchCountTile,
    AdvancedSearchSummaryTile,
} from "components/advanced-search/advanced-search-tiles";
import { CalendarDateInput } from "components/ui/calendar-date";
import { Spinner } from "components/ui/spinner";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRegionId } from "store/selectors/hooks";
import { ApplicationState } from "store/state";
import { stableQueryOptions } from "util/requests";
import {
    AdvancedSearchAggregateOperator,
    AdvancedSearchConditionNodeType,
    AdvancedSearchEntityType,
    AdvancedSearchRequest,
    CustomListKey,
    FieldConditionOperator,
    GetCustomListQuery,
    useGetCustomListQuery,
    useGetEntitiesQuery,
} from "../../../generated/graphql";
import { Actions } from "../../../store/actions";
import { ContentContainer, SectionHeader, Stage, Tiles } from "../../layout";

export const Summary: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const endDateOnChangeHandler = (newdate: CalendarDate) => {
        dispatch({
            type: Actions[Actions.REPORT_PARAMETER_SUMMARY_END_DATE_UPDATE],
            value: newdate,
        });
    };

    const startDateOnChangeHandler = (newdate: CalendarDate) => {
        dispatch({
            type: Actions[Actions.REPORT_PARAMETER_SUMMARY_START_DATE_UPDATE],
            value: newdate,
        });
    };

    const startDate = useSelector(
        (state: ApplicationState) => state.reportParameters.summary.startDate,
    );

    const endDate = useSelector(
        (state: ApplicationState) => state.reportParameters.summary.endDate,
    );

    const regionId = useRegionId();
    const regionKey = useSelector(
        (state: ApplicationState) => state.region.selectedRegion,
    );
    const validQuery = regionId && startDate && endDate;

    return (
        <Stage>
            <SectionHeader title="Summary">
                <div className="max-w-lg lg:max-w-none lg:flex justify-between lg:space-x-3 items-end">
                    <CalendarDateInput
                        label="Start"
                        value={startDate}
                        onChange={startDateOnChangeHandler}
                    />
                    <CalendarDateInput
                        label="End"
                        value={endDate}
                        onChange={endDateOnChangeHandler}
                    />
                </div>
            </SectionHeader>
            {validQuery && (
                <Query
                    startDate={startDate.dayStart().toISOString()}
                    endDate={endDate.dayEnd().toISOString()}
                    regionId={regionId}
                    regionKey={regionKey}
                />
            )}
        </Stage>
    );
};

const buildNewClientsSearchRequest = (
    startDate: string,
    endDate: string,
    regionId: RegionId,
): AdvancedSearchRequest => {
    return {
        entityType: AdvancedSearchEntityType.Client,
        select: [{ fieldName: "id" }],
        condition: {
            nodeType: AdvancedSearchConditionNodeType.And,
            children: [
                {
                    nodeType: AdvancedSearchConditionNodeType.FieldCondition,
                    fieldName: "regionId",
                    operator: FieldConditionOperator.StringEquals,
                    textValue: regionId,
                },
                {
                    nodeType: AdvancedSearchConditionNodeType.Or,
                    children: [
                        {
                            nodeType: AdvancedSearchConditionNodeType.And,
                            children: [
                                {
                                    nodeType:
                                        AdvancedSearchConditionNodeType.FieldCondition,
                                    fieldName: "dateAdded",
                                    operator: FieldConditionOperator.DateOnOrAfter,
                                    dateValue: startDate,
                                },
                                {
                                    nodeType:
                                        AdvancedSearchConditionNodeType.FieldCondition,
                                    fieldName: "dateAdded",
                                    operator: FieldConditionOperator.DateOnOrBefore,
                                    dateValue: endDate,
                                },
                                {
                                    nodeType:
                                        AdvancedSearchConditionNodeType.FieldCondition,
                                    fieldName: "dateAddedManualOverride",
                                    operator: FieldConditionOperator.IsNotSet,
                                },
                            ],
                        },
                        {
                            nodeType: AdvancedSearchConditionNodeType.And,
                            children: [
                                {
                                    nodeType:
                                        AdvancedSearchConditionNodeType.FieldCondition,
                                    fieldName: "dateAddedManualOverride",
                                    operator: FieldConditionOperator.DateOnOrAfter,
                                    dateValue: startDate,
                                },
                                {
                                    nodeType:
                                        AdvancedSearchConditionNodeType.FieldCondition,
                                    fieldName: "dateAddedManualOverride",
                                    operator: FieldConditionOperator.DateOnOrBefore,
                                    dateValue: endDate,
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        sort: { column: "id", ascending: true },
        pageSize: 1,
    };
};

const buildMovedClientsSearchRequest = (
    startDate: string,
    endDate: string,
    regionId: RegionId,
): AdvancedSearchRequest => {
    return {
        entityType: AdvancedSearchEntityType.Client,
        select: [{ fieldName: "id" }],
        condition: {
            nodeType: AdvancedSearchConditionNodeType.And,
            children: [
                {
                    nodeType: AdvancedSearchConditionNodeType.FieldCondition,
                    fieldName: "regionId",
                    operator: FieldConditionOperator.StringEquals,
                    textValue: regionId,
                },
                {
                    nodeType: AdvancedSearchConditionNodeType.FieldCondition,
                    fieldName: "moveTimelineActual",
                    operator: FieldConditionOperator.DateOnOrAfter,
                    dateValue: startDate,
                },
                {
                    nodeType: AdvancedSearchConditionNodeType.FieldCondition,
                    fieldName: "moveTimelineActual",
                    operator: FieldConditionOperator.DateOnOrBefore,
                    dateValue: endDate,
                },
            ],
        },
        sort: { column: "id", ascending: true },
        pageSize: 1,
    };
};

const buildActiveClientsByStatusSearchRequest = (
    regionId: RegionId,
    clientStatusCustomList: GetCustomListQuery["getCustomList"],
): AdvancedSearchRequest => {
    const movedListItem =
        clientStatusCustomList?.items.find(i => i.key === "moved") || null;

    const activeCategory =
        clientStatusCustomList?.categories?.find(c => c.key === "Active") || null;

    if (!movedListItem || !activeCategory) {
        return null;
    }

    return {
        entityType: AdvancedSearchEntityType.Client,
        select: [{ fieldName: "id" }],
        condition: {
            nodeType: AdvancedSearchConditionNodeType.And,
            children: [
                {
                    nodeType: AdvancedSearchConditionNodeType.FieldCondition,
                    fieldName: "regionId",
                    operator: FieldConditionOperator.StringEquals,
                    textValue: regionId,
                },
                {
                    nodeType: AdvancedSearchConditionNodeType.FieldCondition,
                    operator: FieldConditionOperator.CustomListBefore,
                    fieldName: "statusListItem",
                    textValue: movedListItem.id,
                },
                {
                    nodeType: AdvancedSearchConditionNodeType.FieldCondition,
                    operator: FieldConditionOperator.CustomListInCategory,
                    fieldName: "statusListItem",
                    textValue: activeCategory.id,
                },
            ],
        },
        summaries: [
            {
                groupBy: ["statusListItem"],
                aggregates: [
                    { column: "*", operator: AdvancedSearchAggregateOperator.Count },
                ],
                sort: { aggregateIndex: 0, ascending: false },
            },
        ],
        sort: { column: "id", ascending: true },
        pageSize: 1000,
    };
};

const buildActiveClientsPerEmployeeSearchRequest = (
    regionId: RegionId,
    clientStatusCustomList: GetCustomListQuery["getCustomList"],
): AdvancedSearchRequest => {
    const movedListItem =
        clientStatusCustomList?.items.find(i => i.key === "moved") || null;

    const activeCategory =
        clientStatusCustomList?.categories?.find(c => c.key === "Active") || null;

    if (!movedListItem || !activeCategory) {
        return null;
    }

    return {
        entityType: AdvancedSearchEntityType.Client,
        select: [{ fieldName: "id" }],
        condition: {
            nodeType: AdvancedSearchConditionNodeType.And,
            children: [
                {
                    nodeType: AdvancedSearchConditionNodeType.FieldCondition,
                    fieldName: "regionId",
                    operator: FieldConditionOperator.StringEquals,
                    textValue: regionId,
                },
                {
                    nodeType: AdvancedSearchConditionNodeType.FieldCondition,
                    operator: FieldConditionOperator.CustomListBefore,
                    fieldName: "statusListItem",
                    textValue: movedListItem.id,
                },
                {
                    nodeType: AdvancedSearchConditionNodeType.FieldCondition,
                    operator: FieldConditionOperator.CustomListInCategory,
                    fieldName: "statusListItem",
                    textValue: activeCategory.id,
                },
            ],
        },
        summaries: [
            {
                groupBy: ["assignedUserId"],
                aggregates: [
                    { column: "*", operator: AdvancedSearchAggregateOperator.Count },
                ],
                sort: { aggregateIndex: 0, ascending: false },
            },
        ],
        sort: { column: "id", ascending: true },
        pageSize: 1000,
    };
};

const Query: React.FC<{
    startDate: string;
    endDate: string;
    regionId: RegionId;
    regionKey: string;
}> = props => {
    const entityMetadataList = useGetEntitiesQuery({}, stableQueryOptions());
    const clientStatusCustomList = useGetCustomListQuery(
        { key: CustomListKey.ClientStatus },
        stableQueryOptions(),
    );

    const searchRequests = useMemo(() => {
        return {
            newClients: buildNewClientsSearchRequest(
                props.startDate,
                props.endDate,
                props.regionId,
            ),
            movedClients: buildMovedClientsSearchRequest(
                props.startDate,
                props.endDate,
                props.regionId,
            ),
            activeClientsByStatus: buildActiveClientsByStatusSearchRequest(
                props.regionId,
                clientStatusCustomList.data?.getCustomList,
            ),
            activeClientsPerEmployee: buildActiveClientsPerEmployeeSearchRequest(
                props.regionId,
                clientStatusCustomList.data?.getCustomList,
            ),
        };
    }, [
        props.startDate,
        props.endDate,
        props.regionId,
        clientStatusCustomList.data?.getCustomList,
    ]);

    if (
        entityMetadataList.isLoading ||
        clientStatusCustomList.isLoading ||
        !entityMetadataList.data ||
        !clientStatusCustomList.data
    ) {
        return <Spinner />;
    }

    return (
        <ContentContainer>
            <Tiles>
                <AdvancedSearchCountTile
                    request={searchRequests.newClients}
                    title="New Clients"
                    entityMetadataList={entityMetadataList.data.getEntities}
                />
                <AdvancedSearchCountTile
                    request={searchRequests.movedClients}
                    title="Clients Moved"
                    entityMetadataList={entityMetadataList.data.getEntities}
                />
                {searchRequests.activeClientsByStatus ? (
                    <AdvancedSearchSummaryTile
                        request={searchRequests.activeClientsByStatus}
                        title="Status of Active Clients"
                        entityMetadataList={entityMetadataList.data.getEntities}
                    />
                ) : null}
                {searchRequests.activeClientsPerEmployee ? (
                    <AdvancedSearchSummaryTile
                        request={searchRequests.activeClientsPerEmployee}
                        title="Active Clients per Employee"
                        entityMetadataList={entityMetadataList.data.getEntities}
                    />
                ) : null}
            </Tiles>
        </ContentContainer>
    );
};

interface LargeNumberProps {
    children: React.ReactNode;
}

export const LargeNumber: React.FunctionComponent<LargeNumberProps> = props => {
    return <div className="text-center text-6xl font-light p-4">{props.children}</div>;
};
