import React, { forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";
import { useComposeMessageQuery } from "../../generated/graphql";
import {
    getInsertableTemplates,
    getSignatureTemplates,
} from "../editor/upgrade-template";
import { ErrorMessage } from "../ui/error-message";
import { Spinner } from "../ui/spinner";
import { MessageComposeInternal } from "./message-compose";
import { PlacementMessageCompose, Props } from "./types";

const MessageComposeInline: ForwardRefExoticComponent<
    Props & RefAttributes<PlacementMessageCompose>
> = forwardRef<PlacementMessageCompose, Props>((props, ref) => {
    const { entityId, requestSpecialMessageExperience } = props;

    const query = useComposeMessageQuery({
        entityId,
        specialMessageExperience: requestSpecialMessageExperience,
    });

    if (query.isLoading) {
        return <Spinner />;
    }
    if (query.isError) {
        return (
            <ErrorMessage component="InvoiceList">
                <pre>{JSON.stringify(query.error, null, 2)}</pre>
            </ErrorMessage>
        );
    }
    if (!query.data) {
        return <Spinner />;
    }

    return (
        <MessageComposeInternal
            {...props}
            {...query.data.composeMessage}
            signatures={getSignatureTemplates(query.data.getTemplates.templates)}
            templates={getInsertableTemplates(query.data.getTemplates.templates)}
            hideSendControls
            ref={ref}
        />
    );
});

MessageComposeInline.displayName = "MessageComposeInline";
export { MessageComposeInline };
