import * as React from "react";
import * as ReportConstants from "../constants";
import { CustomReports } from "./custom-reports";
import { Referrals } from "./referrals";
import { ClientPlacementRevenue } from "./revenue";
import { Summary } from "./summary";

interface Props {
    reportName: ReportConstants.ReportNames;
}

export const ReportsShow: React.FC<Props> = props => {
    const reportName = props.reportName;

    if (reportName === "summary") {
        return <Summary />;
    }

    if (reportName === "revenue") {
        return <ClientPlacementRevenue />;
    }

    if (reportName === "inbound-referrals" || reportName === "referrals") {
        return <Referrals />;
    }

    if (reportName === "custom") {
        return <CustomReports />;
    }

    return <h2>Sorry, no report found</h2>;
};
