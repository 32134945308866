import { Identifier, XYCoord } from "dnd-core";
import React, { ComponentType, FunctionComponent, MutableRefObject } from "react";
import { useDrag, useDrop } from "react-dnd";

export interface DraggableProps {
    id: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    data?: any;
    type: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    onDrop: (targetId: string, data?: any) => void;
}

export const makeDraggable = <P extends DraggableProps>(
    Component: ComponentType<P>,
): ComponentType<P> => {
    // eslint-disable-next-line react/display-name
    return ({ id, data = null, onDrop, type, ...props }) => {
        const [{ opacity }, drag] = useDrag({
            item: { id, data, type },
            type,
            end(item, monitor) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
                const dropResult = monitor.getDropResult() as any;
                if (item && dropResult) {
                    const isDropAllowed =
                        dropResult.allowedDropEffect === "any" ||
                        dropResult.allowedDropEffect === dropResult.dropEffect;
                    if (isDropAllowed) {
                        onDrop(dropResult.id, item.data);
                    } else {
                        throw new Error(
                            `You cannot move an item into the ${dropResult.id}`,
                        );
                    }
                }
            },
            collect: monitor => ({
                opacity: monitor.isDragging() ? 0.4 : 1,
            }),
        });
        return (
            <div ref={drag} style={{ opacity }}>
                <Component {...(props as P)} />
            </div>
        );
    };
};

interface DroppableProps {
    accept: string;
    id: string;
    children?: React.ReactNode;
}

export const DroppableWrapper: FunctionComponent<DroppableProps> = ({
    accept,
    id,
    children,
}) => {
    const [{ canDrop, isOver }, drop] = useDrop({
        accept: accept,
        drop: () => ({
            id,
            allowedDropEffect: "move",
        }),
        collect: monitor => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });

    return (
        <div
            ref={drop}
            className="droppable-wrapper"
            style={{
                opacity: isOver ? 0.6 : 1,
                filter: isOver && !canDrop ? "grayScale(100%)" : null,
            }}>
            {children}
        </div>
    );
};

interface ReorderableListDropOptions {
    type: string;
    reorder: (draggingIndex: number, targetIndex: number) => void;
    ref: MutableRefObject<HTMLLIElement>;
    index: number;
}

interface DragItem {
    index: number;
    id: string;
    type: string;
}

export const useReorderableListDrop = (options: ReorderableListDropOptions) => {
    const { ref, index, reorder, type } = options;
    return useDrop<DragItem, void, { handlerId: Identifier | null }>({
        accept: type,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item: DragItem, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            if (dragIndex === hoverIndex) {
                return;
            }
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            reorder(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });
};

interface ReorderableListDragOptions {
    type: string;
    index: number;
    id: string;
    commitOrder?: () => void;
    revertOrder?: () => void;
}

export const useReorderableListDrag = (options: ReorderableListDragOptions) => {
    const { type, index, id, commitOrder, revertOrder } = options;
    return useDrag({
        type,
        item: () => {
            return { id, index };
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
        end: (_draggedItem, monitor) => {
            const didDrop = monitor.didDrop();
            if (didDrop) {
                commitOrder && commitOrder();
            } else {
                revertOrder && revertOrder();
            }
        },
    });
};

interface ItemWithOrder {
    order?: number;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const calculateNewOrder = <T,>(
    items: ItemWithOrder[],
    draggingIndex: number,
    targetIndex: number,
) => {
    const target = items[targetIndex];
    const mult = draggingIndex < targetIndex ? 1 : -1;

    return (target.order ?? 0) + 0.1 * mult;
};
