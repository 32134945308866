import { FileId } from "@sp-crm/core";
import { getInsertableTemplates } from "components/editor/upgrade-template";
import { ReadOnlyAttachments } from "components/manage/templates/read-only-attachments";
import { TemplateAttachments } from "components/manage/templates/template-attachments";
import { Radio, RadioOption } from "components/shared/radio";
import { HtmlInput } from "components/ui/html-input";
import { InlineBanner } from "components/ui/inline-banner";
import { Input } from "components/ui/input";
import { Panel } from "components/ui/panel/panel";
import { PanelType } from "components/ui/panel/panel-type";
import { PrimaryButton } from "components/ui/primary-button";
import { SecondaryButton } from "components/ui/secondary-button";
import { AdvancedSearchEntityType, useGetTemplatesQuery } from "generated/graphql";
import React, { useEffect, useMemo, useState } from "react";
import { getSanitizedMarkUpFromEmailBody } from "util/email";
import { validateSignatureRequestBody } from "../signature-defaults";

interface EmailContent {
    subject?: string | null;
    body?: string | null;
    attachments?: FileId[] | null;
}

type EmailContentMode = "default" | "custom";

interface SignatureCustomizeEmailDialogProps {
    isOpen: boolean;
    onDismiss: () => void;
    initialCustomContent: EmailContent | null;
    defaultContent: EmailContent;
    onCommit: (content: EmailContent | null) => void;
    defaultContentLabel: string;
    defaultContentDescription: string;
    placeholderTypes?: AdvancedSearchEntityType[];
    tokenReplacements?: Record<string, string>;
    onTokenInserted?: (tokenValue: string) => boolean;
}

export const SignatureCustomizeEmailDialog: React.FC<
    SignatureCustomizeEmailDialogProps
> = props => {
    const {
        isOpen,
        onDismiss,
        initialCustomContent,
        defaultContent,
        onCommit,
        defaultContentLabel,
        defaultContentDescription,
        placeholderTypes,
        tokenReplacements,
        onTokenInserted,
    } = props;
    const [contentMode, setContentMode] = useState<EmailContentMode>(
        initialCustomContent?.body ? "custom" : "default",
    );
    const [subject, setSubject] = useState<string>(
        initialCustomContent?.subject || defaultContent.subject,
    );
    const [body, setBody] = useState<string>(
        initialCustomContent?.body || defaultContent.body,
    );
    const [attachments, setAttachments] = useState<FileId[]>(
        initialCustomContent?.attachments || defaultContent.attachments || [],
    );

    const templatesQuery = useGetTemplatesQuery();

    const radioOptions: RadioOption[] = [
        {
            text: defaultContentLabel,
            key: "default",
            helpText: defaultContentDescription,
        },
        {
            text: "Customize email content",
            key: "custom",
            helpText: "Customize the email content for this signature template",
        },
    ];

    const templates = getInsertableTemplates(
        templatesQuery.data?.getTemplates.templates || [],
    );

    const errorMessage = useMemo(() => {
        if (contentMode === "default") {
            return null;
        }

        const result = validateSignatureRequestBody(body);

        if (result.isValid) {
            return null;
        }

        return result.errorMessage;
    }, [body, contentMode]);

    useEffect(() => {
        if (isOpen) {
            setContentMode(initialCustomContent?.body ? "custom" : "default");
            setSubject(initialCustomContent?.subject || defaultContent.subject);
            setBody(initialCustomContent?.body || defaultContent.body);
            setAttachments(
                initialCustomContent?.attachments || defaultContent.attachments || [],
            );
        }
    }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Panel
            type={PanelType.large}
            headerText="Customize email content"
            isOpen={isOpen}
            onDismiss={onDismiss}>
            <div className="space-y-4">
                <Radio
                    options={radioOptions}
                    value={contentMode}
                    label=""
                    onChange={v => setContentMode(v as EmailContentMode)}
                />
                {contentMode === "default" ? (
                    <div className="space-y-4">
                        <div className="space-y-1">
                            <p className="text-sm">Subject</p>
                            <p>{defaultContent.subject}</p>
                        </div>
                        <div className="space-y-1">
                            <p className="text-sm">Body</p>
                            <div
                                className="preview-email-body-content selectable-texts"
                                dangerouslySetInnerHTML={getSanitizedMarkUpFromEmailBody(
                                    defaultContent.body,
                                )}></div>
                        </div>
                        <ReadOnlyAttachments attachmentIds={defaultContent.attachments} />
                    </div>
                ) : (
                    <div className="space-y-2">
                        <Input
                            label={<span className="text-sm">Subject</span>}
                            value={subject}
                            onChange={e => {
                                setSubject(e.target.value);
                            }}
                        />
                        <HtmlInput
                            label={<span className="text-sm">Body</span>}
                            initialContent={body}
                            onCommit={setBody}
                            templates={templates}
                            placeholderTypes={placeholderTypes}
                            tokenReplacements={tokenReplacements}
                            onTokenInserted={onTokenInserted}
                        />
                        <TemplateAttachments
                            attachmentIds={attachments}
                            onAttachmentIdsChanged={setAttachments}
                        />
                    </div>
                )}
                {errorMessage ? (
                    <InlineBanner type="warning">{errorMessage}</InlineBanner>
                ) : null}
                <div className="flex items-center space-x-2">
                    <PrimaryButton
                        disabled={!!errorMessage}
                        onClick={e => {
                            e.preventDefault();
                            contentMode === "default"
                                ? onCommit(null)
                                : onCommit({
                                      subject,
                                      body,
                                      attachments,
                                  });
                            onDismiss();
                        }}>
                        Update email content
                    </PrimaryButton>
                    <SecondaryButton
                        onClick={e => {
                            e.preventDefault();
                            onDismiss();
                        }}>
                        Cancel
                    </SecondaryButton>
                </div>
            </div>
        </Panel>
    );
};
