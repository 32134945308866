import { TertiarynavMenu, TertiarynavMenuItem } from "components/navigation/tertiarynav";
import React from "react";
import { useFeature } from "store/selectors/hooks";

export const AgencySettingsNav: React.FC<unknown> = () => {
    const isFaxEnabled = useFeature("fax");
    const workflowsEnabled = useFeature("workflows");
    return (
        <TertiarynavMenu>
            <TertiarynavMenuItem to="/settings/agency/clients">
                Clients
            </TertiarynavMenuItem>
            <TertiarynavMenuItem to="/settings/agency/communities">
                Communities
            </TertiarynavMenuItem>
            <TertiarynavMenuItem to="/settings/agency/customize/cc">
                Customize
            </TertiarynavMenuItem>
            {isFaxEnabled ? (
                <TertiarynavMenuItem to="/settings/agency/fax">Fax</TertiarynavMenuItem>
            ) : null}
            <TertiarynavMenuItem to="/settings/agency/general">
                General
            </TertiarynavMenuItem>
            <TertiarynavMenuItem to="/settings/agency/hosted-form">
                Hosted Intake Form
            </TertiarynavMenuItem>
            <TertiarynavMenuItem to="/settings/agency/invoices">
                Invoices
            </TertiarynavMenuItem>
            <TertiarynavMenuItem to="/settings/agency/referrals">
                Referrals
            </TertiarynavMenuItem>
            <TertiarynavMenuItem to="/settings/agency/signwise">
                SignWise
            </TertiarynavMenuItem>
            <TertiarynavMenuItem to="/settings/agency/tasks">Tasks</TertiarynavMenuItem>
            {workflowsEnabled ? (
                <TertiarynavMenuItem to="/settings/agency/workflow">
                    Workflows
                </TertiarynavMenuItem>
            ) : null}
        </TertiarynavMenu>
    );
};
