import { IAnswer, IQuestion, QuestionCategories } from "@sp-crm/core";
import { CrmPersona } from "components/ui/persona";
import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { ExpandIndicator } from "../../../shared/expand-indicator";
import QuestionList from "./question-list";

type ClientQuestionListProps = {
    name: string;
    questions: IQuestion[];
    category: QuestionCategories;
    answers: IAnswer[];
    onUpdateAnswer: (answer: IAnswer) => Promise<IAnswer>;
    additionalClassNames?: string;
    preJSX: JSX.Element;
    posJSX: JSX.Element;
    hasAnswers: boolean;
};

const ClientQuestionList: FunctionComponent<ClientQuestionListProps> = ({
    name,
    questions,
    category,
    answers,
    onUpdateAnswer,
    additionalClassNames,
    preJSX,
    posJSX,
    hasAnswers,
}) => {
    const [expandClientQuestions, setExpandClientQuestions] = useState(hasAnswers);
    const toggleExpandClientQuestions = useCallback(
        () => setExpandClientQuestions(!expandClientQuestions),
        [expandClientQuestions],
    );

    // without this, we sometimes are instantiated with hasAnswers == false even though
    // the section actually hasAnswers=true. This allows us to reevaluate whether the section
    // should be expanded when hasAnswers changes.
    useEffect(() => {
        if (hasAnswers) {
            setExpandClientQuestions(true);
        }
    }, [hasAnswers]);

    return (
        <div className={additionalClassNames}>
            <div
                className="flex-space-between flex-row-no-bottom-margin expandable-section"
                onClick={toggleExpandClientQuestions}>
                <CrmPersona name={name} size="small" />
                <ExpandIndicator expanded={expandClientQuestions} />
            </div>
            {expandClientQuestions && (
                <QuestionList
                    questions={questions}
                    category={category}
                    answers={answers}
                    onUpdateAnswer={onUpdateAnswer}
                    preJSX={preJSX}
                    posJSX={posJSX}
                />
            )}
        </div>
    );
};

export default ClientQuestionList;
