import {
    IAdditionalClient,
    IAnswer,
    IClient,
    IDetails,
    IQuestion,
    QuestionCategories,
    SAME_FOR_BOTH_STR,
} from "@sp-crm/core";
import { Checkbox } from "components/ui/checkbox";
import React, { PureComponent } from "react";
import ClientQuestionList from "./client-question-list";
import Header from "./header";

type MultipleClientsProps = {
    questions: IQuestion[];
    details: IDetails;
    category: QuestionCategories;
    client: IClient;
    additionalClient: IAdditionalClient;
    onUpdateAnswer: (answer: IAnswer) => Promise<IAnswer>;
    onUpdateAdditionalClientAnswer: (answer: IAnswer) => Promise<IAnswer>;
    onSameForBothClientsCheckboxChanged: (
        category: QuestionCategories,
        newValue: boolean,
    ) => void;
    preJSX: { primaryClient: JSX.Element; additionalClient: JSX.Element };
    posJSX: { primaryClient: JSX.Element; additionalClient: JSX.Element };
    primaryClientHasAnswers: boolean;
    additionalClientHasAnswers: boolean;
};

class MultipleClients extends PureComponent<MultipleClientsProps> {
    shouldShowBothClientsTogether() {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
        return (this.props.additionalClient as any)[
            `${QuestionCategories[this.props.category]}AnswersSameAsPrimaryClient`
        ];
    }

    getSameForBothClientsCheckbox() {
        return (
            <div>
                <Checkbox
                    onChange={newValue =>
                        this.props.onSameForBothClientsCheckboxChanged(
                            this.props.category,
                            newValue.target.checked,
                        )
                    }
                    checked={this.shouldShowBothClientsTogether()}
                    label={SAME_FOR_BOTH_STR}
                />
            </div>
        );
    }

    render() {
        return (
            <div className="input-form-block-no-bottom-margin">
                <div>
                    <Header
                        icon={this.props.details.icon}
                        clientHeading={this.props.details.clientHeading}>
                        {this.getSameForBothClientsCheckbox()}
                    </Header>
                </div>
                {this.shouldShowBothClientsTogether() ? (
                    <ClientQuestionList
                        name={this.props.client.formatClientAndAdditionalClientNames()}
                        questions={this.props.questions}
                        category={this.props.category}
                        answers={this.props.client.answers}
                        onUpdateAnswer={this.props.onUpdateAnswer}
                        preJSX={this.props.preJSX?.primaryClient}
                        posJSX={this.props.posJSX?.primaryClient}
                        hasAnswers={this.props.primaryClientHasAnswers}
                    />
                ) : (
                    <div className="space-y-4">
                        <ClientQuestionList
                            name={this.props.client.formatName(this.props.client.name)}
                            questions={this.props.questions}
                            category={this.props.category}
                            answers={this.props.client.answers}
                            onUpdateAnswer={this.props.onUpdateAnswer}
                            additionalClassNames="no-bottom-space"
                            preJSX={this.props.preJSX?.primaryClient}
                            posJSX={this.props.posJSX?.primaryClient}
                            hasAnswers={this.props.primaryClientHasAnswers}
                        />
                        <ClientQuestionList
                            name={this.props.client.formatName(
                                this.props.additionalClient.name,
                            )}
                            questions={this.props.questions}
                            category={this.props.category}
                            answers={this.props.additionalClient.answers}
                            onUpdateAnswer={this.props.onUpdateAdditionalClientAnswer}
                            preJSX={this.props.preJSX?.additionalClient}
                            posJSX={this.props.posJSX?.additionalClient}
                            hasAnswers={this.props.additionalClientHasAnswers}
                        />
                    </div>
                )}
            </div>
        );
    }
}

export default MultipleClients;
