import * as Common from "@sp-crm/core";
import { ClientId, Maybe } from "@sp-crm/core";
import { defaultLinkStyle } from "components/ui/link";
import { SelectPro } from "components/ui/select-pro";
import * as React from "react";
import { connect } from "react-redux";
import { Action, Dispatch } from "redux";
import { ApplicationState } from "store/state";
import { updateClient } from "../../../store/actions";
import {
    ActionBarComponentRenderer,
    IActionBarComponent,
} from "./../../shared/legacy-action-bar-component-renderer";
import { Subpage } from "./index";

interface ActionBarMoveCommunityOwnProps {
    className?: string;
    client: Common.IClient;
    communities: Common.ICommunity[];
    goToTab?: (section: Subpage) => void;
}

type ActionBarMoveCommunityPropsFromState = ActionBarMoveCommunityOwnProps;

interface ActionBarMoveCommunityPropsFromDispatch {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    onFieldChange: (fieldName: string, newValue: any) => void;
}

type ActionBarMoveCommunityProps = ActionBarMoveCommunityPropsFromDispatch &
    ActionBarMoveCommunityPropsFromState;

class ActionBarMoveCommunity
    extends React.Component<ActionBarMoveCommunityProps>
    implements IActionBarComponent
{
    private forceDismiss: () => void = null;

    public constructor(p: ActionBarMoveCommunityProps) {
        super(p);
        this.state = {
            show: false,
        };
    }

    displayLabel(): string {
        return "Community";
    }

    displayValue(): string {
        let communityName = ActionBarComponentRenderer.defaultUnspecifiedValue;
        const communityValue = this.props.client.chosenCommunityId ?? "";
        this.props.communities.some(x => {
            if (x.id == communityValue) {
                communityName = x.name || "(no name)";
                return true;
            }
            return false;
        });
        return communityName;
    }

    onCommitChange(newVal: string) {
        this.props.onFieldChange("chosenCommunityId", newVal.length > 0 ? newVal : null);

        if (this.forceDismiss) {
            this.forceDismiss();
        }
    }

    goToFindIdealCommunities(e: MouseEvent) {
        e.preventDefault();

        if (this.forceDismiss) {
            this.forceDismiss();
        }

        this.props.goToTab("communities");
    }

    editableContent(): JSX.Element {
        const communities = this.props.communities || [];
        const communityOptions = [].concat(
            communities.map(x => ({
                value: x.id,
                text: x.name || "(no name)",
            })),
        );

        const communityValue = this.props.client.chosenCommunityId
            ? this.props.client.chosenCommunityId
            : "";

        return (
            <div>
                <SelectPro
                    className="placed-community-dropdown"
                    includePlaceholderOption
                    placeholderStr={ActionBarComponentRenderer.defaultUnspecifiedValue}
                    label="Community"
                    value={communityValue}
                    onChange={e => this.onCommitChange(e.target.value)}
                    options={communityOptions}
                />
                <p className="max-width-callout-text">
                    Note: if the community you&apos;re looking for is not shown in the
                    dropdown above, please&nbsp;
                    <a
                        className={defaultLinkStyle}
                        href="#"
                        onClick={this.goToFindIdealCommunities.bind(this)}>
                        Find Ideal Communities
                    </a>
                    &nbsp;first, and add the relevant community as potential match.
                </p>
            </div>
        );
    }

    public render() {
        return (
            <ActionBarComponentRenderer
                className={this.props.className}
                actionBarObject={this}
                setForceDismiss={forceDismiss => (this.forceDismiss = forceDismiss)}
            />
        );
    }
}

function mapStateToProps(
    state: ApplicationState,
    ownProps: ActionBarMoveCommunityOwnProps,
): ActionBarMoveCommunityPropsFromState {
    return {
        ...ownProps,
    };
}

function mapDispatchToProps(
    dispatch: Dispatch<Action>,
    ownProps: ActionBarMoveCommunityOwnProps,
): ActionBarMoveCommunityPropsFromDispatch {
    const clientId: ClientId = Maybe.fromValue(ownProps)
        .map(p => p.client)
        .map(c => c.id)
        .getOrElse(null);
    const val = {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
        onFieldChange: (fieldName: string, newValue: any) => {
            if (clientId) {
                updateClient(clientId, fieldName, newValue, dispatch);
            }
        },
    };
    return val;
}
const component = connect(mapStateToProps, mapDispatchToProps)(ActionBarMoveCommunity);
export { component as ActionBarMoveCommunity };
