import { formatValue } from "@sp-crm/core";
import { Checkbox } from "components/ui/checkbox";
import * as React from "react";
import { SliderSegment } from "../../shared/slider-segment";
import { ISearchFilterClosable } from "./search-filter-closable.interface";
import { SearchFilterProps } from "./search-filter-props.interface";
import { ISearchPrice } from "./util";

interface SearchPriceProps {
    price: ISearchPrice;
    values: ISearchPrice;
}

interface SearchPriceState {
    isValid: boolean;
    values: ISearchPrice;
    includePriceUndefined: boolean;
}

type SearchPriceFilterProps = SearchPriceProps & SearchFilterProps;
const SearchPricePreview = (props: { min: number; max: number; maxValue: number }) => {
    return (
        <div>
            {formatValue(props.min, props.maxValue)} -{" "}
            {formatValue(props.max, props.maxValue)}
        </div>
    );
};
export default SearchPricePreview;

export class SearchPrice
    extends React.Component<SearchPriceFilterProps, SearchPriceState>
    implements ISearchFilterClosable
{
    constructor(props: SearchPriceFilterProps) {
        super(props);
        this.state = {
            values: { min: props.values.min, max: props.values.max },
            includePriceUndefined:
                props.values.includePriceUndefined !== undefined
                    ? props.values.includePriceUndefined
                    : true,
            isValid: false,
        };

        this.onClose = this.onClose.bind(this);
        this.onValid = this.onValid.bind(this);
        this.onValueChange = this.onValueChange.bind(this);
        this.onCheckboxChanged = this.onCheckboxChanged.bind(this);
    }

    UNSAFE_componentWillMount() {
        this.onValid();
    }

    componentWillUnmount() {
        this.onClose();
    }

    onCheckboxChanged(ev: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ ...this.state, includePriceUndefined: ev.target.checked });
    }

    onClose() {
        this.props.onClose({
            activeState: {
                price: {
                    min: isNaN(this.state.values.min)
                        ? this.props.price.min
                        : this.state.values.min,
                    max: isNaN(this.state.values.max)
                        ? this.props.price.max
                        : this.state.values.max,
                    includePriceUndefined: this.state.includePriceUndefined,
                },
            },
            defaultState: {
                price: {
                    min: this.props.price.min,
                    max: this.props.price.max,
                    includePriceUndefined: true,
                },
            },
        });
    }

    onValid() {
        return this.props.onValid(true);
    }

    onValueChange([min, max]: [number, number]) {
        this.setState({ ...this.state, values: { min, max } });
    }

    render() {
        return (
            <div className="search-price-component">
                <SliderSegment
                    min={this.props.price.min}
                    max={this.props.price.max}
                    values={this.state.values}
                    onChange={this.onValueChange}
                />
                <div className="price-checkbox">
                    <Checkbox
                        label="Include communities without price details"
                        checked={this.state.includePriceUndefined}
                        onChange={this.onCheckboxChanged}
                    />
                </div>
            </div>
        );
    }
}
