import { SupportEmailLink } from "components/app/support-email-link";
import { InlineBanner } from "components/ui/inline-banner";
import React from "react";
import { useProductName } from "store/selectors/branding";
import { useFeature } from "store/selectors/hooks";

export const CustomRolesBanner: React.FC<unknown> = () => {
    const customRolesEnabled = useFeature("customRoles");
    const productName = useProductName();

    return customRolesEnabled ? (
        <InlineBanner type="info">
            To configure custom permissions, contact <SupportEmailLink /> to discuss your
            needs and set up the account.
        </InlineBanner>
    ) : (
        <InlineBanner type="info">
            Custom permissions are available to {productName} Premium customers. Contact{" "}
            <SupportEmailLink /> to discuss an upgrade to Premium.
        </InlineBanner>
    );
};
