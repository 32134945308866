import { FileId, globalFilesEntityId } from "@sp-crm/core";
import { QueryRenderer } from "components/clients/show-client/community-comparison/query-renderer";
import { Select } from "components/ui/select";
import { GetFilesQuery, useGetFilesQuery } from "generated/graphql";
import React, { useCallback } from "react";

export type SelectableFile = GetFilesQuery["getFiles"]["files"][0];

interface FileSelectProps {
    label: string;
    description: string;
    value: FileId | null;
    fileFilter?: (file: SelectableFile) => boolean;
    onChange: (value: FileId | null) => void;
}

const noFileSentinel = "_";

export const FileSelect: React.FC<FileSelectProps> = props => {
    const { label, onChange, value, description, fileFilter } = props;

    const query = useGetFilesQuery({ entityId: globalFilesEntityId });
    const handleOnChange = useCallback(
        (e: React.ChangeEvent<HTMLSelectElement>) => {
            if (e.target.value === noFileSentinel) {
                onChange(null);
            } else {
                onChange(e.target.value as FileId);
            }
        },
        [onChange],
    );

    return (
        <QueryRenderer query={query} name="FileSelect.getFiles">
            {data => (
                <div className="space-y-2 mt-4 group">
                    <Select
                        label={label}
                        onChange={handleOnChange}
                        value={value || noFileSentinel}>
                        <option value={noFileSentinel}>Select file</option>
                        {data.getFiles.files
                            .filter(f => !fileFilter || fileFilter(f))
                            .map(file => (
                                <option key={file.id} value={file.id}>
                                    {file.originalFilename}
                                </option>
                            ))}
                    </Select>
                    <p className="text-gray-500 group-hover:text-gray-900">
                        {description}
                    </p>
                </div>
            )}
        </QueryRenderer>
    );
};
