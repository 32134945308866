import React from "react";

interface PillProps {
    children: React.ReactNode;
}

export const Pill: React.FC<PillProps> = props => {
    return (
        <span className="inline-block flex-shrink-0 rounded-full border text-gray-500 px-3 py-0.5 text-xs font-medium">
            {props.children}
        </span>
    );
};
