import React from "react";
import {
    ClientStatusTriggerEditor,
    hasClientStatusTrigger,
} from "./client-status-trigger-editor";
import {
    EmailOpenedTriggerEditor,
    hasEmailOpenedTrigger,
} from "./email-opened-trigger-editor";
import {
    InactiveClientTriggerEditor,
    hasInactiveClientTrigger,
} from "./inactive-client-trigger-editor";
import { EditableWorkflow } from "./types";

interface WorkflowTriggerEditorProps {
    workflow: EditableWorkflow;
    onChange: (workflow: EditableWorkflow) => void;
}

export const WorkflowTriggerEditor: React.FC<WorkflowTriggerEditorProps> = props => {
    const { workflow, onChange } = props;

    if (hasClientStatusTrigger(workflow)) {
        return <ClientStatusTriggerEditor workflow={workflow} onChange={onChange} />;
    } else if (hasEmailOpenedTrigger(workflow)) {
        return <EmailOpenedTriggerEditor workflow={workflow} onChange={onChange} />;
    } else if (hasInactiveClientTrigger(workflow)) {
        return <InactiveClientTriggerEditor workflow={workflow} onChange={onChange} />;
    } else {
        return null;
    }
};
