import { Select } from "components/ui/select";
import { useGetTaskTypesQuery } from "generated/graphql";
import React, { useMemo } from "react";
import {
    FilterableTaskTypes,
    allTaskTypesSentinel,
    unsetTaskTypeSentinel,
} from "store/slices/tasks-dashboard";
import { stableQueryOptions } from "util/requests";

interface TaskTypeSelectProps {
    value: FilterableTaskTypes;
    onChange: (value: FilterableTaskTypes) => void;
    allowAllTaskTypes?: boolean;
    allowUnsetTaskType?: boolean;
    unsetLabel?: string;
}

export const TaskTypeSelect: React.FC<TaskTypeSelectProps> = props => {
    const { value, onChange, allowAllTaskTypes, unsetLabel, allowUnsetTaskType } = props;

    const taskTypesQuery = useGetTaskTypesQuery({}, stableQueryOptions());

    const taskTypesOptions = useMemo(() => {
        return taskTypesQuery.isSuccess
            ? taskTypesQuery.data?.getTaskTypes
                  .filter(x => !x.isArchived || x.id === value)
                  .sort((a, b) =>
                      a.name
                          .toLocaleLowerCase()
                          .localeCompare(b.name.toLocaleLowerCase()),
                  )
                  .map(x => (
                      <option key={x.id} value={x.id}>
                          {x.name ?? "(unnamed)"}
                      </option>
                  ))
            : [];
    }, [taskTypesQuery.isSuccess, taskTypesQuery.data?.getTaskTypes, value]);

    return taskTypesQuery.isSuccess ? (
        <Select
            label="Type"
            value={value}
            onChange={e => onChange(e.target.value as FilterableTaskTypes)}>
            {allowAllTaskTypes ? (
                <option value={allTaskTypesSentinel}>All Task Types</option>
            ) : null}
            {taskTypesOptions}
            {allowUnsetTaskType ? (
                <option value={unsetTaskTypeSentinel}>
                    {unsetLabel ?? "(Tasks without type set)"}
                </option>
            ) : null}
        </Select>
    ) : (
        <Select label="Type" disabled value="loading">
            <option value="loading">Loading...</option>
        </Select>
    );
};
