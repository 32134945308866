import { ITask, TaskParentId } from "@sp-crm/core";
import React from "react";
import { useEntityTasks } from "store/selectors/tasks";

interface EntityTasksShimProps {
    parentId: TaskParentId;
    children: (result: {
        tasks: ITask[];
        refetchTasks: () => void;
        tasksLoaded: boolean;
    }) => React.ReactNode;
}

export const EntityTasksShim: React.FC<EntityTasksShimProps> = props => {
    const { parentId, children } = props;

    const result = useEntityTasks(parentId);

    return <>{children(result)}</>;
};
