import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { LayoutIcon, layoutIcons } from "@sp-crm/core";
import { Icon } from "components/icon";

import React from "react";

interface IconPickerProps {
    icon: LayoutIcon;
    onChange: (icon: LayoutIcon) => void;
}

export const IconPicker: React.FC<IconPickerProps> = props => {
    const { icon, onChange } = props;

    return (
        <Popover as="div" className="relative">
            <PopoverButton className="hover:bg-gray-50">
                <div className="flex items-center space-x-1">
                    <div className="w-6">
                        <Icon name={icon} />
                    </div>
                    <ChevronDownIcon className="w-4 h-4" />
                </div>
            </PopoverButton>
            <PopoverPanel className="origin-top-right absolute left-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none min-w-40 z-10">
                {({ close }) => (
                    <div className="grid grid-cols-8 gap-2 w-80">
                        {layoutIcons.map(icon => (
                            <button
                                key={icon}
                                className="flex items-center justify-center w-8 h-8 rounded-full bg-gray-100 hover:bg-gray-200"
                                onClick={() => {
                                    onChange(icon);
                                    close();
                                }}>
                                <div className="w-6">
                                    <Icon name={icon} />
                                </div>
                            </button>
                        ))}
                    </div>
                )}
            </PopoverPanel>
        </Popover>
    );
};
