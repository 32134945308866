import { EmailTemplateId, SentMessageType } from "@sp-crm/core";
import { QueryRenderer } from "components/clients/show-client/community-comparison/query-renderer";
import { InlineBanner } from "components/ui/inline-banner";
import React from "react";
import { useGetTemplatesQuery, useValidateTemplateQuery } from "../../generated/graphql";
import { Select } from "../ui/select";

const noTemplateSentinel = "_";

interface TemplateSelectProps {
    onChange: (templateId: EmailTemplateId | null) => void;
    selectedTemplateId: EmailTemplateId | null;
    label: string;
    sharedOnly?: boolean;
    messageType?: SentMessageType;
}

export const TemplateSelect: React.FC<TemplateSelectProps> = props => {
    const { onChange, selectedTemplateId, label, sharedOnly, messageType } = props;
    const selectedValue = selectedTemplateId ?? noTemplateSentinel;
    const handleOnChange = React.useCallback(
        (e: React.ChangeEvent<HTMLSelectElement>): void => {
            const value = e.target.value;
            if (value && value !== noTemplateSentinel)
                onChange(e.target.value as EmailTemplateId);
            else onChange(null);
        },
        [onChange],
    );
    const templatesQuery = useGetTemplatesQuery();
    const isValidationEnabled = !!(
        selectedTemplateId &&
        selectedTemplateId !== noTemplateSentinel &&
        messageType
    );
    const validateTemplateQuery = useValidateTemplateQuery(
        {
            emailTemplateId: selectedTemplateId,
            messageType: messageType,
        },
        { enabled: isValidationEnabled },
    );
    return (
        <div>
            <QueryRenderer name="TemplateSelect" query={templatesQuery}>
                {data => {
                    const options: { id: string; description: string }[] = [];
                    templatesQuery.data.getTemplates.templates.forEach(t => {
                        if (
                            t.template.type === "insertable" &&
                            (!sharedOnly || t.template.isShared)
                        ) {
                            options.push({
                                id: t.template.id,
                                description: t.template.description ?? "Untitled",
                            });
                        }
                    });
                    return (
                        <Select
                            onChange={handleOnChange}
                            value={selectedValue}
                            label={label}>
                            <option value={noTemplateSentinel}>Select template</option>
                            {options.map(t => (
                                <option key={t.id} value={t.id}>
                                    {t.description}
                                </option>
                            ))}
                        </Select>
                    );
                }}
            </QueryRenderer>
            {isValidationEnabled ? (
                <QueryRenderer
                    name="TemplateSelectValidation"
                    query={validateTemplateQuery}>
                    {data =>
                        data.validateTemplate.isValid ? null : (
                            <InlineBanner type="warning" fontStyle="normal">
                                {data.validateTemplate.message ? (
                                    <div>{data.validateTemplate.message}</div>
                                ) : null}
                                {data.validateTemplate.missingTokens.length > 0 ? (
                                    <ul>
                                        {data.validateTemplate.missingTokens.map(t => (
                                            <li key={t.tokenKey}>
                                                <strong>{t.humanFriendlyName}</strong>
                                                <br />
                                                Email Template should include{" "}
                                                <code>{`{{${t.tokenKey}}}`}</code>{" "}
                                                <em>({t.description})</em>
                                            </li>
                                        ))}
                                    </ul>
                                ) : null}
                            </InlineBanner>
                        )
                    }
                </QueryRenderer>
            ) : null}
        </div>
    );
};
