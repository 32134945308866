import { ChatBubbleLeftEllipsisIcon, TrashIcon } from "@heroicons/react/20/solid";
import React from "react";
import { breakNewlines } from "util/text";
import { EntityActivitySummary } from "./details/entity-activity-summary";
import { EntityActivityEntryProps } from "./types";

export const EntityActivityEntryNoteAdd: React.FC<EntityActivityEntryProps> = props => {
    const { activity } = props;
    return (
        <div className="relative flex items-start space-x-3">
            <div className="relative px-1">
                <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white">
                    <ChatBubbleLeftEllipsisIcon
                        className="h-5 w-5 text-gray-500"
                        aria-hidden="true"
                    />
                </div>
            </div>
            <div className="min-w-0 flex-1 group pt-1">
                <EntityActivitySummary {...props}>added a note.</EntityActivitySummary>
                {activity.note ? (
                    <div className="mt-2 text-base text-gray-900">
                        <p>{breakNewlines(activity.note.text)}</p>
                    </div>
                ) : (
                    <div className="mt-2 text-xs text-gray-500 italic">Note deleted</div>
                )}
            </div>
        </div>
    );
};

export const EntityActivityEntryNoteDelete: React.FC<
    EntityActivityEntryProps
> = props => {
    return (
        <div className="relative flex items-start space-x-3">
            <div className="relative px-1">
                <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white">
                    <TrashIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                </div>
            </div>
            <div className="min-w-0 flex-1 group pt-1">
                <EntityActivitySummary {...props}>deleted a note.</EntityActivitySummary>
            </div>
        </div>
    );
};
