import React from "react";
import { QueryClient, useQueryClient } from "react-query";

interface QueryClientShimProps {
    children: (queryClient: QueryClient) => React.ReactNode;
}

export const QueryClientShim: React.FC<QueryClientShimProps> = props => {
    const { children } = props;

    const queryClient = useQueryClient();

    return <>{children(queryClient)}</>;
};
