import React, { FunctionComponent } from "react";
import { Header } from "../../../header";

type ClientQuestionsHeaderProps = {
    icon: string;
    clientHeading: string;
    children?: React.ReactNode;
};

const ClientQuestionsHeader: FunctionComponent<ClientQuestionsHeaderProps> = ({
    icon,
    clientHeading,
    children,
}) => (
    <div className="flex-row-no-bottom-margin flex-space-between">
        <div className="header">
            <Header iconName={icon}>{clientHeading}</Header>
        </div>
        {children}
    </div>
);

export default ClientQuestionsHeader;
