import React from "react";
import { EmailActionEditor, hasSingleEmailAction } from "./email-action-editor";
import { hasSingleTaskAction, TaskActionEditor } from "./task-action-editor";
import { EditableWorkflow } from "./types";

interface WorkflowActionEditorProps {
    workflow: EditableWorkflow;
    onChange: (workflow: EditableWorkflow) => void;
}

export const WorkflowActionEditor: React.FC<WorkflowActionEditorProps> = props => {
    const { workflow, onChange } = props;

    if (hasSingleTaskAction(workflow)) {
        return <TaskActionEditor workflow={workflow} onChange={onChange} />;
    } else if (hasSingleEmailAction(workflow)) {
        return <EmailActionEditor workflow={workflow} onChange={onChange} />;
    }

    return null;
};
