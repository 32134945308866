import { Maybe } from "@sp-crm/core";
import * as React from "react";
import { Col, Row } from "../shared/grid";
import * as ReportConstants from "./constants";
import { ReportsNavigation } from "./navigation";

import { ReportsShow } from "./show";

interface ReportsProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    children: any;
    match?: {
        params?: {
            reportname: string;
        };
    };
}

// eslint-disable-next-line @typescript-eslint/ban-types -- eslintintroduction
export class Reports extends React.Component<ReportsProps, {}> {
    currentReport(): ReportConstants.ReportNames {
        return Maybe.fromValue(this.props)
            .map(p => p.match)
            .map(m => m.params)
            .map(p => p.reportname)
            .getOrElse("summary") as ReportConstants.ReportNames;
    }

    render() {
        const currentReport = this.currentReport();
        return (
            <Row type="flex">
                <Col className="component-wrapper">
                    <Row className="multi-page-nav">
                        <Col>
                            <ReportsNavigation />
                        </Col>
                    </Row>
                    <ReportsShow reportName={currentReport} />
                </Col>
            </Row>
        );
    }
}
