import {
    CurrencyInCents,
    currencyInCentsToString,
    formatCurrency,
    formatDateNoTime,
} from "@sp-crm/core";
import { SearchInputDebounced } from "components/ui/search-input-debounced";
import { Spinner } from "components/ui/spinner";
import {
    SearchExternalInvoicesQuery,
    useSearchExternalInvoicesQuery,
} from "generated/graphql";
import React, { useMemo } from "react";

export type ExternalInvoiceSearchHit =
    SearchExternalInvoicesQuery["searchExternalInvoices"]["hits"][number];

interface ExternalInvoiceSearchProps {
    onResults: (invoices: ExternalInvoiceSearchHit[]) => void;
}

export const ExternalInvoiceSearch: React.FC<ExternalInvoiceSearchProps> = props => {
    const { onResults } = props;
    const [invoiceNumberSearch, setInvoiceNumberSearch] = React.useState<string>("");

    const serialSearchTerm = useMemo(() => {
        return invoiceNumberSearch.trim();
    }, [invoiceNumberSearch]);

    const searchExternalInvoices = useSearchExternalInvoicesQuery(
        { params: { serial: serialSearchTerm } },
        {
            enabled: !!serialSearchTerm,
            onSuccess: data => {
                if (data?.searchExternalInvoices) {
                    onResults(data.searchExternalInvoices.hits);
                }
            },
        },
    );

    const invoiceHits = useMemo(() => {
        return searchExternalInvoices.data?.searchExternalInvoices?.hits || [];
    }, [searchExternalInvoices.data]);

    return (
        <div className="space-y-2">
            <label>
                QuickBooks Invoice Number
                <SearchInputDebounced
                    className="mt-1"
                    initial={invoiceNumberSearch}
                    onChange={setInvoiceNumberSearch}
                />
            </label>
            <div>
                {searchExternalInvoices.isLoading ? (
                    <Spinner />
                ) : invoiceHits.length > 0 ? (
                    <>
                        {invoiceHits.length > 1 ? (
                            <p>{`Found ${invoiceHits.length} invoices`}</p>
                        ) : null}
                        <table className="w-full">
                            <thead>
                                <tr>
                                    <th className="text-left">Date</th>
                                    <th className="text-left">Number</th>
                                    <th className="text-left">Customer</th>
                                    <th className="text-left">Due Date</th>
                                    <th className="text-left">Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {invoiceHits.map(invoice => (
                                    <tr key={invoice.externalId}>
                                        <td>{formatDateNoTime(invoice.invoiceDate)}</td>
                                        <td>{invoice.serial}</td>
                                        <td>{invoice.customer}</td>
                                        <td>{formatDateNoTime(invoice.dueDate)}</td>
                                        <td>
                                            {formatCurrency(
                                                currencyInCentsToString(
                                                    invoice.amountInCents as CurrencyInCents,
                                                ),
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </>
                ) : serialSearchTerm ? (
                    <p>{`No invoices match invoice number "${serialSearchTerm}"`}</p>
                ) : null}
            </div>
        </div>
    );
};
