import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import {
    IQuestion,
    Question,
    QuestionCategories,
    findRelatedField,
    questionMetadata,
} from "@sp-crm/core";
import { FIELD_PICKER_TYPE_SEPARATOR } from "components/reports/show/custom-reports/custom-reports-helpers";
import { secondaryClasses } from "components/ui/secondary-button";
import { BridgeEntityResult } from "generated/graphql";
import React, { useMemo } from "react";
import { EntitySearchableFieldList } from "./entity-searchable-field-list";
import { EntityConditionFieldPicker } from "./types";

interface EntityFieldPickerProps {
    entityMetadata: BridgeEntityResult;
    entityMetadataList: BridgeEntityResult[];
    value: EntityConditionFieldPicker;
    onChange(value: EntityConditionFieldPicker): void;
    exclude?: EntityConditionFieldPicker[];
    label?: string;
    textSize?: "sm" | "base";
    includeSummaryFields?: boolean;
    allowEmpty?: boolean;
    relationMode?: "collapsed" | "expanded";
    disabled?: boolean;
}

interface FieldPickerField {
    key: string;
    value: string;
    title: string;
    categoryLabel: string;
}

export const EntityFieldPicker: React.FC<EntityFieldPickerProps> = props => {
    const {
        entityMetadata,
        value,
        onChange,
        exclude,
        includeSummaryFields,
        allowEmpty,
        entityMetadataList,
        disabled,
    } = props;

    const relationMode = props.relationMode ?? "collapsed";

    const questionSource = useMemo(
        () => entityMetadata.questions.map(q => Question.load(q)),
        [entityMetadata],
    );
    const [searchText, setSearchText] = React.useState<string>("");

    const fieldGroups = useMemo((): Map<string, FieldPickerField[]> => {
        let normalizedFields: FieldPickerField[] = [];
        const standardFields = entityMetadata.fields;

        const entityQuestions: IQuestion[] = questionSource;

        const relationFields: FieldPickerField[] = [];

        entityMetadata.relations.forEach(relation => {
            if (
                relation.name === value?.relation?.name ||
                !exclude?.some(e => e.relation?.name === relation.name)
            ) {
                switch (relationMode) {
                    case "collapsed":
                        relationFields.push({
                            key: relation.name,
                            value: `relation${FIELD_PICKER_TYPE_SEPARATOR}${relation.name}`,
                            title: relation.title,
                            categoryLabel: "Related Records",
                        });
                        break;
                    case "expanded": {
                        const relatedEntityMetadata = entityMetadataList.find(
                            e => e.name === relation.otherEntityName,
                        );
                        if (relatedEntityMetadata) {
                            relatedEntityMetadata.fields.forEach(f => {
                                const relationWithField = `${relation.name}.${f.name}`;
                                const relatedField = findRelatedField(
                                    relationWithField,
                                    entityMetadata,
                                    entityMetadataList,
                                );
                                if (
                                    relatedField &&
                                    (relationWithField === value?.fieldName ||
                                        !exclude?.some(
                                            e => e.fieldName === relationWithField,
                                        ))
                                ) {
                                    relationFields.push({
                                        key: relationWithField,
                                        value: `field${FIELD_PICKER_TYPE_SEPARATOR}${relationWithField}`,
                                        title: `${relatedField.title}`,
                                        categoryLabel: relation.title,
                                    });
                                }
                            });
                        }
                        break;
                    }
                    default: {
                        const _exhaustiveCheck: never = relationMode;
                        throw new Error(`Unhandled relation mode: ${_exhaustiveCheck}`);
                    }
                }
            }
        });

        const summaryFields: FieldPickerField[] = [];

        if (includeSummaryFields) {
            summaryFields.push({
                categoryLabel: "Summary Fields",
                key: "*",
                title: "Total count",
                value: `field${FIELD_PICKER_TYPE_SEPARATOR}*`,
            });
        }

        normalizedFields = summaryFields
            .concat(
                standardFields
                    .filter(
                        f =>
                            f.name === value?.fieldName ||
                            !exclude?.some(e => e.fieldName === f.name),
                    )
                    .map(f => ({
                        key: f.name as string,
                        value: `field${FIELD_PICKER_TYPE_SEPARATOR}${f.name}`,
                        title: f.title,
                        categoryLabel: "Standard Fields",
                    })),
            )
            .concat(relationFields)
            .concat(
                entityQuestions
                    .filter(
                        q =>
                            q.id === value?.questionId ||
                            !exclude?.some(e => e.questionId === q.id),
                    )
                    .map(q => ({
                        key: q.id as string,
                        value: `questionId${FIELD_PICKER_TYPE_SEPARATOR}${q.id}`,
                        title: q.title,
                        categoryLabel:
                            entityMetadata.name === "Client"
                                ? questionMetadata[q.category as QuestionCategories]
                                      .clientHeading
                                : entityMetadata.name === "Community"
                                ? questionMetadata[q.category as QuestionCategories]
                                      .communityHeading
                                : "Custom Fields",
                    }))
                    .sort((a, b) => {
                        if (a.categoryLabel === b.categoryLabel) {
                            return a.title
                                .toLocaleLowerCase()
                                .localeCompare(b.title.toLocaleLowerCase());
                        }

                        return a.categoryLabel
                            .toLocaleLowerCase()
                            .localeCompare(b.categoryLabel.toLocaleLowerCase());
                    }),
            )
            .filter(
                f =>
                    !searchText.trim() ||
                    f.title
                        .toLocaleLowerCase()
                        .includes(searchText.trim().toLocaleLowerCase()) ||
                    f.categoryLabel
                        .toLocaleLowerCase()
                        .includes(searchText.trim().toLocaleLowerCase()),
            );

        return normalizedFields.reduce((acc, curr) => {
            const fields = acc.get(curr.categoryLabel) || [];
            fields.push(curr);
            acc.set(curr.categoryLabel, fields);

            return acc;
        }, new Map<string, FieldPickerField[]>());
    }, [
        entityMetadata,
        exclude,
        value,
        searchText,
        includeSummaryFields,
        relationMode,
        entityMetadataList,
        questionSource,
    ]);

    const selectedField = useMemo(() => {
        if (!value) {
            return null;
        }

        const selectedFieldValue = value.fieldName
            ? `field${FIELD_PICKER_TYPE_SEPARATOR}${value.fieldName}`
            : value.questionId
            ? `questionId${FIELD_PICKER_TYPE_SEPARATOR}${value.questionId}`
            : value.relation
            ? `relation${FIELD_PICKER_TYPE_SEPARATOR}${value.relation.name}`
            : null;

        if (selectedFieldValue) {
            for (const fields of Array.from(fieldGroups.values())) {
                const selectedField = fields.find(f => f.value === selectedFieldValue);
                if (selectedField) {
                    return selectedField;
                }
            }
        }

        return null;
    }, [value, fieldGroups]);

    const textSizeClass = props.textSize === "sm" ? "text-sm" : "";

    return (
        <div>
            <Popover as="div" className="relative inline-block text-left w-full">
                <PopoverButton
                    className={`w-full ${secondaryClasses}`}
                    disabled={disabled}>
                    <div className="flex items-center justify-between w-full">
                        <p className={textSizeClass}>
                            {selectedField ? selectedField.title || "" : "-- None --"}
                        </p>
                        <ChevronDownIcon className="w-5 h-5" />
                    </div>
                </PopoverButton>
                <PopoverPanel className="origin-top-right absolute left-0 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none z-10">
                    {({ close }) => (
                        <EntitySearchableFieldList
                            fieldGroups={fieldGroups}
                            onSelected={field => {
                                close();
                                setSearchText("");
                                onChange(field);
                            }}
                            searchText={searchText}
                            onSearchTextChange={setSearchText}
                            allowEmpty={allowEmpty}
                        />
                    )}
                </PopoverPanel>
            </Popover>
        </div>
    );
};
