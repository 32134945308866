import { IDetails, QuestionCategories, questionMetadata } from "@sp-crm/core";
import { SelectPro } from "components/ui/select-pro";
import { SelectProOption } from "helpers/select-defs";
import * as React from "react";
import { categoryLabel } from "./label-helper";

const customizableQuestionKeys = Object.keys(questionMetadata).filter(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    k => ((questionMetadata as any)[k] as IDetails).allowsCustomSettings,
);

const categoryOptions: SelectProOption[] = customizableQuestionKeys.map(k => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    const instance = (questionMetadata as any)[k] as IDetails;
    const text = categoryLabel(instance);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    return { value: (QuestionCategories as any)[k], text };
});

interface Props {
    category: QuestionCategories;
    updateCategory: (v: QuestionCategories) => void;
}
export const EditCategory: React.FunctionComponent<Props> = props => {
    return (
        <SelectPro
            options={categoryOptions}
            includePlaceholderOption={false}
            value={QuestionCategories[props.category]}
            label="Section Where Question Will Show"
            onChange={e =>
                // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
                props.updateCategory((QuestionCategories as any)[e.target.value])
            }
        />
    );
};
