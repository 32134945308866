import { QuestionType, getEntityField } from "@sp-crm/core";
import { Radio, RadioOption } from "components/shared/radio";
import { DeleteButton } from "components/ui/action-button";
import { SecondaryButton } from "components/ui/secondary-button";
import { Select } from "components/ui/select";
import {
    AdvancedSearchAggregate,
    AdvancedSearchAggregateOperator,
    AdvancedSearchRequestSummary,
    BridgeEntityResult,
    BridgeRelationKind,
} from "generated/graphql";
import { produce } from "immer";
import React, { useEffect, useMemo, useState } from "react";
import { EntityFieldPicker } from "./entity-field-picker";
import { EntityConditionFieldPicker } from "./types";

type ResultsFormat = "list" | "summaryWithList";

interface SummaryEditorProps {
    entityMetadata: BridgeEntityResult;
    entities: BridgeEntityResult[];
    summaries?: AdvancedSearchRequestSummary[] | null;
    onChange: (summaries: AdvancedSearchRequestSummary[] | null) => void;
}

export const SummaryEditor: React.FC<SummaryEditorProps> = props => {
    const { summaries, onChange, entityMetadata, entities } = props;

    const [summariesInProgress, setSummariesInProgress] = useState<
        AdvancedSearchRequestSummary[] | null
    >(summaries);
    const [resultsFormat, setResultsFormat] = useState<ResultsFormat>(
        summaries ? "summaryWithList" : "list",
    );

    useEffect(() => {
        setSummariesInProgress(summaries);
        setResultsFormat(summaries ? "summaryWithList" : "list");
    }, [entityMetadata.name]); // eslint-disable-line react-hooks/exhaustive-deps

    const reportOptions: RadioOption[] = [
        {
            key: "list",
            text: "List",
            helpText: "Display results as a list of records",
        },
        {
            key: "summaryWithList",
            text: "Summary + List",
            helpText:
                "Display results first in a summarized format (like a pivot table), then as a list of records",
        },
    ];

    return (
        <div>
            <Radio
                label="Results format"
                options={reportOptions}
                value={resultsFormat}
                onChange={(f: ResultsFormat) => {
                    setResultsFormat(f);
                    if (f === "list") {
                        onChange(null);
                    } else {
                        const newSummaries = summariesInProgress || [
                            {
                                aggregates: [
                                    {
                                        operator: AdvancedSearchAggregateOperator.Count,
                                        column: "*",
                                    },
                                ],
                                groupBy: entityMetadata.preferredGroupBy ?? null,
                                sort: {
                                    aggregateIndex: 0,
                                    ascending: false,
                                },
                                page: 0,
                                pageSize: 25,
                            },
                        ];
                        setSummariesInProgress(newSummaries);
                        onChange(newSummaries);
                    }
                }}
            />
            {resultsFormat === "summaryWithList" ? (
                <SummariesEditor
                    summaries={summariesInProgress}
                    onChange={s => {
                        setSummariesInProgress(s);
                        onChange(s);
                    }}
                    entityMetadata={entityMetadata}
                    entityMetadataList={entities}
                />
            ) : null}
        </div>
    );
};

interface SummariesEditorProps {
    summaries?: AdvancedSearchRequestSummary[] | null;
    onChange: (summaries: AdvancedSearchRequestSummary[] | null) => void;
    entityMetadata: BridgeEntityResult;
    entityMetadataList: BridgeEntityResult[];
}

const SummariesEditor: React.FC<SummariesEditorProps> = props => {
    const { summaries, onChange, entityMetadata, entityMetadataList } = props;

    const handleAddColumn = (e: React.MouseEvent) => {
        e.preventDefault();
        onChange(
            produce(summaries, draft => {
                draft[0].aggregates.push({
                    operator: AdvancedSearchAggregateOperator.Count,
                    column: "*",
                });
            }),
        );
    };

    const aggregateItemExcludeList: EntityConditionFieldPicker[] = useMemo(() => {
        const questionFields: EntityConditionFieldPicker[] = entityMetadata.questions.map(
            q => ({ questionId: q.id }),
        );

        const relations: EntityConditionFieldPicker[] = entityMetadata.relations.map(
            r => ({
                relation: {
                    name: r.name,
                },
            }),
        );

        const nonAggregates = entityMetadata.fields
            .filter(f => !f.reportBehavior.canAggregate)
            .map(f => ({ fieldName: f.name }));

        return [...questionFields, ...relations, ...nonAggregates];
    }, [entityMetadata]);

    const groupByExcludeList: EntityConditionFieldPicker[] = useMemo(() => {
        const questionFields: EntityConditionFieldPicker[] = entityMetadata.questions.map(
            q => ({ questionId: q.id }),
        );

        const relations: EntityConditionFieldPicker[] = entityMetadata.relations
            .filter(r => r.kind !== BridgeRelationKind.ManyToOne)
            .map(r => ({
                relation: {
                    name: r.name,
                },
            }));

        const nonGroupable = entityMetadata.fields
            .filter(f => !f.reportBehavior.summaryGroupable)
            .map(f => ({ fieldName: f.name }));

        return [...questionFields, ...relations, ...nonGroupable];
    }, [entityMetadata]);

    const handleAggregateChange = (
        newAggregate: AdvancedSearchAggregate,
        aggregateIndex: number,
    ) => {
        onChange(
            produce(summaries, draft => {
                draft[0].aggregates[aggregateIndex] = newAggregate;
            }),
        );
    };

    const handleAggregateDelete = (aggregateIndex: number) => {
        onChange(
            produce(summaries, draft => {
                draft[0].aggregates.splice(aggregateIndex, 1);
                if (draft[0].sort?.aggregateIndex === aggregateIndex) {
                    draft[0].sort =
                        draft[0].aggregates.length === 0
                            ? null
                            : {
                                  aggregateIndex: 0,
                                  ascending: false,
                              };
                } else if (draft[0].sort?.aggregateIndex > aggregateIndex) {
                    draft[0].sort!.aggregateIndex--;
                }
            }),
        );
    };

    if (!summaries || summaries.length === 0 || summaries[0].aggregates.length === 0) {
        return null;
    }

    const summary = summaries[0];
    const groupBy = summary.groupBy?.[0] || null;
    const groupByValue = groupBy
        ? getEntityField(entityMetadata, groupBy)
            ? { fieldName: groupBy }
            : entityMetadata.relations.some(f => f.name === groupBy)
            ? { relation: { name: groupBy } }
            : null
        : null;

    return (
        <div className="pl-6 py-4">
            <div className="space-y-4">
                <div className="space-y-2">
                    <p className="text-sm">Summary columns</p>
                    <ul className="space-y-1">
                        {summary.aggregates.map((aggregate, aggregateIndex, arr) => (
                            <li key={aggregateIndex}>
                                <AggregateItemEditor
                                    aggregate={aggregate}
                                    onChange={a => {
                                        handleAggregateChange(a, aggregateIndex);
                                    }}
                                    entityMetadata={entityMetadata}
                                    entityMetadataList={entityMetadataList}
                                    onDelete={
                                        arr.length > 1
                                            ? () => {
                                                  handleAggregateDelete(aggregateIndex);
                                              }
                                            : null
                                    }
                                    entityPickerExcludeList={aggregateItemExcludeList}
                                />
                            </li>
                        ))}
                    </ul>
                    <SecondaryButton onClick={handleAddColumn}>
                        <span className="text-sm">Add column</span>
                    </SecondaryButton>
                </div>
                <div className="space-y-2">
                    <p className="text-sm">Group summaries by</p>
                    <EntityFieldPicker
                        textSize="sm"
                        entityMetadata={entityMetadata}
                        entityMetadataList={entityMetadataList}
                        value={groupByValue}
                        onChange={c => {
                            if (c?.fieldName || c?.relation?.name) {
                                onChange(
                                    produce(summaries, draft => {
                                        draft[0].groupBy = [
                                            c.fieldName || c.relation.name,
                                        ];
                                    }),
                                );
                            } else {
                                onChange(
                                    produce(summaries, draft => {
                                        draft[0].groupBy = null;
                                    }),
                                );
                            }
                        }}
                        exclude={groupByExcludeList}
                        allowEmpty={true}
                    />
                </div>
            </div>
        </div>
    );
};

interface AggregateItemEditorProps {
    aggregate: AdvancedSearchAggregate;
    entityMetadata: BridgeEntityResult;
    entityMetadataList: BridgeEntityResult[];
    onChange: (aggregate: AdvancedSearchAggregate) => void;
    onDelete: () => void;
    entityPickerExcludeList: EntityConditionFieldPicker[];
}

const AggregateItemEditor: React.FC<AggregateItemEditorProps> = props => {
    const {
        aggregate,
        onChange,
        entityMetadata,
        onDelete,
        entityPickerExcludeList,
        entityMetadataList,
    } = props;

    return (
        <div className="flex items-center space-x-2">
            <div className="flex-1">
                <EntityFieldPicker
                    textSize="sm"
                    entityMetadata={entityMetadata}
                    entityMetadataList={entityMetadataList}
                    value={{ fieldName: aggregate.column }}
                    onChange={c => {
                        const field = getEntityField(entityMetadata, c.fieldName);
                        onChange(
                            produce(aggregate, draft => {
                                draft.column = c.fieldName;
                                draft.operator =
                                    field?.questionType === QuestionType.number ||
                                    field?.questionType === QuestionType.currency
                                        ? AdvancedSearchAggregateOperator.Sum
                                        : AdvancedSearchAggregateOperator.Count;
                            }),
                        );
                    }}
                    exclude={entityPickerExcludeList}
                    includeSummaryFields={true}
                />
            </div>
            <AggregateItemOperatorEditor
                aggregate={aggregate}
                onChange={onChange}
                entityMetadata={entityMetadata}
            />
            {onDelete ? (
                <DeleteButton onClick={onDelete} backgroundColor="bg-white" />
            ) : (
                <div className="w-7 h-7"></div>
            )}
        </div>
    );
};

interface AggregateItemOperatorEditorProps {
    aggregate: AdvancedSearchAggregate;
    onChange: (aggregate: AdvancedSearchAggregate) => void;
    entityMetadata: BridgeEntityResult;
}

const AggregateItemOperatorEditor: React.FC<AggregateItemOperatorEditorProps> = props => {
    const { aggregate, onChange, entityMetadata } = props;

    const handleOperatorChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newOperator = e.target.value as AdvancedSearchAggregateOperator;
        onChange(
            produce(aggregate, draft => {
                draft.operator = newOperator;
            }),
        );
    };

    if (aggregate.column === "*") {
        return null;
    }

    const entityField = getEntityField(entityMetadata, aggregate.column);

    if (!entityField) {
        return null;
    }

    switch (entityField.questionType) {
        case QuestionType.text:
        case QuestionType.nary:
            return (
                <Select
                    textSize="sm"
                    value={aggregate.operator}
                    onChange={handleOperatorChange}>
                    <option value={AdvancedSearchAggregateOperator.Count}>
                        Count not empty
                    </option>
                    <option value={AdvancedSearchAggregateOperator.Min}>
                        First (A-Z)
                    </option>
                    <option value={AdvancedSearchAggregateOperator.Max}>
                        Last (Z-A)
                    </option>
                </Select>
            );
        case QuestionType.number:
        case QuestionType.currency:
            return (
                <Select
                    textSize="sm"
                    value={aggregate.operator}
                    onChange={handleOperatorChange}>
                    <option value={AdvancedSearchAggregateOperator.Sum}>Sum</option>
                    <option value={AdvancedSearchAggregateOperator.Average}>
                        Average
                    </option>
                    <option value={AdvancedSearchAggregateOperator.Min}>Minimum</option>
                    <option value={AdvancedSearchAggregateOperator.Max}>Maximum</option>
                </Select>
            );
        case QuestionType.date:
            return (
                <Select
                    textSize="sm"
                    value={aggregate.operator}
                    onChange={handleOperatorChange}>
                    <option value={AdvancedSearchAggregateOperator.Count}>
                        Count not empty
                    </option>
                    <option value={AdvancedSearchAggregateOperator.Min}>Earliest</option>
                    <option value={AdvancedSearchAggregateOperator.Max}>Latest</option>
                </Select>
            );
        default:
            return null;
    }
};
