import { CustomListCategoryId, CustomListKey } from "@sp-crm/core";
import { Select } from "components/ui/select";
import React, { useCallback } from "react";
import { useCustomList } from "store/selectors/bridge";

interface CustomListCategorySelectProps {
    customListKey: CustomListKey;
    value: CustomListCategoryId | null;
    onChange: (value: CustomListCategoryId | null) => void;
    label?: string;
}

export const CustomListCategorySelect: React.FC<
    CustomListCategorySelectProps
> = props => {
    const { customListKey, value, onChange, label } = props;

    const { customList } = useCustomList(customListKey);

    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLSelectElement>) => {
            onChange(e.target.value ? (e.target.value as CustomListCategoryId) : null);
        },
        [onChange],
    );

    if (!customList) {
        return (
            <Select disabled>
                <option>Loading...</option>
            </Select>
        );
    }

    return (
        <Select value={value} onChange={handleChange} label={label}>
            {customList.categories.map(category => (
                <option key={category.id} value={category.id}>
                    {category.name}
                </option>
            ))}
        </Select>
    );
};
