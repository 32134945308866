import { ICommunity } from "@sp-crm/core";
import * as React from "react";
import { ListableCommunity } from "../../community-search/props";

type ThumbnailSize = "64" | "96" | "128" | "256";
const defaultIcon = "/static/images/default-community.png";
interface ThumbnailProps {
    community: ListableCommunity | ICommunity;
    size?: ThumbnailSize;
    className?: string;
}

export const Thumbnail: React.FC<ThumbnailProps> = props => {
    const size: ThumbnailSize = props.size || "256";
    const className =
        props.className || `community-thumbnail community-thumbnail-${size}`;
    const thumbnailPath =
        (props.community as ICommunity).thumbnailUrl ||
        (props.community as ListableCommunity).thumbnail ||
        defaultIcon;
    return <img className={className} src={thumbnailPath} />;
};
