import { EnvelopeIcon } from "@heroicons/react/24/outline";
import React from "react";
import { EntityActivitySummary } from "./details/entity-activity-summary";
import { EntityActivityEntryProps } from "./types";

export const EntityActivityMessageSent: React.FC<EntityActivityEntryProps> = props => {
    const { activity } = props;
    return (
        <div className="relative flex items-start space-x-3">
            <div className="relative px-1">
                <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white">
                    <EnvelopeIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                </div>
            </div>
            <div className="min-w-0 flex-1 group pt-1">
                <EntityActivitySummary {...props}>
                    sent a message
                    {activity.message?.intakeCommunity?.name ? (
                        <>
                            &nbsp;to{" "}
                            <strong>{activity.message?.intakeCommunity?.name}</strong>
                        </>
                    ) : null}
                    .
                </EntityActivitySummary>
                {activity.message ? (
                    <div className="mt-2 text-base text-gray-900">
                        {activity.message.subject}
                    </div>
                ) : null}
            </div>
        </div>
    );
};
