import { WorkflowId } from "@sp-crm/core";
import { workflowPayloadFromWorkflow } from "components/admin/tenant/workflow/workflow-edit";
import { ContentHeader } from "components/layout";
import { EditButton } from "components/ui/action-button";
import { InlineBanner } from "components/ui/inline-banner";
import { Panel } from "components/ui/panel/panel";
import { PanelType } from "components/ui/panel/panel-type";
import { PrimaryButton } from "components/ui/primary-button";
import {
    GetWorkflowsQuery,
    WorkflowPayload,
    WorkflowStatus,
    useUpdateWorkflowMutation,
} from "generated/graphql";
import React from "react";
import { DelayRecipeEditor } from "./delay-recipe-editor";
import { EditWorkflowPanel } from "./edit-workflow-panel";
import { EditableWorkflow } from "./types";
import { WorkflowActionEditor } from "./workflow-action-editor";
import { WorkflowSettingsField } from "./workflow-settings-field";
import { WorkflowTriggerEditor } from "./workflow-trigger-editor";

interface SingleWorkflowSettingsProps {
    allWorkflows: GetWorkflowsQuery["getWorkflows"];
    workflow: EditableWorkflow;
    scope: "user" | "tenant";
    refetch: () => void;
    onSave: (field: string, newValue: string) => void;
    workflowSettings: Record<string, string>;
}

export const SingleWorkflowSettings: React.FC<SingleWorkflowSettingsProps> = props => {
    const { workflow, scope, refetch, onSave, workflowSettings, allWorkflows } = props;

    const [workflowToEdit, setWorkflowToEdit] = React.useState<EditableWorkflow>(null);

    const updateWorkflowMutation = useUpdateWorkflowMutation();

    const updateWorkflow = React.useCallback(
        async (workflowId: WorkflowId, workflow: WorkflowPayload) => {
            await updateWorkflowMutation.mutateAsync({
                workflowId,
                workflow,
            });

            refetch();
        },
        [updateWorkflowMutation, refetch],
    );

    const handlePublish = React.useCallback(
        async (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
            e.preventDefault();
            const workflowId = workflow?.id;
            if (!workflowId) return;
            if (!allWorkflows || allWorkflows.length === 0) return;
            const existingWorkflow = allWorkflows.find(w => w.id === workflowId);
            if (!existingWorkflow) return;
            const payload = workflowPayloadFromWorkflow(existingWorkflow);
            payload.status = WorkflowStatus.Published;
            await updateWorkflow(workflowId, payload);
        },
        [workflow, allWorkflows, updateWorkflow],
    );

    const updateDelayRecipe = React.useCallback(
        async (workflowId: WorkflowId, delayRecipe: string) => {
            if (!workflowId) return;
            if (!allWorkflows || allWorkflows.length === 0) return;
            const existingWorkflow = allWorkflows.find(w => w.id === workflowId);
            if (!existingWorkflow) return;
            const payload = workflowPayloadFromWorkflow(existingWorkflow);
            payload.delay = 0;
            payload.delayRecipe = delayRecipe;
            await updateWorkflow(workflow.id, payload);
        },
        [workflow, allWorkflows, updateWorkflow],
    );

    const settings = (workflow.settings || []).filter(s => s.scope === scope);
    return (
        <div>
            <div className="flex justify-between">
                <ContentHeader>{workflow.name}</ContentHeader>
                {scope === "tenant" ? (
                    <EditButton
                        backgroundColor="bg-white"
                        onClick={() => {
                            setWorkflowToEdit(workflow);
                        }}
                    />
                ) : null}
            </div>
            <div className="space-y-2">
                <p className="text-sm text-gray-500">{workflow.description}</p>
                {scope === "tenant" && workflow.status === WorkflowStatus.Draft ? (
                    <InlineBanner type="info">
                        <div className="space-y-2">
                            <div>
                                This workflow is not currently enabled. Please{" "}
                                <em>publish</em> the workflow once you have configured the
                                options below.
                            </div>
                            <PrimaryButton onClick={handlePublish}>
                                Publish workflow
                            </PrimaryButton>
                        </div>
                    </InlineBanner>
                ) : null}
                {scope === "tenant" ? (
                    <WorkflowTriggerEditor
                        workflow={workflow}
                        onChange={w => {
                            const payload = workflowPayloadFromWorkflow(w);

                            updateWorkflow(workflow.id, payload);
                        }}
                    />
                ) : null}
                {settings.map(setting => (
                    <WorkflowSettingsField
                        key={`${workflow.id}.${scope}.${setting.key}`}
                        scope={scope}
                        workflow={workflow}
                        setting={setting}
                        workflowSettings={workflowSettings}
                        onSave={onSave}
                    />
                ))}
                {scope === "tenant" ? (
                    <WorkflowActionEditor
                        workflow={workflow}
                        onChange={w => {
                            updateWorkflow(workflow.id, workflowPayloadFromWorkflow(w));
                        }}
                    />
                ) : null}
                {scope === "tenant" ? (
                    <DelayRecipeEditor workflow={workflow} onCommit={updateDelayRecipe} />
                ) : null}
            </div>
            <Panel
                type={PanelType.extraLarge}
                headerText="Edit workflow"
                isOpen={!!workflowToEdit}
                onDismiss={() => setWorkflowToEdit(null)}>
                {workflowToEdit ? (
                    <EditWorkflowPanel
                        workflow={workflowToEdit}
                        onSaved={_ => {
                            setWorkflowToEdit(null);
                            refetch();
                        }}
                        onCancel={() => setWorkflowToEdit(null)}
                        onDeleted={_ => {
                            setWorkflowToEdit(null);
                            refetch();
                        }}
                    />
                ) : null}
            </Panel>
        </div>
    );
};
