import * as C from "@sp-crm/core";
import { InternalId } from "@sp-crm/core";
import { Action, Dispatch } from "redux";
import * as http from "../../util/http";
import { Actions } from "./index";
import { makeRequestAction } from "./types";

export interface ClientIntakeDeliveryResult {
    accepted: string[];
    rejected: string[];
    code?: string;
}

export interface ClientIntakeEmailResponse {
    deliveryResults: ClientIntakeDeliveryResult[];
}

export function sendClientIntakeEmail(
    eventId: InternalId,
    messageDetails: C.ClientIntakeMessage,
    dispatch: Dispatch<Action>,
): Promise<void> {
    dispatch(makeRequestAction(Actions.EMAIL_FORM_SUBMIT, eventId));
    const requestDescription = http.newRequestDescription();
    return http
        .post(
            requestDescription,
            `/api/clients/${messageDetails.clientId}/communities/notifications`,
            messageDetails,
        )
        .then(r => r.data as ClientIntakeEmailResponse)
        .then(data => {
            dispatch(makeRequestAction(Actions.EMAIL_FORM_SUCCESS, eventId, data));
        })
        .catch(r => {
            if (!r || !r.response || r.response.status !== 422) {
                http.errorHandler(r, requestDescription, dispatch);
            }
            dispatch(
                makeRequestAction(Actions.EMAIL_FORM_ERROR, eventId, r?.response?.data),
            );
        });
}
