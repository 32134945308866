import { SavedQueryId, Schedule, everyMonday, parseSchedule, toCron } from "@sp-crm/core";
import { Checkbox } from "components/ui/checkbox";
import { HtmlInput } from "components/ui/html-input";
import { InlineBanner } from "components/ui/inline-banner";
import { PrimaryButton } from "components/ui/primary-button";
import { ScheduleEditor } from "components/ui/schedule-editor";
import { SecondaryButton } from "components/ui/secondary-button";
import { Spinner } from "components/ui/spinner";
import { UserAudienceSelector } from "components/ui/user-audience-selector";
import {
    SavedQueryScheduleResult,
    UserAudience,
    UserAudienceType,
    useDeleteSavedQueryScheduleMutation,
    useSendSavedQueryEmailMutation,
    useUpsertSavedQueryScheduleMutation,
} from "generated/graphql";
import React, { useMemo } from "react";

interface AdvancedSearchScheduleEditorProps {
    savedQueryId: SavedQueryId;
    initial: SavedQueryScheduleResult | null;
    onSaved: (schedule: SavedQueryScheduleResult) => void;
    onCancel: () => void;
}

export const AdvancedSearchScheduleEditor: React.FC<
    AdvancedSearchScheduleEditorProps
> = props => {
    const { initial, onSaved, onCancel, savedQueryId } = props;

    const [userAudience, setUserAudience] = React.useState<UserAudience>(
        initial?.audience ?? {
            type: UserAudienceType.Everyone,
        },
    );
    const [schedule, setSchedule] = React.useState<Schedule | null>(
        initial?.schedule ? parseSchedule(initial.schedule) : null,
    );
    const [emailDescription, setEmailDescription] = React.useState<string>(
        initial?.emailDescription ?? "",
    );

    const upsertScheduleMutation = useUpsertSavedQueryScheduleMutation();
    const deleteScheduleMutation = useDeleteSavedQueryScheduleMutation();
    const sendEmailMutation = useSendSavedQueryEmailMutation();

    const handleSave = async () => {
        if (schedule) {
            const result = await upsertScheduleMutation.mutateAsync({
                schedule: {
                    savedQueryId: savedQueryId,
                    schedule: toCron(schedule),
                    audience: userAudience,
                    emailDescription,
                },
            });
            onSaved(result.upsertSavedQuerySchedule);
        } else {
            if (initial) {
                await deleteScheduleMutation.mutateAsync({
                    savedQueryId: savedQueryId,
                });
            }
            onSaved(null);
        }
    };

    const validationError = useMemo(() => {
        if (!schedule) {
            return null;
        }

        if (Array.isArray(schedule.dayOfWeek) && schedule.dayOfWeek.length === 0) {
            return "At least one day of the week must be selected";
        }

        if (
            !schedule.dayOfMonth ||
            (typeof schedule.dayOfMonth === "number" &&
                (schedule.dayOfMonth < 1 || schedule.dayOfMonth > 31))
        ) {
            return "Day of month must be a number between 1 and 31";
        }

        return null;
    }, [schedule]);

    const onEmailNowClicked = React.useCallback(
        async (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
            e.preventDefault();

            await sendEmailMutation.mutateAsync({
                payload: {
                    savedQueryId,
                    audience: userAudience,
                    emailDescription,
                },
            });
        },
        [sendEmailMutation, savedQueryId, userAudience, emailDescription],
    );

    return (
        <div className="space-y-4">
            <p>Email this report on a schedule to the users and groups of your choice.</p>
            <Checkbox
                checked={!!schedule}
                onChange={e => {
                    if (e.target.checked) {
                        setSchedule(everyMonday());
                    } else {
                        setSchedule(null);
                    }
                }}
                label="Enable scheduled email"
            />
            {schedule ? (
                <>
                    <UserAudienceSelector
                        userAudience={userAudience}
                        onChange={setUserAudience}
                    />
                    <ScheduleEditor value={schedule} onChange={setSchedule} />

                    <HtmlInput
                        label={
                            <>
                                <p>Email Description</p>
                                <span className="text-xs italic mt-2">
                                    (Optional) This description will be included in each
                                    emailed report.
                                </span>
                            </>
                        }
                        initialContent={initial?.emailDescription}
                        onCommit={setEmailDescription}
                    />
                    <div className="flex items-center space-x-2">
                        <SecondaryButton onClick={onEmailNowClicked}>
                            <p className="text-sm">Email report now</p>
                        </SecondaryButton>
                        {sendEmailMutation.isLoading ? <Spinner /> : null}
                    </div>
                </>
            ) : null}
            <div className="flex items-center space-x-2 pt-8">
                <PrimaryButton
                    disabled={!!validationError}
                    onClick={e => {
                        e.preventDefault();
                        handleSave();
                    }}>
                    Save
                </PrimaryButton>
                <SecondaryButton
                    onClick={e => {
                        e.preventDefault();
                        onCancel();
                    }}>
                    Cancel
                </SecondaryButton>
            </div>
            {validationError ? (
                <InlineBanner type="error">{validationError}</InlineBanner>
            ) : null}
        </div>
    );
};
