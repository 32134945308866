import { ActionType, EmailTemplateId } from "@sp-crm/core";
import React from "react";
import { Route, Switch } from "react-router";
import { useIsAllowed } from "store/selectors/hooks";
import { TemplateEdit } from "./templates-edit";
import { TemplateIndex } from "./templates-list";
import { TemplateNew } from "./templates-new";

interface RouteMatch {
    match: {
        params: {
            templateId: EmailTemplateId;
        };
    };
}
export const TemplatesIndex: React.FC<unknown> = () => {
    const canShare = useIsAllowed(ActionType.ModifySharedEmailTemplate);
    return (
        <div className="pb-4">
            <Switch>
                <Route exact path="/settings/templates" component={TemplateIndex} />
                <Route
                    exact
                    path="/settings/templates/new"
                    render={() => <TemplateNew canShareTemplates={canShare} />}
                />
                <Route
                    path="/settings/templates/:templateId"
                    render={(params: unknown) => (
                        <TemplateEdit
                            canShareTemplates={canShare}
                            templateId={(params as RouteMatch).match.params.templateId}
                        />
                    )}
                />
            </Switch>
        </div>
    );
};
