import { formatDateNoTime, LatLng, ReferralMappings } from "@sp-crm/core";
import { HoveredMapEntity } from "components/community-search/community-map/types";
import { Icon } from "components/icon";
import { ClampedText } from "components/ui/clamped-text";
import { ReferenceBusinessSearchQuery } from "generated/graphql";
import React, { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { usePreferences, useTenantSettings } from "store/selectors/hooks";
import { twoLineFormattedAddress } from "util/address";
import { PhoneSingleNumber } from "util/phone-display";
import { breakNewlines } from "util/text";

interface ReferenceBusinessSearchListProps {
    businessSearchResults: ReferenceBusinessSearchQuery["referenceBusinessSearch"]["hits"];
    setHoveredOrganization: (hoveredOrganization: HoveredMapEntity | null) => void;
}

export const ReferenceBusinessSearchList: React.FC<
    ReferenceBusinessSearchListProps
> = props => {
    const { businessSearchResults, setHoveredOrganization } = props;

    const handleEndHover = useCallback(() => {
        setHoveredOrganization(null);
    }, [setHoveredOrganization]);

    return (
        <ul className="space-y-4">
            {businessSearchResults.map(business => (
                <li key={business.id}>
                    <ReferenceBusinessSearchCard
                        business={business}
                        onHover={setHoveredOrganization}
                        onEndHover={handleEndHover}
                    />
                </li>
            ))}
        </ul>
    );
};

interface ReferenceBusinessSearchCardProps {
    business: ReferenceBusinessSearchQuery["referenceBusinessSearch"]["hits"][0];
    onHover: (hoveredOrganization: HoveredMapEntity | null) => void;
    onEndHover: () => void;
}

const ReferenceBusinessSearchCard: React.FC<ReferenceBusinessSearchCardProps> = props => {
    const { business, onHover, onEndHover } = props;

    const { showReferralLastUpdatedDateOnMainTable } = usePreferences();
    const { ownedReferralSources } = useTenantSettings();

    const orgTypeMapping = useMemo(() => {
        if (business.organizationType && ReferralMappings[business.organizationType]) {
            return ReferralMappings[business.organizationType];
        }

        return null;
    }, [business.organizationType]);

    const handleMouseEnter = () => {
        let location: LatLng | null = null;
        if (business.latitude && business.longitude) {
            location = {
                lat: parseFloat(business.latitude),
                lng: parseFloat(business.longitude),
            };
        }
        onHover({ entityId: business.id, location });
    };

    const handleMouseLeave = () => {
        onEndHover();
    };

    return (
        <div
            className="col-span-1 divide-y divide-gray-200 rounded md:rounded-lg bg-white shadow p-6"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}>
            <div className="flex-1 overflow-auto">
                <div className="flex space-x-2">
                    <div className="flex-1">
                        <h3 className="twoverride text-gray-900 text-lg">
                            <Link
                                to={`/references/organizations/show/${business.id}`}
                                className="twoverride hover:underline">
                                <div className="flex space-x-2 items-center">
                                    <div className="w-6">
                                        <Icon name={"39_Business"} />
                                    </div>
                                    <div>{business.name || "(no name)"}</div>
                                </div>
                            </Link>
                        </h3>
                        {orgTypeMapping ? (
                            <div className="text-sm font-medium mb-1">
                                {orgTypeMapping.category} &raquo;{" "}
                                {orgTypeMapping.humanReadable}
                            </div>
                        ) : null}
                        {twoLineFormattedAddress(
                            business.address,
                            business.city,
                            business.state,
                            business.zip,
                            "text-sm",
                        )}
                        <div className="text-sm">
                            {business.phone ? (
                                <PhoneSingleNumber
                                    displayName="office"
                                    phoneNumber={business.phone}
                                />
                            ) : null}
                            {business.fax ? (
                                <PhoneSingleNumber
                                    displayName="fax"
                                    phoneNumber={business.fax}
                                />
                            ) : null}
                        </div>
                    </div>
                </div>
                <div className="mt-4 border-t border-gray-200 pt-2">
                    <dl className="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2">
                        {business.summary ? (
                            <div className="sm:col-span-2">
                                <dt className="text-sm font-medium text-gray-500">
                                    Summary
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900">
                                    <ClampedText lines={3}>
                                        {breakNewlines(business.summary)}
                                    </ClampedText>
                                </dd>
                            </div>
                        ) : null}
                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">
                                Number of referrals
                            </dt>
                            <dd className="mt-1 flex text-sm text-gray-900">
                                {business.numberOfReferrals}
                            </dd>
                        </div>
                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">
                                Most recent referral
                            </dt>
                            <dd className="mt-1 flex text-sm text-gray-900">
                                {formatDateNoTime(business.mostRecentReferral)}
                            </dd>
                        </div>
                        {showReferralLastUpdatedDateOnMainTable ? (
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">
                                    Last updated
                                </dt>
                                <dd className="mt-1 flex text-sm text-gray-900">
                                    {formatDateNoTime(business.updatedAt)}
                                </dd>
                            </div>
                        ) : null}
                        {ownedReferralSources && business.assignedTo ? (
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">
                                    Assigned to
                                </dt>
                                <dd className="mt-1 flex text-sm text-gray-900">
                                    {business.assignedTo}
                                </dd>
                            </div>
                        ) : null}
                    </dl>
                </div>
            </div>
        </div>
    );
};
