import React, { FunctionComponent } from "react";
import { Header } from "../../../header";

interface ClientInfoHeaderProps {
    title?: string;
    showIcon?: boolean;
    isCouple?: boolean;
    children?: React.ReactNode;
}

const ClientInfoHeader: FunctionComponent<ClientInfoHeaderProps> = ({
    title = "Client",
    showIcon = true,
    isCouple = false,
    children,
}) => {
    const getIconName = () => {
        if (!showIcon) {
            return "";
        }

        if (!isCouple) {
            return "07_ClientInfo";
        }

        return "56_Couple";
    };

    return (
        <div className="flex-row-no-bottom-margin flex-space-between">
            <div className="flex-column">
                <div className="header">
                    <Header iconName={getIconName()}>{title}</Header>
                </div>
            </div>
            {children}
        </div>
    );
};

export default ClientInfoHeader;
