/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    InteractionType,
    IQuestion,
    isCustomizableSection,
    Maybe,
    Perspective,
    Question,
    QuestionBehavior,
    QuestionCategories,
    QuestionType,
} from "@sp-crm/core";
import { ContentHeader } from "components/layout";
import { Loading } from "components/loading";
import { Radio, RadioOption } from "components/shared/radio";
import { fancyConfirm } from "components/ui/fancy-confirm";
import { InlineBanner } from "components/ui/inline-banner";
import { Input } from "components/ui/input";
import { PrimaryButton } from "components/ui/primary-button";
import { SecondaryButton } from "components/ui/secondary-button";
import * as React from "react";
import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Action, Dispatch } from "redux";
import { deleteQuestion, navigate, updateSettings } from "store/actions";
import { ApplicationState } from "store/state";
import { inAdvancedMode } from "util/browser";
import { EditAnnotations } from "./edit-annotations";
import { EditCategory } from "./edit-category";
import { EditKey } from "./edit-key";
import { EditOptions } from "./edit-options";

interface Props {
    questionId: string;
}

export const Edit: React.FunctionComponent<Props> = props => {
    const settings = useSelector((state: ApplicationState) => state.settings);
    const dispatch = useDispatch();
    const enabledAdvanced = inAdvancedMode();
    return Maybe.fromValue(props.questionId)
        .map(
            id =>
                settings &&
                settings.legacyQuestions &&
                settings.legacyQuestions.find(q => q.id === id),
        )
        .map(question => (
            <EditInternal
                enabledAdvanced={enabledAdvanced}
                dispatch={dispatch}
                key={question.id}
                question={question}
            />
        ))
        .getOrElse(<Loading componentName="Edit setting" />);
};

const questionTypeOptions: RadioOption[] = [
    {
        key: "text",
        text: "Text / Notes",
        helpText:
            'One-line or multi-line text field. Example: "Reason for Seeking Supportive Housing"',
    },
    {
        key: "nary",
        text: "Multiple Options (choose one or many)",
        helpText:
            "Select one or more options from a predefined list. Example: Room types available",
    },
    {
        key: "binary",
        text: "Single Checkbox Option (Yes/No)",
        helpText: "Example: Pets allowed / Has a pet",
    },
    {
        key: "range",
        text: "Range (low and high values)",
        helpText:
            "Low to high range of numbers or dollars. Example: Price range for room",
    },
    {
        key: "number",
        text: "Number only",
        helpText: "Any numerical value. Example: Number of beds",
    },
    {
        key: "currency",
        text: "Dollar amount only",
        helpText: "Single dollar value. Example: Shared Room Rate",
    },
    { key: "phone", text: "Phone number", helpText: "Phone (or fax) number" },
    { key: "date", text: "Date", helpText: "Example: Community opened since" },
    {
        key: QuestionType[QuestionType.user],
        text: "Employee",
        helpText: "Employee at your company",
    },
];

type PdfBehaviorOption = RadioOption & { exclude?: QuestionType[] };

const pdfBehavior: PdfBehaviorOption[] = [
    {
        key: "showIfPresent",
        text: "Show only if set",
        helpText:
            "Field will appear in PDFs if it contains data (i.e. notes are present, or at least one checkbox is checked).",
        exclude: [QuestionType.user],
    },
    {
        key: "alwaysShow",
        text: "Always show",
        helpText: "Field will always appear in PDFs, even if blank.",
        exclude: [QuestionType.user],
    },
    {
        key: "alwaysHide",
        text: "Never show",
        helpText: "Field will never appear in PDFs. Useful for private/proprietary data.",
    },
];

const interactionTypeOptions = [
    {
        key: "none",
        enum: InteractionType.none,
        text: "Disabled",
        allowable: [
            QuestionType.nary,
            QuestionType.binary,
            QuestionType.number,
            QuestionType.currency,
            QuestionType.text,
            QuestionType.date,
            QuestionType.range,
            QuestionType.user,
            QuestionType.phone,
        ],
    },
    {
        key: "binary",
        enum: InteractionType.binary,
        text: "Enabled",
        allowable: [QuestionType.binary],
    },
    {
        key: "singleRadio",
        enum: InteractionType.singleRadio,
        text: "1 option allowed (with radio buttons)",
        helpText: "All options will be visible, but only 1 can be selected",
        allowable: [QuestionType.nary],
    },
    {
        key: "single",
        enum: InteractionType.single,
        text: "1 option allowed (with dropdown)",
        helpText:
            "1 options will be visible, with the rest visible when clicking on the dropdown",
        allowable: [QuestionType.nary],
    },
    {
        key: "multiple",
        enum: InteractionType.multiple,
        text: "Multiple options allowed",
        allowable: [QuestionType.nary],
    },
    {
        key: "multipleDropdown",
        enum: InteractionType.multipleDropdown,
        text: "Multiple options allowed (with dropdown)",
        allowable: [QuestionType.nary],
    },
    {
        key: "singleline",
        enum: InteractionType.singleline,
        text: "Text on single line",
        allowable: [QuestionType.text],
    },
    {
        key: "multiline",
        enum: InteractionType.multiline,
        text: "Text on multiple lines",
        helpText: "Also called a text box",
        allowable: [QuestionType.text],
    },
    {
        key: "number",
        enum: InteractionType.number,
        text: "Enabled",
        allowable: [QuestionType.number],
    },
    {
        key: "rangeNumber",
        enum: InteractionType.rangeNumber,
        text: "Number",
        helpText: "Range with number values",
        allowable: [QuestionType.range],
    },
    {
        key: "currency",
        enum: InteractionType.currency,
        text: "Enabled",
        allowable: [QuestionType.currency],
    },
    {
        key: "rangePrice",
        enum: InteractionType.rangePrice,
        text: "Currency",
        helpText: "Range with dollar values",
        allowable: [QuestionType.range],
    },
    {
        key: "date",
        enum: InteractionType.date,
        text: "Enabled",
        allowable: [QuestionType.date],
    },
    {
        key: "url",
        enum: InteractionType.url,
        text: "URL",
        helpText: "Web address. Example: https://example.com",
        allowable: [QuestionType.text],
    },
    {
        key: "user",
        enum: InteractionType.user,
        text: "Enabled",
        allowable: [QuestionType.user],
    },
    {
        key: "phone",
        enum: InteractionType.phone,
        text: "Enabled",
        allowable: [QuestionType.phone],
    },
];

const interactionTypesForQuestion = (q: IQuestion): RadioOption[] =>
    interactionTypeOptions.filter(o => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
        return o.allowable.indexOf(q.questionType as any) >= 0;
    });

export const updateQuestionType = (q: IQuestion, v: string) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    const newQuestionType = (QuestionType as any)[v]; // TODO problem?
    if (newQuestionType === q.questionType) return;
    const preferredOption = interactionTypeOptions.find(
        i => i.enum !== InteractionType.none && i.allowable.indexOf(newQuestionType) >= 0,
    );
    if (q.legacyClientInteraction !== InteractionType.none) {
        q.legacyClientInteraction = preferredOption.enum;
    }
    if (q.legacyCommunityInteraction !== InteractionType.none) {
        q.legacyCommunityInteraction = preferredOption.enum;
    }
    const preferredPdfOption = pdfBehavior.find(
        p => !p.exclude?.includes(newQuestionType),
    );

    if (preferredPdfOption) {
        q.legacyClientPdfBehavior =
            QuestionBehavior[preferredPdfOption.key as keyof typeof QuestionBehavior];
    }
    q.questionType = newQuestionType;
};

interface InternalProps {
    question: IQuestion;
    enabledAdvanced: boolean;
    dispatch: Dispatch<Action>;
}
export const EditInternal: React.FunctionComponent<InternalProps> = props => {
    const dispatch = props.dispatch;

    const [question, setQuestion] = useState(props.question);

    const [inAdvancedMode, setInAdvancedMode] = useState(false);
    const [advanceModeContent, setAdvanceModeContent] = useState("");
    const toggleAdvancedMode = useCallback(() => {
        if (!inAdvancedMode) {
            setAdvanceModeContent(JSON.stringify(question.asJson, null, 2));
            setInAdvancedMode(true);
        } else {
            const content = JSON.parse(advanceModeContent);
            setQuestion(Question.load(content));
            setAdvanceModeContent("");
            setInAdvancedMode(false);
        }
    }, [
        setInAdvancedMode,
        inAdvancedMode,
        setQuestion,
        question,
        setAdvanceModeContent,
        advanceModeContent,
    ]);

    const updateQuestion = useCallback(
        // eslint-disable-next-line @typescript-eslint/ban-types -- eslintintroduction
        <T extends {}>(cb: (q: IQuestion, val: T) => void) => {
            return (val: T) => {
                const newQuestion = question.clone();
                cb(newQuestion, val);
                setQuestion(newQuestion);
            };
        },
        [question, setQuestion],
    );

    const queryClient = useQueryClient();

    const submitForm = useCallback(
        (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            updateSettings(question, queryClient, dispatch).then(() =>
                navigate("/settings/agency/customize/cc"),
            );
        },
        [dispatch, question, queryClient],
    );

    const deleteWorkflow = useCallback(() => {
        (async () => {
            const result = await fancyConfirm(
                "Are you sure you want to delete this question?",
                "This will cause this question (and all corresponding answers) to disappear from every existing client and community record.",
                "Delete",
                "Cancel",
            );
            if (result) {
                await deleteQuestion(props.question.id, queryClient, dispatch);
                navigate("/settings/agency/customize/cc");
            }
        })();
    }, [dispatch, props.question.id, queryClient]);

    const cancel = useCallback(() => navigate("/settings/agency/customize/cc"), []);

    const showOptions =
        question.questionType === QuestionType.nary &&
        (question.legacyClientInteraction != InteractionType.none ||
            question.legacyCommunityInteraction != InteractionType.none);
    const showAnnotations =
        question.questionType === QuestionType.nary ||
        question.questionType === QuestionType.binary;

    return (
        <div className="space-y-4">
            <ContentHeader>Edit Custom Setting</ContentHeader>
            <div className="space-x-4">
                <PrimaryButton onClick={submitForm} type="submit">
                    Save
                </PrimaryButton>
                <SecondaryButton onClick={cancel}>Cancel</SecondaryButton>
                <SecondaryButton onClick={deleteWorkflow}>Delete</SecondaryButton>
                {props.enabledAdvanced ? (
                    <SecondaryButton onClick={toggleAdvancedMode}>
                        Toggle advanced mode
                    </SecondaryButton>
                ) : null}
            </div>

            {inAdvancedMode && (
                <>
                    <InlineBanner type="warning">
                        Be sure to &quot;Toggle&quot; before saving
                    </InlineBanner>
                    <textarea
                        className="monospace w-full h-96"
                        value={advanceModeContent}
                        onChange={e => setAdvanceModeContent(e.target.value)}
                    />
                </>
            )}
            {!inAdvancedMode && (
                <form onSubmit={submitForm} className="edit-question">
                    <div className="spaced-radio-button-group space-y-4">
                        {props.enabledAdvanced ? (
                            <div className="flex-row-no-bottom-margin">
                                <div className="full-width-grow-faster input-form-block-no-bottom-margin">
                                    <EditKey
                                        onChange={updateQuestion((q, v) => (q.key = v))}
                                        question={question}
                                    />
                                </div>
                                <div className="full-width dummy"></div>
                            </div>
                        ) : null}
                        <div className="flex-row-no-bottom-margin">
                            <div className="full-width-grow-faster input-form-block-no-bottom-margin">
                                <EditCategory
                                    updateCategory={
                                        updateQuestion(
                                            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
                                            (q, v) => (q.category = v as any),
                                            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
                                        ) as any
                                    }
                                    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
                                    category={question.category as any}
                                />
                                <Radio
                                    options={questionTypeOptions}
                                    value={
                                        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
                                        (QuestionType as any)[question.questionType]
                                    }
                                    label="Question type"
                                    onChange={updateQuestion((q, v) =>
                                        updateQuestionType(q, v),
                                    )}
                                />
                            </div>
                            <div className="full-width dummy"></div>
                        </div>
                        <div className="flex-row-no-bottom-margin">
                            <div className="full-width input-form-block-no-bottom-margin setting-column1">
                                <h2>Client</h2>
                                {isCustomizableSection(
                                    Perspective.Client,
                                    question.category as QuestionCategories,
                                ) ? (
                                    <>
                                        <Radio
                                            className="client-interaction-selection"
                                            options={interactionTypesForQuestion(
                                                question,
                                            )}
                                            value={
                                                // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
                                                (InteractionType as any)[
                                                    question.legacyClientInteraction
                                                ]
                                            }
                                            label="Configuration for Client Intake"
                                            onChange={updateQuestion(
                                                (q, v) =>
                                                    (q.legacyClientInteraction =
                                                        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
                                                        (InteractionType as any)[v]),
                                            )}
                                        />

                                        {question.legacyClientInteraction !==
                                            InteractionType.none && (
                                            <>
                                                <div className="bold-label">
                                                    <Input
                                                        value={question.legacyClientTitle}
                                                        label="Question Title (as shown on Client Intake form)"
                                                        onChange={updateQuestion(
                                                            (q, v) =>
                                                                (q.legacyClientTitle =
                                                                    v.target.value),
                                                        )}
                                                    />
                                                </div>
                                                <div>
                                                    <Radio
                                                        options={pdfBehavior.filter(
                                                            p =>
                                                                !p.exclude?.includes(
                                                                    question.questionType as QuestionType,
                                                                ),
                                                        )}
                                                        value={
                                                            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
                                                            (QuestionBehavior as any)[
                                                                question
                                                                    .legacyClientPdfBehavior
                                                            ]
                                                        }
                                                        label="Share when generating Client Intake PDFs"
                                                        onChange={updateQuestion(
                                                            (q, v) =>
                                                                (q.legacyClientPdfBehavior =
                                                                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                                                    (
                                                                        QuestionBehavior as any
                                                                    )[v]),
                                                        )}
                                                    />
                                                </div>
                                            </>
                                        )}
                                    </>
                                ) : (
                                    "Not applicable"
                                )}
                            </div>
                            <div className="full-width input-form-block-no-bottom-margin setting-column2">
                                <h2>Community</h2>
                                {isCustomizableSection(
                                    Perspective.Community,
                                    question.category as QuestionCategories,
                                ) ? (
                                    <>
                                        <Radio
                                            className="community-interaction-selection"
                                            options={interactionTypesForQuestion(
                                                question,
                                            )}
                                            value={
                                                // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
                                                (InteractionType as any)[
                                                    question.legacyCommunityInteraction
                                                ]
                                            }
                                            label="Configuration for Community Attributes"
                                            onChange={updateQuestion(
                                                (q, v) =>
                                                    (q.legacyCommunityInteraction =
                                                        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
                                                        (InteractionType as any)[v]),
                                            )}
                                        />
                                        {question.legacyCommunityInteraction !==
                                            InteractionType.none && (
                                            <>
                                                <div className="bold-label">
                                                    <Input
                                                        value={
                                                            question.legacyCommunityTitle
                                                        }
                                                        label="Question Title (as shown on Community Attributes form)"
                                                        onChange={updateQuestion(
                                                            (q, v) =>
                                                                (q.legacyCommunityTitle =
                                                                    v.target.value),
                                                        )}
                                                    />
                                                </div>
                                                <div>
                                                    <div className="bold-label">
                                                        <label className="large">
                                                            Share when generating
                                                            Community Comparison PDF
                                                        </label>
                                                        <p className="mt-2">
                                                            Configure community comparison
                                                            questions in the
                                                            &quot;Community comparison
                                                            settings&quot; section of{" "}
                                                            <Link to="/settings/agency/communities">
                                                                Agency Settings
                                                            </Link>
                                                        </p>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </>
                                ) : (
                                    "Not Applicable"
                                )}
                            </div>
                            <div className="full-width"></div>
                        </div>
                        {showAnnotations && (
                            <EditAnnotations
                                question={question}
                                updateQuestion={updateQuestion}
                            />
                        )}
                        {showOptions && (
                            <EditOptions
                                question={question}
                                updateQuestion={updateQuestion}
                            />
                        )}
                        <PrimaryButton type="submit">Save</PrimaryButton>
                    </div>
                </form>
            )}
        </div>
    );
};
