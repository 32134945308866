import {
    IQuestion,
    Question,
    QuestionCategories,
    QuestionId,
    QuestionSource,
    genEntityId,
    questionMetadata,
} from "@sp-crm/core";
import { ContentHeader, ContentSubheader } from "components/layout";
import { defaultLinkStyle } from "components/ui/link";
import { Panel } from "components/ui/panel/panel";
import { PanelType } from "components/ui/panel/panel-type";
import { PrimaryButton } from "components/ui/primary-button";
import {
    BridgeEntityResult,
    GetEntitiesQuery,
    UpsertEntityQuestionMutation,
} from "generated/graphql";
import { produce } from "immer";
import React, { useCallback, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { EntityQuestion } from "./entity-question";
import { EntityQuestionEditor } from "./entity-question-editor";

const useFlexibleQuestions = (entityMetadata: BridgeEntityResult): IQuestion[] => {
    return useMemo(() => {
        return (entityMetadata.questions || [])
            .filter(q => q.category === QuestionCategories[QuestionCategories.flexible])
            .map(q => Question.load(q));
    }, [entityMetadata.questions]);
};

interface EntityQuestionsProps {
    entityMetadata: BridgeEntityResult;
}

export const EntityQuestions: React.FC<EntityQuestionsProps> = props => {
    const { entityMetadata } = props;

    const [editingQuestionId, setEditingQuestionId] = useState<QuestionId>(null);

    const handleCreateClicked = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e && e.preventDefault && e.preventDefault();

            setEditingQuestionId(genEntityId<QuestionId>());
        },
        [setEditingQuestionId],
    );

    const handleDismiss = useCallback(() => {
        setEditingQuestionId(null);
    }, [setEditingQuestionId]);

    const queryClient = useQueryClient();
    const questions = useFlexibleQuestions(entityMetadata);

    const handleSaved = useCallback(
        (question: UpsertEntityQuestionMutation["upsertEntityQuestion"]) => {
            const entitiesData = queryClient.getQueryData<GetEntitiesQuery>([
                "getEntities",
                {},
            ]);
            const newData = produce(entitiesData, draft => {
                const entity = draft.getEntities.find(
                    e => e.name === entityMetadata.name,
                );

                if (entity && Array.isArray(entity.questions)) {
                    let found = false;
                    entity.questions = entity.questions.map(existingQuestion => {
                        if (existingQuestion.id === question.id) {
                            found = true;
                            return question;
                        }
                        return existingQuestion;
                    });

                    if (!found) {
                        entity.questions.push(question);
                    }

                    entity.questions.sort((a, b) => a.title.localeCompare(b.title));
                }
            });

            queryClient.setQueryData<GetEntitiesQuery>(["getEntities", {}], newData);
            handleDismiss();
        },
        [queryClient, entityMetadata, handleDismiss],
    );

    const handleDeleted = useCallback(
        (questionId: QuestionId) => {
            const entitiesData = queryClient.getQueryData<GetEntitiesQuery>([
                "getEntities",
                {},
            ]);
            const newData = produce(entitiesData, draft => {
                const entity = draft.getEntities.find(
                    e => e.name === entityMetadata.name,
                );

                if (entity && Array.isArray(entity.questions)) {
                    entity.questions = entity.questions.filter(
                        existingQuestion => existingQuestion.id !== questionId,
                    );
                }
            });

            queryClient.setQueryData<GetEntitiesQuery>(["getEntities", {}], newData);
            handleDismiss();
        },
        [queryClient, entityMetadata, handleDismiss],
    );

    const handleQuestionEdit = useCallback(
        (questionId: QuestionId) => {
            setEditingQuestionId(questionId);
        },
        [setEditingQuestionId],
    );

    const migratedQuestions = useMemo(() => {
        return questions.filter(q => q.source === QuestionSource.Legacy);
    }, [questions]);

    const modernQuestions = useMemo(() => {
        return questions.filter(q => q.source === QuestionSource.Entity);
    }, [questions]);

    const hasLegacyQuestions =
        entityMetadata.name === "Client" || entityMetadata.name === "Community";

    return (
        <div>
            <ContentHeader>
                {hasLegacyQuestions ? "Details Page - Fields" : "Custom fields"}
            </ContentHeader>
            <ContentSubheader>
                {hasLegacyQuestions ? (
                    <>
                        <p className="pr-8">
                            Define fields for {entityMetadata.title} records that can be
                            used on the details page. To add fields to standard form
                            sections, use the{" "}
                            <Link
                                className={defaultLinkStyle}
                                to="/settings/agency/customize/cc">
                                Customize
                            </Link>{" "}
                            page.
                        </p>
                    </>
                ) : (
                    <p>Define additional fields for {entityMetadata.title} records.</p>
                )}
            </ContentSubheader>
            <div className="space-y-4 mt-4">
                {modernQuestions.length > 0 ? (
                    <ul>
                        {modernQuestions.map(question => (
                            <EntityQuestion
                                key={question.id}
                                question={question}
                                onEdit={handleQuestionEdit}
                            />
                        ))}
                    </ul>
                ) : hasLegacyQuestions ? null : (
                    <p>No custom fields have been defined for {entityMetadata.title}</p>
                )}
                <PrimaryButton onClick={handleCreateClicked}>
                    Create custom field
                </PrimaryButton>
            </div>
            {migratedQuestions.length > 0 ? (
                <div className="space-y-4 mt-4">
                    <div className="space-y-2">
                        <p className="text-lg">Migrated fields</p>
                        <ContentSubheader>
                            <p className="pr-8">
                                These fields were marked with the{" "}
                                {
                                    questionMetadata[QuestionCategories.flexible]
                                        .communityHeading
                                }{" "}
                                category in the{" "}
                                <Link
                                    className={defaultLinkStyle}
                                    to="/settings/agency/customize/cc">
                                    Customize
                                </Link>{" "}
                                page so they can be placed in the layout.
                            </p>
                        </ContentSubheader>
                    </div>
                    <ul>
                        {migratedQuestions.map(question => (
                            <EntityQuestion
                                key={question.id}
                                question={question}
                                onEdit={handleQuestionEdit}
                            />
                        ))}
                    </ul>
                </div>
            ) : null}
            <Panel
                type={PanelType.extraLarge}
                hasCloseButton={true}
                isOpen={!!editingQuestionId}
                headerText="Edit custom field"
                onDismiss={handleDismiss}>
                <EntityQuestionEditor
                    entityMetadata={entityMetadata}
                    questionId={editingQuestionId}
                    onCancel={handleDismiss}
                    onSaved={handleSaved}
                    onDeleted={handleDeleted}
                />
            </Panel>
        </div>
    );
};
