import { BridgeEntityResult } from "generated/graphql";
import React from "react";
import { EntityLayoutConfiguration } from "./entity-layout-configuration";
import { EntityQuestions } from "./entity-questions";

interface EntitySettingsProps {
    entityName: string;
    entities: BridgeEntityResult[];
}

export const EntitySettings: React.FC<EntitySettingsProps> = props => {
    const { entityName, entities } = props;

    const entityMetadata = entities.find(e => e.name === entityName);

    if (!entityMetadata) {
        return (
            <div>
                <div>Customization is unavailable for {entityName}</div>
                <pre>
                    {JSON.stringify(
                        entities.map(x => x.name),
                        null,
                        2,
                    )}
                </pre>
            </div>
        );
    }

    return (
        <div className="lg:grid lg:grid-cols-2">
            <EntityQuestions entityMetadata={entityMetadata} />
            <EntityLayoutConfiguration entityMetadata={entityMetadata} />
        </div>
    );
};
