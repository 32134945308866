import { ContentFull, ContentHeader } from "components/layout";
import { InlineEmailTemplateSetting } from "components/templates/inline-email-template-setting";
import { AdvancedSearchEntityType } from "generated/graphql";
import React from "react";
import { useTenantSettings } from "store/selectors/hooks";
import { AgencyCheckboxPref } from "./agency-settings-checkbox";
import { useCommitTenantSetting } from "./agency-settings-hooks";

export const AgencySettingsSignWise: React.FC<unknown> = () => {
    const updatesignatureRequestInitialTemplateId = useCommitTenantSetting(
        "signatureRequestInitialTemplateId",
    );
    const updatesignatureRequestCompleteTemplateId = useCommitTenantSetting(
        "signatureRequestCompleteTemplateId",
    );
    const settings = useTenantSettings();

    return (
        <>
            <ContentFull>
                <ContentHeader>SignWise</ContentHeader>
                <div className="space-y-4">
                    <AgencyCheckboxPref
                        field="enableSignWise"
                        label="Enable SignWise at my company"
                        description="Turn on SignWise e-signatures"
                    />
                    {settings.enableSignWise ? (
                        <>
                            <div>
                                <ContentHeader>Signwise request email</ContentHeader>
                                <InlineEmailTemplateSetting
                                    subjectLabel="SignWise request default email subject"
                                    bodyLabel="SignWise request default email body"
                                    value={settings.signatureRequestInitialTemplateId}
                                    onChange={updatesignatureRequestInitialTemplateId}
                                    messageType="SignatureRequest"
                                    includeAttachments
                                    placeholderTypes={[
                                        AdvancedSearchEntityType.Client,
                                        AdvancedSearchEntityType.Community,
                                        AdvancedSearchEntityType.ReferenceContact,
                                        AdvancedSearchEntityType.ReferenceBusiness,
                                    ]}
                                />
                            </div>
                            <div>
                                <ContentHeader>Signwise completed email</ContentHeader>
                                <InlineEmailTemplateSetting
                                    subjectLabel="SignWise completed document email subject"
                                    bodyLabel="SignWise completed document email body"
                                    attachmentLabel="Additional attachments"
                                    value={settings.signatureRequestCompleteTemplateId}
                                    onChange={updatesignatureRequestCompleteTemplateId}
                                    includeAttachments
                                />
                            </div>
                        </>
                    ) : null}
                </div>
            </ContentFull>
        </>
    );
};
