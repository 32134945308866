import { Location } from "history";
import * as React from "react";
import { connect } from "react-redux";
import { Action, Dispatch } from "redux";
import { ApplicationState } from "store/state";
import { ReminderProvider } from "../../contexts/reminder";
import * as actions from "../../store/actions";
import * as responsiveActions from "../../store/actions/responsive";
import { ResponsiveMode } from "../../store/reducers/responsive";
import { baseAppDataLoaded } from "../../store/selectors/selectors";
import { Alerts } from "../alerts";
import { Responsive } from "../responsive";

import { QueryClient } from "react-query";
import { RouteComponentProps, withRouter } from "react-router";
import { AutoReload } from "./auto-reload";
import { Header } from "./header";
import { MobileNav } from "./navigation/mobile-nav";

interface ContainerPropsFromState {
    children: React.ReactNode;
    location: Location;
    responsiveMode: ResponsiveMode;
    showMenu: boolean;
    baseAppDataLoaded: boolean;
}

interface ContainerPropsFromDispatch {
    nav: (route: string) => void;
    hideMenu: () => void;
    loadAll: (queryClient: QueryClient) => void;
}

interface ContainerOwnProps {
    queryClient: QueryClient;
}

type ContainerProps = ContainerPropsFromDispatch &
    ContainerPropsFromState &
    ContainerOwnProps;

class Container extends React.Component<ContainerProps, undefined> {
    UNSAFE_componentWillMount() {
        if (!this.isLoginWorkflowRoute()) {
            this.props.loadAll(this.props.queryClient);
        }
    }

    isLoginWorkflowRoute(): boolean {
        const pathname: string = this.props.location.pathname;
        if (pathname === "/login") return true;
        if (pathname === "/license") return true;
        if (pathname === "/account/reset-password") return true;
        if (pathname === "/account/reset") return true;
        return false;
    }

    render() {
        if (!this.isLoginWorkflowRoute() && !this.props.baseAppDataLoaded) {
            return null;
        }

        return (
            <div
                className={`h-100 ms-App size-${
                    ResponsiveMode[this.props.responsiveMode]
                }`}>
                <AutoReload />
                <div className="app-component h-100 d-flex flex-column">
                    <ReminderProvider>
                        <Header />
                        <MobileNav />
                    </ReminderProvider>
                    <div className="sp-body row flex-1">
                        <div className="col main-body h-100 position-relative">
                            {this.props.children}
                        </div>
                    </div>
                    <Alerts />
                </div>
                <Responsive />
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: Dispatch<Action>): ContainerPropsFromDispatch {
    return {
        nav: (route: string) => {
            actions.navigate(route);
        },
        hideMenu: () => {
            dispatch(responsiveActions.hideMenu());
        },
        loadAll: (queryClient: QueryClient) => {
            actions.loadAll(queryClient, dispatch);
        },
    };
}

function mapStateToProps(
    state: ApplicationState,
    ownProps: RouteComponentProps & { children: React.ReactNode },
): ContainerPropsFromState {
    return {
        children: ownProps.children,
        location: ownProps.location,
        responsiveMode: state.responsive.mode,
        showMenu: state.responsive.showMenu,
        baseAppDataLoaded: baseAppDataLoaded(state),
    };
}

const component = withRouter(connect(mapStateToProps, mapDispatchToProps)(Container));
export { component as Container };
