import { AutosavingInput } from "components/ui/autosaving-input";
import * as React from "react";
import { InDepthQuestionProps } from "./props";

export class RangeNumberQuestion extends React.Component<
    InDepthQuestionProps,
    undefined
> {
    constructor(props: InDepthQuestionProps) {
        super(props);
        this.updateAnswerHigh = this.updateAnswerHigh.bind(this);
        this.updateAnswerLow = this.updateAnswerLow.bind(this);
    }

    updateAnswerHigh(newVal: string): void {
        this.props.requestAnswerModification(a => {
            a.numberAnswerHigh = parseInt(newVal, 10);
        });
    }

    updateAnswerLow(newVal: string): void {
        this.props.requestAnswerModification(a => {
            a.numberAnswer = parseInt(newVal, 10);
        });
    }

    title(): string {
        return this.props.question.title;
    }

    render(): JSX.Element {
        return (
            <span className="range-question">
                <AutosavingInput
                    onCommit={this.updateAnswerLow}
                    type="number"
                    initial={`${this.props.answer.numberAnswer}`}
                    label={`${this.title()} (low)`}
                />
                <span className="range-question-separator">&#8212;</span>
                <AutosavingInput
                    onCommit={this.updateAnswerHigh}
                    type="number"
                    initial={`${this.props.answer.numberAnswerHigh}`}
                    label={`${this.title()} (high)`}
                />
            </span>
        );
    }
}
