import { IAdditionalClient, IClient, SAME_FOR_BOTH_STR } from "@sp-crm/core";
import { Checkbox } from "components/ui/checkbox";
import React, { PureComponent } from "react";
import { Header } from "../../../header";
import ClientDetails from "./client-details";

type MultipleClientsProps = {
    client: IClient;
    additionalClient: IAdditionalClient;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    onFieldChange: (fieldName: string, newValue: any) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    onAdditionalClientFieldChange: (fieldName: string, newValue: any) => void;
    onSameForBothClientsCheckboxChanged: (newValue: boolean) => void;
};

class MultipleClients extends PureComponent<MultipleClientsProps> {
    shouldShowBothClientsTogether() {
        return this.props.additionalClient.insuranceAnswersSameAsPrimaryClient;
    }

    getSameForBothClientsCheckbox() {
        return (
            <div>
                <Checkbox
                    onChange={newValue =>
                        this.props.onSameForBothClientsCheckboxChanged(
                            newValue.target.checked,
                        )
                    }
                    checked={
                        this.props.additionalClient.insuranceAnswersSameAsPrimaryClient
                    }
                    label={SAME_FOR_BOTH_STR}
                />
            </div>
        );
    }

    render() {
        return (
            <div className="input-form-block-no-bottom-margin">
                <div>
                    <div className="flex-row-no-bottom-margin flex-space-between">
                        <div className="header">
                            <Header iconName="53_insurance">
                                Health Insurance Details
                            </Header>
                        </div>
                        {this.getSameForBothClientsCheckbox()}
                    </div>
                </div>
                {this.shouldShowBothClientsTogether() ? (
                    <ClientDetails
                        client={this.props.client}
                        personaTitle={this.props.client.formatClientAndAdditionalClientNames()}
                        onFieldChange={this.props.onFieldChange}
                    />
                ) : (
                    <>
                        <ClientDetails
                            client={this.props.client}
                            personaTitle={this.props.client.formatName(
                                this.props.client.name,
                            )}
                            onFieldChange={this.props.onFieldChange}
                            additionalClassNames="no-bottom-space"
                        />
                        <ClientDetails
                            client={this.props.additionalClient}
                            personaTitle={this.props.client.formatName(
                                this.props.additionalClient.name,
                            )}
                            onFieldChange={this.props.onAdditionalClientFieldChange}
                        />
                    </>
                )}
            </div>
        );
    }
}

export default MultipleClients;
