import { UserPlusIcon } from "@heroicons/react/24/outline";
import { defaultLinkStyle } from "components/ui/link";
import { GetActivityFeedQuery } from "generated/graphql";
import React from "react";
import { Link } from "react-router-dom";
import { EntityActivitySummary } from "./details/entity-activity-summary";
import { EntityActivityEntryProps } from "./types";

const activityItemValid = (
    activity: GetActivityFeedQuery["getActivityFeed"]["activities"][0],
): boolean => {
    return !!(
        activity.activity.parentReferenceContact &&
        (activity.activity.parentReferenceBusiness || activity.activity.parentCommunity)
    );
};

const contactName = (
    activity: GetActivityFeedQuery["getActivityFeed"]["activities"][0],
): string => {
    return activity.activity.parentReferenceContact?.contactEntity?.name || "(no name)";
};

const contactLink = (
    activity: GetActivityFeedQuery["getActivityFeed"]["activities"][0],
) => {
    const name = contactName(activity);
    const appLink = activity.activity.parentReferenceContact?.appLink;

    if (!appLink) {
        return name;
    }

    return (
        <Link
            className={defaultLinkStyle}
            to={activity.activity.parentReferenceContact.appLink}>
            {name}
        </Link>
    );
};

const orgName = (
    activity: GetActivityFeedQuery["getActivityFeed"]["activities"][0],
): string => {
    return (
        activity.activity.parentReferenceBusiness?.businessContactEntity?.name ||
        activity.activity.parentCommunity?.name ||
        "(no name)"
    );
};

const orgLink = (activity: GetActivityFeedQuery["getActivityFeed"]["activities"][0]) => {
    const name = orgName(activity);
    const appLink =
        activity.activity.parentReferenceBusiness?.appLink ||
        activity.activity.parentCommunity?.appLink;

    if (!appLink) {
        return name;
    }

    return (
        <Link className={defaultLinkStyle} to={appLink}>
            {name}
        </Link>
    );
};

export const EntityActivityOrganizationMemberAdded: React.FC<
    EntityActivityEntryProps
> = props => {
    const { activity } = props;

    if (!activityItemValid(activity)) {
        return null;
    }

    return (
        <div className="relative flex items-start space-x-3">
            <div className="relative px-1">
                <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white">
                    <UserPlusIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                </div>
            </div>
            <div className="min-w-0 flex-1 group pt-1">
                <EntityActivitySummary {...props}>
                    added <strong>{contactLink(activity)}</strong> to{" "}
                    <strong>{orgLink(activity)}</strong>.
                </EntityActivitySummary>
            </div>
        </div>
    );
};

export const EntityActivityOrganizationMemberRemoved: React.FC<
    EntityActivityEntryProps
> = props => {
    const { activity } = props;

    if (!activityItemValid(activity)) {
        return null;
    }

    return (
        <div className="relative flex items-start space-x-3">
            <div className="relative px-1">
                <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white">
                    <UserPlusIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                </div>
            </div>
            <div className="min-w-0 flex-1 group pt-1">
                <EntityActivitySummary {...props}>
                    removed <strong>{contactLink(activity)}</strong> from{" "}
                    <strong>{orgLink(activity)}</strong>.
                </EntityActivitySummary>
            </div>
        </div>
    );
};
