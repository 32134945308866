import { SupportEmailLink } from "components/app/support-email-link";
import { InlineBanner } from "components/ui/inline-banner";
import { Input } from "components/ui/input";
import { PrimaryButton } from "components/ui/primary-button";
import * as React from "react";
import { connect } from "react-redux";
import { Action, Dispatch } from "redux";
import { ApplicationState } from "store/state";
import * as actions from "../../store/actions";
import { IResetPasswordState } from "../../store/reducers/reset-password";
import { ValidationError } from "../shared/validation-error";

interface ResetPasswordPropsFromDispatch {
    doResetPassword: (email: string) => void;
}

interface ResetPasswordPropsFromState {
    resetPassword: IResetPasswordState;
}

interface ResetPasswordState {
    email: string;
    isValidEmail: boolean;
    formIsSubmitted: boolean;
}

interface LoginPropsFromDispatch {
    doResetPassword: (email: string) => void;
}

type ResetPasswordProps = ResetPasswordPropsFromState & ResetPasswordPropsFromDispatch;

class ResetPassword extends React.Component<ResetPasswordProps, ResetPasswordState> {
    constructor(props: ResetPasswordProps) {
        super(props);
        this.state = {
            email: null,
            isValidEmail: false,
            formIsSubmitted: false,
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    onSubmit(e: any) {
        e.preventDefault();
        this.setState({ ...this.state, formIsSubmitted: true });

        if (!this.state.isValidEmail) {
            return;
        }

        this.props.doResetPassword(this.state.email);
    }

    onEmailChange(e: React.ChangeEvent<HTMLInputElement>) {
        const email = e.target.value;
        this.setState({ ...this.state, email, isValidEmail: this.hasValidFormat(email) });
    }

    hasValidFormat(email: string): boolean {
        const emailRegex =
            /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
        return emailRegex.test(email);
    }

    render() {
        const { isSuccessful, isFailed } = this.props.resetPassword;
        return (
            <div className="reset-password">
                <div className="page-header row">
                    <div className="page-title col">
                        <h2>Reset password</h2>
                    </div>
                    <div className="toolbar col" />
                </div>
                <div className="row main-body">
                    <div className="reset-password-limited">
                        {isFailed && (
                            <InlineBanner type="error">
                                Something went wrong. Please contact <SupportEmailLink />{" "}
                                for further information.
                            </InlineBanner>
                        )}
                        {isSuccessful && (
                            <InlineBanner type="success">
                                Please check your email for instructions on resetting your
                                password
                            </InlineBanner>
                        )}
                        <form
                            onSubmit={this.onSubmit.bind(this)}
                            noValidate
                            className="reset-password-form">
                            <Input
                                type="email"
                                value={this.state.email}
                                onChange={this.onEmailChange.bind(this)}
                                label="Email address"
                            />
                            {!this.state.isValidEmail && this.state.formIsSubmitted && (
                                <ValidationError hasError>
                                    It is not a valid email address
                                </ValidationError>
                            )}

                            <div className="reset-password-buttons">
                                <PrimaryButton type="submit">Reset</PrimaryButton>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state: ApplicationState): ResetPasswordPropsFromState {
    return {
        resetPassword: state.resetPassword,
    };
}

function mapDispatchToProps(dispatch: Dispatch<Action>): LoginPropsFromDispatch {
    return {
        doResetPassword: (email: string) => actions.resetPassword(email, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
