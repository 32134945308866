import { globalFilesEntityId } from "@sp-crm/core";
import { QueryClient } from "react-query";
import { Action, Dispatch } from "redux";
import { GetUserTenantRegionOptionsQuery } from "../../generated/graphql";
import * as http from "../../util/http";
import { clearFileCacheAction } from "./files";
import { loadAll, navigate } from "./index";
import { createChangeRegionAction } from "./region";

export const switchUser = async (
    option: GetUserTenantRegionOptionsQuery["getUserTenantRegionOptions"]["options"][0],
    queryClient: QueryClient,
    dispatch: Dispatch<Action>,
) => {
    const requestDescription = http.newRequestDescription();
    const payload = {
        tenantId: option.tenantId,
        userId: option.userId,
    };
    await http.put(requestDescription, `/api/session`, payload);
    dispatch(createChangeRegionAction(option.regionKey));

    // Needed because tenant-wide files use globalFilesEntityId (which is the same for all tenants)
    dispatch(clearFileCacheAction(globalFilesEntityId));

    loadAll(queryClient, dispatch);
    navigate("/");
};
