import { IChangeTracked, User } from "@sp-crm/core";
import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "store/state";
import util from "../../util";

interface OwnProps {
    entity: IChangeTracked;
}

interface Props {
    createdAt?: Date;
    createdBy?: User;
    updatedAt?: Date;
    updatedBy?: User;
}

class BylineSimple extends React.Component<Props, undefined> {
    created(): JSX.Element {
        if (this.props.createdAt && this.props.createdBy) {
            return (
                <div className="created">
                    Created by {util.user.displayName(this.props.createdBy)}{" "}
                    {util.date.ago(this.props.createdAt)}
                </div>
            );
        }
        if (this.props.createdBy) {
            return (
                <div className="created">
                    Created by {util.user.displayName(this.props.createdBy)}
                </div>
            );
        }
        if (this.props.createdAt) {
            return (
                <div className="created">
                    Created {util.date.ago(this.props.createdAt)}
                </div>
            );
        }
        return null;
    }

    updated(): JSX.Element {
        if (this.props.updatedAt && this.props.updatedBy) {
            return (
                <div className="updated">
                    Updated by {util.user.displayName(this.props.updatedBy)}{" "}
                    {util.date.ago(this.props.updatedAt)}
                </div>
            );
        }
        if (this.props.updatedBy) {
            return (
                <div className="updated">
                    Updated by {util.user.displayName(this.props.updatedBy)}
                </div>
            );
        }
        if (this.props.updatedAt) {
            return (
                <div className="updated">
                    Updated {util.date.ago(this.props.updatedAt)}
                </div>
            );
        }
        return null;
    }

    render() {
        return (
            <div className="byline-simple">
                <div>{this.created()}</div>
                <div>{this.updated()}</div>
            </div>
        );
    }
}

function mapStateToProps(state: ApplicationState, ownProps: OwnProps): Props {
    const entity = ownProps.entity;
    const val = {
        createdAt: entity.dateAdded,
        createdBy: state.users.users[entity.createdBy],
        updatedAt: entity.updatedAt,
        updatedBy: state.users.users[entity.updatedBy],
    };

    return val;
}
const control = connect(mapStateToProps)(BylineSimple);
export { control as BylineSimple };
