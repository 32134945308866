import { isFilePreviewable } from "@sp-crm/core";
import { GetFileQuery } from "generated/graphql";
import React from "react";
import { DocumentRender } from "./pdf/document-render";

interface FilePreviewProps {
    file: GetFileQuery["getFile"];
}

export const FilePreview: React.FC<FilePreviewProps> = props => {
    const { file } = props;

    const [pageNumber, setPageNumber] = React.useState(1);

    if (!isFilePreviewable(file)) {
        return null;
    }

    return (
        <div>
            <p>File preview</p>
            <DocumentRender
                documentUrl={`/api/files/download/${file.id}`}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
            />
        </div>
    );
};
