import * as C from "@sp-crm/core";
import { ClientId } from "@sp-crm/core";
import { Panel } from "components/ui/panel/panel";
import { PanelType } from "components/ui/panel/panel-type";
import * as React from "react";
import { connect } from "react-redux";
import { getClient } from "store/selectors/selectors";
import { ApplicationState } from "store/state";
import { userPreferences } from "../../../../store/selectors/preferences";
import { ClientReferralPdfCustomization, defaultClientPDFOptions } from "./customize";
import { PdfDownload } from "./download";

interface ClientIntakeDownloadDialogProps {
    clientId: ClientId;
    onDismiss: () => void;
}

interface ClientIntakeDownloadDialogInternalPropsFromState {
    client: C.IClient;
    onDismiss: () => void;
    userPreferences: C.UserPreferences;
}

type ClientIntakeDownloadDialogInternalProps =
    ClientIntakeDownloadDialogInternalPropsFromState;

interface ClientIntakeDownloadDialogInternalState {
    options: C.ClientIntakeCustomizationOptions;
}

class ClientIntakeDownloadDialogInternal extends React.Component<
    ClientIntakeDownloadDialogInternalProps,
    ClientIntakeDownloadDialogInternalState
> {
    constructor(p: ClientIntakeDownloadDialogInternalProps) {
        super(p);
        this.state = {
            options: defaultClientPDFOptions(p.client, this.props.userPreferences),
        };
    }

    dismiss(delay = 0): void {
        setTimeout(this.props.onDismiss.bind(this), delay);
    }

    pdfCustomizationOptions(): C.ClientIntakeCustomizationOptions {
        return this.state.options;
    }

    updateOptions(options: C.ClientIntakeCustomizationOptions): void {
        this.setState({ ...this.state, options });
    }

    render() {
        return (
            <Panel
                type={PanelType.largeFixed}
                onDismiss={this.dismiss.bind(this)}
                isOpen={true}
                headerText="Save Client Intake PDF">
                <div className="pdf-dialog-panel">
                    <ClientReferralPdfCustomization
                        options={this.pdfCustomizationOptions()}
                        callback={o => this.updateOptions(o)}
                    />
                    <PdfDownload
                        client={this.props.client}
                        options={this.pdfCustomizationOptions()}
                        onDismiss={(n: number) => this.dismiss(n)}
                    />
                </div>
            </Panel>
        );
    }
}

function mapStateToProps(
    state: ApplicationState,
    ownProps: ClientIntakeDownloadDialogProps,
): ClientIntakeDownloadDialogInternalPropsFromState {
    return {
        client: getClient(state, ownProps.clientId),
        onDismiss: ownProps.onDismiss,
        userPreferences: userPreferences(state),
    };
}

const component = connect(mapStateToProps)(ClientIntakeDownloadDialogInternal);
export { component as ClientIntakeDownloadDialog };
