import {
    ClientId,
    hasSomeAnswer,
    IAdditionalClient,
    IAnswer,
    IClient,
    IClientIntakeDetailInfo,
    InteractionType,
    InternalId,
    IQuestion,
    QuestionCategories,
    questionMetadata,
} from "@sp-crm/core";
import { AutosavingInput } from "components/ui/autosaving-input";
import { fancyConfirm } from "components/ui/fancy-confirm";

import { BridgeQuestionShim } from "components/questions/bridge-question-shim";
import { AdvancedSearchEntityType } from "generated/graphql";
import React, { PureComponent, ReactElement } from "react";
import { connect } from "react-redux";
import { Action, Dispatch } from "redux";
import { tenantSettings } from "store/selectors/preferences";
import { getClient } from "store/selectors/selectors";
import { ApplicationState } from "store/state";
import {
    updateAdditionalClientAnswer,
    updateClient,
    updateClientAnswer,
} from "../../../../store/actions";
import Shared from "../../../shared";
import MultipleClients from "./multiple-clients";
import SingleClient from "./single-client";

type ClientQuestionSectionOwnProps = {
    category: QuestionCategories;
    clientId: ClientId;
};

type ClientQuestionSectionPropsFromState = {
    client: IClient;
    additionalClient: IAdditionalClient;
    showClientMonthlyBudget: boolean;
    showClientTotalFinances: boolean;
};

type ClientQuestionSectionDispatchProps = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    onFieldChange: (fieldName: string, newValue: any) => void;
    onUpdateAnswer: (answer: IAnswer) => Promise<IAnswer>;
    onUpdateAdditionalClientAnswer: (
        additionalClientId: InternalId,
        answer: IAnswer,
    ) => Promise<IAnswer>;
};

type ClientQuestionSectionProps = ClientQuestionSectionOwnProps &
    ClientQuestionSectionPropsFromState &
    ClientQuestionSectionDispatchProps;

class ClientQuestionSection extends PureComponent<ClientQuestionSectionProps> {
    constructor(props: ClientQuestionSectionProps) {
        super(props);
        this.handleUpdateAdditionalClientAnswer =
            this.handleUpdateAdditionalClientAnswer.bind(this);
        this.handleSameForBothClientsCheckbox =
            this.handleSameForBothClientsCheckbox.bind(this);
        this.handleAdditionalClientFieldChange =
            this.handleAdditionalClientFieldChange.bind(this);
    }

    handleUpdateAdditionalClientAnswer(answer: IAnswer) {
        return this.props.onUpdateAdditionalClientAnswer(
            this.props.additionalClient.id,
            answer,
        );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    handleAdditionalClientFieldChange = (fieldName: string, newValue: any) => {
        this.props.onFieldChange(`additionalClient.${fieldName}`, newValue);
    };

    handleSameForBothClientsCheckbox(
        questions: IQuestion[],
        category: QuestionCategories,
        newValue: boolean,
    ) {
        if (newValue && this.clientHasAnswers(questions, this.props.additionalClient)) {
            (async () => {
                const result = await fancyConfirm(
                    "Use same answers for both clients?",
                    "This will erase any Additional Client's answers registered so far",
                    "Yes, I'm sure",
                    "Cancel",
                );
                if (result) {
                    this.props.onFieldChange(
                        `additionalClient.${QuestionCategories[category]}AnswersSameAsPrimaryClient`,
                        newValue,
                    );
                }
            })();
        } else {
            this.props.onFieldChange(
                `additionalClient.${QuestionCategories[category]}AnswersSameAsPrimaryClient`,
                newValue,
            );
        }
    }

    clientHasAnswers(
        questions: IQuestion[],
        clientIntakeDetails: IClientIntakeDetailInfo,
    ) {
        if (!clientIntakeDetails || !clientIntakeDetails.answers) {
            return false;
        }

        let hasSpecialFieldsAnswers = false;
        if (this.props.category === QuestionCategories.otherDetails) {
            hasSpecialFieldsAnswers = !!clientIntakeDetails.otherHealthNotes;
        } else if (this.props.category === QuestionCategories.finance) {
            hasSpecialFieldsAnswers =
                !!clientIntakeDetails.budgetMonthly || !!clientIntakeDetails.budgetTotal;
        }

        const questionClientHasAnswersTo = clientIntakeDetails.answers
            .filter(hasSomeAnswer)
            .map(answer => answer.questionId);
        const questionIds = questions
            .filter(question => question.category === this.props.category)
            .map(question => question.id);

        return (
            hasSpecialFieldsAnswers ||
            questionIds.some(questionId =>
                questionClientHasAnswersTo.includes(questionId),
            )
        );
    }

    private hasQuestionsFromCategory(
        questions: IQuestion[],
        category: QuestionCategories,
    ) {
        if (
            (questions || []).filter(
                q =>
                    q.category == category &&
                    q.interaction != InteractionType.none &&
                    q.enabled(questions, this.props.client.answers),
            ).length > 0
        ) {
            return true;
        }

        return false;
    }

    private hasQuestionsToShow(
        questions: IQuestion[],
        financeDefaultQuestions: Record<string, ReactElement> | null,
        otherDetailsDefaultQuestions: Record<string, ReactElement> | null,
    ) {
        return (
            financeDefaultQuestions ||
            otherDetailsDefaultQuestions ||
            this.hasQuestionsFromCategory(questions, this.props.category)
        );
    }

    private getOtherDetailsDefaultQuestions() {
        if (this.props.category === QuestionCategories.otherDetails) {
            return {
                primaryClient: (
                    <div className="other-health-notes custom-question">
                        <AutosavingInput
                            label={"Additional Notes Regarding Needs / Desires:"}
                            multiLine={true}
                            initial={this.props.client.otherHealthNotes}
                            onCommit={newVal =>
                                this.props.onFieldChange("otherHealthNotes", newVal)
                            }
                        />
                    </div>
                ),
                additionalClient: (
                    <div className="other-health-notes custom-question">
                        <AutosavingInput
                            label={"Additional Notes Regarding Needs / Desires:"}
                            multiLine={true}
                            initial={this.props.additionalClient?.otherHealthNotes}
                            onCommit={newVal =>
                                this.handleAdditionalClientFieldChange(
                                    "otherHealthNotes",
                                    newVal,
                                )
                            }
                        />
                    </div>
                ),
            };
        }
        return null;
    }

    getFinanceDefaultQuestions() {
        if (this.props.category === QuestionCategories.finance) {
            return {
                primaryClient: (
                    <div className="adjacent-inputs">
                        {this.props.showClientMonthlyBudget ||
                        this.props.client.budgetMonthly ? (
                            <Shared.InputCurrency
                                label="Monthly Budget"
                                initial={this.props.client.budgetMonthly}
                                onCommit={newVal =>
                                    this.props.onFieldChange("budgetMonthly", newVal)
                                }
                            />
                        ) : null}

                        {this.props.showClientTotalFinances ||
                        this.props.showClientTotalFinances ? (
                            <Shared.InputCurrency
                                label="Total Finances"
                                initial={this.props.client.budgetTotal}
                                onCommit={newVal =>
                                    this.props.onFieldChange("budgetTotal", newVal)
                                }
                            />
                        ) : null}
                    </div>
                ),
                additionalClient: (
                    <div className="adjacent-inputs">
                        {this.props.showClientMonthlyBudget ||
                        this.props.additionalClient?.budgetMonthly ? (
                            <Shared.InputCurrency
                                label="Monthly Budget"
                                initial={this.props.additionalClient?.budgetMonthly}
                                onCommit={newVal =>
                                    this.handleAdditionalClientFieldChange(
                                        "budgetMonthly",
                                        newVal,
                                    )
                                }
                            />
                        ) : null}

                        {this.props.showClientTotalFinances ||
                        this.props.additionalClient?.budgetTotal ? (
                            <Shared.InputCurrency
                                label="Total Finances"
                                initial={this.props.additionalClient?.budgetTotal}
                                onCommit={newVal =>
                                    this.handleAdditionalClientFieldChange(
                                        "budgetTotal",
                                        newVal,
                                    )
                                }
                            />
                        ) : null}
                    </div>
                ),
            };
        }
        return null;
    }

    render() {
        return (
            <BridgeQuestionShim
                entityName={AdvancedSearchEntityType.Client}
                regionKeys={[this.props.client.region]}>
                {questions => {
                    const financeDefaultQuestions = this.getFinanceDefaultQuestions();
                    const otherDetailsDefaultQuestions =
                        this.getOtherDetailsDefaultQuestions();

                    if (
                        !this.hasQuestionsToShow(
                            questions,
                            financeDefaultQuestions,
                            otherDetailsDefaultQuestions,
                        )
                    ) {
                        return null;
                    }

                    const appliesToMultipleClients = !!this.props.additionalClient;
                    const details = questionMetadata[this.props.category];
                    const primaryClientHasAnswers = this.clientHasAnswers(
                        questions,
                        this.props.client,
                    );
                    const additionalClientHasAnswers = this.clientHasAnswers(
                        questions,
                        this.props.additionalClient,
                    );

                    return appliesToMultipleClients ? (
                        <MultipleClients
                            questions={questions}
                            details={details}
                            category={this.props.category}
                            client={this.props.client}
                            additionalClient={this.props.additionalClient}
                            onUpdateAnswer={this.props.onUpdateAnswer}
                            onUpdateAdditionalClientAnswer={
                                this.handleUpdateAdditionalClientAnswer
                            }
                            onSameForBothClientsCheckboxChanged={(category, newValue) =>
                                this.handleSameForBothClientsCheckbox(
                                    questions,
                                    category,
                                    newValue,
                                )
                            }
                            preJSX={financeDefaultQuestions}
                            posJSX={otherDetailsDefaultQuestions}
                            primaryClientHasAnswers={primaryClientHasAnswers}
                            additionalClientHasAnswers={additionalClientHasAnswers}
                        />
                    ) : (
                        <SingleClient
                            client={this.props.client}
                            details={details}
                            questions={questions}
                            category={this.props.category}
                            answers={this.props.client.answers}
                            onUpdateAnswer={this.props.onUpdateAnswer}
                            onFieldChange={this.props.onFieldChange}
                            preJSX={financeDefaultQuestions?.primaryClient}
                            posJSX={otherDetailsDefaultQuestions?.primaryClient}
                        />
                    );
                }}
            </BridgeQuestionShim>
        );
    }
}

const mapDispatchToProps = (
    dispatch: Dispatch<Action>,
    ownProps: ClientQuestionSectionOwnProps,
) => ({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    onFieldChange: (fieldName: string, newValue: any) => {
        updateClient(ownProps.clientId, fieldName, newValue, dispatch);
    },
    onUpdateAnswer: (answer: IAnswer): Promise<IAnswer> => {
        return updateClientAnswer(
            ownProps.clientId,
            answer,
            dispatch,
        ) as Promise<IAnswer>;
    },
    onUpdateAdditionalClientAnswer: (
        additionalClientId: InternalId,
        answer: IAnswer,
    ): Promise<IAnswer> => {
        return updateAdditionalClientAnswer(
            ownProps.clientId,
            additionalClientId,
            answer,
            dispatch,
        ) as Promise<IAnswer>;
    },
});

const mapStateToProps = (
    state: ApplicationState,
    ownProps: ClientQuestionSectionOwnProps,
) => {
    const loadedClient = getClient(state, ownProps.clientId);
    const settings = tenantSettings(state);
    return {
        client: loadedClient,
        additionalClient: loadedClient.additionalClient,
        showClientMonthlyBudget: settings.showClientMonthlyBudget,
        showClientTotalFinances: settings.showClientTotalFinances,
    };
};

export default connect<
    ClientQuestionSectionPropsFromState,
    ClientQuestionSectionDispatchProps,
    ClientQuestionSectionOwnProps
>(
    mapStateToProps,
    mapDispatchToProps,
)(ClientQuestionSection);
