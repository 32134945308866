import { HomeIcon } from "@heroicons/react/20/solid";
import React from "react";
import { ListableCommunity } from "../props";

interface CommunityCardThumbnailProps {
    community: ListableCommunity;
    className: string;
}

export const CommunityCardThumbnail: React.FC<CommunityCardThumbnailProps> = props => {
    const { community, className } = props;
    if (community.thumbnail) {
        return <img className={className} src={community.thumbnail} />;
    }
    return <HomeIcon className={`bg-gray-200 text-gray-50 ${className}`} />;
};
