import { AutosavingInput } from "components/ui/autosaving-input";
import * as React from "react";
import { InDepthQuestionProps } from "./props";

export class NumberQuestion extends React.Component<InDepthQuestionProps, undefined> {
    constructor(props: InDepthQuestionProps) {
        super(props);
        this.updateAnswer = this.updateAnswer.bind(this);
    }

    updateAnswer(newVal: string): void {
        this.props.requestAnswerModification(a => {
            a.numberAnswer = parseInt(newVal, 10);
        });
    }

    title(): string {
        return this.props.question.title;
    }

    render(): JSX.Element {
        return (
            <AutosavingInput
                onCommit={this.updateAnswer}
                type="number"
                initial={`${this.props.answer.numberAnswer}`}
                key={this.props.question.id}
                label={this.title()}
            />
        );
    }
}
