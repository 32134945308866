import { InteractionType, QuestionType } from "@sp-crm/core";
import { Radio } from "components/shared/radio";
import { BridgeQuestionInput } from "generated/graphql";
import React, { useCallback } from "react";

export function getInitialInteraction(questionType: string) {
    switch (questionType) {
        case QuestionType[QuestionType.text]:
            return InteractionType[InteractionType.singleline];
        case QuestionType[QuestionType.nary]:
            return InteractionType[InteractionType.singleRadio];
        case QuestionType[QuestionType.range]:
            return InteractionType[InteractionType.rangeNumber];
        default:
            return InteractionType[InteractionType.none];
    }
}

const textInteractionTypeOptions = [
    {
        key: "singleline",
        text: "Text on single line",
    },
    {
        key: "multiline",
        text: "Text on multiple lines",
        helpText: "Also called a text box",
    },
    {
        key: "url",
        text: "URL",
        helpText: "Web address. Example: https://example.com",
    },
];

const naryInteractionTypeOptions = [
    {
        key: "singleRadio",
        text: "1 option allowed (with radio buttons)",
        helpText: "All options will be visible, but only 1 can be selected",
    },
    {
        key: "single",
        text: "1 option allowed (with dropdown)",
        helpText:
            "1 options will be visible, with the rest visible when clicking on the dropdown",
    },
    {
        key: "multiple",
        text: "Multiple options allowed",
    },
    {
        key: "multipleDropdown",
        text: "Multiple options allowed (with dropdown)",
    },
];

const rangeInteractionTypeOptions = [
    {
        key: "rangeNumber",
        text: "Number",
        helpText: "Range with number values",
    },
    {
        key: "rangePrice",
        text: "Currency",
        helpText: "Range with dollar values",
    },
];

interface QuestionInteractionInputProps {
    question: BridgeQuestionInput;
    onChange: (question: BridgeQuestionInput) => void;
    editable: boolean;
}

export const QuestionInteractionInput: React.FC<
    QuestionInteractionInputProps
> = props => {
    const { question, onChange, editable } = props;

    const handleInteractionTypeChange = useCallback(
        (value: string) => {
            onChange({
                ...question,
                interaction: value,
            });
        },
        [onChange, question],
    );

    const label = "Interaction type";
    const defaultInteraction = getInitialInteraction(question.questionType);

    switch (question.questionType) {
        case QuestionType[QuestionType.text]:
            return (
                <Radio
                    options={textInteractionTypeOptions}
                    value={question.interaction || defaultInteraction}
                    label={label}
                    onChange={handleInteractionTypeChange}
                    disabled={!editable}
                />
            );
        case QuestionType[QuestionType.nary]:
            return (
                <Radio
                    options={naryInteractionTypeOptions}
                    value={question.interaction || defaultInteraction}
                    label={label}
                    onChange={handleInteractionTypeChange}
                    disabled={!editable}
                />
            );
        case QuestionType[QuestionType.range]:
            return (
                <Radio
                    options={rangeInteractionTypeOptions}
                    value={question.interaction || defaultInteraction}
                    label={label}
                    onChange={handleInteractionTypeChange}
                    disabled={!editable}
                />
            );
        default:
            return null;
    }
};
