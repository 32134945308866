import { CustomListItemId } from "@sp-crm/core";
import { fancyConfirm } from "components/ui/fancy-confirm";
import { GetCustomListQuery } from "generated/graphql";

const newMoveInstead =
    "This client has already been marked as Moved. \n\nIs this a client that is being placed an additional time? If so, would you like to begin an additional move workflow for this client?";

export const promptForStatusIdChange = async (
    statusCustomList: GetCustomListQuery["getCustomList"],
    oldStatusId: CustomListItemId,
    newStatusId: CustomListItemId,
): Promise<"reactivate" | "statusChange"> => {
    const movedStatusItem = statusCustomList.items.find(x => x.key === "moved");
    const oldStatusItem = statusCustomList.items.find(x => x.id === oldStatusId);
    const newStatusItem = statusCustomList.items.find(x => x.id === newStatusId);

    const oldStatusWasMoved = ["moved", "invoiced", "paid"].includes(oldStatusItem.key);
    const newStatusBeforeMoved = newStatusItem.order < movedStatusItem.order;

    if (oldStatusWasMoved && newStatusBeforeMoved) {
        const result = await fancyConfirm(
            "Reactivate client?",
            newMoveInstead,
            'Yes, begin a "new" move workflow',
            "No, I just want to change this client's status",
        );
        if (result) {
            return "reactivate";
        } else {
            return "statusChange";
        }
    } else {
        return "statusChange";
    }
};
