import { XCircleIcon } from "@heroicons/react/24/solid";
import * as C from "@sp-crm/core";
import {
    CommunitySortOptions,
    LocalizedDistance,
    Maybe,
    QuestionId,
    RegionId,
    SettingsCommunitySortType,
} from "@sp-crm/core";
import { BridgeQuestionShim } from "components/questions/bridge-question-shim";
import { SearchInput } from "components/ui/search-input";
import fastDeepEqual from "fast-deep-equal";
import { AdvancedSearchEntityType } from "generated/graphql";
import * as React from "react";
import { connect } from "react-redux";
import { Action, Dispatch } from "redux";
import {
    communitySearchMapResetEvent,
    communitySearchResetEvent,
    communitySearchUpdateSearch,
} from "store/actions/communities";
import { regionIdFromState } from "store/selectors/hooks";
import { ApplicationState } from "store/state";
import { navigate } from "../../../store/actions";
import { CommunitySearchInstance } from "../../../store/reducers/community-search";
import { tenantSettings, userPreferences } from "../../../store/selectors/preferences";
import { INeedsAndDesiresFilters } from "../../clients/show-client/community";
import { SearchLocation } from "./search-location";
import { SearchLocationPreview } from "./search-location-preview";
import { SearchMoreFilters } from "./search-more-filters";
import { SearchPrice, default as SearchPricePreview } from "./search-price";
import { renderable } from "./search-question";
import { SearchQuestionCallout } from "./search-question-callout";
import { SearchRating } from "./search-rating";
import { SearchbarItem } from "./searchbar-item";
import { SearchbarItemBadge } from "./searchbar-item-badge";
import { CommunitySortOptionDescriptions, SortCommunity } from "./sort";
import { StatelessSearchbarItem } from "./stateless-searchbar-item";
import { ISearchAddress, ISearchAnswer, ISearchPrice, ISearchRating } from "./util";

interface SearchCommunityPropsFromState {
    searchKey?: string;
    searches?: CommunitySearchInstance;
    needsAndDesiresFilters?: INeedsAndDesiresFilters;
    onlyShowMinimalSearchFilter?: boolean;
    defaultState: string;
    regionId: RegionId;
    includedExcludedEnabled: boolean;
    defaultCommunitySortOrder: SettingsCommunitySortType;
}

interface SearchCommunityOwnProps {
    searchKey: string;
    includedExcludedEnabled: boolean;
    needsAndDesiresFilters?: INeedsAndDesiresFilters;
}

interface SearchCommunityPropsFromDispatch {
    search: (key: string, contents: CommunitySearchInstance) => void;
    clear: (
        key: string,
        defaultCommunitySortOrder: SettingsCommunitySortType,
        regionId: RegionId,
    ) => void;
    resetMapView: () => void;
    navigate: (path: string) => void;
}

export interface SearchCommunityState {
    searchText: string;
    answers: ISearchAnswer[];
    address: ISearchAddress;
    maxDistance: number;
    specificZip: boolean;
    specificCity: boolean;
    specificCounty: boolean;
    bothGenders: boolean;
    maleOnly: boolean;
    femaleOnly: boolean;
    genderNotSet: boolean;
    contractNoState: boolean;
    contractPending: boolean;
    contractActive: boolean;
    contractOneOff: boolean;
    contractNone: boolean;
    contractTerminated: boolean;
    licensePending: boolean;
    licenseActive: boolean;
    licenseOnProbation: boolean;
    licenseClosed: boolean;
    licenseNotApplicable: boolean;
    licenseNotSet: boolean;
    minCapacity?: number;
    maxCapacity?: number;
    rating: ISearchRating;
    price: ISearchPrice;
    sort: CommunitySortOptions;
    hideDontShow: boolean;
    matchLowScore: string;
    matchHighScore: string;
    matchRule: string;
}

const defaultCount = 25;
type SearchCommunityProps = SearchCommunityPropsFromDispatch &
    SearchCommunityPropsFromState;
class SearchCommunityPriv extends React.Component<
    SearchCommunityProps,
    SearchCommunityState
> {
    private PRICE_MIN = 0;
    private PRICE_MAX = 10000;
    private questionIdSingle: QuestionId;

    constructor(props: SearchCommunityProps) {
        super(props);
        this.state = this.newState(props);
        this.clear = this.clear.bind(this);
    }

    componentDidUpdate(prevProps: Readonly<SearchCommunityProps>): void {
        if (!prevProps.searches && this.props.searches) {
            this.setState(this.newState(this.props));
        }
    }

    buildFilter(state: SearchCommunityState): CommunitySearchInstance {
        const maxDistance = (state.maxDistance || 5) as LocalizedDistance;
        const searchText = (state.searchText || "").toLowerCase();
        const { city, state: stateName, street, zip, county } = state.address;

        return {
            searchText,
            maxDistance,
            specificZip: state.specificZip,
            specificCity: state.specificCity,
            specificCounty: state.specificCounty,
            isGeocoding: false,
            onlyZip: false,
            showAll: false,
            perPage: defaultCount,
            pageNumber: 0,
            sort: state.sort,
            bothGenders: state.bothGenders,
            maleOnly: state.maleOnly,
            femaleOnly: state.femaleOnly,
            genderNotSet: state.genderNotSet,
            contractNoState: state.contractNoState,
            contractPending: state.contractPending,
            contractActive: state.contractActive,
            contractOneOff: state.contractOneOff,
            contractNone: state.contractNone,
            contractTerminated: state.contractTerminated,
            licensePending: state.licensePending,
            licenseActive: state.licenseActive,
            licenseOnProbation: state.licenseOnProbation,
            licenseClosed: state.licenseClosed,
            licenseNotApplicable: state.licenseNotApplicable,
            licenseNotSet: state.licenseNotSet,
            minCapacity: state.minCapacity,
            maxCapacity: state.maxCapacity,
            rating: state.rating,
            zip: zip,
            city: city,
            state: stateName,
            address: street,
            county,
            advancedAnswers: state.answers.slice(0),
            price: {
                ...state.price,
                skipMax: state.price.max === this.PRICE_MAX,
            },
            regionId: this.props.regionId,
            hideDontShow: state.hideDontShow,
            matchLowScore:
                state.matchLowScore === ""
                    ? undefined
                    : parseInt(state.matchLowScore, 10),
            matchHighScore:
                state.matchHighScore === ""
                    ? undefined
                    : parseInt(state.matchHighScore, 10),
            matchRule: state.matchRule,
            clientSidePaginationOnly: false,
        };
    }

    newState(
        props: SearchCommunityPropsFromState,
        isClear?: boolean,
    ): SearchCommunityState {
        const dataOverride = !isClear;
        const searchInstance: CommunitySearchInstance = { ...props.searches };

        return {
            answers:
                (dataOverride &&
                    searchInstance.advancedAnswers &&
                    searchInstance.advancedAnswers.slice(0)) ||
                ([] as ISearchAnswer[]),
            address: {
                city: (dataOverride && searchInstance.city) || "",
                state: (dataOverride && searchInstance.state) || "",
                street: (dataOverride && searchInstance.address) || "",
                zip: (dataOverride && searchInstance.zip) || "",
                county: (dataOverride && searchInstance.county) || "",
            },
            maxDistance: (dataOverride && searchInstance.maxDistance) || 5,
            specificZip: (dataOverride && searchInstance.specificZip) || false,
            specificCounty: (dataOverride && searchInstance.specificCounty) || false,
            specificCity: (dataOverride && searchInstance.specificCity) || false,
            bothGenders: (dataOverride && searchInstance.bothGenders) || false,
            maleOnly: (dataOverride && searchInstance.maleOnly) || false,
            femaleOnly: (dataOverride && searchInstance.femaleOnly) || false,
            genderNotSet: (dataOverride && searchInstance.genderNotSet) || false,
            contractNoState: (dataOverride && searchInstance.contractNoState) || false,
            contractPending: (dataOverride && searchInstance.contractPending) || false,
            contractActive: (dataOverride && searchInstance.contractActive) || false,
            contractOneOff: (dataOverride && searchInstance.contractOneOff) || false,
            contractNone: (dataOverride && searchInstance.contractNone) || false,
            contractTerminated:
                (dataOverride && searchInstance.contractTerminated) || false,
            licensePending: (dataOverride && searchInstance.licensePending) || false,
            licenseActive: (dataOverride && searchInstance.licenseActive) || false,
            licenseOnProbation:
                (dataOverride && searchInstance.licenseOnProbation) || false,
            licenseClosed: (dataOverride && searchInstance.licenseClosed) || false,
            licenseNotApplicable:
                (dataOverride && searchInstance.licenseNotApplicable) || false,
            licenseNotSet: (dataOverride && searchInstance.licenseNotSet) || false,
            minCapacity: (dataOverride && searchInstance.minCapacity) || null,
            maxCapacity: (dataOverride && searchInstance.maxCapacity) || null,
            rating: (dataOverride && searchInstance.rating) || {
                value: 0,
                includeUnrated: true,
            },
            price: (dataOverride && searchInstance.price) || {
                min: this.PRICE_MIN,
                max: this.PRICE_MAX,
                includePriceUndefined: true,
            },
            searchText: dataOverride
                ? Maybe.fromValue(searchInstance)
                      .map(si => si.searchText)
                      .getOrElse("")
                : "",
            sort: dataOverride
                ? Maybe.fromValue(searchInstance)
                      .map(si => si.sort)
                      .getOrElse(props.defaultCommunitySortOrder)
                : props.defaultCommunitySortOrder,
            hideDontShow: searchInstance.hideDontShow,
            matchLowScore: searchInstance.matchHighScore
                ? searchInstance.matchHighScore.toString()
                : "",
            matchHighScore: searchInstance.matchLowScore
                ? searchInstance.matchLowScore.toString()
                : "",
            matchRule: searchInstance.matchRule || "",
        };
    }

    isRangeAnswerApplied(rangeAnswer: ISearchPrice) {
        const { min, max, includePriceUndefined } = rangeAnswer;

        return min !== this.PRICE_MIN || max !== this.PRICE_MAX || !includePriceUndefined;
    }

    getCounterBadge(questionIdSingle: string, isMoreFilters?: boolean): number {
        const selectionAnswersCounter = (this.state.answers || [])
            .filter(x => !!x)
            .filter(answer =>
                isMoreFilters
                    ? answer.questionId !== questionIdSingle
                    : answer.questionId === questionIdSingle,
            )
            .reduce((acc, answer) => {
                return (
                    acc +
                    ((answer.selectionAnswer && answer.selectionAnswer.length) ||
                        (answer.booleanAnswer === "true" && 1) ||
                        (answer.rangeAnswer &&
                            this.isRangeAnswerApplied(answer.rangeAnswer) &&
                            1) ||
                        0)
                );
            }, 0);

        return !isMoreFilters
            ? selectionAnswersCounter
            : selectionAnswersCounter +
                  +this.state.bothGenders +
                  +this.state.femaleOnly +
                  +this.state.maleOnly +
                  +this.state.genderNotSet +
                  +this.state.contractNoState +
                  +this.state.contractPending +
                  +this.state.contractActive +
                  +this.state.contractOneOff +
                  +this.state.contractNone +
                  +this.state.contractTerminated +
                  +this.state.licensePending +
                  +this.state.licenseActive +
                  +this.state.licenseOnProbation +
                  +this.state.licenseClosed +
                  +this.state.licenseNotApplicable +
                  +this.state.licenseNotSet +
                  +(this.state.minCapacity !== null) +
                  +(this.state.maxCapacity !== null);
    }

    private startNewSearch(state: SearchCommunityState, e?: React.FormEvent) {
        if (e) {
            e.preventDefault();
        }
        this.fireSearchRequest(state);
    }

    private fireSearchRequest(state: SearchCommunityState) {
        this.props.search(this.props.searchKey, this.buildFilter(state));
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    onFilterApplied(filters: any) {
        const newState: SearchCommunityState = { ...this.state, ...filters };
        this.setState(newState);
        this.startNewSearch(newState);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    clear(e?: any) {
        if (e) {
            e.preventDefault();
        }

        this.props.clear(
            this.props.searchKey,
            this.props.defaultCommunitySortOrder,
            this.props.regionId,
        );
        const newState = this.newState(this.props, true);
        this.setState(newState);
        this.startNewSearch(newState);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    resetToDefault(e?: any) {
        if (e) {
            e.preventDefault();
        }

        const newState = {
            ...this.state,
            ...this.props.needsAndDesiresFilters,
        };
        this.setState(newState);
        this.startNewSearch(newState);
    }

    search(searchText: string) {
        const newState: SearchCommunityState = { ...this.state, searchText };
        this.setState(newState);
        this.startNewSearch(newState);
    }

    isPriceFilterApplied(): boolean {
        return (
            this.state.price.min !== this.PRICE_MIN ||
            this.state.price.max !== this.PRICE_MAX
        );
    }

    isLocationFilterApplied(): boolean {
        // TODO this is not the correct logic to determine if this filter is applied.
        return !!(
            (this.state.address.county && this.state.address.county.length) ||
            (this.state.address.city && this.state.address.city.length) ||
            (this.state.address.zip && this.state.address.zip.length)
        );
    }

    isQuestionFilterApplied(questionId: QuestionId): boolean {
        return this.getCounterBadge(questionId) > 0;
    }

    areMoreFiltersApplied(questionId: QuestionId): boolean {
        return this.getCounterBadge(questionId, true) > 0;
    }

    isFilterApplied(): boolean {
        return (
            !!(
                this.state.searchText.length ||
                this.state.rating.value ||
                this.isQuestionFilterApplied(this.questionIdSingle) ||
                this.isLocationFilterApplied() ||
                this.isPriceFilterApplied()
            ) || this.areMoreFiltersApplied(this.questionIdSingle)
        );
    }

    isAdditionalFiltersApplied(): boolean {
        const copyState = { ...this.state };
        try {
            delete copyState.price.skipMax;
        } catch {
            /* weird */
        }
        return !fastDeepEqual(copyState, this.props.needsAndDesiresFilters);
    }

    minimalSearchBar(): JSX.Element {
        const placeholder = "Name, Contact, or Street";

        return (
            <div className="min-w-80 md:mr-4">
                <SearchInput
                    type="text"
                    onChange={(newValue: React.ChangeEvent<HTMLInputElement>) =>
                        this.search(newValue.target.value)
                    }
                    value={this.state.searchText}
                    placeholder={placeholder}
                />
            </div>
        );
    }

    private usingMapBounds(): boolean {
        if (!this.props.searches) {
            return false;
        }
        return !!this.props.searches.freeMapCoordinates;
    }

    locationFilter(): JSX.Element {
        if (this.usingMapBounds()) {
            return (
                <div className="flex items-center space-x-1 text-gray-700">
                    <div className="text-lg">Map view</div>
                    <XCircleIcon
                        className="w-5 h-5 text-gray-300 hover:text-gray-700 cursor-pointer"
                        onClick={this.props.resetMapView}
                    />
                </div>
            );
        }
        return (
            <div className="searchbar-column">
                <SearchbarItem
                    content={
                        this.isLocationFilterApplied() ? (
                            <SearchLocationPreview
                                address={this.state.address}
                                specificCity={this.state.specificCity}
                                specificCounty={this.state.specificCounty}
                                specificZip={this.state.specificZip}
                            />
                        ) : (
                            <span>Location</span>
                        )
                    }
                    onFilterSubmitted={params => this.onFilterApplied(params)}>
                    <SearchLocation
                        address={this.state.address}
                        defaultState={this.props.defaultState}
                        maxDistance={this.state.maxDistance}
                        specificCity={this.state.specificCity}
                        specificZip={this.state.specificZip}
                        specificCounty={this.state.specificCounty}
                    />
                </SearchbarItem>
            </div>
        );
    }

    sort(): JSX.Element {
        return (
            <div className="searchbar-column">
                <StatelessSearchbarItem
                    content={
                        <span>
                            Sort:{" "}
                            {CommunitySortOptionDescriptions[this.state.sort] ?? "(Name)"}
                        </span>
                    }>
                    <SortCommunity
                        showDistance={this.isLocationFilterApplied()}
                        value={this.state.sort}
                        onChange={e => this.onFilterApplied({ sort: e })}
                    />
                </StatelessSearchbarItem>
            </div>
        );
    }

    questionFilter(filterQuestion: C.IQuestion): JSX.Element {
        if (!this.questionIdSingle) return null;

        return (
            <div className="searchbar-column">
                <SearchbarItem
                    content={
                        this.isQuestionFilterApplied(this.questionIdSingle) ? (
                            <SearchbarItemBadge
                                counter={this.getCounterBadge(this.questionIdSingle)}>
                                {filterQuestion.title}
                            </SearchbarItemBadge>
                        ) : (
                            <span>{filterQuestion.title}</span>
                        )
                    }
                    onFilterSubmitted={params => this.onFilterApplied(params)}>
                    <SearchQuestionCallout
                        question={filterQuestion}
                        answers={this.state.answers}
                    />
                </SearchbarItem>
            </div>
        );
    }

    priceFilter(): JSX.Element {
        return (
            <div className="searchbar-column">
                <SearchbarItem
                    content={
                        this.isPriceFilterApplied() ? (
                            <SearchPricePreview
                                min={this.state.price.min}
                                max={this.state.price.max}
                                maxValue={this.PRICE_MAX}
                            />
                        ) : (
                            <span>Base Price</span>
                        )
                    }
                    onFilterSubmitted={params => this.onFilterApplied(params)}>
                    <SearchPrice
                        price={{
                            min: this.PRICE_MIN,
                            max: this.PRICE_MAX,
                        }}
                        values={this.state.price}
                    />
                </SearchbarItem>
            </div>
        );
    }

    ratingFilter(): JSX.Element {
        return (
            <div className="searchbar-column">
                <SearchbarItem
                    content={
                        this.state.rating.value > 0 ? (
                            <SearchRating
                                ratingValue={this.state.rating.value}
                                isPreview={true}
                            />
                        ) : (
                            <span>Rating</span>
                        )
                    }
                    onFilterSubmitted={params => this.onFilterApplied(params)}>
                    <SearchRating
                        ratingValue={this.state.rating.value}
                        includeUnrated={this.state.rating.includeUnrated}
                    />
                </SearchbarItem>
            </div>
        );
    }

    moreFilters(questions: C.IQuestion[]): JSX.Element {
        return (
            <div className="searchbar-column">
                <SearchMoreFilters
                    content={
                        this.areMoreFiltersApplied(this.questionIdSingle) ? (
                            <SearchbarItemBadge
                                counter={this.getCounterBadge(
                                    this.questionIdSingle,
                                    true,
                                )}>
                                More filters
                            </SearchbarItemBadge>
                        ) : (
                            <span>More filters</span>
                        )
                    }
                    questions={questions}
                    answers={this.state.answers}
                    bothGenders={this.state.bothGenders}
                    femaleOnly={this.state.femaleOnly}
                    maleOnly={this.state.maleOnly}
                    genderNotSet={this.state.genderNotSet}
                    contractNoState={this.state.contractNoState}
                    contractPending={this.state.contractPending}
                    contractActive={this.state.contractActive}
                    contractOneOff={this.state.contractOneOff}
                    contractNone={this.state.contractNone}
                    contractTerminated={this.state.contractTerminated}
                    licensePending={this.state.licensePending}
                    licenseActive={this.state.licenseActive}
                    licenseOnProbation={this.state.licenseOnProbation}
                    licenseClosed={this.state.licenseClosed}
                    licenseNotApplicable={this.state.licenseNotApplicable}
                    licenseNotSet={this.state.licenseNotSet}
                    minCapacity={this.state.minCapacity}
                    maxCapacity={this.state.maxCapacity}
                    rejectedQuestions={
                        this.questionIdSingle ? [this.questionIdSingle] : []
                    }
                    matchHighScore={this.state.matchHighScore}
                    matchLowScore={this.state.matchLowScore}
                    matchRule={this.state.matchRule}
                    onFilterSubmitted={params => this.onFilterApplied(params)}
                    clear={this.clear}
                />
            </div>
        );
    }

    resetFilters(): JSX.Element {
        return (
            <div className="searchbar-column">
                {this.props.needsAndDesiresFilters && this.isAdditionalFiltersApplied() && (
                    <a
                        href="#"
                        className="link-clear visible padded-right"
                        onClick={e => this.resetToDefault(e)}>
                        Reset to Default Filters
                    </a>
                )}
                <a
                    href="#"
                    className={
                        "link-clear " + ((this.isFilterApplied() && "visible") || "")
                    }
                    onClick={e => this.clear(e)}>
                    Clear All Filters
                </a>
            </div>
        );
    }

    searchBar() {
        return (
            <BridgeQuestionShim entityName={AdvancedSearchEntityType.Community}>
                {questions => {
                    const filterQuestion = getFirstTypeFilterQuestion(questions);
                    this.questionIdSingle = filterQuestion && filterQuestion.id;
                    return (
                        <form
                            className="searchbar-component"
                            onSubmit={e => this.startNewSearch(this.state, e)}>
                            <div className="md:flex md:items-center space-y-1 md:space-y-0 md:space-x-4">
                                {this.minimalSearchBar()}
                                {!this.props.onlyShowMinimalSearchFilter &&
                                    this.locationFilter()}
                                {!this.props.onlyShowMinimalSearchFilter &&
                                    this.questionFilter(filterQuestion)}
                                {!this.props.onlyShowMinimalSearchFilter &&
                                    this.priceFilter()}
                                {!this.props.onlyShowMinimalSearchFilter &&
                                    this.ratingFilter()}
                                {!this.props.onlyShowMinimalSearchFilter &&
                                    this.moreFilters(questions)}
                                {this.sort()}
                                {this.resetFilters()}
                            </div>
                        </form>
                    );
                }}
            </BridgeQuestionShim>
        );
    }

    render() {
        return <div className="search-component-wrapper">{this.searchBar()}</div>;
    }
}

function mapStateToProps(
    state: ApplicationState,
    ownProps: SearchCommunityOwnProps,
): SearchCommunityPropsFromState {
    return {
        searchKey: ownProps.searchKey,
        searches: state.communitySearch.searches[ownProps.searchKey],
        needsAndDesiresFilters: ownProps.needsAndDesiresFilters,
        defaultState: tenantSettings(state).defaultState,
        regionId: regionIdFromState(state, "community"),
        includedExcludedEnabled: !!ownProps.includedExcludedEnabled,
        defaultCommunitySortOrder: userPreferences(state).defaultCommunitySortOrder,
    };
}

function mapDispatchToProps(
    dispatch: Dispatch<Action>,
    ownProps: SearchCommunityOwnProps,
): SearchCommunityPropsFromDispatch {
    return {
        search: (searchKey: string, contents: CommunitySearchInstance) => {
            dispatch(communitySearchUpdateSearch(searchKey, contents));
        },
        clear: (
            searchKey: string,
            defaultCommunitySortOrder: SettingsCommunitySortType,
            regionId: RegionId,
        ) => {
            dispatch(
                communitySearchResetEvent(searchKey, defaultCommunitySortOrder, regionId),
            );
        },
        resetMapView: () => dispatch(communitySearchMapResetEvent(ownProps.searchKey)),
        navigate: (url: string) => navigate(url),
    };
}

const component = connect(mapStateToProps, mapDispatchToProps)(SearchCommunityPriv);
export { component as SearchCommunity };

export function getFirstTypeFilterQuestion(questions: C.IQuestion[]): C.IQuestion {
    const order = (questionA: C.IQuestion, questionB: C.IQuestion): number =>
        (questionA.order || 0) - (questionB.order || 0);
    return (questions || [])
        .filter(q => q.category === C.QuestionCategories.type && renderable(q))
        .sort(order)[0];
}
