import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import React, { ComponentType, useCallback } from "react";

const buttonClasses =
    "twoverride inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm rounded-sm sm:rounded text-gray-50 bg-brand-600 " +
    "hover:bg-brand-700 " +
    "space-x-1 items-center justify-around" +
    "focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500";

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
}

interface ActionsMenuSectionProps {
    children: React.ReactNode;
}

export const ActionsMenuSection: React.FC<ActionsMenuSectionProps> = props => {
    const { children } = props;
    return <div className="py-1">{children}</div>;
};

interface ActionsMenuHeaderProps {
    children: React.ReactNode;
}

export const ActionsMenuHeader: React.FC<ActionsMenuHeaderProps> = props => {
    return (
        <div className="px-4 py-2 text-brand-600 text-sm font-bold">{props.children}</div>
    );
};

interface ActionsMenuActionProps {
    onClick: () => void;
    disabled?: boolean;
    Icon: ComponentType<React.ComponentProps<"svg">>;
    hoverColor?: string;
    children: React.ReactNode;
}

export const ActionsMenuAction: React.FC<ActionsMenuActionProps> = props => {
    const { children, onClick, disabled, Icon } = props;
    const actualOnClick = useCallback(
        (e: React.MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault();
            onClick();
        },
        [onClick],
    );
    if (disabled) {
        return (
            <div
                className={classNames(
                    "text-gray-400 flex items-center px-4 py-2 text-sm",
                )}>
                <div>
                    <Icon className="mr-3 h-5 w-5 text-gray-300" aria-hidden="true" />
                </div>
                <div>{children}</div>
            </div>
        );
    }
    return (
        <MenuItem>
            {({ focus }) => (
                <a
                    href="#"
                    onClick={actualOnClick}
                    className={classNames(
                        focus ? "bg-brand-100 text-brand-900" : "text-gray-700",
                        "twoverride group flex items-center px-4 py-2 text-sm",
                    )}>
                    <div>
                        <Icon
                            className="mr-3 h-5 w-5 text-gray-400 group-hover:text-brand-600"
                            aria-hidden="true"
                        />
                    </div>
                    <div>{children}</div>
                </a>
            )}
        </MenuItem>
    );
};

interface ActionsMenuProps {
    children: React.ReactNode;
}

export const ActionsMenu: React.FC<ActionsMenuProps> = props => {
    const { children } = props;
    return (
        <Menu as="div" className="relative inline-block text-left z-1300">
            <div>
                <MenuButton className={buttonClasses}>
                    <div>Actions</div>
                    <div>
                        <ChevronDownIcon className="h-4 w-4" aria-hidden="true" />
                    </div>
                </MenuButton>
            </div>
            <MenuItems className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
                {children}
            </MenuItems>
        </Menu>
    );
};
