import { Transition } from "@headlessui/react";
import { CheckBadgeIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import { CalendarDate, Task, TaskParentId, genEntityId } from "@sp-crm/core";
import { Badge } from "components/ui/badge";
import { Panel } from "components/ui/panel/panel";
import { PanelType } from "components/ui/panel/panel-type";
import { TaskOwnerViewableKind } from "constants/tasks";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    FilterableTaskTypes,
    TasksAll,
    allTaskTypesSentinel,
    taskDashboardSlice,
    unsetTaskTypeSentinel,
} from "store/slices/tasks-dashboard";
import { ApplicationState } from "store/state";
import {
    GetUsersForRegionQuery,
    UpdateTaskPayload,
    useCreateTaskMutation,
} from "../../../generated/graphql";
import { TaskFormV2 } from "./task-form-v2";
import { TaskLineItem } from "./task-line-item";
import { Taskv2 } from "./types";

interface TaskListv2Props {
    refetch: () => Promise<void>;
    tasks: Taskv2[];
    taskTypeFilter: FilterableTaskTypes;
    entityFilter: TaskOwnerViewableKind;
    users: GetUsersForRegionQuery["getUsersForRegion"];
    queryString?: string;
    modifyLocalTask: (task: Taskv2) => void;
}

interface AddPanelState {
    isOpen: boolean;
    sourceTask: Taskv2 | null;
}

export const TaskListv2: React.FunctionComponent<TaskListv2Props> = props => {
    const { refetch, taskTypeFilter, entityFilter, queryString, modifyLocalTask, users } =
        props;
    const [selectedTask, setSelectedTask] = React.useState<Taskv2 | null>(null);
    const [addPanelState, setAddPanelState] = React.useState<AddPanelState>({
        isOpen: false,
        sourceTask: null,
    });

    const createTask = useCreateTaskMutation();

    let tasks = props.tasks;

    const sectionVisibility = useSelector(
        (state: ApplicationState) => state.taskDashboard.sectionVisibility,
    );
    const toggleSection = taskDashboardSlice.actions.toggleSectionVisibility;
    const dispatch = useDispatch();

    const handleTaskSelected = React.useCallback(
        (task: Taskv2) => {
            setSelectedTask(task);
        },
        [setSelectedTask],
    );

    const handleTaskUnselected = React.useCallback(() => {
        setSelectedTask(null);
    }, [setSelectedTask]);

    const handleAddTask = React.useCallback(
        (source: Taskv2) => {
            setAddPanelState({ isOpen: true, sourceTask: source });
        },
        [setAddPanelState],
    );

    const hideAddPanel = React.useCallback(() => {
        setAddPanelState({ isOpen: false, sourceTask: null });
    }, [setAddPanelState]);

    const addPanelInitialTask: Taskv2 = React.useMemo(() => {
        return {
            entity: addPanelState.sourceTask
                ? { ...addPanelState.sourceTask.entity }
                : null,
            task: {
                done: false,
                id: genEntityId(),
                parentId: addPanelState?.sourceTask?.task?.parentId || null,
                status: "new",
                completedAt: null,
                dueDate: CalendarDate.today.toString(),
                dueDateTime: null,
                ownerId: addPanelState?.sourceTask?.task?.ownerId || null,
                taskTypeId: null,
                taskType: null,
                text: "",
            },
        };
    }, [addPanelState.sourceTask]);

    const handleTaskCreated = React.useCallback(
        async (parentId: TaskParentId, params: UpdateTaskPayload) => {
            await createTask.mutateAsync({
                parentId,
                params,
            });
            await refetch();
        },
        [createTask, refetch],
    );

    if (!tasks || tasks.length === 0) {
        return (
            <div className="flex items-center space-x-2">
                <CheckBadgeIcon className="w-8 h-8 text-green-400" />
                <div>No tasks!</div>
            </div>
        );
    }

    if (entityFilter && entityFilter !== TasksAll) {
        tasks = tasks.filter(
            t =>
                t.entity.kind === entityFilter ||
                (entityFilter === "REFERENCE" &&
                    (t.entity.kind === "REFERENCEBUSINESS" ||
                        t.entity.kind === "REFERENCECONTACT")),
        );
    }
    if (taskTypeFilter === unsetTaskTypeSentinel) {
        tasks = tasks.filter(t => !t.task.taskType);
    } else if (taskTypeFilter && taskTypeFilter !== allTaskTypesSentinel) {
        tasks = tasks.filter(t => taskTypeFilter === t.task.taskType?.id);
    }
    if (queryString && queryString !== "") {
        const searchText = queryString.toLowerCase();
        tasks = tasks.filter(t => {
            const targetText = `${t.entity.displayName} ${t.task.text}`.toLowerCase();
            return targetText.includes(searchText);
        });
    }

    if (tasks.length === 0) {
        return (
            <div className="flex items-center space-x-2">
                <div>
                    <em>No tasks match search.</em>
                </div>
            </div>
        );
    }

    const mostRecentMidnight = new Date();
    mostRecentMidnight.setHours(0, 0, 0, 0);

    const midnightTomorrow = new Date();
    midnightTomorrow.setDate(midnightTomorrow.getDate() + 1);
    midnightTomorrow.setHours(23, 59, 59, 999);

    const midnightTonight = new Date();
    midnightTonight.setHours(23, 59, 59, 999);

    const overdueTasks: Taskv2[] = [];
    const dueToday: Taskv2[] = [];
    const dueTomorrow: Taskv2[] = [];
    const dueLater: Taskv2[] = [];
    const noDueDate: Taskv2[] = [];

    tasks.forEach(task => {
        const effectiveDate = Task.load(task.task).when();
        if (effectiveDate) {
            if (effectiveDate < mostRecentMidnight) {
                overdueTasks.push(task);
            } else if (effectiveDate < midnightTonight) {
                dueToday.push(task);
            } else if (effectiveDate < midnightTomorrow) {
                dueTomorrow.push(task);
            } else {
                dueLater.push(task);
            }
        } else {
            noDueDate.push(task);
        }
    });

    return (
        <div className="space-y-4 lg:space-y-8">
            {overdueTasks.length > 0 ? (
                <div className="space-y-2 divide-y divide-gray-400">
                    <h2 className="font-bold text-lg flex items-center space-x-2">
                        <button onClick={() => dispatch(toggleSection("overdue"))}>
                            <ChevronDownIcon
                                className={`w-6 h-6 transform transition duration-50 ${
                                    sectionVisibility.overdue ? "rotate-0" : "-rotate-90"
                                }`}
                            />
                        </button>
                        <div>Overdue</div>
                        <Badge value={overdueTasks.length} type="error" />
                    </h2>
                    <Transition
                        as="div"
                        show={sectionVisibility.overdue}
                        enter="transition-opacity duration-100"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity duration-50"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0">
                        <ListTaskv2
                            refetch={refetch}
                            tasks={overdueTasks}
                            selectedTask={selectedTask}
                            onTaskSelected={handleTaskSelected}
                            onTaskUnselected={handleTaskUnselected}
                            onAddTask={handleAddTask}
                            modifyLocalTask={modifyLocalTask}
                            users={users}
                        />
                    </Transition>
                </div>
            ) : null}
            {dueToday.length > 0 ? (
                <div className="space-y-2 divide-y divide-gray-400">
                    <h2 className="font-bold text-lg flex items-center space-x-2">
                        <button onClick={() => dispatch(toggleSection("today"))}>
                            <ChevronDownIcon
                                className={`w-6 h-6 transform transition duration-50 ${
                                    sectionVisibility.today ? "rotate-0" : "-rotate-90"
                                }`}
                            />
                        </button>
                        <div>Due Today</div>
                        <Badge value={dueToday.length} type="info" />
                    </h2>

                    <Transition
                        as="div"
                        show={sectionVisibility.today}
                        enter="transition-opacity duration-100"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity duration-50"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0">
                        <ListTaskv2
                            refetch={refetch}
                            tasks={dueToday}
                            selectedTask={selectedTask}
                            onTaskSelected={handleTaskSelected}
                            onTaskUnselected={handleTaskUnselected}
                            onAddTask={handleAddTask}
                            modifyLocalTask={modifyLocalTask}
                            users={users}
                        />
                    </Transition>
                </div>
            ) : null}
            {dueTomorrow.length > 0 ? (
                <div className="space-y-2 divide-y divide-gray-400">
                    <h2 className="font-bold text-lg flex items-center space-x-2">
                        <button onClick={() => dispatch(toggleSection("tomorrow"))}>
                            <ChevronDownIcon
                                className={`w-6 h-6 transform transition duration-50 ${
                                    sectionVisibility.tomorrow ? "rotate-0" : "-rotate-90"
                                }`}
                            />
                        </button>
                        <div>Due Tomorrow</div>
                        <Badge value={dueTomorrow.length} type="info" />
                    </h2>
                    <Transition
                        as="div"
                        show={sectionVisibility.tomorrow}
                        enter="transition-opacity duration-100"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity duration-50"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0">
                        <ListTaskv2
                            refetch={refetch}
                            tasks={dueTomorrow}
                            selectedTask={selectedTask}
                            onTaskSelected={handleTaskSelected}
                            onTaskUnselected={handleTaskUnselected}
                            onAddTask={handleAddTask}
                            modifyLocalTask={modifyLocalTask}
                            users={users}
                        />
                    </Transition>
                </div>
            ) : null}
            {dueLater.length > 0 ? (
                <div className="space-y-2 divide-y divide-gray-400">
                    <h2 className="font-bold text-lg flex items-center space-x-2">
                        <button onClick={() => dispatch(toggleSection("future"))}>
                            <ChevronDownIcon
                                className={`w-6 h-6 transform transition duration-50 ${
                                    sectionVisibility.future ? "rotate-0" : "-rotate-90"
                                }`}
                            />
                        </button>
                        <div>Later</div>
                        {sectionVisibility.future ? null : (
                            <Badge value={dueLater.length} type="info" />
                        )}
                    </h2>
                    <Transition
                        as="div"
                        show={sectionVisibility.future}
                        enter="transition-opacity duration-100"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity duration-50"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0">
                        <ListTaskv2
                            refetch={refetch}
                            tasks={dueLater}
                            selectedTask={selectedTask}
                            onTaskSelected={handleTaskSelected}
                            onTaskUnselected={handleTaskUnselected}
                            onAddTask={handleAddTask}
                            modifyLocalTask={modifyLocalTask}
                            users={users}
                        />
                    </Transition>
                </div>
            ) : null}
            {noDueDate.length > 0 ? (
                <div className="space-y-2 divide-y divide-gray-400">
                    <h2 className="font-bold text-lg flex items-center space-x-2">
                        <button onClick={() => dispatch(toggleSection("none"))}>
                            <ChevronDownIcon
                                className={`w-6 h-6 transform transition duration-50 ${
                                    sectionVisibility.none ? "rotate-0" : "-rotate-90"
                                }`}
                            />
                        </button>
                        <div>No due date</div>
                        {sectionVisibility.none ? null : (
                            <Badge value={noDueDate.length} type="info" />
                        )}
                    </h2>
                    <Transition
                        as="div"
                        show={sectionVisibility.none}
                        enter="transition-opacity duration-100"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity duration-50"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0">
                        <ListTaskv2
                            refetch={refetch}
                            tasks={noDueDate}
                            selectedTask={selectedTask}
                            onTaskSelected={handleTaskSelected}
                            onTaskUnselected={handleTaskUnselected}
                            onAddTask={handleAddTask}
                            modifyLocalTask={modifyLocalTask}
                            users={users}
                        />
                    </Transition>
                </div>
            ) : null}
            <Panel
                type={PanelType.large}
                headerText="Add new task"
                isOpen={addPanelState.isOpen}
                onDismiss={hideAddPanel}>
                <TaskFormV2
                    initial={addPanelInitialTask}
                    mode="create"
                    onClose={hideAddPanel}
                    saveTask={handleTaskCreated}
                />
            </Panel>
        </div>
    );
};

interface ListTaskv2Props {
    tasks: Taskv2[];
    refetch: () => Promise<void>;
    selectedTask: Taskv2 | null;
    onTaskSelected: (task: Taskv2) => void;
    onTaskUnselected: (task: Taskv2) => void;
    onAddTask: (task: Taskv2) => void;
    modifyLocalTask: (task: Taskv2) => void;
    users: GetUsersForRegionQuery["getUsersForRegion"];
}

export const ListTaskv2: React.FC<ListTaskv2Props> = props => {
    const {
        refetch,
        tasks,
        onTaskSelected,
        onTaskUnselected,
        onAddTask,
        modifyLocalTask,
        users,
    } = props;
    return (
        <ul className="divide-y divide-gray-200">
            {tasks.map(task => (
                <li key={task.task.id}>
                    <TaskLineItem
                        modifyLocalTask={modifyLocalTask}
                        task={task}
                        refetch={refetch}
                        isSelected={task.task.id === props.selectedTask?.task.id}
                        onSelected={onTaskSelected}
                        onUnselected={onTaskUnselected}
                        onAddTask={onAddTask}
                        users={users}
                    />
                </li>
            ))}
        </ul>
    );
};
