import { daysAgo, timeOnly } from "@sp-crm/core";
import React, { useMemo } from "react";
import { EntityActivityEntryProps } from "../types";

export const EntityActivitySummary: React.FC<EntityActivityEntryProps> = props => {
    const { activity, children } = props;
    const createdAt = useMemo(
        () => new Date(activity.activity.createdAt),
        [activity.activity.createdAt],
    );
    return (
        <div className="flex justify-between items-begin space-x-2">
            <div className="text-sm font-medium text-gray-700">
                <strong>{activity.activity.createdByEntity.preferredName}</strong>{" "}
                {children}
            </div>
            <div className="text-gray-400 text-sm flex-shrink-0">
                <span className="text-gray-600">{daysAgo(createdAt, "")}</span> at{" "}
                <span className="text-gray-600">{timeOnly(createdAt)}</span>
            </div>
        </div>
    );
};
