import { Spinner } from "components/ui/spinner";
import { useGetSavedQueriesQuery } from "generated/graphql";
import React, { useCallback } from "react";
import { useQueryClient } from "react-query";
import { useRouteMatch } from "react-router-dom";
import { CustomReportsExisting } from "./custom-reports-existing";
import { CustomReportsRouteParams } from "./custom-reports-helpers";
import { CustomReportsNew } from "./custom-reports-new";

export const CustomReportsQuery: React.FC = () => {
    const { params } = useRouteMatch<CustomReportsRouteParams>();
    const reportId = params.reportId;
    const isNew = !reportId || reportId === "new";
    const query = useGetSavedQueriesQuery({}, { keepPreviousData: true });
    const queryClient = useQueryClient();
    const refetch = useCallback(
        () => queryClient.invalidateQueries(["getSavedQueries"]),
        [queryClient],
    );

    if (query.isSuccess && query.data) {
        if (isNew) {
            return (
                <CustomReportsNew
                    canSaveAdditional={query.data.getSavedQueries.canSaveAdditional}
                />
            );
        }
        const report = query.data.getSavedQueries.savedQueries.find(
            q => q.id === reportId,
        );
        if (report) {
            return (
                <CustomReportsExisting
                    key={report.id}
                    report={report}
                    onReload={refetch}
                    canDuplicateReports={query.data.getSavedQueries.canSaveAdditional}
                />
            );
        }
    }

    return <Spinner />;
};
